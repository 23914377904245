import axios from 'axios';
import { FIREBASE_FUNCTION_URL } from './index';

/**
 * 로그인 사용자의 장바구니 상품 조회
 */
export async function fetchProducts(cartParam) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__GET_CART_LIST, { ...cartParam });
}

/**
 * 로그인 사용자의 장바구니 상품 추가
 */
export async function addProducts(cartParam) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ADD_CART, [...cartParam]);
}

/**
 * 로그인 사용자의 장바구니 상품 삭제
 */
export async function deleteProducts(cartParam) {
  return axios.delete(FIREBASE_FUNCTION_URL.DELETE__CART_PRODUCTS, { data: [...cartParam] });
}
