import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { DiscountChipIcon, ProductCurrency, ProductPrice } from 'components';
import { Calculator } from 'utils/bourgogne-tools';

// material-ui
import { Box, Chip, ListItemText, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * 상품 상세 - 판매처 리스트 아이템
 *
 * @param product 판매상품 정보
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 * @history
 *  - v1.0.9: 매장표현 디자인 변경
 */
function VendorItem({ product, promFlag }) {
  const { capacity, vendor, price, vintage } = product;

  // 용량 표현 (글자 수 별로 padding이 다름 / 1 ~ 4: 4px 10px, 4 ~ : 4px 8px)
  const CapacityChipUi = React.useMemo(
    () => () =>
      capacity ? (
        <CapacityChip
          label={Calculator.formatCapacity(capacity)}
          sx={{ py: '4px', px: Calculator.formatCapacity(capacity).length > 4 ? '8px' : '10px' }}
        />
      ) : null,
    [capacity]
  );

  // 빈티지 표현
  const VintageChipUi = React.useMemo(() => () => vintage && vintage !== 'NV' ? <VintageChip label={vintage} /> : null, [vintage]);

  // 할인 표현 // <DiscountChipIcon />
  const DiscountChipUi = React.useMemo(
    () => () => promFlag ? <DiscountChip avatar={<DiscountChipIcon />} label="할인" /> : null,
    [promFlag]
  );

  // render
  return (
    <>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="h6" fontSize="18px" fontWeight="bold" color="#140229">
            {vendor.biz.name}
            {vendor.test && <em style={{ fontSize: '10px', marginLeft: '4px' }}>TEST</em>}
          </Typography>
        }
        secondary={
          <>
            <Typography paragraph variant="caption" fontSize="11px" lineHeight={1.36} color="#140229" sx={{ pt: '6px', opacity: 0.7 }}>
              {vendor.biz.address1}
              {vendor?.biz?.address2 && ` ${vendor.biz.address2}`}
            </Typography>
            <Box mt="16px">
              {/* 빈티지/용량 & 가격정보 & 할인여부 */}
              <Box display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing="4px" alignItems="center" mr={1}>
                  <CapacityChipUi />
                  <VintageChipUi />
                  <DiscountChipUi />
                </Stack>
                <Box display="flex" alignItems="center">
                  <ProductPrice fontSize="22px" fontWeight="bold">
                    {(price.original >> 0).toLocaleString()}
                  </ProductPrice>
                  <ProductCurrency multiple={false} pl="1px" />
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
}

VendorItem.propTypes = {
  product: PropTypes.object.isRequired,
  promFlag: PropTypes.bool.isRequired
};

export default VendorItem;

// 빈티지 칩
const VintageChip = styled(Chip)(({ theme }) => ({
  fontFamily: `'D-Din', 'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  backgroundColor: '#9357E5',
  color: theme.palette.primary.contrastText,
  height: '22px',
  fontSize: '12px',
  lineHeight: 'normal',
  padding: '4px 8px',
  '& .MuiChip-label': {
    padding: 0
  }
}));

// 용량 칩
const CapacityChip = styled(Chip)(({ theme }) => ({
  fontFamily: `'D-Din', 'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  backgroundColor: theme.palette.brand.main,
  color: theme.palette.text.white,
  height: '22px',
  fontSize: '12px',
  lineHeight: 'normal',
  '& .MuiChip-label': {
    padding: 0
  }
}));

// 할인 칩
const DiscountChip = styled(Chip)(() => ({
  fontFamily: `'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid #000',
  height: '22px',
  fontSize: '10px',
  lineHeight: 'normal',
  padding: '5px 8px',
  '& .MuiChip-label': {
    padding: '0 0 0 2px'
  }
}));
