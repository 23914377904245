import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// project imports
import { useAuth, useKakaoSdk, useWineOne } from 'hooks';
import { APP_TYPE } from 'store/slices/wine-one';
import { AppleButton, KakaoButton } from 'components';
import { LogoIcon } from 'components/icons';
import { WoAlert } from 'utils/kmwine-alerts';

// material-ui
import { Alert, Box, Button, Container, Divider, Slide, Snackbar, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

/** Slide Up transition */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * 로그인 페이지
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 최효근<hkchoi@wineone.io>
 */
function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const { initialized: kakaoSdkInitialized } = useKakaoSdk();
  const { appType, onNativeBackPressed } = useWineOne();

  const { appleLogin } = useAuth();

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '로그인 실패'
  });

  /** 로그인 실패 스낵바 */
  const handleSnackbarClose = React.useCallback(() => {
    setSnackbar((prev) => ({
      ...prev,
      message: '로그인 실패',
      open: false
    }));
  });

  const loginSnackBar = React.useMemo(
    () => (
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} TransitionComponent={Transition} key="login-error-snackbar">
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    ),
    [handleSnackbarClose, snackbar.open]
  );

  function finishApp() {
    console.log('앱 종료하기');
    // 구매확정 API요청
    WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          1KMWINE
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>앱을 종료하시겠습니까?</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '앱 종료',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          window.kmwine.finishApp();
        } catch (e) {
          console.warn('안드로이드 앱 종료 실패.', e);
        }
      }
    });
  }

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`로그인 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);

    // 안드로이드의 경우
    if (appType === APP_TYPE.AOS) {
      // 안드로이드 앱 종료
      finishApp();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (window.history.length > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    }
  }, [appType, navigate]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [location]);

  // 카카오 로그인 버튼 클릭
  const onClickKakaoLoginButton = React.useCallback(async () => {
    if (!kakaoSdkInitialized) {
      await WoAlert.fire('', '카카오 로그인 모듈을 불러오고 있습니다.<br/>잠시 후 다시 시도해주세요.', 'info');
      return;
    }

    const targetUrl = searchParams.get('from') ?? '/home';
    console.log('#targetUrl: ', searchParams.get('from') ?? '/home');

    // -> <KakaoSignInLoading />
    window.Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}/auth/sign-in/kakao/loading`,
      prompt: 'select_account',
      throughTalk: true,
      state: JSON.stringify({ targetUrl })
    });
  }, [kakaoSdkInitialized, searchParams]);

  // 애플 로그인 버튼 클릭
  const onClickAppleLoginButton = React.useCallback(async () => {
    try {
      const signInResult = await appleLogin();
      console.log('################## signInResult: ', signInResult);
      const { success } = signInResult;

      // 애플 로그인 성공 후 홈으로 이동
      if (success) {
        console.log('[1KMWINE] 애플로그인 성공.', signInResult);

        // 가입한 사용자
        if (signInResult.role === 'user') {
          console.log('# 홈 화면으로 이동 ###############################');
          navigate('/home');
        } else if (typeof signInResult.role === 'undefined') {
          console.log('# 회원가입 화면으로 이동 ###############################');
          navigate(`/cert/self?provider=apple&uid=${signInResult.user.uid}`);
        }
      }
    } catch (e) {
      console.error('[로그인] 애플 로그인 실패', e);
    }
  }, [appleLogin, navigate]);

  // render
  return (
    <Box component="main" display="flex" alignItems="center">
      <Container
        maxWidth="xs"
        sx={{
          px: '16px',
          minHeight: 'calc(var(--vh, 1vh) * 100)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box mt="68px" px="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <LogoIcon sx={{ width: 166, height: 46 }} />
          <Typography component="div" fontSize="18px" fontWeight="normal" letterSpacing="-0.18px">
            가장 빠른 와인 픽&오더 플랫폼
          </Typography>
        </Box>

        <Box flexGrow={1} maxHeight="113px" />

        <Box my="32px">
          <Stack spacing="10px" justifyContent="center">
            <KakaoButton label="카카오 로그인" onClick={onClickKakaoLoginButton} />
            <AppleButton label="Apple 로그인" onClick={onClickAppleLoginButton} />
          </Stack>
        </Box>

        <Box flexGrow={1} />

        <Box mb="32px">
          <Stack
            direction="row"
            divider={
              <Box py="8px" display="flex">
                <Divider orientation="vertical" flexItem />
              </Box>
            }
            spacing={1}
            justifyContent="center"
          >
            <AccountActionButton onClick={() => navigate(`/auth/sign-up/start`)}>회원가입</AccountActionButton>
            <AccountActionButton
              onClick={() => {
                navigate('/auth/email-sign-in');
              }}
            >
              아이디 로그인
            </AccountActionButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default SignInPage;

const AccountActionButton = styled(Button)(() => ({
  fontSize: 13,
  opacity: 0.6,
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.13px'
}));
