import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// project imports
import { useWineOne } from 'hooks';
import { WoDivider } from 'components/dialog';
import { ToolbarTitle } from 'components/toolbar';
import BackButton from 'components/buttons/BackButton';
import SpaceBetweenBox from '../../../components/SpaceBetweenBox';
import { BtnMoreIcon } from '../../../components/icons';

// material-ui
import { AppBar, Box, ButtonBase, Container, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TERM_TYPE } from '../../../services/AppService';

/**
 * 약관 및 정책 목록 화면
 * @returns {JSX.Element}
 * @constructor
 */
function MyDocs() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { onNativeBackPressed } = useWineOne();

  // 뒤로가기
  const pageBackFunc = useCallback(() => {
    console.debug(`약관 및 정책 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location]);

  useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [location]);

  return (
    <Box minHeight={`calc(var(--vh, 1vh) * 100 - ${theme.components.MuiAppBar.styleOverrides.root.minHeight}px)`}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 약관 및 정책`} />
      <AppBar position={'sticky'} elevation={0}>
        <Toolbar>
          <BackButton color="inherit" onClick={pageBackFunc} />
          <ToolbarTitle>약관 및 정책</ToolbarTitle>
        </Toolbar>
        <WoDivider />
      </AppBar>

      <Container component="main" maxWidth="xs">
        {/* 서비스 이용약관[start] */}
        <ListBox
          title="서비스 이용약관"
          onClick={() => {
            navigate(`/term/${TERM_TYPE.SERVICE}/latest`);
          }}
        />

        {/* 개인정보 처리방침[start] */}
        <ListBox
          title="개인정보 처리방침"
          onClick={() => {
            navigate(`/term/${TERM_TYPE.PERSONAL}/latest`);
          }}
        />

        {/* 전자금융거래 이용약관[start] */}
        {/* <ListBox */}
        {/*   title="전자금융거래 이용약관" */}
        {/*   onClick={() => { */}
        {/*     navigate(`/term/${TERM_TYPE.FINANCIAL}/latest`); */}
        {/*   }} */}
        {/* /> */}
      </Container>
    </Box>
  );
}

export default MyDocs;

// eslint-disable-next-line react/prop-types
function ListBox({ title, onClick }) {
  return (
    <Box my="10px">
      <ButtonBase sx={{ height: '50px', width: '100%' }} onClick={onClick}>
        <SpaceBetweenBox width="100%">
          <Typography fontSize={14} fontWeight="bold">
            {title}
          </Typography>
          <BtnMoreIcon />
        </SpaceBetweenBox>
      </ButtonBase>
    </Box>
  );
}
