import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project import
import { WoAlert } from 'utils/kmwine-alerts';
import { WINE_TYPES } from 'config';
import { ReviewHeartIcon } from 'components';
import { setReviewThreadLike } from 'services/ReviewThreadService';
import TasteDivider from '../../../../../mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';
import LikeButtonLottie from 'components/spinner/LikeButtonLottie';
import { ReviewThreadWriterSection, ReviewThreadImageSection } from './index';

// material-ui
import { Box, Button, ButtonBase, Divider, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/styles';

/**
 * 리뷰 쓰레드 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewThreadCard = ({
  review,
  detailPdataId,
  selectReview,
  handleThumbnailClickEvent,
  showBuyVendor = true,
  type = 'reviewThread',
  contents5Line = true
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // 더보기 버튼 표시 여부
  const [showMoreBtn, setShowMoreBtn] = React.useState(false);
  // 리뷰 내용 전체 보기
  const [showAllContents, setShowAllContents] = React.useState(false);
  // 접속한 유저의 리뷰 좋아요 플래그
  const [reviewLike, setReviewLike] = React.useState(review?.like_flag);
  // 리뷰 좋아요 수
  const [reviewLikeCnt, setReviewLikeCnt] = React.useState(review?.review_like_cnt);
  // 리뷰 좋아요 로딩
  const [reviewLikeLoading, setReviewLikeLoading] = React.useState(false);

  // 리뷰 상품 명
  const reviewProductName = React.useMemo(() => {
    if (!review || !review?.pdata_name?.ko) return <Skeleton variant="rectangular" height="16px" width="135px" />;

    const pdata = {
      id: review?.pdata_id,
      pdata_name: review?.pdata_name?.ko,
      product_name: review?.pdata_name?.ko
    };

    return (
      <Stack direction="row" alignItems="center" onClick={() => detailPdataId(pdata)}>
        <ProductName>{review.pdata_name.ko}</ProductName>
        <ChevronRightSharpIcon color="inherit" fontSize="inherit" sx={{ opacity: 0.75, height: 16, width: 16 }} />
      </Stack>
    );
  }, [review]);

  // 리뷰 상품 정보
  const reviewProductInfo = React.useMemo(() => {
    if (!review) return <Skeleton variant="rectangular" height="16px" width="100px" />;

    // 리뷰 상품 정보 배열
    const productInfoArray = [];

    // 빈티지 정보가 있을 경우
    if (review?.vintage) {
      productInfoArray.push(<ProductInfo>{review.vintage === 'NV' ? 'Non' : review.vintage} Vintage</ProductInfo>);
    }
    // 국가 정보가 있을 경우
    if (review?.pdata_country?.ko) {
      productInfoArray.push(<ProductInfo>{review.pdata_country.ko}</ProductInfo>);
    }
    // 품종 정보가 있을 경우
    if (review?.pdata_variety?.ko) {
      productInfoArray.push(<ProductInfo>{review.pdata_variety.ko}</ProductInfo>);
    }
    // 타입(RED, WHITE...) 정보가 있을 경우
    if (review?.pdata_type) {
      productInfoArray.push(<ProductInfo>{WINE_TYPES[review.pdata_type].label.ko}</ProductInfo>);
    }
    // vivino 평점이 있을 경우
    if (review?.pdata_score?.vivino) {
      productInfoArray.push(<ProductVivinoScore>VIVINO {review.pdata_score.vivino}</ProductVivinoScore>);
    }

    return (
      <Stack direction="row" divider={<CbDivider />} spacing="6px" display="flex" alignItems="center">
        {productInfoArray.map((item, index) => (
          <Box key={`pdata-info-${index}-box`}>{item}</Box>
        ))}
      </Stack>
    );
  }, [review]);

  // 리뷰 작성 영역 ref
  const reviewContentsRef = React.useRef();

  // 리뷰 내용이 5줄 이상일 경우 더보기 버튼 보이기
  React.useEffect(() => {
    if (reviewContentsRef?.current?.scrollHeight >= 120) setShowMoreBtn(true);
  }, [reviewContentsRef]);

  // 리뷰 좋아요 버튼 클릭 이벤트
  const clickReviewLikeHandler = async () => {
    const { review_id, pdata_id } = review;

    // 리뷰 아이디 또는 pdata 아이디가 존재하지 않음
    if (!review_id || !pdata_id) {
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return false;
    }

    // 이미 리뷰 좋아요 설정/해제 중
    if (reviewLikeLoading) {
      console.warn('[ReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 요청 중입니다.');
      return false;
    }

    setReviewLikeLoading(true);

    const body = {
      reviewId: review_id,
      pdataId: pdata_id
    };

    const result = await setReviewThreadLike(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setReviewLikeLoading(false);
      });

    // 리뷰 좋아요 설정/해제 중 오류 발생
    if (result.error) {
      console.error('[ReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 중 오류 발생', result.error);
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 반환됨
    if (code !== 0) {
      console.error('[ReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 중 오류 발생', msg);
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return undefined;
    }

    // 리뷰 좋아요 설정/해제 성공
    setReviewLike(!reviewLike);
    setReviewLikeCnt(reviewLike ? reviewLikeCnt - 1 : reviewLikeCnt + 1);
  };

  // 리뷰 썸네일 이미지 클릭 이벤트 콜백
  const handleThumbnailClick = (img, index) => {
    handleThumbnailClickEvent(img, index);
  };

  const reviewCardRef = React.useRef(null);

  // render
  return (
    <Box ref={reviewCardRef} width="100%" height="100%" position="relative">
      {/* 리뷰 신고/차단 블러 처리 */}
      {review?.blur ? (
        <Box
          sx={{
            mx: '-16px',
            bgcolor: 'rgba(0, 0, 0, 0.55)',
            position: 'absolute',
            width: 'calc(100% + 36px)',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box textAlign="center">
            {review?.blur_type === 'report' ? <ReportReviewIcon /> : <BlockReviewIcon />}
            <Typography sx={{ fontSize: 16, fontWeight: 800, lineHeight: 'normal', color: '#FFF', mt: '28px' }}>
              {review?.blur_type === 'report' ? '신고한 게시글입니다.' : '차단한 작성자의 게시글입니다.'}
            </Typography>
            <Typography sx={{ fontSize: 16, lineHeight: 'normal', color: '#FFF', mt: '12px', whiteSpace: 'pre-wrap' }}>
              {review?.blur_type === 'report'
                ? `신고한 게시글은\n더 이상 표시되지 않습니다.`
                : `차단한 작성자의 게시글은\n더 이상 표시되지 않습니다.`}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box bgcolor={theme.palette.background.paper} mx="-16px" px="20px">
        {/* 리뷰 작성자 정보 */}
        <ReviewThreadWriterSection review={review} selectReview={(type, review) => selectReview(type, review)} />

        {/* 리뷰 이미지 */}
        <ReviewThreadImageSection type={type} review={review} onThumbnailClick={handleThumbnailClick} />

        {/* 리뷰 상품 정보 */}
        {(type === 'reviewThread' || type === 'detail') && (
          <Box mt={2}>
            {reviewProductName}
            <Box mt={1}>{reviewProductInfo}</Box>
          </Box>
        )}

        {/* 리뷰 내용 */}
        <Box mt="20px">
          <Box pb={1}>
            {contents5Line ? (
              <ReviewContent more={showAllContents ? true : undefined} ref={reviewContentsRef}>
                {review?.review_content}
              </ReviewContent>
            ) : (
              <ReviewContent more>{review?.review_content}</ReviewContent>
            )}
          </Box>
          {/* 더보기 버튼 */}
          {contents5Line && !showAllContents && showMoreBtn ? (
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <ButtonBase onClick={() => setShowAllContents(true)}>
                <SubText>더보기</SubText>
                <ExpandMoreIcon sx={{ color: '#140229', opacity: 0.6 }} />
              </ButtonBase>
            </Stack>
          ) : null}
        </Box>

        {/* 리뷰 좋아요 수 */}
        <Stack mt="8px" mb={2} direction="row" spacing="4px" alignItems="center">
          <ButtonBase sx={{ width: 22, height: 22, borderRadius: '50%' }} onClick={clickReviewLikeHandler}>
            <Box position="relative" width="100%" height="100%">
              {/* 리뷰 좋아요 애니메이션 */}
              {reviewLikeLoading && !reviewLike ? <LikeButtonLottie height={40} left={-4} /> : null}
              <ReviewHeartIcon filled={reviewLike} width={22} height={22} />
            </Box>
          </ButtonBase>

          <SubText>
            <b>{reviewLikeCnt?.toLocaleString()}명</b>이 좋아합니다
          </SubText>
        </Stack>

        {/* 주문 가능 표시 여부 */}
        {showBuyVendor ? (
          <Box pb={2}>
            <Button
              onClick={() => navigate(`/mall/wine/${review.pdata_id}?pin=3`)}
              sx={{
                border: '1px solid rgba(34, 3, 72, 0.2)',
                width: '100%',
                py: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  color: 'rgba(34, 3, 72, 0.6)',
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal'
                }}
              >
                주문 가능 와인샵 보기
              </Typography>
              <ChevronRightSharpIcon fontSize="inherit" sx={{ color: 'rgba(34, 3, 72, 0.6)', opacity: 0.6, height: 20, width: 20 }} />
            </Button>
          </Box>
        ) : type === 'detail' ? null : (
          <Divider />
        )}
      </Box>
    </Box>
  );
};

export default ReviewThreadCard;

ReviewThreadCard.propTypes = {
  review: PropTypes.object.isRequired,
  detailPdataId: PropTypes.func,
  selectReview: PropTypes.func.isRequired,
  handleThumbnailClickEvent: PropTypes.func.isRequired,
  showBuyVendor: PropTypes.bool,
  type: PropTypes.string,
  contents5Line: PropTypes.bool
};

/**
 * 리뷰 상품명
 */
const ProductName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 800,
  fontStyle: 'normal',
  lineHeight: 'normal'
});

const CbDivider = React.memo(() => (
  <Box width="1px" height="10px" component="img" src={TasteDivider} alt="divider" sx={{ objectFit: 'cover', opacity: '.6' }} />
));

/**
 * 리뷰 상품 정보
 */
const ProductInfo = styled(Typography)({
  fontSize: '10px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: 'rgba(20, 2, 41, 0.7)'
});

/**
 * 리뷰 상품 비비노 점수
 */
const ProductVivinoScore = styled(Typography)({
  fontSize: '10px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: '#A11122'
});

/**
 * 리뷰 내용
 */
const ReviewContent = styled(Typography)(({ more }) => ({
  whiteSpace: 'pre-wrap',
  fontSize: '13px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '1.6',
  color: '#140229',
  wordBreak: 'break-word',
  ...(!more
    ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 5
      }
    : {})
}));

const SubText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: 'rgba(20, 2, 41, 0.7)'
});

// 신고 리뷰 아이콘
const ReportReviewIcon = () => {
  return (
    <SvgIcon sx={{ width: 60, height: 60, fill: 'none' }} viewBox="0 0 60 60">
      <path
        d="M29.9998 14.2102C34.1874 14.2102 38.2036 15.8737 41.1647 18.8348C44.1258 21.7959 45.7893 25.8121 45.7893 29.9997V42.6313H14.2103V29.9997C14.2103 25.8121 15.8739 21.7959 18.835 18.8348C21.7961 15.8737 25.8122 14.2102 29.9998 14.2102Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M12.6331 42.6313H47.3699C47.7887 42.6313 48.1903 42.7977 48.4864 43.0938C48.7825 43.3899 48.9489 43.7915 48.9489 44.2103V52.105H11.0541V44.2103C11.0541 43.7915 11.2205 43.3899 11.5166 43.0938C11.8127 42.7977 12.2143 42.6313 12.6331 42.6313Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M30.1579 33.3158C31.902 33.3158 33.3158 31.902 33.3158 30.1579C33.3158 28.4138 31.902 27 30.1579 27C28.4138 27 27 28.4138 27 30.1579C27 31.902 28.4138 33.3158 30.1579 33.3158Z"
        stroke="white"
        strokeWidth="3"
      />
      <path d="M31.5782 34.0789H28.4203V43.5525H31.5782V34.0789Z" fill="white" />
      <path d="M31.5782 3.15759H28.4203V9.47338H31.5782V3.15759Z" fill="white" />
      <path d="M17.1582 5.94962L14.4234 7.52856L17.5813 12.9982L20.3161 11.4193L17.1582 5.94962Z" fill="white" />
      <path d="M39.6859 11.4187L42.4207 12.9977L45.5786 7.52805L42.8438 5.9491L39.6859 11.4187Z" fill="white" />
    </SvgIcon>
  );
};

// 차단 리뷰 아이콘
const BlockReviewIcon = () => {
  return (
    <SvgIcon sx={{ width: 60, height: 60, fill: 'none' }} viewBox="0 0 60 60">
      <path
        d="M30.0003 52.1053C42.2087 52.1053 52.1056 42.2084 52.1056 30C52.1056 17.7916 42.2087 7.89478 30.0003 7.89478C17.7919 7.89478 7.89508 17.7916 7.89508 30C7.89508 42.2084 17.7919 52.1053 30.0003 52.1053Z"
        stroke="white"
        strokeWidth="3"
      />
      <path d="M14.2108 14.2106L45.7898 45.7895" stroke="white" strokeWidth="3" />
    </SvgIcon>
  );
};
