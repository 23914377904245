import React from 'react';
import PropTypes from 'prop-types';

// import projects
import SearchWrapper from './components/SearchWrapper';
import SearchInput from './components/SearchInput';
import SearchInputIcon from './components/SearchInputIcon';

function SearchBar({ fullWidth, width, wrapperProps, placeholder, onBlur, onChange, onFocus }) {
  return (
    <SearchWrapper fullWidth={fullWidth} width={width} wrapperProps={wrapperProps}>
      <SearchInputIcon />
      <SearchInput fullWidth={fullWidth} placeholder={placeholder} onBlur={onBlur} onChange={onChange} onFocus={onFocus} />
    </SearchWrapper>
  );
}
SearchBar.propTypes = {
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperProps: PropTypes.object,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
};
export default SearchBar;
