// third-party
import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../index';
import { DEV, IS_PRODUCTION, USER_LOCATION_TYPE } from 'config';
import * as UserService from '../../services/UserService';
import moment from 'moment';
import { logEvent } from 'firebase/analytics';
import { Timestamp } from 'firebase/firestore';
import { analytics } from '../../index';
import { WoAlert } from '../../utils/kmwine-alerts';

/* global kakao */

// 사용자 위치 initial state.
export const initialState = {
  loading: true, // 로딩여부
  type: null, // 사용자 위치타입. '현재위치: USER_LOCATION_TYPE.CURRENT', '핀: USER_LOCATION_TYPE.PIN'
  coord: null, // 위치 좌표
  currentLocation: null, // 현재 위치정보 [위치타입이 current일 경우]
  currentPin: null, // 현재 핀정보 [위치타입이 pin일 경우]
  saved: {
    locationList: [], // 내가 저장한 위치정보 리스트
    pinList: [], // 내가 저장한 pin 리스트
    lastCurrentCoord: null // '현재위치'로 저장된 마지막 좌표
  },
  lastUpdated: null
};

const slice = createSlice({
  name: 'user-location',
  initialState,
  reducers: {
    // Set loading state.
    setLoadingState(state, action) {
      state.loading = !!action.payload;
    },

    // 핀정보 수정
    setPinData(state, action) {
      console.log('[핀정보 수정] setPinData: ', action);
      state.type = USER_LOCATION_TYPE.PIN;
      state.coord = action.payload.coord;

      state.currentLocation = initialState.currentLocation; // 현재 위치정보 초기화
      state.currentPin = action.payload.pin; // 선택된 핀정보

      if (action.payload.pin?.updated_at) {
        state.lastUpdated = action.payload.pin.updated_at;
      } else {
        state.lastUpdated = Timestamp.fromDate(new Date()).toMillis();
      }
      state.loading = false;
    },

    // 현재 위치정보 저장
    setCurrentLocation(state, action) {
      const { latitude: lat, longitude: lng, address = '(주소를 알 수 없는 위치입니다)' } = action.payload;

      state.type = USER_LOCATION_TYPE.CURRENT;

      // 핀정보 초기화
      state.currentPin = initialState.currentPin;

      state.coord = { lat, lng };

      state.currentLocation = {
        coord: { lat, lng },
        address
      };

      state.saved = {
        lastCurrentCoord: { lat, lng, address }
      };

      state.lastUpdated = Timestamp.fromDate(new Date()).toMillis();
      state.loading = false;
    },

    // Reset user location.
    resetUserLocation(state) {
      /* eslint-disable no-unused-vars */
      state.loading = initialState.loading;
      state.type = initialState.type;
      state.coord = initialState.coord;
      state.currentLocation = initialState.currentLocation;
      state.currentPin = initialState.currentPin;
      state.lastUpdated = null;
      // state.saved = initialState.saved; // saved는 남겨놓음
      console.debug('사용자 위치정보가 초기화되었습니다.');
    }
  }
});

// Reducer
export default slice.reducer;

/**
 * 사용자 위치정보 수정요청
 * todo refactoring
 * @param type - 사용자 위치타입
 * @param locationData - 위치 또는 핀 정보
 */
export function updateUserLocation(type, locationData) {
  return async (dispatch, getState) => {
    console.debug('Start to update user location information.', type, locationData);
    dispatch(slice.actions.setLoadingState(true));

    const locationSaveResult = {
      success: true,
      code: 0,
      error: null
    };

    // 핀 변경일 경우 locationData = 핀 아이디
    if (type === USER_LOCATION_TYPE.PIN) {
      const response = await UserService.saveLocationPin(locationData).catch((err) => {
        console.error('[wineone] 사용자 위치핀 설정 중 오류가 발생했습니다.', err);
        dispatch(slice.actions.setLoadingState(false));
        return false;
      });
      if (!response) {
        dispatch(slice.actions.setLoadingState(false));
        WoAlert.fire(`위치 설정 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {});
        return false;
      }

      if (process.env.NODE_ENV === 'development') {
        console.log(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `위치핀 변경 요청결과.`, response);
      }
      const { data } = response;

      // 핀정보 변경 성공
      if (data.result.code === 0) {
        const { address, location, name, _id, updated_at: updatedAt } = response.data.data;

        // Google Analyitics. https://developers.google.com/analytics/devguides/collection/ga4/reference/events#join_group
        if (IS_PRODUCTION) {
          logEvent(analytics, 'join_group', {
            group_id: `pin-${_id}`
          });
        }

        const updated_at = new Timestamp(updatedAt._seconds, updatedAt._nanoseconds).toMillis();

        // 핀정보 수정
        await dispatch(
          slice.actions.setPinData({
            coord: {
              lat: location._latitude,
              lng: location._longitude
            },
            pin: {
              address,
              location: {
                latitude: location._latitude,
                longitude: location._longitude
              },
              name,
              pin_id: _id,
              updated_at
            }
          })
        );

        if (process.env.NODE_ENV === 'development') {
          console.log(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `위치핀 업데이트 결과`, response.data.data);
        }
      }
      // 핀정보 변경 실패
      else {
        locationSaveResult.success = false;
        locationSaveResult.code = data.result.code;
        locationSaveResult.error = data.result.msg;
      }
    }

    // 현재 위치로 사용자 정보 저장
    else if (type === USER_LOCATION_TYPE.CURRENT) {
      console.log('################### todo 현재 좌표로 사용자 위치정보를 수정할 것1.', type, locationData);
      const { latitude, longitude } = locationData;
      // todo 현재 주소 따오기
      const geocoder = new kakao.maps.services.Geocoder();
      const currentCooord = new kakao.maps.LatLng(latitude, longitude);

      geocoder.coord2Address(currentCooord.getLng(), currentCooord.getLat(), (result, status) => {
        console.log('--- result', result);
        console.log('--- status', status);
        let address = '(주소를 알 수 없는 위치입니다)';
        if (status === kakao.maps.services.Status.OK) {
          console.log('그런 너를 마주칠까 ' + result[0].address.address_name + '을 못가');
          address = result[0].address.address_name;
        }
        dispatch(slice.actions.setCurrentLocation({ latitude, longitude, address }));
      });
    } else {
      locationSaveResult.success = false;
      locationSaveResult.code = -1;
      locationSaveResult.error = `올바르지 않은 호출. [type=${type}]`;
    }

    await dispatch(slice.actions.setLoadingState(false));
    if (process.env.NODE_ENV === 'development') {
      console.log(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `사용자의 위치(type=${type})정보가 업데이트 되었습니다.`);
    }
    return locationSaveResult;
  };
}

export function setUserLocation(type, locationData) {
  return async (dispatch) => {
    // console.log('----------------------------------> type: ', type, locationData);
    // 핀 변경일 경우 locationData = 핀 아이디
    if (type === USER_LOCATION_TYPE.PIN) {
      console.log('사용자의 핀정보 state 업데이트');
      if (locationData === null || locationData === undefined) {
        // 핀정보 수정
        dispatch(
          slice.actions.setPinData({
            coord: null,
            pin: null
          })
        );
      } else {
        const { location, address, name, _id: pin_id, updated_at: updatedAt } = locationData;
        // 현재 좌표
        const coord = location ? { lat: location.latitude, lng: location.longitude } : null;
        // 핀
        const pin = pin_id
          ? {
              address,
              location,
              name,
              pin_id,
              updated_at: updatedAt?.toMillis() ?? moment().valueOf()
            }
          : null;

        // 핀정보 수정
        dispatch(slice.actions.setPinData({ coord, pin }));
      }

      dispatch(slice.actions.setLoadingState(false));
    }
    // 현재 위치로 변경
    else if (type === USER_LOCATION_TYPE.CURRENT) {
      console.log('사용자의 현재 위치정보 state 업데이트');
      // 사용자 위치정보 변경
      const {
        latitude, // 위도
        longitude // 경도
      } = locationData;

      // 현재 주소 따오기
      const geocoder = new kakao.maps.services.Geocoder();
      const currentCooord = new kakao.maps.LatLng(latitude, longitude);

      geocoder.coord2Address(currentCooord.getLng(), currentCooord.getLat(), (result, status) => {
        let address = '(주소를 알 수 없는 위치입니다)';
        if (status === kakao.maps.services.Status.OK) {
          console.log('좌표 주소: ' + result[0].address.address_name);
          address = result[0].address.address_name;
        }
        dispatch(slice.actions.setCurrentLocation({ latitude, longitude, address }));
      });
    } else {
      console.log('[1KMWINE] 위치정보가 없는 사용자 입니다.');
      // 핀정보 초기화
      dispatch(
        slice.actions.setPinData({
          coord: null,
          pin: null
        })
      );
      // todo 현재위치정보 초기화
      dispatch(slice.actions.setLoadingState(false));
    }
  };
}

/**
 * 사용자 위치정보 초기화
 * @returns {(function(): Promise<void>)|*}
 */
export function resetUserLocation() {
  return async () => {
    // console.log(`[1kmwine] 'user-location'의 state를 초기화했습니다.`);
    dispatch(slice.actions.resetUserLocation());
  };
}
