import axios from 'axios';
import { NEST_API_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';
/**
 * 리뷰 쓰레드 목록 조회
 */
export async function getReviewThreadList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__REVIEW_THREAD_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][getReviewThreadList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 쓰레드 목록 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getReviewThreadList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰 작성
 */
export async function createPdataReview(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__PDATA_REVIEW_CREATE, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][createPdataReview] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 작성 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `createPdataReview`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰 단일 조회
 */
export async function getReviewDetail(reviewId): Promise<any> {
  if (!reviewId) throw new Error('reviewId가 없습니다.');
  return axios
    .get(NEST_API_URL.GET__PDATA_REVIEW_DETAIL, { params: { id: reviewId } })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][getReviewDetail] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 단일 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getReviewDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰 수정
 */
export async function updateReview(body): Promise<any> {
  return axios
    .put(NEST_API_URL.PUT__UPDATE_PDATA_REVIEW, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][updateReview] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 수정 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `updateReview`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰 좋아요 설정/해제
 */
export async function setReviewThreadLike(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__REVIEW_THREAD_LIKE, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][setReviewThreadLike] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 좋아요 설정/해제 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `setReviewThreadLike`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰를 작성할 수 있는 pdata목록
 */
export async function getWriterReviewPdataList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__REVIEW_WRITE_PDATA_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][getWriterReviewPdataList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰를 작성할 수 있는 pdata목록 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getWriterReviewPdataList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 주문 상품 중 리뷰를 작성 안한 상품 개수 & 리뷰 작성 개수
 */
export async function getUserReviewCount(): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__PDATA_REVIEW_COUNT)
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][getUserReviewCount] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `주문 상품 중 리뷰를 작성 안한 상품 개수 & 리뷰 작성 개수 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getUserReviewCount`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 내가 작성한 리뷰 목록
 */
export function getMyWriteReviewList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__MY_REVIEW_WRITE_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][getMyWriteReviewList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `내가 작성한 리뷰 목록 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getMyWriteReviewList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 리뷰 신고
 */
export function createReviewReport(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__REVIEW_REPORT, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[review-thread.service.ts][createReviewReport] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `리뷰 신고하기 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `createReviewReport`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
