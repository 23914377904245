// action - state management
import { LOGIN, LOGOUT, REFRESH, REGISTER } from './actions';
import { logEvent, setUserId } from 'firebase/analytics';
import { analytics } from '../index';
import { DEV } from '../config';
import { getAuth } from 'firebase/auth';
import { amplitude } from 'index';

// ==============================|| ACCOUNT REDUCER ||============================== //

export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    // 로그인 처리
    case LOGIN: {
      const { user, isLoggedIn } = action.payload;
      const auth = getAuth();
      const { currentUser } = auth;

      // Google analytics: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
      try {
        if (currentUser.providerData.length > 0) {
          logEvent(analytics, 'login', {
            method: currentUser.providerData[0].providerId
          });
        } else {
          logEvent(analytics, 'login');
        }

        setUserId(analytics, currentUser.uid);
      } catch (e) {
        /* DO NOTHING */
      }

      // Amplitude user property
      setAmplitudeUserIdentity(user);

      return {
        ...state,
        isInitialized: true,
        isLoggedIn,
        user
      };
    }
    // 사용자 정보(user) 갱신
    case REFRESH: {
      const { user } = action.payload;

      console.groupCollapsed(`%c[accountReducer][REFRESH]`, DEV.CONSOLE.LABEL_STYLE, `로그인 사용자정보가 갱신되었습니다.`);
      console.log('member: ', user);
      console.groupEnd();

      // Amplitude user property
      setAmplitudeUserIdentity(user);

      return { ...state, user };
    }
    // 로그아웃
    case LOGOUT: {
      try {
        setUserId(analytics, null);

        try {
          // Amplitude logout
          amplitude.getInstance().setUserId(null);
          amplitude.getInstance().clearUserProperties();
          amplitude.getInstance().regenerateDeviceId();
        } catch (e) {
          console.warn(e);
        }
      } catch (e) {
        /* DO NOTHING */
      }

      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;

// Amplitude user property
const setAmplitudeUserIdentity = (member) => {
  try {
    const identify = new amplitude.Identify();

    // nickname
    identify.set('nickname', member.nickname);
    // gender
    identify.set('gender', member?.gender ?? 'none');
    // location
    if (member?.pin) {
      identify.set('location', member.pin.name);
    } else {
      identify.set('location', 'not defined');
    }
    // birth
    const { year, month, date } = member.birth;
    identify.set('birth', {
      year: Number(year),
      month: Number(month),
      date: Number(date)
    });

    amplitude.getInstance().identify(identify);
    // console.debug('[Amplitude] Set user identity');
  } catch (e) {
    console.error('Amplitude identity function error. ', e);
  }
};
