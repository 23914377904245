import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@mui/styles';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

const GRID_BORDER_RADIUS = '10px';

const useStyles = makeStyles({
  gridItem: {
    overflow: 'hidden',
    height: '94px',
    '&:first-child': {
      borderTopLeftRadius: GRID_BORDER_RADIUS,
      borderBottomLeftRadius: GRID_BORDER_RADIUS
    },
    '&:last-child': {
      borderTopRightRadius: GRID_BORDER_RADIUS,
      borderBottomRightRadius: GRID_BORDER_RADIUS
    }
  }
});

/**
 * 주문상태별 카운트 표현 Grid
 *
 * @param {array<{title, count}>} list
 * @returns {JSX.Element}
 * @constructor
 */
function CountGrid({ loading = true, list = [] }) {
  const classes = useStyles();
  const theme = useTheme();
  const BG_COLOR = useMemo(() => alpha(theme.palette.primary.main, 0.04), []);
  // console.log(list.length);
  return (
    <Grid container spacing={'2px'}>
      {!loading ? (
        list.length > 0 ? (
          list.map((countItem, i) => (
            <Grid key={`order-count-grid-item-${i}`} className={`${classes.gridItem}`} item xs={12 / list.length}>
              <GridContent title={countItem.title} count={countItem.count} />
            </Grid>
          ))
        ) : (
          <Grid className={`${classes.gridItem}`} item xs={12}>
            <Box height="100%" display="flex" justifyContent="center" alignItems="center" bgcolor={BG_COLOR}>
              <Typography variant="caption" color="error">
                [error] 주문 데이터가 없습니다.
              </Typography>
            </Box>
          </Grid>
        )
      ) : (
        <>
          <Grid className={`${classes.gridItem}`} item xs={12 / 3}>
            <Skeleton height="94px" variant="rectangular" />
          </Grid>
          <Grid className={`${classes.gridItem}`} item xs={12 / 3}>
            <Skeleton height="94px" variant="rectangular" />
          </Grid>
          <Grid className={`${classes.gridItem}`} item xs={12 / 3}>
            <Skeleton height="94px" variant="rectangular" />
          </Grid>
        </>
      )}
    </Grid>
  );
}

CountGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired
};

export default CountGrid;

/* eslint-disable react/prop-types */
function GridContent({ title, count = 0 }) {
  const theme = useTheme();
  return (
    <Box sx={{ bgcolor: alpha(theme.palette.primary.main, 0.04), p: '22px 10px' }}>
      <Stack sx={{ textAlign: 'center' }} spacing="4px">
        <Typography fontSize={24} fontWeight="bold" lineHeight="normal" letterSpacing="-0.24px">
          {count}
        </Typography>
        <Typography fontSize={14} fontWeight="normal" lineHeight="normal" letterSpacing="-0.14px" sx={{ pt: '4px' }}>
          {title}
        </Typography>
      </Stack>
    </Box>
  );
}
