import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMatch, useNavigate } from 'react-router-dom';

// project imports
import { useSelector } from 'store';
import { fetchAlarmList, removeAllAlarms } from 'store/slices/alarm';
import BackButton from 'components/buttons/BackButton';
import { WoDivider } from 'components/dialog';
import AlarmItem from './AlarmItem';
import SmallButton from '../../SmallButton';
import { WoAlert } from 'utils/kmwine-alerts';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress, Container, Divider, Drawer, Grow, Stack, SvgIcon, Typography } from '@mui/material';

/**
 * 알림 표현영역(drawer)
 *
 * @param {boolean} open 열림여부
 * @param {function} onClose
 * @returns {JSX.Element}
 * @constructor
 */
function AlarmDrawer({ open = false, onClose }) {
  const navigate = useNavigate();
  const alarm = useSelector((state) => state.alarm);
  const { initialized, loading, totalCount, alarms, done } = alarm;

  const globalDispatch = useDispatch();

  const paperRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const [paperRefCombined, setPaperRefCombined] = React.useState(false);

  const mallMateched = useMatch('/mall');

  // 로딩 표현
  const loadingComponent = React.useMemo(() => {
    return loading ? (
      <Box display="flex" justifyContent="center" mt="12px" minHeight="100px" pt="16px">
        <CircularProgress size={30} color="brand" />
      </Box>
    ) : null;
  }, [loading]);

  // 알림 없음
  const alarmNotExists = React.useMemo(() => {
    if (!initialized || loading) return null;
    if (alarms.current > 0 || alarms.length > 0) return null;

    return (
      <NoAlarmBox
        onClick={() => {
          // 주문하기 페이지로 넘어가기
          if (mallMateched) navigate(-1);
          else navigate('/mall');
        }}
      />
    );
  }, [initialized, loading, totalCount, alarms]);

  // 알림 목록
  const alarmList = React.useMemo(() => {
    if (alarms.current === 1 && loading) return null;
    if (!loading && alarms.length === 0) return null;

    return (
      <Stack direction="column" divider={<Divider />} className="my-alarm-box">
        {alarms.map((alarm, i) => {
          // console.log(`[Alarms] ${i} = `, alarm);
          return (
            <AlarmItem
              key={`user-alarm-${i}-${alarm.id}`}
              alarm={alarm}
              index={i}
              onDelete={() => {
                console.log(`Alarm-${i}가 알람에서 제외되었습니다.`);
              }}
            />
          );
        })}
      </Stack>
    );
  }, [alarms, initialized, loading]);

  // React.useEffect(() => {
  //   globalDispatch(fetchAlarmList(1));
  // }, [initialized]);

  // page scroll (Infinite scroll paging)
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = (e) => {
    const { scrollTop, offsetHeight } = e.currentTarget;

    if (y < scrollTop) {
      if (scrollTop + offsetHeight >= containerRef.current.offsetHeight - 180) {
        if (alarm.done) {
          console.info('[App Alarm] 모든 알림을 조회했습니다.');
          return false;
        }
        if (alarm.loading) {
          console.log('[App Alarm] 이미 알림을 조회 중 입니다.');
          return false;
        }

        console.debug(`[App Alarm] 다음 페이지 호출 (${alarm.current + 1})`);
        globalDispatch(fetchAlarmList(alarm.current + 1));
      }
    }
    setY(paperRef.current.scrollTop);
  };

  React.useEffect(() => {
    if (paperRefCombined) {
      setY(paperRef.current.scrollTop);
      paperRef.current.addEventListener('scroll', handleScrollEvent);
    }

    return () => {
      try {
        paperRef.current.removeEventListener('scroll', handleScrollEvent);
      } catch (e) {
        /* DO NOTHING */
      }
    };
  }, [paperRefCombined, alarm]);

  // 알림 전체삭제
  const removeAll = async () => {
    console.debug(`알림 '전체 삭제' 버튼 눌림.`);
    WoAlert.fire({
      title: '알림 삭제',
      html: `<div style='line-height: 1.5rem; text-align: left'>전체삭제 하시겠습니까?</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      console.log('--- result: ', result);
      if (result.isConfirmed) {
        globalDispatch(removeAllAlarms());
      }
    });
  };

  // render
  return (
    <Drawer
      anchor="right"
      open={open}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        ref(ref) {
          paperRef.current = ref;
          setPaperRefCombined(true);
        }
      }}
    >
      <Box position="fixed" top={0} left={0} right={0}>
        <DrawerHeader>
          <BackButton color="inherit" onClick={onClose} />
          <DrawerTitle>알림</DrawerTitle>
          <Box flexGrow={1} />

          <Grow in={!alarmNotExists}>
            <Box>
              <SmallButton onClick={() => removeAll()} sx={{ lineHeight: 'normal' }}>
                전체 삭제
              </SmallButton>
            </Box>
          </Grow>
        </DrawerHeader>
        <WoDivider />
      </Box>

      {/* 알림이 없을 경우 */}
      {alarmNotExists}

      {/* 알람 영역 */}
      <Container
        ref={containerRef}
        id="alarm-list-container"
        sx={{ pt: '56px', width: '100vw', pb: alarms.length > 0 && done ? '180px' : 0 }}
      >
        {alarmList}
      </Container>

      {/* 로딩하는 모양 */}
      {loadingComponent}
    </Drawer>
  );
}

export default AlarmDrawer;

AlarmDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

// Drawer header.
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, '20px'),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

// Drawer title.
const DrawerTitle = styled((props) => (
  <Typography
    variant="div"
    noWrap
    lineHeight={1}
    fontSize="18px"
    fontWeight={800}
    letterSpacing="-0.18px"
    color="text.primary"
    {...props}
  />
))({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

/* eslint-disable react/prop-types */
function NoAlarmIcon({ height = 86, width = 86, color = '#e5dcef' }) {
  return (
    <SvgIcon htmlColor={color} viewBox="0 0 86 86" sx={{ width, height }}>
      <g style={{ stroke: 'currentcolor', strokeWidth: '4px', fill: 'none' }}>
        <path d="M25 0a25 25 0 0 1 25 25v29H0V25A25 25 0 0 1 25 0z" style={{ stroke: 'none' }} transform="translate(18 14)" />
        <path d="M25 2a23 23 0 0 1 23 23v27H2V25A23 23 0 0 1 25 2z" style={{ fill: 'none' }} transform="translate(18 14)" />
      </g>
      <path transform="translate(12 64)" style={{ fill: 'currentcolor' }} d="M0 0h62v4H0z" />
      <path transform="translate(39 73)" style={{ fill: 'currentcolor' }} d="M0 0h8v4H0z" />
      <path transform="translate(41 9)" style={{ fill: 'currentcolor' }} d="M0 0h4v6H0z" />
    </SvgIcon>
  );
}

/**
 * 알림없음 표현영역
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
/* eslint-disable react/prop-types */
const NoAlarmBox = ({ onClick }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt="80px">
    <NoAlarmIcon />
    <Box textAlign="center" mt="60px">
      <Typography fontSize={18} fontWeight="bold">
        새로운 알림이 없습니다.
      </Typography>
      <Typography fontSize={18} fontWeight={300}>
        지금 와인을 주문 해보세요.
      </Typography>
    </Box>

    <Container sx={{ position: 'fixed', bottom: '40px' }}>
      {/* todo iOS safety area bottom */}
      <Button
        fullWidth
        variant="contained"
        color="brand"
        size="large"
        sx={{ height: 50, fontSize: 17, fontWeight: 'bold', letterSpacing: '-0.17px' }}
        onClick={onClick}
      >
        와인 주문하기
      </Button>
    </Container>
  </Box>
);
