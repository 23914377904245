import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';

// project imports
import { CLO_CODE } from 'config';
import { useAuth, useScriptRef } from 'hooks';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { WoAlert } from 'utils/kmwine-alerts';
import BackHeader from 'layouts/Main/BackHeader';
import { getRealTimeOrderList } from '../../../services/ContentService';
import { OrderProductTimelineItem } from './components';

// material-ui
import { Box, CircularProgress, Container, Fade } from '@mui/material';
import { Timeline } from '@mui/lab';

const LIVE_ITEM_SIZE = 20; // 한번에 보여줄 아이템 수

const initialState = { loaded: false, loading: false, page: { current: 1, size: LIVE_ITEM_SIZE }, items: [] };

/**
 * 실시간 주문목록 화면
 * @constructor
 */
function RealtimeOrders() {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const location = useLocation();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // 주문상품 목록
  const [orderProductList, setOrderProductList] = React.useState(initialState);

  React.useEffect(() => {
    console.log('실시간 주문정보 조회');
    fetchRealTimeOrderList(1).then(() => {
      console.log('실시간 주문정보 조회');
    });
  }, []);

  /** 주문상품 표현하기 */
  const fetchRealTimeOrderList = async (current) => {
    setOrderProductList((list) => ({ ...list, items: current === 1 ? [] : orderProductList.items, loading: true }));

    const response = await getRealTimeOrderList({
      current,
      size: LIVE_ITEM_SIZE
    }).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `실시간 주문정보 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn(' Unmounted component.');
      return;
    }

    // 실시간 주문정보 조회 오류
    if (response.error) {
      setOrderProductList((list) => ({ ...list, loading: false }));
      await WoAlert.fire(`실시간 주문정보 조회 중\n오류가 발생했어요.`, '', 'warning').then(() => {
        navigate(-1);
      });
    }

    const {
      products, // 상품리스트
      page, // 페이지 정보
      result // 처리결과
    } = response;

    // 리스트 조회 성공
    if (result.code === 0) {
      setOrderProductList((oldList) => {
        const items = current === 1 ? products : [...oldList.items, ...products];
        return { loaded: true, loading: false, page, items };
      });
    } else {
      console.error(`실시간 주문목록 조회 중 오류`, result);
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `실시간 주문목록  조회 오류#2`,
        msg: `[uid=${user._id}] ${JSON.stringify(result)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error: result }
      })
        .then(console.log)
        .catch(console.error);
    }
  };

  // // 스크롤 디렉션
  // const [y, setY] = React.useState(window.scrollY);
  //
  // const handleScrollEvent = React.useCallback(
  //   (e) => {
  //     const window = e.currentTarget;
  //     // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
  //     if (y < window.scrollY) {
  //       // 스크롤이 바닥에 거의(-200) 닿았을 때
  //       if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 200) {
  //         if (!orderProductList.loaded) return; // 최초 조회시 중복요청 제외
  //         if (orderProductList.loading) return; // 페이지 요청 중일 경우 pass
  //
  //         // 다음 페이지 호출
  //         if (orderProductList.page.current < orderProductList.page.total_pages) {
  //           console.log(`[RealtimeOrderIndex] 다음 페이지 호출 (${orderProductList.page.current + 1}) `);
  //
  //           fetchRealTimeOrderList(orderProductList.page.current + 1);
  //         }
  //         // 이미 검색결과를 모두 보여준 상태
  //         else {
  //           console.log('[1KMWINE] 모든 실시간 주문정보 리스트를 출력했습니다.');
  //         }
  //       }
  //     }
  //     setY(window.scrollY);
  //   },
  //   [y, orderProductList.loading]
  // );

  // // 스크롤 감시
  // React.useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener('scroll', handleScrollEvent);
  //
  //   return () => {
  //     window.removeEventListener('scroll', handleScrollEvent);
  //   };
  // }, [handleScrollEvent]);

  // render
  return (
    <BackHeader title="실시간 구매 현황" showFooter={false} showBackButton={true} showBottomNav={false}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 실시간 구매 현황`} />

      <Container maxWidth="xs" sx={{ minHeight: 'calc(var(--vh, 1vh) * 100)', bgcolor: '#f1f0f2', overflow: 'hidden' }}>
        <Box>
          {orderProductList.loaded && orderProductList.items.length && (
            <Timeline
              position="right"
              sx={{
                p: 0,
                m: 0,
                '& .Km-TimeLineContent-Box:last-child': {
                  marginBottom: '120px'
                }
              }}
            >
              {orderProductList.items.map((orderProduct, i) => (
                <Fade in style={{ transitionDelay: `${i * 50}ms` }} key={`realtime-order-item-${i}`}>
                  <Box className="Km-TimeLineContent-Box">
                    <OrderProductTimelineItem orderProduct={orderProduct} />
                  </Box>
                </Fade>
              ))}
            </Timeline>
          )}
        </Box>

        {/* 리스트 로딩 표현 */}
        <Fade in={orderProductList.loading}>
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} color="brand" />
          </Box>
        </Fade>
      </Container>
    </BackHeader>
  );
}

export default RealtimeOrders;
