import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useLocationState } from 'react-router-use-location-state';
import { httpsCallable } from 'firebase/functions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

// project imports
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';
import { useScriptRef, useWineOne } from 'hooks';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { BtnMoreIcon } from 'components/icons';
import { fetchMyCouponDownloadList } from '../../../services/UserService';
import { CouponTermDialog, Coupon } from './components';
import BackHeader from '../../../layouts/Main/BackHeader';

// material-ui
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { WoAlert } from '../../../utils/kmwine-alerts';

const initialCouponState = Object.freeze({
  loaded: false,
  loading: false,
  coupons: [],
  error: false
});

/**
 * 나의 쿠폰 페이지
 *
 * @authors hkcho<hkcho@wineone.io>
 */
function MyCoupon() {
  const navigate = useNavigate();
  const { onNativeBackPressed } = useWineOne();
  const location = useLocation();
  const scriptedRef = useScriptRef();

  // 쿠폰 등록모달 열림여부
  const [cpnRegModalOpen, setCpnRegModalOpen] = React.useState(false);

  const openCouponRegModal = () => {
    setCpnRegModalOpen(true);
  };

  // 사용자 보유쿠폰 정보
  const [myCoupons, setMyCoupons] = useLocationState('myCoupons', {
    loaded: false,
    error: false,
    coupons: []
  });

  // 사용자 보유쿠폰 목록 조회
  const fetchMyCoupons = React.useCallback(async () => {
    setMyCoupons({
      loaded: false,
      error: false,
      coupons: []
    });
    // 사용자 보유쿠폰 목록 조회
    const result = await fetchMyCouponDownloadList();
    if (!scriptedRef.current) {
      console.warn('[MyCoupon][fetchMyCoupons] Unmounted component.');
      return;
    }

    console.log('# 로그인 사용자 보유쿠폰 목록조회 결과', result);
    if (result.result.code !== 0) {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `사용자 보유쿠폰 목록 조회 실패`,
        msg: `[${result.result.msg}`,
        which: `${window.location.pathname}${window.location.search}`
      })
        .then(console.log)
        .catch(console.error);
      return;
    }

    setMyCoupons({ loaded: true, error: false, coupons: result.data });
  }, []);

  React.useEffect(() => {
    fetchMyCoupons();
  }, []);

  // 쿠폰 발급 및 사용 안내 dialog 오픈여부
  const [termDialogOpen, setTermDialogOpen] = useLocationState('couponTermOpen', false);

  // 쿠폰발급안내 dialog 열기. pushState
  const handleTermDialogOpen = () => {
    setTermDialogOpen(true, { method: 'push' });
  };

  // 쿠폰발급안내 dialog 닫기
  const handleTermDialogClose = () => {
    /*
     * pushState를 사용하기 때문에 dialog를 닫기 위해서는 popState를 해야한다
     */
    // setTermDialogOpen(false);
    navigate(-1);
  };

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`쿠폰 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    if (termDialogOpen) {
      handleTermDialogClose();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location, termDialogOpen]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [location, termDialogOpen, pageBackFunc]);

  const cpnRegFormik = useFormik({
    initialValues: { code: '' },
    validationSchema: yup.object({
      code: yup.string().trim().required('쿠폰코드를 입력해주세요').min(4, '쿠폰코드를 4자리 이상 입력해주세요.')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const result = await axios
        .post(`${process.env.REACT_APP_API_SERVER_HOST}/member/coupon/code/${values.code.toUpperCase()}`)
        .catch((error) => {
          if (error.message !== 'Network Error') {
            httpsCallable(
              getFirebaseFunctions(),
              'call-cdm-clo-error'
            )({
              code: CLO_CODE.UNEXPECTED_ERROR,
              title: `코드로 쿠폰 다운로드 실패`,
              msg: error.message ?? 'Unexpected Error',
              which: `${window?.location?.href}`,
              param: { coupon_code: values.code }
            })
              .then(console.log)
              .catch(console.error);
          }
          return { error };
        });

      if (!scriptedRef.current) {
        console.warn('[MyCoupon][cpnRegFormik.onSubmit] Unmounted component.');
        return;
      }
      setSubmitting(false);

      if (result.error) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `코드로 쿠폰 다운로드 실패`,
          msg: result.error ?? 'Unexpected Error',
          which: `${window?.location?.href}`,
          param: { coupon_code: values.code }
        })
          .then(console.log)
          .catch(console.error);
        await WoAlert.fire('쿠폰등록 실패', '알 수 없는 오류', 'error');
        return;
      }

      if (result.data?.code !== 0) {
        let msg = '알 수 없는 오류';
        if (result.data.code === 1) msg = '이미 발급받은 쿠폰입니다.';
        if (result.data.code === 2) msg = '쿠폰 발급대상이 아닙니다';
        if (result.data.code === 1000) msg = '쿠폰코드가 올바르지 않습니다.';

        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `코드로 쿠폰 다운로드 실패`,
          msg: msg ?? 'Unexpected Error',
          which: `${window?.location?.href}`,
          param: { coupon_code: values.code }
        })
          .then(console.log)
          .catch(console.error);

        await WoAlert.fire('쿠폰등록 실패', msg, 'error');
        return;
      }

      if (result.data.code === 0) {
        await WoAlert.fire({
          title: '쿠폰 등록 완료',
          html: '쿠폰이 등록되었습니다.',
          customClass: { confirmButton: 'max-100' }
        }).then(async () => {
          await fetchMyCoupons();

          cpnRegFormik.resetForm();
          setCpnRegModalOpen(false);

          return undefined;
        });
      }
    }
  });

  // render
  return (
    <>
      <BackHeader title="쿠폰" onBackButtonClick={pageBackFunc}>
        <HelmetHeader />

        <Container maxWidth="xs" sx={{ minHeight: 'calc(var(--vh, 1vh) * 100)', px: [2, 2, 2, 2] }}>
          <Box mt={3}>
            <Button
              fullWidth
              variant="contained"
              size="x-large"
              color="brand"
              type="button"
              onClick={openCouponRegModal}
              sx={{ fontWeight: 800 }}
            >
              쿠폰 등록
            </Button>
          </Box>
          <Box sx={{ pb: 3 }}>
            {!myCoupons.loaded ? (
              <Box minHeight={300} display="flex" alignItems={'center'} justifyContent="center">
                <Typography variant="caption" fontSize={14} sx={{ opacity: 0.7 }}>
                  쿠폰 정보를 가져오고 있습니다.
                </Typography>
              </Box>
            ) : myCoupons.coupons.length > 0 ? (
              <>
                <SpaceBetweenBox px="10px" height={66} display="flex" alignItems="center">
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={12} fontWeight={900} marginRight="5px">
                      보유 쿠폰
                    </Typography>
                    <Typography fontSize={12} fontWeight={900} color="#9357E5">
                      {myCoupons.coupons.length.toLocaleString()}
                    </Typography>
                  </Box>

                  <ButtonBase onClick={() => handleTermDialogOpen('paper')} sx={{ color: '#140229', opacity: '.45' }}>
                    <Typography fontSize={12}>쿠폰 사용 안내</Typography>
                    <BtnMoreIcon />
                  </ButtonBase>
                </SpaceBetweenBox>

                {/* 보유 쿠폰 목록 */}
                <Stack spacing={2}>
                  {myCoupons.coupons.map((data) => {
                    return <Coupon key={`my-coupon-${data.coupon.id}`} data={data} />;
                  })}
                </Stack>
              </>
            ) : (
              <Box minHeight={200} display="flex" alignItems={'center'} justifyContent="center">
                <Typography variant="caption" fontSize={14} sx={{ opacity: 0.7 }}>
                  보유 쿠폰이 없습니다.
                </Typography>
              </Box>
            )}
          </Box>

          {/* 쿠폰 발금 및 사용 안내 */}
          <CouponTermDialog open={termDialogOpen} handleTermDialogClose={handleTermDialogClose} />
        </Container>
      </BackHeader>

      {/* 쿠폰 등록 Dialog */}
      <Dialog
        open={cpnRegModalOpen}
        fullWidth
        maxWidth="xs"
        keepMounted={false}
        PaperProps={{
          component: 'form',
          onReset: cpnRegFormik.handleReset,
          onSubmit: cpnRegFormik.handleSubmit
        }}
      >
        <DialogTitle>쿠폰 등록</DialogTitle>
        <DialogContent sx={{ mb: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            name="code"
            value={cpnRegFormik.values.code?.toUpperCase() ?? ''}
            error={cpnRegFormik.touched.code && Boolean(cpnRegFormik.errors.code)}
            helperText={cpnRegFormik.touched.code && cpnRegFormik.errors.code}
            onChange={cpnRegFormik.handleChange}
            onBlur={cpnRegFormik.handleBlur}
            label="쿠폰 코드"
            type="text"
            fullWidth
            variant="outlined"
            InputProps={{ inputProps: { maxLength: 12, enterKeyHint: 'send' } }}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="reset"
                size="large"
                color="gray"
                variant="outlined"
                onClick={() => {
                  cpnRegFormik.resetForm();
                  setCpnRegModalOpen(false);
                }}
              >
                닫기
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton loading={cpnRegFormik.isSubmitting} fullWidth type="submit" size="large" color="brand" variant="contained">
                등록
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MyCoupon;

const couponWingCont = (
  <Box>
    <Typography fontSize={11} fontWeight={800} sx={{ transform: 'rotate(-90deg)' }}>
      COUPON
    </Typography>
  </Box>
);

/**
 * 쿠폰 발급 및 사용 안내 버튼
 */
const CouponTermButton = React.memo(({ onClick }) => {
  const theme = useTheme();

  return (
    <Box py={1}>
      <ButtonBase onClick={onClick} sx={{ height: theme.height.listButtonHeight, width: '100%' }}>
        <Typography fontSize={14} fontWeight="bold">
          쿠폰 발급 및 사용 안내
        </Typography>
        <BtnMoreIcon />
      </ButtonBase>
    </Box>
  );
});

CouponTermButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

const HelmetHeader = React.memo(() => <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 쿠폰`} />);
