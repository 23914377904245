import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card, CardContent, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { alpha } from '@mui/material/styles';

/**
 * 환불안내
 * @authors 최효근<hkchoi@wineone.io>
 */
const PromotionRefundInfo = ({ pickupState }) => {
  return (
    <Card elevation={0}>
      <CardContent>
        {pickupState === 'none' ? (
          <Stack spacing="16px">
            <RefundInfoContent>
              예약 주문 상품은 프로모션 종료 후 픽업샵으로 상품이 배송 시작되어 <strong>주문 마감 후에는 취소/반품이 불가합니다.</strong>
            </RefundInfoContent>
            <RefundInfoContent>픽업 시에 성인 인증을 위한 신분증 확인이 진행되니, 꼭 신분증을 챙겨주세요!</RefundInfoContent>
          </Stack>
        ) : (
          <Stack spacing="16px">
            <RefundInfoContent>
              일시 품절 등의 사유로 주문이 불가한 상품은 부분 취소 처리되며, 결제하신 수단으로 자동 환불됩니다.
            </RefundInfoContent>
            <RefundInfoContent>
              픽업 완료 후 7일 이내 교환 및 반품이 가능하며, 세트 상품의 경우 개별 교환/반품이 불가합니다.
            </RefundInfoContent>
            <RefundInfoContent>픽업 시에 성인 인증을 위한 신분증 확인이 진행되니, 꼭 신분증을 챙겨주세요!</RefundInfoContent>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default PromotionRefundInfo;

PromotionRefundInfo.propTypes = {
  pickupState: PropTypes.string
};

const infoColor = alpha('#140229', 0.7);

const RefundInfoContent = React.memo(({ children }) => (
  <Box fontSize="12px" lineHeight="16px" width="100%" display="flex" color={infoColor}>
    <Box>
      <CheckIcon fontSize="12px" color={infoColor} />
    </Box>
    <Box pl="8px">{children}</Box>
  </Box>
));
RefundInfoContent.propTypes = {
  children: PropTypes.any
};
