export { default } from './TodayOneBottle';

// 오늘의 상품 state
export const TOB = Object.freeze({
  // display state (화면 표시 상태)
  DISPLAY: {
    /** 숨김 */
    HIDE: 'HIDE',
    /** 전시 대기 */
    WAIT: 'WAIT',
    /** 전시 중 */
    ING: 'ING',
    /** 종료 */
    END: 'END'
  },
  // sale state (판매 상태)
  SALE: {
    /** 오픈 예정 */
    WAIT: 'WAIT',
    /** 오픈 */
    ING: 'ING',
    /** 종료 */
    END: 'END'
  },
  // code state (오늘의 한 병 상태 체크 코드)
  CODE: {
    /** 판매시간 종료 */
    END_SALE: 'END_SALE',
    /** 인당 수량 구매 초과 */
    SALE_OVER: 'SALE_OVER',
    /** 매진 */
    SOLD_OUT: 'SOLD_OUT',
    /** 관심샵 미등록 */
    NOT_FVR: 'NOT_FVR',
    /** 아무 문제 없음 */
    OK: 'OK',
    /** 관심샵 등록 완료 */
    ADD_FVR: 'ADD_FVR'
  }
});
