import axios from 'axios';
import { FIREBASE_FUNCITON_URL, NODE_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';

/**
 * 주문정보 생성
 * @param orderParam 주문정보
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function createOrder(orderParam) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__CREATE_ORDER, { ...orderParam });
}

/**
 * 서비스 이용 수수료 계산
 * @deprecated
 * @param {Array} products
 * @param cancelToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function calculateUserCommission(products, cancelToken = null) {
  const token = cancelToken !== null ? cancelToken : axios.CancelToken.source().token;
  return axios.post(FIREBASE_FUNCITON_URL.POST__FETCH_USERCOMMISSION, { ...products }, { cancelToken: token });
}

/**
 * 로그인 사용자의 주문상태별 주문건수 조회
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function fetchOrderCountByStatus() {
  return axios.get(NODE_URL.GET__ORDER_COUNT_BY_STATUS);
}

/**
 * 로그인 사용자의 주문목록 조회
 * @param current
 * @param size
 * @param searchQuery
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function fetchOrderList(current, size, searchQuery) {
  return axios.get(NODE_URL.GET__ORDER_LIST, { params: { current, size, sq: searchQuery } });
}

export async function fetchOrderDetail(oid) {
  return axios.get(NODE_URL.GET__ORDER_DETAIL.replace('#{oid}', oid));
}

/**
 * 환불 서비스 이용 수수료 계산
 * @param {string} oid - 주문번호
 * @param {Array<string>} order_product_ids - 반품대상상품(order_product.id)
 * @param {null | CancelToken} cancelToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function calculateRefundCommission(oid, order_product_ids, cancelToken = null) {
  const token = cancelToken !== null ? cancelToken : axios.CancelToken.source().token;
  return axios.post(FIREBASE_FUNCITON_URL.POST__PROCESS_REFUND_PREVIEW, { oid, order_product_ids }, { cancelToken: token });
}

/**
 * 주문상품 구매확정 처리
 * @param {string} oid - 주문번호
 * @param {array<string>} orderItemIds - 주문 상품 아이디 배열
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function confirmOrderItem(oid, orderItemIds) {
  return axios.post(FIREBASE_FUNCITON_URL.POST_PROCESS_CONFIRM, { oid, order_product_ids: orderItemIds });
}

/**
 * 주문취소 요청
 * @param {string} oid - 주문번호
 * @param {string} cancelType - 취소타입 (미사용)
 * @param {string} msg - 주문취소 메시지(미사용)
 * @returns {Promise<void>}
 */
export async function requestOrderCancel(oid, cancelType, msg) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__ORDER_CANCEL, { oid, cancelType, msg });
}

/**
 * 픽업일시 변경
 * @param {string} oid - 주문번호
 * @param {string} pickup_date - 변경된 픽업날짜(YYYY-MM-DD)
 * @param {string} pickup_time - 변경된 픽업시간(HH:mm)
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function requestChangePickDate(oid, pickup_date, pickup_time) {
  return axios.post(FIREBASE_FUNCITON_URL.POST_CHANGE_PICKUP_DATE, { oid, pickup_date, pickup_time });
}

/**
 * 주문상품 교환요청
 * @param {string} oid - 주문번호
 * @param {array<string>} order_product_ids - 교환대상 상품 id 배열
 * @param {string} msg - 사유
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function requestExchange(oid, order_product_ids, msg) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PROCESS_EXCHANGE, { oid, order_product_ids, msg });
}

/**
 * 주문상품 반품요청
 * @param {string} oid - 주문번호
 * @param {array<string>} order_product_ids - 반품대상 상품 id 배열
 * @param {string} msg - 사유
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function requestRefund(oid, order_product_ids, msg) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PROCESS_REFUND, { oid, order_product_ids, msg });
}

export async function requestExcRtn(type, oid, order_product_ids, msg) {
  // 교환 요청
  if (type === 'exchange') return requestExchange(oid, order_product_ids, msg);
  // 반품 요청
  if (type === 'refund') return requestRefund(oid, order_product_ids, msg);
  throw new Error(`'type' have to 'exchange' or 'refund'. [type=${type}]`, { reason: 'invalid_type' });
}

/**
 * 교환신청 취소
 * @param {string} oid - 주문번호
 * @param {array<string>} order_product_ids - 교환신청 취소상품 id 배열
 * @param {string} msg - 사유
 * @returns {Promise<AxiosResponse<any>>}
 */
export function cancelExchangeRequest(oid, order_product_ids, msg) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PROCESS_EXCHANGE_CANCEL, { oid, order_product_ids, msg });
}

/**
 * 반품신청 취소
 * @param {string} oid - 주문번호
 * @param {array<string>} order_product_ids - 반품신청 취소상품 id 배열
 * @param {string} msg - 사유
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function cancelRefundRequest(oid, order_product_ids, msg) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PROCESS_REFUND_CANCEL, { oid, order_product_ids, msg });
}

/**
 * 사용 가능한 쿠폰목록 조회
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getAvailableCouponList(vendorId, orderItems) {
  return axios
    .post(NODE_URL.POST__AVAILABLE_COUPON, { vendor_id: vendorId, products: orderItems })
    .then((response) => {
      if (response.data.result.code !== 0) {
        throw new Error(response.data.result.msg);
      }
      return response.data;
    })
    .catch((error) => {
      console.error('사용가능한 쿠폰목록 조회 중 오류', error);
      if (process.env.NODE_ENV === 'production') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[1KMWINE] 사용가능한 쿠폰목록 조회 중 오류`,
          msg: `${error.message}`,
          which: `${window.location.pathname}${window.location.search}`
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
