import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';

// project imports
import { useAmplitude, useAuth, useScriptRef } from 'hooks';
import { VendorAddress } from './components';
import { SpaceBetweenBox } from 'components';
import { VendorImage } from 'components/v2';
import { addFvrVendor, deleteFvrVendor } from '../../../../../services/VendorService';
import { openSnackbar } from '../../../../../store/slices/snackbar';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../../../../utils/firebase-tools';
import { CLO_CODE } from '../../../../../config';

import { WoAlert } from '../../../../../utils/kmwine-alerts';

// material-ui
import { alpha, styled } from '@mui/material/styles';
import { Box, ButtonBase, Card, CardContent, Chip, Grid, Typography } from '@mui/material';

// assets imports
import vendorFvrOff from 'assets/images/ic_vendor_fvr_off.png';
import vendorFvrOn from 'assets/images/ic_vendor_fvr_on.png';
import DiscountChipIcon from './components/assets/ico_shop_list_discount.png';
import { makeStyles } from '@mui/styles';

const vendorImgWidth = 90;
const vendorImgHeight = 90;

/**
 * 입점샵 카드
 *
 * @param vendor
 * @param fetchVendorCnt
 * @param onVendorQrCouponDownloaded
 * @param tabIndex
 * @returns {JSX.Element}
 * @authors 최효근<hkchoi@wineone.io>
 */
function VendorCard({ vendor, fetchVendorCnt, onVendorQrCouponDownloaded, tabIndex }) {
  const navigate = useNavigate();
  const location = useLocation();
  const scriptedRef = useScriptRef();
  const globalDispatch = useDispatch();
  const classes = useStyles();

  const { logEvent } = useAmplitude();

  // 사용자 위치정보
  const { userLocation } = useSelector((state) => state);

  // 로그인 사용자 정보
  const { user } = useAuth();

  // tag 요소 ref
  const parentRef = React.useRef(null);
  // 보여지는 tag길이
  const [shownElementsWidth, setShownElementsWidth] = React.useState(0);
  // 숨겨진 tag
  const [hiddenElements, setHiddenElements] = React.useState([]);

  // 관심샵 toggle loading
  const [fvrToggleLoading, setFvrToggleLoading] = React.useState(false);

  // page name 구하기
  const findPageName = () => {
    let pageName = '와인샵';
    switch (tabIndex) {
      case 'all':
        pageName += ' > 전체';
        break;
      case 'recentPickUp':
        pageName += ' > 최근 픽업한 샵';
        break;
      case 'fvrVendor':
        pageName += ' > 관심샵';
        break;
      default:
        pageName += ` > ${tabIndex}`;
        break;
    }
    return pageName;
  };

  // tag길이, 숨겨진 값 구하기
  const findHiddenElements = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const { childNodes } = parentRef.current;

      const hiddenElements = [];
      let shownElementsWidth = 0;
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];

        const childRect = childNode.getBoundingClientRect();

        if (childRect.top > parentRect.top) {
          hiddenElements.push(childNode);
        } else {
          shownElementsWidth += childNode.offsetWidth;
        }
      }
      setHiddenElements(hiddenElements);
      setShownElementsWidth(shownElementsWidth);
    }
  };

  React.useEffect(() => {
    findHiddenElements();
  }, [parentRef, vendor?.shop.service_tag]);

  // 벤더 상세정보로 가기
  const onVendorClick = () => {
    try {
      // Amplitude 'Select Store'
      logEvent.selectStore(findPageName(), vendor._id, vendor.biz?.name);
    } catch (e) {
      /* DO NOTHING */
    }

    navigate(`/vendor/d/${vendor._id}`, { state: { vendor: { _id: vendor._id, ...vendor } } });
  };

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    let addr;

    if (vendor?.biz.address1) {
      addr = vendor.biz.address1;
      if (vendor.biz.address2) addr += ` ${vendor.biz.address2}`;
    }

    return addr;
  }, [vendor]);

  // 관심샵 추가,제거
  const toggleVendorFvr = async (event) => {
    event.stopPropagation();
    setFvrToggleLoading(true);
    let result;
    if (isFvr) {
      result = await deleteFvrVendor(vendor._id, 'Wine-shop list page').catch((error) => ({ error }));
    } else {
      const ampliProps = {
        'vendor name': vendor?.biz?.name ?? 'unknown',
        'page name': '와인샵'
      };

      if (userLocation?.type === 'pin' && userLocation?.currentPin) {
        ampliProps['pin id'] = userLocation.currentPin.pin_id;
        ampliProps['pin name'] = userLocation.currentPin.name;
      }

      result = await addFvrVendor(vendor._id, ampliProps).catch((error) => ({ error }));
    }

    setFvrToggleLoading(false);

    // 관심샵 등록/삭제 중 오류
    if (result.error) {
      console.error('관심샵 변경 중 오류(catched error)', result.error);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, '', 'warning');
      }
    }

    // 관심샵 등록/삭제 성공
    else if (result.data.result.code === 0) {
      await fetchVendorCnt(); // 관심샵 카운트 갱신

      setIsFvr(!isFvr); // 별표 반대로 표현

      // VENDOR_QR 쿠폰 수령시 레이어로 띄울 것
      if (!isFvr && result.data?.VENDOR_QR) {
        const vendorQrCouponResult = result.data.VENDOR_QR;

        console.log('#result.data: ', vendorQrCouponResult);

        // 쿠폰 발급 중 오류
        if (vendorQrCouponResult.error) {
          await WoAlert.fire(
            `이벤트 쿠폰오류`,
            '쿠폰 발급 중에 오류가 발생했어요.<br/>관심샵 해제 후 다시 한번 관심샵으로 설정해주세요',
            ''
          );
          return undefined;
        }

        // 쿠폰발급 성공
        if (vendorQrCouponResult.success) {
          console.log('-----------------------------> vendorQrCouponResult: ', vendorQrCouponResult);
          onVendorQrCouponDownloaded(vendorQrCouponResult.coupon_id);
          return undefined;
        }

        // 기타 미발급 사유
        console.warn(`입점샵 쿠폰 미발급 사유: ${result.data.VENDOR_QR.message}`);
      }

      // Amplitude 'addFavorite'
      if (!isFvr) {
        try {
          logEvent.addVendorToFavorite(findPageName(), vendor._id, vendor.biz?.name);
        } catch (e) {
          /* DO NOTHING */
        }
      }

      globalDispatch(
        openSnackbar({
          open: true,
          message: `관심샵이 ${isFvr ? '제외' : '추가'}되었습니다.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 2800
        })
      );
    } else {
      console.error('관심샵 변경 중 오류', result.data.result);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.data.result)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, '', 'warning');
      }
    }
  };

  // 테스트 입점샵 여부
  const isTestVendor = React.useMemo(() => {
    return vendor?.test ?? false;
  }, [vendor?.test]);

  // 관심샵 여부
  const [isFvr, setIsFvr] = React.useState(vendor.my_fvr === 'true');

  // render
  return (
    <Card square elevation={0} sx={{ marginBottom: '24px' }}>
      <Box
        component="section"
        position="relative"
        display="flex"
        width="100%"
        sx={{ flexDirection: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        {/* 샵 이미지 */}

        <ButtonBase className="vendor-image-box" onClick={onVendorClick} sx={{ borderRadius: '10px', width: vendorImgWidth }}>
          <VendorImage src={vendor?.vendor_img?.thumb} width={vendorImgWidth} height={vendorImgHeight} alt={`${vendor?._id}_image`} />
        </ButtonBase>

        {/* 샵 정보 영역 */}
        <Box className={classes.vendorInfoBox}>
          <CardContent className={classes.vendorCardContent}>
            <SpaceBetweenBox sx={{ alignItems: 'center' }}>
              {/* 와인샵 이름 */}
              <Typography noWrap className={classes.vendorBizName} onClick={onVendorClick}>
                {vendor.biz.name}
              </Typography>

              {/* 관심샵 버튼 */}
              <ButtonBase disableRipple className={classes.btnFav} disabled={fvrToggleLoading} onClick={toggleVendorFvr}>
                <Box component="img" src={isFvr ? vendorFvrOn : vendorFvrOff} width="36px" height="36px" />
              </ButtonBase>
            </SpaceBetweenBox>
            {/* 주소 */}
            <Box onClick={onVendorClick} width="100%" mt="6px">
              <VendorAddress>{vendorAddr}</VendorAddress>
            </Box>

            {/* 샵 tag chips[start] */}
            <Box
              onClick={onVendorClick}
              mt={1}
              display="flex"
              sx={{
                maxWidth: '90%',
                height: 24,
                width: '90%',
                position: 'absolute',
                bottom: '2px'
              }}
            >
              <Grid
                width={shownElementsWidth !== 0 ? `${shownElementsWidth + 1}px` : '85%'}
                overflow="hidden"
                container
                spacing="4px"
                ref={parentRef}
              >
                {isTestVendor ? (
                  <Grid item xs="auto">
                    <ServiceChip label="TEST" color="error" size="small" />
                  </Grid>
                ) : null}
                {/* 프로모션 진행 중인 와인샵 일 경우 표시 */}
                {vendor.on_promo && (
                  <Grid item xs="auto">
                    <ServiceChip
                      avatar={<Box component="img" src={DiscountChipIcon} />}
                      label="할인"
                      color="default"
                      size="small"
                      sx={{
                        bgcolor: '#220348',
                        color: '#fff',
                        '& .MuiChip-avatar': { width: '12px', height: '12px', margin: 0, marginRight: '2px' }
                      }}
                    />
                  </Grid>
                )}

                {vendor?.shop?.service_tag?.map((tag, i) => {
                  return (
                    <Grid item xs="auto" key={`v-svc-tag-${vendor?._id}-${i}`}>
                      <ServiceTag tagCode={tag} />
                    </Grid>
                  );
                })}
              </Grid>
              {shownElementsWidth !== 0 && hiddenElements.length > 0 && (
                <Box marginLeft="4px">
                  <ServiceChip
                    label={`+${hiddenElements.length}`}
                    color="default"
                    size="small"
                    sx={(theme) => ({ bgcolor: '#ffffff', border: `1px solid ${alpha(theme.palette.brand.main, 0.04)}` })}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
}

export default VendorCard;

VendorCard.propTypes = {
  vendor: PropTypes.object,
  fetchVendorCnt: PropTypes.func,
  onVendorQrCouponDownloaded: PropTypes.func.isRequired,
  tabIndex: PropTypes.any
};

/* eslint-disable react/prop-types */
const ServiceTag = ({ tagCode, ref, ...other }) => {
  const { code } = useSelector((state) => state.woConstants);

  if (!code.service_tag) {
    console.warn('서비스태그(service_tag) 상수가 아직 로드되지 않았습니다.');
    return null;
  }

  const tag = (
    <ServiceChip
      label={code.service_tag[tagCode].ko}
      color="default"
      size="small"
      sx={(theme) => ({ bgcolor: alpha(theme.palette.brand.main, 0.04) })}
      {...other}
    />
  );

  return tag;
};

const ServiceChip = styled(Chip)`
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 1.3;
  border-radius: 2px;
  padding: 4px 6px;
  & .MuiChip-label {
    padding: 0;
  }
  & .MuiSvgIcon-root {
    margin-right: 2px;
  }
`;

const useStyles = makeStyles({
  vendorInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc( 100% - ${vendorImgWidth}px )`,
    height: vendorImgHeight
  },
  vendorCardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    flex: '1',
    width: '100%',
    position: 'relative'
  },
  vendorBizName: {
    width: '85%',
    fontSize: '16px',
    fontWeight: 750,
    letterSpacing: '-0.11px'
  },
  btnFav: {
    position: 'absolute',
    right: '0',
    borderRadius: '8px',
    width: '36px',
    height: '36px'
  }
});
