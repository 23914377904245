import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { UserLocationContent } from './components';
import { useSelector } from 'store';

// material-ui
import DialogContent from '@mui/material/DialogContent';
import { Dialog, Slide } from '@mui/material';
import { WoDialogTitle } from '../../dialog';
import WoDivider from '../../dialog/WoDivider';

/**
 * 사용자 위치설정 시트
 * @param {boolean} open - 시트 열림/닫힘 여부
 * @param {function} onClose - 시트 닫힘 눌렸을 떄
 * @param {function} onPinChangeClick - '변경하기' 버튼 클릭 콜백
 * @returns {JSX.Element}
 * @constructor
 */
function UserLocationSheet({ open = false, onClose = () => {}, onPinChangeClick }) {
  // 사용자 위치정보
  const userLocation = useSelector((state) => state.userLocation);

  if (userLocation.loading) {
    console.info('[wineone] 사용자의 위치(핀)정보를 아직 가져오지 못 했습니다.');
  }

  return (
    <Dialog
      open={!userLocation.loading && open}
      variant="bottom"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
      keepMounted
      onClose={onClose}
    >
      <WoDialogTitle title="나의 위치 설정" onClickClose={onClose} />
      <WoDivider />
      {/* 위치 선택방법 List */}
      <DialogContent sx={{ py: 0 }}>
        <UserLocationContent
          onPinChangeClick={() => {
            onPinChangeClick(userLocation);
          }}
          show={open}
        />
      </DialogContent>
    </Dialog>
  );
}

UserLocationSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPinChangeClick: PropTypes.func
};

export default UserLocationSheet;

// 위치설정 dialog transition.
/* eslint-disable react/prop-types */
const Transition = React.forwardRef(function Transition(props, ref) {
  const { children, ...other } = props;
  return (
    <Slide {...other} ref={ref} direction="up">
      {children}
    </Slide>
  );
});
