import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// project imports
import { useScriptRef } from 'hooks';
import { BottomDialog, WoDialogTitle, WoDivider } from 'components/dialog';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { getPickupAvailableDates } from 'services/VendorService';

// material-ui
import { Box, Button, Collapse, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

// assets
import Loading from 'assets/images/loading_ani.gif';

/**
 * 픽업일시 변경 모달
 * @param open
 * @param order
 * @param onClose
 * @param onSelect
 * @returns {JSX.Element}
 * @constructor
 */
function PickdateChangeModal({ open = false, order, onClose, onSelect }) {
  const scriptedRef = useScriptRef();

  // 주문한 입점샵 아이디
  const vendorId = React.useMemo(() => order?.vendor?.id ?? order?.vendor?._id, [order?.vendor?.id, order?.vendor?._id]);

  // 입점샵 픽업가능날짜
  const [pickupAvailableDates, setPickupAvailableDates] = React.useState({ loaded: false, error: null, data: {} });
  // 픽업시간 옵션 array
  const [pickupTimeArray, setPickupTimeArray] = React.useState([]);
  // 픽업날짜
  const [pickupYmd, setPickupYmd] = React.useState(null);
  // 픽업시간
  const [pickupTime, setPickupTime] = React.useState(null);
  // 픽업변경 가능여부
  const [changeAvailable, setChangeAvailable] = React.useState(false);

  // 픽업 가능일시 가져오기
  React.useEffect(() => {
    if (vendorId && order.oid) {
      // const orderDate = `${order.pickup_date} ${order.pickup_time}`;
      const orderDate = moment(order.created_at).format('YYYY-MM-DD HH:mm');
      console.debug('픽업 가능일시 가져오기', orderDate);

      const product_ids = [];
      Object.entries(order.order_products).forEach(([key, { items }]) => {
        items.forEach(({ product_id }) => {
          product_ids.push(product_id);
        });
      });
      fetchPickupAvailableDates(order.vendor._id, orderDate, { product_ids });
    } else {
      setPickupYmd(null);
      setPickupTime(null);
      setPickupAvailableDates({ loaded: false, error: null, data: {} });
    }
  }, [vendorId, order?.oid]);

  // 픽업날짜 변경감시
  React.useEffect(() => {
    if (pickupAvailableDates.loaded && pickupYmd != null) {
      const { data } = pickupAvailableDates;
      setPickupTimeArray(data?.[pickupYmd] ?? []);
    } else {
      setPickupTimeArray([]);
    }
  }, [pickupAvailableDates.loaded, pickupYmd]);

  const handleRequestClick = React.useCallback(() => {
    if (pickupYmd == null) {
      console.warn('[1kmwine] 변경할 픽업날짜가 선택되지 않았습니다.');
      return false;
    }

    if (pickupTime == null) {
      console.warn('[1kmwine] 변경할 픽업시간이 선택되지 않았습니다.');
      return false;
    }

    if (`${order.pickup_date} ${order.pickup_time}` === `${pickupYmd} ${pickupTime}`) {
      console.warn('픽업일시가 변경되지 않았습니다.');
      return false;
    }

    console.debug(`[픽업 일시 변경 dialog] 변경된 픽업일시 -> ${pickupYmd} ${pickupTime}`);

    // YYYYMMDD -> YYYY-MM-DD
    onSelect(moment(pickupYmd, 'YYYYMMDD').format('YYYY-MM-DD'), pickupTime);
  }, [order, pickupYmd, pickupTime]);

  // render
  return (
    <BottomDialog open={open} onClose={onClose}>
      <WoDialogTitle title="픽업 일시 변경" onClickClose={onClose} />

      <WoDivider />

      <DialogContent sx={{ position: 'relative', height: 300, width: '100%' }}>
        {pickupAvailableDates.loaded ? (
          <Box>
            <SpaceBetweenBox>
              <Typography variant="subtitle2" fontSize="11px" fontWeight={800}>
                날짜 선택
              </Typography>
              {!pickupYmd && (
                <Typography variant={'caption'} lineHeight={1} color={'error.main'}>
                  *{changeAvailable ? '픽업날짜를 선택해주세요.' : '픽업변경 가능일이 지났습니다.'}
                </Typography>
              )}
            </SpaceBetweenBox>

            {/* 픽업날짜 선택버튼 표현[start] */}
            <Box mt={1}>
              <Grid container spacing={1}>
                {Object.keys(pickupAvailableDates.data).map((date) => {
                  const selected = pickupYmd === date; // 픽업 날짜 선택 플래그

                  return (
                    <Grid item xs={3} key={`pickup-ymd-${date}`}>
                      <Button
                        fullWidth
                        size="small"
                        type="button"
                        color="brand"
                        variant={selected ? 'contained' : 'outlined'}
                        sx={{
                          p: '3px 0',
                          letterSpacing: '-0.5px',
                          fontWeight: selected && 'bold',
                          border: '1.5px solid #60546E1A',
                          '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                        }}
                        value={date}
                        disabled={!changeAvailable}
                        onClick={(e) => {
                          setPickupTime(null);
                          setPickupYmd(e.target.value);
                        }}
                      >
                        {moment(date, 'YYYYMMDD').format('M.D (ddd)')}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* 픽업날짜 선택버튼 표현[end] */}

            {/* 픽업시간 선택버튼 표현[start] */}
            <Collapse in={pickupYmd != null && pickupTimeArray.length > 0}>
              <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="subtitle2" fontSize="11px">
                    시간 선택
                  </Typography>
                  {!pickupTime && (
                    <Typography variant={'caption'} lineHeight={1} color={'error.main'}>
                      *픽업시간을 선택해주세요.
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box mt={1}>
                <Grid container spacing={1}>
                  {pickupTimeArray.map(([hour, available], i) => {
                    // 지나간 시간은 선택 못 하도록 ( buffer: 1분 )
                    const time = moment({ hour });
                    const timeFormat = time.format('HH:mm'); // 픽업 시간 포맷
                    const selected = pickupTime === timeFormat; // 픽업 시간 선택 플래그

                    // 옵션시간이 현재시간을 지났을 경우
                    const disabled = !available || moment(`${pickupYmd} ${timeFormat}`, 'YYYYMMDD HH:mm').isSameOrBefore(moment());

                    return (
                      <Grid item xs={3} key={`pickup-time-${i}`}>
                        <Button
                          fullWidth
                          disabled={disabled}
                          size="small"
                          type="button"
                          color="brand"
                          variant={selected ? 'contained' : 'outlined'}
                          sx={{
                            p: '3px 0',
                            letterSpacing: '-0.5px',
                            fontWeight: selected && 'bold',
                            border: '1.5px solid #60546E1A',
                            '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                          }}
                          value={timeFormat}
                          onClick={(e) => setPickupTime(e.target.value)}
                        >
                          {time.format(`A ${hour === 0 ? 'H' : 'h'}시`)}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Collapse>
            {/* 픽업시간 선택버튼 표현[end] */}
          </Box>
        ) : (
          <PickupAvailDateLoading />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing="8px">
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={onClose} sx={{ fontSize: 17, height: '46px' }}>
              취소
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="brand"
              disabled={
                pickupYmd == null ||
                pickupTime == null ||
                (order && `${order.pickup_date} ${order.pickup_time}` === `${pickupYmd} ${pickupTime}`)
              }
              onClick={handleRequestClick}
              sx={{ fontWeight: 800, fontSize: 17, height: '46px' }}
            >
              변경 요청
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BottomDialog>
  );

  /**
   * 픽업 가능일시 불러오기
   * @param vendorId
   * @param orderDate
   * @returns {Promise<void>}
   */
  async function fetchPickupAvailableDates(vendorId, orderDate, options): Promise<void> {
    setPickupAvailableDates({ loaded: false, error: null, data: {} });
    const result = await getPickupAvailableDates(vendorId, orderDate, options);

    if (!scriptedRef.current) {
      console.warn('[fetchPickupAvailableDates] Unmounted component.');
      return;
    }

    // 픽업 가능일시 조회 성공
    if (result.code === 0) {
      setPickupAvailableDates({ loaded: true, error: null, data: { ...result.data } });

      /*
       * 픽업변경 가능일이 지났는지 검사
       *************************************************************************
       */
      if (Object.keys(result.data).length === 0) setChangeAvailable(false);
      else {
        const dates = Object.keys(result.data);

        // 마지막 픽업 가능일시를 구한다
        const lastPickupAvailDate = dates[dates.length - 1];
        const lastPickupAvailHour = `${result.data[lastPickupAvailDate].slice(-1)[0][0]}`.padStart(2, '0');
        const lastPickupAvailDateTime = moment(`${lastPickupAvailDate} ${lastPickupAvailHour}`, 'YYYYMMDD HH');

        // 픽업일시 변경 가능여부 - 현재 시간이 마지막 픽업 가능일시를 지났을 경우 '픽업변경 가능일이 지났습니다' 표현
        setChangeAvailable(moment().isBefore(lastPickupAvailDateTime));
      }
    } else {
      console.error('[fetchPickupAvailableDates] Fetch pickup available dates failed.', result);
      setPickupAvailableDates({ loaded: true, error: true, data: {} });
    }
  }
}

PickdateChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  order: PropTypes.object
};

export default PickdateChangeModal;

const PickupAvailDateLoading = React.memo(() => (
  <Box
    height="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    bgcolor={(theme) => theme.palette.background.paper}
    zIndex={1}
  >
    <Box component="img" src={Loading} height={80} />
    <Box mt="12px">
      <Typography fontSize={18} fontWeight="bold" textAlign="center">
        픽업 가능시간을 확인 중 입니다
      </Typography>
      <Typography fontSize={16} fontWeight={300} textAlign="center">
        잠시만 기다려주세요
      </Typography>
    </Box>
  </Box>
));
