/**
 * 와인 정렬 순서
 * @history
 *  - v1.0.9 : 가까운 거리순 추가
 */
export const WINE_ORDER = Object.freeze([
  { label: '가까운 거리순', value: 'distance' },
  // { label: '최신순', value: 'recent' },
  // { label: '높은 평점순', value: 'star' }, // v1.0.9 - 높은 평점순 제외
  { label: '낮은 가격순', value: 'lowp' },
  // { label: '높은 가격순', value: 'highp' },
  { label: '인기순', value: 'wish' }
  // { label: '리뷰순', value: 'review' }
]);

/**
 * 상품상세 - 주문가능매장 정렬순서
 */
export const PRD_VENDOR_ORDER = Object.freeze([
  { label: '가까운 거리순', value: 'distance' },
  { label: '낮은 가격순', value: 'lowp' }
  // { label: '높은 가격순', value: 'highp' }
]);

/**
 * 사용자 위치 타입
 * @type {{PIN: string, CURRENT: string}}
 */
export const USER_LOCATION_TYPE = Object.freeze({
  CURRENT: 'current',
  PIN: 'pin'
});

/**
 * 주문(order) 상태
 * @type {{STATE: {ING: string, REJECT: string, APPROVED: string, REQ: string}}}
 */
export const ORDER = Object.freeze({
  STATE: {
    /** 결제중 */
    ING: 'ING',
    /** 승인요청 */
    REQUEST: 'REQ',
    /** 주문취소 (사용자 취소) */
    CANCEL: 'CANCEL',
    /** 거절된 주문 */
    REJECT: 'REQ_NO',
    /** 승인된 주문 */
    APPROVED: 'REQ_OK',
    /** 픽업완료 */
    PICK_UP_DONE: 'PICK_OK'
  }
});

/**
 * 주문상품(order item) 상태
 * @type {{STATE: {ING: string, EXCHANGE_REJECTED: string, RETURN_WAIT: string, EXCHANGE_WAIT: string, ORDER_CANCEL: string, REQUEST_RETURN: string, RETURN_COMPLETE: string, REQUEST: string, PICK_UP_DONE: string, PURCHASE_CONFIRM: string, RETURN_REJECTED: string, REQUEST_EXCHANGE: string}}}
 */
export const ORDER_ITEM = Object.freeze({
  STATE: {
    /** 결제중 */
    ING: ORDER.STATE.ING,
    /** 승인요청 */
    REQUEST: ORDER.STATE.REQUEST,
    /** 거절된 주문 */
    REJECT: ORDER.STATE.REJECT,
    /** 승인된 주문 */
    APPROVED: ORDER.STATE.APPROVED,
    /** 주문취소 */
    ORDER_CANCEL: 'CANCEL',

    /** 픽업완료 */
    PICK_UP_DONE: ORDER.STATE.PICK_UP_DONE,

    /** 부분 승인 */
    PART_APPROVED: 'REQ_PART_OK',
    /** 구매확정 */
    PURCHASE_CONFIRM: 'CONFIRM',

    /** 교환/반품 통합 */
    EXCHANGE_RETURN: 'EXCRTN',

    /** 반품요청 */
    REQUEST_RETURN: 'REQ_RTN',
    /** 반품대기 */
    RETURN_WAIT: 'RTN_WAIT',
    /** 반품취소 (판매사) */
    RETURN_REJECTED: 'RTN_NO',
    /** 반품완료 */
    RETURN_COMPLETE: 'RTN_OK',

    /** 교환요청 */
    REQUEST_EXCHANGE: 'REQ_EXC',
    /** 교환대기 */
    EXCHANGE_WAIT: 'EXC_WAIT',
    /** 교환취소 (판매사) */
    EXCHANGE_REJECTED: 'EXC_NO'
    /* 교환완료 = PICK_UP_DONE */
  }
});

/**
 * 상품 수령옵션
 * @type {[{label: string, informal: string, value: string},{label: string, informal: string, value: string}]}
 */
export const PACKING_OPTIONS = Object.freeze([
  { value: 'bag', label: '쇼핑백', informal: '쇼핑백에 담아주세요' },
  // { value: 'box', label: '박스', informal: '박스에 담아주세요' }, // 영업 요청으로 제외
  { value: '', label: '일반', informal: '그냥 가져갈게요' }
]);

/**
 * 결제관련 상수 모음
 * @type {{INICIS: {API_KEY, P_MID, P_NEXT_URL: string}, SERVER_HOST}}
 */
export const PAYMENT = Object.freeze({
  /** 결제(payment)서버 호스트 */
  SERVER_HOST: process.env.REACT_APP_PAYMENT_SERVER_HOST,
  /** 이니시스(inicis) */
  INICIS: {
    /** 상점 아이디(market id) */
    P_MID: process.env.REACT_APP_INICIS_MID,
    /** 주문 결제처리 URL */
    P_NEXT_URL: `${process.env.REACT_APP_PAYMENT_SERVER_HOST}/call-pay-order-next`
  }
});

/**
 * Application URL scheme
 * @type {{IOS_1KMWINE: string, AOS_1KMWINE: string}}
 */
export const APP_SCHEME = Object.freeze({
  AOS_1KMWINE: 'onekmwine',
  IOS_1KMWINE: 'onekmwine'
});

/**
 * 개발관련
 * @type {{CONSOLE: {LABEL_STYLE: string}}}
 */
export const DEV = {
  CONSOLE: {
    LABEL_STYLE: 'color: purple; background:pink; padding: 0;'
  }
};

export const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

/**
 * @type {boolean} 프로덕션에 배포된 인스턴스
 */
export const IS_PRODUCTION = process.env.NODE_ENV === 'production' && SERVICE_TYPE === 'production';

/**
 * @type {boolean} 개발서버에 배포된 인스턴스
 */
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'production' && SERVICE_TYPE === 'development';

export const COUPON_TYPE = Object.freeze({
  ADMIN: 'ADMIN',
  VENDOR: 'VENDOR',
  ADIMN_TO_VENDOR: 'ADVEN'
});

export const CLO_CODE = Object.freeze({
  /* 7100 - 회원관련 CLO 분류코드 정의 */

  /** 회원가입 성공 */
  USER_REGISTER_SUCCESS: 7100,
  /** 회원가입 실패 */
  USER_REGISTER_FAILURE: 7101,
  /** 이미 가입한 사용자의 가입시도 */
  DUPLICATE_USERS_ATTEMPT: 7102,

  /* 7200 - 주요화면 오류 */
  /** 주요 컨텐츠 관련 오류 */
  IMPORTANT_CONTENT_ERROR: 7200,
  /** 주문조회 오류 */
  ORDER_LOAD_ERROR: 7201,
  ORDER_REFUND_FETCH_ERROR: 7301,
  ORDER_REFUND_COMMISION_FETCH_ERROR: 7302,
  /* 7300 - SNS provider 관련 분류코드 정의 */
  // 7310 - Kakao
  /** 카카오 로그인 Unexpected error. */
  SNS_KAKAO_UNEXPECTED_ERROR: 7319,
  // 7320 - Google
  // 7330 - Apple
  // 7340 - Naver
  /** 입점샵 정보 조회 오류 */
  VENDOR_FETCH_ERROR: 7401,
  /** 알 수 없거나 정의되지 않은 기타 에러 */
  UNEXPECTED_ERROR: 7999
});

/** 사용자 닉네임 정규식 */
export const NICKNAME_REGEX = /^[a-zA-Z가-힣\d\-_]{2,10}$/;

export const storage_bucket = Object.freeze({
  common: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}common.1kmwine.com`,
  pdata_review: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}pdata.review.1kmwine.com`,
  user: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}user.1kmwine.com`
});

export const PRODUCT = Object.freeze({
  BIT: {
    IN_PIN: 1, // 핀 내 상품
    OUT_PIN: 2, // 핀 외 상품
    ALL: 3 // 모든 상품 (IN_PIN + OUT_PIN)
  }
});

export const PDATA_CATEGORY = Object.freeze({
  /** 와인 */
  WINE: { value: 'wine', label: { ko: '와인', en: 'Wine' } },
  /** 위스키 */
  WHISKEY: { value: 'whiskey', label: { ko: '위스키', en: 'Whiskey' } },
  /** 일본주 */
  NIHONSHU: { value: 'nihonshu', label: { ko: '일본주', en: 'Nihonshu' } },
  /** 티켓 */
  TICKET: { value: 'ticket', label: { ko: '티켓', en: 'Ticket' } },
  /** 기획상품 */
  OFFER: { value: 'offer', label: { ko: '기획상품', en: 'Offer' } }
});

/** 와인타입 */
export const WINE_TYPES = Object.freeze({
  /** 레드 */
  RED: { value: 'RED', label: { ko: '레드', en: 'Red' } },
  WHITE: { value: 'WHITE', label: { ko: '화이트', en: 'White' } },
  SPARKLING: { value: 'SPARKLING', label: { ko: '스파클링', en: 'Sparkling' } },
  ROSE: { value: 'ROSE', label: { ko: '로제', en: 'Rose' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 위스키 타입 */
export const WHISKEY_TYPES = Object.freeze({
  SCOTCH: { value: 'SCOTCH', label: { ko: '스카치', en: 'Scotch' } },
  SINGLE_MALT: { value: 'SINGLE_MALT', label: { ko: '싱글 몰트', en: 'Single malt' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 일본주 타입 */
export const NIHONSHU_TYPES = Object.freeze({
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 티켓 타입 */
export const TICKET_TYPES = Object.freeze({
  TASTING: { value: 'TASTING', label: { ko: '시음회', en: 'Tasting' } },
  CLASS: { value: 'CLASS', label: { ko: '클래스', en: 'Class' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

export const getTypeByValue = (category: string, type: string, lang = 'ko') => {
  try {
    let types;
    switch (category) {
      case PDATA_CATEGORY.WINE.value:
        types = WINE_TYPES;
        break;
      case PDATA_CATEGORY.WHISKEY.value:
        types = WHISKEY_TYPES;
        break;
      case PDATA_CATEGORY.NIHONSHU.value:
        types = NIHONSHU_TYPES;
        break;
      case PDATA_CATEGORY.TICKET.value:
        types = TICKET_TYPES;
        break;
      default:
        types = null;
    }

    if (types === null) return type;
    return types[type.toUpperCase()].label[lang];
  } catch (e) {
    return type;
  }
};

const categoryTypes = {};

// 와인(wine)
categoryTypes[PDATA_CATEGORY.WINE.value] = [];
Object.keys(WINE_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.WINE.value].push(WINE_TYPES[key]);
});

// 위스키(whiskey)
categoryTypes[PDATA_CATEGORY.WHISKEY.value] = [];
Object.keys(WHISKEY_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.WHISKEY.value].push(WHISKEY_TYPES[key]);
});

// 일본주(nihonshu)
categoryTypes[PDATA_CATEGORY.NIHONSHU.value] = [];
Object.keys(NIHONSHU_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.NIHONSHU.value].push(NIHONSHU_TYPES[key]);
});

// 티켓(ticket)
categoryTypes[PDATA_CATEGORY.TICKET.value] = [];
Object.keys(TICKET_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.TICKET.value].push(TICKET_TYPES[key]);
});

export const CATEGORY_TYPES = Object.freeze(categoryTypes);

/** 럭키박스 상품 pdata 배열, todo 럭키박스 2차 때 재사용, 럭키박스 2차 완료 후 삭제 */
export const LUCKYBOX_ITEM_IDS: string[] = Object.freeze(['00026729']);

export const KmPromotionState = Object.freeze({
  /** 프로모션 진행 중 */
  OK: 'OK',
  /** 프로모션 조기 종료 */
  CANCEL: 'CANCEL',
  /** 프로모션 종료 */
  FINISH: 'FINISH'
});
