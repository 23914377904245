import React from 'react';
import { Box, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../store';
import PropTypes from 'prop-types';
import {
  CitrusAromaIcon,
  DefaultAromaIcon,
  FlowerAromaIcon,
  FreshHerbAromaIcon,
  MineralAromaIcon,
  PomeFruitAromaIcon,
  RedFruitAromaIcon,
  SmokeAromaIcon,
  SpiceAromaIcon,
  VegetableAromaIcon
} from '../../icons';
import AromaDivision from '../../../assets/images/aroma_division.svg';
import { fetchCodes } from '../../../store/slices/wo-constants';

function AromaChip({ pAroma, isLastIndex, index }) {
  // 코드 상수
  const { woConstants } = useSelector((state) => state);

  // if (!woConstants || !woConstants.code.aroma) return <Skeleton height="20px" width="40px" />;

  const aroma = woConstants.code.aroma && woConstants?.code?.aroma[pAroma];

  React.useEffect(() => {
    // 서비스 코드 조회
    dispatch(fetchCodes(['aroma']));
  }, [woConstants]);

  return aroma ? (
    <Box display="flex" height="20px" key={`aromachip-${index}`}>
      <Box>{matchedIcon(aroma?.en)}</Box>
      <Typography marginLeft="2px" fontSize="14px">
        {aroma.ko}
      </Typography>
      <Box width="15px" height="20px" display="flex" alignItems="center" justifyContent="center">
        {!isLastIndex && <Box component="img" src={AromaDivision} />}
      </Box>
    </Box>
  ) : null;
}

AromaChip.propTypes = {
  pAroma: PropTypes.string,
  isLastIndex: PropTypes.bool,
  index: PropTypes.number
};

export default AromaChip;

function matchedIcon(code) {
  let svgIcon;
  /* eslint-disable no-fallthrough */
  switch (code) {
    // 감귤류
    case 'Lemon': // 레몬
    case 'Lime': // 라임
    case 'Grapefruit': // 자몽
      svgIcon = <CitrusAromaIcon />;
      break;

    // todo 흰열매
    case 'Gooseberry': // 까지밥나무
      console.debug(`${code}: 흰열매 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // 이과과일
    case 'Pear': // 배
    case 'Apple': // 사과
    case 'Green Apple': // 풋사과
      svgIcon = <PomeFruitAromaIcon />;
      break;

    // todo 핵과일
    case 'Peach': // 복숭아
      console.debug(`${code}: 핵과일 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // todo 열대과일 아이콘
    case 'Melon': // 멜론
    case 'Guava': // 구아바
    case 'Pineapple': // 파인애플
    case 'Passion Fruit': // 패션프룻
    case 'Lychee': // 라치
    case 'Banana': // 바나나
      console.debug(`${code}: 열대과일 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // todo 귀부향 아이콘
    case 'Dried Apricot': // 건살구
    case 'Orange Peel': // 오렌지껍질
      console.debug(`${code}: 귀부향 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // 붉은열매 아이콘
    case 'Raspberry': // 나무딸기
    case 'Blackcurrant': // 블랙커런트
    case 'Strawberry': // 딸기
    case 'Blackberry': // 블랙베리
    case 'Cherry': // 체리
    case 'Plum': // 자두
      svgIcon = <RedFruitAromaIcon />;
      break;

    // todo 주정강화 아이콘
    case 'Prune': // 건자두
      console.debug(`${code} : 주정강화 아이콘 필요`); // 일단 붉은열매 아이콘으로 교체
      svgIcon = <RedFruitAromaIcon />;
      break;

    // 꽃 아이콘
    case 'Honeysuckle': // 인동
    case 'Hawthorn': // 산사나무
    case 'Orange Blossom': // 오렌지꽃
    case 'Linden': // 보리수
    case 'Jasmine': // 자스민
    case 'Acacia': // 아카시아
    case 'Rose': // 장미
    case 'Lavender': // 라벤더
    case 'Violet': // 제비꽃
      svgIcon = <FlowerAromaIcon />;
      break;

    // todo 채소
    case 'Capsicum': // 피망
    case 'Fennel': // 회향
    case 'Tomato': // 토마토
      svgIcon = <VegetableAromaIcon />;
      break;

    // 신선한 허브
    case 'Cut Grass': // 갓깎은 잔디
    case 'Dill': // 딜
    case 'Thyme': // 타임
    case 'Fern': // 양치류
    case 'Mint': // 민트
      svgIcon = <FreshHerbAromaIcon />;
      break;

    // todo 말린 허브
    case 'Hay': // 건초
    case 'Black Tea': // 홍차
    case 'Tobacco': // 담배
      console.debug(`${code}: 말린허브 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // todo 잎
    case 'Blackcurrant Leaf': // 건초
    case 'Bay Leaf': // 홍차
    case 'Eucalyptus': // 유칼립투스
      console.debug(`${code}: 잎 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // 미네랄
    case 'Flint': // 부싯돌
    case 'Iodine': // 요오드
    case 'Kerosene': // 석유
      svgIcon = <MineralAromaIcon />;
      break;

    // todo 효모

    // todo 젖산발효
    case 'Butter': // 버터
      console.debug(`${code}: 젖산발효 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // 향신료
    case 'Vanilla': // 바닐라
    case 'Pepper': // 후추
    case 'Cinnamon': // 계피
    case 'Liquorice': // 감초
    case 'Nutmeg': // 육두구
    case 'Cloves': // 정향
      svgIcon = <SpiceAromaIcon />;
      break;

    // todo 견과류
    case 'Almond': // 아몬드
    case 'Coconut': // 코코넛
    case 'Hazelnut': // 헤이즐넛
      console.debug(`${code}: 견과류 아이콘 필요`);
      svgIcon = <DefaultAromaIcon />;
      break;
    // todo 나무
    // todo 숙성
    // todo 동물성
    // todo 버섯이끼류
    // todo 오염
    // todo 산화
    // todo "산화, 과열"
    // todo 휘발성 산
    // todo 황화
    // todo 브렛

    // 구운향
    case 'Bacon': // 베이컨
    case 'Caramel': // 카라멜
    case 'Chocolate': // 초콜렛
    case 'Coffee': // 커피
    case 'Smoke': // 연기
    case 'Tar': // 타르
    case 'Toast': // 토스트
      svgIcon = <SmokeAromaIcon />;
      break;

    default:
      console.debug('[dev] 아이콘 지정이 안되어있는 아로마코드 =', code);
      svgIcon = <DefaultAromaIcon />;
  }

  return svgIcon;
}
