import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { SearchField } from 'components/search';

// material-ui
import { Box, Container, Grid, Tab, Tabs } from '@mui/material';
import { PDATA_CATEGORY } from '../../../../config';
import { SearchIcon } from './icons';

// components

/**
 * '검색' 인덱스화면 툴바
 *
 * @param {Object} filter - 적용된 필터
 * @param {string} searchQuery - 검색어
 * @param {function} onInputClick - 검색어 영역 클릭이벤트 콜백
 * @returns {MallToolbar}
 *
 * @authors 조현권<hkcho@wineone.io>, 제동균<jedk@wineone.io>
 */
function MallToolbar({ filter, searchQuery, onInputClick, onChange }) {
  // [PDATA_CATEGORY.WINE, PDATA_CATEGORY.WHISKEY, PDATA_CATEGORY.NIHONSHU];
  // todo hkcho 최적화
  const PDATA_CATEGORIES = [];
  Object.keys(PDATA_CATEGORY).forEach((key) => {
    if (key !== 'TICKET') PDATA_CATEGORIES.push(PDATA_CATEGORY[key]);
  });

  const placeHolder = React.useMemo(() => {
    if (filter?.pdata_category === PDATA_CATEGORY.WINE.value) return '와인 이름으로 검색';
    return '상품명으로 검색';
  }, [filter.pdata_category]);

  const hasSearchQuery = React.useMemo(() => {
    return typeof searchQuery === 'string' && searchQuery.trim().length > 0;
  }, [searchQuery]);

  // render
  return (
    <>
      {hasSearchQuery ? (
        <Container sx={{ position: 'sticky', mt: '-12px', py: '12px', top: 0, zIndex: 5, background: 'white' }}>
          <Box maxWidth="sm" mx="auto" className="order-index-toolbar">
            <SearchField readOnly value={searchQuery} onClick={onInputClick} placeholder={placeHolder} />
          </Box>
        </Container>
      ) : null}

      <Container
        maxWidth="md"
        sx={{
          mt: hasSearchQuery ? '10px' : '4px',
          position: 'relative',
          zIndex: 0,
          pb: '20px',
          borderBottom: filter.pdata_category === PDATA_CATEGORY.WINE.value ? '8px solid #F3F2F5' : 'none'
        }}
      >
        <Box maxWidth="md" mx="auto">
          <Box className="search-tool-bar">
            <Grid container display="flex" justifyContent="center" spacing="8px">
              <Grid item xs={!hasSearchQuery ? 'auto' : 12}>
                <Tabs
                  value={filter.pdata_category}
                  onChange={onChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  // variant="scrollable"
                  centered
                  // scrollButtons
                  allowScrollButtonsMobile
                  aria-label="상품 카테고리 탭"
                  sx={(theme) => ({
                    mx: 'auto',
                    maxWidth: '268px',
                    minHeight: '38px',
                    backgroundColor: '#F4F0F8',
                    borderRadius: '21px',
                    textAlign: 'center',
                    '& .MuiTabs-flexContainer': { height: '100%' },
                    '& .MuiButtonBase-root': {
                      height: '38px',
                      minHeight: '100%',
                      minWidth: '46px',
                      px: '12px',
                      zIndex: 1,
                      borderRadius: '21px',
                      fontSize: '12px'
                    },
                    '& .Mui-selected': { color: theme.palette.brand.contrastText, fontWeight: 800 },
                    '& .MuiTabScrollButton-root': { transition: 'all 150ms', width: '20px', minWidth: '20px', padding: 0 },
                    '& .MuiTabScrollButton-root.Mui-disabled': { width: 0, minWidth: 0 }
                  })}
                  TabIndicatorProps={{
                    sx: (theme) => ({
                      height: '100%',
                      bgcolor: theme.palette.brand.main,
                      borderRadius: '21px'
                    })
                  }}
                >
                  <Tab label="전체" id="category-tab-all" value="all" />
                  {PDATA_CATEGORIES.map(({ value, label }) => (
                    <Tab
                      key={`category-tab-${value.toLowerCase()}`}
                      label={label.ko}
                      id={`category-tab-${value.toLowerCase()}`}
                      value={value}
                    />
                  ))}
                </Tabs>
              </Grid>

              {!hasSearchQuery ? (
                <Grid item xs="auto">
                  <Box
                    onClick={onInputClick}
                    sx={{
                      width: '38px',
                      height: '38px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#F4F0F8',
                      borderRadius: '21px'
                    }}
                  >
                    <SearchIcon />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

MallToolbar.propTypes = {
  filter: PropTypes.object, // 필터
  searchQuery: PropTypes.string, // 검색어
  onInputClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MallToolbar;
