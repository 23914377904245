import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledSearchWrapper = styled('div')({
  position: 'relative',
  marginLeft: 0
});

export default function SearchWrapper({ children, fullWidth = false, width = 'auto', wrapperProps = {} }) {
  return (
    <StyledSearchWrapper {...wrapperProps} sx={{ width: fullWidth ? '100%' : width }}>
      {children}
    </StyledSearchWrapper>
  );
}

SearchWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperProps: PropTypes.object
};
