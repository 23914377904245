import React from 'react';
import PropTypes from 'prop-types';

import { SpaceBetweenBox } from 'components';
import { ShowMoreButton } from '../../../../components/buttons';

import { Typography } from '@mui/material';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';

/**
 * 홈화면 컨텐츠 헤더
 *
 * @param {string} title
 * @param {boolean} showMoreButton
 * @param {function} onMoreButtonClick
 * @param {boolean} noWrap
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function HomeContentHeader({ title, showMoreButton = false, onMoreButtonClick = () => false, noWrap = false }) {
  return (
    <SpaceBetweenBox>
      <Typography variant={'subtitle1'} fontWeight={800} fontSize={21} letterSpacing={'0.4px'} noWrap={noWrap} sx={{ pl: 0.5 }}>
        {title}
      </Typography>
      <ShowMoreButton
        onClick={onMoreButtonClick}
        endIcon={<ChevronRightSharpIcon color="inherit" fontSize="inherit" sx={{ opacity: 0.45, height: 14, width: 14 }} />}
        sx={{ mr: -1, visibility: showMoreButton ? 'visible' : 'hidden' }}
      >
        <Typography variant="caption" fontSize="inherit" lineHeight="1.46" sx={{ opacity: 0.45, height: 14 }}>
          전체보기
        </Typography>
      </ShowMoreButton>
    </SpaceBetweenBox>
  );
}

HomeContentHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  showMoreButton: PropTypes.bool,
  onMoreButtonClick: PropTypes.func,
  noWrap: PropTypes.bool
};

export default HomeContentHeader;
