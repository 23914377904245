import React from 'react';
import { Route } from 'react-router-dom';
import AuthLayout from '../utils/route-guard/AuthLayout';
import VendorListIndex from '../views/vendor/VendorList';

import VendorDetailIndex from '../views/vendor/VendorDetail';

export default function VendorRoutes() {
  return (
    <Route path="/vendor" element={<AuthLayout />}>
      <Route index element={<VendorListIndex />} />
      <Route path="d/:vendorId" element={<VendorDetailIndex />} />
    </Route>
  );
}
