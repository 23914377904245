import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ButtonGroup, TextField } from '@mui/material';

/**
 * 수량 선택 박스
 *
 * @param {number} value 주문수량
 * @param {number} min 최소
 * @param {number} max 최대
 * @param {number} step 한 클릭에 움직이는 수
 * @param {number} boxHeight
 * @param {function} onChange
 * @param {boolean} disabled 비활성화 여부H
 * @returns {JSX.Element}
 * @constructor
 */
function QuantityBox({ value: valueProp = 1, min = 1, max = -1, step = 1, onChange = null, boxHeight = 45, disabled = false }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(valueProp); // 수량

  React.useEffect(() => {
    setValue(valueProp < 0 ? 1 : valueProp);
  }, [valueProp]);

  const handleValue = (newVal) => {
    if (typeof onChange === 'function') {
      onChange(newVal);
    }
    setValue(newVal);
  };

  // 감소
  const decrease = () => handleValue(value - step);

  // 증가
  const increase = () => {
    let _value = value >> 0;
    if (max > 0 && value + step > max) {
      console.log(`[wineone] maxvalue에 닿았다. max=${max}`);
    } else {
      _value = value + step;
    }
    handleValue(_value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.nativeEvent.stopPropagation();
      }}
      zIndex={1}
      maxWidth={200}
    >
      <ButtonGroup disabled={disabled} variant="outlined" aria-label="outlined button group">
        <Button
          title="감소"
          edge="start"
          color="dark"
          aria-label="감소"
          onClick={() => {
            if (value > min) decrease();
          }}
          sx={{ p: 0, fontSize: '20px', width: boxHeight, height: boxHeight }}
        >
          <span style={{ display: 'inline-block', height: '1px', width: '11px', backgroundColor: theme.palette.brand.main }} />
        </Button>
        <TextField
          hiddenLabel
          variant="outlined"
          disabled={disabled}
          type="number"
          value={value}
          size="small"
          inputProps={{ sx: { textAlign: 'center', fontSize: '20px', letterSpacing: 'normal' }, readOnly: true }}
          sx={{
            width: 80,
            textAlign: 'center',
            '& .MuiOutlinedInput-root': { borderRadius: 0, ml: '-1px', height: boxHeight },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              border: '1px solid rgba(33, 37, 41, 0.5)',
              borderRadius: 0,
              backgroundColor: 'rgba(0,0,0,0)'
            }
          }}
        />
        <Button
          title="증가"
          edge="end"
          color="dark"
          aria-label="증가"
          disabled={max > 0 && value >= max}
          onClick={increase}
          sx={{ p: 0, fontSize: '20px', width: boxHeight, height: boxHeight }}
        >
          +
        </Button>
      </ButtonGroup>
    </Box>
  );
}

QuantityBox.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  boxHeight: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default QuantityBox;
