import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// project imports
import OrderList from './OrderList';
import OrderDetail from './OrderDetail';
import OrderCancel from './OrderCancel';
import OrderExcRtn from './OrderExcRtn';

/**
 * 내 주문 index
 * @returns {JSX.Element}
 * @constructor
 */
function MyOrder() {
  const location = useLocation();

  return (
    <Routes location={location}>
      {/* 나의 주문목록 */}
      <Route path="/list" element={<OrderList />} />
      {/* 주문 상세 */}
      <Route path="/:orderNo/detail" element={<OrderDetail />} />
      {/* 주문취소 */}
      <Route path="/:orderNo/cancel" element={<OrderCancel />} />
      {/* 교환/반품 신청 */}
      <Route path="/:orderNo/excrtn" element={<OrderExcRtn />} />
    </Routes>
  );
}

export default MyOrder;
