import React from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';

// material-ui
import { Box } from '@mui/material';

// assets
import likeLoadingJson from 'assets/lottie/like-loader.json';

/**
 * 좋아요 버튼 클릭 시 애니메이션
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function LikeButtonLottie({ width = 30, height = 30, bottom = 0, left = 0 }) {
  const containerRef = React.useRef(null);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      lottie.loadAnimation({
        container: containerRef.current,
        autoplay: true,
        animationData: likeLoadingJson,
        rendererSettings: { clearCanvas: true }
      });
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width,
        height,
        position: 'absolute',
        bottom,
        left
      }}
    />
  );
}

export default LikeButtonLottie;

LikeButtonLottie.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number
};
