import React from 'react';
import Highlighter from 'react-highlight-words';
import { useDebounce } from 'use-debounce';
import PropTypes from 'prop-types';

// project imports
import { useLocalStorage } from 'hooks';
import * as WineOneSearch from 'services/WineOneSearch';
import { SearchHistoryDeleteIcon, SearchHistoryIcon, SmallButton, SpaceBetweenBox } from 'components';
import { SearchField } from 'components/search';

// material-ui
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// assets
import ArrowBackImg from 'assets/images/arrow-back.png';

// 추천 검색어 client
const querySuggestionClient = WineOneSearch.createElasticAppSearchClient(WineOneSearch.ENGINE.PDATA);

/**
 * 리뷰 pdata 검색 영역
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function SearchDialog({ open, onClose = () => false, onSearch = () => false, value: valueProp, queryHistory: queryHistoryProp = [] }) {
  // 검색어
  const [value, setValue] = React.useState(valueProp);
  // Debounced 검색어 - https://www.npmjs.com/package/use-debounce
  const [searchValue] = useDebounce(value, 200);

  // 검색어 Property hook
  React.useEffect(() => {
    if (open) setValue(() => valueProp);
  }, [valueProp, open]);

  // Debounced 검색어 hook -> 추천 검색어 조회
  React.useEffect(() => {
    // 상품 검색모달이 닫혀있을 경우
    if (!open) {
      console.debug('[상품검색] 검색창이 닫혀있는 상태입니다. 추천쿼리를 조회하지 않습니다.');
      return undefined;
    }

    // 입력된 검색어가 없을 경우
    if (searchValue.length === 0) {
      console.log('[상품검색] 검색어가 없습니다. 추천쿼리를 조회하지 않습니다.');
      setSuggestQueries(() => []); // 추천검색어 비움
      return;
    }

    fetchSuggestQueries(searchValue);
  }, [open, searchValue]);

  // 최근 검색어
  const [queryHistory, setQueryHistory] = useLocalStorage('wine-query-history', [...queryHistoryProp]);

  // n번째 최근 검색이력 삭제
  const removeQueryHistory = React.useCallback(
    (index) => {
      const _queryHistory = [...queryHistory];
      _queryHistory.splice(index, 1);
      console.log(index + '번 째 검색이력 삭제. queryHistory=', _queryHistory);
      setQueryHistory(_queryHistory);
    },
    [queryHistory]
  );

  // 추천 검색어 목록
  const [suggestQueries, setSuggestQueries] = React.useState([]);

  // 추천 검색어 목록 조회
  const fetchSuggestQueries = React.useCallback(async (query) => {
    console.debug('[상품검색] 추천 검색어 조회 : ', query);

    // 추천 검색어 조회 요청 from Elastic Search
    const response = await querySuggestionClient
      .querySuggestion(query.trim(), {
        size: 10,
        types: {
          documents: {
            fields: ['name_ko', 'name_en']
          }
        }
      })
      .catch((error) => ({ error }));

    // 추천 검색어 조회 오류
    if (response.error) {
      const { error } = response;
      console.error('[상품검색] 추천 검색어 조회 중 오류가 발생했습니다.', error);
      return;
    }

    console.debug('추천 검색어 조회결과: ', response);

    const {
      meta: { request_id },
      results: { documents }
    } = response;

    // 추천 검색어 목록
    const suggestQueries = documents.map((document) => ({ ...document, request_id }));
    setSuggestQueries(() => [...suggestQueries]);
  }, []);

  // 추천어 조회 완료여부
  const isSuggestQueriesLoaded = React.useMemo(() => {
    return value.trim().length > 0 && suggestQueries.length > 0;
  }, [value, suggestQueries]);

  // 상품 검색
  const searchProduct = React.useCallback(
    (query) => {
      onSearch(query);
    },
    [onSearch]
  );

  // 검색 초기화
  function resetSearch() {
    setValue('');
    searchProduct('');
  }

  // render
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar position="relative" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={onClose} aria-label="close" sx={{ width: 46 }}>
            <Box component="img" src={ArrowBackImg} sx={{ height: 34 }} alt="" />
          </IconButton>
          <FormStyledTextField
            onSubmit={(e) => {
              e.preventDefault();
              searchProduct(value);
              return false;
            }}
          >
            <Stack direction="row" spacing="8px" width="100%" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SearchField
                autoFocus
                value={value}
                onInput={(e) => {
                  setValue(e.target.value);
                }}
                placeholder="상품명으로 검색"
              />
              <Button
                type="button"
                size="medium"
                fontSize="14px"
                tabIndex={-1}
                sx={{
                  justifyContent: 'flex-end',
                  minWidth: 'auto',
                  width: '58px',
                  pl: '8px',
                  pr: '20px',
                  backgroundColor: '#ffffff',
                  color: alpha('#140229', 0.7)
                }}
                onClick={() => {
                  resetSearch();
                }}
              >
                취소
              </Button>
            </Stack>
          </FormStyledTextField>
        </Toolbar>
      </AppBar>

      {/* 검색어 입력 안한경우 */}
      {value.trim().length === 0 ? (
        <Container>
          <SpaceBetweenBox pt={1}>
            <Typography variant="caption" fontWeight={800} fontSize={11}>
              최근 검색어
            </Typography>
            <SmallButton
              onClick={() => {
                setQueryHistory(() => []);
              }}
              disabled={queryHistory.length === 0}
            >
              모두 지우기
            </SmallButton>
          </SpaceBetweenBox>

          {/* 최근 검색어 목록 표현[start] */}
          <Box>
            <List>
              {/* 최근 검색어가 없을 경우 */}
              {queryHistory.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography fontSize="18px" fontWeight={300} sx={{ opacity: 0.7, py: 2 }}>
                    최근 검색기록이 없습니다.
                  </Typography>
                </Box>
              )}

              {/* 최근 검색어 목록 표현 */}
              {queryHistory.map((oldQuery, i) => (
                <ListItem key={`old-query-${i}`} sx={{ p: 0 }}>
                  <SpaceBetweenBox width="100%">
                    <ListItemButton
                      onClick={() => {
                        searchProduct(oldQuery);
                      }}
                      sx={{ p: 0 }}
                    >
                      <Box display="flex" alignItems="center">
                        <SearchHistoryIcon />
                        <Typography component="span" fontSize="16px" sx={{ ml: '5px' }}>
                          {oldQuery}
                        </Typography>
                      </Box>
                    </ListItemButton>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        removeQueryHistory(i);
                      }}
                    >
                      <SearchHistoryDeleteIcon />
                    </IconButton>
                  </SpaceBetweenBox>
                </ListItem>
              ))}
            </List>
          </Box>
          {/* 최근 검색어 목록 표현[end] */}
        </Container>
      ) : (
        <List>
          {/* 추천 검색어 목록 표현[start] */}
          {isSuggestQueriesLoaded ? (
            // 추천 검색어 목록 보여주기
            suggestQueries.map(({ suggestion }, i) => {
              return (
                <ListItemButton key={`suggestion-wine-name-${i}`} onClick={() => searchProduct(suggestion)} sx={{ px: '20px' }}>
                  <ListItem sx={{ pl: 0, py: 0, fontSize: '14px', fontWeight: 400 }}>
                    <HighlightedText mark={value} text={suggestion} />
                  </ListItem>
                </ListItemButton>
              );
            })
          ) : (
            // 조회된 추천 검색어가 없을 때, 입력값(value)을 표현
            <ListItemButton
              onClick={() => {
                searchProduct(value);
              }}
              sx={{ px: '20px' }}
            >
              <ListItem sx={{ pl: 0, py: 0, fontSize: '14px', fontWeight: 400 }}>
                <HighlightedText mark={value} text={value} />
              </ListItem>
            </ListItemButton>
          )}
          {/* 추천 검색어 목록 표현[end] */}
        </List>
      )}
    </Dialog>
  );
}

SearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  queryHistory: PropTypes.arrayOf(PropTypes.string)
};
export default SearchDialog;

const FormStyledTextField = styled('form')(
  ({ theme }) => `
  flex-grow: 1;
  margin-right: -20px;
  height: 42px;
  & .MuiFormControl-root {
    background-color: ${theme.palette.grey[50]};
    border-radius: 4px;
    padding: 0;
  }
  `
);

// eslint-disable-next-line react/prop-types
const HighlightedText = React.memo(({ mark, text }) => {
  return (
    <Highlighter
      highlightTag="strong"
      highlightStyle={{ fontWeight: 800 }}
      searchWords={`${mark}`.trim().split(' ')}
      autoEscape={true}
      textToHighlight={text}
    />
  );
});
