import PropTypes from 'prop-types';
import React from 'react';

// import mui
import { Box, Typography } from '@mui/material';

// assets
import notDownload from '../assets/promotion coupon_download area.png';
import alreadyDownload from '../assets/promotion detail_coupon_download complete.png';

/**
 * pdata 프로모션 페이지 쿠폰
 * @param event
 * @returns {JSX.Element}
 * @constructor 최효근<hkchoi@winone.io>
 */
function PromotionCoupon({ coupon, setCouponDrawerOpen, setCouponList, isStart, notStartClick }) {
  // 쿠폰팩인지 여부
  const isPack = coupon.p_cpn_type === 'pack';

  // 이미 다운받았는지 여부
  const alreadyDownloaded = React.useMemo(() => {
    if (isPack) {
      return coupon.coupons.every((coupon) => coupon.my.downloaded === 1);
    }
    return coupon.my.downloaded === 1;
  }, [coupon]);

  // 쿠폰 클릭시
  const clickCoupon = () => {
    if (!isStart) {
      notStartClick();
      return;
    }

    if (isPack) {
      setCouponList(coupon.coupons);
    } else {
      setCouponList([coupon]);
    }
    setCouponDrawerOpen(true);
  };

  // 쿠폰팩일경우 할인 총합 구하기
  const findTotalAmount = React.useMemo(() => {
    if (isPack) {
      let amount = 0;
      coupon.coupons.forEach((coupon) => {
        if (coupon.discount_type === 'percent') {
          amount += coupon.max_dis_amount;
        }
        amount += coupon.amount;
      });
      return amount;
    }
    return coupon.amount;
  }, [coupon, isPack]);

  return (
    <Box
      mb="16px"
      width="100%"
      borderRadius="8px"
      boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
      onClick={() => {
        clickCoupon();
      }}
    >
      <Box width="100%" height="93px" display="flex">
        <Box width="100%" sx={{ bgcolor: '#F3F2F5' }}>
          <Box padding="16px">
            <Box mb="8px" display="flex">
              {isPack ? (
                <Box display="flex">
                  <Typography color="rgba(119, 74, 210, 0.80)" fontSize="24px" fontWeight="700">
                    총&nbsp;
                  </Typography>
                  <Typography fontFamily="D-DIN" lineHeight="35px" fontSize="32px" color="#774AD2" fontWeight="700">
                    {findTotalAmount.toLocaleString()}
                  </Typography>
                  <Typography lineHeight="35px" fontSize="24px" color="#774AD2" fontWeight="800">
                    원
                  </Typography>
                </Box>
              ) : coupon.discount_type === 'price' ? (
                <Box display="flex">
                  <Typography fontFamily="D-DIN" lineHeight="35px" fontSize="32px" color="#774AD2" fontWeight="700">
                    {coupon.amount.toLocaleString()}
                  </Typography>
                  <Typography lineHeight="35px" fontSize="24px" color="#774AD2" fontWeight="800">
                    원
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography fontFamily="D-DIN" lineHeight="32px" fontSize="32px" color="#774AD2" fontWeight="700">
                    {coupon.percent}
                  </Typography>
                  <Typography color="#774AD2" fontSize="24px" fontWeight="800">
                    %
                  </Typography>
                </Box>
              )}
              <Typography color="#774AD2" fontSize="24px" fontWeight="700">
                &nbsp;할인
              </Typography>
            </Box>
            {isPack ? (
              <Typography fontSize="12px" fontWeight="800" color="rgba(20, 2, 41, 0.60)">
                {coupon.name} 쿠폰팩&nbsp;
                <Typography component="span" fontSize="12px" fontWeight="800" color="rgba(20, 2, 41, 0.40)">
                  (쿠폰 {coupon.coupons.length}종)
                </Typography>
              </Typography>
            ) : (
              <Typography fontSize="12px" fontWeight="800" color="rgba(20, 2, 41, 0.60)">
                {coupon.name}
              </Typography>
            )}
          </Box>
        </Box>

        <Box width="76px" height="93px" component="img" src={alreadyDownloaded ? alreadyDownload : notDownload} />
      </Box>
    </Box>
  );
}

export default PromotionCoupon;

PromotionCoupon.propTypes = {
  coupon: PropTypes.object,
  setCouponDrawerOpen: PropTypes.func,
  setCouponList: PropTypes.func,
  isStart: PropTypes.bool,
  notStartClick: PropTypes.func
};
