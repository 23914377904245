import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// project imports
import Footer from 'components/Footer';
import { AlarmButton, CartButton } from 'components/buttons';
// import { AlarmButton, CartButton } from 'components/buttons';
// material-ui
import { Box, CircularProgress, Stack, Toolbar } from '@mui/material';
import UserLocationButton from '../UserLocationButton';
import { useSelector } from '../../../../../store';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * '오더' 화면 레이아웃
 *
 * @param {node} children
 * @param {node} toolbar
 * @param {node} stickyToolbar
 * @param {boolean} showFooter
 * @returns {MallLayout}
 * @constructor
 */
function MallLayout({ children, toolbar = null, stickyToolbar = null, showFooter = true }) {
  const location = useLocation();
  const navigate = useNavigate();
  const PIN_MAP_OPEN_HASH = '#pinmap-open';

  // 사용자 위치정보, 서비스 상수
  const { userLocation } = useSelector((state) => state);

  // 핀선택지도 열기
  const openPinMap = () => {
    console.log('[오더] 핀 선택지도 열기 ');
    navigate(PIN_MAP_OPEN_HASH, { state: location.state });
  };

  const alarmButtonComponent = useMemo(() => {
    return <AlarmButton showBadge />;
  }, []);

  // '사용자 위치' 설정 버튼
  const userLocationButton = React.useMemo(
    () => (!userLocation.loading ? <UserLocationButton onClick={openPinMap} /> : <CircularProgress color="brand" size={12} />),
    [userLocation]
  );

  // render
  return (
    <Box>
      <Toolbar>
        <Box component="section" height="100%" width="100%" display="flex" justifyContent="space-between">
          {/* 핀선택지도 */}
          <Box height={(theme) => theme.components.MuiAppBar.styleOverrides.root.minHeight} display="flex" alignItems="center" top={0}>
            <Box minWidth={100} maxWidth="45vw">
              {userLocationButton}
            </Box>
          </Box>
        </Box>

        <Stack direction="row" spacing="8px" sx={{ display: 'flex', alignItems: 'center' }}>
          {/* 장바구니 버튼 */}
          <CartButton showBadge />

          {/* George 알람 버튼: 작업 완료 후 공개 */}
          {alarmButtonComponent}
        </Stack>
      </Toolbar>

      {/* Secondary Toolbar */}
      {toolbar && <>{toolbar}</>}

      {/* Secondary Toolbar */}
      {stickyToolbar !== null && <>{stickyToolbar}</>}

      <main>{children}</main>

      {showFooter && <MemoizedFooter />}
    </Box>
  );
}

MallLayout.propTypes = {
  children: PropTypes.node,
  toolbar: PropTypes.node,
  stickyToolbar: PropTypes.node,
  showFooter: PropTypes.bool
};

export default MallLayout;

const MemoizedFooter = React.memo(Footer);
