import axios from 'axios';
import { NODE_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';

/**
 * 현재 진행 중인 프로모션 상세 조회
 */
export async function getPromotion(id: number) {
  return axios
    .get(NODE_URL.GET__GET__PROMOTION.replace(':promoId', id))
    .then((response) => response.data)
    .catch((error) => {
      console.error('[getPromotion] 프로모션 상세정보 조회 실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `프로모션 상세정보 조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `getPromotion`,
          param: { location: window?.location?.href ?? 'Unknown' }
        }).catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 진행 중인 프로모션 쿠폰 리스트 조회
 */
export async function getPromotionCoupons(id) {
  return axios
    .get(NODE_URL.GET__GET__PROMOTION_COUPONS.replace(':promoId', id))
    .then((response) => response.data)
    .catch((error) => {
      console.error('[getPromotion] 프로모션 상세정보 조회 실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `프로모션 상세정보 조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `getPromotion`,
          param: { location: window?.location?.href ?? 'Unknown' }
        }).catch(console.error);
      }
      throw error;
    });
}
