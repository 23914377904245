import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material-ui
import { AppBar, Backdrop, Box, Container, CircularProgress, Toolbar, Typography } from '@mui/material';
import BackButton from 'components/buttons/BackButton';
import WoDivider from 'components/dialog/WoDivider';
import LinearProgress from '@mui/material/LinearProgress';

const defaultBackdrop = <CircularProgress color="success" />;

/**
 * 주문하기 레이아웃
 * @constructor
 */
function OrderLayout({
  children,
  title = '샵 상세',
  appBarRef: appBarRefProp = () => {},
  showBackDrop = false,
  backDrop = null,
  loading = false,
  onBackButtonClick: onBackButtonClickProp,
  ...other
}) {
  const navigate = useNavigate();

  const appBarRef = useRef(null);

  useEffect(() => {
    appBarRefProp(appBarRef);
  }, []);

  const onBackButtonClick = (e) => {
    if (onBackButtonClickProp && typeof onBackButtonClickProp === 'function') {
      onBackButtonClickProp(e);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box {...other}>
      <Backdrop open={showBackDrop} sx={{ zIndex: 1200 }}>
        {backDrop != null ? backDrop : defaultBackdrop}
      </Backdrop>
      <AppBar id={'order-layout-app-bar'} position="sticky" ref={appBarRef} elevation={0} sx={{ px: [0, 0, 0, 0] }}>
        <Container maxWidth="xs" sx={{ position: 'relative', p: [0, 0, 0, 0] }}>
          <Toolbar sx={{ px: [2, 2, 2, 2], height: ['54px', '54px', '54px', '54px'], minHeight: ['54px', '54px', '54px', '54px'] }}>
            {/* 페이지 제목 */}
            <Box position="absolute" width="100%" left={0} display="flex" justifyContent="center" alignItems="center">
              <Typography component="div" fontSize={18} fontWeight={800} maxWidth={'150px'} noWrap>
                {title}
              </Typography>
            </Box>

            {/* 뒤로가기 버튼 */}
            <BackButton color="inherit" onClick={onBackButtonClick} />
            <Box flexGrow={1} />
          </Toolbar>
          <WoDivider px={2} />
          <Box position="absolute" bottom={-4} left={0} width="100%" zIndex={1} px={2}>
            {loading && <LinearProgress color="brand" />}
          </Box>
        </Container>
      </AppBar>
      <main>{children}</main>
    </Box>
  );
}

OrderLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  appBarRef: PropTypes.func,
  showBackDrop: PropTypes.bool,
  backDrop: PropTypes.node,
  loading: PropTypes.bool,
  onBackButtonClick: PropTypes.func
};

export default OrderLayout;
