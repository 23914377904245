import React from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// project imports
import { useSelector } from 'store';
import BackHeader from 'layouts/Main/BackHeader';
import { useAmplitude, useScriptRef } from 'hooks';
import { getSelfPromotion, getSelfPromotionList } from 'services/PromotionService';
import { SpaceBetweenBox } from '../../../components';
import ProductCard from './components/ProductCard';
import { WoAlert } from '../../../utils/kmwine-alerts';
import { amplitude } from '../../../index';
import BottomButtonSheet from './components/BottomButtonSheet';
import { openSnackbar } from '../../../store/slices/snackbar';
import { addProduct } from '../../../store/slices/cart';
import { PdataInfo } from 'components/pdata';
import CartSnackbar from '../../vendor/VendorDetail/components/CartSnackbar';

// material-ui
import { Box, ButtonBase, Chip, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

// assets
import ShopIcon from '../../../assets/images/ic_shop.png';

// 조회할 프로모션 페이지 사이즈
const PROMOTION_PAGE_SIZE = 30;

/**
 * 프로모션 진행 중 와인샵 목록 화면
 * 홈 > 프로모션 진행 중 와인샵 > 더보기
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>, 최효근<hkchoi@wineone.io>
 */
function SelfPromotions() {
  const scriptedRef = useScriptRef();

  const { userLocation } = useSelector((state) => state);

  const location = useLocation();

  const navigationType = useNavigationType();

  const { logEvent } = useAmplitude();

  const globalDispatch = useDispatch();
  const navigate = useNavigate();

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('promo_list_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('promo_list_showPdataInfo', false);

  // 마지막주문상품
  const [lastOrderItemMap, setLastOrderItemMap] = useLocationState('last_order_item_map', null);

  // 주문상품(함께 주문) 초기값
  const initialOrderItemMap = React.useMemo(() => {
    let initVal = {};
    if (navigationType !== 'PUSH' && lastOrderItemMap) {
      initVal = { ...lastOrderItemMap };
    }
    return initVal;
  }, [location]);

  // 주문상품
  const [orderItemMap, setOrderItemMap] = React.useState(initialOrderItemMap);

  // 전체 주문 병 수
  const [totalBottleCount, setTotalBottleCount] = useLocationState('promotion_totalBottleCount', 0);
  // 전체 주문 병 수 계산
  const [totalPrice, setTotalPrice] = useLocationState('promotion_totalPrice', 0);

  // 장바구니 로딩
  const [cartLoading, setCartLoading] = React.useState(false);

  // 카트 스낵바 상태
  const [cartSnackbar, setCartSnackbar] = React.useState(false);

  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 프로모션 목록
  const [promotionList, setPromotionList] = useLocationState('promotions_self_promo', { loaded: false, coord: {}, data: [], error: false });

  // 프로모션 상품 목록
  const [promotionProductList, setPromotionProductList] = useLocationState('promotions_self_promo_products', {
    loaded: false,
    error: false,
    data: []
  });

  // 물고들어온 active promotion이 있을경우
  const initialActivePromotion = React.useMemo(() => {
    if (location.state?.activePromotion) {
      return location.state.activePromotion;
    }
    return null;
  }, [location.state?.activePromotion]);

  // 선택된 프로모션
  const [activePromotion, setActivePromotion] = useLocationState('promotions_active_self_promotion', initialActivePromotion);

  // 클릭한 칩의 내용을 state로 업데이트
  const handleChipClick = async (promotion) => {
    // 이미 선택된 셀프 프로모션 칩을 클릭했을 경우 아무일도 일어나지 않음
    if (promotion._id === activePromotion?._id) return;

    // 이미 선택한 상품이 있을 경우
    if (totalBottleCount > 0) {
      await WoAlert.fire({
        title: (
          <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
            알림
          </Typography>
        ),
        html: `<div style='line-height: 1.5rem; text-align: left; font-size: 16px'>샵 변경시 담은 상품이 초기화 됩니다.<br/> 변경하시겠습니까?</div>`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        }
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setLoading(true);
          setOrderItemMap({});
          setTotalBottleCount(0);
          setTotalPrice(0);
          setActivePromotion(promotion);
          await fetchSelfPromotionProductList(promotion._id);
          setLoading(false);
        }
      });
    } else {
      setLoading(true);
      setActivePromotion(promotion);
      await fetchSelfPromotionProductList(promotion._id);
      setLoading(false);
    }
  };

  // 셀프 프로모션 목록 조회
  const fetchSelfPromotionList = async () => {
    setLoading(true);
    setPromotionList({ loaded: false, data: [], error: false });

    // request body
    const body = {
      page: { current: 1, size: PROMOTION_PAGE_SIZE },
      states: ['ING'],
      with_product: false,
      order_by: [['distance', 'asc']],
      coordinate: userLocation.coord
    };

    const result = await getSelfPromotionList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PromotionSection][fetchSelfPromotionList] Unmounted component.');
      return;
    }

    // 프로모션 목록 조회 중 오류 발생
    if (result.error) {
      console.error('[SelfPromotions][fetchSelfPromotionList] 셀프 프로모션 목록 조회실패', result.error);
      setPromotionList({ loaded: true, data: [], error: true });
      return;
    }

    // 프로모션 목록 조회 중 오류 발생 진행중인 프로모션이 없을 경우
    if (result.promotions.length === 0) {
      WoAlert.fire(`진행중인 프로모션이 없습니다`, '', 'warning').then(() => {
        navigate('/');
      });
      return false;
    }

    setPromotionList({ loaded: true, coord: userLocation.coord, data: [...result.promotions], error: false });
    setLoading(false);

    // 처음 호출시 slide 설정
    if (activePromotion === null) {
      setActivePromotion(result.promotions[0]);
      await fetchSelfPromotionProductList(result.promotions[0]._id);
    }

    // 물고들어온 activepromotion이 있을시
    if (initialActivePromotion) {
      await fetchSelfPromotionProductList(initialActivePromotion._id);
    }
  };

  // 셀프 프로모션 상세정보 조회
  const fetchSelfPromotionProductList = async (id) => {
    setPromotionProductList({ loaded: false, data: [], error: false });
    const result = await getSelfPromotion(id).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[SelfPromotions][fetchPromotionProductList] Unmounted component.');
      return;
    }

    if (result.error) {
      console.error('셀프 프로모션 상세조회 실패', result.error);
      setPromotionProductList({ loaded: false, data: [], error: true });
      return;
    }

    setPromotionProductList({ loaded: true, data: result.products, error: false });
  };

  // 최초호출
  React.useEffect(() => {
    if (!promotionList.loaded) {
      fetchSelfPromotionList();
    }
  }, []);

  // 상품 추가/제외
  const handleProductItem = React.useCallback(
    (product) => {
      const productd = product.product._id;
      const _orderItemMap = { ...orderItemMap };

      const orderProductForm = {
        capacity: product.product.capacity.toString(),
        name: product.product.pdata.name,
        pdata: product.product.pdata,
        price: product.price,
        vintage: product.product.vintage,
        _id: product.product._id
      };

      // 이미 주문상품에 추가되어있을 경우 -> 주문상품에서 제외
      if (_orderItemMap[productd]) {
        console.log(`[wineone] 주문상품 제외 (${product.product.pdata.name.ko})`);
        delete _orderItemMap[productd];
      }
      // 주문상품 추가
      else {
        console.log(`[wineone] 주문상품 추가 (${product.product.pdata.name.ko})`);
        _orderItemMap[productd] = {
          product: orderProductForm,
          quantity: 1
        };

        // Amplitude Select Wine (vendor detail)
        logEvent.selectWine('vendor detail', product.product.pdata.name.ko);
      }

      setOrderItemMap(_orderItemMap);
    },
    [orderItemMap, logEvent]
  );

  // 상품 수량변경
  const handleOrderItemQuantity = (orderItem, val) => {
    console.debug(`수량 변경됨. val=${val}`);
    const { product } = orderItem;

    const _orderItemMap = { ...orderItemMap };
    _orderItemMap[product._id] = {
      product,
      quantity: val
    };
    setOrderItemMap(_orderItemMap);
  };

  // 와인 상세정보 보기
  const moveToWineInfo = async (_pdata) => {
    if (showPdataInfo) return false; // 이미 열려있을 때

    console.debug('[1KMWINE] 와인 상세정보 보기. pdata=', _pdata);
    const pdataId = _pdata._id ?? _pdata.id;

    if (!pdataId) {
      console.error('[1KMWINE] 와인 상세정보 보기. pdataId가 없음.');
      return undefined;
    }

    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  React.useEffect(() => {
    let totalCount = 0;
    let totalPrice = 0;
    const productIds = Object.keys(orderItemMap) ?? [];
    productIds.forEach((addedPrdId) => {
      const { quantity } = orderItemMap[addedPrdId];

      totalCount += quantity;
      totalPrice += orderItemMap[addedPrdId].product.price.original * quantity;
    });

    setTotalBottleCount(totalCount);
    setTotalPrice(totalPrice);
  }, [orderItemMap]);

  // 장바구니 버튼 클릭 이벤트
  const cartButtonClickEvent = async () => {
    // 선택한 상품이 없을 시
    if (totalBottleCount === 0) {
      globalDispatch(
        openSnackbar({
          open: true,
          message: `상품을 선택해주세요.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
    }
    // 선택한 상품이 있을 시
    else {
      setCartLoading(true);
      const body = [];
      const productIds = Object.keys(orderItemMap) ?? [];

      productIds.forEach((product) => {
        const { quantity } = orderItemMap[product];
        body.push({ product_id: product, cart_quantity: quantity });
      });

      const { code } = await globalDispatch(addProduct(body)).finally(() => setCartLoading(false));
      setCartSnackbar(code === 0);
    }
  };

  const selfPromotionList = React.useMemo(() => {
    let first = [];
    let second = [];
    if (promotionList.loaded) {
      // 셀프 프로모션이 6개 이상일 경우, 둘로 나누어 표현
      if (promotionList.data.length >= 6) {
        if (promotionList.data.length % 2 === 1) {
          // 홀수일 경우
          first = promotionList.data.slice(0, Math.floor(promotionList.data.length / 2) + 1);
          second = promotionList.data.slice(Math.floor(promotionList.data.length / 2) + 1);
        } else {
          // 짝수일 경우
          const halfIndex = promotionList.data.length / 2;
          first = promotionList.data.slice(0, halfIndex);
          second = promotionList.data.slice(halfIndex);
        }
      } else {
        first = promotionList.data;
      }
    }
    return {
      first,
      second
    };
  }, [promotionList.loaded, promotionList.data]);

  const moveToOrderConfirmPage = () => {
    if (totalBottleCount === 0) {
      globalDispatch(
        openSnackbar({
          open: true,
          message: `상품을 선택해주세요.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
      return;
    }
    // 주문상품목록
    const orderItems = [];

    // 주문에 추가된 상품이 있을경우
    if (orderItemMap) {
      Object.keys(orderItemMap).forEach((productId) => {
        orderItems.push(orderItemMap[productId]);
      });
    }

    setLastOrderItemMap(orderItemMap);

    // Amplitude Order Start
    amplitude.getInstance().logEvent('Order Start');

    // 픽업주문 화면으로 이동
    navigate(`/order/confirm`, {
      state: {
        mainProduct: { ...orderItems[0] },
        orderItems,
        vendor: { id: activePromotion.vendor._id }
      }
    });
  };

  // todo 24.06.17. 11:00 ~ 24.06.23. 23:00 까지는 로드샵 페스티벌로 이름이 뜨도록
  const headerTitle = React.useMemo(() => {
    const nowMmt = moment.utc();

    // UTC 시간으로 - 24.06.17 02:00 ~ 24.06.23 14:00
    const startMmt = moment.utc('24061702', 'YYMMDDHH');
    const endMmt = moment.utc('24062314', 'YYMMDDHH');
    // console.log('#nowMmt.isBetween(startMmt, endMmt): ', nowMmt.isBetween(startMmt, endMmt));
    if (nowMmt.isBetween(startMmt, endMmt)) {
      return '로드샵 페스티벌';
    }

    return '와인샵 특가';
  }, []);

  // render
  return (
    <Box>
      {/* 장바구니 상품 추가 성공 스낵바 */}
      {cartSnackbar && (
        <CartSnackbar
          open={cartSnackbar}
          close={() => setCartSnackbar(false)}
          vendor={{ _id: activePromotion.vendor._id }}
          mainProduct={{ show: false }}
          selectProducts={orderItemMap}
        />
      )}
      <BackHeader
        title={headerTitle}
        hideDivider
        showCart
        showFooter={false}
        showBottomNav={false}
        secondaryToolbar={
          promotionList.data.length > 0 ? (
            <Box padding="16px" mb="40px" bgcolor="#F3F2F5" display={promotionList.data.length > 1 ? 'block' : 'none'}>
              <Stack
                direction="row"
                className="none-scroll-bar"
                sx={{
                  pr: '20px',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  msOverflowStyle: 'none',
                  flexDirection: 'column'
                }}
              >
                <Box>
                  <Box display="flex">
                    {selfPromotionList.first.map((promotion) => {
                      return (
                        <SelfPromotionChip
                          key={promotion._id}
                          active={promotion._id === activePromotion?._id}
                          promotion={promotion}
                          onClick={() => handleChipClick(promotion)}
                        />
                      );
                    })}
                  </Box>
                  {selfPromotionList.second.length > 0 ? (
                    <Box display="flex" mt="6px">
                      {selfPromotionList.second.map((promotion) => {
                        return (
                          <SelfPromotionChip
                            key={promotion._id}
                            active={promotion._id === activePromotion?._id}
                            promotion={promotion}
                            onClick={() => handleChipClick(promotion)}
                          />
                        );
                      })}
                    </Box>
                  ) : null}
                </Box>
              </Stack>
            </Box>
          ) : null
        }
      >
        <Container>
          <Box width="100%" height="100%" pb="100px">
            {/* 에러발생시 */}
            {promotionList.loaded && promotionList.error && !loading && (
              <Box height="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography>프로모션 정보를 불러오는데 에러가 발생하였습니다</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '10px' }}
                  onClick={() => {
                    fetchSelfPromotionList();
                  }}
                >
                  다시 시도
                </Button>
              </Box>
            )}
            {activePromotion && !promotionList.error && (
              <Box height="82px">
                <SpaceBetweenBox sx={{ mt: 2 }}>
                  <Typography
                    lineHeight="1.4"
                    fontSize="21px"
                    fontWeight="800"
                    sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    {activePromotion?.name}
                  </Typography>
                  <ButtonBase
                    onClick={() => {
                      navigate(`/vendor/d/${activePromotion.vendor._id}`);
                    }}
                  >
                    <Box
                      width="89px"
                      height="26px"
                      border="1px solid #220348"
                      borderRadius="24px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginLeft="5px"
                    >
                      <Box width="14px" height="14px" component="img" src={ShopIcon} />
                      <Typography fontSize="12px" sx={{ marginTop: '1px', marginLeft: '4px' }}>
                        샵으로 가기
                      </Typography>
                    </Box>
                  </ButtonBase>
                </SpaceBetweenBox>
                <Box display="flex" alignItems="center" mt="6px" height="12px">
                  <Typography fontSize="10px" fontWeight="900" lineHeight="12px">
                    기간
                  </Typography>
                  <Typography
                    letterSpacing="0.3px"
                    lineHeight="12px"
                    fontFamily="D-DIN"
                    fontSize="11px"
                    fontWeight="700"
                    sx={{ marginLeft: '4px' }}
                  >
                    {moment(activePromotion.start_dt).format('yyyy.MM.DD')}~{moment(activePromotion.end_dt).format('yyyy.MM.DD')}
                  </Typography>
                </Box>
              </Box>
            )}
            {promotionProductList.loaded && promotionProductList.error && !loading && (
              <Box height="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography>프로모션 상품을 불러오는데 에러가 발생하였습니다</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '10px' }}
                  onClick={() => {
                    fetchSelfPromotionProductList(activePromotion._id);
                  }}
                >
                  다시 시도
                </Button>
              </Box>
            )}

            {promotionProductList.data.length > 0 &&
              activePromotion &&
              promotionProductList.loaded &&
              !promotionProductList.error &&
              promotionProductList.data.map((product, index) => (
                <ProductCard
                  key={`prd-${product.product._id}-${index}`}
                  index={index}
                  productId={product.product._id}
                  product={product}
                  orderItemMap={orderItemMap}
                  onClick={handleProductItem}
                  quantityOnClick={handleOrderItemQuantity}
                  imgOnClick={(pdata) => {
                    moveToWineInfo(pdata);
                  }}
                />
              ))}

            <Box width="100%" height="100%" mt="30px" display="flex" justifyContent="center">
              {loading && <CircularProgress />}
            </Box>
          </Box>
        </Container>

        {/* 장바구니 / 주문하기 영역 */}
        <BottomButtonSheet
          orderItemMap={orderItemMap}
          wish={true}
          onCartButtonClick={cartButtonClickEvent}
          onOrderButtonClick={moveToOrderConfirmPage}
          onDeleteClick={handleProductItem}
          totalBottleCount={totalBottleCount}
          totalPrice={totalPrice}
          cartLoading={cartLoading}
        />

        {/* pdata 상세정보 */}
        <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />
      </BackHeader>
    </Box>
  );
}

export default SelfPromotions;

function SelfPromotionChip({ promotion, active, onClick }) {
  const label = React.useMemo(() => {
    let str = promotion.vendor.biz.name;
    if (promotion.vendor.biz.rep_area_nm) {
      str += ` (${promotion.vendor.biz?.rep_area_nm})`;
    }
    return str;
  }, [promotion.vendor?.biz?.name, promotion.vendor?.biz?.rep_area_nm]);
  return (
    <Chip
      variant="outlined"
      label={label}
      sx={{
        border: active && '1.5px solid #9357E5',
        backgroundColor: '#FFFFFF',
        color: active ? '#9357E5' : '#140229',
        fontWeight: 'bold',
        marginRight: '4px',
        '&.MuiChip-clickable:hover': { backgroundColor: '#FFFFFF' }
      }}
      onClick={onClick}
    />
  );
}

SelfPromotionChip.propTypes = {
  promotion: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
