import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import ProductCard from '../ProductCard';

/**
 * 샵 상품 리스트
 *
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function ProductList({ productList, onToggleProduct, onProductClick, orderItemMap = {}, onQuantityChange }) {
  // render
  return (
    <Stack>
      {productList.length > 0 ? (
        productList.map((product, i) => {
          const { pdata } = product;
          return (
            <ProductCard
              index={i}
              productId={product?.id ?? product?._id}
              product={product}
              orderItemMap={orderItemMap}
              onClick={onToggleProduct}
              imgOnClick={() => {
                if (product) onProductClick(pdata);
              }}
              quantityOnClick={onQuantityChange}
              key={`v-prd-${product._id ?? product.id}-${i}`}
            />
          );
        })
      ) : (
        <Box height="40vh" pt={5} display="flex" justifyContent="center" minHeight="200px">
          <Typography variant="caption" color="text.secondary">
            상품을 준비 중 입니다
          </Typography>
        </Box>
      )}
    </Stack>
  );
}

ProductList.propTypes = {
  productList: PropTypes.array.isRequired,
  onToggleProduct: PropTypes.func.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  orderItemMap: PropTypes.object
};

export default ProductList;
