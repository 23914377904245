import React, { useMemo } from 'react';
import { Checkbox as MuiCheckbox, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

function Checkbox(props) {
  const { variant = 'circle', ...other } = props;
  const icon = useMemo(() => (variant === 'circle' ? <Icon /> : <FlatIcon />), [variant]);
  const checkedIcon = useMemo(() => (variant === 'circle' ? <CheckedIcon /> : <FlatCheckedIcon />), [variant]);
  return <MuiCheckbox icon={icon} checkedIcon={checkedIcon} {...other} />;
}
Checkbox.propTypes = {
  variant: PropTypes.string
};

export default Checkbox;

// eslint-disable-next-line react/prop-types
const Icon = ({ width = 30, height = 30 }) => {
  return (
    <SvgIcon viewBox="0 0 30 30" sx={{ width, height, m: '-6px' }}>
      <path style={{ opacity: 0.5, fill: 'none' }} d="M0 0h30v30H0z" />
      <circle cx="9" cy="9" r="9" transform="translate(6 6)" style={{ fill: '#220348', opacity: 0.1 }} />
      <path
        d="m4818.38 2892.326 2.953 2.953 4.987-4.986"
        transform="translate(-4807.351 -2877.786)"
        style={{ stroke: '#fff', strokeLinecap: 'round', strokeWidth: '1.5px', fill: 'none' }}
      />
    </SvgIcon>
  );
};

// eslint-disable-next-line react/prop-types
const CheckedIcon = ({ width = 30, height = 30 }) => {
  return (
    <SvgIcon viewBox="0 0 30 30" sx={{ width, height, m: '-6px' }}>
      <path style={{ opacity: 0.5, fill: 'none' }} d="M0 0h30v30H0z" />
      <circle cx="9" cy="9" r="9" transform="translate(6 6)" style={{ fill: '#220348' }} />
      <path
        d="m4818.38 2892.326 2.953 2.953 4.987-4.986"
        transform="translate(-4807.351 -2877.786)"
        style={{ stroke: '#defd9a', strokeLinecap: 'round', strokeWidth: '1.5px', fill: 'none' }}
      />
    </SvgIcon>
  );
};

// eslint-disable-next-line react/prop-types
const FlatIcon = ({ width = 30, height = 30 }) => {
  return (
    <SvgIcon viewBox="0 0 30 30" sx={{ width, height, m: '-6px' }}>
      <path style={{ opacity: 0.5, fill: 'none' }} d="M0 0h34v34H0z" />
      <path
        d="m1720.068 544.515 5.758 5.758 10.212-10.212"
        transform="translate(-1711 -529)"
        style={{ stroke: '#1d043b', strokeWidth: '2px', opacity: 0.2, fill: 'none' }}
      />
    </SvgIcon>
  );
};

// eslint-disable-next-line react/prop-types
const FlatCheckedIcon = ({ width = 30, height = 30 }) => {
  return (
    <SvgIcon viewBox="0 0 30 30" sx={{ width, height, m: '-6px' }}>
      <path style={{ opacity: 0.5, fill: 'none' }} d="M0 0h34v34H0z" />
      <path
        d="m1720.068 544.515 5.758 5.758 10.212-10.212"
        transform="translate(-1711 -529)"
        style={{ stroke: '#1d043b', strokeWidth: '2px', fill: 'none' }}
      />
    </SvgIcon>
  );
};
