import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import BourgogneRoutes from './routes';
import moment from 'moment';

import { WineOneProvider } from './contexts/WineOneContext';
import { WineOneAuthProvider as AuthProvider } from 'contexts/AuthContext';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import './assets/scss/style.scss';
import 'react-image-crop/src/ReactCrop.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'animate.css';

import Page from './components/Page';
import BottomNav from './components/BottomNav';

import ScrollToTop from './routes/components/ScrollToTop';
import axios from 'axios';

import { amplitude, analytics } from './index';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';
import { getAuth } from 'firebase/auth';

import { setCodeVersion } from 'store/slices/wo-constants';
import { useDispatch, useSelector } from './store';
import Snackbar from './components/Snackbar';
import { UnexpectedError, WineBottleLoadingLottie } from './components';
import { IS_PRODUCTION } from './config';

// material-ui
import { Backdrop, Box, Typography } from '@mui/material';
import { ChannelTalk } from './components/ChannelTalk';
import { updateLastConnectionTime } from './services/UserService';
import { PinSettingDialog } from './components/dialog/PinSettingDialog';
import useMobileNative from './hooks/useMobileNative';
import { CustomPromotions } from './views/promotion';

// 5분에 한번씩 사용자 접속정보 갱신
// let connectCheckInterval = null;

/**
 * App.jsx
 *
 * @returns {JSX.Element}
 *
 * @authors 조현권<hkcho@wineone.io>
 * @history
 *   - 2023. 01. 27. : 정확한 DAU 집계를 위해 `[amplitude] Land On Homepage` 이벤트 로그 추가
 *   - 2023. 02. 09. : ErrorBoundary FallBackComponent를 UnexpectedError로 변경
 */
function App() {
  const location = useLocation();
  const backdrop = useSelector((state) => state.backdrop);

  const sendPageViewEvent = React.useCallback(
    (docTitle) => {
      if (IS_PRODUCTION && docTitle !== process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE) {
        logEvent(analytics, 'page_view', {
          page_title: docTitle,
          page_location: window.location.origin,
          page_path: `${location.pathname}${location.search}`
        });
      }
    },
    [location.pathname, location.search]
  );

  const dispatch = useDispatch();

  const { urlPathChanged } = useMobileNative();

  React.useLayoutEffect(() => {
    // 1km wine 코드정보 가져오기
    dispatch(setCodeVersion(process.env.REACT_APP_WO_CODE_VERSION));

    // visibility 이벤트 감지
    window.onload = windowOnload;
    // 주기적인(5분) 사용자 접속정보 갱신
    // setConnectionInterval();
    return () => {
      window.onload = null;
      // clearInterval(connectCheckInterval);
    };
  }, []);

  // On Mount
  React.useEffect(() => {
    if (IS_PRODUCTION) {
      // Amplitude Land On Homepage
      amplitude.getInstance().logEvent('Land On Homepage');
    }
  }, []);

  // location.path가 변경될 때 마다 URL이 변경되었음을 Mobile Native에 알림
  React.useEffect(() => {
    urlPathChanged(location.pathname);
  }, [location.pathname]);

  // render
  return (
    <WineOneProvider location={location}>
      <Helmet
        onChangeClientState={(newState) => {
          // console.log('[Helmet][onChangeClientState]', newState);
          const docTitle = newState.title ?? process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
          document.title = docTitle;
          sendPageViewEvent(docTitle);
        }}
      />

      {/* 최상위 backdrop */}
      <Backdrop open={backdrop.open} sx={{ zIndex: 2400 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <WineBottleLoadingLottie />
          <Typography variant="caption" color="#fff">
            {backdrop.text}
          </Typography>
        </Box>
      </Backdrop>
      <Page>
        <AuthProvider>
          <ScrollToTop />

          <ErrorBoundary FallbackComponent={UnexpectedError}>
            <CustomPromotions />
            <BourgogneRoutes location={location} />
          </ErrorBoundary>
          {/* 하단 네비게이션 */}
          <BottomNav />

          {/* 최초 핀설정 */}
          <PinSettingDialog />

          {/* 채널톡 */}
          <ChannelTalk location={location} />

          {/* Global Snackbar */}
          <Snackbar />
        </AuthProvider>
      </Page>
    </WineOneProvider>
  );
}

export default App;

// iOS Safari 100vh issue
// 100vh -> calc(var(--vh, 1vh) * 100)
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Axios header global setting.
axios.defaults.headers.common['x-wineone-client'] = '1kmwine';

// Axios' authentication header setting.
axios.interceptors.request.use(async (config) => {
  const auth = getAuth();

  if (auth.currentUser) {
    const { accessToken, stsTokenManager } = auth.currentUser;
    let bearerToken = accessToken;

    // 토큰이 만료되었을 경우 갱신
    if (stsTokenManager.isExpired) {
      console.debug('[App] 사용자 토큰이 만료되어 갱신합니다.');
      const { token } = await auth.currentUser.getIdTokenResult(true);
      bearerToken = token;

      console.debug(`[App]  ㄴ 갱신된 토큰=${bearerToken}`);
    }

    config.headers.Authorization = `Bearer ${bearerToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

function windowOnload() {
  // check the visiblility of the page
  let hidden, visibilityState, visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
    visibilityState = 'visibilityState';
  } else if (typeof document.mozHidden !== 'undefined') {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
    visibilityState = 'mozVisibilityState';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
    visibilityState = 'msVisibilityState';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
    visibilityState = 'webkitVisibilityState';
  }

  if (typeof document.addEventListener === 'undefined' || typeof hidden === 'undefined') {
    /* DO NOTHING */
  } else {
    console.debug('--------------------- Add visibility change event callback ---------------------');
    document.addEventListener(
      visibilityChange,
      function () {
        updateLastConnectionTime().then((result) => {
          if (result?.code === 0) console.debug('[1KMWINE] 마지막 접속시간을 갱신했습니다.[event=visibilityChange]');
        });

        console.debug(`[1KMWINE] visibility changed. state=${visibilityState}`);

        const auth = getAuth();
        switch (document[visibilityState]) {
          case 'visible':
            try {
              window.webkit?.messageHandlers?.kmwine?.postMessage('urlPathChanged');
            } catch (e) {
              console.error(`[webkit post message] 'urlPathChanged' error.`, e);
            }

            // setConnectionInterval();

            if (auth.currentUser) {
              try {
                const { stsTokenManager } = auth.currentUser;

                // Firebase authentication token이 만료되었을 경우 갱신
                if (stsTokenManager.isExpired) {
                  console.debug('[1KMWINE] 사용자 토큰이 만료되었습니다. 토큰을 갱신합니다.');
                  auth.currentUser.getIdTokenResult(true).then(() => {
                    console.debug('[1KMWINE:App.js] 사용자 access token을 갱신했습니다.');
                  });
                } else {
                  const expirationMmt = moment(stsTokenManager.expirationTime);
                  console.debug(`[1KMWINE] 토큰이 아직 유효합니다. 토큰 만료시간='${expirationMmt.format('YY-MM-DD HH:mm')}'`);
                }
              } catch (e) {
                /* DO NOTHING */
              }
            }

            break;

          // hidden
          case 'hidden':
            console.debug('[1KMWINE] 5분 간격의 사용자의 접속여부를 갱신기능을 해제합니다.');
            // clearInterval(connectCheckInterval);
            break;
          default: // nothing
        }
      },
      false
    );
  }
}

// function setConnectionInterval() {
//   console.debug('[1KMWINE] 5분에 한번씩 사용자의 접속여부를 갱신합니다.');
//   clearInterval(connectCheckInterval); // 기존 인터벌 있을경우 취소
//
//   connectCheckInterval = setInterval(() => {
//     updateLastConnectionTime()
//       .then((result) => {
//         if (result?.code === 0) console.debug('[1KMWINE] 마지막 접속시간을 갱신했습니다.[event=interval]');
//       })
//       .catch(() => {
//         /* DO NOTHING */
//       });
//   }, 1000 * 60 * 5);
// }
