class ChannelService {
  constructor() {
    this.loadScript();
  }

  // eslint-disable-next-line class-methods-use-this
  loadScript() {
    const w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.');
    }
    const ch = function () {
      // eslint-disable-next-line prefer-rest-params
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  /** 채널톡 초기화 */
  // eslint-disable-next-line class-methods-use-this
  boot(settings, callback) {
    // console.log('--------------------- boot settings: ', settings);
    window.ChannelIO('boot', settings, function onBoot(error, user) {
      if (typeof callback === 'function') callback(error, user);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  shutdown() {
    // console.debug('[채널톡] shotdown.');
    window.ChannelIO('shutdown');
  }

  // eslint-disable-next-line class-methods-use-this
  showChannelButton() {
    window.ChannelIO('showChannelButton');
  }

  // eslint-disable-next-line class-methods-use-this
  hideChannelButton() {
    window.ChannelIO('hideChannelButton');
  }

  // eslint-disable-next-line class-methods-use-this
  updateUser(loginUserProps, callback) {
    const user =
      loginUserProps == null
        ? {
            id: null,
            memberId: null,
            email: null,
            alert: 0,
            profile: null
          }
        : loginUserProps;

    // console.debug('[채널톡] 사용자 업데이트 요청.', user); // 넘어오는 값 체크용

    try {
      window.ChannelIO('updateUser', user, function onProfileChanged(error, user) {
        if (typeof callback === 'function') {
          callback(error, user);
        } else if (error) {
          console.error('[채널톡] 사용자 정보수정 실패. ', error);
        } else {
          console.debug('[채널톡] 사용자 정보수정 성공. ', user);
        }
      });
    } catch (e) {
      console.error('[채널톡] 사용자 정보수정 오류.', e);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addTags(tags = ['1KMWINE', '사용자']) {
    window.ChannelIO('addTags', tags, function onAddTags(error, user) {
      if (error) {
        console.error('[채널톡] addTags 오류. ', error);
      } else {
        console.log('[채널톡] addTags success. ', user);
      }
    });
  }
}

export default new ChannelService();
