import React from 'react';
import { SvgIcon } from '@mui/material';

/* eslint-disable react/prop-types */
export function HerePinIcon({ height = 43, width = 49.744 }) {
  return (
    <SvgIcon viewBox="0 0 49.744 43" sx={{ width, height }}>
      <ellipse cx="9.5" cy="3" rx="9.5" ry="3" transform="translate(12 37)" style={{ opacity: 0.3, fill: '#4a1393' }} />
      <g style={{ fillRule: 'evenodd', fill: '#4a1393' }}>
        <path
          d="m49.023-96-41.149.024-8.6 26.209 25.2-.018L19.963-56l19.352-10.392z"
          style={{ stroke: 'none' }}
          transform="translate(.721 96)"
        />
        <path
          d="M47.642-95 8.6-94.976.659-70.769l25.197-.017-4.166 12.723 16.8-9.021L47.641-95M49.022-96l-9.707 29.608L19.963-56l4.513-13.785-25.197.017 8.595-26.208L49.023-96z"
          style={{ fill: 'rgba(34,3,72,.2)', stroke: 'none' }}
          transform="translate(.721 96)"
        />
      </g>
      <path
        d="m17.055-34.842-4.893 15H9.877l1.392-4.267H9.136l-.073.151a5.726 5.726 0 0 1-2.177 2.419 6.2 6.2 0 0 1-3.28.89 4.849 4.849 0 0 1-1.732-.277 2.489 2.489 0 0 1-1.134-.822 2.5 2.5 0 0 1-.474-1.327 4.422 4.422 0 0 1 .208-1.608l.057-.181 1.852-5.677.062-.181a6.208 6.208 0 0 1 .841-1.608 5.921 5.921 0 0 1 1.34-1.327 5.772 5.772 0 0 1 1.674-.824 6.5 6.5 0 0 1 1.913-.277 3.741 3.741 0 0 1 2.609.806 2.493 2.493 0 0 1 .721 2.161l-.015.157h2.2l1.047-3.208zm15.661 0-4.893 15h-2.285l4.893-15zm-4.941.42-.5 1.545-.1.3a18.277 18.277 0 0 1-1.959 4.005 19.627 19.627 0 0 1-2.989 3.6 16.974 16.974 0 0 1-3.61 2.62 12.4 12.4 0 0 1-3.938 1.361l.674-2.066.211-.046a9.723 9.723 0 0 0 2.707-1.1 13.54 13.54 0 0 0 2.706-2.091 16.094 16.094 0 0 0 2.267-2.83 14.822 14.822 0 0 0 1.583-3.359h-6.322l.636-1.948zM7.584-32.843a2.825 2.825 0 0 0-1.785.6 3.288 3.288 0 0 0-1.069 1.514l-.047.137L2.8-24.814l-.042.137a1.82 1.82 0 0 0 .085 1.509 1.515 1.515 0 0 0 1.39.6 2.825 2.825 0 0 0 1.785-.6 3.288 3.288 0 0 0 1.069-1.509l.047-.137 1.885-5.778.042-.137a1.82 1.82 0 0 0-.085-1.509 1.515 1.515 0 0 0-1.392-.605zm5.493 3.191h-2.083l-1.161 3.561h2.083z"
        transform="translate(8.745 40.375)"
        style={{ fill: '#defd9a' }}
      />
    </SvgIcon>
  );
}

/* eslint-disable react/prop-types */
export function CurrentLocationIcon({ height = 34, width = 34, color = 'inherit' }) {
  return (
    <SvgIcon htmlColor={color} viewBox="0 0 34 34" sx={{ width, height }}>
      <g transform="translate(7 7)" style={{ stroke: 'currentcolor', strokeWidth: '2px', fill: 'none' }}>
        <circle cx="10" cy="10" r="10" style={{ stroke: 'none' }} />
        <circle cx="10" cy="10" r="9" style={{ fill: 'none' }} />
      </g>
      <g transform="translate(14 14)" style={{ stroke: 'currentcolor', strokeWidth: '2px', fill: 'none' }}>
        <circle cx="3" cy="3" r="3" style={{ stroke: 'none' }} />
        <circle cx="3" cy="3" r="2" style={{ fill: 'none' }} />
      </g>
      <path d="M0 18v-4h2v4zM0 4V0h2v4z" transform="translate(16 8)" style={{ fill: 'currentcolor' }} />
      <path d="M0 18v-4h2v4zM0 4V0h2v4z" transform="rotate(-90 13 5)" style={{ fill: 'currentcolor' }} />
    </SvgIcon>
  );
}
