import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// project imports
import { useScriptRef } from 'hooks';
import { getReviewThreadList } from 'services/ReviewThreadService';
import { HomeContentHeader } from '../index';
import { MainReviewThreadCard } from './components';

// material-ui
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ShowMoreButton, SpaceBetweenBox } from '../../../../../components';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';

const useStyles = makeStyles((theme) => ({
  contentSwiper: {
    marginTop: '6px',
    marginLeft: '-20px',
    marginRight: '-20px',
    paddingBottom: '32px',
    '--swiper-pagination-color': theme.palette.brand.main
  }
}));

// 리뷰 쓰레드 정보 state
const initMainReviewThread = Object.freeze({
  loaded: false,
  error: false,
  list: []
});

/**
 * 홈 화면에 표시될 메인 리뷰 쓰레드 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const MainReviewThread = () => {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();

  const classes = useStyles();

  // 리뷰 카드 정보 swiper ref
  const cardSwiperRef = React.useRef(null);
  // swiper index
  let SWIPER_INDEX = 0;
  // swiper index정보
  const [swiperIndexState, setSwiperIndexState] = useLocationState('main-review-thread-swiper-index', 0);

  // 리뷰 쓰레드 정보
  const [reviewThreads, setReviewThreads] = useLocationState('main-review-threads', initMainReviewThread);

  // 뒤로가기로 접근 시 swiper index 세팅
  React.useEffect(() => {
    if (swiperIndexState && typeof swiperIndexState === 'number') {
      SWIPER_INDEX = swiperIndexState;
      cardSwiperRef.current.swiper.slideTo(SWIPER_INDEX);
    }
  }, [swiperIndexState]);

  React.useLayoutEffect(() => {
    fetchReviewThreads();
  }, []);

  // 메인 리뷰 쓰레드 정보 조회
  const fetchReviewThreads = async () => {
    const body = {
      page: {
        current: 1,
        size: 10
      },
      sort: 'new'
    };

    const result = await getReviewThreadList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[MainReviewThread][fetchReviewThreads] Unmounted component.');
      return undefined;
    }

    // 리뷰 쓰레드 목록 조회 중 오류 발생
    if (result.error) {
      setReviewThreads({ loaded: true, error: true, list: [] });
      console.error('[MainReviewThread][fetchReviewThreads] 리뷰 쓰레드 목록 조회 중 오류 발생 > ', result.error);
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 반환됨
    if (code !== 0) {
      setReviewThreads({ loaded: true, error: true, list: [] });
      console.error('[MainReviewThread][fetchReviewThreads] 잘못된 Response값이 반환됨 > ', msg);
      return undefined;
    }

    setReviewThreads({ loaded: true, error: false, list: result.data });
  };

  // 리뷰 카드 클릭 후 쓰레드 목록으로 이동
  const goThreadList = (index) => {
    navigate('/home/review-thread', { state: { cardIndex: index } });
  };

  // render
  return (
    reviewThreads.loaded &&
    !reviewThreads.error &&
    reviewThreads.list.length > 0 && (
      <Container sx={{ bgcolor: '#F3F2F5', pt: '32px', my: '20px' }}>
        <SpaceBetweenBox sx={{ px: '4px' }}>
          <Typography variant={'subtitle1'} fontWeight={800} fontSize={21} letterSpacing={'0.4px'} noWrap>
            유저의 리얼리뷰
          </Typography>
          <ShowMoreButton
            onClick={() => {
              console.log('리뷰 쓰레드 더보기 버튼 클릭됨.');
              setSwiperIndexState(SWIPER_INDEX);
              navigate('/home/review-thread');
            }}
            endIcon={<ChevronRightSharpIcon color="inherit" fontSize="inherit" sx={{ opacity: 0.45, height: 14, width: 14 }} />}
            sx={{ mr: -1 }}
          >
            <Typography variant="caption" fontSize="inherit" lineHeight="1.46" sx={{ opacity: 0.45, height: 14 }}>
              전체보기
            </Typography>
          </ShowMoreButton>
        </SpaceBetweenBox>
        <Box px="4px">
          {/* 홈 화면 리뷰 쓰레드 목록 */}
          <Swiper
            ref={cardSwiperRef}
            className={`HomeMainReviewThreads-Swiper ${classes.contentSwiper}`}
            loop={true}
            slidesPerView="auto"
            centeredSlides={true}
            spaceBetween={12}
            pagination={{
              dynamicBullets: true
            }}
            modules={[Pagination]}
            onSlideChange={(e) => {
              SWIPER_INDEX = e.realIndex;
            }}
          >
            {reviewThreads.list.map((review, index) => (
              <SwiperSlide key={index}>
                <MainReviewThreadCard review={review} index={index} goThreadList={goThreadList} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    )
  );
};

export default MainReviewThread;
