import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

/**
 * 다이얼로그 취소 버튼
 * @param onClick 버튼클릭 콜백
 * @param buttonText 버튼 텍스트
 * @returns {JSX.Element}
 * @constructor
 */
function DialogCancelButton({ onClick = null, buttonText = '취소', ...other }) {
  return (
    <Button onClick={(e) => (onClick ? onClick(e) : null)} color="gray" sx={{ py: 0.5 }} {...other}>
      {buttonText}
    </Button>
  );
}

DialogCancelButton.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string
};

export default DialogCancelButton;
