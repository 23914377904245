// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;
/**
 * @constant
 * @type {{GET__PDATA_ONE: string, GET__PRODUCT_ONE: string}}
 */
export const FIREBASE_FUNCITON_URL = {
  /** product 조회 @deprecated */
  GET__PRODUCT_ONE: `${BASE_HOST}/call-product-product-one`,
  /** 상품리뷰가 도움이 돼요 */
  POST__PRODUCT_REVIEW_LIKE: `${BASE_HOST}/call-product-review-like`,
  /** 상품리뷰 신고하기 */
  POST__PRODUCT_REVIEW_REPORT: `${BASE_HOST}/call-product-review-report`
};

export const NEST_API_URL = {
  /** 판매상품 검색 */
  POST__PRODUCT_SEARCH: `${API_SERVER_HOST}/product/search`,
  /** 오늘의 한 병 목록 */
  POST__TODAY_ONE_BOTTLE_LIST: `${API_SERVER_HOST}/bottle/list`,
  /** 오늘의 한 병 유효성 검사 (검사하고자 하는 오늘의 한 병 -> 날짜, 관심샵 여부 등) */
  POST__TODAY_ONE_BOTTLE_CHECK: `${API_SERVER_HOST}/bottle/check`,
  /** 오늘의 한 병 상세 조회 */
  GET__TODAY_ONE_BOTTLE_DETAIL: `${API_SERVER_HOST}/bottle/get`,
  /** 공동구매 목록 */
  POST__CO_BUYING_LIST: `${API_SERVER_HOST}/cobuying/list`,
  /** 공동구매 상세 조회 */
  GET__CO_BUYING_DETAIL: `${API_SERVER_HOST}/cobuying/get`,
  /** 지도에 위치한 공동구매 입점샵 리스트 조회 */
  POST__CO_BUYING_VENDOR_LIST: `${API_SERVER_HOST}/cobuying/getVendorMapList`
};

export * from './product'; // 판매상품 정보
