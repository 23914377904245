import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { ProductImage, ProductName } from 'components/product';
import { Calculator } from 'utils/bourgogne-tools';
import { OrderAddIcon, OrderCheckedIcon } from 'components/icons';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import OrderTool from '../../../../../utils/bourgogne-tools/OrderTool';

const IMG_WIDTH = 107; // product thumbnail img width
const IMG_HEIGHT = 127; // product thumbnail img height

/**
 * 교환/반품 > 주문상품 카드
 * @returns {JSX.Element}
 * @constructor
 */
function OrderCard({ orderProduct, onClick, checked = false, disabled = false }) {
  const theme = useTheme();
  const { capacity, name, packing, pdata, price, quantity } = orderProduct;

  // 교환/반품 가능여부
  const excRtnAble = OrderTool.isExcRtnAbleOrderProduct(orderProduct.order.state, orderProduct.state);

  if (!excRtnAble) {
    console.log('교환/ 반품이 불가능한 상품입니다. ', orderProduct.name.ko);
    return null;
  }

  // console.debug('[교환/반품 요청] 주문 상품: ', orderProduct);

  // render
  return (
    <Card
      component="section"
      className="no-border-radius order-card"
      elevation={0}
      sx={{
        mt: '12px',
        '&:first-of-type': {
          mt: 0
        }
      }}
    >
      <CardActionArea disabled={disabled} onClick={(e) => onClick(e, orderProduct)}>
        {/* pl="26px" */}
        <Box position="relative" display="flex" flexDirection="row" height={IMG_HEIGHT} pl={excRtnAble ? '26px' : 0}>
          {/* 상품선택확인 */}
          {excRtnAble && (
            <Box position="absolute" left={0} top="50%" zIndex={1} sx={{ transform: 'translateY(-50%)' }}>
              {checked ? <OrderCheckedIcon /> : <OrderAddIcon />}
            </Box>
          )}

          {/* 상품 이미지 */}
          <ProductImage width={IMG_WIDTH} height="100%" src={pdata.bottle_img?.thumb} alt={name.ko} />

          <Box flexGrow={1} p="7px" pl="10px" display="flex" flexDirection="column" justifyContent="space-between">
            {/* 상품명 (한글) */}
            <ProductName>
              {name.ko} {Calculator.formatCapacity(capacity)}
            </ProductName>

            {/* 주문 수량/금액 */}
            <Box>
              {/* todo 포장옵션 활성화 후 공개하기
              <OptString component="div" variant="body2" color="text.secondary">
                옵션 : {Strings.packingOptionToStr(packing)}
              </OptString>
              */}
              <OptString component="div" variant="body2" color="text.secondary" sx={{ mt: '5px' }}>
                수량 : {quantity}개
              </OptString>

              <Box mt="5px" display="flex" alignItems="center">
                <Typography
                  component="span"
                  variant="body2"
                  fontSize={22}
                  fontFamily={theme.palette.fontFamily.currency}
                  fontWeight="normal"
                >
                  {((price.original >> 0) * quantity).toLocaleString()}
                </Typography>
                <span style={{ fontSize: 16, fontWeight: 300, lineHeight: 'normal' }}>원</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

OrderCard.propTypes = {
  orderProduct: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default OrderCard;

const OptString = styled(Typography)(({ theme }) => ({
  opacity: 0.5,
  fontSize: 11,
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.11px',
  color: theme.palette.text.primary
}));
