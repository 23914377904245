import React from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { Box, ButtonBase } from '@mui/material';

// assets
import liveBtn from 'assets/images/ic_hone_live.png';

/**
 * 실시간 버튼
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function LiveButton() {
  const navigate = useNavigate();
  return (
    <>
      <ButtonBase
        aria-label="실시간 주문"
        onClick={() => {
          navigate('/home/live');
        }}
      >
        <Box component="img" src={liveBtn} sx={{ height: 32 }} />
      </ButtonBase>
    </>
  );
}

export default LiveButton;
