import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

// material-ui
import { Box, ButtonBase, Container, Fab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { useKakaoMap, useScriptRef, useWineOne } from 'hooks';
import { CLO_CODE, DEV } from 'config';
import { CurrentLocationIcon, WineBottleLoadingLottie } from 'components';
import { useSelector } from 'store';
import CoBuyingMapVendorCard from './component/CoBuyingMapVendorCard';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { WoAlert } from 'utils/kmwine-alerts';
import { getCoBuyingVendorList } from 'services/ProductService';

// assets
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import ArrowBackImg from 'assets/images/arrow-back.png';

/**
 * 공동구매 픽업샵 선택 지도 페이지
 *
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
const CobuyingMap = ({ height = '100%', width = '100%' }) => {
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  const { user } = getAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { geolocation, refreshUserGeolocation } = useWineOne();

  const classes = useStyles();

  // 사용자 위치정보
  const { userLocation } = useSelector((state) => state);

  // 공동구매 아이디
  const { coBuyingId } = useParams();

  // 카카오 지도
  const { kakaoMaps, markerImages } = useKakaoMap();

  const userMarker = React.useRef(null); // 사용자 위치마커
  const vendorMarkerObj = React.useRef({}); // 입점사 마커 객체
  const coBuyingMapDivRef = React.useRef(null); // 카카오 맵이 들어갈 div

  // 입점샵 리스트
  const [vendorList, setVendorList] = React.useState(null);
  // 입점샵 리스트 열림여부
  const [vendorListOpen, setVendorListOpen] = React.useState(false);

  // 선택된 vendor
  const [selectedVendor, setSelectedVendor] = useLocationState('cb-map-sv', null);
  const selectedVendorId = React.useRef(selectedVendor?.vendor_id ?? null);

  React.useLayoutEffect(() => {
    refreshUserGeolocation(); // 사용자 현재위치 최신화
  }, []);

  React.useEffect(() => {
    selectedVendorId.current = selectedVendor?.vendor_id ?? null;
  }, [selectedVendor?.vendor_id]);

  // 지도
  const [coBuyingMap, setCoBuyingMap] = React.useState(null);
  // 지도 옵션
  const [mapOption, setMapOption] = useLocationState('cb-m-center', {
    lat: location.state.location.y,
    lng: location.state.location.x,
    level: location.state.location_level
  });

  // 카카오지도 초기화[start]
  const initializeKakaoMap = React.useCallback(() => {
    if (kakaoMaps === null) {
      console.warn('[VendorListMap] 카카오 지도 스크립트를 불러오고 있습니다.');
      return;
    }
    // 지도 옵션
    const _map = new kakaoMaps.Map(coBuyingMapDivRef.current, {
      center: new kakaoMaps.LatLng(mapOption.lat, mapOption.lng),
      level: mapOption.level, // 기본 줌 레벨
      minLevel: 2,
      maxLevel: 14 // 최대 줌 레벨
    });

    if (scriptedRef.current) setCoBuyingMap(_map);
  }, [kakaoMaps]);
  // 카카오지도 초기화[end]

  // 사용자 위치 변경됨
  React.useEffect(() => {
    if (kakaoMaps === null) {
      console.debug('[VendorListMap] 카카오 지도 스크립트를 불러오고 있습니다.');
      return;
    }
    const { access, initialized, latitude, longitude } = geolocation;
    if (!initialized) console.debug('[VendorListMap] 사용자의 위치를 확인하는 중...');
    if (!access) {
      console.warn('[VendorListMap] 사용자의 위치정보에 접근할 수 없습니다.');
      return undefined;
    }
    if (!coBuyingMap) {
      console.debug(`[VendorListMap] 'vendorMap' not initialized yet...`);
      return undefined;
    }
    if (userMarker.current === null) {
      userMarker.current = new kakaoMaps.Marker({
        image: markerImages.currentUser
      });
    }

    // 사용자 현 위치마커 표현 - 마커 위치지정
    userMarker.current.setPosition(new kakaoMaps.LatLng(latitude, longitude));
    if (coBuyingMap) {
      userMarker.current.setMap(coBuyingMap);
    }

    return () => {
      if (userMarker.current) {
        userMarker.current.setMap(null);
        userMarker.current = null;
      }
    };
  }, [kakaoMaps, coBuyingMap, geolocation]);

  // 지도 중심좌표 변경 이벤트 callback
  const mapBoundChanged = () => {
    const centerCoord = coBuyingMap.getCenter();
    setMapOption({ lat: centerCoord.getLat(), lng: centerCoord.getLng(), level: coBuyingMap.getLevel() });

    requestVendorList(); // 입점사 목록 조회
  };

  React.useEffect(() => {
    if (coBuyingMap) {
      // 맵 중심/줌 변경 이벤트 리스너 등록
      kakaoMaps.event.addListener(coBuyingMap, 'idle', mapBoundChanged);

      requestVendorList(); // 입점사 목록 조회
    }
    return () => {
      try {
        if (coBuyingMap) kakaoMaps.event.removeListener(coBuyingMap, 'idle', mapBoundChanged);
      } catch (e) {
        /* DO NOTHING */
      }
    };
  }, [coBuyingMap, selectedVendor]);

  React.useEffect(() => {
    if (coBuyingMapDivRef.current) {
      if (coBuyingMap == null) {
        // 지도 초기화
        initializeKakaoMap();
      } else {
        console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `이미 초기화된 카카오지도가 있습니다.`);
      }
    }
  }, [coBuyingMapDivRef, geolocation, userLocation]);

  // 샵정보 가지고오기
  const requestVendorList = async () => {
    // 지도영역 확인
    const bounds = coBuyingMap.getBounds();

    // 지도영역의 대각선 거리 구하기
    const coordNE = bounds.getNorthEast();
    const coordSW = bounds.getSouthWest();

    // 지도 꼭지점 위도경도

    const body = {
      co_buying_id: coBuyingId,
      square_point: [
        `${coordSW.getLng()} ${coordNE.getLat()}`,
        `${coordNE.getLng()} ${coordNE.getLat()}`,
        `${coordNE.getLng()} ${coordSW.getLat()}`,
        `${coordSW.getLng()} ${coordSW.getLat()}`
      ]
    };

    const results = await getCoBuyingVendorList(body).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `공동구매 입점샵 검색중 에러#1`,
        msg: `[uid=${user?._id ?? 'anonymous'}] ${JSON.stringify(error.message)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id,
          error
        }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[VendorListMap] Unmounted component.');
      return;
    }

    console.debug(`[wineone] 주변 입점샵 검색결과 => `, results);
    if (results.error) {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주변 입점샵 검색중 에러`,
        msg: `[uid=${user?._id ?? 'anonymous'}]}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);
      WoAlert.fire(`와인샵을 불러오는 중 오류가 발생했습니다.`, '', 'error').then(() => {
        navigate(-1);
      });
      return;
    }

    const { success, cobuyingVendors } = results;

    if (!success) {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주변 입점샵 검색중 에러`,
        msg: `[uid=${user?._id ?? 'anonymous'}]}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      await WoAlert.fire(`와인샵을 불러오는 중 오류가 발생했습니다.`, '', 'error').then(() => {
        navigate(-1);
      });

      return undefined;
    }

    setVendorList(cobuyingVendors);

    cobuyingVendors.forEach((result) => {
      // vendor id
      const vendor_id = result._id;

      // vendor marker object
      let vendorMarker = vendorMarkerObj.current[vendor_id];

      // 기존 마커가 없을 경우
      let isNewMarker = false;

      if (!vendorMarker) {
        isNewMarker = true;
        vendorMarker = new kakaoMaps.Marker({ clickable: true, zIndex: 2 });
      }

      // Vendor marker location
      const lat = result.biz__location.y;
      const lng = result.biz__location.x;

      const vendorPosition = new kakaoMaps.LatLng(lat, lng);

      // const markerImg = selectedVendor?.vendor_id === vendor_id ? markerImages.vendor.on : markerImages.vendor.off;
      const markerImg = selectedVendorId.current === vendor_id ? markerImages.vendor.on : markerImages.vendor.off;
      vendorMarker.setImage(markerImg);

      // 입점사 이름
      const name = result.biz__name;
      vendorMarker.setTitle(name);
      vendorMarker.setPosition(vendorPosition);
      vendorMarker.setMap(coBuyingMap);
      vendorMarker.setClickable(true);

      // vendor marker 클릭시
      const markerClicked = () => {
        if (vendorListOpen) {
          setVendorListOpen(false);
          return;
        }

        if (scriptedRef.current) {
          Object.keys(vendorMarkerObj.current).forEach((vendorId) => {
            const oldMarker = vendorMarkerObj.current[vendorId];
            oldMarker.setImage(markerImages.vendor.off);
          });

          coBuyingMap.panTo(vendorPosition);
          vendorMarker.setImage(markerImages.vendor.on);

          setSelectedVendor(() => ({
            location: { lat, lng, latitude: Number(lat), longitude: Number(lng) },
            name,
            vendor_id,
            biz_address1: result.biz__address1,
            biz_address2: result.biz__address2,
            biz_location: result.biz__location,
            shop_service_tag: result.shop__service_tag,
            vendor_img: result.vendor_img
          }));
        }
      };

      if (isNewMarker) {
        kakaoMaps.event.addListener(vendorMarker, 'click', markerClicked);
      }
      vendorMarkerObj.current[vendor_id] = vendorMarker;
      vendorMarkerObj.current = { ...vendorMarkerObj.current };
    });
  };

  // 지도 클릭가능여부 toggle
  const toggleMapClick = (state) => {
    coBuyingMap.setDraggable(state);
    coBuyingMap.setZoomable(state);

    Object.keys(vendorMarkerObj.current).forEach((key) => {
      const innerObject = vendorMarkerObj.current[key];
      innerObject.setClickable(state);
    });
  };

  // render
  return (
    <Box id="co-buying-map-container" position="relative">
      <Fab
        color="white"
        onClick={() => {
          navigate(-1);
        }}
        aria-label="위치설정 닫기"
        sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
      >
        <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} />
      </Fab>
      {/* 지도영역 */}
      <Box
        // 빈곳 클릭시
        onClick={() => {
          if (selectedVendor) {
            setSelectedVendor(null);
          } else {
            toggleMapClick(true);
            setVendorListOpen(false);
          }
        }}
      >
        <Box
          component="div"
          id="co-buying-map"
          ref={coBuyingMapDivRef}
          style={{ width, height }}
          // sx={{ opacity: vendorListOpen && '.5' }}
        >
          {coBuyingMap == null && (
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <WineBottleLoadingLottie />
              <Typography variant="caption">지도를 불러오는 중 입니다</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {vendorListOpen && !selectedVendor && (
        <Box position="fixed" bottom={0} left={0} width="100%" height="100%" zIndex={1} sx={{ backgroundColor: 'black', opacity: '.5' }} />
      )}

      {/* 하단 액션영역 */}
      {coBuyingMap !== null && (
        <>
          <Box position="fixed" bottom={vendorList && !vendorListOpen && !selectedVendor ? '64px' : 0} left={0} width="100vw" zIndex={2}>
            {/* 리스트버튼 */}
            {vendorList && !vendorListOpen && !selectedVendor && (
              <Box position="fixed" bottom="64px" left={0} zIndex={1} width="100%" display="flex" justifyContent="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="171px"
                  height="32px"
                  bgcolor="#ffffff"
                  borderRadius="20px"
                  onClick={(event) => {
                    if (vendorList.length > 0) {
                      toggleMapClick(false);
                      setVendorListOpen(true);
                    }
                  }}
                  sx={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
                >
                  <MenuIcon sx={{ width: '16px', color: '#9357E5', marginRight: '5px', marginBottom: '2px' }} />
                  <Typography fontSize="12px">현재 위치 주변 픽업샵 ({vendorList?.length})</Typography>
                </Box>
              </Box>
            )}

            {/* 유저현재위치 버튼 */}
            {geolocation?.access && !vendorListOpen && (
              <Fab
                color="white"
                size="x-small"
                onClick={() => {
                  console.debug('[핀지도] 사용자의 위치로 이동합니다.', geolocation.latitude, geolocation.longitude);
                  const pinPosition = new kakaoMaps.LatLng(geolocation.latitude, geolocation.longitude);
                  coBuyingMap.panTo(pinPosition);
                }}
                aria-label="사용자 위치로 이동"
                sx={{ position: 'absolute', right: 20, bottom: selectedVendor ? 240 : 0 }}
              >
                <CurrentLocationIcon width="28px" height="28px" color={theme.palette.text.primary} />
              </Fab>
            )}

            {/* 샵 리스트 */}
            {vendorList && vendorListOpen && (
              <Box className={classes.vendorListWrapper}>
                <Container>
                  <Box height="76px" display="flex" alignItems="center" justifyContent="center" position="relative">
                    <ButtonBase
                      onClick={() => {
                        toggleMapClick(true);
                        setVendorListOpen(false);
                      }}
                      sx={{ width: '34px', height: '34px', position: 'absolute', left: 0 }}
                    >
                      <CloseIcon width="22px" height="22px" />
                    </ButtonBase>
                    <Typography fontWeight="900" fontSize="16px">
                      현재 위치 주변 픽업샵({(vendorList?.length ?? 0).toLocaleString()})
                    </Typography>
                  </Box>
                  <Box className="none-scroll-bar" sx={{ maxHeight: '419px', overflow: 'auto' }}>
                    {vendorList?.map((vendor, index) => (
                      <CoBuyingMapVendorCard
                        key={`vc-item-${index}`}
                        vendor={vendor}
                        index={index}
                        onVendorSelect={(vendor) => {
                          const { x: lng, y: lat } = vendor.biz__location;
                          setMapOption((prev) => ({ ...prev, lat, lng }));

                          setSelectedVendor(() => ({
                            location: { lat, lng, latitude: Number(lat), longitude: Number(lng) },
                            name: vendor.biz__name,
                            vendor_id: vendor._id,
                            biz_address1: vendor.biz__address1,
                            biz_address2: vendor.biz__address2,
                            biz_location: vendor.biz__location,
                            shop_service_tag: vendor.shop__service_tag,
                            vendor_img: vendor.vendor_img
                          }));
                          navigate(`/order/co-buying/confirm?cid=${coBuyingId}&vid=${vendor._id}`, { state: { vendor } });
                        }}
                      />
                    ))}
                  </Box>
                </Container>
              </Box>
            )}

            {/* 샵하나만 선택했을시 */}
            {vendorList && selectedVendor && !vendorListOpen && (
              <Box
                width="100%"
                bgcolor="#ffffff"
                sx={{
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  boxShadow: '0px -15px 10px 0 rgb(0 0 0 / 16%)'
                }}
              >
                <Container>
                  <Box height="76px" display="flex" alignItems="center" justifyContent="center" position="relative">
                    <ButtonBase
                      onClick={() => {
                        setSelectedVendor(null);
                      }}
                      sx={{ width: '34px', height: '34px', position: 'absolute', left: 0 }}
                    >
                      <CloseIcon width="22px" height="22px" />
                    </ButtonBase>
                    <Typography fontWeight="900" fontSize="16px">
                      선택된 샵
                    </Typography>
                  </Box>
                  <Box className="none-scroll-bar">
                    <CoBuyingMapVendorCard
                      vendor={{
                        biz__name: selectedVendor.name,
                        biz__address1: selectedVendor.biz_address1,
                        biz__address2: selectedVendor.biz_address2,
                        biz__location: selectedVendor.biz_location,
                        shop__service_tag: selectedVendor.shop_service_tag,
                        vendor_img: selectedVendor.vendor_img,
                        _id: selectedVendor.vendor_id
                      }}
                      index={0}
                      onVendorSelect={(vendor) => {
                        const { x: lng, y: lat } = vendor.biz__location;
                        setMapOption((prev) => ({ ...prev, lat, lng }));
                        navigate(`/order/co-buying/confirm?cid=${coBuyingId}&vid=${vendor._id}`, { state: { vendor } });
                      }}
                    />
                  </Box>
                </Container>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CobuyingMap;

CobuyingMap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const useStyles = makeStyles((theme) => ({
  vendorListWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    boxShadow: '0px -15px 10px 0 rgb(0 0 0 / 16%)'
  }
}));
