import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { getFirestore } from '../../utils/firebase-tools';

export const TERM_TYPE = {
  PERSONAL: 'PERSONAL', // 개인정보 처리방침
  SERVICE: 'SERVICE', // 서비스 이용약관
  FINANCIAL: 'FINANCIAL', // 전자금융거래 이용약관
  PERSONAL_AGREE: 'PERSONAL_AGREE' // 개인정보 수집동의약관
};

/**
 * TERM_TYPE -> 문서이름
 * @param {TERM_TYPE} type
 */
export function termTypeToString(type) {
  let str = '알 수 없는 문서';
  if (type === TERM_TYPE.PERSONAL) {
    str = '개인정보 처리방침';
  } else if (type === TERM_TYPE.SERVICE) {
    str = '서비스 이용약관';
  } else if (type === TERM_TYPE.FINANCIAL) {
    str = '전자금융거래 이용약관';
  }

  return str;
}

/**
 * 약관 및 정책문서 내용 상세 조회
 * @param {string} termId - 문서 아이디
 * @returns {Promise<string>}
 */
export async function getTerm(termId) {
  return 'docId: ' + termId;
}

/**
 * 최신 약관 및 정책 정보 조회
 * @param {TERM_TYPE} type 문서 타입
 * @returns {Promise<string>}
 */
export async function getLatestTerm(type) {
  return getDocs(query(collection(getFirestore(), `terms`), where('type', '==', type), orderBy('created_at', 'desc'), limit(1)));
}
