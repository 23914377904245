import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { openSnackbar } from 'store/slices/snackbar';
import { createReviewReport } from 'services/ReviewThreadService';
import { Radio, TextField } from 'components';

// material-ui
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AlertTitle, Box, Button, Grid, SvgIcon, Typography } from '@mui/material';

/**
 * 신고항목
 * @type {string[]}
 */
const options = ['상품과 무관', '광고 및 홍보', '개인정보 노출', '이미지 도용, 저작권 침해', '부적절한 내용'];

function ReviewReportDialog(props) {
  const { onClose, value: valueProp, open, pdata, review, setRowItems, ...other } = props;

  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const globalDispatch = useDispatch();

  const [reportText, setReportText] = React.useState('');

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  React.useEffect(() => {
    // console.log('#value: ', value);
    if (value !== 'write') {
      setReportText('');
    }
  }, [value]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const validaReport = React.useMemo(() => {
    let valid = true;
    if (value === '') {
      valid = false;
    } else if (value === 'write') {
      valid = reportText.length > 4;
    }
    // console.log('valid', valid);
    return valid;
  }, [value, reportText]);

  const [reporting, setReporting] = React.useState(false);

  /** 신고하기 버튼 click 이벤트 콜백 */
  const report = async () => {
    if (!validaReport) {
      return false;
    }
    const pdataId = pdata.id ?? pdata._id;
    const { review_id: reviewId } = review;
    const msg = value !== 'write' ? value : reportText;

    setReporting(true);

    const body = {
      pdata_id: pdataId,
      review_id: reviewId,
      reason: msg
    };

    const result = await createReviewReport(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setReporting(false);
      });

    if (result.error) {
      console.error('[ReviewReportDialog][report] 리뷰 신고하기 오류 발생 > ', result.error);
      WoAlert.fire('', '리뷰 신고 중 오류가 발생했습니다. 잠시후 다시 시도해주세요', 'error');
      return undefined;
    }

    const { code, msg: resultMsg } = result.result;

    // 0: 성공, 3: 중복신고
    if (code !== 0 && code !== 3) {
      console.error('[ReviewReportDialog][report] 리뷰 신고하기 오류 발생 > ', resultMsg);
      globalDispatch(
        openSnackbar({
          open: true,
          message: '리뷰 신고 중 오류가 발생했습니다. 잠시후 다시 시도해주세요',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
      return undefined;
    }

    const message = (
      <>
        <AlertTitle>게시물 신고 완료</AlertTitle>
        <Typography variant="caption">게시글이 신고 되었습니다.</Typography>
      </>
    );
    setRowItems();
    handleCancel(); // 모달 닫기

    globalDispatch(
      openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true,
        autoHideDuration: 4000
      })
    );
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle component="div" sx={{ pl: '14px' }}>
        <ReportIcon />
        <Box lineHeight="normal" ml="5px">
          신고하기
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ py: 0, border: 0 }}>
        <RadioGroup ref={radioGroupRef} aria-label="신고사유" name="ringtone" value={value} onChange={handleChange}>
          {options.map((option) => (
            <FormControlLabel disabled={reporting} value={option} key={option} control={<Radio />} label={option} />
          ))}
          <FormControlLabel disabled={reporting} value="write" control={<Radio />} label="직접입력" />
        </RadioGroup>
        <Box mt="4px">
          <TextField
            disabled={value !== 'write'}
            hiddenLabel
            fullWidth
            multiline
            minRows={2}
            maxRows={4}
            placeholder="신고 사유를 입력해주세요.(4자 이상)"
            inputProps={{ maxLength: 600 }}
            value={reportText}
            onChange={(e) => setReportText(e.target.value)}
            onBlur={() => {
              setReportText(reportText.trim().replace(/ +(?= )/g, ''));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="block" width="100%" sx={{ pt: '10px', pb: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button variant="outlined" size="x-large" fullWidth autoFocus onClick={handleCancel} disabled={reporting}>
                취소
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                loading={reporting}
                disabled={!validaReport || reporting}
                size="x-large"
                variant="contained"
                color="brand"
                fullWidth
                onClick={report}
              >
                신고하기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

ReviewReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  pdata: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired,
  setRowItems: PropTypes.func
};

export default ReviewReportDialog;

const ReportIcon = () => (
  <SvgIcon viewBox="0 0 35 35" sx={{ width: 35, height: 35 }}>
    <path style={{ fill: 'none', opacity: 0.02 }} d="M0 0h35v35H0z" />
    <path d="M10.5 0A10.5 10.5 0 0 1 21 10.5V18H0v-7.5A10.5 10.5 0 0 1 10.5 0z" transform="translate(7 6)" style={{ fill: '#25064c' }} />
    <path d="M2 0h21a2 2 0 0 1 2 2v3H0V2a2 2 0 0 1 2-2z" transform="translate(5 25)" style={{ fill: '#25064c' }} />
    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(15 14)" style={{ fill: '#defd9a' }} />
    <path transform="translate(17 18)" style={{ fill: '#defd9a' }} d="M0 0h1v6H0z" />
    <path d="M.5 0a.5.5 0 0 1 .5.5V3H0V.5A.5.5 0 0 1 .5 0z" transform="translate(17 2)" style={{ fill: '#25064c' }} />
    <path d="M.5 0a.5.5 0 0 1 .5.5V3H0V.5A.5.5 0 0 1 .5 0z" transform="rotate(-30 11.507 -17.55)" style={{ fill: '#25064c' }} />
    <path d="M0 0h1v2.5a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V0z" transform="rotate(-150 12.395 -.106)" style={{ fill: '#25064c' }} />
  </SvgIcon>
);
