import React from 'react';
import moment from 'moment';
import { default as ReactSwitch } from 'react-switch';
import { useLocation } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { httpsCallable } from 'firebase/functions';

// project imports
import { CLO_CODE } from 'config';
import { useAuth, useScriptRef } from 'hooks';
import { SpaceBetweenBox } from 'components';
import { WoAlert } from 'utils/kmwine-alerts';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import BackHeader from 'layouts/Main/BackHeader';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { setTermsPush } from 'services/UserService';

// material-ui
import { common } from '@mui/material/colors';
import { Box, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { lighten, rgbToHex } from '@mui/system/colorManipulator';

/**
 * 마이 > 앱설정 > 알림 설정
 * @returns {JSX.Element}
 * @authors 최효근<hkchoi@wineone.io>
 */
function alarmSetting() {
  const scriptedRef = useScriptRef();
  const location = useLocation();
  const theme = useTheme();

  // 로그인 사용자 정보
  const { user, refresh } = useAuth();

  const globalDispatch = useDispatch();

  // loading
  const [loading, setLoading] = React.useState(false);

  // timestamp to date
  const timeStampToDate = React.useCallback((timeStamp) => {
    if (timeStamp) return moment(new Timestamp(timeStamp.seconds, timeStamp.nanoseconds).toDate()).format('YYYY.MM.DD');
  }, []);

  // 사용자 push수신여부 업데이트 요청
  const updateMarketingPushFlag = async (pushValue) => {
    setLoading(true);

    const res = await setTermsPush(pushValue)
      .catch((error) => ({ error }))
      .finally(() => {
        if (scriptedRef.current) {
          setLoading(false);
        }
      });

    await refresh();

    // 마케팅 수신변경 오류
    if (res.error) {
      console.error('[마케팅 수신변경] 마케팅 수신변경 중 오류 발생', res.error);

      const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `마케팅 수신 동의변경 중 오류 발생`,
        msg: `[uid=${user.uid}] ${JSON.stringify(res.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user.uid
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire('변경 실패', '마케팅 수신 동의 변경 중 오류가 발생했습니다.', 'error');
      }
      return undefined;
    }

    const resultCode = res?.data?.result?.code;

    if (resultCode === 0) {
      const {
        terms: { push_at }
      } = user; // Global user state 업데이트

      // Global snackbar 띄우기.
      globalDispatch(
        openSnackbar({
          open: true,
          message: `${timeStampToDate(push_at)}에 마케팅 정보 알림을 ${pushValue ? '설정' : '해제'}했습니다.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
    } else {
      console.error('[마케팅 수신변경] 마케팅 수신변경 중 오류 발생');

      if (scriptedRef.current) {
        WoAlert.fire('변경 실패', '마케팅 수신동의 변경 중 오류가 발생했습니다.', 'error');
      }
    }
  };

  // toggle switch action
  const handleMarketingAlarmSwitch = React.useCallback(
    async (checked = false) => {
      globalDispatch(closeSnackbar());

      // 알림해제 시도시
      if (!checked) {
        const result = await WoAlert.fire({
          title: <Box textAlign="left">혜택/이벤트 알림 설정</Box>,
          html: (
            <Typography paragraph lineHeight="1.5rem" textAlign="left" fontSize={16}>
              알림을 해제하면 쿠폰, 할인, 특가 등<br />
              다양한 혜택과 이벤트 알림을 더 이상 받을 수 없게 됩니다.
              <br />
              <br /> 알림을 해제하시겠어요?
            </Typography>
          ),
          reverseButtons: false,
          showCancelButton: true,
          focusCancel: true,
          customClass: { confirmButton: 'max-50', cancelButton: 'max-50' },

          // 알림 유지(cancel)
          cancelButtonColor: theme.palette.brand.main,
          cancelButtonText: (
            <Typography fontSize="1.1rem" fontWeight="bold" color={theme.palette.brand.contrastText}>
              알림 유지
            </Typography>
          ),
          cancelButtonAriaLabel: '알림 유지',

          // 알림 해제(confirm)
          confirmButtonColor: theme.palette.white.main,
          confirmButtonText: (
            <Typography fontSize="inherit" fontWeight="normal" color={theme.palette.brand.main}>
              알림 해제
            </Typography>
          ),
          confirmButtonAriaLabel: '알림 해제'
        });

        if (result.isDismissed) return false;
      }

      updateMarketingPushFlag(checked);
    },
    [updateMarketingPushFlag, user]
  );

  // render
  return (
    <BackHeader title="알림 설정" showBottomNav={false} showFooter={false}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 알림 설정`} />
      <Container maxWidth="xs">
        {/* 혜택/이벤트 알림 */}
        <Box sx={{ height: '80px', width: '100%' }} borderBottom={(theme) => `1px solid ${theme.palette.brand.main}`}>
          <SpaceBetweenBox width="100%" sx={{ height: '100%' }}>
            <Box display="flex" flexDirection="column">
              <Typography fontSize={14} fontWeight="bold">
                혜택/이벤트 알림
              </Typography>
              <Typography color="text.secondary" fontSize={10} fontWeight="bold">
                혜택 및 이벤트 알림 수신 {user?.terms?.push ?? false ? '동의' : '거부'}&nbsp;
                {user?.terms?.push_at && timeStampToDate(user.terms.push_at)}
              </Typography>
            </Box>

            <Switch checked={user?.terms?.push ?? false} disabled={loading} onChange={handleMarketingAlarmSwitch} />
          </SpaceBetweenBox>
        </Box>
      </Container>
    </BackHeader>
  );
}

export default alarmSetting;

// 스위치 STYLE
// eslint-disable-next-line react/prop-types
const Switch = React.memo(({ checked, disabled, onChange }) => {
  const theme = useTheme();
  return (
    <ReactSwitch
      handleDiameter={20}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      width={50}
      height={27}
      onColor={theme.palette.brand.main}
      offColor={rgbToHex(lighten(theme.palette.brand.main, 0.6))}
      onHandleColor={theme.palette.text.brand}
      offHandleColor={common.white}
      checkedIcon={<SwitchIcon label="ON" color={theme.palette.brand.contrastText} />}
      uncheckedIcon={<SwitchIcon label="OFF" color={common.white} />}
      activeBoxShadow="none"
    />
  );
});

// eslint-disable-next-line react/prop-types
const SwitchIcon = React.memo(({ label, color }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%" fontSize={10} fontWeight="600" lineHeight={1} color={color}>
    {label}
  </Box>
));
