import React from 'react';
import { Navigate, Route } from 'react-router-dom';

// project imports
import AuthGuard from '../utils/route-guard/AuthGuard';

// pages (component)
import PaymentInicis from 'views/pages/payment/PaymentInicis';
import OrderComplete from '../views/pages/order/OrderComplete';

/**
 * 주문관련 라우트
 * @constructor
 */
export default function PaymentRoutes() {
  return (
    <Route path="/payment">
      <Route index element={<Navigate replace to={'/'} />} />
      {/* 이니시스 카드결제 요청 */}
      <Route
        path={'inicis/request'}
        element={
          <AuthGuard>
            <PaymentInicis />
          </AuthGuard>
        }
      />
      {/* 주문완료화면 */}
      <Route
        path={'complete'}
        element={
          <AuthGuard>
            <OrderComplete />
          </AuthGuard>
        }
      />
    </Route>
  );
}
