import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Numbers } from 'utils/bourgogne-tools';
import { HeartIcon } from '../../../icons';

/**
 * 좋아요 개수 약식표현
 * @param {number} count 개수
 * @param {string} color 하트 색상
 * @constructor
 */
function WishCountSimple({ count = 0, color = '#fb6464', fontWeight = 100, ...other }) {
  return (
    <Box display={'flex'} alignItems={'center'} {...other}>
      <HeartIcon height={13} width={13} color={color} />
      <Typography variant={'caption'} lineHeight="normal" fontSize={11} fontWeight>
        {count > 9999 ? Numbers.nFormatter(count, 0) : count.toLocaleString()}
      </Typography>
    </Box>
  );
}

WishCountSimple.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string,
  fontWeight: PropTypes.number
};

export default WishCountSimple;
