export { default } from './ProductList';

/**
 * 상품 뷰 타입 - 리스트
 * @constant
 * @type {string}
 */
export const VIEW_TYPE_LIST = 'list';

/**
 * 상품 뷰 타입 - 그리드
 * @constant
 * @type {string}
 */
export const VIEW_TYPE_GRID = 'grid';
