import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project imports
import { SoldOutIcon } from 'components';

// material-ui
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';

/**
 * 오늘의 한 병을 이미 구매 완료한 경우의 모달
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const EndOrderModal = (props) => {
  const { open, vendor_id } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle sx={{ px: '24px', pb: '18px' }}>
        <Stack direction="row" alignItems="center" spacing="5px">
          <SoldOutIcon />
          <Typography sx={{ fontSize: '18px', fontWeight: 900, letterSpacing: '-0.18px', color: '#140229' }}>주문 완료 안내</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ px: '24px', fontSize: '16px', letterSpacing: '-0.16px', color: '#140229' }}>
        오늘의 한 병을 이미 주문 하셨습니다.
        <br />
        내일의 한 병으로 다시 만나요!
      </DialogContent>

      <DialogActions sx={{ px: '24px', pt: '1px', pb: '25px' }}>
        <Button
          fullWidth
          variant="contained"
          color="brand"
          onClick={() => navigate(`/vendor/d/${vendor_id}`, { replace: true })}
          sx={{
            py: '16px',
            borderRadius: '26px',
            fontSize: 17,
            fontWeight: 800,
            lineHeight: 'normal',
            letterSpacing: '-0.17px'
          }}
        >
          오늘의 샵 둘러보기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndOrderModal;

EndOrderModal.propTypes = {
  open: PropTypes.bool,
  vendor_id: PropTypes.string
};
