import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

// project imports
import { BottomDialog, WoDialogTitle } from 'components/dialog';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { useDispatch, useSelector } from 'store';
import { fetchCodes } from 'store/slices/wo-constants';
import { COUNTRY_FILTER_NAMES, INITIAL_FILTER_STATE, VARIETY_FILTER_NAMES } from './index';

// material-ui
import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Slide,
  Typography
} from '@mui/material';
import { Numbers } from '../../../../../utils/bourgogne-tools';
import { Switch } from '../../../../../components';
import { useCode } from '../../../../../hooks';

// assets
import wineType_ALL from './assets/ico_wine_type_ALL.png';
import wineType_RED from './assets/ico_wine_type_RED.png';
import wineType_WHITE from './assets/ico_wine_type_WHITE.png';
import wineType_SPARKLING from './assets/ico_wine_type_SPARKLING.png';
import wineType_ROSE from './assets/ico_wine_type_ROSE.png';
import wineType_ETC from './assets/ico_wine_type_ETC.png';
import wineTypeSelect from './assets/ico_wine_type_select_on.png';
import country_AR from './assets/img_country_ARGENTINA.png';
import country_AU from './assets/img_country_AUSTRALIA.png';
import country_CL from './assets/img_country_CHILE.png';
import country_FR from './assets/img_country_FRANCE.png';
import country_IT from './assets/img_country_ITALY.png';
import country_NZ from './assets/img_country_NEWZEALAND.png';
import country_PT from './assets/img_country_PORTUGAL.png';
import country_US from './assets/img_country_US.png';
import country_ZA from './assets/img_country_SOUTHAFRICA.png';
import country_ES from './assets/img_country_SPAIN.png';
import country_DE from './assets/img_country_GERMANY.png';

/**
 * 상품검색필터 dialog
 *
 * @param openProp dialog 열림여부
 * @param filterState 실제 적용된 필터 스테이트
 * @param onClose 닫기 콜백
 * @param onConfirm 확인 콜백
 * @returns {JSX.Element}
 * @constructor
 */
function FilterDialog({ open: openProp, filterState, filterItemSequence: filterItemSeqProp = [], onClose = () => {}, onConfirm }) {
  const { getCodeNameByValue } = useCode();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(openProp);

  // 서비스 상수
  const { woConstants } = useSelector((state) => state);

  React.useLayoutEffect(() => {
    dispatch(fetchCodes(['variety', 'country', 'wine_type']));
  }, [woConstants]);

  // 필터 아이템 선택순서
  const [filterItemSequence, setFilterItemSequence] = React.useState(filterItemSeqProp);

  // props 대응
  React.useEffect(() => {
    setFilterItemSequence([...filterItemSeqProp]);
  }, [filterItemSeqProp]);

  React.useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  // 필터에 사용할 품종
  const varietyFilterItems = useMemo(() => {
    const items = {};
    if (woConstants.code && woConstants.code.variety) {
      VARIETY_FILTER_NAMES.forEach((vNm) => {
        if (woConstants.code.variety[vNm]) {
          items[vNm] = { ...woConstants.code.variety[vNm] };
        }
      });
    }
    return items;
  }, [woConstants.code.variety]);

  // 필터에 사용할 국가
  const countryFilterItems = useMemo(() => {
    const items = {};
    if (woConstants.code && woConstants.code.country) {
      COUNTRY_FILTER_NAMES.forEach((cCd) => {
        if (woConstants.code.country[cCd]) {
          items[cCd] = { ...woConstants.code.country[cCd] };
        }
      });
    }
    return items;
  }, [woConstants.code.country]);

  // dialog내 필터 상태
  const [filter, setFilter] = useState(INITIAL_FILTER_STATE);

  // 실제 필터의 값으로 동기화
  useEffect(() => {
    setFilter({ ...filterState });
  }, [filterState]);

  // 필터 닫기 -> filterState로 변경
  const handleFilterClose = () => {
    setFilter({ ...filterState });
    setFilterItemSequence([...filterItemSeqProp]);
    onClose();
  };

  // dialog가 열릴 때, dialog에 포커스
  const filterElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: filterElement } = filterElementRef;
      if (filterElement !== null) {
        filterElement.focus();
      }
    }
  }, [open]);

  // todo delete
  // 금액범위 조정
  // const handlePriceRange = (event, price_range) => {
  //   let [min, max] = price_range;
  //
  //   // 최소 금액이 금액 최대로 갈 경우
  //   if (min === PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP) {
  //     min = PRODUCT_MAX_PRICE;
  //   }
  //   // 최대 금액이 금액 최소로 갈 경우
  //   if (max === 0) {
  //     max = PRODUCT_PRICE_STEP;
  //   }
  //   setFilter({ ...filter, price_range: [min, max] });
  // };

  // todo delete
  // 가격 직접 입력 영역
  // const [priceCustomOpen, setPriceCustomOpen] = useState(false);
  // const togglePriceCustom = () => {
  //   if (!priceCustomOpen) {
  //     setFilter({ ...filter, price_range_custom: INITIAL_FILTER_STATE.price_range_custom });
  //   } else {
  //     setFilter({ ...filter, price_range: INITIAL_FILTER_STATE.price_range });
  //   }
  //
  //   // setPriceCustomOpen(!priceCustomOpen);
  // };

  // 검색금액범위 최소값 표현
  const priceMinLabel = useMemo(() => {
    const { price_range_custom } = filter;
    const priceMin = price_range_custom[0];
    if (priceMin === 0) return '0원';

    return `${Numbers.numberToKorean(priceMin >> 0)}원`;
  }, [filter.price_range_custom]); // todo delete filter.price_range

  // 검색금액범위 최대값 표현
  const priceMaxLabel = useMemo(() => {
    const { price_range_custom } = filter;
    const priceMax = price_range_custom[1];

    let label = `${Numbers.numberToKorean(priceMax >> 0)}원`;

    // 금액검색범위 최대 -> 제한없음
    if (price_range_custom[1] === '') {
      label = '제한없음';
    }
    return label;
  }, [filter.price_range_custom]); // todo delete filter.price_range

  // 초기화
  const handleReset = React.useCallback(() => {
    setFilter(INITIAL_FILTER_STATE);
    setFilterItemSequence([]);
  }, []);

  // 확인('상품보기') 콜백
  const handleConfirm = React.useCallback(() => {
    onConfirm(filter, filterItemSequence);
  }, [filter, filterItemSequence]);

  /**
   * 스타일 필터값
   */
  const handleTasteFilter = (taste, bit) => {
    const tasteBit = filter[taste];
    if (Number.isNaN(tasteBit)) return;

    let newTasteBit = tasteBit & bit ? tasteBit - bit : tasteBit + bit;
    if (newTasteBit === 5) newTasteBit = 7;

    setFilter((f) => {
      const newFilter = { ...f };
      newFilter[taste] = newTasteBit;
      return newFilter;
    });

    // filterItemSequence 정리
    if (newTasteBit > 0) {
      // 이미 선택되어 있던 '스타일' item
      const oldTaste = _.find(filterItemSequence, (o) => o.item === taste);

      // 선택되어 있지 않았을 경우
      if (!oldTaste) {
        setFilterItemSequence((f = []) => {
          f.push({ item: taste, value: newTasteBit });
          return f;
        });
      }
      // 이미 선택되어있었을 경우
      else {
        // 이미 있을 경우 해당 item의 value 교체
        setFilterItemSequence((fis) => {
          const newFis = [...fis];
          newFis.forEach((fi) => {
            if (fi.item === taste) fi.value = newTasteBit;
          });
          return newFis;
        });
      }
    } else {
      // filterItemSequence에서 제거
      const remainSequence = _.reject(filterItemSequence, { item: taste });
      setFilterItemSequence(remainSequence);
    }
  };

  /**
   * VIVINO 4.0 이상 스위치 on/off
   */
  const handleVivinoFilter = (active) => {
    setFilter((filter) => ({ ...filter, vivino: active }));
  };

  /**
   * 가격대  필터
   */
  const handlePriceFilter = (minPrice, maxPrice) => {
    let min = filter.price_range_custom[0];
    let max = filter.price_range_custom[1];
    let priceAll = false;
    const minArray = [0, 10000, 50000, 100000, 200000, 500000];
    const maxArray = ['', 500000, 200000, 100000, 50000, 10000];

    // 전체 선택값 표현
    if (min === 0 && max === '' && filter.price_all) {
      min = minPrice;
      max = maxPrice;
    }
    // 같은 범위를 선택하였을 때 전체로 변경
    else if (min === minPrice && max === maxPrice) {
      min = 0;
      max = '';
      priceAll = true;
    }
    // 여러 선택범위에서 최소값이 같은 범위를 선택하였을때 최소 범위 제거
    else if (min === minPrice) {
      let minFlag = false;
      for (let i = 0; i < minArray.length; i++) {
        if (minFlag) {
          min = minArray[i];
          break;
        }
        if (minArray[i] === minPrice) minFlag = true;
      }
    }
    // 여러 선택범위에서 최대값이 같은 범위를 선택하였을때 최대 범위 제거
    else if (max === maxPrice) {
      let maxFlag = false;
      for (let i = 0; i < maxArray.length; i++) {
        if (maxFlag) {
          max = maxArray[i];
          break;
        }
        if (maxArray[i] === maxPrice) maxFlag = true;
      }
    }
    // 범위의 중간을 선택하였을 때
    else if (minPrice > min && (maxPrice < max || max === '')) {
      max = maxPrice;
    }
    // 기존에 제한없음 이거나 제한없을을 선택하였을 때 제한없음 범위 유지
    else if (maxPrice === '' || max === '') {
      if (min > minPrice) min = minPrice;
      max = '';
    }
    // 기존 최소값이 선택된 최소값보다 클때 최소값 범위 수정
    else if (min > minPrice) {
      min = minPrice;
    }
    // 기존 최대값이 선택된 최대값보다 작을때 최대값 범위 수정
    else if (max < maxPrice) {
      max = maxPrice;
    }

    setFilter((filter) => ({ ...filter, price_range_custom: [min, max], price_all: priceAll }));
  };

  /**
   * 와인 종류 필터
   */
  const handleTypeFilter = (code) => {
    setFilter((filter) => ({ ...filter, tabIndex: code }));
  };

  /**
   * 국가 필터
   */
  const handleCountryFilter = (code) => {
    const { country = [] } = filter;

    // 이미 선택되어 있을 경우 -> 제거
    if (country.includes(code)) {
      setFilter((filter) => ({ ...filter, country: country.filter((item) => item !== code) }));
      setFilterItemSequence((fis) => _.reject(fis, { item: 'country', value: code }));
    }
    // 모든 국가가 선택될 경우 초기화 -> 전체 선택한 결과와 동일
    else if (country.length === Object.keys(countryFilterItems).length) {
      setFilter((filter) => ({ ...filter, country: [] }));
      setFilterItemSequence((fis) => _.reject(fis, { item: 'country' }));
    }
    // 국가 선택
    else {
      setFilter((filter) => ({ ...filter, country: [...filter.country, code] }));
      setFilterItemSequence((fis) => {
        return _.concat(fis, { item: 'country', value: code });
      });
    }
  };

  /**
   * 품종 필터
   */
  const handleVarietyFilter = (code) => {
    const { variety = [] } = filter;
    // 이미 선택되어 있을 경우 -> 제거
    if (variety.includes(code)) {
      setFilter((filter) => ({ ...filter, variety: variety.filter((item) => item !== code) }));
      setFilterItemSequence((fis) => _.reject(fis, { item: 'variety', value: code }));
    }
    // 모든 품종이 선택될 경우 초기화 -> 전체 선택한 결과와 동일
    else if (variety.length === Object.keys(varietyFilterItems).length) {
      setFilter((filter) => ({ ...filter, variety: [] }));
      setFilterItemSequence((fis) => _.reject(fis, { item: 'variety' }));
    }
    // 품종 선택
    else {
      setFilter((filter) => ({ ...filter, variety: [...filter.variety, code] }));
      setFilterItemSequence((fis) => {
        return _.concat(fis, { item: 'variety', value: code });
      });
    }
  };

  // render
  return (
    <BottomDialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      onClose={handleFilterClose}
      aria-describedby="filter-dialog-description"
    >
      <WoDialogTitle title="와인 상세 필터" onClickClose={handleFilterClose} />
      <Divider sx={{ mx: '20px' }} />
      <DialogContent id="filter-dialog-description" ref={filterElementRef} tabIndex={-1} sx={{ p: 0, overflow: 'hidden' }}>
        <List
          className={'none-scroll-bar'}
          sx={{
            width: '100%',
            maxWidth: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            height: 'calc(var(--vh, 1vh) * 68)',
            padding: 0,
            '& ul': {
              padding: 0,
              '& li.MuiListSubheader-root': {
                color: '#140229',
                mt: '16px',
                fontSize: '16px',
                fontWeight: 900,
                px: '24px',
                top: -2
              }
            }
          }}
        >
          {/* VIVINO 평점 */}
          <li>
            <ul>
              <ListItem sx={{ pt: 0 }}>
                <Box height="24px" />
              </ListItem>
            </ul>
          </li>
          <li>
            <ul>
              <ListItem sx={{ pt: 0 }}>
                <SpaceBetweenBox width="100%" px="8px" sx={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column">
                    <Typography fontSize={16} fontWeight={900}>
                      VIVINO 4.0 이상
                    </Typography>
                  </Box>

                  <Switch
                    // disabled={freezing}
                    checked={filter.vivino}
                    checkedIcon={
                      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <Typography fontSize="11px" fontWeight={800} color="#fff" lineHeight="normal" sx={{ pt: '1px' }}>
                          ON
                        </Typography>
                      </Box>
                    }
                    uncheckedIcon={
                      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <Typography fontSize="11px" fontWeight={800} color="#fff" lineHeight="normal" sx={{ pt: '1px' }}>
                          OFF
                        </Typography>
                      </Box>
                    }
                    onChange={(checked) => {
                      handleVivinoFilter(checked);
                    }}
                  />
                </SpaceBetweenBox>
              </ListItem>
            </ul>
          </li>
          {/* 금액범위 */}
          <li>
            <ul>
              <ListSubheader>
                <SpaceBetweenBox>
                  가격대
                  {/* 금액범위 표현 */}
                  <Typography color="text.primary">
                    {priceMinLabel} ~ {priceMaxLabel}
                  </Typography>
                </SpaceBetweenBox>
              </ListSubheader>
              <ListItem sx={{ pt: 0 }}>
                <Grid container spacing="8px">
                  <Grid item>
                    <SelectChip
                      label="전체"
                      checked={filter.price_range_custom[0] === 0 && filter.price_range_custom[1] === '' && filter.price_all}
                      onClick={() => setFilter((prev) => ({ ...prev, price_range_custom: [0, ''], price_all: true }))}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="1만원 이하"
                      checked={filter.price_range_custom[0] === 0 && !filter.price_all}
                      onClick={() => handlePriceFilter(0, 10000)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="1~5만원"
                      checked={
                        ((filter.price_range_custom[0] <= 10000 && filter.price_range_custom[1] >= 50000) ||
                          (filter.price_range_custom[0] <= 10000 && filter.price_range_custom[1] === '')) &&
                        !filter.price_all
                      }
                      onClick={() => handlePriceFilter(10000, 50000)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="5~10만원"
                      checked={
                        ((filter.price_range_custom[0] <= 50000 && filter.price_range_custom[1] >= 100000) ||
                          (filter.price_range_custom[0] <= 50000 && filter.price_range_custom[1] === '')) &&
                        !filter.price_all
                      }
                      onClick={() => handlePriceFilter(50000, 100000)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="10~20만원"
                      checked={
                        ((filter.price_range_custom[0] <= 100000 && filter.price_range_custom[1] >= 200000) ||
                          (filter.price_range_custom[0] <= 100000 && filter.price_range_custom[1] === '')) &&
                        !filter.price_all
                      }
                      onClick={() => handlePriceFilter(100000, 200000)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="20~50만원"
                      checked={
                        ((filter.price_range_custom[0] <= 200000 && filter.price_range_custom[1] >= 500000) ||
                          (filter.price_range_custom[0] <= 200000 && filter.price_range_custom[1] === '')) &&
                        !filter.price_all
                      }
                      onClick={() => handlePriceFilter(200000, 500000)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectChip
                      label="50만원 이상"
                      checked={filter.price_range_custom[1] === '' && !filter.price_all}
                      onClick={() => handlePriceFilter(500000, '')}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </ul>
          </li>
          {/* 와인타입 */}
          <li>
            <ul>
              <ListSubheader>타입</ListSubheader>
              <ListItem sx={{ paddingTop: 0 }}>
                <Grid container spacing="8px">
                  <Grid item>
                    <SelectChip
                      label="전체"
                      checked={filter.tabIndex === 'all'}
                      onClick={() => handleTypeFilter('all')}
                      icon={wineType_ALL}
                      wineType={true}
                    />
                  </Grid>
                  {Object.entries(woConstants.code?.wine_type ?? {}).map(([code]) => {
                    const wineTypeImgArray = {
                      RED: wineType_RED,
                      WHITE: wineType_WHITE,
                      SPARKLING: wineType_SPARKLING,
                      ROSE: wineType_ROSE,
                      ETC: wineType_ETC
                    };
                    return (
                      <Grid item key={`type-grid-key-${code}`}>
                        <SelectChip
                          label={getCodeNameByValue('wine_type', code) ?? code}
                          checked={filter.tabIndex === code}
                          onClick={() => handleTypeFilter(code)}
                          key={`type-key-${code}`}
                          icon={wineTypeImgArray[code]}
                          wineType={true}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </ListItem>
            </ul>
          </li>
          {/* 스타일 */}
          <li>
            <ul>
              <ListSubheader>스타일</ListSubheader>
              <ListItem sx={{ pt: 0, pb: '12px' }}>
                <SpaceBetweenBox width="100%" sx={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" width="107px" paddingLeft="8px">
                    <Typography fontSize={14} fontWeight="bold" sx={{ opacity: '0.5' }}>
                      당도
                    </Typography>
                  </Box>
                  <Grid container spacing="8px">
                    <Grid item xs={4}>
                      <SelectChip
                        label="낮음"
                        checked={(filter.taste_sweet & 1) === 1}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_sweet', 1)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="중간"
                        checked={(filter.taste_sweet & 2) === 2}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_sweet', 2)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="높음"
                        checked={(filter.taste_sweet & 4) === 4}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_sweet', 4)}
                      />
                    </Grid>
                  </Grid>
                </SpaceBetweenBox>
              </ListItem>
            </ul>
            <ul>
              <ListItem sx={{ pt: 0, pb: '12px' }}>
                <SpaceBetweenBox width="100%" sx={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" width="107px" paddingLeft="8px">
                    <Typography fontSize={14} fontWeight="bold" sx={{ opacity: '0.5' }}>
                      산도
                    </Typography>
                  </Box>
                  <Grid container spacing="8px">
                    <Grid item xs={4}>
                      <SelectChip
                        label="낮음"
                        checked={(filter.taste_acidity & 1) === 1}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_acidity', 1)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="중간"
                        checked={(filter.taste_acidity & 2) === 2}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_acidity', 2)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="높음"
                        checked={(filter.taste_acidity & 4) === 4}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_acidity', 4)}
                      />
                    </Grid>
                  </Grid>
                </SpaceBetweenBox>
              </ListItem>
            </ul>
            <ul>
              <ListItem sx={{ pt: 0, pb: '12px' }}>
                <SpaceBetweenBox width="100%" sx={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" width="107px" paddingLeft="8px">
                    <Typography fontSize={14} fontWeight="bold" sx={{ opacity: '0.5' }}>
                      바디감
                    </Typography>
                  </Box>
                  <Grid container spacing="8px">
                    <Grid item xs={4}>
                      <SelectChip
                        label="낮음"
                        checked={(filter.taste_body & 1) === 1}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_body', 1)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="중간"
                        checked={(filter.taste_body & 2) === 2}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_body', 2)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="높음"
                        checked={(filter.taste_body & 4) === 4}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_body', 4)}
                      />
                    </Grid>
                  </Grid>
                </SpaceBetweenBox>
              </ListItem>
            </ul>
            <ul>
              <ListItem sx={{ pt: 0, pb: '12px' }}>
                <SpaceBetweenBox width="100%" sx={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" width="107px" paddingLeft="8px">
                    <Typography fontSize={14} fontWeight="bold" sx={{ opacity: '0.5' }}>
                      탄닌
                    </Typography>
                  </Box>
                  <Grid container spacing="8px">
                    <Grid item xs={4}>
                      <SelectChip
                        label="낮음"
                        checked={(filter.taste_tannin & 1) === 1}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_tannin', 1)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="중간"
                        checked={(filter.taste_tannin & 2) === 2}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_tannin', 2)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectChip
                        label="높음"
                        checked={(filter.taste_tannin & 4) === 4}
                        fullWidth={true}
                        onClick={() => handleTasteFilter('taste_tannin', 4)}
                      />
                    </Grid>
                  </Grid>
                </SpaceBetweenBox>
              </ListItem>
            </ul>
          </li>
          {/* 국가 */}
          <li>
            <ul>
              <ListSubheader>국가</ListSubheader>
              <ListItem sx={{ paddingTop: 0 }}>
                <Grid container rowSpacing="12px" columnSpacing="8px">
                  <Grid item>
                    <SelectChip
                      label="전체"
                      checked={!filterState.country || filter.country.length === 0}
                      onClick={() => {
                        setFilterItemSequence((fis) => _.reject(fis, { item: 'country' }));
                        setFilter((filter) => ({ ...filter, country: [] }));
                      }}
                      noRadius={true}
                    />
                  </Grid>
                  {Object.entries(countryFilterItems).map(([code, val]) => {
                    const countryImgArray = {
                      FR: country_FR,
                      IT: country_IT,
                      US: country_US,
                      CL: country_CL,
                      ES: country_ES,
                      AU: country_AU,
                      DE: country_DE,
                      AR: country_AR,
                      NZ: country_NZ,
                      ZA: country_ZA,
                      PT: country_PT
                    };
                    const { country = [] } = filter;
                    return (
                      <Grid item key={`country-grid-key-${code}`}>
                        <SelectChip
                          label={val.ko}
                          checked={country.indexOf(code) >= 0}
                          onClick={() => {
                            handleCountryFilter(code);
                          }}
                          key={`country-key-${code}`}
                          icon={countryImgArray[code]}
                          noRadius={true}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <SelectChip
                      label="기타"
                      checked={filter.country.indexOf('etc') >= 0}
                      onClick={() => {
                        handleCountryFilter('etc');
                      }}
                      noRadius={true}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </ul>
          </li>
          {/* 품종 */}
          <li>
            <ul>
              <ListSubheader>품종</ListSubheader>
              <ListItem sx={{ pt: 0, pb: '28px' }}>
                <Grid container rowSpacing="12px" columnSpacing="8px">
                  <Grid item>
                    <SelectChip
                      label="전체"
                      checked={!filterState.variety || filter.variety.length === 0}
                      onClick={() => {
                        setFilterItemSequence((fis) => _.reject(fis, { item: 'variety' }));
                        setFilter((filter) => ({ ...filter, variety: [] }));
                      }}
                    />
                  </Grid>
                  {Object.entries(varietyFilterItems).map(([code, val]) => {
                    const { variety = [] } = filter;
                    return (
                      <Grid item key={`variety-grid-key-${code}`}>
                        <SelectChip
                          label={val.ko}
                          checked={variety.indexOf(code) >= 0}
                          onClick={() => {
                            handleVarietyFilter(code);
                          }}
                          key={`variety-key-${code}`}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <SelectChip
                      label="기타"
                      checked={filter.variety.indexOf('etc') >= 0}
                      onClick={() => {
                        handleVarietyFilter('etc');
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </ul>
          </li>
        </List>
      </DialogContent>

      <Divider sx={{ mx: '20px' }} />
      <DialogActions sx={{ px: '20px', pt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" size="x-large" color="brand" sx={{ lineHeight: '1rem' }} onClick={handleReset}>
              초기화
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" size="x-large" color="brand" onClick={handleConfirm}>
              상품보기
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BottomDialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool,
  filterState: PropTypes.object.isRequired,
  filterItemSequence: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired
};

export default FilterDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// todo delete
// const TasteButton = styled((props) => <Button fullWidth size="small" {...props} />)({
//   minWidth: '16px'
// });

/**
 * 검색 필터 Chips
 */
const SelectChip = React.forwardRef(function Transition(props, ref) {
  const { label, checked, onClick, fullWidth, icon, noRadius, wineType, ...other } = props;
  const variant = checked ? 'contained' : 'outlined';

  const sx = fullWidth
    ? noRadius
      ? checked
        ? {
            width: '100%',
            borderRadius: '6px',
            backgroundColor: '#220348',
            color: '#ffffff',
            border: '1.5px solid #220348',
            '&:hover': { backgroundColor: '#220348' }
          }
        : { width: '100%', borderRadius: '6px', border: '1.5px solid #EFEDF0' }
      : checked
      ? {
          width: '100%',
          backgroundColor: '#220348',
          color: '#ffffff',
          border: '1.5px solid #220348',
          '&:hover': { backgroundColor: '#220348' }
        }
      : { width: '100%', border: '1.5px solid #EFEDF0' }
    : noRadius
    ? checked
      ? {
          borderRadius: '6px',
          backgroundColor: '#220348',
          color: '#ffffff',
          border: '1.5px solid #220348',
          '&:hover': { backgroundColor: '#220348' }
        }
      : { borderRadius: '6px', border: '1.5px solid #EFEDF0' }
    : checked
    ? { backgroundColor: '#220348', color: '#ffffff', border: '1.5px solid #220348', '&:hover': { backgroundColor: '#220348' } }
    : { border: '1.5px solid #EFEDF0' };

  const avatar = icon ? (
    <Avatar
      alt={label}
      src={checked && wineType ? wineTypeSelect : icon}
      sx={
        wineType
          ? {
              '&.MuiChip-avatar': { marginRight: '-12px' },
              '& .MuiChip-avatar': { marginRight: '-12px' },
              '& img': { width: '11px', height: '18px' }
            }
          : {
              borderRadius: '0',
              '&.MuiChip-avatar': {
                marginLeft: '12px',
                width: '16px',
                height: '16px'
              },
              '& .MuiChip-avatar': {
                marginLeft: '12px',
                width: '16px',
                height: '16px'
              }
            }
      }
    />
  ) : undefined;
  return <Chip ref={ref} variant={variant} label={label} sx={sx} avatar={avatar} onClick={onClick} {...other} />;
});
SelectChip.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  noRadius: PropTypes.bool,
  wineType: PropTypes.bool
};
