import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Autoplay, Pagination, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import projects
import { useWineOne } from 'hooks';
import { BackButton, CartButton } from 'components/buttons';
import { StarOffIcon, StarOnIcon } from 'components';
import './vendorHeader.scss';

// material-ui
import { AppBar, Box, Container, IconButton, Skeleton, Stack, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

// assets
import defaultShopDetailImg from 'assets/images/default_shop_detail_img.png';

// 입점샵 대표이미지 세로 사이즈
const COVER_IMAGE_HEIGHT = 210;

/**
 * 입점샵 상세페이지의 header
 *
 * @constructor
 * @authors 이재일<leeji@wineone.io>
 */
function BackwardTitle({
  imgs = [],
  onBackButtonClick = null,
  showBottomNav: showBottomNavProp = true,
  vendor,
  fvr = 0,
  toggleVendorFvr,
  children
}) {
  const { showBottomNav, hideBottomNav, resetBottomNav } = useWineOne();
  // 로그인 사용자 정보
  const theme = useTheme();
  const navigate = useNavigate();

  const classes = useStyles();

  React.useEffect(() => {
    // console.log('@마운트됨');
    if (showBottomNavProp) {
      showBottomNav();
    } else {
      hideBottomNav();
    }

    return () => {
      // console.log('@언마운트됨');
      resetBottomNav();
    };
  }, []);

  // 샵 이미지 coverImage ref
  const coverImageRef = React.useRef(null);
  // 샵 이미지 여부
  const [vendorImgFlag, setVendorImgFlag] = React.useState(false);
  // 샵 이미지 정보
  const [vendorImages, setVendorImages] = React.useState({ loaded: false, imageList: [] });

  // 관심샵 등록/삭제 로딩
  const [fvrToggleLoading, setFvrToggleLoading] = React.useState(false);

  // 입점샵 정보가 로드되었을 시 입점샵 이미지 검사
  React.useLayoutEffect(() => {
    if (!vendor?.vendor_imgs) return;
    if (!imgs || imgs.length === 0) {
      setVendorImages({ loaded: true, imageList: [] });
      setVendorImgFlag(false);
    } else {
      setVendorImages({ loaded: true, imageList: imgs });
      setVendorImgFlag(true);
    }
  }, [vendor?.vendor_imgs]);

  // 임점샵 이미지 cover 높이
  const coverHeight = React.useMemo(() => {
    return coverImageRef.current ? coverImageRef.current.offsetHeight - 56 : window.screen.width;
  }, [coverImageRef.current]);

  // 스크롤 트리거
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: coverHeight
  });

  // 뒤로가기 버튼 클릭
  const handleBackButtonClick = React.useCallback(() => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [onBackButtonClick]);

  // 뒤로가기 버튼
  const backButton = React.useMemo(
    () => <BackButton color={!vendorImgFlag || trigger ? theme.palette.brand.main : '#fff'} onClick={handleBackButtonClick} />,
    [handleBackButtonClick, trigger, vendorImgFlag]
  );

  // 관심샵 추가/삭제
  const vendorFvrChange = async () => {
    await setFvrToggleLoading(true);
    await toggleVendorFvr(fvr);
    await setFvrToggleLoading(false);
  };

  const headerBgColor = React.useMemo(
    () => (vendorImages.loaded && (!vendorImgFlag || trigger) ? 'background.paper' : 'transparent'),
    [vendorImages.loaded, vendorImgFlag, trigger]
  );

  // render
  return (
    <Container maxWidth="xs" sx={{ bgcolor: 'background.paper', px: [0, 0, 0, 0], position: 'relative' }}>
      <AppBar
        className={classes.header}
        sx={{
          bgcolor: headerBgColor
        }}
      >
        <Toolbar sx={{ px: [2.5, 0, 0, 0] }}>
          {/* 페이지 제목 */}
          <Box position="absolute" width="100%" left={0} display="flex" justifyContent="center" alignItems="center">
            <Typography component="div" fontSize={18} fontWeight={800} maxWidth="150px" noWrap>
              {!vendorImgFlag || trigger ? vendor?.biz?.name : ''}
            </Typography>
          </Box>

          {/* 뒤로가기 버튼 */}
          {backButton}

          <Box flexGrow={1} />

          <Stack direction="row" spacing="8px" sx={{ display: 'flex', alignItems: 'center' }}>
            {/* 관심샵 버튼 */}
            <IconButtonBase sx={{ borderRadius: '50%' }} onClick={vendorFvrChange}>
              {fvr ? (
                <StarOnIcon color={!vendorImgFlag || trigger ? '#140229' : '#fff'} />
              ) : (
                <StarOffIcon color={!vendorImgFlag || trigger ? '#140229' : '#fff'} />
              )}
            </IconButtonBase>
            {/* 장바구니 버튼 */}
            <CartButton color={!vendorImgFlag || trigger ? '#140229' : '#fff'} showBadge />
          </Stack>
        </Toolbar>
      </AppBar>

      {/* 입점샵 이미지 로드 전 skeleton영역 */}
      {!vendorImages.loaded && <Skeleton width="100%" height="210px" animation="wave" variant="rectangular" />}

      {/* 입점샵 이미지 */}
      {vendorImages.loaded && vendorImages.imageList.length > 0 && (
        <Container ref={coverImageRef} sx={{ p: [0, 0], position: 'relative', width: '100%', height: COVER_IMAGE_HEIGHT }}>
          <Swiper
            id="vendor-images-swiper"
            parallax
            // loop
            speed={600}
            pagination={{
              type: 'fraction',
              renderFraction: (currentClass, totalClass) => {
                return `<div class="fraction-box">
                          <span class="${currentClass}"></span> / <span class="${totalClass}"></span>
                        </div>`;
              }
            }}
            modules={[Autoplay, Parallax, Pagination]}
            style={{
              height: '100%',
              backgroundColor: theme.palette.alternate.main
            }}
          >
            {vendorImages.imageList.map((image, i) => (
              <SwiperSlide key={`vendor-image-${i}`} style={{ position: 'relative' }}>
                {/* 샵 이미지 상단 50% 영역 그라데이션 처리 */}
                <Box
                  sx={{
                    width: '100%',
                    height: '50%',
                    position: 'absolute',
                    zIndex: 1,
                    background: `linear-gradient(180deg, #1616164D, #54545400)`
                  }}
                />
                {/* 샵 이미지 표시 */}
                <LazyLoadImage
                  wrapperClassName={classes.vendorCoverImage}
                  placeholder={defaultShopDetailImg}
                  src={image ? image.thumb : ''}
                  visibleByDefault
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      )}
      <main>{children}</main>
    </Container>
  );
}

BackwardTitle.propTypes = {
  imgs: PropTypes.any,
  onBackButtonClick: PropTypes.func,
  vendor: PropTypes.object,
  showBottomNav: PropTypes.bool,
  fvr: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  toggleVendorFvr: PropTypes.func,
  children: PropTypes.node
};

export default BackwardTitle;

const IconButtonBase = styled(IconButton)({
  color: '#f9b546',
  padding: 0
});

const useStyles = makeStyles(() => ({
  header: { maxWidth: 444, left: 'auto', right: 'auto' },
  vendorCoverImage: {
    width: '100%',
    height: '100%',
    '& img': {
      position: 'relative',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center top',
      imageRendering: 'auto'
    }
  }
}));
