import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card, CardContent, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { alpha } from '@mui/material/styles';

/**
 * 환불안내
 * @authors 최효근<hkchoi@wineone.io>
 */
const CoBuyingRefundInfo = React.memo(() => (
  <Card elevation={0}>
    <CardContent>
      <Stack spacing="16px">
        <RefundInfoContent>
          공동 구매 상품은 주문 기간 동안 목표 수량 미달성 시 자동 취소 처리 되며, 결제하신 수단으로 자동 환불됩니다.
        </RefundInfoContent>
        <RefundInfoContent>
          공동 구매 상품은 목표 수량 달성 시 할인된 가격으로 판매 되는 상품으로, 주문 마감 후 취소/반품이 불가합니다.
        </RefundInfoContent>
        <RefundInfoContent>픽업 시에 성인 인증을 위한 신분증 확인이 진행되니, 꼭 신분증을 챙겨주세요!</RefundInfoContent>
      </Stack>
    </CardContent>
  </Card>
));

export default CoBuyingRefundInfo;

const infoColor = alpha('#140229', 0.7);

const RefundInfoContent = React.memo(({ children }) => (
  <Box fontSize="12px" lineHeight="16px" width="100%" display="flex" color={infoColor}>
    <Box>
      <CheckIcon fontSize="12px" color={infoColor} />
    </Box>
    <Box pl="8px">{children}</Box>
  </Box>
));
RefundInfoContent.propTypes = {
  children: PropTypes.any
};
