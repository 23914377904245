/* *********************************************************************************************************************
 * Elstic Search
 *  - https://github.com/elastic/app-search-javascript
 ******************************************************************************************************************** */

import * as ElasticAppSearch from '@elastic/app-search-javascript';

/**
 * Elastic App Search에 있는 Engine명
 *
 * @constant
 * @type {{VARIETY: string, PIN: string, COUNTRY: string, PRODUCT: string, AROMA: string, IMPORTER: string, PDATA: string, WINE_TYPE: string}}
 *
 * @authors 조현권<hkcho@wineone.io>
 */
export const ENGINE = Object.freeze({
  PIN: 'pin',
  PDATA: 'pdata',
  PRODUCT: 'product',
  /** @deprecated */
  VENDOR: 'vendor',
  EVENT: 'event',
  NOTICE: 'notice'
});

/**
 * Elastic App Search client 생성
 * @param engineName 검색대상 엔진이름
 *
 * @authors 조현권<hkcho@wineone.io>
 */
export const createElasticAppSearchClient = (engineName) => {
  // let client = _cachedClients_[engineName];
  //
  // try {
  //   if (!client) {
  //     if (!getKeyByValue(engineName)) {
  //       throw new Error(`Invalid engine name: '${engineName}'`);
  //     }
  //
  //     console.info('Create Elastic App Search Client. [engineName: %s]', engineName);
  //     client = ElasticAppSearch.createClient({
  //       searchKey: process.env.REACT_APP_ELSTC_SRCH_API_KEY,
  //       endpointBase: process.env.REACT_APP_ELSTC_SRCH_ENDPOINT_BASE,
  //       cacheResponses: false,
  //       engineName
  //     });
  //     _cachedClients_[engineName] = client;
  //   } else {
  //     // console.debug('Elastic App Search client is already created.[engineName: %s]', engineName);
  //   }
  // } catch (error) {
  //   console.error('Failed to create Elastic App Search client. [engineName: %s]', engineName);
  // }
  // // console.debug('[1KMWINE] Elastic App Search client: %o', client);
  // return client;
  return ElasticAppSearch.createClient({
    searchKey: process.env.REACT_APP_ELSTC_SRCH_API_KEY,
    endpointBase: process.env.REACT_APP_ELSTC_SRCH_ENDPOINT_BASE,
    cacheResponses: false,
    engineName
  });
};

// 엔진 중복호출 개선을 위한 Elastic 엔진 client cache
// const _cachedClients_ = {};
//
// function getKeyByValue(value) {
//   return Object.keys(ENGINE)[Object.values(ENGINE).indexOf(value)];
// }
