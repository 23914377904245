import axios from 'axios';
import { FIREBASE_FUNCITON_URL, NODE_URL } from './index';

/** wish(찜) 하기 */
export async function addWishItem(pdataId) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__WISH_PDATA, { pdata_id: pdataId });
}

export function removeWishItem() {
  console.log('###################### wish아이템 제거');
}

export function getWishList({ coordinate, page }) {
  return axios.post(NODE_URL.POST__WISH_LIST, { coordinate, page }).then(({ data }) => data);
}
