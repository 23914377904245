import React from 'react';
import PropTypes from 'prop-types';
import { StarIcon } from '../../icons';

function ReviewStars({ stars = null }) {
  return stars == null ? null : (
    <span>
      <StarIcon filled={stars >= 1} />
      <StarIcon filled={stars >= 2} />
      <StarIcon filled={stars >= 3} />
      <StarIcon filled={stars >= 4} />
      <StarIcon filled={stars === 5} />
    </span>
  );
}

ReviewStars.propTypes = {
  stars: PropTypes.number
};

export default ReviewStars;
