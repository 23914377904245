import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const SmallButton = styled((props) => <Button variant="outlined" className="Km-SmallButton" {...props} />)`
  height: 27px;
  border-color: #d9d3df;
  border-radius: 14px;
  padding: 6px 11px;
  font-size: 11px;
  line-height: normal;
`;

export default SmallButton;
