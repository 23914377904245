import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { Helmet } from 'react-helmet-async';

// project imports
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { APP_TYPE } from 'store/slices/wine-one';
import MyCommonLayout from '../layout/MyCommonLayout';
import { InventoryDashboard, UserProfileCard } from './components';
import { WoAlert } from 'utils/kmwine-alerts';
import { getDashboardCounts } from 'services/UserService';
import { PIN_MAP_OPEN_HASH, USER_LOCATION_DIALOG_HASH } from 'components/widgets/UserLocationSheet';
import { BtnMoreIcon, SmallButton, SpaceBetweenBox } from 'components';
import { PinMap } from 'components/widgets/UserLocationSheet/components';

// material-ui
import { Box, ButtonBase, Container, Dialog, Divider, Fab, Slide, Typography } from '@mui/material';

// assets
import evt221017Icon from 'assets/images/evt22101701/evt221017_01_icon.png';
import ArrowBackImg from 'assets/images/arrow-back.png';

/**
 * 마이메뉴 인덱스 페이지
 * @returns {JSX.Element}
 * @constructor
 */
function MyIndex() {
  const scriptedRef = useScriptRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, refresh } = useAuth();

  const {
    appVersion,
    appType, // 접근 앱 타입
    onNativeBackPressed, // 기기에서 뒤로가기버튼 눌렀을 때
    refreshUserGeolocation
  } = useWineOne();

  React.useLayoutEffect(() => {
    refresh(); // 사용자 정보 최신화
    refreshUserGeolocation(); // 사용자 위치정보 최신화
  }, []);

  const [dashboardCounts, setDashboardCounts] = useLocationState('my_cnts', { order: -1, coupon: -1, review: -1, event: -1 });

  const fetchDashboardCounts = React.useCallback(async () => {
    const result = await getDashboardCounts().catch((error) => {
      console.error('마이페이지 > 대시보드 카운트 조회 실패', error);
      return { order: -1, coupon: -1, review: -1, event: -1 };
    });

    if (!scriptedRef.current) return;

    setDashboardCounts({
      order: result.order_count,
      coupon: result.coupon_count,
      review: result.review_count,
      event: result.event.app_event_count
    });
  }, [setDashboardCounts]);

  React.useEffect(() => {
    fetchDashboardCounts(); // 주문, 쿠폰, 리뷰, 이벤트 개수 조회
  }, [fetchDashboardCounts]);

  // 사용자 프로필 카드
  const userProfileCard = React.useMemo(() => <UserProfileCard user={user} />, [user]);

  // '사용자 위치설정' 다이얼로그 열림여부
  // todo hash이동이 아닌 pushState로 변경할 것
  const locationOpen = React.useMemo(() => {
    return location.hash === USER_LOCATION_DIALOG_HASH;
  }, [location]);

  // 핀선택지도 열림여부
  const mapOpen = React.useMemo(() => location.hash === PIN_MAP_OPEN_HASH, [location.hash]);

  // 핀선택지도 열기
  const openPinMap = () => {
    navigate({ search: location.search, hash: PIN_MAP_OPEN_HASH }, { state: location.state });
  };

  // 핀선택지도 닫기
  const handleMapDialogClose = () => {
    console.debug('[MyIndex.jsx] 핀 선택지도 닫기.');
    if (location.hash === PIN_MAP_OPEN_HASH) navigate(-1); // 중복클릭 방지
  };

  // 핀 선택완료됨
  const onPinConfirmed = (result) => {
    if (!result.success) {
      WoAlert.fire(`위치 설정 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {});
    } else {
      handleMapDialogClose(); // 핀 지도 닫기
    }
  };

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(() => {
      console.info(`[MyIndex.jsx] 기기의 '뒤로가기' 버튼이 감지되었습니다.`);

      // 위치핀 선택지도가 열려있을 경우
      if (mapOpen) {
        console.log('[마이] 핀 선택지도 닫기');
        handleMapDialogClose();
      }
      // 위치설정 dialog가 열려있을 경우
      else if (locationOpen) {
        // onLocationDialogClose();
        handleMapDialogClose();
      } else {
        navigate(-1);
      }
    });
  }, [location, mapOpen, locationOpen]);

  const moveToAppStore = () => {
    if (appType === APP_TYPE.AOS) {
      // todo 플레이스토어 새탭으로 띄우기
      console.log('플레이스토어 새탭으로 띄우기');
    } else if (appType === APP_TYPE.IOS) {
      // todo Appstore Connect 새탭으로 띄우기
      console.log('Appstore Connect 새탭으로 띄우기');
    }
  };

  // todo 업데이트 가능여부 체크
  const needUpdate = React.useMemo(() => {
    // console.log('----------------------- appType: ', appType);
    // console.log('----------------------- appVersion: ', appVersion);
    return true;
  }, [appVersion, appType]);

  // render
  return (
    <MyCommonLayout>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 마이페이지`} />
      <Container maxWidth="xs" sx={{ px: [2, 2, 2, 2] }}>
        {/* 사용자 프로필 */}
        {userProfileCard}

        {/* 주문/픽업, 리뷰, 쿠폰 */}
        <Box>
          <InventoryDashboard dashboardCounts={dashboardCounts} />
        </Box>
        {/* 메뉴 리스트[start] */}
        {/* 최근 본 상품[start] */}
        {/* <Box my="10px"> */}
        {/*   <ButtonBase */}
        {/*     sx={{ height: '50px', width: '100%' }} */}
        {/*     onClick={() => { */}
        {/*       WoAlert.fire('준비 중 입니다.', '', 'warning'); */}
        {/*     }} */}
        {/*   > */}
        {/*     <SpaceBetweenBox width="100%"> */}
        {/*       <Typography fontSize={14} fontWeight="bold"> */}
        {/*         최근 본 상품 */}
        {/*       </Typography> */}
        {/*       <BtnMoreIcon /> */}
        {/*     </SpaceBetweenBox> */}
        {/*   </ButtonBase> */}
        {/* </Box> */}
        {/* 최근 본 상품[end] */}

        <Divider />

        {/* 친구추천쿠폰 이벤트[start]: todo 이벤트 종료 후 삭제 */}
        {/* {showEvt221017_01 && ( */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              navigate(`/event/evt221017_01`);
            }}
          >
            <SpaceBetweenBox width="100%">
              <Box textAlign="left" maxWidth="calc( 100% - 18px )" display="flex" alignItems="center" sx={{ ml: '-5px' }}>
                <img src={evt221017Icon} alt="친구추천" style={{ width: 30, height: 30, padding: '5px', margin: '0 6px 4px 6px' }} />
                <Typography fontSize={14} fontWeight="bold">
                  {/* 친구초대 이벤트 💌 <br /> 5,000원 할인 쿠폰과 스타벅스 쿠폰까지 */}
                  친구 초대하고, <b style={{ fontWeight: 800 }}>5,000원 쿠폰</b> 받기
                </Typography>
              </Box>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* )} */}
        {/* 친구추천쿠폰 이벤트[end]: todo 이벤트 종료 후 삭제 */}

        {/* 키워드 알림[start] */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              navigate('keyword/alarm');
            }}
          >
            <SpaceBetweenBox width="100%">
              <Box textAlign="left" maxWidth="calc( 100% - 18px )">
                <Typography fontSize={14} fontWeight="bold">
                  키워드 알림
                </Typography>
                <Typography variant="caption" noWrap display="inline-block" maxWidth="100%" sx={{ opacity: 0.4 }}>
                  관심있는 단어를 키워드로 설정하시면 알림을 받을 수 있습니다.
                </Typography>
              </Box>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* 키워드 알림[end] */}

        {/* 위치 설정[start] */}
        <Box my="10px">
          <ButtonBase sx={{ height: '50px', width: '100%' }} onClick={openPinMap}>
            <SpaceBetweenBox width="100%">
              <Typography fontSize={14} fontWeight="bold">
                위치 설정
              </Typography>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* 위치 설정[end] */}

        {/* 앱 설정[start] : todo 앱 연동 */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              // WoAlert.fire('준비 중 입니다.', '', 'warning');
              navigate('/my/app-setting');
            }}
          >
            <SpaceBetweenBox width="100%">
              <Typography fontSize={14} fontWeight="bold">
                앱 설정
              </Typography>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* 앱 설정[end] */}

        <Divider />

        {/* Faq[start] */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              console.debug(`FAQ 화면으로 이동합니다.`);
              navigate('/my/faq');
            }}
          >
            <SpaceBetweenBox width="100%">
              <Typography fontSize={14} fontWeight="bold">
                FAQ
              </Typography>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* Faq[end] */}

        {/* 공지사항[start] */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              console.debug(`공지사항 리스트 화면으로 이동합니다.`);
              navigate('/my/notice/list');
            }}
          >
            <SpaceBetweenBox width="100%">
              <Typography fontSize={14} fontWeight="bold">
                공지사항
              </Typography>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* 공지사항[end] */}

        {/* 약관 및 정책[start] */}
        <Box my="10px">
          <ButtonBase
            sx={{ height: '50px', width: '100%' }}
            onClick={() => {
              console.debug(`약관 및 정책 화면으로 이동합니다.`);
              navigate('/my/docs');
            }}
          >
            <SpaceBetweenBox width="100%">
              <Typography fontSize={14} fontWeight="bold">
                약관 및 정책
              </Typography>
              <BtnMoreIcon />
            </SpaceBetweenBox>
          </ButtonBase>
        </Box>
        {/* 약관 및 정책[end] */}

        {/* 약관 및 정책[start] */}
        {appType !== APP_TYPE.WEB && appVersion.name && (
          <Box my="10px">
            <SpaceBetweenBox height="50px" width="100%">
              <Typography fontSize={14} fontWeight="bold">
                현재 버전 {appVersion.name}
              </Typography>
              {needUpdate && <SmallButton onClick={() => moveToAppStore()}>최신 버전 업데이트 하기</SmallButton>}
            </SpaceBetweenBox>
          </Box>
        )}

        {/* 약관 및 정책[end] */}

        {/* 메뉴 리스트[end] */}

        {/* 핀선택 지도[start] todo refactoring */}
        {/* {userLocationDialog} */}
        <Dialog
          open={mapOpen}
          scroll={'paper'}
          fullScreen
          TransitionComponent={PinMapTransition}
          keepMounted
          onClose={handleMapDialogClose}
          aria-describedby="pin-select-map-dialog-title"
        >
          <Fab
            color="white"
            onClick={handleMapDialogClose}
            aria-label="위치설정 닫기"
            sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
          >
            <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} alt="" />
          </Fab>
          {mapOpen ? (
            <>
              <PinMap height={`calc(var(--vh, 1vh) * 100`} onPinConfirmed={onPinConfirmed} />
            </>
          ) : null}
        </Dialog>
        {/* 핀선택 지도[end] */}
      </Container>
    </MyCommonLayout>
  );
}

export default MyIndex;

// 직접위치설정 dialog transition. todo refactoring -> component로 만들 것
/* eslint-disable react/prop-types */
const PinMapTransition = React.forwardRef(function Transition(props, ref) {
  const { children, ...other } = props;
  return (
    <Slide {...other} ref={ref} direction="left">
      {children}
    </Slide>
  );
});
