import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// import projects
import { useWineOne } from '../../hooks';
import BackButton from '../../components/buttons/BackButton';
import { WoDivider } from '../../components/dialog';
import { AlarmButton, CartButton } from 'components/buttons';
import Footer from 'components/Footer';

// third-party: material-ui
import { AppBar, Box, Container, Stack, Toolbar, Typography } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ShareButton from '../../components/buttons/ShareButton';

/**
 * 뒤로가기 헤더가 있는 레이아웃
 *
 * @param title 헤더 제목
 * @param showFooter 푸터 표현여부
 * @param showBottomNav 하단 네비게이션 표현여부
 * @param showAlarms 알람보기 버튼 표현여부
 * @param showCart 장바구니보기 버튼 표현여부
 * @param hideDivider Divider 숨김
 * @returns {JSX.Element}
 * @constructor
 */
function BackwardTitle({
  appBarPosition = 'sticky',
  showBackButton = true,
  onBackButtonClick = null,
  title = '',
  children,
  showFooter = true,
  showBottomNav: showBottomNavProp = true,
  showAlarms: showAlarmsProp = false,
  showCart: showCardsProp = false,
  showShare: showShareProp = false,
  shareProp = null,
  hideDivider = false,
  rightTool = null,
  secondaryToolbar = null
}) {
  const { showBottomNav, hideBottomNav, resetBottomNav } = useWineOne();
  const navigate = useNavigate();

  React.useEffect(() => {
    // console.log('@마운트됨');
    if (showBottomNavProp) {
      showBottomNav();
    } else {
      hideBottomNav();
    }

    return () => {
      // console.log('@언마운트됨');
      resetBottomNav();
    };
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1
  });

  // 뒤로가기 버튼 클릭
  const handleBackButtonClick = React.useCallback(() => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [onBackButtonClick]);

  const backButton = React.useMemo(
    () => (showBackButton ? <BackButton color="inherit" onClick={handleBackButtonClick} /> : null),
    [showBackButton, handleBackButtonClick]
  );

  // 알림버튼
  const alarmButtonComponent = React.useMemo(() => {
    return <AlarmButton showBadge />;
  }, [showAlarmsProp]);

  // 공유하기 버튼
  const shareButtonComponent = React.useMemo(() => {
    return <ShareButton {...shareProp} />;
  }, [shareProp]);

  // render
  return (
    <Box bgcolor="background.paper">
      <AppBar position={appBarPosition} elevation={trigger ? 1 : 0}>
        <Container disableGutters maxWidth="xs" sx={{ px: [0, 0, 0, 0] }}>
          <Toolbar sx={{ px: [2, 2, 2, 2], height: ['54px', '54px', '54px', '54px'], minHeight: ['54px', '54px', '54px', '54px'] }}>
            {/* 페이지 제목 */}
            <Box position="absolute" width="100%" left={0} display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="div"
                fontSize={18}
                fontWeight={800}
                maxWidth={showAlarmsProp && showCardsProp ? '150px' : '200px'}
                noWrap
              >
                {title}
              </Typography>
            </Box>

            {/* 뒤로가기 버튼 */}
            {backButton}
            <Box flexGrow={1} />

            <Stack direction="row" spacing="8px" sx={{ display: 'flex', alignItems: 'center' }}>
              {/* 장바구니 버튼 */}
              {showCardsProp && <CartButton showBadge />}

              {/* 알람 버튼 */}
              {showAlarmsProp && alarmButtonComponent}

              {/* 공유 버튼 */}
              {showShareProp && shareButtonComponent}

              {rightTool && <>{rightTool}</>}
            </Stack>
          </Toolbar>
          {/* Secondary Toolbar */}
          {secondaryToolbar && <>{secondaryToolbar}</>}

          {!hideDivider && <WoDivider />}
        </Container>
      </AppBar>
      <main>{children}</main>
      {showFooter && <MemoizedFooter />}
    </Box>
  );
}

BackwardTitle.propTypes = {
  appBarPosition: PropTypes.string,
  showBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  showFooter: PropTypes.bool,
  showBottomNav: PropTypes.bool,
  showAlarms: PropTypes.bool,
  showCart: PropTypes.bool,
  showShare: PropTypes.bool,
  shareProp: PropTypes.object,
  hideDivider: PropTypes.bool,
  rightTool: PropTypes.node,
  secondaryToolbar: PropTypes.object
};

export default BackwardTitle;

const MemoizedFooter = React.memo(Footer);
