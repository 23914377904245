import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { HeartIcon } from '../icons';
import { openSnackbar } from '../../store/slices/snackbar';
import { useDispatch } from '../../store';
import { useLocation } from 'react-router-dom';

// material-ui
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import heartbeatLoaderJson from 'assets/lottie/heartbeat-loader.json';
import lottie from 'lottie-web';
import { useAmplitude, useAuth, useScriptRef } from '../../hooks';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import { getFirestore } from '../../utils/firebase-tools';
import { addWishItem } from '../../services/UserService';

// amplitude
import { amplitude } from 'index';

function WishButton({ pdataId, pdataName, onWishChange = () => false, ...other }) {
  const scriptedRef = useScriptRef();
  const containerRef = useRef(null);
  const [isWish, setIsWish] = useState(null);
  const [loading, setLoading] = useState(true);

  const { logEvent } = useAmplitude();

  const location = useLocation();

  const { user } = useAuth();

  const globalDispatch = useDispatch();

  useEffect(() => {
    try {
      containerRef.current.innerHTML = '';
    } catch (e) {
      /* DO NOTHING */
    }
    lottie.loadAnimation({
      container: containerRef.current,
      loop: true,
      autoplay: true,
      animationData: heartbeatLoaderJson,
      rendererSettings: { clearCanvas: true }
    });
  }, [isWish, loading]);

  useEffect(() => {
    getDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
      .then((docSnapshot) => {
        if (scriptedRef.current) {
          setIsWish(docSnapshot.exists());
        }
      })
      .catch((e) => {
        console.error(`와인[pdata.id=${pdataId}]의 wish 여부 조회 중 오류가 발생했습니다.`, e);
      })
      .finally(() => {
        if (scriptedRef.current) {
          setLoading(false);
        }
      });
  }, [pdataId]);

  const toggleWish = () => {
    // if (loading) {
    //   console.warn('[wish] 다른 기능이 요청 중 입니다.');
    //   return false;
    // }
    setLoading(true);
    // console.log(`현재 wish 여부 : ${isWish}`);
    if (isWish) {
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에서 제외.`);
      setIsWish(false);
      deleteDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
        .then((r) => {
          console.debug('찜목록 제외 완료', r);

          // Amplitude number of wishlist wines user property
          const identify = new amplitude.Identify().add('number of wishlist wines', -1);
          amplitude.getInstance().identify(identify);

          if (scriptedRef.current) {
            setIsWish(false);
            onWishChange(false);
          }

          globalDispatch(
            openSnackbar({
              open: true,
              message: '상품을 위시 리스트에서 제외합니다.',
              variant: 'alert',
              alert: { color: 'success' },
              close: true,
              autoHideDuration: 2000
            })
          );
        })
        .finally(() => {
          if (scriptedRef.current) {
            setLoading(false);
          }
        });
    } else {
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에 추가.`);

      setIsWish(true);
      addWishItem(pdataId)
        .then((r) => {
          if (scriptedRef.current) {
            console.debug('찜목록 추가 완료. response=', r);

            // 주문 가능 와인샵
            if (location.pathname.includes('/mall/wine')) {
              logEvent.addToWishList('주문 가능 와인샵', pdataId, pdataName);
            }
            // 와인샵 상세페이지
            if (location.pathname.includes('/vendor/d')) {
              logEvent.addToWishList('와인샵 상세', pdataId, pdataName);
            }
            // 실시간 구매 현황 페이지
            if (location.pathname.includes('/home/live')) {
              logEvent.addToWishList('실시간 구매 현황', pdataId, pdataName);
            }
            // 이벤트 페이지
            if (location.pathname.includes('/my/event')) {
              logEvent.addToWishList('이벤트', pdataId, pdataName);
            }

            // 홈 리뷰 목록 페이지
            if (location.pathname === '/home/main-reviews') {
              logEvent.addToWishList('리뷰 목록', pdataId, pdataName);
            }

            // 리뷰 상세 페이지
            if (location.pathname.includes('/home/main-review')) {
              logEvent.addToWishList('리뷰 상세', pdataId, pdataName);
            }

            // 큐레이션 카드 페이지
            if (location.pathname.includes('/home/curation-card')) {
              logEvent.addToWishList('큐레이션 카드', pdataId, pdataName);
            }

            // 매거진 페이지
            if (location.pathname.includes('/magazine')) {
              logEvent.addToWishList('매거진', pdataId, pdataName);
            }

            // 주문하기 페이지
            if (location.pathname === '/order/confirm') {
              logEvent.addToWishList('주문하기', pdataId, pdataName);
            }

            setIsWish(true);
            onWishChange(true);
          }

          globalDispatch(
            openSnackbar({
              open: true,
              message: '상품을 위시 리스트에 추가합니다.',
              variant: 'alert',
              alert: { color: 'success' },
              close: true,
              autoHideDuration: 2000
            })
          );
        })
        .finally(() => {
          if (scriptedRef.current) {
            setLoading(false);
          }
        });
    }
  };

  return isWish == null /* || loading */ ? (
    <Box ref={containerRef} width={30} height={30} />
  ) : (
    <IconButtonBase aria-label="와인 찜하기" onClick={toggleWish} {...other}>
      <HeartIcon filled={isWish} />
    </IconButtonBase>
  );
}

WishButton.propTypes = {
  pdataId: PropTypes.string.isRequired,
  pdataName: PropTypes.string.isRequired,
  onWishChange: PropTypes.func
};

export default WishButton;

const IconButtonBase = styled(IconButton)({
  color: '#fb6464',
  padding: 0
});
