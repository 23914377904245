import React from 'react';

// project imports
import { VendorImage } from 'components/v2';
import { VendorAddress } from '../../../../../vendor/VendorList/components/VendorCard/components';

// material-ui
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';

// 입점샵 이미지 가로 크기
const VENDOR_IMAGE_WIDTH = 60;
// 입점샵 이미지 세로 크기
const VENDOR_IMAGE_HEIGHT = 60;

/**
 * 오늘의 한 병 입점샵 정보
 */
const VendorInfo = ({ vendorSrc, vendorName, vendorAddress }) => {
  const classes = useStyles();

  // render
  return (
    <Box>
      <Box
        component="section"
        position="relative"
        display="flex"
        width="100%"
        sx={{ flexDirection: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        {/* 샵 이미지 영역 */}
        <VendorImage src={vendorSrc} width={VENDOR_IMAGE_WIDTH} height={VENDOR_IMAGE_HEIGHT} alt="입점샵 대표 이미지" />
        {/* 샵 정보 영역 */}
        <Box className={classes.vendorInfoBox}>
          <Box width="100%">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* 와인샵 이름 */}
              <Typography noWrap className={classes.vendorBizName}>
                {vendorName}
              </Typography>
            </Box>

            <Box>
              <VendorAddress>{vendorAddress}</VendorAddress>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorInfo;

VendorInfo.propTypes = {
  vendorSrc: PropTypes.string,
  vendorName: PropTypes.string,
  vendorAddress: PropTypes.string
};

const useStyles = makeStyles((theme) => ({
  vendorInfoBox: {
    display: 'flex',
    alignItems: 'center',
    width: `calc( 100% - ${VENDOR_IMAGE_WIDTH}px )`,
    height: VENDOR_IMAGE_HEIGHT,
    marginLeft: '8px'
  },
  vendorBizName: {
    width: '100%',
    maxWidth: '100%',
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '-0.14px',
    color: '#140229'
  }
}));
