import React from 'react';
import { useLocationState } from 'react-router-use-location-state';
import { useNavigate } from 'react-router-dom';
import { josa } from 'josa';

// project imports
import { getLatestTerm, TERM_TYPE, termTypeToString } from 'services/AppService';
import { BottomDialog, WoDialogTitle, WoDivider } from './dialog';
import { ErrorIcon } from './icons';

// material-ui
import { Box, Button, ButtonBase, Container, DialogContent, Grid, Stack, Typography } from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

const initialTermState = Object.freeze({
  loaded: false,
  error: false,
  type: null,
  term: null
});

/**
 * 와인원(1KMWINE) footer
 * @constructor
 */
function Footer() {
  const navigate = useNavigate();

  // 약관 다이얼로그 열림여부
  const [openDialog, setOpenDialog] = useLocationState('ftTermDialogOpen', false);

  // 약관/정책
  const [termState, setTermState] = React.useState(initialTermState);

  const handleCloseDialog = () => {
    navigate(-1);
  };

  const handleTermDialog = (type) => {
    setOpenDialog(true, { method: 'push' });
    fetchTerm(type);
  };

  const fetchTerm = (type) => {
    setTermState({ ...initialTermState, type });

    getLatestTerm(type.toUpperCase())
      .then((snapshot) => {
        if (!snapshot.empty) {
          setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
        } else {
          console.error(`[1kmwine] 문서가 존재하지 않습니다. [type=${type}]`);
          setTermState({ ...termState, type, loaded: true, error: false, term: null });
        }
      })
      .catch((e) => {
        console.error('[1kmwine] 문서 조회 중 오류가 발생했습니다.', e);
        setTermState({ ...termState, type, loaded: true, error: true, term: null });
      });
  };

  return (
    <Box component="footer" sx={{ px: 0 }}>
      <Grid container sx={{ minHeight: 97 }}>
        <Grid item xs={12} sx={{ bgcolor: '#f7f4fa', p: '25px 20px 20px' }}>
          <Typography variant="subtitle2" fontSize={12} fontWeight={800} letterSpacing={'-0.12px'}>
            {process.env.REACT_APP_COMPANY_NAME}
          </Typography>
          <Box mt={1}>
            <Typography variant="body2" component="p" fontSize={11} lineHeight={1.73} letterSpacing="-0.11px">
              {process.env.REACT_APP_COMPANY_ADDR}
            </Typography>
            <Typography variant="body2" component="p" fontSize={11} lineHeight={1.73} letterSpacing="-0.11px">
              대표자명 : {process.env.REACT_APP_COMPANY_CEO_NAME}
            </Typography>
            <Typography variant="body2" component="p" fontSize={11} lineHeight={1.73} letterSpacing="-0.11px">
              사업자등록번호 : {process.env.REACT_APP_COMPANY_REG_NO}
            </Typography>
            <Typography variant="body2" component="p" fontSize={11} lineHeight={1.73} letterSpacing="-0.11px">
              통신판매업 신고번호 : {process.env.REACT_APP_E_MARKET_REG_NO}
            </Typography>
            <Typography variant="body2" component="p" fontSize={11} lineHeight={1.73} letterSpacing="-0.11px">
              고객센터 : {process.env.REACT_APP_CS_EMAIL_ADDRESS}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ bgcolor: '#ebe7ef', p: '25px 20px 20px' }}>
          <Box height="100%" display="flex" justifyContent="space-between" flexDirection="column">
            <Typography lineHeight={1.73} letterSpacing="-0.11px" fontSize={11} color="text.primary" sx={{ opacity: 0.4 }}>
              {josa(`${process.env.REACT_APP_COMPANY_NAME}#{는}`)} 상품 주문/픽업 과정에 직접 관여하지 않으며 상품 주문, 픽업, 환불의 의무와
              책임은 각 판매업체에 있습니다.
            </Typography>

            <Stack
              display="flex"
              direction="row"
              justifyContent="flex-start"
              sx={{ marginTop: '6px' }}
              spacing={1}
              divider={<Box>&middot;</Box>}
            >
              <TermButton
                docName={termTypeToString(TERM_TYPE.SERVICE)}
                onClick={() => {
                  handleTermDialog(TERM_TYPE.SERVICE);
                }}
              />
              <TermButton
                docName={termTypeToString(TERM_TYPE.PERSONAL)}
                onClick={() => {
                  handleTermDialog(TERM_TYPE.PERSONAL);
                }}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <BottomDialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <WoDialogTitle title={termTypeToString(termState.type)} onClickClose={handleCloseDialog} />
        <WoDivider />
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <div dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </BottomDialog>
    </Box>
  );
}

export default Footer;

/* eslint-disable react/prop-types */
const TermButton = ({ docName, onClick }) => {
  // 법적문서 dialog, todo 서비스 이용약관, 개인정보 보호방침을 Portal로 구현할 것
  return (
    <ButtonBase onClick={onClick} sx={{ fontSize: '11px', fontWeight: 'normal', lineHeight: 1.73, letterSpacing: '-0.11px' }}>
      {docName}
    </ButtonBase>
  );
};
