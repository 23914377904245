import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, ButtonBase, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * 카테고리 박스
 *
 * @authors 최효근 <hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function CategoryBox({ category, icon }) {
  const navigate = useNavigate();

  return (
    <Box width="100%" textAlign="center">
      <ButtonBase
        aria-label={`category-button-${category.value}`}
        onClick={() => {
          console.log(category.value);
          navigate('/mall', { state: { category: category.value } });
        }}
        sx={{
          position: 'relative',
          width: '100%',
          paddingBottom: '100%',
          backgroundColor: '#F4F0F8',
          borderRadius: '20px',
          padding: '10px'
        }}
      >
        <Box component="img" src={icon} width="100%" height="100%" />
      </ButtonBase>
      <Box mt="7px">
        <Typography fontSize="12px" textAlign="center" height="20px">
          {category.label.ko}
        </Typography>
      </Box>
    </Box>
  );
}

export default CategoryBox;

CategoryBox.propTypes = {
  category: PropTypes.object,
  icon: PropTypes.any
};
