import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

// project imports
import { WoDivider } from 'components/dialog';
import OrderStateGroup from './OrdetStateGroup';
import { OrderActionButtons } from 'components/widgets/order';

// material-ui
import { Box, Button, Card, CardHeader, CircularProgress, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  orderCard: {
    padding: '0',
    backgroundColor: 'transparent'
  }
});

/**
 * 주문내역 목록 > 주문정보 카드
 *
 * @param {object} order
 * @param {function} orderAction
 * @param {function}orderItemAction
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>, 제동균<jedk@wineone.io>, 이재일<leeji@wineone.io>
 */
function OrderCard({ order, orderAction, orderItemAction, moveToPdataInfo }) {
  const navigate = useNavigate();
  const classes = useStyles();

  const isTicket = React.useMemo(() => {
    return order.order_products[Object.keys(order.order_products)[0]].items[0]?.pdata.category === 'ticket';
  }, [order]);

  const {
    oid: orderNo, // 주문번호
    created_at: orderDate, // 주문 생성일
    vendor // 판매사(입점사) 정보
  } = React.useMemo(() => order, [order]);

  // 주문상품 배열
  const orderProducts = React.useMemo(() => order?.order_products ?? {}, [order?.order_products]);

  // 주문상세 페이지로 이동
  const moveToOrderDetail = React.useCallback(() => navigate(`/my/order/${orderNo}/detail`), [orderNo]);

  // 주문일시
  const orderDateStr = React.useMemo(() => {
    if (!orderDate) return '';
    return moment(orderDate).format('YYYY. MM. DD (ddd) a hh:mm');
  }, [orderDate]);

  // render
  return (
    <Card component="section" className={`no-border-radius order-card ${classes.orderCard}`} elevation={0}>
      <WoDivider px={0} />
      <OrderCardHeader
        sx={{ px: 1 }}
        title={orderDateStr} // 주문일시
        action={<OrderDetailButton onClick={moveToOrderDetail} />} // 주문상세 페이지 이동버튼
      />
      <OrderContentBox className="order-card-body">
        <Box mb="16px" textAlign="center" position="relative">
          {/* 주문한 샵 이름 */}
          <Typography fontSize={16} fontWeight={900}>
            {vendor.biz.name}
          </Typography>
          {order.loading && (
            <Box position="absolute" right={4} top="50%" sx={{ transform: 'translateY(-50%)' }}>
              <CircularProgress size={12} />
            </Box>
          )}
        </Box>
        <Divider />
        {/* 주문 상품 목록 [START] */}
        {/* PROD-308: CO_BUY(공동구매 진행 중) */}
        <OrderStateGroup
          groupState="CO_BUY"
          groupNm="공동구매"
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />

        <OrderStateGroup
          groupState="KM_PROMOTION"
          groupNm="프로모션"
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />

        {/* REQ(승인요청 상태) */}
        <OrderStateGroup
          groupState="REQ"
          groupNm={orderProducts.REQ?.state_nm}
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />

        {/* REQ_OK(전체승인, 부분승인) 상태 */}
        {/* 럭키박스/티켓일 경우 배송대기 */}
        <OrderStateGroup
          groupState="REQ_OK"
          groupNm={isTicket ? '배송대기' : '픽업대기'}
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />

        {/* 주문단위 기능버튼 모음 (주문의 상태가 승인요청(REQ) 또는 전체승인/부분승인(REQ_OK)일 경우에만 표시 */}
        {/* [PROD-308] 공동구매의 경우 아래 버튼 표현 안함 */}
        {(order.state === 'REQ' || order.state === 'REQ_OK') && (
          <OrderActionButtons
            order={order}
            wrapper={(props) => <Box sx={{ mt: '16px' }} {...props} />}
            handleClick={(e, action) => {
              // 주문취소 화면으로 이동
              if (action === 'order.cancel') {
                navigate(`/my/order/${orderNo}/cancel`, { state: { order } });
              } else {
                orderAction(action);
              }
            }}
          />
        )}

        {/* --------------------------------------------------------------------------------------------------------------------------- */}
        {/* 픽업완료 상태 */}
        <OrderStateGroup
          groupState="PICK_OK"
          groupNm={orderProducts.PICK_OK?.state_nm}
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />
        {/* 구매확정 상태 */}
        <OrderStateGroup
          groupState="CONFIRM"
          groupNm={orderProducts.CONFIRM?.state_nm}
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />
        {/* 주문 취소 상태 */}
        <OrderStateGroup
          groupState="CANCEL"
          groupNm="주문 취소"
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />
        {/* 교환/반품 상태 */}
        <OrderStateGroup
          groupState="EXCRTN"
          groupNm="교환/반품"
          order={order}
          orderItemAction={orderItemAction}
          detailPdataInfo={moveToPdataInfo}
        />
        {/*   /!* 주문 상품 목록 [END] *!/ */}
      </OrderContentBox>
    </Card>
  );
}

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  orderAction: PropTypes.func.isRequired,
  orderItemAction: PropTypes.func.isRequired,
  moveToPdataInfo: PropTypes.func
};

export default OrderCard;

/* eslint-disable react/prop-types */
function OrderDetailButton({
  onClick = () => {
    console.warn('[OrderComponents.OrderDetailButton] Click event callback NOT exists.');
  }
}) {
  return (
    <Button size="small" color="gray" onClick={onClick} sx={{ opacity: 0.45 }}>
      <Typography variant="caption" lineHeight={0} fontSize={12} color="text.primary">
        주문상세
      </Typography>
      <ArrowForwardIosOutlinedIcon sx={{ ml: 0.5, fontSize: 11 }} />
    </Button>
  );
}

const OrderCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: '20px 0',
  '& .MuiCardHeader-title': {
    fontSize: '12px',
    fontWeight: 800,
    lineHeight: 'normal',
    color: theme.palette.text.primary
  }
}));

const OrderContentBox = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  paddingTop: '16px',
  paddingBottom: '20px',
  paddingLeft: '16px',
  paddingRight: '16px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  border: '1px solid #e7e4eb',
  height: 'auto',
  transition: theme.transitions.create(['height'])
}));
