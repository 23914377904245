import React from 'react';
import PropTypes from 'prop-types';

// import project
import { BottomDialog, WoDialogTitle } from '../../../../components';
import { openSnackbar } from 'store/slices/snackbar';
import Coupon from './Coupon';

// import mui
import { AlertTitle, Box, Button, CircularProgress, Container, DialogContent, Divider, Stack, Typography } from '@mui/material';

// assets
import { downloadCoupons } from '../../../../services/UserService';
import { useDispatch } from 'react-redux';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../../../utils/firebase-tools';
import { CLO_CODE } from '../../../../config';
import { WoAlert } from '../../../../utils/kmwine-alerts';

/**
 * 프로모션 쿠폰 다이얼로그
 *
 * @returns {JSX.Element}
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function CouponDialog({
  couponDrawerOpen,
  setCouponDrawerOpen,
  couponDialogList,
  setCouponDialogList,
  couponList,
  setCouponList,
  couponLoading,
  setCouponLoading
}) {
  const globalDispatch = useDispatch();

  // 쿠폰이 다운 가능한지 체크
  const findCouponAvailable = (coupon) => {
    if (coupon.my.downloaded === 1) return false;
    if (coupon.publish !== -1 && coupon.publish > coupon.download) return false;
    if (new Date() > new Date(coupon.download_period.end_dt)) return false;
    if (new Date() < new Date(coupon.download_period.start_dt)) return false;

    return true;
  };

  // 모두다운로드가 가능한지 체크
  const checkAllAvailable = React.useMemo(() => {
    if (couponDialogList.length > 1) {
      return couponDialogList.some((coupon) => findCouponAvailable(coupon));
    }
    return true;
  }, [couponDialogList]);

  // 쿠폰 한번에 다운받기
  const couponAllDownload = async () => {
    setCouponLoading(true);
    for (let i = 0; i < couponDialogList.length; i++) {
      const coupon = couponDialogList[i];

      if (findCouponAvailable(coupon)) {
        const result = await downloadCoupons([coupon.id]);

        if (result.data.result.code !== 0) {
          httpsCallable(
            getFirebaseFunctions(),
            'call-cdm-clo-error'
          )({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `쿠폰 다운로드 중 중 에러`,
            msg: ` ${JSON.stringify(result.data.result.msg)}`
          })
            .then(console.log)
            .catch(console.error);
          setCouponLoading(false);
          WoAlert.fire(`쿠폰 다운로드 중 에러가 발생하였습니다. </br> 잠시후 다시 시도해주세요`, '', 'error');

          return;
        }

        findRightCouponAndChange(coupon.id);
      }
    }
    setCouponLoading(false);
    globalDispatch(
      openSnackbar({
        open: true,
        message: (
          <>
            <AlertTitle>쿠폰이 발급되었습니다.</AlertTitle>
            <Typography variant="caption">프로모션이 마감되기 전에 쿠폰으로 할인 받고 상품을 주문해보세요!</Typography>
          </>
        ),
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true,
        autoHideDuration: 6000
      })
    );
  };

  // 맞는 쿠폰을 찾아서 상태값바꾸기
  const findRightCouponAndChange = (couponId) => {
    setCouponList((prevCouponList) => {
      const updatedCouponList = prevCouponList.map((couponItem) => {
        if (couponItem.p_cpn_type === 'pack') {
          const updatedCoupons = couponItem.coupons.map((coupon) => {
            if (coupon.id === couponId) {
              return {
                ...coupon,
                my: {
                  ...coupon.my,
                  downloaded: 1
                }
              };
            }
            return coupon;
          });
          return {
            ...couponItem,
            coupons: updatedCoupons
          };
        }
        if (couponItem.id === couponId) {
          return {
            ...couponItem,
            my: {
              ...couponItem.my,
              downloaded: 1
            }
          };
        }
        return couponItem;
      });

      // 맞는 쿠폰찾아서 dialoglist까지 바꾸기
      updatedCouponList.forEach((coupon) => {
        if (coupon.p_cpn_type === 'coupon' && coupon.id === couponDialogList[0].id) {
          setCouponDialogList([coupon]);
        } else if (coupon.p_cpn_type === 'pack' && coupon.coupons[0].id === couponDialogList[0].id) {
          setCouponDialogList(coupon.coupons);
        }
      });

      return updatedCouponList;
    });
  };

  return (
    <BottomDialog open={couponDrawerOpen} onClose={() => setCouponDrawerOpen(false)}>
      <WoDialogTitle title="쿠폰 다운로드" onClickClose={() => setCouponDrawerOpen(false)} />
      <DialogContent sx={{ px: 0, pb: 0 }}>
        <Container maxWidth="xs" disableGutters sx={{ height: '100%' }}>
          <Box component="section" sx={{ px: '20px', pt: '2px' }}>
            <Stack>
              {/* 쿠폰 목록 표현 */}
              {couponDialogList.map((coupon) => (
                <Coupon
                  key={`prm-coup-dwn-${coupon.id}`}
                  coupon={coupon}
                  couponList={couponList}
                  setCouponList={setCouponList}
                  couponLoading={couponLoading}
                  setCouponLoading={setCouponLoading}
                  findRightCouponAndChange={findRightCouponAndChange}
                />
              ))}
            </Stack>

            {/* 쿠폰이 여러개 있을 경우 모두 다운받기 버튼 */}
            {couponDialogList.length > 1 && (
              <Box mt={4} mx={-0.5} px={0.5} pb={3} position="sticky" bottom={0} bgcolor="background.paper">
                <Divider sx={{ borderColor: '#2D154D' }} />
                <Button
                  disabled={!checkAllAvailable}
                  fullWidth
                  variant="contained"
                  sx={{ height: '46px', mt: '16px', backgroundColor: '#220348' }}
                  onClick={couponAllDownload}
                >
                  {couponLoading ? (
                    <CircularProgress color="success" size="20px" />
                  ) : (
                    <Typography color={checkAllAvailable ? '#DEFD9A' : ''} fontSize="17px" fontWeight="800">
                      쿠폰 한 번에 다운받기
                    </Typography>
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </DialogContent>
    </BottomDialog>
  );
}

export default CouponDialog;

CouponDialog.propTypes = {
  couponDrawerOpen: PropTypes.bool,
  setCouponDrawerOpen: PropTypes.func,
  couponDialogList: PropTypes.array,
  setCouponDialogList: PropTypes.func,
  couponList: PropTypes.array,
  setCouponList: PropTypes.func,
  couponLoading: PropTypes.bool,
  setCouponLoading: PropTypes.func
};
