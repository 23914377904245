/**
 * 1KMWINE order utilities.
 */

// project imports
import { ORDER_ITEM } from 'config';
import moment from 'moment';

class OrderTool {
  constructor() {
    // eslint-disable-next-line no-throw-literal
    throw '인스턴스화 할 수 없습니다.';
  }

  /**
   * 교환/반품 가능한 주문상품인지 확인
   * @param {string} orderState - 주문상태
   * @param {string} orderProductState - 주문상품상태
   * @returns {boolean} - 교환/반품 가능여부
   */
  static isExcRtnAbleOrderProduct(orderState, orderProductState) {
    let excRtnAble = false;

    // eslint-disable-next-line default-case
    switch (orderProductState) {
      // 픽업완료
      case ORDER_ITEM.STATE.PICK_UP_DONE:
        excRtnAble = true;
    }

    return excRtnAble;
  }

  /**
   * 주문상품 상태코드를 한글로 변환함
   * @param {string} orderProductState - 주문상품 상태코드
   * @returns {string} 상태
   */
  static orderItemStateToKorean(orderProductState) {
    let label = '알 수 없음';

    // 주문요청 = 주문 확인 중
    if (orderProductState === ORDER_ITEM.STATE.REQUEST) {
      label = '주문 확인 중';
    }
    // 주문취소 (사용자)
    else if (orderProductState === ORDER_ITEM.STATE.ORDER_CANCEL) {
      label = '주문취소';
    }
    // 주문승인 = 부분승인
    else if (orderProductState === ORDER_ITEM.STATE.PART_APPROVED) {
      label = '부분승인';
    }
    // 주문승인 = 픽업대기
    else if (orderProductState === ORDER_ITEM.STATE.APPROVED) {
      label = '픽업대기';
    }
    // 주문취소 (판매사)
    else if (orderProductState === ORDER_ITEM.STATE.REJECT) {
      label = '와인샵 취소';
    }
    // 픽업완료
    else if (orderProductState === ORDER_ITEM.STATE.PICK_UP_DONE) {
      label = '픽업완료';
    }
    // 교환신청
    else if (orderProductState === ORDER_ITEM.STATE.REQUEST_EXCHANGE) {
      label = '교환신청';
    }
    // 교환취소 (판매사)
    else if (orderProductState === ORDER_ITEM.STATE.EXCHANGE_REJECTED) {
      label = '와인샵 교환취소';
    }
    // 교환대기
    else if (orderProductState === ORDER_ITEM.STATE.EXCHANGE_WAIT) {
      label = '교환대기';
    }
    // 반품신청
    else if (orderProductState === ORDER_ITEM.STATE.REQUEST_RETURN) {
      label = '반품신청';
    }
    // 반품취소 (판매사)
    else if (orderProductState === ORDER_ITEM.STATE.RETURN_REJECTED) {
      label = '와인샵 반품취소';
    }
    // 반품대기
    else if (orderProductState === ORDER_ITEM.STATE.RETURN_WAIT) {
      label = '반품대기';
    }
    // 반품완료
    else if (orderProductState === ORDER_ITEM.STATE.RETURN_COMPLETE) {
      label = '반품완료';
    }
    // 구매확정
    else if (orderProductState === ORDER_ITEM.STATE.PURCHASE_CONFIRM) {
      label = '구매확정';
    } else {
      console.warn('[OrderTool] 주문상품 상태코드 변환. 정의되지 않은 코드 = ' + orderProductState);
    }
    return label;
  }

  /**
   * 주문상품
   * @param orderProduct
   * @returns {null}
   */
  static orderItemStateDateStr(orderProduct) {
    if (orderProduct == null) return null;
    const { state, state_at } = orderProduct;

    if (!state_at) return null;

    let label = null;
    // 주문취소
    if (state === ORDER_ITEM.STATE.ORDER_CANCEL) {
      label = '취소일시';
    }
    // 교환요청 & 반품요청
    else if (state === ORDER_ITEM.STATE.REQUEST_EXCHANGE || state === ORDER_ITEM.STATE.REQUEST_RETURN) {
      label = '요청일시';
    }
    // 교환승인
    else if (state === ORDER_ITEM.STATE.EXCHANGE_WAIT || state === ORDER_ITEM.STATE.RETURN_WAIT) {
      label = '교환확인 일시';
    }

    // 반품완료
    else if (state === ORDER_ITEM.STATE.RETURN_COMPLETE) {
      label = '반품일시';
    } else {
      console.warn('[OrderTool] 주문상품 상태일시 안내 텍스트. ', state);
    }

    if (label == null) return null;

    const dateStr = moment(state_at.toDate()).format('YYYY.MM.DD (ddd) A hh:mm');
    return `${label} : ${dateStr}`;
  }

  /**
   * 결제정보 뱃지 문자열
   * @param {Object} payment 지불정보
   */
  static paymentBadgeStr(payment) {
    let str = null;
    try {
      if (payment.p_type === 'CARD') {
        str = this.pSrcCodeToString(payment.p_src_code);
        // str = payment.p_fn_nm ?? payment.p_card_issuer_name;

        // console.log('--------------------- payment: ', payment);

        /*
         * p_card_checkflag => 0: 신용, 1: 체크, 2: 기프트
         */

        // 신용카드
        if (payment.p_card_checkflag === '0') {
          if (str) str += ' ';
          str += payment.p_fn_nm;

          // 신용카드일 경우 할부개월 (일시불: '00', 할부)
          if (payment.p_rmesg2 === '00') {
            // str += ` 일시불`;
          } else {
            // console.warn('할부개월 수 표현. payment.p_rmesg2=', payment.p_rmesg2);
            str += ` ${Number(payment.p_rmesg2)}개월`;
          }
        }
        // 체크카드
        else if (payment.p_card_checkflag === '1') {
          if (str) str += ' ';
          str += payment?.p_fn_nm ?? '';
        }
      } else {
        str = '기타';
      }
    } catch (e) {
      console.warn('결제정보 뱃지 문자열 생성 실패. ', e);
      str = null;
    }

    return str;
  }

  /**
   * 간편(앱)결제 코드를 문자열로 변환
   * @param {'C', 'B', 'D', 'G', 'O', 'L', 'K', 'H', 'I', 'R', 'U', 'J'}pSrcCode
   * @link https://manual.inicis.com/pay/stdpay_m.html#popup_48
   */
  static pSrcCodeToString(pSrcCode) {
    let ganpyunName;
    switch (pSrcCode) {
      case 'C':
        ganpyunName = 'PAYCO';
        break;
      case 'B':
        ganpyunName = '삼성페이';
        break;
      case 'D':
        ganpyunName = '삼성페이(체크)';
        break;
      case 'G':
        ganpyunName = 'SSGPAY';
        break;
      case 'O':
        ganpyunName = 'KAKAOPAY';
        break;
      case 'L':
        ganpyunName = 'LPAY';
        break;
      case 'K':
        ganpyunName = '국민앱카드';
        break;
      case 'H':
        ganpyunName = '토스페이';
        break;
      case 'I':
        ganpyunName = '네이버페이';
        break;
      case 'R':
        ganpyunName = '차이페이';
        break;
      case 'U':
        ganpyunName = '티머니페이';
        break;
      case 'J':
        ganpyunName = '핀페이';
        break;
      default:
        ganpyunName = '';
        // ganpyunName = `간편결제`;
        // if (pSrcCode) ganpyunName += `(${pSrcCode})`;
        // else console.warn('알 수 없는 간편결제 코드: ', pSrcCode);
        break;
    }
    // console.log('###########################pSrcCode: ', pSrcCode, ganpyunName);

    return ganpyunName;
  }
}

export default OrderTool;
