import { styled } from '@mui/material/styles';
import MuiSearchIcon from '@mui/icons-material/Search';

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

function SearchInputIcon() {
  return (
    <SearchIconWrapper>
      <MuiSearchIcon />
    </SearchIconWrapper>
  );
}

export default SearchInputIcon;
