import React from 'react';
import { Radio as MuiRadio } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

function Radio(props) {
  return (
    <MuiRadio
      sx={{
        '&:hover': {
          bgcolor: 'transparent'
        }
      }}
      disableRipple
      color="default"
      checkedIcon={<WoCheckedIcon />}
      icon={<WoIcon />}
      {...props}
    />
  );
}

const WoIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: alpha(theme.palette.brand.main, 0.25),
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#ffffff,#ffffff 58%,transparent 50%)',
    content: '""'
  },
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: '#30404d'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(57,75,89,.5)'
  }
}));

const WoCheckedIcon = styled(WoIcon)(({ theme }) => ({
  backgroundColor: theme.palette.brand.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#defd99,#defd99 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.brand.main
  }
}));

export default Radio;
