import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, SvgIcon, Typography } from '@mui/material';

// assets
import ReviewThreadBannerImg from '../assets/review_mini banner.png';

/**
 * 리뷰 목록이 비어있음 component
 * @constructor
 *
 * @authros 이재일<leeji@wineone.io>
 */
const ReviewThreadEmpty = ({ showHeader = true, contentHeight, bgcolor = '#F1F0F2', contentPd = '80px' }) => {
  return (
    <Box bgcolor={bgcolor} height={contentHeight}>
      {showHeader && (
        <Box component="img" src={ReviewThreadBannerImg} alt="review_thread_banner_img" sx={{ width: '100%', height: '100px' }} />
      )}
      <Box pt={contentPd} pb={showHeader ? 0 : '60px'} textAlign="center">
        <EmptyReviewIcon />
        <Stack pt="60px" spacing={1}>
          <Typography sx={{ fontSize: 16, fontWeight: 700, lineHeight: 'normal' }}>아직 등록된 리뷰가 없습니다.</Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 300, lineHeight: 'noraml' }}>지금 리뷰를 작성 해보세요!</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default ReviewThreadEmpty;

ReviewThreadEmpty.propTypes = {
  showHeader: PropTypes.bool,
  contentHeight: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  contentPd: PropTypes.string
};

// 리뷰 비어있음 아이콘
const EmptyReviewIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 86, height: 86, fill: 'none' }} viewBox="0 0 86 86">
      <path
        d="M43.001 67.4993L45.9346 62.0005H74V22.9998H11.999V62.0005H40.0684L43.001 67.4993ZM43 74.8743C42.6533 74.8743 42.3066 74.698 42.1182 74.3452L37.668 66.0005H7.99902V18.9998H78V66.0005H48.334L43.8818 74.3452C43.6934 74.698 43.3467 74.8743 43 74.8743Z"
        fill="#E5DCEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 46.3611L39.553 48.8611C39.3987 48.9728 39.2071 49.0205 39.0184 48.994C38.8298 48.9675 38.6587 48.869 38.541 48.7191C38.4711 48.6287 38.4249 48.5223 38.4065 48.4094C38.3882 48.2966 38.3983 48.181 38.436 48.0731L39.819 44.1161L36.306 41.7051C36.2289 41.6535 36.163 41.5867 36.1122 41.5089C36.0615 41.4312 36.027 41.3439 36.0109 41.2525C35.9947 41.161 35.9972 41.0673 36.0182 40.9768C36.0392 40.8864 36.0783 40.8011 36.133 40.7261C36.2018 40.6318 36.2921 40.5553 36.3965 40.5031C36.5009 40.4509 36.6163 40.4245 36.733 40.4261L41.033 40.4811L42.308 36.4911C42.3685 36.311 42.497 36.1617 42.6662 36.0751C42.8353 35.9886 43.0316 35.9717 43.213 36.0281C43.324 36.0609 43.4253 36.1202 43.5082 36.2008C43.5912 36.2815 43.6532 36.3811 43.689 36.4911L44.964 40.4811L49.264 40.4261C49.4531 40.421 49.6364 40.4911 49.7739 40.621C49.9113 40.7509 49.9915 40.9301 49.997 41.1191C49.9978 41.2339 49.9702 41.3472 49.9169 41.4488C49.8635 41.5505 49.7859 41.6375 49.691 41.7021L46.181 44.1161L47.564 48.0721C47.5938 48.1603 47.6056 48.2534 47.5989 48.3462C47.5922 48.439 47.5671 48.5295 47.525 48.6125C47.4829 48.6954 47.4247 48.7691 47.3538 48.8294C47.2829 48.8896 47.2007 48.935 47.112 48.9631C47.0012 49.0001 46.8831 49.0103 46.7675 48.9929C46.652 48.9755 46.5421 48.931 46.447 48.8631L43 46.3611Z"
        fill="#E5DCEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 46.3611L22.553 48.8611C22.3987 48.9728 22.2071 49.0205 22.0184 48.994C21.8298 48.9675 21.6587 48.869 21.541 48.7191C21.4711 48.6287 21.4249 48.5223 21.4065 48.4094C21.3882 48.2966 21.3983 48.181 21.436 48.0731L22.819 44.1161L19.306 41.7051C19.2289 41.6535 19.163 41.5867 19.1122 41.5089C19.0615 41.4312 19.027 41.3439 19.0109 41.2525C18.9947 41.161 18.9972 41.0673 19.0182 40.9768C19.0392 40.8864 19.0783 40.8011 19.133 40.7261C19.2018 40.6318 19.2921 40.5553 19.3965 40.5031C19.5009 40.4509 19.6163 40.4245 19.733 40.4261L24.033 40.4811L25.308 36.4911C25.3685 36.311 25.497 36.1617 25.6662 36.0751C25.8353 35.9886 26.0316 35.9717 26.213 36.0281C26.324 36.0609 26.4253 36.1202 26.5082 36.2008C26.5912 36.2815 26.6532 36.3811 26.689 36.4911L27.964 40.4811L32.264 40.4261C32.4531 40.421 32.6364 40.4911 32.7739 40.621C32.9113 40.7509 32.9915 40.9301 32.997 41.1191C32.9978 41.2339 32.9702 41.3472 32.9169 41.4488C32.8635 41.5505 32.7859 41.6375 32.691 41.7021L29.181 44.1161L30.564 48.0721C30.5938 48.1603 30.6056 48.2534 30.5989 48.3462C30.5922 48.439 30.5671 48.5295 30.525 48.6125C30.4829 48.6954 30.4247 48.7691 30.3538 48.8294C30.2829 48.8896 30.2007 48.935 30.112 48.9631C30.0012 49.0001 29.8831 49.0103 29.7675 48.9929C29.652 48.9755 29.5421 48.931 29.447 48.8631L26 46.3611Z"
        fill="#E5DCEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 46.3611L56.553 48.8611C56.3987 48.9728 56.2071 49.0205 56.0184 48.994C55.8298 48.9675 55.6587 48.869 55.541 48.7191C55.4711 48.6287 55.4249 48.5223 55.4065 48.4094C55.3882 48.2966 55.3983 48.181 55.436 48.0731L56.819 44.1161L53.306 41.7051C53.2289 41.6535 53.163 41.5867 53.1122 41.5089C53.0615 41.4312 53.027 41.3439 53.0109 41.2525C52.9947 41.161 52.9972 41.0673 53.0182 40.9768C53.0392 40.8864 53.0783 40.8011 53.133 40.7261C53.2018 40.6318 53.2921 40.5553 53.3965 40.5031C53.5009 40.4509 53.6163 40.4245 53.733 40.4261L58.033 40.4811L59.308 36.4911C59.3685 36.311 59.497 36.1617 59.6662 36.0751C59.8353 35.9886 60.0316 35.9717 60.213 36.0281C60.324 36.0609 60.4253 36.1202 60.5082 36.2008C60.5912 36.2815 60.6532 36.3811 60.689 36.4911L61.964 40.4811L66.264 40.4261C66.4531 40.421 66.6364 40.4911 66.7739 40.621C66.9113 40.7509 66.9915 40.9301 66.997 41.1191C66.9978 41.2339 66.9702 41.3472 66.9169 41.4488C66.8635 41.5505 66.7859 41.6375 66.691 41.7021L63.181 44.1161L64.564 48.0721C64.5938 48.1603 64.6056 48.2534 64.5989 48.3462C64.5922 48.439 64.5671 48.5295 64.525 48.6125C64.4829 48.6954 64.4247 48.7691 64.3538 48.8294C64.2829 48.8896 64.2007 48.935 64.112 48.9631C64.0012 49.0001 63.8831 49.0103 63.7675 48.9929C63.652 48.9755 63.5421 48.931 63.447 48.8631L60 46.3611Z"
        fill="#E5DCEF"
      />
    </SvgIcon>
  );
});
