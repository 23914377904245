import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// import assets
import vendorDefaultImg from 'assets/images/default_vendor.png';
import { useNavigationType } from 'react-router-dom';

/**
 * 샵 이미지
 *
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function VendorImage(props) {
  const { width, height, borderRadius = 10, ratio, src: srcProp = null, alt, children } = props;

  // 이미지 src
  const [src, setSrc] = React.useState(srcProp);
  const navigationType = useNavigationType();

  React.useLayoutEffect(() => {
    if (srcProp?.thumb) {
      if (Array.isArray(srcProp.thumb)) {
        if (srcProp.thumb.length > 0) {
          setSrc(srcProp.thumb[0]);
        } else {
          setSrc(vendorDefaultImg);
        }
      } else {
        setSrc(srcProp.thumb);
      }
    } else {
      setSrc(vendorDefaultImg);
    }
  }, [srcProp]);

  // render
  return (
    <VendorImageWrapper width={width} height={height} borderRadius={borderRadius} ratio={ratio}>
      {/* {image} - style.scss#product-image-wrapper */}
      <LazyLoadImage
        placeholder={
          <img
            src={vendorDefaultImg}
            alt=""
            draggable="false"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center',
              borderRadius: `${borderRadius}px`
            }}
          />
        }
        visibleByDefault={navigationType === 'POP'}
        width="100%"
        height="100%"
        effect="blur"
        draggable={false}
        src={src}
        alt={alt}
      />
      {children}
    </VendorImageWrapper>
  );
}

VendorImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.number,
  ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.any,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node
};

// export
export default VendorImage;

/**
 * 샵 이미지 래퍼(wrapper)
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
const VendorImageWrapper = styled((props) => {
  const { ratio, width, height, borderRadius, ...other } = props;
  let imgHeight = height;

  if (ratio) imgHeight = width * ratio;
  else if (Number.isNaN(imgHeight)) imgHeight = '100%';

  return (
    <Box
      width={width}
      height={imgHeight}
      ratio={ratio}
      minHeight={imgHeight}
      minWidth={width}
      overflow="hidden"
      draggable={false}
      borderRadius={`${borderRadius}px`}
      {...other}
    />
  );
})(
  () => `
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  & img:first-of-type {
      object-fit: cover;
  }
`
);
