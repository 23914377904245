import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator/es';

// third-party: firebase
import { httpsCallable } from 'firebase/functions';
import {
  browserLocalPersistence,
  getAuth,
  OAuthProvider,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth';

// third-party: amplitude
import { amplitude } from 'index';

// reducer - state management
import { LOGIN, LOGOUT, REFRESH } from 'store/actions';
import accountReducer, { initialState } from 'store/accountReducer';

// project imports
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getFirebaseFunctions, getFirestore } from '../utils/firebase-tools';
import { CLO_CODE, IS_PRODUCTION, USER_LOCATION_TYPE } from '../config';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { resetUserLocation, setUserLocation } from 'store/slices/user-location';

import { USER_AGENT } from 'store/slices/wine-one';
import { removeFcmToken, updateLastConnectionTime } from '../services/UserService';
import { WineBottleLoadingLottie } from '../components/spinner';
import { closeBackdrop, openBackdrop } from 'store/slices/backdrop';
import ChannelService from '../components/channelService';
import MaintenanceDialog from '../components/dialog/MaintenanceDialog';
import useMobileNative from 'hooks/useMobileNative';
import { fetchCartItems, resetUserCart } from 'store/slices/cart';
import { requestKakaoSignIn, signUp } from 'services/AuthService';
import jwt_decode from 'jwt-decode';

import { Box, Typography } from '@mui/material';

const { navigator } = window;
const { userAgent } = navigator;

const AuthContext = React.createContext(null);

/**
 * WineOne Authentication Provider
 *
 * @param children
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>, 제동균<jedk@wineone.io>
 */
export const WineOneAuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(accountReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const globalDispatch = useDispatch();
  const auth = getAuth();
  const { onAuthChecked } = useMobileNative();

  // 사용자 위치정보
  const globalState = useSelector((state) => state);
  const { userLocation, wineOne } = globalState;

  const geolocationRef = React.useRef(wineOne.geolocation);
  geolocationRef.current = wineOne.geolocation;

  React.useEffect(() => {
    geolocationRef.current = wineOne.geolocation;
  }, [wineOne.geolocation]);

  // 사용자 GOD 모드 -> 로그인 사용자의 `member` 컬렉션의 `god` 필드가 true 일 경우
  const isGod = React.useMemo(() => {
    try {
      if (!state.isInitialized) return false;
      return state.user?.god ?? false;
    } catch (e) {
      console.error('---e: ', e);
      return false;
    }
  }, [state.isInitialized, state.user]);

  /*
   * 웹 서비스 이용가능여부
   *   - ok: 정상
   *   - maintenance: 점검 중
   */
  const isAvailable = React.useMemo(() => wineOne.health.state === 'ok' || isGod, [wineOne.health, isGod]);

  /*
   * 핀위치 지정이 필요한 사용자인지 여부
   * @since v1.0.10
   */
  const needPinSetting = React.useMemo(() => {
    // 로그인하지 않은 사용자는 핀세팅 필요 없음
    if (!state.isLoggedIn) return false;
    // role이 user가 아닌경우 (회원가입이 완료되지 않은 경우)
    if (state.user?.role !== 'user') return false;

    // 사용자의 위치상태가 핀이지만 핀 정보가 없을 경우
    if (state.user?.location_type === USER_LOCATION_TYPE.PIN) {
      console.debug('[AuthContext][needPinSetting] location_type이 pin인 사용자 입니다.', state.user?.location_type);
      // 지정된 핀이 없을 경우 핀세팅이 필요
      if (!state.user?.pin?._id) return true;
    }

    return false;
  }, [state]);

  // 최초
  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      console.log('[1KMWINE] auth.onAuthStateChanged: ', firebaseUser);

      const [_, firstPath] = window.location.pathname.split('/');
      if (firebaseUser) {
        amplitude.getInstance().setUserId(firebaseUser.uid);
        console.log('[onAuthStateChanged] 사용자 정보 조회 시작.', firebaseUser);

        const idTokenResult = await firebaseUser.getIdTokenResult();
        const { claims } = idTokenResult;

        // 사용자 확인
        if (claims.role === 'user') {
          const user = await getMemberFromFirestore(firebaseUser.uid);

          if (user === null) {
            console.warn("firebase auth에는 존재하지만 firestore에는 존재하지 않는 사용자입니다. '로그아웃' 처리합니다.");
            await logout();
            console.warn('정보가 존재하지 않는 사용자를 로그아웃 처리했습니다');
            return;
          }

          // 만료된 토큰
          if (firebaseUser.stsTokenManager && firebaseUser.stsTokenManager.isExpired) {
            console.info('토큰이 만료되어 갱신합니다.');
            firebaseUser.getIdToken(true).finally(() => {
              console.debug('[1KMWINE] onAuthStateChanted.getIdToken. 만료된 토큰갱신완료');
            });
          }

          dispatch({ type: LOGIN, payload: { isLoggedIn: true, user } });
          globalDispatch(fetchCartItems()); // 로그인 사용자의 장바구니 정보 조회

          updateLastConnectionTime().then((result) => {
            if (result?.code === 0) console.debug('[1KMWINE] 마지막 접속시간을 갱신했습니다.[event=onAuthStateChanged]');
          });
        }
        // 사용자 권한의 사용자가 아닐경우
        else {
          console.warn(`[1KMWINE] 사용자의 권한이 'user'가 아닙니다.`, claims.role);

          // console.log('-------- window.location: ', window.location);
          console.log('-------- pathname: ', window.location.pathname);
          console.log('-------- firstPath:', firstPath);
          // 최초 로그인 회원의 회원가입과 관련된 화면일 경우
          if (claims.role === undefined && (firstPath === 'auth' || firstPath === 'cert')) {
            console.debug('[AuthContext] 회원가입과 관련된 화면입니다. firebase auth를 그대로 사용합니다.');
          } else {
            logout().then(() => {
              console.warn(`[1KMWINE] 'user' 권한을 가지지 않은 사용자를 로그아웃 처리했습니다.`);
            });
          }
        }
      } else {
        console.log('#------ firebase user not exists');
        // 회원가입 완료화면
        if (window.location.pathname === '/auth/sign-up/complete') {
          dispatch({ type: LOGOUT });
        } else {
          logout().then(() => {
            console.debug('사용자 정보가 존재하지 않아 로그아웃 처리되었습니다');
          });
        }
      }
    });

    return () => {
      unsubscribe(); // detaching the listener
    };
  }, []);

  /**
   * 로그인
   *  - `firebase authentication`을 사용한 이메일/비밀번호 로그인
   *  - https://firebase.google.com/docs/auth/web/password-auth#sign_in_a_user_with_an_email_address_and_password
   *
   * @param email 사용자 아이디 (이메일)
   * @param password 비밀번호
   * @param callback
   * @returns {Promise<void>}
   */
  const signin = async (email, password, callback) => {
    // todo 로그인 코드 정리
    await setPersistence(auth, browserLocalPersistence);
    const userCredential = await signInWithEmailAndPassword(auth, email, password).catch((error) => {
      console.warn('[1KMWINE] 로그인 실패', error);
      callback(error, null);
      return false;
    });

    // 이메일 로그인 실패
    if (!userCredential) return false;

    // console.debug(`로그인 성공 후, auth reducer은 'auth.onAuthStateChanged'에서 처리합니다.`);

    console.debug('[email signin] 이메일 아이디 로그인 성공 사용자 후속');
    getMemberFromFirestore(userCredential.user.uid).then((result) => {
      console.log('[email signin] 로그인 후 사용자 정보 조회 완료.', result);
    });

    // Amplitude User ID
    amplitude.getInstance().setUserId(userCredential.user.uid);
    // Amplitude Login (app, success)
    amplitude.getInstance().logEvent('Login', {
      'auth provider': 'app',
      success: true,
      err: 'none',
      uid: userCredential?.user?.uid ?? 'unknown'
    });

    const isApplication = userAgent.includes(USER_AGENT.PREFIX);

    if (isApplication) {
      // 안드로이드에서 접근
      if (userAgent.includes(USER_AGENT.AOS)) {
        try {
          window.kmwine.getToken(); // -- fcm-token-changed에서 수신
        } catch (e) {
          console.warn('안드로이드의 fcm token정보 조회 중 오류가 발생했습니다.', e);
        }
      }
      // iOS에서 접근
      else if (userAgent.includes(USER_AGENT.IOS)) {
        try {
          window.webkit.messageHandlers.kmwine.postMessage('getToken'); // -- fcm-token-changed에서 수신
        } catch (e) {
          console.warn('iOS fcm token정보 조회 중 오류가 발생했습니다.', e);
        }
      }
    } else {
      console.warn('todo 앱접근이 아닐 경우 앱다운로드 경로로 이동시킬 것');
    }
  }; // end of signin

  /** 카카오 로그인 */
  const kakaoLogin = async (code, redirectUri) => {
    console.debug('카카오 로그인 인가코드: ', code);
    if (code == null || typeof code !== 'string') {
      // throw new Error('Kakao auth token cannot be null or empty.', { cause: 'empty_access_token' });
      return { success: false, message: '인가코드가 없습니다' };
    }

    console.info(`1KMWINE 로그인 요청. provider=kakao, [인가코드=${code}]`);
    const snsUserLoginResult = await requestKakaoSignIn(code, redirectUri);
    const { success, custom_token: customToken } = snsUserLoginResult;

    // 1kmwine 백엔드에서 카카오 사용자 조회여부 확인
    if (success) {
      // customToken이 있음 == 카카오 회원 -> 로그인처리
      if (customToken) {
        await setPersistence(auth, browserLocalPersistence);

        let userCredential;
        try {
          userCredential = await signInWithCustomToken(auth, customToken);
        } catch (error) {
          console.error('카카오 커스텀 로그인 처리 실패', error);
          httpsCallable(
            getFirebaseFunctions(),
            'call-cdm-clo-error'
          )({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `카카오 커스텀 로그인 처리실패 [${error.name ?? 'Unexpected'}]`,
            msg: error.message ?? 'Unexpected',
            which: `AuthContext.kakaoLogin`,
            param: { location: window.location.href }
          }).catch(console.error);
          return { error };
        }

        if (userCredential.error) {
          return { success: false, message: userCredential.error?.message ?? '알 수 없는 오류' };
        }

        // console.log('@ 카카오 로그인 성공처리.userCredential: ', userCredential);
        const { user: firebaseUser } = userCredential;

        // 사용자 정보 조회
        const user = await getMemberFromFirestore(firebaseUser.uid);
        dispatch({ type: LOGIN, payload: { isLoggedIn: user != null, user } });

        // Amplitude User ID
        amplitude.getInstance().setUserId(firebaseUser.uid);
        // Amplitude Login (kakao, success)
        amplitude.getInstance().logEvent('Login', { 'auth provider': 'kakao', success: true, err: 'none' });
      } else {
        // 카카오 로그인에 성공했으나 본인인증 및 회원정보 입력을 하지 않은 사용자
        console.log('최초 로그인 kakao 사용자 입니다.');
      }
    }

    // 1kmwine 백엔드에서 조회했으나 실패처리를 함
    return snsUserLoginResult;
  };

  // 애플 로그인[start]
  const appleLogin = async () => {
    console.info('[1KMWINE] 애플 로그인을 시도합니다.');
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.setCustomParameters({
      locale: 'ko_KR'
    });

    // !! signInWithRedirect 사용하면 안됨 - https://firebase.google.com/docs/auth/web/redirect-best-practices?hl=ko
    // const signinResult = await signInWithRedirect(auth, provider)
    // Apple Login
    const signinResult = await signInWithPopup(auth, provider)
      .then((userCredential) => {
        console.log('#userCredential: ', userCredential);
        const { uid } = userCredential.user;
        const { role } = jwt_decode(userCredential.user.accessToken);

        // claims에 role='user'인지 체크
        if (role === 'user') {
          amplitude.getInstance().setUserId(uid); // Amplitude User ID
          amplitude.getInstance().logEvent('Login', { 'auth provider': 'apple', success: true, err: 'none' }); // Amplitude Login (apple, success)
        }

        return { success: true, role, ...userCredential };
      })
      .catch((error) => {
        const { code = null, customData = null, name = null } = error;

        // Amplitude Login (apple, fail)
        amplitude.getInstance().logEvent('Login', {
          'auth provider': 'apple',
          success: false,
          err: code
        });

        // 사용자가 로그인을 취소함
        if (code === 'auth/popup-closed-by-user' || code === 'auth/user-cancelled') {
          console.debug('사용자가 애플 로그인 팝업을 닫았습니다');
        }
        // 로그인 오류
        else {
          console.groupCollapsed('[1KMWINE] 애플 로그인 실패');
          console.warn('code: ', code);
          console.warn('name: ', name);
          console.warn('customData: ', customData);
          console.groupEnd();
        }
        return { success: false, error };
      })
      .finally(() => {
        // globalDispatch(closeBackdrop());
      });

    console.debug('[1KMWINE] 애플 로그인결과: ', signinResult);

    return signinResult;
  };
  // 애플 로그인[end]

  /**
   * 회원가입
   * @returns {Promise<void>}
   */
  const register: Promise<void> = async (user, terms) => {
    console.group('[1KMWINE] 회원가입 요청');
    console.log('user: ', user);
    console.log('terms: ', terms);
    console.groupEnd();

    const result = await signUp({ user, terms });

    console.log('###', result.data.data);
    return result.data.data;
  }; // end of register

  /**
   * 로그아웃
   */
  const logout = async (callback) => {
    globalDispatch(openBackdrop({ open: true, text: '안전하게 로그아웃 중 입니다.' }));
    // FCM token 제거
    try {
      await removeFcmToken();
    } catch (e) {
      console.error('[logout] FCM 토큰 초기화 중 오류가 발생했습니다.', e);
    }

    if (!state.isInitialized || !state.isLoggedIn) {
      console.warn('[logout] 이미 로그아웃 되어있는 사용자 입니다.');
    }
    // 사용자 정보 초기화
    else {
      globalDispatch(resetUserLocation()); // 사용자의 위치(핀)정보 초기화
      globalDispatch(resetUserCart()); // 사용자의 장바구니 정보 초기화
    }
    // 메인 이벤트 팝업 localStorage 삭제
    localStorage.removeItem('main-bottom-popup-expire-date');

    const auth = getAuth();
    const logoutResult = await auth.signOut();

    dispatch({ type: LOGOUT });

    // todo Application Badge 초기화
    // kmwine.clearBadge()
    // console.log('##################################################### logout');

    if (typeof callback === 'function') {
      callback();
    } else {
      const [_, firstPath] = location.pathname.split('/');
      if (firstPath !== 'auth') {
        const { pathname, search, hash } = location;
        const redirectPath = pathname + search + hash;

        // sign-in page URL
        let initialPageURL = '/auth/sign-in';
        if (redirectPath !== '/') {
          initialPageURL += `?from=${encodeURIComponent(redirectPath)}`;
        }
        navigate(`${initialPageURL}`, { replace: true });
      }
    }
    globalDispatch(closeBackdrop());
    return logoutResult;
  };

  /**
   * 로그인 사용자정보 갱신
   */
  const refreshUserInfo = async () => {
    console.log('[AuthContext] 로그인사용자 정보 갱신 시작');
    const { currentUser } = auth; // 현재 로그인 사용자 firebase authentication

    // firestore에서 로그인 사용자정보(member) 조회
    const member = await getMemberFromFirestore(currentUser.uid).catch((e) => {
      console.warn('[1KMWINE] 로그인사용자 정보조회 중 오류 [E174840].', e);
      logout();
      return false;
    });

    dispatch({ type: REFRESH, payload: { user: member } });

    return member;
  };

  /**
   * TODO 사용자 프로필 업데이트
   */
  const updateProfile = () => {
    console.log('@update customer profile.');
  };

  // 로그인 사용자 정보조회
  const getMemberFromFirestore = async (uid) => {
    if (validator.isEmpty(uid)) {
      console.warn(`'uid' not exists.`);
      return null;
    }

    // api로 가지고오기
    // const member = await getUserDetail(uid).catch((error) => console.error(error));

    // console.time(`Get member from firestore.[uid=${uid}]`);
    // firestore에서 로그인 사용자정보(member) 조회
    const memberSnapshot = await getDoc(doc(getFirestore(), 'member', uid)).catch((e) => {
      console.warn('[1KMWINE] 로그인 사용자 정보조회 중 오류 [E859093].', `uid='${uid}'`, e);
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: 859093,
        title: `로그인 사용자 정보조회 실패 [${e.name ?? 'Unexpected'}]`,
        msg: e.message ?? 'Unexpected',
        which: `getMemberFromFirestore`,
        param: { location: window?.location?.href ?? 'Unknown' }
      }).catch(console.error);
      return false;
    });

    if (!memberSnapshot || !memberSnapshot.exists()) {
      console.log(`[AuthContext] member not exists. [uid=${uid}]`);
      return null;
    }

    const member = memberSnapshot.data();

    // Amplitude User ID
    amplitude.getInstance().setUserId(uid);

    try {
      const amplitudeUserProps = {
        nickname: member.nickname,
        gender: member.gender,
        birth: {
          year: Number(member.birth.year),
          month: Number(member.birth.month),
          date: Number(member.birth.date)
        },
        location: member?.pin?.name ?? 'undefined'
      };
      amplitude.getInstance().setUserProperties(amplitudeUserProps);

      if (member.location_type === 'pin') {
        if (member.pin?._id) {
          amplitude.getInstance().setGroup('pin', member.pin._id);
          const identify = new amplitude.Identify().set('location', member.pin.name);
          amplitude.getInstance().identify(identify);
        }
      } else {
        amplitude.getInstance().setGroup('pin', []);
      }
    } catch (e) {
      console.warn('Amplitude 사용자 속성 세팅 오류.', e);
    }

    // Update Channel talk customer
    // console.log('---- member: ', member);
    if (IS_PRODUCTION) {
      try {
        const name = `${IS_PRODUCTION ? '' : '[개발기] '}${member.nickname}`;
        // reference: https://developers.channel.io/docs/web-user-object
        // console.log('################################################## member:', member);

        // 마케팅 메시지 수신여부
        const pushAgree = member?.terms?.push ?? false;
        // console.log('############# marketingAgree: ', pushAgree);

        const userProps = {
          memberId: member._id,
          name,
          unsubscribeEmail: !pushAgree, // 이메일 수신거부
          unsubscribeTexting: !pushAgree, // 문자 수신거부
          profile: {
            name,
            nickname: member.nickname,
            realname: member.uname,
            mobileNumber: member.mobile,
            email: member.email,
            birth: member.birth.full,
            gender: member.gender, // 성별, F=여, M=남
            userWebVersion: process.env.REACT_APP_VERSION // User Web Version
          }
        };

        if (member.email) {
          userProps.email = member.email;
          userProps.profile.email = member.email;
        }

        // if (member.profile_img?.thumb?.url) {
        //   console.log('------------- member.profile_img?.thumb?.url: ', member.profile_img?.thumb?.url);
        //   userProps.avatarUrl = member.profile_img.thumb.url;
        // }
        ChannelService.updateUser(userProps);
      } catch (e) {
        console.log('채널톡 사용자 정보 업데이트 실패', e);
        const sendCloWarn = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-warn');
        sendCloWarn({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `채널톡 사용자 정보 업데이트 실패`,
          msg: `${e.message}`,
          which: window.location.pathname,
          param: member
        })
          .then(console.log)
          .catch(console.error);
      }
    } else {
      console.warn('개발기에서는 채널톡 사용자 정보를 업데이트 하지 않습니다');
    }
    /*
    // 차단 사용자정보 조회[START]
    const blockUsersSnapshot = await getDocs(collection(memberSnapshot.ref, 'block_user'));
    const blockUsers = [];
    if (!blockUsersSnapshot.empty) {
      blockUsersSnapshot.docs.forEach((blockUserDoc) => {
        const { uid } = blockUserDoc.data();
        blockUsers.push(uid);
      });
    }
    member.blockUsers = blockUsers;
    // 차단 사용자정보 조회[END]
*/

    const {
      location_type, // 위치타입 ('현재위치', '핀')
      pin = null // 핀정보
    } = member;

    // console.log('######## 사용자 위치정보 확인: ', member);

    // 핀일경우
    if (location_type === USER_LOCATION_TYPE.PIN) {
      // 핀 정보가 없을 경우
      if (pin === null) {
        console.warn('[1KMWINE] 사용자의 핀 정보가 없습니다');
        globalDispatch(setUserLocation(USER_LOCATION_TYPE.PIN, null));
      }
      // 핀 변경사항이 없음
      else if (userLocation.currentPin?.pin_id === pin._id) {
        // console.debug('핀 변경사항이 없습니다.');
      } else {
        console.log('[1KMWINE] 핀정보 변경이 감지되어 수정합니다.', pin);
        globalDispatch(setUserLocation(location_type, pin));
      }
    }
    // 현재위치를 사용하는 사용자
    else if (location_type === USER_LOCATION_TYPE.CURRENT) {
      // 현재 위치 사용자
      if (userLocation.saved?.lastCurrentCoord?.lat && userLocation.saved?.lastCurrentCoord?.lng) {
        const { lat: latitude, lng: longitude } = userLocation.saved.lastCurrentCoord;
        globalDispatch(setUserLocation(USER_LOCATION_TYPE.CURRENT, { latitude, longitude }));
      } else {
        const { geolocation } = wineOne;
        if (geolocation.initialized) {
          console.warn('마지막 위치가 저장되어있지 않습니다. 현재 위치로 갱신합니다.', geolocation);

          globalDispatch(setUserLocation(USER_LOCATION_TYPE.CURRENT, { latitude: geolocation.latitude, longitude: geolocation.longitude }));
        } else {
          console.warn('마지막 위치가 저장되어있지 않으나 아직 현재 위치를 확인할 수 없습니다...', geolocation);
          setTimeout(() => {
            const geolocation = geolocationRef.current;
            globalDispatch(
              setUserLocation(USER_LOCATION_TYPE.CURRENT, { latitude: geolocation.latitude, longitude: geolocation.longitude })
            );
          }, 5000);
        }
      }
    }
    // 위치정보가 지정되지 않은 사용자
    else {
      console.warn('[1KMWINE] 위치정보가 없는 사용자 입니다.');
      globalDispatch(setUserLocation(null));
    }

    // App에서 실행된 경우
    const isApplication = userAgent.includes(USER_AGENT.PREFIX);

    // 사용자에게 fcm token이 없을 경우
    if (isApplication) {
      if (!member.fcmToken) {
        // 안드로이드에서 접근
        if (userAgent.includes(USER_AGENT.AOS)) {
          try {
            window.kmwine.getToken(); // -- fcm-token-changed에서 수신
          } catch (e) {
            console.warn('안드로이드의 fcm token정보 조회 중 오류가 발생했습니다.', e);
          }
        }
        // iOS에서 접근
        else if (userAgent.includes(USER_AGENT.IOS)) {
          try {
            window.webkit.messageHandlers.kmwine.postMessage('getToken'); // -- fcm-token-changed에서 수신
          } catch (e) {
            console.warn('iOS fcm token정보 조회 중 오류가 발생했습니다.', e);
          }
        }
      }
    }

    return member;
  };

  React.useEffect(() => {
    if (state.isInitialized && state.isLoggedIn) onAuthChecked();
  }, [state.isInitialized]);

  // 사용자 정보 감지 로더
  if (state.isInitialized !== undefined && !state.isInitialized) {
    // 회원가입 화면 또는 기가입 안내 화면일 경우에는 그냥 넘어갑니다.
    if (location.pathname !== '/auth/sign-up' && location.pathname !== '/auth/sign-up/duplicated') {
      return (
        <Box height="calc(var(--vh, 1vh) * 100)" display="flex" justifyContent="center" alignItems="center">
          <Box textAlign="center">
            <WineBottleLoadingLottie />
            <Typography variant="caption" fontSize="12px">
              사용자 정보를 확인하는 중 입니다
            </Typography>
          </Box>
        </Box>
      );
    }
  }

  // render
  return (
    <AuthContext.Provider
      value={{
        ...state,
        needPinSetting,
        signin,
        appleLogin,
        kakaoLogin,
        logout,
        register,
        refresh: refreshUserInfo,
        updateProfile
      }}
    >
      {/* 서비스 사용가능여부 */}
      {isAvailable && children}

      {/* 서비스 점검 공지 Dialog */}
      <MaintenanceDialog open={!isAvailable} health={wineOne.health} />
    </AuthContext.Provider>
  );
};

WineOneAuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
