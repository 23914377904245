import axios from 'axios';
import { NEST_API_URL, FIREBASE_FUNCTION_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE, IS_PRODUCTION } from '../../config';

/**
 * 현재 공개 중인 메인배너 조회
 * @see https://api.1kmwine.kr/docs#/메인화면%20컨텐츠/MainContentController_getCurrentMainBannerList
 */
export async function getCurrentMainBannerList(): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__CURRENT_BANNER_LIST)
    .then((res) => res.data)
    .catch((error) => {
      console.error('[main-content.service.ts][getCurrentMainBannerList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `메인배너 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCurrentMainBannerList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

export async function getCardCurationDetail(curationCardId, coord): Promise<any> {
  if (!coord.lat || !coord.lng) throw new Error('Invalid coordinate');
  return axios
    .get(NEST_API_URL.GET__CURATION_CARD_DETAIL, {
      params: { id: curationCardId, c: `${coord.lat},${coord.lng}` }
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `카드 큐레이션 상세 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCardCurationDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 공개 중인 필터큐레이션 조회
 * @see https://api.1kmwine.kr/docs#/메인화면%20컨텐츠/MainContentController_getCurrentFilterCurationList
 */
export async function getCurrentFilterCurationList(): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__CURRENT_CURATION_FILTER_LIST, { params: { size: 12 }, timeout: 25 * 1000 })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `필터 큐레이션 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: error.message ?? 'Unexpected',
          which: `getCurrentFilterCurationList`,
          param: { location: window.location.href }
        }).catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 공개 중인 필터큐레이션 상세 조회
 * @see https://api.1kmwine.kr/docs#/메인화면%20컨텐츠/MainContentController_getCurrentFilterCurationDetail
 */
export async function getCurrentFilterCurationDetail(body): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__CURRENT_CURATION_FILTER_DETAIL, { params: { ...body } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `필터 큐레이션 상세 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCurrentFilterCurationDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

export async function getWineDiscoveryDetail(wineDiscoveryId, coordStr): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__WINE_DISCOVERY_DETAIL, { params: { id: wineDiscoveryId, c: coordStr } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `와인디스커버리 상세 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getWineDiscoveryDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 공개중인 메인 콘텐츠 조회
 */
export async function getMainContentsList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__LIST_CONTENTS_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('[main-content.service.ts][getMainContentsList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `메인 콘텐츠 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getMainContentsList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 공개중인 테마 목록 조회
 */
export async function getThemeList(): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__CURRENT_THEME_LIST)
    .then((res) => res.data)
    .catch((error) => {
      console.error('[main-content.service.js][getThemeList] 요청실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `테마 목록 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getThemeList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 현재 공개중인 테마 상세 데이터 조회
 */
export async function getThemeDetail(curationCardId, coord): Promise<any> {
  if (!coord.lat || !coord.lng) throw new Error('Invalid coordinate');
  return axios
    .get(NEST_API_URL.GET__CURRENT_THEME_DETAIL, {
      params: { id: curationCardId, c: `${coord.lat},${coord.lng}` }
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `테마 상세 데이터 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getThemeDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 실시간 주문 목록 조회
 */

export async function getRealTimeOrderList(body): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__REALTIME_ORDER_LIST, { params: { ...body } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `실시간 주문 목록 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getRealTimeOrderList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 목록 조회
 */
export async function getLiveTalkList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__LIVE_TALK_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 목록 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getLiveTalkList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 단일 조회
 */
export async function getLiveTalkDetail(questionId): Promise<any> {
  if (!questionId) throw new Error('Invalid questionId');
  return axios
    .get(NEST_API_URL.GET__LIVE_TALK_DETAIL, { params: { _id: questionId } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 단일 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getLiveTalkDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 생성
 */
export async function createLiveTalk(body): Promise<any> {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__LIVE_TALK_CREATE, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 단일 생성 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `createLiveTalk`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 답변 선택
 */
export async function createLiveTalkAnswer(body): Promise<any> {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__LIVE_TALK_ANSWER, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 답변 선택 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `createLiveTalkAnswer`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 삭제
 */
export async function deleteLiveTalk(body): Promise<any> {
  return axios
    .delete(FIREBASE_FUNCTION_URL.DELETE__LIVE_TALK_DELETE, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 삭제 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `deleteLiveTalk`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 술술 라이브 톡 신고
 */
export async function reportLiveTalk(body): Promise<any> {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__LIVE_TALK_REPORT, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `술술 라이브 톡 신고 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `reportLiveTalk`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 메인 팝업 목록 조회
 */
export async function getMainPopupList(): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__MAIN_POPUP_LIST)
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `메인 팝업 목록 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getMainPopupList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
