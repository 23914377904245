import React from 'react';
import { SvgIcon } from '@mui/material';

/* eslint-disable react/prop-types */
/**
 * 하트아이콘
 *
 * @param {number} height - px
 * @param {number} width - px
 * @param {boolean} filled - 색상 채워짐 여부
 * @param {string} color
 * @returns {SvgIcon}
 * @constructor
 */
export function HeartIcon({ height = 30, width = 30, filled = true, color = 'inherit', ...other }) {
  return (
    <SvgIcon {...other} htmlColor={color} viewBox="0 0 30 30" sx={{ width, height }}>
      {filled ? (
        <path
          d="M7.314-1.2 1.137-7.373a3.882 3.882 0 0 1 0-5.49 3.882 3.882 0 0 1 5.49 0L8-11.49l1.373-1.373a3.882 3.882 0 0 1 5.49 0 3.882 3.882 0 0 1 0 5.49L8.686-1.2a.971.971 0 0 1-1.372 0z"
          transform="translate(7 23)"
          style={{ fill: 'currentcolor', fillRule: 'evenodd' }}
        />
      ) : (
        <g style={{ fillRule: 'evenodd', opacity: 0.75, fill: 'none' }}>
          <path
            d="M7.314-1.2 1.137-7.373a3.882 3.882 0 0 1 0-5.49 3.882 3.882 0 0 1 5.49 0L8-11.49l1.373-1.373a3.882 3.882 0 0 1 5.49 0 3.882 3.882 0 0 1 0 5.49L8.686-1.2a.971.971 0 0 1-1.372 0z"
            style={{ stroke: 'none' }}
            transform="translate(7 23)"
          />
          <path
            d="m8-1.924 6.156-6.156A2.863 2.863 0 0 0 15-10.118c0-.77-.3-1.493-.844-2.038A2.863 2.863 0 0 0 12.118-13c-.77 0-1.494.3-2.038.844L8-10.076l-2.08-2.08A2.863 2.863 0 0 0 3.882-13c-.77 0-1.493.3-2.038.844A2.863 2.863 0 0 0 1-10.118c0 .77.3 1.494.844 2.038L8-1.924M8-.912a.968.968 0 0 1-.686-.284L1.137-7.373a3.882 3.882 0 1 1 5.49-5.49L8-11.49l1.373-1.373a3.882 3.882 0 0 1 5.49 5.49L8.686-1.196A.968.968 0 0 1 8-.912z"
            style={{ fill: '#140229', stroke: 'none' }}
            transform="translate(7 23)"
          />
        </g>
      )}
    </SvgIcon>
  );
}

/**
 * 별 아이콘
 *
 * @param {number} height - px
 * @param {number} width - px
 * @param {boolean} filled - 색상 채워짐 여부
 * @param {string} color
 * @returns {StarIcon}
 * @constructor
 */
export function StarIcon({ height = 13, width = 10, filled = true, color = '#fec466', ...other }) {
  return (
    <SvgIcon {...other} htmlColor={color} viewBox="0 0 20 25" sx={{ width, height }}>
      {filled ? (
        <>
          <path style={{ fill: 'none', opacity: 0.5 }} d="M0 0h20v25H0z" />
          <path
            d="M8.128 2.9 4.189 5.788a.837.837 0 0 1-1.157-.164.8.8 0 0 1-.12-.745L4.493.314.478-2.468A.8.8 0 0 1 .28-3.6a.832.832 0 0 1 .685-.34l4.916.064 1.457-4.6a.83.83 0 0 1 1.034-.534.819.819 0 0 1 .544.534l1.457 4.6 4.916-.064a.819.819 0 0 1 .837.8.806.806 0 0 1-.349.673L11.763.314l1.581 4.565a.807.807 0 0 1-.517 1.028.84.84 0 0 1-.76-.118L8.128 2.9z"
            transform="translate(1.872 14.051)"
            style={{ fill: 'currentcolor', fillRule: 'evenodd' }}
          />
        </>
      ) : (
        <>
          <path style={{ fill: 'none', opacity: 0.5 }} d="M0 0h20v25H0z" />
          <path
            d="M8.128 2.9 4.189 5.788a.837.837 0 0 1-1.157-.164.8.8 0 0 1-.12-.745L4.493.314.478-2.468A.8.8 0 0 1 .28-3.6a.832.832 0 0 1 .685-.34l4.916.064 1.457-4.6a.83.83 0 0 1 1.034-.534.819.819 0 0 1 .544.534l1.457 4.6 4.916-.064a.819.819 0 0 1 .837.8.806.806 0 0 1-.349.673L11.763.314l1.581 4.565a.807.807 0 0 1-.517 1.028.84.84 0 0 1-.76-.118L8.128 2.9z"
            transform="translate(1.872 14.051)"
            style={{ fill: '#ede7f4', fillRule: 'evenodd' }}
          />
        </>
      )}
    </SvgIcon>
  );
}

/**
 * 채워진 별 아이콘
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * 채워진 별 아이콘
 * @param {'small' | 'medium'} size - Icon size
 * @param {'yellow' | 'red'  } color - Icon color
 * @returns {JSX.Element}
 * @constructor
 */
export const RatingStarFill = ({ size = 'medium', color = 'yellow' }) => {
  let htmlColor = '#efbd6e';
  switch (color) {
    case 'yellow':
      htmlColor = '#efbd6e';
      break;
    case 'red':
      htmlColor = '#b92240';
      break;
    default:
      console.warn(`[RatingStarFill] Undefined color string = '${color}`);
  }

  let width, height;
  switch (size) {
    case 'small':
      width = 10;
      height = 13;
      break;
    case 'medium':
      width = 20;
      height = 25;
      break;
    default:
      console.warn(`[RatingStarFill] Undefined size string = '${size}`);
  }

  // render
  return (
    <SvgIcon htmlColor={htmlColor} viewBox="0 0 10 13" sx={{ width, height }}>
      <path
        d="M4.128-2.955 2.159-1.484a.413.413 0 0 1-.578-.084.413.413 0 0 1-.06-.38l.79-2.328L.3-5.694a.413.413 0 0 1-.1-.576.413.413 0 0 1 .343-.175L3-6.412l.733-2.348a.413.413 0 0 1 .517-.272.413.413 0 0 1 .272.272l.729 2.348 2.458-.032a.413.413 0 0 1 .419.408.413.413 0 0 1-.175.343L5.946-4.276l.79 2.328a.413.413 0 0 1-.258.524.413.413 0 0 1-.38-.06l-1.97-1.471z"
        transform="translate(.872 11.051)"
        style={{ fill: 'currentcolor', fillRule: 'evenodd' }}
      />
    </SvgIcon>
  );
};

/**
 * 비워진 별 아이콘
 * @param {'small' | 'medium'} size - Icon size
 * @returns {JSX.Element}
 * @constructor
 */
export const RatingStarLine = ({ size = 'medium' }) => {
  let width, height;
  switch (size) {
    case 'small':
      width = 10;
      height = 13;
      break;
    case 'medium':
      width = 20;
      height = 25;
      break;
    default:
      console.warn(`[RatingStarLine] Undefined size string = '${size}`);
  }

  // render
  return (
    <SvgIcon htmlColor="#e9e7ed" viewBox="0 0 10 13" sx={{ width, height }}>
      <path
        d="M4.128-2.955 2.159-1.484a.413.413 0 0 1-.578-.084.413.413 0 0 1-.06-.38l.79-2.328L.3-5.694a.413.413 0 0 1-.1-.576.413.413 0 0 1 .343-.175L3-6.412l.733-2.348a.413.413 0 0 1 .517-.272.413.413 0 0 1 .272.272l.729 2.348 2.458-.032a.413.413 0 0 1 .419.408.413.413 0 0 1-.175.343L5.946-4.276l.79 2.328a.413.413 0 0 1-.258.524.413.413 0 0 1-.38-.06l-1.97-1.471z"
        transform="translate(.872 11.051)"
        style={{ fill: '#e9e7ed', fillRule: 'evenodd' }}
      />
    </SvgIcon>
  );
};

/**
 * 채워진 별 아이콘 큰 사이즈
 * @constructor
 *
 * @authros 이재일<leeji@wineone.io>
 */
export const RatingStarLargeFill = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
      <path
        d="M14.128,11.67l-6.893,5a1.475,1.475,0,0,1-2.024-.285A1.372,1.372,0,0,1,5,15.093L7.766,7.181h0L.74,2.359A1.379,1.379,0,0,1,.393.4a1.46,1.46,0,0,1,1.2-.594l8.6.11h0l2.55-7.98a1.455,1.455,0,0,1,1.81-.925,1.426,1.426,0,0,1,.953.925l2.55,7.98h0l8.6-.11a1.427,1.427,0,0,1,1.465,1.386,1.392,1.392,0,0,1-.612,1.166L20.49,7.181h0l2.766,7.912a1.4,1.4,0,0,1-.9,1.782,1.481,1.481,0,0,1-1.331-.2l-6.893-5Z"
        transform="translate(-0.128 9.051)"
        style={{ fill: '#fec466', fillRule: 'evenodd' }}
      />
    </SvgIcon>
  );
};

/**
 * 비워진 별 아이콘 큰 사이즈
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
export const RatingStarLargeLine = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
      <path
        d="M14.128,11.67l-6.893,5a1.475,1.475,0,0,1-2.024-.285A1.372,1.372,0,0,1,5,15.093L7.766,7.181h0L.74,2.359A1.379,1.379,0,0,1,.393.4a1.46,1.46,0,0,1,1.2-.594l8.6.11h0l2.55-7.98a1.455,1.455,0,0,1,1.81-.925,1.426,1.426,0,0,1,.953.925l2.55,7.98h0l8.6-.11a1.427,1.427,0,0,1,1.465,1.386,1.392,1.392,0,0,1-.612,1.166L20.49,7.181h0l2.766,7.912a1.4,1.4,0,0,1-.9,1.782,1.481,1.481,0,0,1-1.331-.2l-6.893-5Z"
        transform="translate(-0.128 9.051)"
        style={{ fill: '#ede7f4', fillRule: 'evenodd' }}
      />
    </SvgIcon>
  );
};

/**
 * 공유하기 아이콘
 * @returns {JSX.Element}
 * @constructor
 */
export const ShareIcon = ({ width = 34, height = 34 }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox="0 0 34 34">
      <g transform="translate(-321 -58)">
        <g transform="translate(321 58)">
          <rect style={{ width, height, fill: 'none', opacity: 0.5 }} />
        </g>
        <g transform="translate(320.132 58.151)">
          <path
            d="M32.17,21.428a.721.721,0,0,0,1.439,0V9.151l3.513,3.3a.752.752,0,0,0,1.018,0,.649.649,0,0,0,0-.957l-4.732-4.45a.741.741,0,0,0-.5-.2h-.048a.743.743,0,0,0-.536.2L27.59,11.5a.649.649,0,0,0,0,.957.752.752,0,0,0,1.018,0L32.17,9.1Z"
            transform="translate(-15.11)"
            style={{ fill: '#140229' }}
          />
          <path
            d="M9.268,54.843a.7.7,0,0,0-1.4,0v5.185A2.984,2.984,0,0,0,10.9,62.961H24.5a2.984,2.984,0,0,0,3.033-2.933V54.843a.7.7,0,0,0-1.4,0v5.185A1.607,1.607,0,0,1,24.5,61.607H10.9a1.607,1.607,0,0,1-1.633-1.579Z"
            transform="translate(0 -35.974)"
            style={{ fill: '#140229' }}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
