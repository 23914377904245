import React from 'react';
import Highlighter from 'react-highlight-words';

import PropTypes from 'prop-types';

// project imports
import { useLocalStorage } from 'hooks';
import { SearchHistoryDeleteIcon, SearchHistoryIcon, SmallButton, SpaceBetweenBox } from 'components';
import { SearchField } from 'components/search';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';

// assets
import ArrowBackImg from 'assets/images/arrow-back.png';
import { alpha, styled } from '@mui/material/styles';

/**
 * GNB[와인샵] > 검색 다이어로그
 * @param {boolean} open - 다이어로그 오픈 여부
 * @param {function} onClose - close 콜백
 * @param {string} value - 검색어
 * @returns {JSX.Element}
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function SearchDialog({ open, onClose = () => false, onSearch = () => false, value: valueProp, queryHistory: queryHistoryProp = [] }) {
  // 검색어
  const [value, setValue] = React.useState(valueProp);

  // 검색어 Property hook
  React.useEffect(() => {
    if (open) setValue(() => valueProp);
  }, [valueProp, open]);

  // 최근 검색어
  const [queryHistory, setQueryHistory] = useLocalStorage('vendor-query-history', [...queryHistoryProp]);

  React.useEffect(() => {
    setQueryHistory([...queryHistoryProp]);
  }, [queryHistoryProp]);

  // n번째 최근 검색이력 삭제
  const removeQueryHistory = React.useCallback(
    (index) => {
      const _queryHistory = [...queryHistory];
      _queryHistory.splice(index, 1);
      setQueryHistory(_queryHistory);
    },
    [queryHistory]
  );

  // 상품 검색
  const searchVendor = React.useCallback(
    (query) => {
      onSearch(query);
    },
    [value]
  );

  // 검색 초기화
  function resetSearch() {
    setValue('');
    onSearch('');
  }

  // render
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar position="relative" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={onClose} aria-label="close" sx={{ width: 46 }}>
            <Box component="img" src={ArrowBackImg} sx={{ height: 34 }} alt="" />
          </IconButton>
          <FormStyledTextField
            onSubmit={(e) => {
              e.preventDefault();
              searchVendor(value);
              return false;
            }}
          >
            <Stack direction="row" spacing="8px" width="100%" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SearchField
                autoFocus
                value={value}
                onInput={(e) => {
                  setValue(e.target.value);
                }}
                placeholder="주소/매장명으로 검색"
              />
              <Button
                type="button"
                size="medium"
                fontSize="14px"
                tabIndex="-1"
                sx={{
                  justifyContent: 'flex-end',
                  minWidth: 'auto',
                  width: '58px',
                  pl: '8px',
                  pr: '20px',
                  backgroundColor: '#ffffff',
                  color: alpha('#140229', 0.7)
                }}
                onClick={() => {
                  resetSearch();
                }}
              >
                취소
              </Button>
            </Stack>
          </FormStyledTextField>
        </Toolbar>
      </AppBar>

      {/* 검색어 입력 안한경우 */}
      {value.trim().length === 0 ? (
        <Container>
          <SpaceBetweenBox pt={1}>
            <Typography variant="caption" fontWeight={800} fontSize={11}>
              최근 검색어
            </Typography>
            <SmallButton
              onClick={() => {
                setQueryHistory(() => []);
              }}
              disabled={queryHistory.length === 0}
            >
              모두 지우기
            </SmallButton>
          </SpaceBetweenBox>

          {/* 최근 검색어 목록 표현[start] */}
          <Box>
            <List>
              {/* 최근 검색어가 없을 경우 */}
              {queryHistory.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography fontSize="18px" fontWeight={300} sx={{ opacity: 0.7, py: 2 }}>
                    최근 검색기록이 없습니다.
                  </Typography>
                </Box>
              )}

              {/* 최근 검색어 목록 표현 */}
              {queryHistory.map((oldQuery, i) => (
                <ListItem key={`old-query-${i}`} sx={{ p: 0 }}>
                  <SpaceBetweenBox width="100%">
                    <ListItemButton
                      onClick={() => {
                        searchVendor(oldQuery);
                      }}
                      sx={{ p: 0 }}
                    >
                      <Box display="flex" alignItems="center">
                        <SearchHistoryIcon />
                        <Typography component="span" fontSize="14px" sx={{ ml: '5px' }}>
                          {oldQuery}
                        </Typography>
                      </Box>
                    </ListItemButton>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        removeQueryHistory(i);
                      }}
                    >
                      <SearchHistoryDeleteIcon />
                    </IconButton>
                  </SpaceBetweenBox>
                </ListItem>
              ))}
            </List>
          </Box>
          {/* 최근 검색어 목록 표현[end] */}
        </Container>
      ) : (
        <List>
          {/* 현재 검색어 표현 */}
          <ListItemButton
            onClick={() => {
              console.log('---- 와인 검색 - value: ', value);
              searchVendor(value);
            }}
            sx={{ px: '20px' }}
          >
            <ListItem sx={{ pl: 0, py: 0, fontSize: '16px', fontWeight: 400 }}>
              <HighlightedText mark={value} text={value} />
            </ListItem>
          </ListItemButton>
        </List>
      )}
    </Dialog>
  );
}

SearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  queryHistory: PropTypes.arrayOf(PropTypes.string)
};
export default SearchDialog;

const FormStyledTextField = styled('form')(
  ({ theme }) => `
  flex-grow: 1;
  margin-right: -20px;
  margin-left: 8px;
  height: 42px;
  & .MuiFormControl-root {
    background-color: ${theme.palette.grey[50]};
    border-radius: 4px;
    padding: 0;
  }
  `
);

// eslint-disable-next-line react/prop-types
const HighlightedText = React.memo(({ mark, text }) => {
  return (
    <Highlighter
      highlightTag="strong"
      highlightStyle={{ fontWeight: 800 }}
      searchWords={`${mark}`.trim().split(' ')}
      autoEscape={true}
      textToHighlight={text}
    />
  );
});
