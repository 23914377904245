import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { RedBlockIcon, RedReportIcon } from 'components';

// material-ui
import { Box, Divider, Drawer, List, ListItem, ListItemButton, Typography } from '@mui/material';

/**
 * 리뷰 신고/차단 하기 drawer
 * @returns {number}
 * @constructor
 *
 * @authors
 */
const ReviewReportDrawer = ({ open, onClose, action }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
          p: '12px 0'
        }
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <Box sx={{ width: 32, height: 6, borderRadius: '3px', bgcolor: '#8D81A0' }} />
      </Box>

      <List sx={{ px: '20px' }}>
        <ListItem disablePadding>
          <ListItemButton sx={{ p: '19px 0' }} onClick={() => action('report')}>
            <RedReportIcon />
            <Typography sx={{ ml: 1, fontSize: 17, color: '#EA6C6C', fontWeight: 400, lineHeight: 'normal' }}>신고하기</Typography>
          </ListItemButton>
        </ListItem>
        <Divider component="li" />
        <ListItem disablePadding>
          <ListItemButton sx={{ p: '19px 0' }} onClick={() => action('block')}>
            <RedBlockIcon />
            <Typography sx={{ ml: 1, fontSize: 17, color: '#EA6C6C', fontWeight: 400, lineHeight: 'normal' }}>차단하기</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default ReviewReportDrawer;

ReviewReportDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};
