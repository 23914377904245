import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Button, Typography } from '@mui/material';
import { useSelector } from 'store';
import { USER_LOCATION_TYPE } from 'config';
import { KeyboardDownIcon, PeriscopeSmallIcon } from 'components/icons';
import { makeStyles } from '@mui/styles';

/**
 * 사용자 위치(userLocation) 선택버튼
 *  - '오더' > '인덱스'
 * @returns {JSX.Element}
 * @constructor
 */
function UserLocationButton({ onClick }) {
  const classes = useStyles();
  // 사용자 위치정보
  const userLocation = useSelector((state) => state.userLocation);

  const label = useMemo(() => {
    let label = '위치 확인 중';
    if (!userLocation.loading) {
      const { type } = userLocation;
      if (type === USER_LOCATION_TYPE.PIN) {
        label = userLocation.currentPin.name;
      } else if (type === USER_LOCATION_TYPE.CURRENT) {
        // label = userLocation.currentLocation.address;
        label = '현재 위치';
      }
    }
    return label;
  }, [userLocation]);

  return !userLocation.loading ? (
    <Button
      className={`${classes.root} ${classes.light}`}
      startIcon={<PeriscopeSmallIcon />}
      endIcon={<KeyboardDownIcon />}
      onClick={onClick}
      fullWidth
    >
      <UserLocationPinAlias maxWidth="25vw">{label}</UserLocationPinAlias>
    </Button>
  ) : null;
}

UserLocationButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default UserLocationButton;

// 사용자 현재 위치 핀 별칭
/* eslint-disable react/prop-types */
const UserLocationPinAlias = ({ children }) => (
  <Typography
    variant="subtitle2"
    component="div"
    textAlign="left"
    lineHeight={1}
    fontSize="15px"
    fontWeight="bold"
    noWrap
    sx={{ mixBlendMode: 'difference', width: 'auto', display: 'inline-block' }}
  >
    {children}
  </Typography>
);

const useStyles = makeStyles({
  root: {
    height: '34px',
    marginLeft: '-14px',
    padding: '6px 12px 6px 16px',
    // borderRadius: 20,
    overflow: 'hidden',
    width: 'auto',
    backdropFilter: 'blur(15px)',
    color: 'text.primary',
    backgroundColor: 'rgba(255,255,255,0.15)',
    '& .MuiButton-startIcon': {
      marginRight: 0
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0
    }
  }
});
