import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { Calculator } from 'utils/bourgogne-tools';
import { CoBuyChip, ProductImage } from 'components/v2';
import { ProductCurrency, ProductName, ProductPrice } from 'components/product';

// material-ui
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const IMG_WIDTH = 107; // product thumbnail img width
const IMG_HEIGHT = 127; // product thumbnail img height

/**
 * 주문취소 > 주문상품 카드
 * @returns {JSX.Element}
 * @constructor
 */
function OrderCard({ order, orderProduct }) {
  console.log('주문취소 > 주문 상품: ', orderProduct);
  const { capacity, name, pdata, price, quantity } = orderProduct;

  // 공동구매 여부
  const isCoBuyingOrder = React.useMemo(() => {
    if (!order.loaded) return false;
    const { cobuy_state = 'NONE' } = order;
    return cobuy_state !== 'NONE';
  }, [order.loaded]);

  // render
  return (
    <Card component="section" className="no-border-radius order-card" elevation={0}>
      <Box display="flex" flexDirection="row" mt={1} height={IMG_HEIGHT}>
        {/* 상품 이미지 */}
        <ProductImage width={IMG_WIDTH} height="100%" src={pdata.bottle_img?.thumb} alt="상품 이미지" />

        <Box flexGrow={1} p="7px" pl="10px" display="flex" flexDirection="column" justifyContent="space-between">
          {/* 상품명 (한글) */}
          <ProductName component="div">
            {isCoBuyingOrder && <CoBuyChip sx={{ mr: '4px' }} />}
            {name.ko} {order.product_categories[0] !== 'ticket' && Calculator.formatCapacity(capacity)}
          </ProductName>

          {/* 주문 수량/금액 */}
          <Box>
            <OptString component="div" variant="body2" color="text.secondary" sx={{ mt: '5px' }}>
              수량 : {quantity.toLocaleString()}개
            </OptString>

            <Box mt="5px" display="flex" alignItems="center">
              <ProductPrice>{((price.original >> 0) * quantity).toLocaleString()}</ProductPrice>
              <ProductCurrency multiple={false} pl="1px" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  orderProduct: PropTypes.object.isRequired
};

export default OrderCard;

const OptString = styled(Typography)(({ theme }) => ({
  opacity: 0.5,
  fontSize: 11,
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.11px',
  color: theme.palette.text.primary
}));
