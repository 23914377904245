import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { Calculator, Strings } from 'utils/bourgogne-tools';
import { OrderAddIcon, OrderCheckedIcon, WishButton } from 'components';
import QuantityBox from './QuantityBox';
import ProductImage from './ProductImage';

// material-ui
import { Box, ButtonBase, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

const IMG_WIDTH = 96; // 상품 썸네일 이미지 너비
const IMG_HEIGHT = 144; // 상품 썸네일 이미지 높이

/**
 * 프로모션 리스트 상품카드
 *
 * @param i
 * @param productId
 * @param productProp
 * @param orderItemMap
 * @param onClick
 * @param imgOnClick
 * @param quantityOnClick
 * @returns {JSX.Element}
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function ProductCard({
  index: i,
  productId,
  product,
  orderItemMap = {},
  onClick = () => false,
  imgOnClick = () => false,
  quantityOnClick = () => {}
}) {
  const { pdata } = product.product;

  const orderItem = React.useMemo(() => {
    return orderItemMap[productId];
  }, [orderItemMap]);

  // 상품 추가여부
  const addedOrderItem = !!orderItem;

  // 상품 품절여부
  const isSoldOut = React.useMemo(() => product?.quantity === 0, [product?.quantity]);

  // tag 문자열
  const tagStrings = React.useMemo(() => Strings.pdataTagStrings(pdata), [pdata]);

  // render
  return (
    <ListItem key={`promotion-product-${i}`} sx={{ px: '18px', py: '8px', position: 'relative' }}>
      <Box component="section" height="144px" display="flex" alignItems="flex-start" flexGrow={1} maxWidth="100%">
        {/* 추가/제외 버튼 */}
        {!isSoldOut && (
          <Box
            position="absolute"
            left={-6}
            top="50%"
            zIndex={1}
            onClick={() => {
              if (!product) {
                console.warn(`상품정보가 아직 로드되지 않았습니다. [id=${productId}]`);
                return undefined;
              }
              onClick(product);
            }}
            sx={{ transform: 'translateY(-50%)', opacity: 1 }}
          >
            {addedOrderItem ? <OrderCheckedIcon /> : <OrderAddIcon />}
          </Box>
        )}

        {/* 위시 버튼  */}
        <Box position="absolute" right={1} top={'1px'}>
          {pdata && (
            <WishButton
              pdataId={pdata.id ?? pdata._id}
              pdataName={pdata.name.ko}
              onWishChange={(isWish) => {
                console.log('추가상훔 찜하기 여부: ', isWish);
              }}
            />
          )}
        </Box>

        {/* 품절표시 */}
        {isSoldOut && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="42px"
            height="25px"
            border="1px solid #EA3D44"
            left="44px"
            top="70px"
            borderRadius="2px"
            zIndex="10"
            className="sold-out-box"
            bgcolor="#FFF"
          >
            <Typography color="#EA3D44" fontSize="15px" fontWeight="700">
              품절
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 0 }} width={IMG_WIDTH}>
          <ButtonBase
            sx={{ width: '100%', height: '100%' }}
            className="pdata-detail-button"
            onClick={() => {
              if (pdata) imgOnClick(pdata);
            }}
          >
            {!pdata ? (
              <Skeleton animation="wave" variant="square" width={IMG_WIDTH} height={IMG_HEIGHT} sx={{ borderRadius: '8px' }} />
            ) : (
              <ProductImage
                width="96px"
                height="144px"
                visibleByDefault
                src={pdata.bottle_img?.thumb ?? wineDefaultImg}
                isSoldOut={isSoldOut}
                alt={'상품'}
                vivino={product?.product?.pdata?.score?.vivino}
              />
            )}
          </ButtonBase>
        </Box>

        <Box
          width={`calc(100% - ${IMG_WIDTH}px - 28px)`}
          ml="16px"
          mr="12px"
          height="144px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ opacity: isSoldOut && '.4' }}
        >
          <Box width="100%" marginTop="2px">
            {/* 한줄평 */}
            <Typography
              width="100%"
              fontSize="11px"
              letterSpacing="0.15px"
              lineHeight="14px"
              height="18px"
              color="#9357E5"
              fontWeight="700"
              sx={{
                height: '14px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginBottom: '4px'
              }}
            >
              {pdata.desc1}
            </Typography>
            {/* 상품명 */}
            <Box height="36px" sx={{ fontSize: '14px', lineHeight: '18px', letterSpacing: '0.2px', fontWeight: '800' }}>
              <Typography
                fontSize="inherit"
                lineHeight="inherit"
                letterSpacing="inherit"
                fontWeight="inherit"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  opacity: 0.8
                }}
              >
                {pdata?.name?.ko}
              </Typography>
            </Box>
            <Box mt="11px" mb="7px" display={addedOrderItem && 'none'}>
              {/* 상품 tag */}
              <Box>
                <Stack direction="row" spacing="3px">
                  {tagStrings.map((tagStr, i) => (
                    <Box display="flex" key={`prd-${product._id}-tag-${i}`}>
                      <Typography color={'rgba(20, 2, 41)'} fontSize="12px" fontWeight="400" lineHeight="14px" letterSpacing="0.1px">
                        {tagStr}
                      </Typography>
                      {i < tagStrings.length - 1 && ( // 마지막 아이템 이전에만 CircleIcon을 추가합니다.
                        <Box display="flex" alignItems="center" width="3px" height="100%" marginLeft="3px" marginRight="1px" mb="3px">
                          <CircleIcon
                            style={{
                              width: '3px',
                              height: '3px',
                              color: 'rgba(20, 2, 41, 0.60)'
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Stack>
              </Box>
              {/* 상품 vintage, 용량 */}
              <Box display="flex" alignItems="center" mt="4px">
                {product.product.vintage !== 'NV' && (
                  <>
                    <Typography lineHeight="14px" fontFamily="D-DIN" fontSize="13px" fontWeight="400" letterSpacing="0.1px">
                      {product.product.vintage}
                    </Typography>
                    <Box display="flex" alignItems="center" width="3px" height="100%" marginLeft="3px">
                      <CircleIcon
                        style={{
                          width: '3px',
                          height: '3px',
                          color: 'rgba(20, 2, 41, 0.60)'
                        }}
                      />
                    </Box>
                  </>
                )}

                <Typography
                  lineHeight="14px"
                  fontFamily="D-DIN"
                  fontSize="13px"
                  fontWeight="normal"
                  letterSpacing="0.1px"
                  sx={{ marginLeft: product.product.vintage !== 'NV' ? '3px' : 0 }} // NonVintage의 경우 ml 0
                >
                  {Calculator.formatCapacity(product.product.capacity)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width="100%">
            {/* 상품 가격 */}
            <Box mb={addedOrderItem && '5px'}>
              {/* 할인 전 가격 */}
              <Typography
                display={addedOrderItem && 'none'}
                lineHeight="15px"
                fontSize="13px"
                fontWeight="400"
                fontFamily="D-DIN"
                sx={{ textDecoration: 'line-through' }}
              >
                {product.price.sale.toLocaleString()}
              </Typography>

              <Box display="flex" alignItems="center" mt="1px">
                <Typography lineHeight="17px" fontSize="16px" fontWeight="700" fontFamily="D-DIN" color="#B80004">
                  {product.price.original.toLocaleString()}
                </Typography>
                <Typography lineHeight="16px" fontSize="11px" fontWeight="700" color="#B80004" sx={{ ml: '1px', mt: '1px' }}>
                  원
                </Typography>
              </Box>
            </Box>

            {/* 수량 박스 */}
            {addedOrderItem && (
              <Box width="129px">
                <QuantityBox
                  boxHeight={35}
                  disabled={product.quantity === 0}
                  max={product.quantity >> 0}
                  value={orderItemMap[productId]?.quantity}
                  onChange={(val) => {
                    quantityOnClick(orderItem, val);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}

ProductCard.propTypes = {
  index: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  orderItemMap: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  imgOnClick: PropTypes.func.isRequired,
  quantityOnClick: PropTypes.func.isRequired,
  product: PropTypes.object
};

export default ProductCard;
