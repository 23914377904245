import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { useSelector } from 'store';
import { VendorImage } from '../../../../vendor/VendorList/components/VendorCard/components';

// material-ui
import { useTheme, alpha, styled } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, Chip, Grid, Typography } from '@mui/material';
import VendorDetailDialog from './VendorDetailDialog';
import { useLocationState } from 'react-router-use-location-state';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const vendorImgWidth = 90;
const vendorImgHeight = 90;

/**
 * 공동구매지도 > 입점샵카드
 *
 * @constructor 최효근<hkchoi@winone.io>
 */
function VendorCardInfo({ vendor, index }) {
  // tag 요소 ref
  const parentRef = React.useRef(null);
  // 보여지는 tag길이
  const [shownElementsWidth, setShownElementsWidth] = React.useState(0);
  // 숨겨진 tag
  const [hiddenElements, setHiddenElements] = React.useState([]);

  // 샵상세정보열고닫기
  const [openVendorDetailDialog, setOpenVendorDetailDialog] = useLocationState('ovdd', false);

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    let addr;
    if (!vendor.biz) return null;

    if (vendor?.biz?.address1) {
      addr = vendor.biz.address1;
      if (vendor.biz.address2) addr += `\r\n${vendor.biz.address2}`;
    }
    return addr;
  }, [vendor?.biz]);

  const theme = useTheme();

  // tag길이, 숨겨진 값 구하기
  const findHiddenElements = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const { childNodes } = parentRef.current;

      const hiddenElements = [];
      let shownTotalElementsWidth = 0;
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];

        const childRect = childNode.getBoundingClientRect();

        if (childRect.top > parentRect.top) {
          hiddenElements.push(childNode);
        } else {
          shownTotalElementsWidth += childNode.offsetWidth;
        }
      }
      setHiddenElements(hiddenElements);
      setShownElementsWidth(shownTotalElementsWidth);
    }
  };

  React.useEffect(() => {
    setShownElementsWidth(0);
    setHiddenElements([]);
    findHiddenElements();
  }, [vendor]);

  return (
    <Box
      component="section"
      width="100%"
      display="flex"
      position="relative"
      sx={{ flexDirection: 'row' }}
      justifyContent="center"
      alignItems="center"
      height="122px"
      borderTop={'1px solid rgba(0, 0, 0, 0.1)'}
      paddingY="16px"
      key={`cobuying-vendor-box-${index}`}
    >
      <Box className="vendor-image-box" sx={{ borderRadius: '10px', width: vendorImgWidth }}>
        <VendorImage
          src={vendor.vendor_img && vendor.vendor_img[0]}
          width={vendorImgWidth}
          height={vendorImgHeight}
          alt={`${vendor._id}_image`}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: `calc( 100% - ${vendorImgWidth}px )`,
          height: vendorImgHeight,
          marginTop: '2px'
        }}
      >
        <CardContent
          sx={{
            pt: 0,
            pb: 0,
            flex: '1',
            width: '100%',
            position: 'relative'
          }}
        >
          {/* 매장 이름 영역 */}

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography width="85%" noWrap fontSize="15px" fontWeight={750} letterSpacing="-0.11px">
              {vendor.biz.name}
            </Typography>
            <ButtonBase onClick={() => setOpenVendorDetailDialog(true)}>
              <Typography color="#140229" fontSize="11px" sx={{ width: '40px' }}>
                상세보기
              </Typography>
              <KeyboardArrowRightIcon sx={{ opacity: '50%', width: '11px' }} />
            </ButtonBase>
          </Box>

          <Box marginTop="3px" sx={{ maxWidth: '90%', width: '90%' }}>
            <Typography
              fontSize="12px"
              sx={{
                opacity: '.7',
                letterSpacing: '-0.14px',
                wordBreak: 'keep-all',
                lineHeight: '1.2',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2
              }}
            >
              {vendor.biz.address1} {vendor.biz.address2}
            </Typography>
          </Box>

          {/* 샵 tag chips[start] */}
          <Box
            display="flex"
            sx={{
              maxWidth: '90%',
              height: 24,
              width: '90%',
              position: 'absolute',
              bottom: '2px'
            }}
          >
            <Grid
              width={shownElementsWidth === 0 ? '85%' : `${shownElementsWidth + 1}px`}
              overflow="hidden"
              container
              spacing="4px"
              ref={parentRef}
            >
              {(vendor.shop?.service_tag ?? []).map((tag, i) => {
                return (
                  <Grid item xs="auto" key={`v-svc-tag-${vendor._id}-${i}`}>
                    <ServiceTag tagCode={tag} />
                  </Grid>
                );
              })}
            </Grid>
            {shownElementsWidth !== 0 && hiddenElements.length > 0 && (
              <Box marginLeft="4px">
                <ServiceChip
                  label={`+${hiddenElements.length}`}
                  color="default"
                  size="small"
                  sx={{ bgcolor: '#ffffff', border: `1px solid ${alpha(theme.palette.brand.main, 0.04)}` }}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
      <VendorDetailDialog
        openVendorDetailDialog={openVendorDetailDialog}
        setOpenVendorDetailDialog={setOpenVendorDetailDialog}
        vendor={vendor}
        vendorAddr={vendorAddr}
      />
    </Box>
  );
}

export default VendorCardInfo;

VendorCardInfo.propTypes = {
  vendor: PropTypes.object,
  coBuyingId: PropTypes.string,
  index: PropTypes.number
};

/* eslint-disable react/prop-types */
const ServiceTag = ({ tagCode, ref, ...other }) => {
  const theme = useTheme();
  const { code } = useSelector((state) => state.woConstants);

  if (!code.service_tag) {
    console.warn('서비스태그(service_tag) 상수가 아직 로드되지 않았습니다.');
    return null;
  }

  const tag = (
    <ServiceChip
      label={code.service_tag[tagCode].ko}
      color="default"
      size="small"
      sx={{ bgcolor: alpha(theme.palette.brand.main, 0.04) }}
      {...other}
    />
  );

  return tag;
};

const ServiceChip = styled(Chip)`
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 1.3;
  border-radius: 2px;
  padding: 4px 6px;
  & .MuiChip-label {
    padding: 0;
  }
  & .MuiSvgIcon-root {
    margin-right: 2px;
  }
`;
