import React from 'react';
import { useLocationState } from 'react-router-use-location-state';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { useDebounce } from 'use-debounce';

// project imports
import { useAuth, useScriptRef } from '../../../../hooks';
import { OrderLayout } from '../components';
import { CouponDialog, OrderSection, TitleBox } from '../OrderConfirm/components';
import waveBorderImg from '../OrderConfirm/components/wave_border.png';
import { Checkbox, ErrorIcon, ProductLabel, ProductName, ProductPrice, SmallButton, SpaceBetweenBox } from '../../../../components';
import { CoBuyingRefundInfo, VendorInfoCard } from './components';
import { getLatestTerm, TERM_TYPE } from '../../../../services/AppService';
import { useDispatch } from '../../../../store';
import { getFirebaseFunctions } from '../../../../utils/firebase-tools';
import { getCoBuyingDetail } from '../../../../services/ProductService';
import { closeBackdrop, openBackdrop } from '../../../../store/slices/backdrop';
import { WoAlert } from '../../../../utils/kmwine-alerts';
import { CLO_CODE, ORDER } from '../../../../config';
import { fetchMyCouponList } from '../../../../services/UserService';
import { amplitude } from '../../../../index';
import * as OrderService from '../../../../services/OrderService';
import { getOneVendor } from '../../../../services/VendorService';
import { ProductImage } from '../../../../components/v2';
import WishButton from '../../../../components/buttons/WishButton';
import { Calculator } from '../../../../utils/bourgogne-tools';
import { QuantityBox } from '../OrderTodayBottleConfirm/components';

// material-ui
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  TextField as MuiTextField,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import TasteDivision from '../../../mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';

// 상품 이미지 가로 크기
const IMG_WIDTH = 107;
// 상품 이미지 세로 크기
const IMG_HEIGHT = 148;
const spacing = '12px';

// 추가 요청 사항 최대 글자수
const COMMENT_MAX_LEN = 40;

// 약관/정책 초기값
const initialTermState = Object.freeze({
  loaded: false,
  error: false,
  type: null,
  term: null
});

/**
 * 공동구매 주문확인 화면
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function OrderCoBuyingConfirm() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  // 로그인 사용자 정보
  const { user } = useAuth();
  const globalDispatch = useDispatch();

  // clo error
  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // URL query-strings
  const [searchParams] = useSearchParams();

  // 어디서 왔는가??
  const from = searchParams.get('from') || 'normal';

  const coBuyingId = searchParams.get('cid');
  const vendorId = searchParams.get('vid');

  // AppBar ref
  let appBarRef = React.useRef(null);
  // AppBar 높이
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  // AppBar 높이 구하기
  React.useEffect(() => {
    // AppBar 높이 구하기
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, [appBarRef]);

  // 주문확인 및 이용약관 동의여부
  const [agree, setAgree] = React.useState(false);

  // 샵 정보
  const [vendor, setVendor] = React.useState({ loaded: false, data: null });

  // 공동 구매 정보
  const [coBuying, setCoBuying] = React.useState({ loaded: false, data: null });

  // 구매할 수 있는 공동구매 수량
  const [cobOrderCount, setCobOrderCount] = React.useState({ now: 1, max: 0 });

  // 추가 요청 사항
  const [comment, setComment] = React.useState('');

  // 최종 구매 정보 (상품 id, 가격, 수량)
  const [orderInfo, setOrderInfo] = React.useState({ product_id: '', price: 0, quantity: 1 });

  // 주문 금액 계산
  const calculateOrderPrice = React.useMemo(() => {
    if (!coBuying.loaded) return;

    // 상품 정보
    const {
      data: { price__original: priceOriginal, product_id }
    } = coBuying;
    if (!priceOriginal) return <Skeleton width="120px" />;

    // 선택한 상품 수량
    const { now } = cobOrderCount;
    if (!now || typeof now !== 'number') return <Skeleton width="120px" />;

    const price = priceOriginal;

    setOrderInfo({ product_id, price, quantity: now });

    return price.toLocaleString();
  }, [coBuying.loaded, cobOrderCount]);

  // 샵 정보 가지고오기
  const fetchVendorDetail: Promise<void> = React.useCallback(async () => {
    const vendorData = await getOneVendor(vendorId).catch((error) => ({ error }));

    if (vendorData.error) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `샵정보 조회 중 오류`,
        msg: `[uid=${user._id}]`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchVendorDetail][fetchVendorDetail] ', vendorData.error);
      return undefined;
    }

    const { data, result } = vendorData.data;

    if (result.code !== 0) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `샵정보 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.msg)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchVendorDetail][fetchVendorDetail] ', result.msg);
      return undefined;
    }

    setVendor({ loaded: true, data });
  }, []);

  React.useLayoutEffect(() => {
    fetchVendorDetail();
  }, []);

  // 공동구매 상세정보 가지고 오기
  const fetchCoBuyingDetail: Promise<any> = React.useCallback(async () => {
    globalDispatch(openBackdrop({ open: true, text: '상품 정보를 불러오는 중입니다.' }));
    const result = await getCoBuyingDetail(coBuyingId).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      globalDispatch(closeBackdrop());
      console.warn('[fetchCoBuyingDetail][fetchCoBuyingDetail] Unmounted component.');
      return undefined;
    }

    // 공동구매 조회 실패
    if (result.error) {
      globalDispatch(closeBackdrop());

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `공동구매 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] ', result.error);
      WoAlert.fire('공동구매', '공동구매 조회에 실패하였습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    const { cobuying: cobuyingResult, success } = result;

    // 잘못된 Response 값이 반환됨
    if (!success) {
      globalDispatch(closeBackdrop());

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `공동구매 조회 중 오류`,
        msg: `[uid=${user._id}]`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] 잘못된 Response 값이 반환됨');
      WoAlert.fire('공동구매', '공동구매 조회에 실패하였습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // 현재판매량
    const orderCnt = cobuyingResult.order_cnt === null ? 0 : Number(cobuyingResult.order_cnt);

    // 유저의 구입수량
    const myOrderCnt = cobuyingResult.my_order_cnt === null ? 0 : Number(cobuyingResult.my_order_cnt);

    // 주문수량이 최대수량을 넘었을경우
    if (cobuyingResult.max_quantity <= orderCnt) {
      globalDispatch(closeBackdrop());
      WoAlert.fire('공동구매', '상품이 품절되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }
    // show가 false일 경우
    if (cobuyingResult.show === 'false') {
      globalDispatch(closeBackdrop());
      WoAlert.fire('공동구매', '상품 전시 기간이 종료되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // 상품 판매기간이 종료되었을 경우
    if (new Date(cobuyingResult.sale_end_dt) < new Date()) {
      globalDispatch(closeBackdrop());
      WoAlert.fire('공동구매', '상품 판매 기간이 종료되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    const findMaxQuantity = () => {
      if (cobuyingResult.sale_cnt === 0) {
        return cobuyingResult.max_quantity - orderCnt;
      }
      if (cobuyingResult.max_quantity - orderCnt < cobuyingResult.sale_cnt) {
        return cobuyingResult.max_quantity - orderCnt;
      }
      return cobuyingResult.sale_cnt - myOrderCnt;
    };

    setCobOrderCount({
      now: 1,
      max: findMaxQuantity()
    });
    setCoBuying({ loaded: true, data: cobuyingResult });

    globalDispatch(closeBackdrop());
  }, []);

  React.useLayoutEffect(() => {
    if (!coBuyingId) return;
    fetchCoBuyingDetail();
  }, []);

  // 주문정보
  const [order, setOrder] = React.useState({
    request: false, // 요청 여부
    data: null // 주문 데이터
  });

  // 내가 보유한 쿠폰 다이어로그 열림
  const [openCouponDialog, setOpenCouponDialog] = React.useState(false);

  // 사용자 보유쿠폰 정보
  const [myCoupons, setMyCoupons] = useLocationState('myCoupons', {
    loaded: false,
    error: false,
    coupons: []
  });

  // 적용가능한 보유쿠폰 정보
  const [availableCoupons, setAvailableCoupons] = React.useState([]);

  // 쿠폰 상태정보(선택 등)
  const [selectedCoupons, setSelectedCoupons] = useLocationState('selectedCoupons', []);

  // 최대할인금액
  const [couponMaxAmount, setCouponMaxAmount] = React.useState(null);

  // 쿠폰 할인금액
  const discountByCoupons = React.useMemo(() => {
    return selectedCoupons.reduce((dcTotal, selected) => {
      return dcTotal + (selected.coupon.amount >> 0);
    }, 0);
  }, [selectedCoupons]);

  // 총 상품 금액
  const goodsTotalAmount = React.useMemo(() => {
    const { price, quantity } = orderInfo;

    return price * quantity;
  }, [orderInfo]);

  // 결제 금액 (총 상품 금액 - 쿠폰 할인 )
  const amount = goodsTotalAmount - discountByCoupons;

  // 약관/정책
  const [termState, setTermState] = React.useState(initialTermState);

  // 약관 가져오기
  const fetchTerm = (type) => {
    if (termState.loaded && termState.type === type) {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
      return;
    }

    setTermState({ ...termState, type });

    if (!termState.loaded || termState.type !== type) {
      getLatestTerm(type.toUpperCase())
        .then((snapshot) => {
          if (scriptedRef.current) {
            if (!snapshot.empty) {
              setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
            } else {
              console.error(`[1KMWINE] 문서가 존재하지 않습니다. [type=${type}]`);
              setTermState({ ...termState, type, loaded: true, error: false, term: null });
            }
          }
        })
        .catch((e) => {
          console.error(`[1KMWINE] 문서 조회 중 오류가 발생했습니다. [type=${type}]`, e);
          if (scriptedRef.current) {
            setTermState({ ...termState, type, loaded: true, error: true, term: null });
          }
        });
    } else {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
    }
  };

  // 약관보기
  const [showTerms, setShowTerms] = useLocationState('termModalOpen', false);

  const handleShowTerms = (show = false, agreeTerms) => {
    if (typeof agreeTerms === 'boolean') setAgree(agreeTerms);
    if (show) {
      fetchTerm(TERM_TYPE.SERVICE);
      setShowTerms(show, { method: 'true' });
    } else {
      navigate(-1);
    }
  };

  // '결제하기' 버튼 클릭
  const startPurchase = async () => {
    const { price, quantity } = orderInfo;
    const productAmount = price * quantity;

    // 쿠폰 금액이 상품 금액보다 많은 경우
    if (discountByCoupons > productAmount) {
      if (selectedCoupons.length > 0) {
        WoAlert.fire(`잘못된 쿠폰입니다.  \n쿠폰을 다시 선택해주세요.`, '', 'error').then(() => {
          setSelectedCoupons([]);
        });
        return undefined;
      }
    }

    setOrder({ ...order, request: true });

    const orderCnt = coBuying.data.order_cnt === null ? 0 : Number(coBuying.data.order_cnt);

    // 주문수량이 최대수량을 넘었을경우
    if (coBuying.data.max_quantity <= orderCnt) {
      WoAlert.fire('공동구매', '상품이 품절되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }
    // show가 false일 경우
    if (coBuying.data.show === 'false') {
      WoAlert.fire('공동구매', '상품 전시 기간이 종료되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }
    // 상품 판매기간이 종료되었을 경우
    if (new Date(coBuying.data.sale_end_dt) < new Date()) {
      WoAlert.fire('공동구매', '상품 판매 기간이 종료되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // 주문 상품id, 수량
    const { product_id: orderProductId, quantity: orderProductQuantity } = orderInfo;

    // -------------------------------------------------------------------- 주문생성 [START]
    // 선택된 쿠폰아이디 array
    const coupon = selectedCoupons.map(({ id }) => id);

    // 주문(order)생성 요청 파라미터
    const orderRequestParam = {
      vendor_id: vendorId, // 판매사(입점샵) ID
      products: [], // 주문상품
      coupon,
      pickup_date: `${moment(coBuying.data.pick_end_dt).format('YYYY-MM-DD')}`,
      pickup_time: '20:00',
      pay: {
        type: 'CARD', // 결제방식 'CARD'
        amount // 결제 총액
      },
      state: ORDER.STATE.ING, // 최초상태 : 결제 중
      paid: false,
      from
    };

    // 주문상품 정보
    orderRequestParam.products = [{ id: orderProductId, quantity: orderProductQuantity, packing: null, comment }];
    console.debug('결제 요청정보: ', { ...orderRequestParam });

    // Amplitude Payment Start
    amplitude.getInstance().logEvent('Payment Start');

    const res = await OrderService.createOrder(orderRequestParam).catch((error) => ({ error }));

    // 공동구매 주문정보 생성 오류
    if (res.error) {
      const { error } = res;
      console.error('[1KMWINE] 주문정보 생성실패.', error);
      setOrder({ ...order, request: false });
      errorCallback();
      return undefined;
    }

    setOrder({ ...order, request: false });

    const { status, data } = res;

    if (status !== 200) return errorCallback();

    const { data: orderCreated, result } = data;
    console.debug('생성된 주문정보: ', orderCreated);
    if (result.code !== 0) {
      WoAlert.fire(`주문정보 생성 중 오류가 발생했습니다.\n[${result.msg}]\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        console.warn(`[wineone] 주문정보 생성 오류 [code: ${result.code}, msg: ${result.msg}]`);

        // 주문금액 오류일 경우 뒤로가기
        if (result.code === 1) navigate(-1);
      });
      return false;
    }

    navigate(`/payment/inicis/request`, { state: { order: { ...orderCreated } } }); // 이니시스 카드결제요청

    async function errorCallback() {
      await WoAlert.fire('주문정보 생성 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.', '', 'error').then(() => {
        console.warn('[1KMWINE] 주문정보 생성 오류 [OrderConfirm.createOrder$error]');
      });
      return false;
    }
  };

  // 뒤로가기

  const pageBackFunc = () => {
    navigate(-1);
  };

  // '쿠폰 선택' 라벨
  const couponSectionLabel = React.useMemo(() => {
    if (!myCoupons.loaded) {
      return <span style={{ fontSize: '11px' }}>보유 쿠폰을 확인 중 입니다...</span>;
    }

    if (selectedCoupons.length > 0) {
      return `쿠폰 적용 (${selectedCoupons.length}개)`;
    }
    return availableCoupons.length > 0 ? (
      `사용 가능 쿠폰 ${availableCoupons.length}개`
    ) : (
      <span style={{ fontSize: '11px', color: theme.palette.text.disabled }}>사용 가능한 쿠폰이 없습니다.</span>
    );
  }, [myCoupons.loaded, myCoupons.error, selectedCoupons, availableCoupons]);

  // 주문개수 디바운스처리
  const [orderQuantity] = useDebounce(orderInfo.quantity, 400);

  React.useEffect(() => {
    if (coBuying.loaded) {
      fetchMyCoupons();
    }
  }, [coBuying.loaded, orderQuantity]);

  // 사용자 보유쿠폰 목록 조회
  const fetchMyCoupons: Promise<void> = async () => {
    setMyCoupons({ loaded: false, error: false, coupons: [] });

    if (!coBuying.loaded) return;
    const { product_id } = coBuying.data;
    const { quantity } = orderInfo;

    // 파라미터 정보가 모두 있을 경우 조회
    if (product_id && vendorId && quantity && typeof quantity === 'number') {
      console.log('[1KMWINE] 사용자 보유쿠폰 조회 시작...');

      // 쿠폰 목록 조회 request Body
      const _products = [{ product: { _id: product_id, pdata: { _id: coBuying?.data?.pdata___id } }, quantity: orderInfo.quantity }];

      // todo OrderService.getAvailableCouponList로 교체할 것
      const response = await fetchMyCouponList(vendorId, _products).catch((error) => {
        console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류.', error);
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류`,
          msg: `[uid=${user._id}] ${error}`,
          which: `${location.pathname}${location.search}`,
          param: { uid: user._id, product_id: searchParams.get('pid') }
        })
          .then(console.log)
          .catch(console.error);
        return { error };
      });

      if (!scriptedRef.current) {
        console.warn(`[OrderTodayBottleConfirm][fetchMyCoupons] Unmounted component.`);
        return undefined;
      }

      // 보유 쿠폰목록 불러오기 오류
      if (response.error) {
        setMyCoupons((myCoupons) => ({ ...myCoupons, loaded: true, error: true }));
        await WoAlert.fire('', '보유쿠폰 목록 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.', 'error').then(() => {
          navigate(-1);
        });
        return undefined;
      }

      // [오늘의한병 주문확인] 쿠폰정보 조회 성공
      const { result, data } = response.data;

      if (result.code === 0) {
        // 쿠폰 자동적용
        const autoApplyCoupon = [];

        // 쿠폰 최대할인금액
        let maxAmount = 0;

        data.map((el) => {
          if (response.data.autoApplyCouponId.includes(el.coupon.id)) {
            autoApplyCoupon.push(el);
            maxAmount += el.coupon.amount;
          }
          return undefined;
        });

        setSelectedCoupons(autoApplyCoupon);
        setCouponMaxAmount(maxAmount);

        setMyCoupons({
          loaded: true,
          error: false,
          coupons: data
        });
      } else {
        console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류. code=', result.code, result.msg);
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류`,
          msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id
          }
        })
          .then(console.log)
          .catch(console.error);

        setMyCoupons((myCoupons) => ({ ...myCoupons, loaded: true, error: true, coupons: [] }));
        await WoAlert.fire(`보유쿠폰 목록 조회 중 오류가 발생했어요.`, '', 'warning');
      }
    } else {
      // console.warn('입점샵 아이디(vendor.id)를 아직 확인할 수 없습니다.', vendor);
    }
  };

  return (
    <OrderLayout
      title="주문하기"
      appBarRef={(ref) => {
        appBarRef = ref;
      }}
      showBackDrop={false}
      backDrop={
        <Box>
          <Typography variant="body2" color="#ffffff">
            상품정보를 확인 중 입니다
          </Typography>
        </Box>
      }
      onBackButtonClick={() => {
        pageBackFunc();
      }}
    >
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 공동구매 주문하기`} />
      <Backdrop open={order.request} sx={{ zIndex: theme.zIndex.appBar + 5 }}>
        <Box p={3} sx={{ backdropFilter: 'blur(33px)', background: 'rgba(0,0,0,0.4)', borderRadius: '8px', overflow: 'hidden' }}>
          <Typography variant="body2" color="#ffffff">
            주문정보를 생성하고 있습니다.
          </Typography>
        </Box>
      </Backdrop>
      <Container maxWidth="xs" pt={0} sx={{ minHeight: `calc(var(--vh, 1vh) * 100 - ${appBarHeight})` }}>
        {/* 입점샵 정보 [START] */}
        {vendor.loaded && <VendorInfoCard vendor={vendor.data} />}
        {/* 입점샵 정보 [END] */}
        <Divider sx={{ borderColor: '#2D154D' }} />

        <OrderSection>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              상품 정보
            </Typography>
          </TitleBox>
          <Box aria-label="공동구매">
            <Box py={2}>
              <Box display="flex">
                {/* 구매상품 이미지 */}
                <ProductImage
                  src={coBuying.loaded ? coBuying.data?.bottle_img?.thumb : ''}
                  width={IMG_WIDTH}
                  height={IMG_HEIGHT}
                  alt="todayOneBottle_Product_Image"
                >
                  {coBuying.loaded && coBuying.data.sale_cnt !== 0 && (
                    <ProductLabel height={26} fontSize={9}>
                      최대 {cobOrderCount.max}병 주문 가능
                    </ProductLabel>
                  )}
                </ProductImage>

                {/* 구매상품 정보 */}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={`calc( 100% - ${IMG_WIDTH}px - ${spacing} )`}
                  ml="10px"
                >
                  <Box>
                    <SpaceBetweenBox sx={{ height: 20 }}>
                      <Typography variant="caption" fontSize={11} fontWeight={800} letterSpacing="-0.11px">
                        {coBuying.data?.producer?.ko ? (
                          coBuying.data?.producer?.ko
                        ) : (
                          <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>
                        )}
                      </Typography>
                      {coBuying?.data?.pdata___id ? (
                        <WishButton
                          pdataId={coBuying.data?.pdata___id}
                          pdataName={coBuying.data?.name__ko}
                          onWishChange={(isWish) => {
                            console.log('오늘의 한 병 상품 찜하기 여부: ', isWish);
                          }}
                        />
                      ) : null}
                    </SpaceBetweenBox>
                    {/* 상품명 */}
                    <ProductName sx={{ pt: 0.25 }}>{coBuying.data?.name__ko ?? <Skeleton width="80%" />}</ProductName>

                    {/* 빈티지 / 용량 */}
                    <Box>
                      <Stack
                        direction="row"
                        spacing="6px"
                        divider={
                          <Box display="flex" alignItems="center" pb="1px">
                            <Box height="9px" width="1px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
                          </Box>
                        }
                        sx={{ fontSize: 11, fontWeight: 300, color: 'text.secondary' }}
                      >
                        {coBuying.data?.vintage && coBuying.data.vintage !== 'NV' && (
                          <Typography variant="caption">{`${coBuying.data.vintage}년`}</Typography>
                        )}
                        <Typography variant="caption">{Calculator.formatCapacity(coBuying.data?.capacity)}</Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="center">
                      <ProductPrice>{calculateOrderPrice}</ProductPrice>
                      <span style={{ paddingLeft: '1px', fontWeight: 300, fontSize: '16px', letterSpacing: '-1px' }}>원</span>
                    </Box>

                    {!coBuying.loaded ? (
                      <CircularProgress size={16} color="brand" />
                    ) : cobOrderCount.max > 1 ? (
                      <QuantityBox
                        value={cobOrderCount.now}
                        max={cobOrderCount.max}
                        onChange={(newVal) => setCobOrderCount({ ...cobOrderCount, now: newVal })}
                      />
                    ) : (
                      <Typography component="span" color="text.secondary" fontSize="16px">
                        수량 · 1
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              {/* 구매상품 추가 요청사항 */}
              <Box mt="12px">
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ '& .MuiInputBase-root': { height: 42 }, '& .MuiTypography-root': { lineHeight: '42px' } }}
                >
                  <MuiTextField
                    hiddenLabel
                    fullWidth
                    autoComplete="off"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    onBlur={(e) => {
                      setComment(e.target.value.trim());
                    }}
                    // placeholder={`추가요청사항: (ex. 선물포장 해주세요, 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
                    // 2023.11.23 모젤 크리스마스 주문 시 선물포장이 많이 들어오는 이슈로 잠시 숨김처리
                    placeholder={`추가요청사항: (ex. 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
                    inputProps={{ maxLength: COMMENT_MAX_LEN }}
                    sx={{
                      '& .MuiInputBase-input::-webkit-input-placeholder': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#140229'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '5px',
                        backgroundColor: 'transparent',
                        borderWidth: '1px !important'
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </OrderSection>

        {/* 주문/픽업 정보 */}
        {coBuying.loaded && (
          <Box component="section">
            <Typography fontWeight="900" fontSize="14px" sx={{ marginBottom: '16px' }}>
              주문/픽업 정보
            </Typography>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                주문 기간
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography>
                {moment(coBuying.data.sale_start_dt).isSame(coBuying.data.sale_end_dt, 'day')
                  ? `${moment(coBuying.data.sale_start_dt).locale('ko').format('MM.DD(ddd)')}`
                  : `${moment(coBuying.data.sale_start_dt).locale('ko').format('MM.DD(ddd)')} ~
                          ${moment(coBuying.data.sale_end_dt).locale('ko').format('MM.DD(ddd)')}`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                입고 일정
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography>
                주문 마감 후 {moment(coBuying.data.delivery_end_dt).locale('ko').format('MM.DD(ddd)')}까지 픽업샵에 입고
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                픽업 기간
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography>픽업샵 입고 완료 후 별도 안내</Typography>
            </Box>
          </Box>
        )}

        {/* 쿠폰 정보 [START] */}
        <OrderSection sx={{ mt: 2 }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              쿠폰
            </Typography>
          </TitleBox>

          {/* 현재 선택된 쿠폰 금액 */}
          {selectedCoupons.length > 0 && (
            <Box marginBottom="16px" marginTop="8px">
              {myCoupons.loaded ? (
                <Typography fontSize="14px" color="#9357E5">
                  {discountByCoupons.toLocaleString()}원 {couponMaxAmount === discountByCoupons ? '최대' : ''} 할인 적용 중
                </Typography>
              ) : (
                <Skeleton width="200px" sx={{ fontSize: '14px' }} />
              )}
            </Box>
          )}

          {/* 쿠폰 변경하기 버튼 */}
          <SpaceBetweenBox height="28px">
            <Typography>{couponSectionLabel}</Typography>
            {myCoupons.loaded && (
              <SmallButton
                onClick={() => {
                  setOpenCouponDialog(true);
                }}
                sx={{ borderColor: '#60546E26', color: '#140229' }}
              >
                {selectedCoupons.length === 0 ? `보유 쿠폰 ${myCoupons.coupons.length}개` : '쿠폰 변경하기'}
              </SmallButton>
            )}
          </SpaceBetweenBox>

          {/* 적용된 쿠폰의 이름과 금액 */}
          <Box pt="14px" color={(theme) => alpha(theme.palette.text.primary, 0.55)} width="100%">
            {selectedCoupons.length > 0 && myCoupons.loaded ? (
              selectedCoupons.map((selected, i) => {
                const { coupon } = selected;
                return (
                  <SpaceBetweenBox key={`selected-coupon-${i}`} height="28px" sx={{ pl: '1rem' }}>
                    <Typography noWrap sx={{ maxWidth: '50%' }}>
                      ㄴ&nbsp;{coupon.name}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginRight="12px">
                      <Typography fontWeight="bold">{coupon.amount.toLocaleString()}</Typography>원
                    </Box>
                  </SpaceBetweenBox>
                );
              })
            ) : selectedCoupons.length === 0 ? null : (
              <SpaceBetweenBox sx={{ pl: '1rem' }}>
                <Skeleton width="120px" height="28px" />
                <Skeleton width="30px" height="28px" sx={{ marginRight: '12px' }} />
              </SpaceBetweenBox>
            )}
          </Box>
        </OrderSection>
        {/* 쿠폰 정보 [END] */}

        {/* 결제수단 [START] */}
        <OrderSection sx={{ mt: '32px' }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              결제수단
            </Typography>
          </TitleBox>
          <Box pt={1} pb={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" fontSize="14px">
                신용/체크카드
              </Typography>
              {/* todo 다른 결제방법 기능작업 후 활성화
              <Button variant="outlined" size="small" sx={{ py: 0.25 }}>
                변경
              </Button>
               */}
            </Box>
          </Box>
        </OrderSection>
        {/* 결제수단 [END] */}

        <Box sx={{ mx: '-20px', px: '20px', pt: 3.5, position: 'relative', bgcolor: '#f3f2f5' }}>
          <Box component="img" alt="" src={waveBorderImg} top="-3px" position="absolute" left={0} width="100%" height="4px" />
          {/* 결제 금액 [START] */}
          <OrderSection>
            <TitleBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                결제 금액
              </Typography>
            </TitleBox>
            <Box pt="17px" pb="30px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>총 상품 금액</Typography>
                <Typography fontWeight={900}>{goodsTotalAmount.toLocaleString()}원</Typography>
              </Box>
              {/* 쿠폰 할인 */}
              <Collapse mountOnEnter unmountOnExit in={selectedCoupons.length > 0}>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                  <Typography>쿠폰 사용</Typography>
                  {myCoupons.loaded ? (
                    <Typography color="#EA3D44" fontWeight={900}>
                      -{discountByCoupons.toLocaleString()}원
                    </Typography>
                  ) : (
                    <CircularProgress size={14} sx={{ color: '#EA3D44' }} />
                  )}
                </Box>
              </Collapse>
            </Box>
          </OrderSection>
          {/* 결제 금액 [END] */}

          {/* 총 결제 금액 [START] */}
          <Divider />
          <SpaceBetweenBox sx={{ py: 3 }}>
            <Typography variant="subtitle1" fontWeight={900} fontSize="14px">
              총 결제 금액
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" fontSize="22px" color="#140229" fontWeight="bold" fontFamily="D-DIN-Bold,D-DIN">
                {myCoupons.loaded ? `${amount.toLocaleString()}원` : <CircularProgress size={22} sx={{ color: '#140229' }} />}
              </Typography>
            </Box>
          </SpaceBetweenBox>
          {/* 총 결제 금액 [END] */}

          {/* 환불규정 */}
          <CoBuyingRefundInfo />

          {/* 주문내용 확인 및 약관동의 [START] */}
          <Box py={1} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  size="small"
                  inputProps={{ 'aria-labelledby': 'order-confirm-term' }}
                />
              }
              label={
                <Typography id="order-confirm-term" variant="caption" lineHeight={1} fontSize="14px">
                  주문내용 확인 및 약관동의
                </Typography>
              }
            />
            <Button variant="outlined" size="small" sx={{ py: 0.25 }} onClick={() => handleShowTerms(true)}>
              약관보기
            </Button>
          </Box>
          {/* 주문내용 확인 및 약관동의 [END] */}

          {/* 결제하기 버튼 [START] */}
          <Box pt="24px" pb="16px">
            <Button
              fullWidth
              type="button"
              disabled={!coBuying.loaded || !myCoupons.loaded || !agree}
              variant="contained"
              color="brand"
              size="x-large"
              onClick={startPurchase}
              sx={{ height: 46 }}
            >
              <Typography fontSize={17} fontWeight={800}>
                결제하기
              </Typography>
            </Button>
          </Box>
          {/* 결제하기 버튼 [END] */}
        </Box>
      </Container>

      {/* 쿠폰선택 다이얼로그 */}
      {coBuying.loaded && (
        <CouponDialog
          loaded={myCoupons.loaded}
          amount={coBuying.data.price__original}
          open={openCouponDialog}
          onClose={() => setOpenCouponDialog(false)}
          onSelect={(coupons) => {
            console.debug('[주문하기] 선택된 쿠폰 정보. ', coupons);
            setSelectedCoupons(coupons);
            setOpenCouponDialog(false);
          }}
          coupons={myCoupons}
          selectedCoupons={selectedCoupons}
        />
      )}
      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color="text.primary">
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color="text.primary" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color="text.primary" variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size="small"
              variant="outlined"
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>
      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color="text.primary">
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color="text.primary" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color="text.primary" variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size="small"
              variant="outlined"
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </OrderLayout>
  );
}

export default OrderCoBuyingConfirm;

// eslint-disable-next-line react/prop-types
const Transition = React.forwardRef(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});
