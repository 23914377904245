import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { useSelector } from 'store';
import { useAuth, useLocalStorage, useScriptRef } from 'hooks';
import { USER_LOCATION_TYPE } from 'config';
import { KeyboardDownIcon, PeriscopeFilledIcon } from 'components/icons';

// material-ui
import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';
import { Button, Chip, CircularProgress, Fade, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { useNavigationType } from 'react-router-dom';

/* Sprint 23-1: 위치변경버튼 안내 툴팁 [start] */
/** 툴팁 노출지연 ms */
const TOOLTIP_SHOW_AFTER = 1200;
/** 툴팁 노출시간 ms */
const TOOLTIP_EXPOSURE_MILLIS = 4000;
/** 툴팁 노출 최대횟수 */
const TOOLTIP_EXPOSURE_COUNT_MAX = 3;

// 툴팁 보이기 타임아웃 ID
let tooltipShowTimeout = null;
// 툴팁 감추기 타임아웃 ID
let tooltipHideTimeout = null;
/* Sprint 23-1: 위치변경버튼 안내 툴팁 [end] */

const useStyles = makeStyles({
  root: {
    height: '34px',
    marginLeft: '-14px',
    padding: '6px 12px 6px 16px',
    // borderRadius: 20,
    overflow: 'hidden',
    width: 'auto',
    backdropFilter: 'blur(15px)',
    color: 'text.primary',
    backgroundColor: 'rgba(255,255,255,0.15)',
    '& .MuiButton-startIcon': {
      marginRight: 0
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0
    }
  }
});

/**
 * 위치 버튼
 * @constructor
 */
function LocationButton({ onClick, light, hideTooltip = true }) {
  const scriptedRef = useScriptRef();
  const navigationType = useNavigationType();

  const { needPinSetting } = useAuth();

  // 툴팁 노출 횟수
  const [tooltipShowCount, setTooltipShowCount] = useLocalStorage('tooltip-home-locationBtn', 0);

  // 사용자 위치정보
  const userLocation = useSelector((state) => state.userLocation);
  const { loading, type, currentPin } = userLocation;

  const isClickable = React.useMemo(() => onClick && typeof onClick === 'function', [onClick]);

  // 위치 표현
  const label = React.useMemo(() => {
    let label = '설정 중';
    if (type === USER_LOCATION_TYPE.CURRENT) {
      label = '현재 위치';
    } else if (type === USER_LOCATION_TYPE.PIN) {
      label = currentPin ? currentPin.name : '설정 중';
    } else {
      console.debug('위치를 아직 알 수 없습니다.', userLocation);
      if (userLocation.loading) {
        console.info('위치 정보를 가져오고 있습니다.');
      } else {
        console.warn('잘못된 위치 타입. ', type);
      }
    }
    return label;
  }, [userLocation]);

  const classes = useStyles();

  /* Sprint 23-1: 위치변경버튼 안내 툴팁 [start] */
  // 위치변경버튼 툴팁 보이기
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (showTooltip && !hideTooltip) {
      setTooltipShowCount((tooltipShowCount) => {
        console.debug(`[LocationButton] 위치변경버튼 툴팁 노출횟수. ${tooltipShowCount + 1}/${TOOLTIP_EXPOSURE_COUNT_MAX}`);
        return ++tooltipShowCount;
      });

      // #{TOOLTIP_EXPOSURE_MILLIS} millisecond 후 툴팁 감춤
      tooltipHideTimeout = setTimeout(() => {
        if (scriptedRef.current) {
          setShowTooltip(false);
        }
      }, TOOLTIP_EXPOSURE_MILLIS);
    } else if (hideTooltip) {
      hideLocationTooltip();
    }
  }, [showTooltip, hideTooltip]);

  // 툴팁 감추기
  const hideLocationTooltip = () => {
    clearTimeout(tooltipShowTimeout);
    clearTimeout(tooltipHideTimeout);

    if (scriptedRef.current) {
      setShowTooltip(false);
    }
  };

  // 툴팁 자동 보이기/감추기
  React.useEffect(() => {
    // 화면 진입 또는 재진입시에 표출되도록 함
    if (navigationType === 'POP') return undefined;
    // 핀세팅 화면에서는 띄우지 않음
    if (needPinSetting) {
      console.info('[LocationButton] 핀세팅 화면에서는 툴팁을 띄우지 않습니다.');
      return undefined;
    }
    if (tooltipShowCount >= TOOLTIP_EXPOSURE_COUNT_MAX) {
      console.info(`[LocationButton] 이미 툴팁 노출횟수를 달성했습니다. [TOOLTIP_EXPOSURE_COUNT_MAX = ${TOOLTIP_EXPOSURE_COUNT_MAX}]`);
      return undefined;
    }

    // #{TOOLTIP_SHOW_AFTER} millisecond 후 툴팁 표현
    tooltipShowTimeout = setTimeout(() => {
      if (scriptedRef.current) {
        setShowTooltip(true);
      }
    }, TOOLTIP_SHOW_AFTER);

    // console.log('##tootipShowTimeout: ', tootipShowTimeout);

    return () => {
      clearTimeout(tooltipShowTimeout);
      clearTimeout(tooltipHideTimeout);

      if (scriptedRef.current) {
        setShowTooltip(false);
      }
    };
  }, [hideTooltip, needPinSetting]);
  /* Sprint 23-1: 위치변경버튼 안내 툴팁 [end] */

  // render
  return loading ? (
    <Chip
      icon={<CircularProgress color={'inherit'} size={14} />}
      label={'위치정보 확인 중...'}
      size={'small'}
      color={'gray'}
      variant="outlined"
    />
  ) : (
    <LocationBtnTooltip
      title={
        <Typography variant="body2" fontSize="0.8rem" fontWeight="normal">
          다른 지역의 와인을 탐색해보세요.
        </Typography>
      }
      arrow
      open={showTooltip && !hideTooltip}
    >
      <Button
        fullWidth
        startIcon={<PeriscopeFilledIcon />}
        className={`${classes.root} ${light ? classes.light : classes.dark}`}
        onClick={(e) => {
          if (isClickable) {
            setShowTooltip(false);
            onClick(e);
          }
        }}
        disableRipple={!isClickable}
        endIcon={isClickable ? <KeyboardDownIcon /> : null}
      >
        <Typography
          variant="subtitle2"
          component="div"
          textAlign="left"
          lineHeight={1}
          fontSize="15px"
          fontWeight="bold"
          noWrap
          sx={{ mixBlendMode: 'difference', width: 'auto', display: 'inline-block' }}
        >
          {label}
        </Typography>
      </Button>
    </LocationBtnTooltip>
  );
}

LocationButton.propTypes = {
  onClick: PropTypes.func,
  light: PropTypes.bool.isRequired,
  hideTooltip: PropTypes.bool
};

export default LocationButton;

// Sprint 23-1: 위치변경버튼 툴팁
const LocationBtnTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 600 }}
    disableFocusListener
  />
))(({ theme }) => {
  const tooltipBorder = `1px solid ${alpha(theme.palette.divider, 0.2)}`;
  return {
    position: 'fixed !important',
    transform: 'none !important',
    top: '40px !important',
    paddingLeft: '20px',
    [`& .${tooltipClasses.arrow}`]: {
      color: alpha(theme.palette.background.paper, 0.95), // 화살표 bg
      fontSize: '16px' // 화살표 높이
    },
    [`& .${tooltipClasses.arrow}:before`]: {
      border: tooltipBorder // 화살표 border
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: alpha(theme.palette.background.paper, 0.95), // 말풍선 bg
      backdropFilter: 'blur(2px)',
      color: theme.palette.text.primary,
      border: tooltipBorder, // 말풍선 border
      marginTop: '18px !important', // 화살표 높이 보정
      padding: '0.75rem' // 말풍선 내부 패딩
    }
  };
});
