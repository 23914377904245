// Firebase functions base host.
const FB_FUNCTIONS_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

/**
 * @constant
 */
export const FIREBASE_FUNCITON_URL = {
  /** 로그인 유저 위치타입(location_type) 수정 */
  PUT__SET_LOCATION_TYPE: `${FB_FUNCTIONS_HOST}/call-auth-member-setLocationType`,

  /** 이메일 중복검사 */
  POST__EMAIL_CHECK: `${FB_FUNCTIONS_HOST}/call-auth-signup-emailCheck`,

  /** 인증메일 발송요청 */
  POST__SEND_CERT_EMAIL: `${FB_FUNCTIONS_HOST}/call-auth-signup-sendCertEmail`,

  /** 이메일 인증 여부 확인 */
  POST__CHECK_CERT_EMAIL: `${FB_FUNCTIONS_HOST}/call-auth-signup-checkCertEmail`,

  /** 추천인 코드 유효성 확인 */
  POST__CHECK_F_CODE: `${FB_FUNCTIONS_HOST}/call-auth-signup-fCodeCheck`,

  /**
   * 사용자 중복가입여부 확인
   * @deprecated
   */
  POST__CHECK_USER_CI: `${FB_FUNCTIONS_HOST}/call-auth-signup-checkUserCi`,

  /** 닉네임 중복검사 */
  POST__NICKNAME_CHECK: `${FB_FUNCTIONS_HOST}/call-auth-signup-nicknameCheck`,

  /** 닉네임 수정 */
  PUT__CALL_AUTH_MEMBER_SET_NICKNAME: `${FB_FUNCTIONS_HOST}/call-auth-member-setNickname`,

  /** 프로필 이미지 변경 */
  POST__CALL_AUTH_MEMBER_SET_PROFILE_IMG: `${FB_FUNCTIONS_HOST}/call-auth-member-setProfileImg`,

  /** 혜택/이벤트 알림 수신 동의 변경 */
  POST__CALL_AUTH_MEMBER_SET_TERMS_PUSH: `${FB_FUNCTIONS_HOST}/call-auth-member-setTermsPush`,

  /** 쿠폰 다운로드 */
  PUT__DOWNLOAD_COUPON: `${FB_FUNCTIONS_HOST}/call-pay-coupon-download`,

  /** 사용가능한 쿠폰 목록 조회 */
  POST__COUPON_LIST: `${FB_FUNCTIONS_HOST}/call-pay-coupon-userCouponList`,

  /** 사용자 FCM TOKEN 업데이트 */
  POST__SET_FCM_TOKEN: `${FB_FUNCTIONS_HOST}/call-auth-member-setFcmToken`,

  /** 사용자 FCM TOKEN 삭제 */
  POST__REMOVE_FCM_TOKEN: `${FB_FUNCTIONS_HOST}/call-auth-member-removeFcmToken`,

  /** wish(찜) 하기 */
  POST__WISH_PDATA: `${FB_FUNCTIONS_HOST}/call-auth-member-wish`,

  /** 와인 취향 저장하기 */
  PUT__SET_MY_TASTE: `${FB_FUNCTIONS_HOST}/call-auth-member-setMyTaste`,

  /** 구매상품 리뷰 작성 */
  POST__PRODUCT_REVIEW_CREATE: `${FB_FUNCTIONS_HOST}/call-product-review-create`,

  /** 사용자 해제 */
  DELETE__CALL_AUTH_MEMBER_USER_BLOCK_CANCEL: `${FB_FUNCTIONS_HOST}/call-auth-member-userBlockCancel`,

  /**
   * 키워드 알림 등록
   * @see https://clevercube.notion.site/call-cdm-keyword-create-4c61136b9d1c4e86833eeee3bdef998e
   */
  POST__CALL_CDM_KEYWORD_CREATE: `${FB_FUNCTIONS_HOST}/call-cdm-keyword-create`,
  /**
   * 키워드 알림 삭제
   * @see https://clevercube.notion.site/call-cdm-keyword-delete-34abbdbc83a3430ba02df81780658e13
   */
  PUT__CALL_CDM_KEYWORD_DELETE: `${FB_FUNCTIONS_HOST}/call-cdm-keyword-delete`,
  /**
   * 키워드 알림상태 업데이트
   * @see https://clevercube.notion.site/call-cdm-keyword-update-bebc2a9eb5e54a38b1a24b31540670bb
   */
  PUT__CALL_CDM_KEYWORD_UPDATE: `${FB_FUNCTIONS_HOST}/call-cdm-keyword-update`,

  /** 회원탈퇴 */
  POST__CALL_AUTH_MEMBER_LEAVE: `${FB_FUNCTIONS_HOST}/call-auth-member-leave`,

  /** 로그인 사용자 휴대폰번호 변경요청 */
  POST__CALL_AUTH_MEMBER_SET_MOBILE: `${FB_FUNCTIONS_HOST}/call-auth-member-setMobile`,

  /** 로그인 사용자 알림 전체삭제 */
  PUT__CALL_AUTH_MEMBER_REMOVE_ALL_ALARM: `${FB_FUNCTIONS_HOST}/call-auth-member-removeAllAlarm`,

  /** 사용자 최종 접속정보 갱신 */
  PUT__CALL_AUTH_MEMBER_CONNECT: `${FB_FUNCTIONS_HOST}/call-auth-member-connect`
};

export const NODE_URL = {
  /** 로그인 유저정보 조회 */
  GET__USER__DETAIL: `${API_SERVER_HOST}/member/info/detail`,

  /** 로그인 유저의 위치핀 정보저장 */
  POST__SET_PIN: `${API_SERVER_HOST}/member/setPin`,

  GET__DASHBOARD_COUNTS: `${API_SERVER_HOST}/member/dashboard/counts`,
  /** @deprecated 사용가능한 쿠폰 목록 조회 */
  POST__COUPON__LIST: `${API_SERVER_HOST}/member/coupon/list`,
  /** 사용자의 보유쿠폰 목록 조회 */
  POST__MY_COUPON_DOWNLOAD_LIST: `${API_SERVER_HOST}/member/coupon/v2/list`,

  /** 사용가능한 쿠폰 카운트 조회 */
  GET__COUPON__COUNT: `${API_SERVER_HOST}/member/coupon/counts`,

  /** 위시리스트 조회 */
  POST__WISH_LIST: `${API_SERVER_HOST}/member/wish/list`,

  /** 닉네임 중복검사 */
  GET__NICKNAME_CHECK: `${API_SERVER_HOST}/util/nickname/check`,
  /** 이메일 중복검사 */
  GET__EMAIL_CHECK: `${API_SERVER_HOST}/util/email/duplicate`,
  /** 친구추천코드 유효성 검사 */
  GET__FCODE_CHECK: `${API_SERVER_HOST}/member/f-code/get`,
  /** 친구추천코드 소급적용 */
  POST__FCODE_POST: `${API_SERVER_HOST}/member/f-code/post`,

  /** 유저 차단 목록 */
  POST__USER_BLOCK_LIST: `${API_SERVER_HOST}/member/block-user/list`,
  /** 유저 차단하기 */
  POST__USER_BLOCK: `${API_SERVER_HOST}/member/block-user`,
  /** 유저 차단 해제 */
  DELETE__USER_BLOCK: `${API_SERVER_HOST}/member/block-user`
};

export * from './user'; // 사용자 관련기능
export * from './wish'; // 사용자 위시(wish) 관련기능
export * from './alarm'; // 사용자 알림(alarm) 관련기능
