import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { BottomDialog, Checkbox, WoDialogTitle, WoDivider } from 'components';
import { vendorProductOrderOpts } from '../../VendorDetailIndex';

/**
 * 주문 다이얼로그
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function OrderDialog(props) {
  const { id = 'vendor-product-order-menu', onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    console.log('# value: ', value);
    onClose(value);
  };

  const handleChange = (event) => {
    console.log('# event: ', event);
    setValue(event.target.value);
  };

  return (
    <BottomDialog open={open} keepMounted id={id} maxWidth="xs" onClose={handleCancel} {...other}>
      <WoDialogTitle title="상품 정렬" onClickClose={handleCancel} />
      <WoDivider />

      <DialogContent sx={{ py: 0 }}>
        <List dense sx={{ width: '100%' }} disablePadding>
          {vendorProductOrderOpts.map((option, i) => (
            <React.Fragment key={option.value}>
              {i > 0 && <Divider component="li" />}
              <ListItem
                dense
                secondaryAction={
                  <Checkbox
                    edge="end"
                    variant="flat"
                    checked={option.value === value}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': option.label }}
                    value={option.value}
                  />
                }
                disablePadding
                sx={{ height: 64 }}
              >
                <ListItemButton
                  value={option.value}
                  onClick={() => {
                    setValue(option.value);
                  }}
                  sx={{ height: '100%' }}
                >
                  <ListItemText
                    primary={option.label}
                    primaryTypographyProps={{ fontSize: '18px', letterSpacing: '-0.18px', lineHeight: 'normal' }}
                  />
                </ListItemButton>
              </ListItem>
              {/* <Divider component="li" /> */}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" size="x-large" color="brand" onClick={handleCancel}>
              취소
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" size="x-large" color="brand" onClick={handleOk}>
              확인
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BottomDialog>
  );
}

OrderDialog.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

export default OrderDialog;
