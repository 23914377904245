import Swal from 'sweetalert2/dist/sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/** WineOne Alert */
const WoAlert = withReactContent(
  Swal.mixin({
    showConfirmButton: true,
    confirmButtonText: '확인',
    cancelButtonText: '취소',
    reverseButtons: true,
    showClass: {
      popup: 'animate__animated animate__fastest animate__fadeIn'
    },
    hideClass: {
      popup: 'animate__animated animate__fastest animate__fadeOut'
    }
  })
);

export { WoAlert };
