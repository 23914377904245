import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// project imports
import { useWineOne } from 'hooks';
import BackButton from 'components/buttons/BackButton';
import { ToolbarTitle } from 'components/toolbar';
import { WoDivider } from 'components/dialog';
import { getLatestTerm, termTypeToString } from 'services/AppService';
import { WineBottleLoadingLottie } from 'components/spinner';
import { ErrorIcon } from 'components/icons';

// material-ui
import { AppBar, Backdrop, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

function TermPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { onNativeBackPressed } = useWineOne();

  // 뒤로가기
  const pageBackFunc = useCallback(() => {
    console.debug(`정책 상세화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location]);

  const initialState = {
    loaded: false,
    error: false,
    term: null
  };

  // 약관/정책
  const [termState, setTermState] = useState(initialState);

  useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [location]);

  // 주문번호
  const { type } = useParams();

  const fetchTerm = () => {
    setTermState(initialState);

    getLatestTerm(type.toUpperCase())
      .then((snapshot) => {
        if (!snapshot.empty) {
          setTermState({ ...termState, loaded: true, error: false, term: snapshot.docs[0].data() });
        } else {
          console.error(`[1kmwine] 문서가 존재하지 않습니다. [type=${type}]`);
          setTermState({ ...termState, loaded: true, error: false, term: null });
        }
      })
      .catch((e) => {
        console.error('[1kmwine] 문서 조회 중 오류가 발생했습니다.', e);
        setTermState({ ...termState, loaded: true, error: true, term: null });
      });
  };

  useEffect(() => {
    fetchTerm();
  }, [type]);

  return (
    <Box minHeight={`calc(var(--vh, 1vh) * 100 - ${theme.components.MuiAppBar.styleOverrides.root.minHeight}px)`}>
      <AppBar position={'sticky'} elevation={0}>
        <Toolbar>
          <BackButton color="inherit" onClick={pageBackFunc} />
          <ToolbarTitle>{termTypeToString(type)}</ToolbarTitle>
        </Toolbar>
        <WoDivider />
      </AppBar>
      <Backdrop open={!termState.loaded}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <WineBottleLoadingLottie />
          <Typography color="#fff">문서를 불러오고 있습니다.</Typography>
        </Box>
      </Backdrop>
      <main>
        <Container maxWidth="xs" disableGutters>
          {termState.loaded && !termState.error && <div dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
        </Container>
        {termState.error && (
          <Container>
            <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <ErrorIcon />
              <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
              <Button variant="contained" color="brand" startIcon={<RefreshOutlinedIcon />} onClick={fetchTerm} sx={{ mt: '7px' }}>
                다시 시도
              </Button>
            </Box>
          </Container>
        )}
      </main>
    </Box>
  );
}

export default TermPage;
