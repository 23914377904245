// material-ui
import { Box, Typography } from '@mui/material';

// assets
import VendorIcon from './components/assets/img_map_banner.png';
import { useNavigate } from 'react-router-dom';

function VendorMapBanner() {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="108px"
      marginTop="56px"
      sx={{ background: `linear-gradient(to right, #AD42E1, #7635CF)` }}
      onClick={() => {
        navigate('/map/vendor');
      }}
    >
      <Box marginRight="17px">
        <Typography fontSize="19px" color="#ffffff" sx={{ width: '202px' }}>
          내 주변 픽업 가능 와인샵을 찾아보세요.
        </Typography>
      </Box>
      <Box component="img" width="84px" height="84px" src={VendorIcon} />
    </Box>
  );
}

export default VendorMapBanner;
