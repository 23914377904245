import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// project imports
import { useWineOne } from 'hooks';
import Footer from 'components/Footer';
// import { AlarmButton, CartButton } from 'components/buttons';
import { AlarmButton, CartButton } from 'components/buttons';
import { ToolbarTitle } from 'components/toolbar';
import { WoDivider } from 'components/dialog';

// material-ui
import { AppBar, Box, Container, Stack, Toolbar } from '@mui/material';

/**
 * 마이(my) 화면 레이아웃

 * @constructor
 */
function MyCommonLayout({ title = '마이', children, showFooter = true, showBottomNav: showBottomNavProp = true }) {
  const { showBottomNav, hideBottomNav, resetBottomNav } = useWineOne();

  useEffect(() => {
    // console.log('@마운트됨');
    if (showBottomNavProp) {
      showBottomNav();
    } else {
      hideBottomNav();
    }

    return () => {
      // console.log('@언마운트됨');
      resetBottomNav();
    };
  }, []);

  // 알림버튼
  const alarmButtonComponent = useMemo(() => {
    return <AlarmButton showBadge />;
  }, []);

  // render
  return (
    <Box minHeight={'calc(var(--vh, 1vh) * 100)'}>
      <AppBar position="sticky" sx={{ px: [0, 0, 0, 0] }}>
        <Container maxWidth="xs" sx={{ position: 'relative', p: [0, 0, 0, 0] }}>
          <Toolbar sx={{ px: [2, 2, 2, 2], height: ['54px', '54px', '54px', '54px'], minHeight: ['54px', '54px', '54px', '54px'] }}>
            <Box flexGrow={1} />
            <ToolbarTitle>{title}</ToolbarTitle>

            <Stack direction="row" spacing="8px" sx={{ display: 'flex', alignItems: 'center' }}>
              {/* 장바구니 버튼 */}
              <CartButton showBadge />

              {/* 알람 버튼 */}
              {alarmButtonComponent}
            </Stack>
          </Toolbar>
          <WoDivider />
        </Container>
      </AppBar>
      <main>{children}</main>
      {showFooter && <MemoizedFooter />}
    </Box>
  );
}

MyCommonLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  showFooter: PropTypes.bool,
  showBottomNav: PropTypes.bool
};

export default MyCommonLayout;

const MemoizedFooter = React.memo(Footer);
