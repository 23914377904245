import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project imports
import { VIEW_TYPE_GRID, VIEW_TYPE_LIST } from '../../../mall/MallIndex/components/ProductList';
import { ProductImage } from 'components/v2';
import { ProducerTypo, ProductName } from 'components';

// material-ui
import { Box, ButtonBase, Grid, Stack, SvgIcon, Typography } from '@mui/material';

const IMG_HEIGHT = 185; // 상품 이미지 높이

/**
 * pdata 목록
 *
 * @param {string} viewType - 상품 표현 타입
 * @param {Array<Object>} [list=[]] -  상품 리스트
 * @param totalCount
 * @returns {JSX.Element|null}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PdataList({ viewType = VIEW_TYPE_GRID, list = [], totalCount = null }) {
  const navigate = useNavigate();

  if (totalCount === 0) return null; // 표현할 와인이 없을 경우

  // 뷰 형태(리스트/그리드)
  const isListView = React.useMemo(() => viewType === VIEW_TYPE_LIST, [viewType]);

  // render
  return (
    <Grid className="review-pdata-list-container" container columnSpacing={2} rowSpacing={isListView ? 3 : 2} sx={{ pb: '24px' }}>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" spacing="6px" pt="28px" pb="4px">
          <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal' }}>검색 결과</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal', color: '#9357E5' }}>
            {totalCount?.toLocaleString()}
          </Typography>
        </Stack>
      </Grid>
      {list.map((row, i) => {
        return (
          <Grid item key={`product-list-item-${i}`} xs={isListView ? 12 : 6} sm={isListView ? 12 : 4} md={isListView ? 6 : 3}>
            {/* 상품 이미지 */}
            <ProductImage src={row?.bottle_img_thumb?.raw} width="100%" height={IMG_HEIGHT} />

            {/* 상품 생산자 */}
            <Box height={30} mt={1}>
              <ProducerTypo>
                {row?.producer_ko?.raw?.trim() ? row?.producer_ko?.raw : <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>}
              </ProducerTypo>
            </Box>

            {/* 상품 명 */}
            <ProductName>{row?.name_ko?.raw}</ProductName>

            {/* 리뷰쓰기 버튼 */}
            <ButtonBase
              sx={{ width: '100%', mt: '12px', mb: '4px', borderRadius: '4px' }}
              onClick={() => navigate(`/my/review/${row?.id?.raw}/write`)}
            >
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: '5px',
                  border: '1px solid #D9D3DF',
                  borderRadius: '4px'
                }}
                spacing="2px"
              >
                <WriterIcon />
                <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal' }}>리뷰 쓰기</Typography>
              </Stack>
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );
}

PdataList.propTypes = {
  viewType: PropTypes.string.isRequired,
  list: PropTypes.array,
  totalCount: PropTypes.number
};

export default PdataList;

const WriterIcon = React.memo(() => (
  <SvgIcon sx={{ width: 22, height: 22, fill: 'none' }} viewBox="0 0 22 22">
    <path
      d="M6.28558 15.2065C6.28558 15.2065 6.33767 15.2065 6.35721 15.2065L8.26512 15.0307C8.53209 15.0047 8.77953 14.8874 8.96837 14.6986L16.1702 7.49674C16.5088 7.15814 16.6977 6.70884 16.6977 6.23349C16.6977 5.75814 16.5088 5.30884 16.1702 4.97023L15.7079 4.50791C15.0307 3.8307 13.8521 3.8307 13.1749 4.50791L12.2567 5.42605L5.97953 11.7033C5.7907 11.8921 5.67349 12.1395 5.65395 12.4065L5.47814 14.3144C5.4586 14.5553 5.54326 14.7898 5.71256 14.9656C5.86884 15.1219 6.0707 15.2065 6.28558 15.2065ZM14.4447 4.95721C14.653 4.95721 14.8614 5.03535 15.0177 5.19814L15.48 5.66047C15.6363 5.81674 15.7209 6.0186 15.7209 6.23349C15.7209 6.44837 15.6363 6.65674 15.48 6.80651L14.907 7.37954L13.2986 5.77116L13.8716 5.19814C14.0279 5.04186 14.2363 4.95721 14.4447 4.95721ZM6.62419 12.4977C6.62419 12.4586 6.64372 12.426 6.66977 12.4L12.6019 6.4614L14.2102 8.06977L8.27814 14.0019C8.27814 14.0019 8.21302 14.0474 8.18047 14.0474L6.46791 14.2037L6.62419 12.4912V12.4977ZM18 17.2837C18 17.5507 17.7786 17.7721 17.5116 17.7721H4.48837C4.2214 17.7721 4 17.5507 4 17.2837C4 17.0167 4.2214 16.7953 4.48837 16.7953H17.5116C17.7786 16.7953 18 17.0167 18 17.2837Z"
      fill="#140229"
    />
  </SvgIcon>
));
