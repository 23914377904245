import React from 'react';
import { Outlet, Route, useParams } from 'react-router-dom';
import MallIndex from '../views/mall/MallIndex';
import WineDetail from '../views/mall/wine/WineDetail';
import AuthLayout from '../utils/route-guard/AuthLayout';

import WineReviewList from '../views/mall/wine/WineReviewList';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from '../utils/firebase-tools';
import { useScriptRef } from '../hooks';

/**
 * 쇼핑몰관련 라우트
 * @constructor
 */
export default function MallRoutes() {
  return (
    <Route path="/mall" element={<AuthLayout />}>
      {/* '오더' 인덱스화면 */}
      <Route index element={<MallIndex />} />

      <Route path="wine/:pdata_id" element={<WineLayout />}>
        {/* 상품(와인) 인덱스 화면 */}
        <Route index element={<WineDetail />} />
        {/* 리뷰 목록화면 */}
        <Route path={'review/list'} element={<WineReviewList />} />

        {/* 상품(와인) 상세화면 */}
        <Route path="*" element={<WineDetail />} />
      </Route>
    </Route>
  );
}

function WineLayout() {
  const scriptedRef = useScriptRef();

  const { pdata_id: pdataId = null } = useParams();
  const [pdataSnapshot, setPdataSnapshot] = React.useState(null);

  React.useEffect(() => {
    // console.log('[MallRoutes][WineLayout] 와인정보 조회하기: ', pdataId);
    getDoc(doc(getFirestore(), 'pdata', pdataId)).then((snapshot) => {
      if (scriptedRef.current && snapshot.exists()) {
        setPdataSnapshot(snapshot);
      }
    });
  }, []);

  return <Outlet context={{ pdataId, pdataSnapshot }} />;
}
