import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { collection, doc, documentId, getDoc, getDocs, query, where } from 'firebase/firestore';
import validator from 'validator/es';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// project imports
import { CLO_CODE, IS_PRODUCTION } from 'config';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { useAuth, useScriptRef } from 'hooks';
import BackHeader from 'layouts/Main/BackHeader';
import { WoAlert } from 'utils/kmwine-alerts';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { requestOrderCancel } from 'services/OrderService';

// components
import { OrderCard } from './components';

// material-ui
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

// 입력가능한 글자 최대 길이
const COMMENT_MAX_LEN = 200;

/**
 * 주문취소 화면
 *
 * @returns {JSX.Element}
 * @constructor
 */
function OrderCancel() {
  // 사용자 정보
  const { user } = useAuth();

  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const location = useLocation();

  // 주문번호
  const { orderNo } = useParams(); // 주문번호(oid)

  // 주문정보
  const [order, setOrder] = React.useState({ loaded: false });

  // 주문취소사유
  const [cancelType, setCancelType] = React.useState(null);

  const handleChange = (event) => {
    console.log('[OrderCancel.jsx] handleChange: ', event.target.value);
    setCancelType(event.target.value);
    if (event.target.value === '999') {
      setReason('');
    } else {
      let reasonText = `기타(${event.target.value})`;
      try {
        const [{ textContent }] = event.target.labels;
        reasonText = `${textContent}`;
        // setReason(textContent ?? `기타(${event.target.value})`);
      } catch (e) {
        /* DO NOTHING */
      }
      setReason(reasonText);
    }
  };

  // 주문취소 직접입력
  const [reason, setReason] = React.useState('');

  // 구매시 사용된 쿠폰 정보
  const [usedCoupon, setUsedCoupon] = React.useState({
    loaded: false,
    error: false,
    datas: []
  });

  React.useLayoutEffect(() => {
    if (order.loaded) {
      console.log('취소할 주문정보: ', order);
    } else {
      console.debug(`주문정보 조회 [orderNo=${orderNo}]`);

      // 주문정보 조회
      getDoc(doc(getFirestore(), 'order', orderNo))
        .then((orderSnapshot) => {
          // 존재하지 않는 주문정보
          if (!orderSnapshot.exists()) {
            console.error(`[1kmwine] 존재하지 않는 주문정보를 조회함. [oid=${orderNo}]`);
            WoAlert.fire(`존재하지 않는 주문입니다.`, '', 'warning').then(() => {
              navigate(-1);
              // navigate(`/my/order/${orderNo}/detail`, { replace: true });
            });
            return false;
          }

          if (scriptedRef.current) {
            // firestore order -> order_products(subcollection)
            getDocs(collection(orderSnapshot.ref, 'order_products')).then((orderProductsSnapshot) => {
              const orderItems = [];
              if (scriptedRef.current) {
                orderProductsSnapshot.forEach((orderItemDoc) => {
                  orderItems.push(orderItemDoc.data());
                });

                const order = { ...orderSnapshot.data(), orderItems };
                setOrder({ loaded: true, ...order });
              }
            });
          }
        })
        .catch((error) => {
          console.error('[1KMWINE] ', error);
          // 주문취소 화면에서 주문정보 조회 실패
          if (IS_PRODUCTION) {
            try {
              const auth = getAuth();
              const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
              sendCloError({
                code: CLO_CODE.ORDER_LOAD_ERROR,
                title: `주문취소 화면에서 주문조회 실패 [uid=${auth.currentUser.uid}, oid=${orderNo}]`,
                msg: `${JSON.stringify(error)}`,
                which: `${location.pathname}${location.search}`
              })
                .then(console.log)
                .catch(console.error);
            } catch (e) {
              /* DO NOTHING */
            }
          }
          WoAlert.fire(`주문정보 조회 중 오류가 발생했습니다.\n[주문번호=${orderNo}]`, '', 'warning').then(() => {
            navigate(-1);
          });
          return false;
        });
    }
  }, [order]);

  React.useLayoutEffect(() => {
    console.debug('주문상세정보: ', order);
    if (order.loaded && !usedCoupon.loaded) {
      if (order.coupon.length > 0) {
        console.debug('결제시 사용된 쿠폰을 조회합니다. ', user._id, order.coupon);
        setUsedCoupon({ loaded: false, error: false, datas: [] });

        getDocs(query(collection(getFirestore(), `member/${user._id}/coupon_download`), where(documentId(), 'in', order.coupon)))
          .then((querySnapshot) => {
            const datas = [];
            querySnapshot.docs.forEach((couponDownloadDoc) => {
              datas.push(couponDownloadDoc.data());
            });
            console.debug('결제시 사용한 쿠폰정보 조회 완료. ', datas);
            setUsedCoupon({ loaded: true, error: false, datas });
          })
          .catch((error) => {
            console.error('[1kmwine] 사용쿠폰정보 조회 실패 ', error);
            setUsedCoupon({ loaded: true, error: true, datas: [] });
          });
      } else {
        console.log('결제시 사용된 쿠폰이 없습니다.');
        setUsedCoupon({ loaded: true, error: false, datas: [] });
      }
    }
  }, [order]);

  // todo 주문취소 가능여부
  const cancelAble = React.useMemo(() => {
    let able = true;
    if (cancelType == null) return false;

    if (cancelType === '999') {
      if (validator.isEmpty(reason)) {
        console.warn('[1kmwine] 직접입력을 선택했으나, 내용을 입력하지 않음');
        able = false;
      } else {
        // todo 입력값 검사
      }
    }
    return able;
  }, [cancelType, reason]);

  // 해당 페이지의 주문취소가 전체취소/부분취소 구분할 플래그
  const partCancelState: boolean = React.useMemo(() => {
    if (!order.loaded) return false;
    return order.product_states.includes('REQ_PART_OK');
  }, [order?.product_state]);

  // 취소 안내 메세지
  const cancelText = React.useMemo(
    () => ({
      title: partCancelState ? '주문 취소 안내' : '주문 전체 취소 안내',
      text: partCancelState ? '주문이 확정된 전체 상품을 취소 처리합니다.' : '주문한 전체 상품을 취소 처리합니다.'
    }),
    [partCancelState]
  );

  /* 주문취소 대상상품 목록[start] */
  const orderProductList = React.useMemo(() => {
    return (
      order.loaded && (
        <Box>
          {order.orderItems.map((orderItem) => {
            if (orderItem.state === 'REQ' || orderItem.state === 'REQ_OK' || orderItem.state === 'REQ_PART_OK') {
              return <OrderCard key={`order-cancel-${orderItem.product_id}`} order={order} orderProduct={orderItem} />;
            }
            return null;
          })}
        </Box>
      )
    );
  }, [order.loaded, order?.orderItems]);
  /* 주문취소 대상상품 목록[end] */

  const handleOrderCancelClick = React.useCallback(() => {
    console.log('[OrderCancel.jsx] 주문취소 요청', orderNo, cancelType, reason);
    WoAlert.fire({
      title: '주문취소',
      html: `<div style='line-height: 1.5rem; text-align: left'>주문을 취소하시겠습니까?</div>`,
      allowOutsideClick: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '주문취소',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      },
      preConfirm: () => {
        WoAlert.showLoading();
        WoAlert.getCancelButton().setAttribute('style', 'display:none');

        // 상품을 잘못 선택
        if (cancelType !== '999') {
          console.log('----- cancelType: ', cancelType);
        }

        return requestOrderCancel(orderNo, cancelType, reason)
          .then((response) => {
            // console.log('---- 주문취소 요청결과: ', response);
            const { result, data } = response.data;
            if (result.code !== 0) {
              throw new Error('주문취소 요청실패', { cause: result.code, data: response });
            }
            return data;
          })
          .catch((error) => {
            console.error('[1kmwine] 주문취소 요청 실패', error);
            WoAlert.fire(`주문취소 요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
            return false;
          });
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        WoAlert.fire(`주문취소가 신청되었습니다.`, '', 'success').then(() => {
          navigate(`/my/order/${orderNo}/detail`, { replace: true });
        });
      }
    });
  });

  // render
  return (
    <BackHeader title="주문취소">
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 주문취소 신청`} />
      <Container maxWidth="sm">
        <Box pt="12px">
          {/* 주문취소 대상상품 목록 */}
          {orderProductList}
        </Box>

        {/* 주문취소 안내 */}
        {order.loaded && (
          <>
            <Box mt="14px" p="5px 12px" bgcolor={alpha('#f46553', 0.1)} borderRadius="5px">
              <Box display="flex" alignItems="center">
                <InfoIcon sx={{ color: '#E37462', width: '15px', height: '18px', marginRight: '4px' }} />
                <Typography variant="subtitle2" fontSize={11} fontWeight={800} sx={{ my: '9px' }}>
                  {cancelText.title}
                </Typography>
              </Box>
              <Typography paragraph fontSize={11} lineHeight={1.55} sx={{ mb: '9px' }}>
                {cancelText.text}
              </Typography>
            </Box>

            <Divider sx={{ my: '30px' }} />
            <Box>
              <FormControl>
                <FormLabel id="order-cancel-buttons-group" sx={{ pb: '7px' }}>
                  <Typography variant="subtitle1" fontSize={14} fontWeight={800} lineHeight="normal" color="text.primary">
                    사유 선택
                  </Typography>
                </FormLabel>
                <RadioGroup aria-labelledby="order-cancel-buttons-group" name="cancelType" value={cancelType} onChange={handleChange}>
                  <FormControlLabel value="1" control={<Radio />} label="상품을 잘못 선택" />
                  <FormControlLabel value="2" control={<Radio />} label="다른 와인으로 재주문 예정" />
                  <FormControlLabel value="3" control={<Radio />} label="예약한 픽업 일시에 방문 불가" />
                  <FormControlLabel value="4" control={<Radio />} label="다른 결제 수단으로 재주문 예정" />
                  <FormControlLabel value="5" control={<Radio />} label="샵을 잘못 선택" />
                  <FormControlLabel value="999" control={<Radio />} label="직접입력" />
                </RadioGroup>
              </FormControl>

              {/* 취소 사유가 직접 입력일 경우 */}
              {cancelType === '999' && (
                <Box mt="6px">
                  <TextField
                    fullWidth
                    disabled={cancelType !== '999'}
                    required={cancelType === '999'}
                    autoComplete="off"
                    multiline
                    rows={3}
                    inputProps={{ maxRows: 5, maxLength: COMMENT_MAX_LEN }}
                    // inputProps={{ maxLength: COMMENT_MAX_LEN }}
                    value={reason}
                    onChange={(e) => {
                      console.log('------------------ e.target.value: ', e.target.value);
                      setReason(e.target.value);
                    }}
                    placeholder="상세사유를 입력해주세요."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Container>

      {/* 환불 예상금액[start] */}
      <Box bgcolor="#f3f2f5" mt="32px" pt="12px" pb="1px">
        <Container maxWidth="sm" component="section">
          <Box>
            <Typography variant="subtitle1" fontSize={14} fontWeight={800} lineHeight="normal" sx={{ my: '18px' }}>
              환불 예상 금액
            </Typography>
            <Stack spacing="12px">
              {/* 상품 금액 */}
              <SpaceBetweenBox>
                <Typography variant="body1" fontSize={14}>
                  환불 상품 금액
                </Typography>
                <Typography variant="body1" fontSize={14} fontWeight={800}>
                  {order.loaded ? `${order.pay.product.toLocaleString()}원` : <Skeleton width={80} />}
                </Typography>
              </SpaceBetweenBox>

              {/* 서비스 수수료 */}
              <SpaceBetweenBox>
                <Typography variant="body1" fontSize={14}>
                  환불 서비스 수수료
                </Typography>
                <Typography variant="body1" fontSize={14} fontWeight={800}>
                  {order.loaded ? `${order.pay.commission.toLocaleString()}원` : <Skeleton width={80} />}
                </Typography>
              </SpaceBetweenBox>

              {/* 할인 금액 */}
              {order.loaded && order.pay.discount > 0 && (
                <SpaceBetweenBox>
                  <Typography variant="body1" fontSize={14}>
                    총 할인 금액
                  </Typography>
                  <Typography variant="body1" color="error" fontSize={14} fontWeight={800}>
                    +{order.pay.discount.toLocaleString()}원
                  </Typography>
                </SpaceBetweenBox>
              )}

              {/* 결제시 사용한 쿠폰 목록 */}
              {usedCoupon.loaded &&
                !usedCoupon.error &&
                usedCoupon.datas.length > 0 &&
                usedCoupon.datas.map((data, i) => (
                  <SpaceBetweenBox key={`coupon-discount-${i}`} sx={{ opacity: 0.4 }}>
                    <Typography variant="body1" fontSize={14}>
                      {usedCoupon.datas.length === i ? <span>&#x251C;</span> : <span>&#x2514;</span>} {data.coupon.name}
                    </Typography>
                    <Typography variant="body2" fontSize={14} fontWeight={800}>
                      {data.coupon.discount_type === 'percent'
                        ? data.coupon.percent_amount.toLocaleString()
                        : (data.coupon.amount >> 0).toLocaleString()}
                      원
                    </Typography>
                  </SpaceBetweenBox>
                ))}
            </Stack>

            <Divider sx={{ mt: '30px' }} />

            <SpaceBetweenBox sx={{ my: '25px' }}>
              <Typography variant="subtitle1" fontSize={14} fontWeight={800} lineHeight="normal">
                최종 환불 금액
              </Typography>

              {order.loaded ? (
                <Box display="inline-flex" alignItems="center">
                  <Typography
                    variant="body1"
                    component="span"
                    fontSize={22}
                    fontWeight={800}
                    lineHeight="normal"
                    fontFamily="D-DIN-Bold,D-DIN"
                  >
                    {order.pay.remains.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" component="span" fontSize={16} fontWeight={300} sx={{ ml: '2px' }}>
                    원
                  </Typography>
                </Box>
              ) : (
                <Skeleton width={140} />
              )}
            </SpaceBetweenBox>
          </Box>
        </Container>
      </Box>
      {/* 환불 예상금액[end] */}

      <Container maxWidth="sm" sx={{ py: '16px' }}>
        <Button
          fullWidth
          disabled={!cancelAble}
          variant="contained"
          color="brand"
          size="large"
          onClick={handleOrderCancelClick}
          sx={{ fontWeight: 'bold', height: '50px', fontSize: '17px' }}
        >
          주문 취소 신청
        </Button>
      </Container>
    </BackHeader>
  );
}

export default OrderCancel;
