/**
 * 숫자관련 유틸리티
 */
class Numbers {
  constructor() {
    // eslint-disable-next-line no-throw-literal
    throw '인스턴스화 할 수 없습니다.';
  }

  /**
   * 넘버 포매터
   * <i>1403 -> 1k 또는 1.4k </i>
   * @param {number} num 변환할 숫자
   * @param {number} digits 표현 소수점
   * @returns {string}
   */
  static nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
      .slice()
      .reverse()
      .find((item) => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  }

  static numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static numberToKorean(number) {
    const inputNumber = number < 0 ? false : number;
    const unitWords = ['', '만', '억', '조', '경'];
    const splitUnit = 10000;
    const splitCount = unitWords.length;
    const resultArray = [];
    let resultString = '';

    for (let i = 0; i < splitCount; i++) {
      let unitResult = (inputNumber % splitUnit ** (i + 1)) / splitUnit ** i;
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) resultArray[i] = unitResult;
    }

    for (let i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString = String(this.numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  }
}

export default Numbers;
