import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { Calculator } from 'utils/bourgogne-tools';
import { ArrowCircleButton } from './components';
import wineDefaultImg from 'assets/images/default_wine.webp';

// material-ui
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ProductImage, WoDialogTitle, WoDivider } from '../../../../../../components';
import { useLocationState } from 'react-router-use-location-state';
import { useNavigationType } from 'react-router-dom';

/**
 * 상품주문 하단 버튼시트
 * @param {Object} product 메인 상품정보
 * @param {number} quantity 메인 상품 선택수량
 * @param {Object} orderItemMap 함께 주문한 상품
 * @param {function} onCartButtonClick 장바구니 버튼 클릭이벤트 콜백
 * @param {function} onOrderButtonClick 결제하기 버튼 클릭이벤트 콜백
 * @param {function} onDeleteClick 상품 선택취소 클릭이벤트 콜백
 * @param {number} totalBottleCount 선택한 상품 병수 총합
 * @returns {JSX.Element}
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function BottomButtonSheet({
  product,
  quantity,
  orderItemMap = {},
  onCartButtonClick,
  onOrderButtonClick,
  onDeleteClick,
  totalBottleCount = 0
}) {
  const theme = useTheme();
  const navigationType = useNavigationType();

  const bottomSheetRef = React.useRef(null);
  const buttonRowRef = React.useRef(null);

  // 상품정보 영역 open 여부
  const [orderInfoOpen, setOrderInfoOpen] = useLocationState('orderInfoOpen', false);

  const handleSheetAction = (action) => {
    console.log('action: ', action);
    setOrderInfoOpen(() => false);
  };

  const generateListItem = (product, quantity, useDelete) => {
    const imgSrc = product?.pdata?.bottle_img?.thumb ?? wineDefaultImg;
    const { vintage } = product;
    const vintageStr = vintage === 'NV' ? '' : `${vintage}년 | `;
    const capacityStr = `${Calculator.formatCapacity(product.capacity)} | `;

    const secondaryStr = `${vintageStr}${capacityStr}${quantity}병`;

    return (
      <ListItem
        key={`selectd-prd-${product._id}`}
        secondaryAction={
          useDelete && (
            <IconButton edge="end" aria-label="선택 취소" onClick={() => onDeleteClick(product)}>
              <CloseIcon />
            </IconButton>
          )
        }
        sx={{ px: 0 }}
      >
        <ListItemAvatar sx={{ maxHeight: '40px' }}>
          <ProductImage width={40} height={40} src={imgSrc} alt={product.name.ko} wrapperProps={{ sx: { padding: '4px' } }} />
        </ListItemAvatar>
        <ListItemText primary={product.name.ko} primaryTypographyProps={{ noWrap: true, sx: { pr: '40px' } }} secondary={secondaryStr} />
      </ListItem>
    );
  };

  // render
  return (
    <Box>
      <Box position={'fixed'} bottom={0} width={'100%'} ref={bottomSheetRef} sx={{ zIndex: 1198 }}>
        <Slide direction={'up'} in={orderInfoOpen} container={bottomSheetRef.current} appear={navigationType === 'PUSH'}>
          <OrderProductBox bottom={buttonRowRef.current ? buttonRowRef.current.offsetHeight - 2 : 58} zIndex={-1}>
            <WoDialogTitle
              title="선택한 상품"
              onClickClose={() => handleSheetAction(null)}
              tools={[<Typography variant="caption">총 {totalBottleCount}병</Typography>]}
            />
            <WoDivider />

            <Box
              py="8px"
              px="20px"
              maxHeight={185}
              sx={{
                overflowY: 'auto',
                transition: `max-height ${theme.transitions.duration.short}s ${theme.transitions.easing.easeInOut}`
              }}
            >
              <Box>
                <List>
                  {/* 주 상품 표현 */}
                  {generateListItem(product, quantity, false)}
                  {Object.keys(orderItemMap).map((productId) => {
                    const { product, quantity } = orderItemMap[productId];
                    return generateListItem(product, quantity, true);
                  })}
                </List>
              </Box>
            </Box>
          </OrderProductBox>
        </Slide>

        <ButtonGroupWrapper
          display="flex"
          alignItems="center"
          width={1}
          px={2}
          zIndex={1}
          sx={{
            background: theme.palette.background.paper,
            borderTop: 'none'
          }}
          ref={buttonRowRef}
        >
          <Grid container flexGrow={1} spacing={1}>
            {/* todo 장바구니 담기 버튼
            <Grid item xs="auto">
              <Button
                fullWidth
                disabled={quantity === 0}
                variant="outlined"
                onClick={onCartButtonClick}
                color={'dark'}
                sx={{ p: '5px', height: 46, minWidth: 46, width: 46 }}
              >
                <CartIcon color={theme.palette.text.primary} width={34} height={34} />
              </Button>
            </Grid>
            */}
            <Grid item xs>
              <Button
                fullWidth
                disabled={quantity === 0}
                variant="contained"
                color="brand"
                onClick={onOrderButtonClick}
                sx={{ height: 46, fontSize: 17, fontWeight: 800, letterSpacing: '-0.17px' }}
              >
                주문하기
              </Button>
            </Grid>

            {/* 선택상품 정보 펼치기/접기 [start] */}
            <Grid item xs="auto">
              <Box
                height="100%"
                // minWidth="48px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width={totalBottleCount > 0 ? 'auto' : 0}
              >
                <Box pl="8px" pr="4px">
                  <Button
                    onClick={() => {
                      setOrderInfoOpen((orderInfoOpen) => !orderInfoOpen);
                    }}
                    sx={{ p: 0, minWidth: 0, justifyContent: 'flex-end' }}
                  >
                    <Stack direction="row">
                      <Collapse orientation="horizontal" in={!orderInfoOpen} appear={navigationType !== 'PUSH'}>
                        <Box overflow="hidden" display="flex" alignItems="center" height="100%">
                          <Typography fontSize="12px" component="div" sx={{ height: '1rem', pr: '8px' }}>
                            총&nbsp;{totalBottleCount.toLocaleString()}
                            <span>병 선택</span>
                          </Typography>
                        </Box>
                      </Collapse>
                      <Box>
                        <ArrowCircleButton component="div" on={orderInfoOpen} />
                      </Box>
                    </Stack>
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* 선택상품 정보 펼치기/접기 [end] */}
          </Grid>
        </ButtonGroupWrapper>
      </Box>
      {/* </ClickAwayListener> */}
    </Box>
  );
}

BottomButtonSheet.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  orderItemMap: PropTypes.object,
  onCartButtonClick: PropTypes.func.isRequired,
  onOrderButtonClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  totalBottleCount: PropTypes.number
};

export default BottomButtonSheet;
// <Box
//   position={'absolute'}
//   left={-1}
//   right={-1}
//   bottom={buttonRowRef.current ? buttonRowRef.current.offsetHeight - 2 : 58}
//   px={0}
//   bgcolor={theme.palette.background.paper}
//   border={`1px solid ${alpha(theme.palette.brand.main, 0.1)}`}
//   borderBottom={0}
//   boxShadow="0 -2px 10px 0 rgba(0, 0, 0, 0.16)"
//   sx={{ borderTopLeftRadius: 24, borderTopRightRadius: 24 }}
//   zIndex={-1}
// >
const OrderProductBox = styled(Box)(({ theme }) => {
  const borderWidth = 1;
  return {
    position: 'absolute',
    left: `-${borderWidth}px`,
    right: `-${borderWidth}px`,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.background.paper,
    border: `${borderWidth}px solid ${alpha(theme.palette.brand.main, 0.1)}`,
    borderBottom: 'none',
    boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 0.16)',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24
  };
});

export const ButtonGroupWrapper = styled(Box)`
  padding-top: 8px;
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
`;
