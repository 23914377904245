import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// project imports
import { PRODUCT } from 'config';
import { useAmplitude, useScriptRef } from 'hooks';
import { getThemeList } from 'services/ContentService';
import { ProducerTypo, ProductCurrency, ProductName, ProductPrice, SpaceBetweenBox } from 'components';
import ReviewPointSimple from 'components/widgets/review/ReviewPointSimple';
import WishCountSimple from 'components/widgets/wish/WishCountSimple';
import ProductImage from './components';
import { ProductChipStack } from '../../../../mall/MallIndex/components/ProductList/components/ProductCard/components';
import { HomeContentHeader } from '../index';

// material-ui
import { Box, ButtonBase, Chip, Container, Grid, Typography } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { styled } from '@mui/material/styles';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './styles.scss';

// 테마 목록 초기 상태
const initialState = Object.freeze({
  loaded: false,
  error: false,
  title: '',
  show: false,
  list: []
});

// 테마 카드 가로 크기
const THEME_CARD_WIDTH = 318;
// 테마 상품 이미지 가로 크기
const THEME_PDATA_WIDTH = 107;
// 테마 상품 이미지 세로 크기
const THEME_PDATA_HEIGHT = 148;

/**
 * 테마 리스트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ThemeSection = () => {
  const scriptedRef = useScriptRef();
  const { logEvent } = useAmplitude();
  const navigate = useNavigate();

  // 테마 카드 정보 swiper
  const cardSwiperRef = React.useRef(null);

  let SWIPER_INDEX = 0;

  // chip swiper state
  const [chipSwiper, setChipSwiper] = React.useState(null);

  // 테마목록 정보
  const [themeList, setThemeList] = React.useState(initialState);

  const [swiperIndexState, setSwiperIndexState] = useLocationState();

  // 뒤로가기로 접근 시 swiper index 세팅
  React.useEffect(() => {
    if (swiperIndexState && typeof swiperIndexState === 'number' && chipSwiper) {
      SWIPER_INDEX = swiperIndexState;
      cardSwiperRef.current.swiper.slideTo(SWIPER_INDEX);
    }
  }, [swiperIndexState, chipSwiper]);

  React.useLayoutEffect(() => {
    if (!themeList.loaded) fetchThemeList();
  }, [themeList.loaded]);

  // 테마목록 조회
  const fetchThemeList: Promise<void> = React.useCallback(async () => {
    setThemeList(initialState);

    const themeData = await getThemeList().catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[ThemeSection][fetchMainContents] Unmounted component.');
      return;
    }

    // 테마 목록 조회 실패
    if (themeData.error) {
      console.error('[ThemeSection][fetchMainContents] themeData.error: ', themeData.error);
      setThemeList({ ...themeList, error: true });
      return undefined;
    }

    const { code, message } = themeData.result;

    // 잘못된 Response 값이 넘어옴.
    if (code !== 0) {
      console.error('[MainContents][fetchMainContents] ', message);
      setThemeList({ ...themeList, error: true });
      return undefined;
    }

    // 조회된 테마 목록
    const { title, list, show } = themeData.data;

    // 테마 노출 여부가 false인 경우
    if (!show) {
      console.warn('[MainContents][fetchMainContents] 테마 노출 여부가 false 입니다');
      return undefined;
    }

    setThemeList({ ...themeList, loaded: true, title, list, show });
  }, []);

  // 와인 클릭 이벤트
  const selectWine = (pdata) => {
    setSwiperIndexState(SWIPER_INDEX);

    const {
      id,
      keyword,
      on_promo,
      name: { ko },
      minimum
    } = pdata;

    // Amplitude Select Wine (home theme)
    logEvent.selectWine(`home theme main ${keyword}`, ko);

    // [PROD-176] 전지역에서 검색되도록 수정
    navigate(`/mall/wine/${id}?pin=${PRODUCT.BIT.ALL}`, {
      state: { pdata, priceMin: minimum ?? null, onPromo: on_promo > 0 }
    });
  };

  // render
  return (
    themeList.loaded &&
    !themeList.error &&
    themeList.show &&
    themeList.list.length > 0 && (
      <Box mt="64px">
        <Container>
          <HomeContentHeader title={themeList.title ?? '테마 상품'} />
        </Container>

        <Box>
          {/* 테마 칩 swiper */}
          <Swiper
            onSwiper={setChipSwiper}
            spaceBetween={8}
            slidesPerView="auto"
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="chipSwiper"
          >
            {themeList.list.map((item, index) => {
              const { keyword } = item;
              return (
                <SwiperSlide key={`theme-${index}-chip`} id={index}>
                  <Chip label={keyword} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* 테마 정보 swiper */}
          <Swiper
            ref={cardSwiperRef}
            slidesPerView="auto"
            centeredSlides={true}
            centeredSlidesBounds={true}
            spaceBetween={14}
            thumbs={{ swiper: chipSwiper }}
            modules={[Thumbs]}
            className="cardSwiper"
            onSlideChange={(e) => {
              chipSwiper.slideTo(e.activeIndex);
              // setLocationState(e.activeIndex);
              SWIPER_INDEX = e.activeIndex;
            }}
          >
            {themeList.list.map((item, index) => {
              const {
                id,
                keyword,
                theme_desc,
                pdata__id,
                pdata_producer,
                pdata_name,
                pdata_category,
                pdata_country,
                pdata_variety,
                pdata_type,
                price_minimum,
                pdata_star,
                pdata_reviews,
                pdata_wishes,
                on_promo
              } = item;

              // 상품 이미지 정보
              const bottle_img = item.pdata_bottle_img.thumb;

              // pdata 데이터 포맷
              const pdata = {
                keyword,
                id: pdata__id,
                name: pdata_name,
                category: pdata_category,
                country: pdata_country,
                variety: pdata_variety,
                type: pdata_type,
                on_promo,
                minimum: price_minimum
              };
              return (
                <SwiperSlide key={`theme-${index}-card`} style={{ width: THEME_CARD_WIDTH }}>
                  <Box>
                    {/* 테마 정보 */}
                    <Box
                      sx={{
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        bgcolor: '#9357E5',
                        p: '16px'
                      }}
                    >
                      {/* 키워드 & 전체보기 버튼 */}
                      <SpaceBetweenBox>
                        <Typography color="#fff" variant="subtitle1" lineHeight="normal">
                          {keyword}
                        </Typography>
                        <ButtonBase sx={{ bgcolor: '#A27BE3', opacity: 0.8, padding: '4px 9px', borderRadius: '16px' }}>
                          <Box display="flex" alignItems="center" spacing={2}>
                            <Typography
                              color="#fff"
                              variant="subtitle2"
                              fontSize="13px"
                              lineHeight="normal"
                              onClick={() => {
                                setSwiperIndexState(SWIPER_INDEX);
                                navigate(`/home/theme/${id}`, { state: { title: themeList?.title ?? '' } });
                              }}
                            >
                              상품 전체 보기
                            </Typography>
                            <ArrowForwardIosOutlinedIcon sx={{ fontSize: '12px', ml: '4px', color: '#fff' }} />
                          </Box>
                        </ButtonBase>
                      </SpaceBetweenBox>

                      {/* 테마 설명 */}
                      <Box mt="14px">
                        <ThemeDescText>{theme_desc}</ThemeDescText>
                      </Box>
                    </Box>

                    {/* 테마 상품 정보 */}
                    <ButtonBase
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        border: '1px solid #9357E5',
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px',
                        py: '12px',
                        pr: '14px'
                      }}
                      onClick={() => selectWine(pdata)}
                    >
                      {/* 상품 이미지 */}
                      <ProductImage width={THEME_PDATA_WIDTH} height={THEME_PDATA_HEIGHT} src={bottle_img} alt={'test'} />

                      {/* 상품 정보 */}
                      <Box
                        flexGrow={1}
                        p="7px"
                        ml="5px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        textAlign="left"
                      >
                        {/* 생산자 (한글) */}
                        <ProducerTypo>
                          {pdata_producer?.ko ? pdata_producer.ko : <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>}
                        </ProducerTypo>

                        {/* 상품명 (한글) */}
                        <ProductName>{pdata_name?.ko}</ProductName>

                        <Box sx={{ py: 0, height: 45 }}>
                          {/* 최저 상품가격 */}
                          <Box pt={1} pb={0.5}>
                            {price_minimum ? (
                              <Box display="flex" alignItems="center">
                                <ProductPrice>{price_minimum?.toLocaleString()}</ProductPrice>
                                <ProductCurrency multiple pl="1px" />
                              </Box>
                            ) : (
                              <Typography component="span" fontSize="11px" color="text.secondary" sx={{ py: '4px' }}>
                                [ 상품 입고가 곧 준비됩니다 ]
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        {/* 상품 별점/평점 */}
                        <Box pb="6px">
                          <Grid container display="flex" alignItems="center" spacing={1}>
                            <Grid item xs="auto">
                              {/* 리뷰 점수 */}
                              <ReviewPointSimple point={pdata_star} count={pdata_reviews} />
                            </Grid>
                            <Grid item xs="auto">
                              {/* 좋아요 개수 */}
                              <WishCountSimple count={pdata_wishes} />
                            </Grid>
                          </Grid>
                        </Box>

                        {/* 상품 tag chips[start] */}
                        <Box className="none-scroll-bar" sx={{ maxWidth: '100%', height: 24, width: '100%', overflowX: 'auto' }}>
                          <ProductChipStack pdata={pdata} />
                        </Box>
                        {/* 상품 tag chips[end] */}
                      </Box>
                    </ButtonBase>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>
    )
  );
};

export default ThemeSection;

// 테마 설명
const ThemeDescText = styled((props) => <Typography {...props} />)(() => ({
  color: '#fff',
  fontSize: '14px',
  lineHeight: 1.3,
  height: '2.2rem',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-all'
}));
