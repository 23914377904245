import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// project imports
import { PACKING_OPTIONS } from 'config';
import ProductImage from 'components/product/ProductImage';
import { Calculator } from 'utils/bourgogne-tools';
import WishButton from 'components/buttons/WishButton';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ProductCurrency, ProductName, ProductPrice } from 'components/product';

// material-ui
import { Box, FormControl, MenuItem, Select, Stack, TextField as MuiTextField, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

const IMG_WIDTH = 95;
const IMG_HEIGHT = 148;
const spacing = '12px';

const COMMENT_MAX_LEN = 40;

/**
 * 주문상품 아이템
 * @returns {JSX.Element}
 * @constructor
 */
function OrderProduct({ orderItem, orderOption, onOptionChange }) {
  const theme = useTheme();

  const {
    product // 판매상품 정보
  } = orderItem;

  const {
    pdata // 상품원본 정보
  } = product;
  // console.log('#orderOption: ', orderOption);

  const [option, setOption] = React.useState(orderOption);

  React.useLayoutEffect(() => {
    setOption(orderOption);
  }, [orderOption]);

  const handleOption = (field, value) => {
    const _option = { ...option };
    _option[field] = value;
    setOption(_option);
    onOptionChange(product._id, _option, field);
  };

  const {
    quantity, // 주문수량
    packing, // 포장옵션
    comment // 요청사항
  } = option;

  // 용량 표기
  const capacityStr = useMemo(() => {
    return Calculator.formatCapacity(product.capacity);
  }, [product.capacity]);

  // 상품 이미지 썸네일
  const bottleThumbnailSrc = useMemo(() => {
    return pdata.bottle_img ? pdata.bottle_img.thumb : wineDefaultImg;
  }, [pdata.bottle_img]);

  // render
  return (
    <Box py={2}>
      <Box display={'flex'}>
        {/* 구매상품 이미지 */}
        <Box width={IMG_WIDTH} height={IMG_HEIGHT} sx={{ mr: spacing }}>
          <ProductImage width={'100%'} height={'100%'} src={bottleThumbnailSrc} alt={pdata.name.ko} />
        </Box>

        {/* 구매상품 정보 */}
        <Box display="flex" flexDirection="column" justifyContent="space-between" width={`calc( 100% - ${IMG_WIDTH}px - ${spacing} )`}>
          <Box>
            <SpaceBetweenBox sx={{ height: 20 }}>
              <Typography variant="caption" fontSize={11} fontWeight={800} letterSpacing="-0.11px">
                {pdata?.producer?.ko ? pdata.producer.ko : <span>1KMWINE</span>}
              </Typography>
              {/* Wish 포함여부 */}
              {pdata && (
                <WishButton
                  pdataId={pdata.id ?? pdata._id}
                  pdataName={pdata.name.ko}
                  onWishChange={(isWish) => {
                    console.log('추가상훔 찜하기 여부: ', isWish);
                  }}
                />
              )}
            </SpaceBetweenBox>
            {/* 상품명 */}
            <ProductName sx={{ pt: 0.25 }}>{pdata.name.ko}</ProductName>
            {/* 빈티지 / 용량 */}
            <Box>
              <Stack
                direction="row"
                spacing="6px"
                divider={
                  <Box display="flex" alignItems="center" pb="1px">
                    <Box height={'9px'} width={'1px'} bgcolor={alpha(theme.palette.text.primary, 0.1)} />
                  </Box>
                }
                sx={{ fontSize: 11, fontWeight: 300, color: 'text.secondary' }}
              >
                {product.vintage && product.vintage !== 'NV' && <Typography variant="caption">{`${product.vintage}년`}</Typography>}
                <Typography variant="caption">{capacityStr}</Typography>
              </Stack>
            </Box>
          </Box>

          <Box>
            <Box display="flex" alignItems="center">
              <ProductPrice>{(product.price.original * quantity).toLocaleString()}</ProductPrice>
              <ProductCurrency multiple={false} pl="1px" />
            </Box>
            <Box>
              <Typography component={'span'} color="text.secondary" fontSize="16px">
                수량 · {quantity.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* 옵션 */}
      <Box display="none">
        <FormControl>
          <Select
            value={packing}
            displayEmpty
            onChange={(e) => {
              handleOption('packing', e.target.value);
            }}
          >
            {/* 상품수령옵션[start] */}
            {PACKING_OPTIONS.map(({ value, informal }, i) => (
              <MenuItem key={`packing-opts-${i}`} value={value} disabled>
                <Typography fontSize={12}>{informal}</Typography>
              </MenuItem>
            ))}
            {/* 상품수령옵션[end] */}
          </Select>
        </FormControl>
      </Box>

      <Box mt="8px">
        <FormControl
          fullWidth
          size="small"
          sx={{ '& .MuiInputBase-root': { height: 42 }, '& .MuiTypography-root': { lineHeight: '42px' } }}
        >
          <MuiTextField
            hiddenLabel
            id={`order-item-comment-${product._id}`}
            fullWidth
            autoComplete="off"
            value={comment}
            onChange={(e) => {
              handleOption('comment', e.target.value);
            }}
            onBlur={(e) => {
              handleOption('comment', `${e.target.value}`.trim());
            }}
            // placeholder={`추가요청사항: (ex. 선물포장 해주세요, 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
            // 2023.11.23 모젤 크리스마스 주문 시 선물포장이 많이 들어오는 이슈로 잠시 숨김처리
            placeholder={`추가요청사항: (ex. 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
            inputProps={{ maxLength: COMMENT_MAX_LEN }}
            sx={{
              '& .MuiInputBase-input::-webkit-input-placeholder': {
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#140229'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '5px',
                backgroundColor: 'transparent',
                borderWidth: '1px !important'
              }
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

OrderProduct.propTypes = {
  orderItem: PropTypes.object.isRequired,
  orderOption: PropTypes.object.isRequired,
  onOptionChange: PropTypes.func
};

export default OrderProduct;
