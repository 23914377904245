import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function MarkedText({ label, bgHeight = '13px' }) {
  return (
    <Box component="span" className="marked-text">
      <Box component="span" className="marked-text__label">
        {label}
      </Box>
      <Box component="span" className="marked-text__bg" height={bgHeight} />
    </Box>
  );
}

MarkedText.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default MarkedText;
