import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';

// material-ui
import { Box, ButtonBase, CircularProgress, Container, Dialog, Fab, Slide, Toolbar } from '@mui/material';

// project imports
import UserLocationButton from '../UserLocationButton';
import { PinMap } from 'components/widgets/UserLocationSheet/components';
import { WoAlert } from 'utils/kmwine-alerts';

// imports assets
import ArrowBackImg from 'assets/images/arrow-back.png';
import mapBtn from 'assets/images/ic_header_map.png';

/**
 * 매장(vendor) 화면 레이아웃
 * @param {string} title 헤더 제목
 * @param {boolean} showBottomNav 하단 네비게이션 표현여부
 * @constructor 최효근 <hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function VendorLayout({ title = '와인샵', children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const PIN_MAP_OPEN_HASH = '#pinmap-open';

  // 사용자 위치정보, 서비스 상수
  const { userLocation } = useSelector((state) => state);

  // 핀선택지도 열기
  const openPinMap = () => {
    navigate(PIN_MAP_OPEN_HASH, { state: location.state });
  };

  // 핀선택지도 열림여부
  const pinMapOpen = React.useMemo(() => location.hash === PIN_MAP_OPEN_HASH, [location.hash]);

  // 핀선택지도 닫기
  const handlePinMapDialogClose = () => {
    if (location.hash === PIN_MAP_OPEN_HASH) navigate(-1); // 중복클릭 방지
  };

  // 핀선택변경
  const onPinConfirmed = (result) => {
    if (!result.success) {
      WoAlert.fire(`위치 설정 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {});
    } else {
      handlePinMapDialogClose(); // 핀 지도 닫기
    }
  };

  // '사용자 위치' 설정 버튼
  const userLocationButton = React.useMemo(
    () => (!userLocation.loading ? <UserLocationButton onClick={openPinMap} /> : <CircularProgress color="brand" size={12} />),
    [userLocation]
  );

  // 핀 지도 버튼
  const pinMapButtonComponent = React.useMemo(() => (
    <ButtonBase
      sx={{ width: 34, height: 34 }}
      onClick={() => {
        navigate('/map/vendor');
      }}
    >
      <Box component="img" src={mapBtn} sx={{ width: 34, height: 34 }} />
    </ButtonBase>
  ));

  // render
  return (
    <Box minHeight="calc(var(--vh, 1vh) * 100)">
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | ${title}`} />
      <Container maxWidth="xs" sx={{ px: [0, 0] }}>
        <Toolbar>
          <Box component="section" height="100%" width="100%" display="flex" justifyContent="space-between">
            {/* 핀선택지도 */}
            <Box height={(theme) => theme.components.MuiAppBar.styleOverrides.root.minHeight} display="flex" alignItems="center" top={0}>
              <Box minWidth={100} maxWidth="45vw">
                {userLocationButton}
              </Box>
            </Box>
          </Box>

          {/* 핀 지도 버튼 */}
          <Box>{pinMapButtonComponent}</Box>
        </Toolbar>
      </Container>

      <main>{children}</main>

      {/* 핀선택 지도 */}
      <Dialog
        open={pinMapOpen}
        scroll="paper"
        fullScreen
        TransitionComponent={PinMapTransition}
        keepMounted
        onClose={handlePinMapDialogClose}
        aria-describedby="pin-select-map-dialog-title"
      >
        <Fab
          color="white"
          onClick={handlePinMapDialogClose}
          aria-label="위치설정 닫기"
          sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
        >
          <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} alt="" />
        </Fab>
        {pinMapOpen && <PinMap height={`calc(var(--vh, 1vh) * 100`} onPinConfirmed={onPinConfirmed} />}
      </Dialog>
    </Box>
  );
}

VendorLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default VendorLayout;

// 직접위치설정 dialog transition.
/* eslint-disable react/prop-types */
const PinMapTransition = React.forwardRef(function Transition(props, ref) {
  const { children, ...other } = props;
  return (
    <Slide {...other} ref={ref} direction="left">
      {children}
    </Slide>
  );
});
