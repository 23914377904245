// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import wineOneReducer from './slices/wine-one';
import alarmReducer from './slices/alarm';
import cartReducer from './slices/cart';

import backdropReducer from './slices/backdrop';
import snackbarReducer from './slices/snackbar';
import userLocationReducer from './slices/user-location';
import woConstantReducer from './slices/wo-constants';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  alarm: alarmReducer,
  backdrop: backdropReducer,
  cart: cartReducer,
  snackbar: snackbarReducer,
  userLocation: persistReducer(
    {
      key: 'user-location',
      storage
    },
    userLocationReducer
  ),
  wineOne: persistReducer(
    {
      key: 'wineOne',
      storage
    },
    wineOneReducer
  ),
  woConstants: persistReducer(
    {
      key: 'wo-const',
      storage
    },
    woConstantReducer
  )
});

export default reducer;
