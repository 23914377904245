import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { Calculator, Strings } from 'utils/bourgogne-tools';
import { OrderAddIcon, OrderCheckedIcon, ProductLabel, ReviewPointSimple, WishButton, WishCountSimple } from 'components';
import QuantityBox from './QuantityBox';
import ProductImage from './ProductImage';

// material-ui
import { Box, Button, ButtonBase, Grid, ListItem, Skeleton, Stack, Typography } from '@mui/material';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';
import CircleIcon from '@mui/icons-material/Circle';
import { ProductChipStack } from '../../../mall/MallIndex/components/ProductList/components/ProductCard/components';
import soldOutIcon from '../assets/product list_add product btn_off.png';

const IMG_WIDTH = 96; // 상품 썸네일 이미지 너비
const IMG_HEIGHT = 144; // 상품 썸네일 이미지 높이

/**
 * 프로모션 리스트 상품카드
 *
 * @param i
 * @param productId
 * @param productProp
 * @param orderItemMap
 * @param onClick
 * @param imgOnClick
 * @param quantityOnClick
 * @returns {JSX.Element}
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function ProductCard({
  index: i,
  productId,
  product,
  orderItemMap = {},
  onClick = () => false,
  quantityOnClick = () => {},
  imgOnClick = () => false,
  isStart,
  notStartClick
}) {
  const { pdata } = product;

  const orderItem = React.useMemo(() => {
    return orderItemMap[productId];
  }, [orderItemMap]);

  // 상품 추가여부
  const addedOrderItem = !!orderItem;

  // 최대 주문가능 개수
  const findLimitCnt = React.useMemo(() => {
    if (product.limit_cnt === 0) return product.quantity;

    if (product.limit_cnt) {
      if (product.quantity >= product.limit_cnt - product.order_cnt) {
        return product.limit_cnt - product.order_cnt;
      }
      return product.quantity;
    }
  }, [product]);

  // 상품 품절여부
  const isSoldOut = React.useMemo(() => {
    if (product.quantity === 0) return true;
    return false;
  }, [product]);

  // tag 문자열
  const tagStrings = React.useMemo(() => Strings.pdataTagStrings(pdata), [pdata]);

  // render
  return (
    <ListItem key={`promotion-product-${i}`} sx={{ px: '18px', py: '8px', position: 'relative' }}>
      {/* <Box position="absolute" right="0" top={0}> */}
      {/*   <WishButton aria-label="위시 와인에 추가" pdataId={pdata.id ?? pdata._id} pdataName={pdata?.name?.ko ?? ''} /> */}
      {/* </Box> */}
      <Box component="section" height="154px" display="flex" alignItems="flex-start" flexGrow={1} maxWidth="100%">
        {/* 추가/제외 버튼 */}
        <Box
          position="absolute"
          left={-12}
          top="50%"
          zIndex={1}
          onClick={() => {
            if (!product) {
              console.warn(`상품정보가 아직 로드되지 않았습니다. [id=${productId}]`);
              return undefined;
            }
            if (!isStart) {
              notStartClick();
              return;
            }
            if (findLimitCnt < 1) {
              return;
            }
            onClick(product);
          }}
          sx={{ transform: 'translateY(-50%)', opacity: 1 }}
        >
          {findLimitCnt < 1 ? (
            <Box width="100%" height="100%" component="img" src={soldOutIcon} />
          ) : addedOrderItem ? (
            <OrderCheckedIcon />
          ) : (
            <OrderAddIcon />
          )}
        </Box>

        <Box sx={{ mt: 0 }} width={IMG_WIDTH}>
          <ButtonBase
            sx={{ width: '100%', height: '100%' }}
            onClick={() => {
              if (pdata) imgOnClick(pdata);
            }}
          >
            <ProductImage
              width="107px"
              height="154px"
              visibleByDefault
              src={pdata.bottle_img?.thumb ?? wineDefaultImg}
              alt={'상품'}
              vivino={product?.product?.pdata?.score?.vivino}
            >
              {product.limit_cnt !== 0 && (
                <Box
                  borderRadius="6px"
                  position="absolute"
                  top={8}
                  minWidth="40px"
                  left={8}
                  height="20px"
                  bgcolor="rgba(147, 87, 229, 0.55)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px="4px"
                >
                  <Typography fontSize="10px" color="#FFFFFF" fontWeight="bold">
                    1인 {product.limit_cnt}병
                  </Typography>
                </Box>
              )}
              <ProductLabel height={26} fontSize={9}>
                상품 상세 보기
              </ProductLabel>
            </ProductImage>
          </ButtonBase>
        </Box>

        <Box
          width={`calc(100% - ${IMG_WIDTH}px)`}
          ml="16px"
          mr="12px"
          height="144px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" marginTop="2px">
            <Box display="flex" justifyContent="space-between">
              <Box>
                {pdata.producer?.ko ? (
                  <Typography
                    lineHeight="12px"
                    fontSize="11px"
                    fontWeight="800"
                    sx={{ marginBottom: '7px', opacity: findLimitCnt < 1 && '.5' }}
                  >
                    {pdata.producer?.ko}
                  </Typography>
                ) : (
                  <Typography
                    lineHeight="12px"
                    fontSize="11px"
                    fontWeight="800"
                    sx={{ marginBottom: '7px', opacity: findLimitCnt < 1 && '.5' }}
                  >
                    1KMWINE
                  </Typography>
                )}
              </Box>
            </Box>

            {/* 상품명 */}
            <Box height="32px" sx={{ fontSize: '14px', lineHeight: '14px', letterSpacing: '0.2px', fontWeight: '400' }}>
              <Typography
                fontSize="inherit"
                lineHeight="inherit"
                letterSpacing="inherit"
                fontWeight="inherit"
                sx={{
                  color: findLimitCnt < 1 ? 'rgba(20, 2, 41, 0.50)' : 'rgba(20, 2, 41, 0.70)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  opacity: 0.8
                }}
              >
                {pdata?.name?.ko}
              </Typography>
            </Box>
            <Box mt="4px" mb="4px">
              {/* 상품 vintage, 용량 */}
              <Box display="flex" alignItems="center" mt="4px">
                {product.vintage !== 'NV' && (
                  <>
                    <Typography color="rgba(20, 2, 41, 0.70)" fontSize="11px" fontWeight="400">
                      {product.vintage}년
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="3px"
                      height="100%"
                      marginLeft="3px"
                      marginBottom="2px"
                    >
                      <Box
                        style={{
                          width: '1px',
                          height: '9px',
                          border: '1px solid rgba(20, 2, 41, 0.10)'
                        }}
                      />
                    </Box>
                  </>
                )}

                <Typography
                  color="rgba(20, 2, 41, 0.70)"
                  fontSize="11px"
                  fontWeight="400"
                  sx={{ marginLeft: product.vintage !== 'NV' ? '3px' : 0 }} // NonVintage의 경우 ml 0
                >
                  {Calculator.formatCapacity(product.capacity)}
                </Typography>

                {pdata.score.vivino !== 0 && pdata.score.vivino !== null && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="3px"
                      height="100%"
                      marginX="3px"
                      marginBottom="2px"
                    >
                      <Box
                        style={{
                          width: '1px',
                          height: '9px',
                          border: '1px solid rgba(20, 2, 41, 0.10)'
                        }}
                      />
                    </Box>
                    <Typography color="rgba(161, 17, 34, 0.70)" fontSize="11px" fontWeight="400">
                      VIVINO {pdata.score.vivino}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Box width="100%">
            {/* 상품 가격 */}
            <Box mb={addedOrderItem && '5px'}>
              {isSoldOut ? (
                <Box
                  border="1.5px solid rgba(96, 84, 110, 0.10)"
                  borderRadius="5px"
                  width="38px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ backgroundColor: 'rgba(20, 2, 41, 0.03)' }}
                >
                  <Typography color="rgba(20, 2, 41, 0.60)" fontSize="12px" fontWeight="700">
                    품절
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography lineHeight="22px" fontSize="22px" fontWeight="400" fontFamily="D-DIN" color="#140229">
                    {product.price.sale.toLocaleString()}
                  </Typography>
                  <Typography lineHeight="16px" fontSize="16px" fontWeight="300" color="#140229" sx={{ ml: '1px', mt: '3px' }}>
                    원
                  </Typography>
                </Box>
              )}
            </Box>

            {/* 수량 박스 */}
            {addedOrderItem ? (
              <Box width="129px" mt="7px">
                <QuantityBox
                  boxHeight={35}
                  disabled={product.quantity === 0}
                  max={findLimitCnt}
                  value={orderItemMap[productId]?.quantity}
                  onChange={(val) => {
                    quantityOnClick(orderItem, val);
                  }}
                />
              </Box>
            ) : (
              <>
                {/* 상품 별점/평점 */}
                <Box mb="6px" display="block" mt="4px">
                  <Grid container display="flex" alignItems="center" columnSpacing={1} rowSpacing={0.5}>
                    <Grid item xs="auto">
                      {/* 리뷰 점수 */}
                      <ReviewPointSimple point={pdata?.star} count={pdata?.reviews} fontWeight={400} />
                    </Grid>
                    <Grid item xs="auto">
                      {/* 좋아요 개수 */}
                      <WishCountSimple count={pdata.wishes} fontWeight={400} />
                    </Grid>
                  </Grid>
                </Box>
                {/* 상품 tag chips[start] */}
                <Box
                  className="none-scroll-bar"
                  display={'block'} // v1.0.9
                  sx={{ maxWidth: '100%', height: 24, width: '100%', overflowX: 'auto' }}
                >
                  <ProductChips pdata={pdata} />
                </Box>
                {/* 상품 tag chips[end] */}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}

ProductCard.propTypes = {
  index: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  orderItemMap: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  imgOnClick: PropTypes.func.isRequired,
  quantityOnClick: PropTypes.func.isRequired,
  product: PropTypes.object,
  isStart: PropTypes.bool,
  notStartClick: PropTypes.func
};

export default ProductCard;

// eslint-disable-next-line react/prop-types
const ProductChips = React.memo(({ pdata }) => <ProductChipStack pdata={pdata} />);
