import React from 'react';
import PropTypes from 'prop-types';

// components
import { TextFieldSearchIcon } from 'components/icons';

// material-ui
import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import buttonTextDelImg from 'assets/images/btn_text_del@3x.webp';

/**
 *
 * @param {boolean} autoFocus
 * @param {boolean} readOnly
 * @param {string} color
 * @param {function} onClick
 * @param {function} onInput
 * @param {function} onBlur
 * @param {string} searchQuery
 * @param {string} placeholder
 * @param {number} maxLength
 * @param inputRef
 * @returns {SearchField}
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function SearchField({
  autoFocus = false,
  readOnly = false,
  color = 'brand',
  onClick = () => false,
  onInput = () => false,
  onBlur = () => false,
  value: searchQuery,
  placeholder = '',
  maxLength = 40,
  inputRef = null
}) {
  return (
    <SearchFieldBase
      fullWidth
      readOnly={readOnly}
      color={color}
      autoComplete="wine-name"
      autoFocus={autoFocus}
      onClick={onClick}
      onInput={onInput}
      onBlur={onBlur}
      value={searchQuery}
      placeholder={placeholder}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TextFieldSearchIcon height={26} width={26} />
          </InputAdornment>
        ),
        'aria-label': '검색',
        inputProps: {
          maxLength,
          type: readOnly ? 'text' : 'search',
          role: readOnly ? 'text' : 'search',
          readOnly
        }
      }}
    />
  );
}

SearchField.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  inputRef: PropTypes.any
};

export default SearchField;

const SearchFieldBase = React.forwardRef(function SearchFieldBase(props, ref) {
  return <StyledTextField {...props} ref={ref} />;
});

const StyledTextField = styled(TextField)(
  ({ theme }) => `
  & .MuiInputBase-input {
    padding: ${theme.spacing(1)};
    padding-left: 0;
    height: 26px;
    font-size: 14px;
    font-weight: 700;
    
    ::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      margin: 3px 0 3px 10px;
      background: url(${buttonTextDelImg}) no-repeat 50% 50%;
      background-size: contain;
    }
    
    ::placeholder {
      font-weight: normal;
    }
  }
  
  & .MuiOutlinedInput-root {
    border-radius: ${theme.shape.borderRadius}px;
    padding-left: ${theme.spacing(1)};
  }
  
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-color: #1C1C1C00;
    background-color: #1C1C1C0A;
    border-width: 1px;
  }
`
);
