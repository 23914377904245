import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const StyledInputBase = styled((props) => <TextField variant="outlined" size="small" {...props} />)(({ theme }) => ({
  '& .MuiInputBase-input': {
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    transition: theme.transitions.create('width')
  }
}));

function SearchInput({
  type = 'search',
  autoComplete = '',
  color = null,
  autoFocus = false,
  fullWidth = false,
  placeholder = '',
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  ...other
}) {
  // console.log('@placeholder: ', placeholder);
  return (
    <StyledInputBase
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      color={color}
      type={type}
      fullWidth={fullWidth}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      {...other}
    />
  );
}

SearchInput.propTypes = {
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
};

export default SearchInput;
