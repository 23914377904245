import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Badge, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';

// assets
import CartImg from 'assets/images/ic_order_cart.png';
import { useSelector } from '../../store';
import { useAmplitude } from '../../hooks';
import { CartIcon } from '../icons';

/**
 * 장바구니 버튼
 *
 * @param {string} color
 * @param {boolean} showBadge
 * @param {'end'|'start'|false} edge
 * @param other
 */
function CartButton({ color: colorProp, count = 0, showBadge = false, edge = false, ...other }) {
  const navigate = useNavigate();
  const { logEvent } = useAmplitude();
  const location = useLocation();

  const openCartDrawer = () => {
    const path = location.pathname;

    if (path === '/home') {
      logEvent.openCart('홈페이지');
    }
    if (path === '/mall') {
      logEvent.openCart('와인검색');
    }
    if (path.includes('/mall/wine')) {
      logEvent.openCart('주문 가능 와인샵');
    }
    if (path === '/mall') {
      logEvent.openCart('와인샵');
    }
    if (path.includes('/vendor/d')) {
      logEvent.openCart('와인샵 상세');
    }
    if (path === '/wish') {
      logEvent.openCart('위시');
    } else if (path === '/my') {
      logEvent.openCart('마이');
    } else if (path.includes('/pos/campaign/list')) {
      logEvent.openCart('와인픽스 특가');
    }

    navigate('/my/cart');
  };

  // 장바구니 정보
  const cart = useSelector((state) => state.cart);

  // render
  return (
    <ButtonBase
      aria-label="장바구니"
      edge={edge}
      onClick={() => {
        openCartDrawer();
      }}
      {...other}
    >
      <Badge
        color="secondary"
        invisible={!showBadge || !cart?.totalItems}
        badgeContent={cart.totalItems}
        overlap="circular"
        max={99}
        sx={{
          '& .BaseBadge-badge': {
            top: '11px',
            right: cart.totalItems < 99 ? '9px' : '5px',
            fontFamily: 'D-DIN-Bold,D-DIN',
            fontSize: '11px',
            minWidth: cart.totalItems < 99 ? '14px' : '22px',
            maxWidth: cart.totalItems < 99 ? '14px' : '22px',
            minHeight: '14px',
            maxHeight: '14px'
          }
        }}
      >
        <CartIcon color={colorProp ?? '#140229'} />
      </Badge>
    </ButtonBase>
  );
}

CartButton.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  showBadge: PropTypes.bool,
  edge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default CartButton;

const ButtonBase = styled((props) => <IconButton {...props} />)`
  padding: 0;
  margin-left: 13px;
`;

/* eslint-disable react/prop-types */
export function CartOutlinedButton({ onClick }) {
  return (
    <IconButton aria-label="장바구니" sx={{ border: '1px solid #e7e4eb', borderRadius: '5px', p: '5px' }} onClick={onClick}>
      <Box component="img" src={CartImg} sx={{ width: '34px', height: '34px' }} />
    </IconButton>
  );
}
