import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { CloseIcon } from 'components/icons';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    height: 78,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  }
});

function WoDialogTitle({ title, tools = [], onClickClose = () => {}, wrapperProps = {} }) {
  const woDialogTitleStyle = useStyles();

  return (
    <DialogTitle component="div" className={woDialogTitleStyle.root} {...wrapperProps}>
      <IconButton className="DialogTitleCloseBtn" onClick={onClickClose} sx={{ p: 0, ml: '-8px' }}>
        <CloseIcon />
      </IconButton>
      <Typography
        variant="dialogTitle"
        sx={{ position: 'absolute', height: '20px', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}
      >
        {title}
      </Typography>
      <Box flexGrow={1} />

      {tools.map((tool, i) => (
        <React.Fragment key={`wo-dialog-title-tool-${i}`}>{tool}</React.Fragment>
      ))}
    </DialogTitle>
  );
}

WoDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tools: PropTypes.array,
  onClickClose: PropTypes.func,
  wrapperProps: PropTypes.object
};

export default WoDialogTitle;
