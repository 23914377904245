import { AppBar, Box, Container, Dialog, Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

/**
 * 반품,교환,환불 안내 다이얼로그
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
/* eslint-disable react/prop-types */
function RefundDialog({ openCancelDialog, setOpenCancelDialog }) {
  const theme = useTheme();

  return (
    <Dialog open={openCancelDialog} fullScreen>
      <AppBar elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpenCancelDialog(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            반품/교환/환불 안내
          </Typography>
        </Toolbar>
      </AppBar>
      {/* 56 + 16 */}
      <Box mt="72px" bgcolor="#ffffff" pb="33vh">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Container>
              <Typography variant="subtitle1" fontWeight="bold">
                반품, 교환, 환불 정보
              </Typography>
              <Box mt={1}>
                <Stack direction="column" spacing={0.5}>
                  <div>&bull; 단순변심에 의한 상품의 반품·교환은 제품 수령 후 7일 이내까지 가능합니다. </div>
                  <div>
                    &bull; 상품 등의 내용이 표시 + 광고의 내용과 다르거나 계약내용과 다르게 이행된 경우 반품·교환은 상품을 수령한 날로부터
                    3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 가능합니다.
                  </div>
                  <div>
                    &bull; 반품·교환에 대한 구체적인 문의사항이 있는 경우 1KMWINE CS담당자 이메일로 문의 부탁드립니다.(e-mail :
                    cs@wineone.io)
                  </div>
                  <div>
                    &bull; 1KMWINE은 고객의 반품으로 상품 등의 대금을 환불할 때 고객이 『여신전문금융업법』 제2조 제3호에 따른 신용카드나 그
                    밖에 「전자상거래 등에서의 소비자 보호에 관한 법률」(이하 “전자상거래법＂이라 한다) 시행령 제22조에서 정하는
                    결제수단으로 상품 등의 대금을 지급한 경우에는 지체 없이 해당 결제수단을 제공한 사업자(이하 “결제업자＂이라 한다)에게
                    상품 등의 대금 청구를 정지하거나 취소하도록 요청합니다.
                  </div>
                  <div>
                    &bull; 1KMWINE이 결제업자로부터 해당 상품 등의 대금을 이미 받은 때에는 지체 없이 그 대금을 결제업자에게 환급하고, 그
                    사실을 소비자에게 통보합니다.
                  </div>
                  <div>
                    &bull; 환급이 지연되어 고객이 대금을 결제하게 된 경우 1KMWINE은 그 지연기간에 대한 지연배상금을 고객에게 지급해야 하며,
                    고객은 1KMWINE이 정당한 사유 없이 결제업자에게 대금을 환급하지 아니하는 경우 결제업자에게 1KMWINE에 대한 다른 채무와
                    1KMWINE에게 환급 받을 금액의 상계를 요청할 수 있습니다.
                  </div>
                </Stack>
              </Box>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Container>
              <Typography variant="subtitle1" fontWeight="bold">
                반품, 교환비
              </Typography>
              <Box mt={1}>
                <Stack direction="column" spacing={0.5}>
                  <div>
                    &bull; 반품·교환비는 고객의 단순 변심에 의한 반품·교환의 경우에만 발생하며, 반품·교환에 따른 배송비를 의미합니다.
                  </div>
                  <div>
                    &bull; 상품을 구매처에 직접 가져다 주어야 하며 고객의 사정으로 택배로 발송을 해야 한다면 그에 따른 배송료는 고객이
                    지불합니다.
                  </div>
                </Stack>
              </Box>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Container>
              <Typography variant="subtitle1" fontWeight="bold">
                반품이 불가능한 경우
              </Typography>
              <Box mt={1}>
                <Stack direction="column" spacing={0.5}>
                  <div>&bull; 전자상거래법 제17조에 따라 다음에 해당하는 경우 단순변심에 의한 상품 반품은 불가능합니다.</div>
                  <div>
                    &bull; 고객에게 책임이 있는 사유로 상품 등이 멸실되거나 훼손된 경우 (단, 상품 등의 내용을 확인하기 위하여 포장 등을
                    훼손한 경우는 제외)
                  </div>
                  <div>&bull; 고객의 사용 또는 일부 소비로 상품 등 가치가 현저히 감소한 경우</div>
                  <div>&bull; 시간이 지나 다시 판매하기 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우</div>
                  <div>&bull; 같은 성능을 지닌 상품 및 용역으로 복제가 가능하고 그 원본을 훼손한 경우</div>
                  <div>&bull; 고객의 단순변심으로 인한 교환·반품 요청이 상품을 수령한 날로부터 7일을 경과한 경우</div>
                  <div>&bull; 회사가 청약철회 제한에 대해 사전 고지한 경우</div>
                  <div>&bull; 기타 ‘전자상거래법’이 정하는 청약철회 제한 사유에 해당하는 경우 상품 반품은 불가능 합니다.</div>
                </Stack>
              </Box>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Container>
              <Typography variant="subtitle1" fontWeight="bold">
                소비자피해 보상의 처리
              </Typography>
              <Box mt={1}>
                <Stack direction="column" spacing={0.5}>
                  <div>
                    &bull; 상품에 대한 소비자 피해보상 등은 소비자분쟁해결 기준(공정거래 위원회 고시) 및 1KMWINE 약관에 따라 받으실 수
                    있습니다.
                  </div>
                  <div>
                    &bull; 거래에 관한 약관의 내용은 1KMWINE 홈페이지(www.1kmwine.io) 혹은 1KMWINE APP 안에 있는 MY>약관 및 정책 내
                    이용약관을 참고 부탁 드립니다.
                  </div>
                </Stack>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default RefundDialog;
