import React from 'react';
import { default as ReactSwitch } from 'react-switch';
import { useTheme } from '@mui/material/styles';

/**
 * 1KMWINE 스타일 스위치(Switch)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Switch(props) {
  const theme = useTheme();
  return (
    <ReactSwitch
      handleDiameter={21}
      width={53}
      height={27}
      onColor={theme.palette.brand.main}
      offColor="#a69ab5"
      onHandleColor="#defd9a"
      offHandleColor="#ffffff"
      checkedIcon={false}
      uncheckedIcon={false}
      activeBoxShadow="none"
      {...props}
    />
  );
}

export default Switch;
