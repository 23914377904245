import React from 'react';

// assets
import { IconButton } from '@mui/material';
import { ArrowBackIcon } from '../icons';

/**
 * 뒤로가기 버튼
 * @param {function} onClick
 * @constructor
 */
/* eslint-disable react/prop-types */
function BackButton({ width = 34, height = 34, color = 'brand.main', sx = { ml: '-16px' }, onClick }) {
  return (
    <IconButton onClick={onClick} edge={'start'} sx={sx}>
      <ArrowBackIcon width={width} height={height} color={color} />
    </IconButton>
  );
}

export default BackButton;
