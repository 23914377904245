import React from 'react';
import { Navigate, Route } from 'react-router-dom';

// pages (component)
import OrderConfirm from 'views/pages/order/OrderConfirm';
import OrderTicketConfirm from 'views/pages/order/OrderTicketConfirm';
import OrderTodayBottleConfirm from 'views/pages/order/OrderTodayBottleConfirm';
import OrderCoBuyingConfirm from 'views/pages/order/OrderCoBuyingConfirm';
import OrderPromotionConfirm from '../views/pages/order/OrderPromotionConfirm/OrderPromotionConfirm';
// 주문전 페이지

/**
 * 주문관련 라우트
 * @constructor
 */
export default function OrderRoutes() {
  return (
    <Route path="/order">
      <Route index element={<Navigate replace to={'/'} />} />
      {/* 와인상품 주문 확인 */}
      <Route path="confirm" element={<OrderConfirm />} />
      {/* [PROD-223][임시] 티켓상품 주문 확인 (단품) */}
      <Route path="ticket/confirm" element={<OrderTicketConfirm />} />
      {/* [PROD-271] 오늘의 한병 주문 확인 (단품) */}
      <Route path="today-bottle/confirm" element={<OrderTodayBottleConfirm />} />
      {/* [PROD-308] 공동구매 주문 확인 (단품) */}
      <Route path="co-buying/confirm" element={<OrderCoBuyingConfirm />} />
      {/* [OW2024-83] pdata promotion 주문확인 */}
      <Route path={'promotion/confirm'} element={<OrderPromotionConfirm />} />
    </Route>
  );
}
