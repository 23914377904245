import React, { useEffect, useMemo, useState } from 'react';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ko';

// project imports
import { USER_LOCATION_TYPE } from 'config';
import { WoAlert } from 'utils/kmwine-alerts';
import * as UserService from 'services/UserService';
import { useDispatch, useSelector } from 'store';
import { setUserLocation } from 'store/slices/user-location';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getFirestore } from 'utils/firebase-tools';
import { useScriptRef, useWineOne } from 'hooks';
import { WineBottleLoadingLottie } from '../../../spinner';
import { NowLocationIcon, PeriscopeIcon } from '../../../icons';

// material-ui
import {
  Backdrop,
  Box,
  Checkbox,
  Chip,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

function UserLocationContent({ onPinChangeClick, show = true, ...other }) {
  // 사용자 위치정보
  const userLocation = useSelector((state) => state.userLocation);
  const scriptedRef = useScriptRef();
  const globalDispatch = useDispatch();

  const { geolocation } = useWineOne();

  const [loading, setLoading] = useState(false);

  // 사용자 위치타입 변경
  const handleUserLocationType = async (e, locationType) => {
    // todo 장바구니에 상품이 있을 경우, 장바구니 초기화 여부 묻기
    console.log('##### todo: 장바구니에 상품이 있을 경우, 장바구니 초기화 여부 물어보기');

    setLoading(true);

    if (userLocation.type !== locationType) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`[1KMWINE] 'userLocationType'이 변경됨. ${userLocation.type} -> ${locationType}`);
      }

      // 사용자 location_type 변경
      UserService.setLocationType(locationType)
        .then((response) => {
          console.debug('member location type 업데이트 결과: ', response);
          const { result } = response.data;
          const { code, msg } = result;

          // 변경성공
          if (code === 0) {
            // 사용자의 위치타입을 현재위치(current)로 변경
            if (locationType === USER_LOCATION_TYPE.CURRENT) {
              console.info('[1KMWINE] 사용자의 현재위치로 변경함');
              // handleCurrLocationChange(result);

              // 현재 기기의 위도 경도
              const { latitude, longitude } = geolocation;
              globalDispatch(setUserLocation(USER_LOCATION_TYPE.CURRENT, { latitude, longitude }));
            }

            // 사용자의 위치타입을 핀(pin)으로 변경
            else if (locationType === USER_LOCATION_TYPE.PIN) {
              console.info('[1KMWINE] 사용자의 위치타입을 핀으로 변경함');

              const auth = getAuth();
              getDoc(doc(getFirestore(), 'member', auth.currentUser.uid))
                .then((snapshot) => {
                  if (snapshot.exists()) {
                    const { pin = null } = snapshot.data();
                    globalDispatch(setUserLocation(USER_LOCATION_TYPE.PIN, pin));
                  } else {
                    globalDispatch(setUserLocation(USER_LOCATION_TYPE.PIN, null));
                  }
                })
                .catch((e) => {
                  console.warn('[1kmwine] 로그인사용자 정보조회 중 오류 [E93840293].', `uid='${auth.currentUser.uid}'`, e);
                  return false;
                });
            }
          } else {
            WoAlert.fire({
              title: '위치정보 설정오류',
              html: `<div style='line-height: 1.5rem; text-align: center'>설정 저장 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.<br/><br/>[${msg}]</div>`,
              customClass: {
                confirmButton: 'max-100'
              },
              confirmButtonText: '확인'
            });
          }
        })
        .finally(() => {
          setLoading(() => false);
        });
    } else {
      console.debug(`[1KMWINE] 'userLocationType'이 변경되지 않았습니다. [type=${userLocation.type}]`);
      setLoading(() => false);
    }
  };

  // 오늘 핀변경횟수
  const [pinLogSize, setPinLogSize] = useState(null);
  // 로그인 사용자의 일일 핀변경 가능횟수
  const [changeLocationLimit, setChangeLocationLimit] = useState(3);

  useEffect(() => {
    if (show) {
      const auth = getAuth();
      const { uid } = auth.currentUser;
      fetchPinLogSize(); // 오늘 핀 변경횟수 확인

      // 사용자의 일일 핀 변경 가능횟수
      getDoc(doc(getFirestore(), 'member', uid))
        .then((snapshot) => {
          if (scriptedRef.current) {
            const { change_location_limit } = snapshot.data();
            setChangeLocationLimit(change_location_limit);
          }
        })
        .catch((err) => {
          // todo error
          console.warn('[wineone] 로그인사용자 정보조회 중 오류 [E938521].', err);
          return false;
        });
    }
  }, [show]);

  // 핀 변경 회수 조회
  const fetchPinLogSize = async () => {
    const auth = getAuth();
    const { uid } = auth.currentUser;
    const snapshot = await getDocs(
      query(collection(getFirestore(), `pin_log`), where('user_id', '==', uid), where('created_at', '>=', moment().startOf('day').toDate()))
    ).catch((error) => ({ error }));

    if (scriptedRef.current) {
      if (snapshot.error) {
        console.warn('[1KMWINE] 핀변경 로그 조회 중 오류가 발생했습니다. 임의의 수(3)을 세팅합니다.', snapshot.error);
        setPinLogSize(3);
      } else {
        setPinLogSize(snapshot.size);
      }
    }
  };

  // 현재 위치좌표 갱신
  const refreshCurrentLocation = () => {
    console.log('현재 위치 재설정');

    // 현재 기기의 위도 경도
    const { latitude, longitude } = geolocation;
    globalDispatch(setUserLocation(USER_LOCATION_TYPE.CURRENT, { latitude, longitude }));
  };

  // 현재 위치핀 정보
  const currentUserLocationPin = useMemo(() => {
    const { type = false } = userLocation;

    // 위치정보가 없을 경우
    if (!type) {
      return (
        <Box p="14px" bgcolor="#faebeb">
          <Typography variant={'subtitle1'} fontWeight="normal" textAlign="center" color="error">
            위치 설정 방법을 선택해주세요.
          </Typography>
        </Box>
      );
    }

    // 핀정보가 없을 경우
    if (type === USER_LOCATION_TYPE.PIN && userLocation.currentPin === null) {
      return (
        <Box p="14px" bgcolor="#faebeb">
          <Typography variant={'subtitle1'} fontWeight="normal" textAlign="center" color="error">
            지정된 핀 정보가 없습니다.
          </Typography>
          <Typography variant={'subtitle2'} textAlign="center" sx={{ mt: '7px' }}>
            핀위치 지정 후 서비스 이용이 가능합니다.
          </Typography>
          <Box display={'flex'} justifyContent={'center'} mt={2}>
            <Link component="button" onClick={onPinChangeClick} fontSize={15}>
              변경하기
            </Link>
          </Box>
        </Box>
      );
    }

    // render
    return (
      <Box p="14px 13px 12px" mb="16px" borderRadius="6px" bgcolor="#0000000a">
        <Box display="flex">
          <Box flexGrow={1} maxWidth="calc(100% - 58px)">
            <Typography
              component="span"
              sx={{
                opacity: 0.7,
                fontSize: 11,
                letterSpacing: '-0.11px'
              }}
            >
              <b style={{ fontWeight: 800 }}>설정된 위치</b>
              {!userLocation.loading && ` (${moment(userLocation.lastUpdated).locale('ko').fromNow()})`}
            </Typography>

            <Box mt={1.25}>
              {/* 사용자 위치명칭 */}
              <Typography lineHeight={1.4} fontSize={16} fontWeight="bold">
                {!userLocation.loading
                  ? userLocation.type === USER_LOCATION_TYPE.CURRENT
                    ? `[현재 위치]`
                    : userLocation.currentPin?.name ?? '불러오는 중'
                  : '불러오는 중'}
              </Typography>
              <Box>
                {/* 핀 주소 */}
                <Typography noWrap fontSize={11} sx={{ opacity: 0.7, letterSpacing: '-0.11px', maxWidth: 'calc(100% - 14px)' }}>
                  {!userLocation.loading
                    ? userLocation.type === USER_LOCATION_TYPE.CURRENT
                      ? userLocation.currentLocation.address
                      : userLocation.currentPin.address
                    : '불러오는 중'}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* 사용자 핀위치 수정하기 버튼 */}
          {type === USER_LOCATION_TYPE.PIN &&
            onPinChangeClick &&
            typeof onPinChangeClick === 'function' &&
            pinLogSize < changeLocationLimit && (
              <Box display="flex" alignItems="flex-end">
                <UpdateLocationButton label="수정하기" onClick={onPinChangeClick} />
              </Box>
            )}

          {/* 현재위치 갱신 버튼 */}
          {type === USER_LOCATION_TYPE.CURRENT && (
            <Box display="flex" alignItems="flex-end">
              <UpdateLocationButton label="수정하기" onClick={refreshCurrentLocation} />
            </Box>
          )}
        </Box>
      </Box>
    );
  }, [userLocation, pinLogSize, changeLocationLimit]);

  return (
    <Box className={'user-location-content'}>
      <Backdrop open={loading} sx={{ zIndex: 2400 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <WineBottleLoadingLottie />
          <Typography color="#fff">위치 정보를 수정하고 있습니다.</Typography>
        </Box>
      </Backdrop>

      <List disablePadding>
        {/* 현재위치 체크박스[start] */}
        {/* todo 현재위치 기능 가능해질 때 까지 숨김처리 */}
        <ListItem
          sx={{ display: 'none' }}
          secondaryAction={
            <Checkbox
              disabled // 220922 '현재위치' 기능 막기
              edge="end"
              checked={userLocation.type === USER_LOCATION_TYPE.CURRENT}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => {
                handleUserLocationType(e, USER_LOCATION_TYPE.CURRENT);
              }}
            />
          }
          disablePadding
        >
          <ListItemButton
            disabled // 220922 '현재위치' 기능 막기
            onClick={(e) => {
              handleUserLocationType(e, USER_LOCATION_TYPE.CURRENT);
            }}
            sx={{ px: 0, height: 64 }}
          >
            <ListItemIcon sx={{ color: 'text.primary', minWidth: '38px' }}>
              <NowLocationIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>
              <Typography paragraph={false} fontSize={18} lineHeight={1}>
                현재 위치로 설정
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {/* 현재위치 체크박스[end] */}

        <Divider component="li" variant="inset" sx={{ ml: '38px' }} />
        <ListItem
          secondaryAction={
            <Checkbox
              edge="end"
              checked={userLocation.type === USER_LOCATION_TYPE.PIN}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ display: 'none' }}
              onClick={(e) => {
                console.debug('#직접 위치설정 -> 핀');
                handleUserLocationType(e, USER_LOCATION_TYPE.PIN);
              }}
            />
          }
          disablePadding
        >
          <ListItemButton
            disableRipple
            onClick={(e) => {
              console.debug('#직접 위치설정 -> 핀');
              handleUserLocationType(e, USER_LOCATION_TYPE.PIN);
            }}
            sx={{ px: 0, height: 64 }}
          >
            <ListItemIcon sx={{ color: 'text.primary', minWidth: '38px' }}>
              <PeriscopeIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>
              <Typography paragraph={false} fontSize={18} lineHeight={1}>
                직접 위치 설정
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      {currentUserLocationPin}

      {userLocation.currentPin && (
        <Box pt={1} pb="calc( 8px + env(safe-area-inset-bottom) )" textAlign="center" bgcolor="#faebeb" mx="-20px" mt={2}>
          {pinLogSize === changeLocationLimit ? (
            <Typography variant="caption" color="#d62d2d">
              오늘은 더 이상 핀변경을 하실 수 없습니다. ({changeLocationLimit}회 중 {pinLogSize}회 변경)
            </Typography>
          ) : (
            <Typography variant="caption" color="#d62d2d">
              {/* 위치는 최대 1개, 하루에 {changeLocationLimit}회 변경 가능합니다. */}
              위치 설정 핀은 하루에 {changeLocationLimit}회 변경할 수 있어요. ({pinLogSize}/{changeLocationLimit})
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

UserLocationContent.propTypes = {
  onPinChangeClick: PropTypes.func,
  show: PropTypes.bool
};

export default UserLocationContent;

const UpdateLocationButton = styled((props) => <Chip variant="outlined" clickable size="small" {...props} />)({
  width: 58,
  height: 27,
  padding: 0,
  borderRadius: 14,
  fontSize: 11,
  border: 'solid 1px rgba(96, 84, 110, 0.15)',
  backgroundColor: '#fff',
  '& .MuiChip-label': {
    padding: 0
  }
});
