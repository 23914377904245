import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { KmwineIconVertical } from 'components';

// material-ui
import { Box, ButtonBase, Stack, SvgIcon, Typography } from '@mui/material';

/**
 * 리뷰 목록 조회 실패 component
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewThreadError = ({ reloadList, contentHeight, bgcolor = '#F1F0F2', contentPd = '80px', type = 'list' }) => {
  return (
    <Box
      bgcolor={type === 'list' ? '#F1F0F2' : '#FFF'}
      height={contentHeight}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb={type === 'list' ? 0 : '60px'}
    >
      <Box pt={contentPd} textAlign="center">
        <KmwineIconVertical />
        <Stack pt={type === 'list' ? '60px' : '40px'} spacing={1}>
          <Typography sx={{ fontSize: 16, fontWeight: 700, lineHeight: 'normal' }}>리뷰 데이터를 불러오지 못했어요.</Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 300, lineHeight: 'noraml' }}>화면을 새로고침 해주세요.</Typography>
        </Stack>
      </Box>

      <Box pb={type === 'list' ? '115px' : '0'} textAlign="center">
        <ButtonBase
          variant="contained"
          sx={{
            border: '1px solid rgba(34, 3, 72, 0.2)',
            color: 'rgba(34, 3, 72, 0.6)',
            backgroundColor: '#FFFFFF',
            p: '10px 34px',
            borderRadius: '8px',
            mt: type === 'list' ? 0 : '40px'
          }}
          onClick={() => reloadList(1)}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: 'normal',
              mr: '4px'
            }}
          >
            새로고침
          </Typography>
          <RefreshIcon />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default ReviewThreadError;

ReviewThreadError.propTypes = {
  reloadList: PropTypes.func.isRequired,
  contentHeight: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  contentPd: PropTypes.string,
  type: PropTypes.string
};

// 새로고침 아이콘
const RefreshIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 16, height: 16, fill: 'none' }} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9961 8.8736C14.561 12.3664 11.5643 15 8.02416 15C4.15084 15 1 11.86 1 8C1 4.14 4.15084 1 8.02416 1C9.56145 1 11.0369 1.504 12.2387 2.4096V1.6C12.2387 1.2688 12.5084 1 12.8407 1C13.1731 1 13.4428 1.2688 13.4428 1.6V4.04C13.4428 4.3712 13.1731 4.64 12.8407 4.64H10.4324C10.1001 4.64 9.83038 4.3712 9.83038 4.04C9.83038 3.7088 10.1001 3.44 10.4324 3.44H11.6013C10.5914 2.6464 9.33588 2.2 8.02416 2.2C4.81472 2.2 2.20414 4.8016 2.20414 8C2.20414 11.1984 4.81472 13.8 8.02416 13.8C10.9567 13.8 13.4404 11.6184 13.8008 8.7264C13.8418 8.3968 14.1372 8.1616 14.4727 8.2048C14.8027 8.2456 15.0371 8.5448 14.9961 8.8736Z"
        fill="#220348"
        fillOpacity={0.5}
      />
    </SvgIcon>
  );
});
