import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';

// project imports
import { useAuth } from 'hooks';
import { ThreeCircleMoreButton, UserDefaultIcon } from 'components';

// material-ui
import { Avatar, Box, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const AVATAR_WIDTH = 48;
const AVATAR_HEIGHT = 48;
const BUTTON_WIDTH = 24;

/**
 * 리뷰 쓰레드 작성자 정보 영역
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewThreadWriterSection = ({ review, selectReview }) => {
  // 로그인 사용자 정보
  const { user } = useAuth();

  // 작성자 아바타
  const writerAvatar = React.useMemo(() => {
    let avatar = <Skeleton width={AVATAR_HEIGHT} height={AVATAR_HEIGHT} variant="circular" />;
    const { uid, profile_img__thumb__url } = review;
    if (uid != null) {
      // 작성자 프로필 이미지
      if (profile_img__thumb__url) {
        avatar = <Avatar variant="circular" src={profile_img__thumb__url} sx={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }} />;
      } else {
        avatar = (
          <Avatar variant="circular" sx={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}>
            <UserDefaultIcon width="100%" height="100%" />
          </Avatar>
        );
      }
    }
    return avatar;
  }, [review]);

  // 작성일자
  const writeDate = React.useMemo(() => {
    if (!review?.review_created_at) return null;

    const { review_created_at } = review;
    let label = moment(review_created_at).fromNow().replaceAll('달', '개월');
    if (label === '한 개월 전') label = '1개월 전';
    return label;
  }, [review]);

  // 평점
  const stars = React.useMemo(() => {
    let stars = null;
    if (typeof review?.review_star === 'number') {
      stars = (
        <Box pt="3px">
          <Rating
            readonly
            initialValue={review.review_star}
            size="12px"
            allowFraction
            emptyIcon={<EmptyRatingIcon />}
            fillIcon={<FilledRatingIcon />}
          />
        </Box>
      );
    }
    return stars;
  }, [review]);

  // 구매자 리뷰 칩
  const buyerReviewChip = React.useMemo(() => {
    let chip = null;
    const { order_count = 0 } = review;
    if (order_count > 0) {
      chip = (
        <Box
          component="span"
          display="inline-block"
          bgcolor="#F1F0F2"
          color="#9357E5"
          fontSize="9px"
          borderRadius="4px"
          px="4px"
          py="2px"
          fontWeight={700}
          lineHeight="normal"
          letterSpacing="-0.12px"
          textAlign="center"
        >
          구매자 리뷰
        </Box>
      );
    }
    return chip;
  }, [review]);

  // 리뷰 수정 또는 신고/차단
  const reviewAction = () => {
    const { _id: uid } = user;
    const { uid: reviewWriterUid } = review;

    // 로그인한 유저의 uid 또는 리뷰 작성자의 uid가 올바르지 않음
    if (!uid || !reviewWriterUid) return;

    // 리뷰 수정
    if (uid === reviewWriterUid) {
      selectReview('update', review);
    }
    // 리뷰 신고/차단
    else {
      selectReview('report', review);
    }
  };

  return (
    <Box component="section" display="flex" pt="20px" mb="16px">
      <Box className="user-avatar" width={AVATAR_HEIGHT} height={AVATAR_HEIGHT}>
        {writerAvatar}
      </Box>
      <Box flexGrow={1} pl="12px" display="flex">
        <Box width={`calc(100% - ${BUTTON_WIDTH}px)`} pr="6px" display="flex" alignItems="center">
          <Box>
            {/* 작성자 닉네임 & 작성 날짜 */}
            <Stack direction="row" spacing="4px" alignItems="center">
              <WriterNickname>{review?.nickname ?? <Skeleton width={100} />}</WriterNickname>
              <WriterTime>{writeDate}</WriterTime>
            </Stack>

            {/* 작성자의 취향 */}
            <WriterTaste>{review.taste_str}</WriterTaste>

            {/* 작성자의 별점 및 구매자 칩 */}
            <Stack direction="row" spacing="4px" alignItems="center" mt="4px" maxHeight="12px">
              {stars}
              {buyerReviewChip}
            </Stack>
          </Box>
        </Box>
        <Box textAlign="right" onClick={reviewAction}>
          <ThreeCircleMoreButton />
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewThreadWriterSection;

ReviewThreadWriterSection.propTypes = {
  review: PropTypes.object,
  selectReview: PropTypes.func.isRequired
};

/**
 * 작성자 닉네임
 * @type {StyledComponent<PropsOf<OverridableComponent<TypographyTypeMap>> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const WriterNickname = styled(Typography)({
  fontSize: '12px',
  fontWeight: 800,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.12px',
  textAlign: 'left'
});

/**
 * 작성 시간
 */
const WriterTime = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.12px',
  textAlign: 'left'
});

/**
 * 작성자 취향
 */
const WriterTaste = styled(Typography)({
  fontSize: '12px',
  color: 'rgba(20, 2, 41, 0.70)',
  marginTop: '4px',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.12px',
  textAlign: 'left'
});

// 비어있는 별 아이콘
const EmptyRatingIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 12, height: 12, fill: 'none' }} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0023 8.74691L3.41914 10.6767C3.36211 10.7193 3.29726 10.7502 3.22829 10.7677C3.15933 10.7852 3.08759 10.7889 3.01717 10.7787C2.94676 10.7684 2.87905 10.7444 2.81791 10.708C2.75678 10.6716 2.70341 10.6236 2.66085 10.5665C2.60826 10.4961 2.57362 10.4138 2.5599 10.327C2.54619 10.2401 2.55382 10.1513 2.58214 10.068L3.61855 7.01387L0.980291 5.15357C0.922006 5.11261 0.87237 5.06056 0.834224 5.0004C0.796078 4.94023 0.770171 4.87314 0.757985 4.80295C0.7458 4.73276 0.747575 4.66086 0.76321 4.59136C0.778844 4.52186 0.808031 4.45612 0.8491 4.39791C0.899815 4.32597 0.967294 4.26747 1.0457 4.22747C1.1241 4.18746 1.21107 4.16717 1.29909 4.16833L4.52246 4.21162L5.48409 1.13124C5.50518 1.06327 5.53946 1.00012 5.58497 0.945396C5.63047 0.890673 5.68631 0.845451 5.7493 0.812313C5.81228 0.779176 5.88118 0.758772 5.95205 0.752268C6.02293 0.745763 6.09439 0.753286 6.16235 0.774405C6.24632 0.800469 6.32268 0.846575 6.38485 0.908744C6.44702 0.970913 6.49313 1.04728 6.51919 1.13124L7.47558 4.21162L10.7003 4.16964C10.7715 4.1686 10.8423 4.18163 10.9085 4.20798C10.9747 4.23433 11.0351 4.27349 11.0862 4.32321C11.1372 4.37294 11.178 4.43224 11.2061 4.49774C11.2342 4.56324 11.2491 4.63363 11.25 4.7049C11.2511 4.79291 11.2308 4.87988 11.1908 4.95829C11.1508 5.03669 11.0923 5.10417 11.0204 5.15489L8.38736 7.01387L9.42377 10.068C9.44668 10.1354 9.4561 10.2066 9.45149 10.2776C9.44689 10.3486 9.42834 10.418 9.39691 10.4818C9.36548 10.5457 9.32178 10.6027 9.26831 10.6496C9.21485 10.6966 9.15266 10.7325 9.0853 10.7554C9.00205 10.7838 8.91316 10.7914 8.82631 10.7777C8.73945 10.764 8.65724 10.7293 8.58677 10.6767L7.29453 9.71182L6.0023 8.74691Z"
        fill="#EDE7F4"
      />
    </SvgIcon>
  );
});

// 채워져있는 별 아이콘
const FilledRatingIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 12, height: 12, fill: 'none' }} viewBox="0 0 12 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0023 8.74691L3.41914 10.6767C3.36211 10.7193 3.29726 10.7502 3.22829 10.7677C3.15933 10.7852 3.08759 10.7889 3.01717 10.7787C2.94676 10.7684 2.87905 10.7444 2.81791 10.708C2.75678 10.6716 2.70341 10.6236 2.66085 10.5665C2.60826 10.4961 2.57362 10.4138 2.5599 10.327C2.54619 10.2401 2.55382 10.1513 2.58214 10.068L3.61855 7.01387L0.980291 5.15357C0.922006 5.11261 0.87237 5.06056 0.834224 5.0004C0.796078 4.94023 0.770171 4.87314 0.757985 4.80295C0.7458 4.73276 0.747575 4.66086 0.76321 4.59136C0.778844 4.52186 0.808031 4.45612 0.8491 4.39791C0.899815 4.32597 0.967294 4.26747 1.0457 4.22747C1.1241 4.18746 1.21107 4.16717 1.29909 4.16833L4.52246 4.21162L5.48409 1.13124C5.50518 1.06327 5.53946 1.00012 5.58497 0.945396C5.63047 0.890673 5.68631 0.845451 5.7493 0.812313C5.81228 0.779176 5.88118 0.758772 5.95205 0.752268C6.02293 0.745763 6.09439 0.753286 6.16235 0.774405C6.24632 0.800469 6.32268 0.846575 6.38485 0.908744C6.44702 0.970913 6.49313 1.04728 6.51919 1.13124L7.47558 4.21162L10.7003 4.16964C10.7715 4.1686 10.8423 4.18163 10.9085 4.20798C10.9747 4.23433 11.0351 4.27349 11.0862 4.32321C11.1372 4.37294 11.178 4.43224 11.2061 4.49774C11.2342 4.56324 11.2491 4.63363 11.25 4.7049C11.2511 4.79291 11.2308 4.87988 11.1908 4.95829C11.1508 5.03669 11.0923 5.10417 11.0204 5.15489L8.38736 7.01387L9.42377 10.068C9.44668 10.1354 9.4561 10.2066 9.45149 10.2776C9.44689 10.3486 9.42834 10.418 9.39691 10.4818C9.36548 10.5457 9.32178 10.6027 9.26831 10.6496C9.21485 10.6966 9.15266 10.7325 9.0853 10.7554C9.00205 10.7838 8.91316 10.7914 8.82631 10.7777C8.73945 10.764 8.65724 10.7293 8.58677 10.6767L7.29453 9.71182L6.0023 8.74691Z"
        fill="#FEC466"
      />
    </SvgIcon>
  );
});
