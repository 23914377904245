// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const NODE_URL = {
  // 주문 가능 와인샵 목록 조회
  POST__GET_ORDERABLE_LIST: `${API_SERVER_HOST}/pdata/vendors/available`,
  // pdata info 화면 데이터 조회
  GET__PDATA_INFO_DATA: `${API_SERVER_HOST}/pdata/info`,
  // pdata 리뷰 정보 조회
  GET__PDATA_REVIEW_DATA: `${API_SERVER_HOST}/pdata/reviews/info`
};

export * from './pdata';
