// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const NODE_URL = {
  /** 현재 진행 중인 셀프프로모션 목록 조회 */
  POST__GET_VENDOR__SELF_PROMOTION_LIST: `${API_SERVER_HOST}/promotion/self/list`,

  /** 셀프 프로모션 상세조회 */
  GET__GET__SELF_PROMOTION: `${API_SERVER_HOST}/promotion/self/:promoId/detail`,

  /** 프로모션 상세조회 */
  GET__GET__PROMOTION: `${API_SERVER_HOST}/promotion/1km/:promoId/detail`,

  /** 프로모션 상세조회 */
  GET__GET__PROMOTION_COUPONS: `${API_SERVER_HOST}/promotion/1km/:promoId/coupon/list`
};

export * from './selfPromotion';
