import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Parallax } from 'swiper';
import PropTypes from 'prop-types';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import './style.scss';

// material-ui
import { Box, Dialog, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

/**
 * 리뷰 이미지 뷰어
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewImageViewer = ({ open, onClose, images, currentIndex }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const swiperRef = React.useRef(null);

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!open) return;
    setLoaded(false);
    if (Array.isArray(images) && images.length > 0) {
      setLoaded(true);
    } else {
      WoAlert.fire('', '잘못된 접근입니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        handleClose();
      });
    }
  }, [open, images]);

  // 이미지 뷰어 다이어로그 닫기
  const handleClose = () => {
    onClose();
    navigate(-1);
  };

  return (
    <Dialog fullScreen open={open}>
      <Paper sx={{ width: '100%', height: '100%', bgcolor: '#000' }}>
        <Box sx={{ width: '100%', height: 48, zIndex: 2002, position: 'relative', display: 'flex', alignItems: 'center', px: '4px' }}>
          <IconButton sx={{ width: 34, height: 34 }} onClick={handleClose}>
            <CloseIcon sx={{ color: '#FFF' }} />
          </IconButton>
        </Box>
        {loaded && (
          <Swiper
            id="review-thread-image-swiper"
            ref={swiperRef}
            initialSlide={currentIndex}
            parallax
            pagination={{
              type: 'fraction',
              renderFraction: (currentClass, totalClass) => {
                return `<div class="fraction-box">
                          <span class="${currentClass}"></span> <span class="slash">/</span> <span class="${totalClass}"></span>
                        </div>`;
              }
            }}
            modules={[Parallax, Pagination]}
          >
            {images.map((item, index) => {
              const { type, img } = item;

              let imgSrc;

              if (img?.viewer?.url) {
                imgSrc = img.viewer.url;
              } else if (img?.original?.url) {
                imgSrc = img.original.url;
              } else {
                imgSrc = img.thumb.url;
              }
              return (
                <SwiperSlide key={index} style={{ display: 'flex', maxHeight: '85%' }}>
                  {/* pdata 이미지 */}
                  {type === 'pdata' ? (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Box sx={{ width: '100%', bgcolor: theme.palette.background.pdata.wine, aspectRatio: '1/1' }}>
                        <img
                          src={img?.original ?? img.thumb}
                          alt={`pdata_image_${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            padding: '40px 0',
                            boxSizing: 'border-box'
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    // 리뷰 이미지
                    <img
                      src={imgSrc}
                      alt={`review_image_${index}`}
                      style={{
                        width: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Paper>
    </Dialog>
  );
};

export default ReviewImageViewer;

ReviewImageViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  currentIndex: PropTypes.number
};
