import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// material-ui
import { Box, ButtonBase, List, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

// 리뷰 이미지 가로 크기
const REVIEW_IMG_WIDTH = 148;
// 리뷰 이미지 세로 크기
const REVIEW_IMG_HEIGHT = 148;

/**
 * 리뷰 쓰레드 이미지 섹션
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewThreadImageSection = ({ review, onThumbnailClick, type = 'reviewThread' }) => {
  // 이미지 배열
  const [imgs, setImgs] = React.useState([]);

  React.useEffect(() => {
    const { review_image } = review;

    const imgListArray = [];

    // 등록된 리뷰 이미지가 있음
    if (review_image && review_image?.length > 0) {
      review_image.forEach((img) => {
        imgListArray.push({ type: 'review', img });
      });
    }

    // pdata 이미지
    if (review?.pdata_image?.thumb) {
      imgListArray.push({ type: 'pdata', img: { thumb: review.pdata_image.thumb, original: review.pdata_image?.removebg } });
    }

    setImgs(imgListArray);
  }, [review]);

  // 이미지 오류 처리
  const onError = React.useCallback(() => {
    setImgs([]);
  }, []);

  return (
    imgs.length > 0 && (
      <List
        component={Stack}
        direction="row"
        disablePadding
        className="none-scroll-bar"
        spacing={1}
        sx={(theme) => ({
          overflow: 'auto',
          mx: -2,
          px: 2,
          scrollSnapType: 'x',
          scrollPadding: theme.spacing(2)
        })}
      >
        {imgs.map((item, index) => {
          const { type: itemType, img } = item;

          if (type !== 'reviewThread' && itemType === 'pdata') return;
          return (
            <ReviewThreadImageWrapper
              key={`review-img-${index}-card`}
              width={REVIEW_IMG_WIDTH}
              height={REVIEW_IMG_HEIGHT}
              ratio={REVIEW_IMG_WIDTH / REVIEW_IMG_HEIGHT}
            >
              <ButtonBase
                key={`review-img-${index}-card`}
                sx={{ width: '100%', height: '100%' }}
                onClick={() => onThumbnailClick(imgs, index)}
              >
                <LazyLoadImage
                  wrapperClassName="review-image-wrapper"
                  src={itemType === 'review' ? img?.thumb?.url : img?.thumb}
                  placeholder={<Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />}
                  alt="리뷰 이미지"
                  width="100%"
                  height="100%"
                  effect="blur"
                  draggable={false}
                  style={{
                    objectFit: item.type === 'pdata' ? 'contain' : 'cover',
                    objectPosition: 'center center',
                    padding: item.type === 'review' ? 0 : '12px 5px 11.2px'
                  }}
                  onError={onError}
                />
              </ButtonBase>
            </ReviewThreadImageWrapper>
          );
        })}
      </List>
    )
  );
};

export default ReviewThreadImageSection;

ReviewThreadImageSection.propTypes = {
  review: PropTypes.object.isRequired,
  onThumbnailClick: PropTypes.func.isRequired,
  type: PropTypes.string
};

const ReviewThreadImageWrapper = styled((props) => {
  const theme = useTheme();

  const { padding, ratio, width, height, ...other } = props;
  let imgHeight = height;

  if (ratio) imgHeight = width * ratio;
  else if (Number.isNaN(imgHeight)) imgHeight = '100%';

  // 상품 이미지 배경색
  const bgcolor = theme.palette.background.pdata.wine;

  return (
    <Box
      width={width}
      height={imgHeight}
      ratio={ratio}
      minHeight={imgHeight}
      p={padding}
      minWidth={width}
      overflow="hidden"
      bgcolor={bgcolor}
      draggable={false}
      {...other}
    />
  );
})(
  ({ theme }) => `
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  overflow: hidden;
  transition: ${theme.transitions.create(['border-color', 'background-color', 'box-shadow'])}
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
`
);
