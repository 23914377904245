// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const FIREBASE_FUNCTION_URL = {
  // 장바구니 리스트
  POST__GET_CART_LIST: `${API_SERVER_HOST}/member/cart/getCarts`,

  // 장바구니 상품 추가
  POST__ADD_CART: `${API_SERVER_HOST}/member/cart/addCart`,

  // 장바구니 상품 삭제
  DELETE__CART_PRODUCTS: `${API_SERVER_HOST}/member/cart/deleteProduct`
};

export * from './cart'; // 장바구니 관련 API
