import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const { pathname, search, hash } = location;

  // const auth = getAuth();

  // state 상에서 로그인 처리되어있고 firebase auth가 있는 사용자
  // const loggedInUser = isLoggedIn && !!auth.currentUser;
  const loggedInUser = isLoggedIn;
  // console.debug('Is logged in user: ', loggedInUser);

  React.useEffect(() => {
    if (!loggedInUser) {
      // 로그인 하지 않았을 경우 '로그인' 화면으로 이동시킵니다.
      navigate(`/auth/sign-in?from=${encodeURIComponent(pathname + search + hash)}`, { replace: true });
      return null; // page 깜박거리는 현상 처리
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? children : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
