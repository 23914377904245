import React from 'react';
import moment from 'moment';

// project imports
import { KmPromotionState, ORDER } from 'config';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { Strings } from 'utils/bourgogne-tools';

// material-ui
import { Box, Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * Order state box.
 *
 * @param state Order state value.
 * @param {boolean} hideSubText Hide subscription text.
 * @param date Date for expression.
 * @returns {JSX.Element}
 * @constructor
 */
/* eslint-disable react/prop-types */
function OrderState({ order, hideSubText = false, date = null, wrapperProps = {}, orderList = false }) {
  const theme = useTheme();

  // [PROD-308] 공동구매 주문인가
  const isCoBuyOrder = React.useMemo(() => {
    const { cobuy_state = 'NONE' } = order;
    return cobuy_state !== 'NONE';
  }, [order.cobuy_state]);

  // 예약 프로모션인지 확인
  const isPromoReserve = React.useMemo(() => {
    if (order.promotion_state) {
      return order.promotion_state === 'PROMO_RESERVE';
    }
    return false;
  }, [order]);

  // let subText = null;

  const subText_ = React.useMemo(() => {
    if (hideSubText) return null;

    const { state } = order;
    let subText = null;
    // 주문 확인 중 -> 서브텍스트
    if (state === ORDER.STATE.REQUEST) {
      subText = (
        <Typography variant="caption" fontSize={11} lineHeight="normal" letterSpacing="-0.11px" color="text.primary" sx={{ opacity: 0.5 }}>
          주문 승인 대기 (평균 응답 시간 10분 이내)
        </Typography>
      );
    }
    // 픽업 대기 -> 서브텍스트
    else if (state === ORDER.STATE.APPROVED) {
      const pickupDate = moment(order.pickup_date_time.toMillis());
      subText = (
        <Typography variant="caption" fontSize={11} lineHeight="normal" letterSpacing="-0.11px" color="text.primary" sx={{ opacity: 0.5 }}>
          픽업일시 : {pickupDate.format('YYYY. MM. DD(ddd) A hh:mm')} ({pickupDate.fromNow()})
        </Typography>
      );
    }
    // 주문취소 -> 서브텍스트
    else if (state === ORDER.STATE.CANCEL) {
      subText = (
        <Typography variant="caption" fontSize={11} lineHeight="normal" letterSpacing="-0.11px" color="text.primary" sx={{ opacity: 0.5 }}>
          취소일시 : {moment(order.cancel_at.toMillis()).format('YYYY. MM. DD(ddd) A hh:mm')}
        </Typography>
      );
    }
    return subText;
  }, [order?.state, isCoBuyOrder]);

  const { label } = React.useMemo(() => {
    // 럭키박스 or ticket 일시
    if (!isCoBuyOrder && order.is_ticket && order.state === 'REQ_OK') {
      return { label: '배송대기' };
    }

    // 프로모션 주문일 경우
    if (isPromoReserve) {
      if (order.state === 'CANCEL') return { label: '주문취소' };

      if (order.state === 'PICK_OK') return { label: '픽업완료' };
      if (order.state === 'CONFIRM') return { label: '구매확정 ' };

      const nowDate = new Date();

      // 예약판매 프로모션인 경우
      if (order.promotion.type === 'reserve') {
        // 예약주문 진행 중
        if (nowDate < order.promotion.period.end_dt.toDate()) {
          return { label: '예약주문 진행 중' };
        }
        // 입점샵에 예약판매 상품 입고됨

        if (order.promotion.promotion_vendor.r_state === 'OK') {
          return { label: '픽업대기' };
        }

        return { label: '주문마감(입고대기)' };
      }

      return { label: '@처리필요@' };
    }
    // 공동구매 주문이 아닐경우
    if (!isCoBuyOrder) return { label: Strings.orderItemStateToString(order.state) };

    const { coBuy, cobuy_state = 'NONE' } = order;

    // console.log('##### order:', order);

    // 사용자 주문취소
    if (order.state === 'CANCEL' && cobuy_state !== 'FAIL') return { label: '주문취소' };

    // 목표수량 미달성
    if (cobuy_state === 'FAIL') return { label: '주문취소' };

    // 공동구매 픽업완료(|구매확정)
    if (order.state === 'PICK_OK') {
      // 공동구매 주문은 주문상품이 무조건 1개
      return { label: order.orderItems[0].state === 'CONFIRM' ? '구매확정' : '픽업완료' };
    }

    let result = { label: '공동구매' };
    // 공동구매 진행 중
    if (cobuy_state === 'WAIT') {
      result = { label: '공동구매 진행 중' };
    }
    // 공동구매 종료 - 달성완료
    else if (cobuy_state === 'SUCC') {
      result = { label: '공동구매 주문확정' };

      try {
        const { vendor: coBuyVendor } = order.co_buy ?? order.coBuy;
        // 공동구매 상품이 입점샵에 입고완료
        if (coBuyVendor.state === 'OK') {
          result = { label: '픽업대기' };
        }
      } catch (e) {
        console.warn('[주문목록] 주문상태표현 오류', e);
      }
    }
    // 남은 공동구매 상태
    else {
      console.warn('#@처리 필요@#', coBuy);
    }

    return result;
  }, [isCoBuyOrder, order.state]);

  return (
    <SpaceBetweenBox mb="9px" {...wrapperProps}>
      <Box display="flex" alignItems="center">
        <Chip
          label={label}
          size="small"
          sx={{
            minWidth: '75px',
            maxWidth: '100px',
            padding: '6px 0 5px',
            fontSize: '11px',
            fontWeight: 800,
            lineHeight: 'normal',
            color: '#ffffff',
            bgcolor: theme.palette.brand.main
          }}
        />
        {!hideSubText && <Box pl="4px">{subText_}</Box>}
      </Box>
      {!hideSubText && date != null && (
        <Typography variant="body2" color="text.secondary">
          ( {moment(date).format('YYYY. M. D')} )
        </Typography>
      )}
    </SpaceBetweenBox>
  );
}

export default OrderState;
