// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;
/**
 * @constant
 * @type {{POST__FETCH_USERCOMMISSION: string, POST__CREATE_ORDER: string}}
 */
export const FIREBASE_FUNCITON_URL = {
  /** 주문(order) 정보 생성 */
  POST__CREATE_ORDER: `${BASE_HOST}/call-pay-order-create`,

  /** 서비스 이용요금(수수료) 계산 */
  POST__FETCH_USERCOMMISSION: `${BASE_HOST}/call-pay-commission-getUserCommission`,

  /** 주문취소 요청 */
  POST__ORDER_CANCEL: `${BASE_HOST}/call-pay-process-cancel`,

  /** 주문상품 교환신청 */
  POST__PROCESS_EXCHANGE: `${BASE_HOST}/call-pay-process-exchange`,
  /** 주문상품 교환신청 취소 */
  POST__PROCESS_EXCHANGE_CANCEL: `${BASE_HOST}/call-pay-process-exchangeCancel`,

  /** 반품 환불금액 계산 */
  POST__PROCESS_REFUND_PREVIEW: `${BASE_HOST}/call-pay-process-refundPreview`,
  /** 주문상품 반품신청 */
  POST__PROCESS_REFUND: `${BASE_HOST}/call-pay-process-refund`,
  /** 주문상품 반품신청 취소 */
  POST__PROCESS_REFUND_CANCEL: `${BASE_HOST}/call-pay-process-refundCancel`,

  /** 주문상품 구매확정 처리 */
  POST_PROCESS_CONFIRM: `${BASE_HOST}/call-pay-process-confirm`,

  /** 픽업 일시 변경 */
  POST_CHANGE_PICKUP_DATE: `${BASE_HOST}/call-pay-process-changePickupDate`
};

export const NODE_URL = {
  /** 주문 상태별 개수 조회 */
  GET__ORDER_COUNT_BY_STATUS: `${API_SERVER_HOST}/member/order/count-by-status`,
  /** 로그인사용자 주문목록 조회 */
  GET__ORDER_LIST: `${API_SERVER_HOST}/member/order/list`,
  /** 주문 상세정보 조회 */
  GET__ORDER_DETAIL: `${API_SERVER_HOST}/member/order/#{oid}/detail`,

  /** 사용가능한 쿠폰목록 조회 */
  POST__AVAILABLE_COUPON: `${API_SERVER_HOST}/order/coupon/available/list`
};

export * from './order'; // 주문정보
