import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { ORDER_ITEM } from '../../../../../config';
import moment from 'moment/moment';
import React from 'react';
import OrderTool from '../../../../../utils/bourgogne-tools/OrderTool';

export { default as CountGrid } from './CountGrid';

export { default as OrderCard } from './OrderCard';

export const OrderStateChip = styled(Chip)(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 'bold',
  letterSpacing: '-0.1px',
  paddingTop: '2px',
  paddingBottom: '2px',
  height: 16,
  borderRadius: '2px',
  backgroundColor: alpha(theme.palette.brand.main, 0.1),
  '& .MuiChip-label': {
    paddingLeft: '3px',
    paddingRight: '3px',
    lineHeight: 'normal'
  }
}));

/** 주문상품 상태칩 */
// eslint-disable-next-line react/prop-types
export const OrderItemStateChip = React.memo(({ orderItemState }) => {
  console.log('[주문상품 상태칩]', orderItemState);
  const theme = useTheme();
  const chipLabel = OrderTool.orderItemStateToKorean(orderItemState);

  // 칩에 색이 없는 버전
  const bgcolor = alpha(theme.palette.brand.main, 0.1);
  const color = theme.palette.text.primary;

  // 칩에 색을 넣은 버전
  // let bgcolor = alpha(theme.palette.brand.main, 0.1);
  // let color = theme.palette.text.primary;
  // console.log('---- theme: ', theme.palette.error);
  // // 반품대기 상태일 경우
  // if (orderItemState === ORDER_ITEM.STATE.RETURN_WAIT) {
  //   bgcolor = theme.palette.error.main;
  //   color = theme.palette.error.contrastText;
  // }
  // // 교환대기 상태일 경우
  // else if (orderItemState === ORDER_ITEM.STATE.EXCHANGE_WAIT) {
  //   bgcolor = theme.palette.warning.main;
  //   color = theme.palette.warning.contrastText;
  // }
  // // 픽업완료 상태일 경우
  // else if (orderItemState === ORDER_ITEM.STATE.PICK_UP_DONE) {
  //   bgcolor = theme.palette.success.main;
  //   color = theme.palette.success.contrastText;
  // }

  return <OrderStateChip label={chipLabel} sx={{ bgcolor, color }} />;
});

export function orderProductStateDesc(orderProduct, order) {
  const { state, comment, msg } = orderProduct;

  // 반품예정일은 승인처리된 다음날
  let component = null;

  // 주문요청 = 주문 확인 중
  if (state === ORDER_ITEM.STATE.REQUEST) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* todo 포장옵션 활성화 후 공개하기
        <PickupStack title="옵션">
          <PickupContent>{Strings.packingOptionToStr(orderProduct.packing)}</PickupContent>
        </PickupStack>
        */}
        {/* 요청사항 */}
        {comment && (
          <PickupStack title="요청 사항">
            <PickupContent>
              <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
                {comment}
              </Typography>
            </PickupContent>
          </PickupStack>
        )}
      </Stack>
    );
  }
  // 픽업 대기
  else if (state === ORDER_ITEM.STATE.APPROVED) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* todo 포장옵션 활성화 후 공개하기
        <PickupStack title="옵션">
          <PickupContent>{Strings.packingOptionToStr(orderProduct.packing)}</PickupContent>
        </PickupStack>
        */}
        {/* 요청사항 */}
        {comment && (
          <PickupStack title="요청 사항">
            <PickupContent>
              <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
                {comment}
              </Typography>
            </PickupContent>
          </PickupStack>
        )}
      </Stack>
    );
  }
  // 주문취소 (사용자)
  else if (state === ORDER_ITEM.STATE.ORDER_CANCEL) {
    component = order.msg ? (
      <Stack direction="column" spacing="8px">
        {/* 취소 사유 */}
        <PickupStack title="취소 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {order.msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    ) : null;
  }
  // 주문취소 (판매사)
  else if (state === ORDER_ITEM.STATE.REJECT) {
    component = msg ? (
      <Stack direction="column" spacing="8px">
        {/* 취소 사유 */}
        <PickupStack title="취소 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    ) : null;
  }
  // 픽업완료
  else if (state === ORDER_ITEM.STATE.PICK_UP_DONE) {
    console.debug('[픽업완료] 주문상품은 상세정보 표현이 없습니다');
  }
  // 교환요청
  else if (state === ORDER_ITEM.STATE.REQUEST_EXCHANGE) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 반품사유 */}
        <PickupStack title="교환 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    );
  }
  // 교환대기 (교환승인)
  else if (state === ORDER_ITEM.STATE.EXCHANGE_WAIT) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 교환 */}
        <PickupStack title="교환 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    );
  }
  // 교환취소 (판매사)
  else if (state === ORDER_ITEM.STATE.EXCHANGE_REJECTED) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 교환사유 */}
        <PickupStack title="교환 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>

        {/* 거절사유 */}
        <PickupStack title="취소 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {orderProduct.no_msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    );
  }

  // 반품요청
  else if (state === ORDER_ITEM.STATE.REQUEST_RETURN) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 반품사유 */}
        <PickupStack title="반품 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    );
  }
  // 반품대기
  else if (state === ORDER_ITEM.STATE.RETURN_WAIT) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 반품사유 */}
        <PickupStack title="반품 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>

        {/* 반품예정 */}
        <PickupStack title="반품 예정">
          <PickupContent>{moment(orderProduct.rtn_date, 'YYYY-MM-DD').format('YYYY. MM. DD (ddd)')}</PickupContent>
        </PickupStack>
      </Stack>
    );
  }
  // 반품취소 (판매사)
  else if (state === ORDER_ITEM.STATE.RETURN_REJECTED) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 반품사유 */}
        <PickupStack title="반품 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>

        {/* 판매사가 작성한 반품취소 사유 */}
        <PickupStack title="취소 사유">
          <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
            {orderProduct.no_msg ?? '반품요청이 취소되었습니다.'}
          </Typography>
        </PickupStack>
      </Stack>
    );
  }
  // 반품완료
  else if (state === ORDER_ITEM.STATE.RETURN_COMPLETE) {
    component = (
      <Stack direction="column" spacing="8px">
        {/* 반품사유 */}
        <PickupStack title="반품 사유">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {msg}
            </Typography>
          </PickupContent>
        </PickupStack>

        {/* 반품 완료 */}
        <PickupStack title="반품 완료">
          <PickupContent>
            <Typography component="div" fontSize={12} lineHeight="normal" noWrap>
              {moment(orderProduct.rtn_ok_at.toDate()).format('YYYY.MM.DD (ddd) A hh:mm')}
            </Typography>
          </PickupContent>
        </PickupStack>
      </Stack>
    );
  }
  // 구매완료
  else if (state === ORDER_ITEM.STATE.PURCHASE_CONFIRM) {
    console.debug('[구매완료] 주문상품은 상세정보 표현이 없습니다');
  } else {
    console.log('[orderProductStateDesc] 확인이 필요한 주문상품 상태', state);
    console.log('- orderProductStateDesc: ', state, orderProduct, order);
  }
  return component != null ? (
    <Box mt="14px" pl="4px">
      {component}
    </Box>
  ) : null;
}

// eslint-disable-next-line react/prop-types
const PickupStack = ({ children, title }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing="8px"
      divider={
        <Box display="flex" alignItems="center" py="3px">
          <Box height={1} width={'1px'} bgcolor={alpha(theme.palette.text.primary, 0.1)} />
        </Box>
      }
    >
      <PickupTitle>{title}</PickupTitle>
      {children}
    </Stack>
  );
};

const PickupTitle = styled(Typography)({
  fontSize: 12,
  fontWeight: 300,
  lineHeight: 'normal',
  minWidth: 54,
  width: 54,
  opacity: 0.7
});

const PickupContent = styled((props) => <Typography component="span" {...props} />)({
  fontSize: 12,
  lineHeight: 'normal',
  width: 'calc(100% - 54px - 16px)'
});
