import axios from 'axios';
import moment from 'moment';

import validator from 'validator/es';
import { FIREBASE_FUNCITON_URL, NODE_URL } from './index';
import { doc, getDoc, GeoPoint, Timestamp } from 'firebase/firestore';
import { getFirebaseFunctions, getFirestore } from '../../utils/firebase-tools';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { CLO_CODE } from '../../config';

/**
 * 사용자 정보 조회
 * @param {string} uid
 * @returns {Promise<DocumentSnapshot<DocumentData>>}
 */
export async function getUserDetail(uid) {
  if (validator.isEmpty(uid)) {
    throw new Error(`'uid' cannot be null or empty. [uid=${uid}]`, { cause: 'invalid_uid' });
  }
  console.time('사용자 정보 조회');
  return axios
    .get(NODE_URL.GET__USER__DETAIL, { params: { uid } })
    .then((response) => {
      if (!response.data) throw new Error('사용자 정보 없음');

      const member = { ...response.data };
      if (member.partners) {
        delete member.partners;
      }
      if (member.connected_at) {
        member.connected_at = Timestamp.fromDate(moment(member.connected_at).toDate());
      }
      if (member.created_at) {
        member.created_at = Timestamp.fromDate(moment(member.created_at).toDate());
      }
      if (member.password_change_at) {
        member.password_change_at = Timestamp.fromDate(moment(member.password_change_at).toDate());
      }
      if (member.pin?.location) {
        // console.log('-----> member.pin.location: ', member.pin.location);
        member.pin.location = new GeoPoint(member.pin.location.y, member.pin.location.x);
      }
      if (member.pin?.updated_at) {
        member.pin.updated_at = Timestamp.fromDate(moment(member.pin.updated_at).toDate());
      }
      if (member.sync_at) {
        member.sync_at = Timestamp.fromDate(moment(member.sync_at).toDate());
      }
      if (member.terms?.push_at) {
        member.terms.push_at = Timestamp.fromDate(moment(member.terms.push_at).toDate());
      }

      return member;
    })
    .catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `로그인 사용자 정보조회 API 요청 실패 [${error.message}] -> firestore에서 조회 시작`,
        msg: error.message ?? 'Unexpected',
        which: `getUserDetail`,
        param: { uid, location: window.location.href }
      }).catch(console.error);

      throw error;
    })
    .finally(() => {
      console.timeEnd('사용자 정보 조회');
    });
}

/**
 * 다른 사용자(member) 정보 조회
 * @param {string} uid
 * @returns {Promise<DocumentSnapshot<DocumentData>>}
 */
export async function getMember(uid) {
  if (validator.isEmpty(uid)) {
    throw new Error(`'uid' cannot be null or empty. [uid=${uid}]`, { cause: 'invalid_uid' });
  }
  return getDoc(doc(getFirestore(), `member/${uid}/member_public/${uid}`));
}

/**
 * 마이페이지 > 주문, 쿠폰, 리뷰, 이벤트 개수 조회
 */
export async function getDashboardCounts() {
  return axios
    .get(NODE_URL.GET__DASHBOARD_COUNTS)
    .then((response) => response.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 주문, 쿠폰, 리뷰, 이벤트 개수조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `getDashboardCounts`,
          param: { location: window.location.href }
        }).catch(console.error);
      }

      throw error;
    });
}

/**
 * 사용자 차단(/해제)
 * todo 차단 사용자 관리를 MySQL에서 하도록 기능변경 필요
 * @param {string} uid
 * @returns {Promise<void>}
 */
export async function userBlock(uid) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__CALL_AUTH_MEMBER_USER_BLOCK, { uid });
}

/**
 * 사용자 차단 해제
 * todo 차단 사용자 관리를 MySQL에서 하도록 기능변경 필요
 * @param {string} uid
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function userBlockCancel(uid) {
  return axios.delete(FIREBASE_FUNCITON_URL.DELETE__CALL_AUTH_MEMBER_USER_BLOCK_CANCEL, { data: { uid } });
}

/**
 * 로그인 사용자의 위치타입 변경
 * @param {'pin'|'current'} location_type
 * @returns {Promise<void>}
 */
export async function setLocationType(location_type) {
  return axios.put(FIREBASE_FUNCITON_URL.PUT__SET_LOCATION_TYPE, { location_type });
}

/**
 * 로그인 사용자의 위치핀 정보 저장
 * @param pin_id 핀 아이디
 * @returns {Promise<void>}
 */
export async function saveLocationPin(pin_id) {
  return axios.post(NODE_URL.POST__SET_PIN, { pin_id });
}

/**
 * 이메일 사용가능여부 확인
 * @param {string} email - 사용자 아이디(이메일)
 * @returns {boolean|Promise<AxiosResponse<any>>}
 */
export function checkEmail(email) {
  if (!validator.isEmail(email)) return false;
  return axios.get(NODE_URL.GET__EMAIL_CHECK, { params: { email } });
}

/**
 * 인증메일 발송요청
 * @param {string} email
 * @param {string} username
 */
export function sendCertEmail(email, username) {
  if (!validator.isEmail(email)) {
    throw new Error(`Invalid email address. [${email}]`);
  }
  if (validator.isEmpty(username)) {
    throw new Error(`'username' cannot be null or empty`);
  }
  return axios.post(FIREBASE_FUNCITON_URL.POST__SEND_CERT_EMAIL, { to: email, username });
}

/**
 * 인증메일 확인여부
 * @param code
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkCertEmail({ code = null, email }) {
  if (!validator.isEmail(email)) {
    throw new Error(`Invalid email address. [${email}]`);
  }

  if (validator.isEmpty(code)) {
    throw new Error(`'code' cannot be null or empty`);
  }

  return axios.post(FIREBASE_FUNCITON_URL.POST__CHECK_CERT_EMAIL, { code, email });
}

/**
 * 추천인 코드 유효성 검사 API 호출
 * @param {string} f_code 추천인 코드
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkFCode(f_code) {
  return axios.get(NODE_URL.GET__FCODE_CHECK, { params: { f_code } }).then((res) => res.data);
}

/**
 * 추천인 코드 소급적용
 * @param {string} f_code 추천인 코드
 * @param {string} uid 유저아이디
 * @returns {Promise<AxiosResponse<any>>}
 */
export function giveFCode({ f_code, uid }) {
  return axios.post(NODE_URL.POST__FCODE_POST, { f_code, uid }).then((res) => res.data);
}

/**
 * 닉네임 사용가능여부 확인
 * @param {string} nickname
 * @returns {Promise<void>}
 */
export function checkNickname(nickname) {
  if (validator.isEmpty(nickname)) return false;
  return axios.get(NODE_URL.GET__NICKNAME_CHECK, { params: { nickname } });
}

export function setNickname(nickname) {
  if (validator.isEmpty(nickname)) return false;
  return axios.put(FIREBASE_FUNCITON_URL.PUT__CALL_AUTH_MEMBER_SET_NICKNAME, { nickname });
}

/**
 * 프로필 이미지 변경
 */
export function setProfileImage(imageId) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__CALL_AUTH_MEMBER_SET_PROFILE_IMG, { image_id: imageId });
}

/**
 * 혜택/이벤트 알림 수신 동의 변경
 */
export function setTermsPush(push) {
  return axios.put(FIREBASE_FUNCITON_URL.POST__CALL_AUTH_MEMBER_SET_TERMS_PUSH, { terms: push });
}

/**
 * 쿠폰 다운로드
 * @param {array<string>} ids - 쿠폰 아이디 배열
 * @returns {Promise<boolean>}
 */
export async function downloadCoupons(ids = []) {
  if (ids.length === 0) {
    throw new Error(`Coupon 'ids' array cannot be empty.`, { cause: 'Empty ids' });
  }

  return axios.put(FIREBASE_FUNCITON_URL.PUT__DOWNLOAD_COUPON, { ids });
}

/**
 * 사용자의 사용가능한 쿠폰 조회
 * @deprecated OrderService.getAvailableCouponList로 교체할 것
 * @param {string | null} vendorId - 입점사(vendor) ID
 * @param {Array<string>} product_ids - 선택상품 id 배열
 * @param {Array<Object>} products - 선택상품 배열
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function fetchMyCouponList(vendorId, products = []) {
  console.warn('[deprecated] 사용자 사용가능 쿠폰목록 조회는 -> OrderService.getAvailableCouponList');
  return axios.post(NODE_URL.POST__COUPON__LIST, { vendor_id: vendorId, products });
}

/**
 * 사용자가 보유하고 있는 쿠폰목록 조회
 */
export async function fetchMyCouponDownloadList(options = {}) {
  options.current ??= 1;
  options.size ??= 100;
  return axios
    .post(NODE_URL.POST__MY_COUPON_DOWNLOAD_LIST, options)
    .then((response) => response.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 보유쿠폰 목록 API 호출 실패`,
          msg: `[${error.name}] ${error.message}`,
          which: `${window.location.pathname}${window.location.search}`
        })
          .then(console.log)
          .catch(console.error);
      }

      throw error;
    });
}

/**
 * 사용자의 사용가능한 쿠폰 카운트 조회
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function fetchMyCouponCount(uid) {
  return axios.get(NODE_URL.GET__COUPON__COUNT, { params: { uid } });
}

/**
 * 로그인한 사용자 정보 조회
 * @returns {Promise<DocumentData|null>}
 */
export async function getCurrentMember() {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  // firestore에서 로그인 사용자정보(member) 조회
  const memberSnapshot = await getDoc(doc(getFirestore(), 'member', auth.currentUser.uid)).catch((e) => {
    console.warn('[1kmwine] 로그인 사용자 정보조회 중 오류 [E859094].', e);
  });

  if (!memberSnapshot || !memberSnapshot.exists()) {
    return null;
  }
  return memberSnapshot.data(); // Login member data.
}

/**
 * 로그인 사용자의 FCM TOKEN값을 서버에 전달
 * @param token
 * @returns {null}
 */
export function setFcmToken(token) {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  console.info('[1kmwine] 사용자 fcm token 업데이트 요청. token=', token);
  axios
    .post(FIREBASE_FUNCITON_URL.POST__SET_FCM_TOKEN, { token })
    .then((response) => {
      console.log('[1kmwine] 사용자 fcm token 수정요청 응답.', response);
    })
    .catch((e) => console.error('[1kmwine] 사용자 fcm token 수정요청 실패.', e));
}

export async function removeFcmToken() {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  console.info('[1KMWINE] 사용자 fcm token 삭제 요청.');
  return axios
    .post(FIREBASE_FUNCITON_URL.POST__REMOVE_FCM_TOKEN)
    .then((response) => {
      console.debug('[1kmwine] 사용자 fcm token 삭제요청 응답.', response);
    })
    .catch((e) => console.error('[1kmwine] 사용자 fcm token 삭제요청 실패.', e));
}

/**
 * 와인 취향 저장
 *
 * API:
 *  - [call-auth-member-setMyTaste](https://clevercube.notion.site/call-auth-member-setMyTaste-7c9c392bc66646fda7f159f3951ca712)
 * @param {Array<Object>}tasteArray
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setMyTaste(tasteArray) {
  return axios.put(FIREBASE_FUNCITON_URL.PUT__SET_MY_TASTE, tasteArray);
}

/**
 * 구매상품 리뷰 쓰기
 * - [API 문서](https://clevercube.notion.site/call-product-review-create-492c5e0f8bd848c1ae310ea2d1f6a4c0)
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function productReviewCreate(requestBody) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PRODUCT_REVIEW_CREATE, requestBody);
}

/**
 * 키워드 알림 등록 요청
 * @param {string} text
 * @returns {Promise<void>}
 *
 * @see https://clevercube.notion.site/call-cdm-keyword-create-4c61136b9d1c4e86833eeee3bdef998e
 */
export async function cdmKeywordCreate(text) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__CALL_CDM_KEYWORD_CREATE, { text });
}

/**
 * 키워드 알림 삭제 요청
 * @param {array<string>} keywordIdArr 삭제할 키워드 ID 배열
 * @returns {Promise<AxiosResponse<any>>}
 *
 * @see https://clevercube.notion.site/call-cdm-keyword-delete-34abbdbc83a3430ba02df81780658e13
 */
export async function cdmKeywordDelete(keywordIdArr) {
  return axios.put(FIREBASE_FUNCITON_URL.PUT__CALL_CDM_KEYWORD_DELETE, { ids: keywordIdArr });
}

/**
 * 키위드 알림 업데이트
 * @param {string} id 키워드 ID
 * @param {boolean} active 알림 활성화 여부
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function cdmKeywordUpdate(id = '', active = false) {
  if (validator.isEmpty(id)) {
    throw Error(`Keyword ID cannot be null or empty.`, { cause: 'Empty keyword ID.' });
  }
  return axios.put(FIREBASE_FUNCITON_URL.PUT__CALL_CDM_KEYWORD_UPDATE, { id, active });
}

/**
 * 회원탈퇴
 * @param {string} msg 탈퇴사유
 * @returns {Promise<void>}
 */
export async function authMemberLeave(msg) {
  if (validator.isEmpty(msg)) {
    throw Error(`Withdrawal message('msg') cannot be null or empty.`, { cause: 'Empty withdraw message.' });
  }
  return axios.post(FIREBASE_FUNCITON_URL.POST__CALL_AUTH_MEMBER_LEAVE, { msg });
}

/**
 * 사용자 비밀번호 업데이트 요청
 * @param {string} uid
 * @param {string} email
 * @param {string | null} userCi
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updatePassword(uid, email, userCi, password) {
  return httpsCallable(getFirebaseFunctions(), 'call-auth-member-updatePassword')({ uid, email, user_ci: userCi, password });
}

/**
 * 사용자 휴대폰번호 업데이트 요청
 * @param {string} mobile 핸드폰 번호
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setMobile(mobile) {
  if (validator.isEmpty(mobile)) {
    throw Error(`Mobile number cannot be null or empty.`, { cause: 'Empty mobile.' });
  }
  return axios.put(FIREBASE_FUNCITON_URL.POST__CALL_AUTH_MEMBER_SET_MOBILE, { mobile }).then((response) => {
    const auth = getAuth();

    try {
      // 채널톡 프로필 업데이트
      if (response.data.result.code === 0 && auth.currentUser && window.ChannelIOInitialized) {
        console.debug('채널톡 고객 전화번호 업데이트: ', auth.currentUser, mobile);
        window.ChannelIO(
          'updateUser',
          {
            memberId: auth.currentUser.uid,
            profile: {
              mobileNumber: mobile
            }
          },
          function onProfileChanged(error, user) {
            console.log('--- user: ', user);
            console.log('--- error: ', error);
          }
        );
      }
    } catch (e) {
      console.warn('채널톡 사용자정보 업데이트 실패.', e);
    }
    return response;
  });
}

/**
 * 로그인 사용자의 최신 접속정보 갱신
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 조현권<hkcho@wineone.io>
 */
export async function updateLastConnectionTime() {
  try {
    const { currentUser } = getAuth();
    // 현재 로그인한 사용자가 없을 경우
    if (!currentUser) {
      console.warn(`[1KMWINE] 로그인 사용자가 없습니다. 접속정보를 갱신하지 않습니다.`);
      return undefined;
    }
    // console.debug(`[1KMWINE] 로그인 사용자의 최신 접속정보 갱신시작. [uid=${currentUser.uid}]`);
  } catch (e) {
    console.warn('사용자 로그인여부 확인 실패.', e);
    return undefined;
  }
  return axios
    .put(FIREBASE_FUNCITON_URL.PUT__CALL_AUTH_MEMBER_CONNECT)
    .then((response) => {
      // console.debug(`[1KMWINE] 로그인 사용자의 최신 접속정보 갱신완료.#430`, response);
      return response?.data?.result;
    })
    .catch((e) => {
      console.error(`[1KMWINE] 로그인 사용자의 최신 접속정보 갱신실패.`, e);
      return undefined;
    });
}

/**
 * 유저 차단 목록
 */
export async function blockUserList(body) {
  return axios
    .post(NODE_URL.POST__USER_BLOCK_LIST, { ...body })
    .then((response) => response.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 차단 목록 조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `blockUserList`,
          param: { location: window.location.href }
        }).catch(console.error);
      }

      throw error;
    });
}

/**
 * 유저 차단하기
 */
export async function blockUser(uid) {
  return axios
    .post(`${NODE_URL.POST__USER_BLOCK}/${uid}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 차단 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `blockUser`,
          param: { location: window.location.href }
        }).catch(console.error);
      }

      throw error;
    });
}

/**
 * 유저 차단 해제하기
 */
export async function blockUserCancel(uid) {
  return axios
    .delete(`${NODE_URL.DELETE__USER_BLOCK}/${uid}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 차단 해제 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `blockUserCancel`,
          param: { location: window.location.href }
        }).catch(console.error);
      }

      throw error;
    });
}
