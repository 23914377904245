import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { ReviewUpdateIcon } from 'components';

// material-ui
import { Box, Drawer, List, ListItem, ListItemButton, Typography } from '@mui/material';

/**
 * 리뷰 수정 하기 drawer
 * @returns {number}
 * @constructor
 *
 * @authors
 */
const ReviewUpdateDrawer = ({ open, onClose, action }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
          p: '12px 0'
        }
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <Box sx={{ width: 32, height: 6, borderRadius: '3px', bgcolor: '#8D81A0' }} />
      </Box>

      <List sx={{ px: '20px' }}>
        <ListItem disablePadding>
          <ListItemButton sx={{ p: '19px 0' }} onClick={() => action('report')}>
            <ReviewUpdateIcon />
            <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 400, lineHeight: 'normal' }}>수정하기</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default ReviewUpdateDrawer;

ReviewUpdateDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};
