import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';

// project imports
import { useAuth, useMobileNative, useScriptRef, useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { amplitude } from 'index';
import { AuthTitleHeader, BackButton } from 'components';
import { EmailSignUpForm } from './components';
import SnsSameEmailPage from './components/SnsSameEmailPage';
import { CLO_CODE } from '../../../../config';

// Material-ui
import { alpha } from '@mui/material/styles';
import { Box, CircularProgress, Container, Paper, Typography } from '@mui/material';

/** 회원정보 입력화면 */
export const MEMBER_VIEW = 1;

/**
 * 회원가입 페이지
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>
 */
function SignUpPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  const { getPreference } = useMobileNative();

  const { onNativeBackPressed } = useWineOne();
  const { register } = useAuth();

  React.useEffect(() => {
    // 올바르지 않은 접근
    if (!location.state?.result) {
      WoAlert.fire({
        title: '올바르지 않은 접근',
        html: `올바르지 않은 접근입니다.<br/>로그인 화면으로 이동합니다.`,
        customClass: { confirmButton: 'max-100' }
      }).then(() => {
        navigate('/auth/sign-in', { replace: true });
      });
    }
  }, [location.state?.result]);

  // 네이티브에 저장된 QR정보 로드 완료 여부
  const [qrVendorLoaded, setQrVendorLoaded] = React.useState(false);

  /**
   * Native에서 'wo.javascript'이벤트를 수신해서 처리합니다.
   * - window에 이벤트 리스너로 달리기 때문에 async로 수정하지 마십시오.
   *
   * @authors 조현권<hkcho@wineone.io>
   */
  const receiveEvents = (event) => {
    console.debug('[SignUpPage][receiveEvents] wo.javascript', event.detail);

    // event 처리 시작
    const { detail = null } = event;

    if (!detail) {
      console.warn(`Payload 'detail'를 통해 수신된 정보가 없습니다. 송신 데이터를 확인해주세요.`);
      setQrVendorLoaded(true);
      return false;
    }
    const { type } = detail;

    // Preference - AOS: SharedPreference, IOS: UserDefaults
    if (type === 'preference') {
      console.log(`-- Preference 정보 수신됨. detail=`, detail);
      const {
        result: { invite_vendor = null }
      } = detail;

      // 입점샵 QR로 앱설치한 사용자 (wo.javascript, preference, invite_vendor);
      if (invite_vendor) {
        setCustomer((prev) => ({ ...prev, invite_vendor, invite_vendor_type: 'QR' }));
      }

      setQrVendorLoaded(true);
    }
  };

  React.useLayoutEffect(() => {
    console.log("Event Listener 'wo.javascript' 등록");
    window.addEventListener('wo.javascript', receiveEvents, false);

    // Application install attribution 확인 - AOS: SharedPreference, IOS: UserDefaults
    getPreference('invite_vendor');
    // window.dispatchEvent(new CustomEvent('wo.javascript', {detail: {type: 'preference', result: {invite_vendor: 'O0ptYv5QUMTGjU5E4DFW'},from: 'Android'}}));

    return () => {
      console.log("Event Listener 'wo.javascript' 해제");
      window.removeEventListener('wo.javascript', receiveEvents);
    };
  }, []);

  // 회원가입 사용자 정보 확인완료 여부
  const [userDataChecked, setUserDataChecked] = React.useState(false);

  // MCASH 본인인증 결과
  const certResult = React.useMemo(() => location.state?.result ?? null, [location.state?.result]);

  // 마케팅 메시지 수신동의여부
  const push = certResult.terms?.push;

  // 기본정보 from 인증결과
  const {
    provider = '', // SNS 로그인 프로바이더
    uid = '', // Apple 로그인의 경우 uid 넘어옴, Kakao의 경우 sns_uid 넘어옴
    email = '' // SNS 로그인 사용자의 email 주소(optional),
  } = React.useMemo(() => certResult.user, [certResult.user]);

  // SNS 로그인 사용자 여부
  const isSnsUser = React.useMemo(() => provider === 'kakao' || provider === 'apple', [provider]);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`회원가입 정보입력 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);

    // 계정정보 화면에서 뒤로가기
    WoAlert.fire({
      title: '회원가입 취소',
      html: `<div style='line-height: 1.5rem; text-align: left'>회원가입을 취소하시겠습니까?</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '취소',
      cancelButtonText: '계속',
      customClass: { confirmButton: 'max-50', cancelButton: 'max-50' },
      icon: 'question'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/', { replace: true });
      }
    });
  }, [navigate]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // 회원정보: default
  const initialCustomer = React.useMemo(() => {
    const { user } = certResult;
    console.debug('[1KMWINE] MCASH 인증 정보.', certResult);

    return {
      provider: user.provider, // 로그인 프로바이더

      email: user.email ?? '', // 이메일 주소
      email_duplicated: user.email_duplicated ?? false, // 이메일 중복여부

      password: '', // 비밀번호

      // uname: user.name ?? '', // 사용자 실명
      snsUid: user.uid, // sns uid

      f_code: '', // 202210 추천인 코드
      invite_vendor: '', // v1.1.3 입점샵 QR 프로모션

      // 인증정보
      cert: {
        trade_id: location.state.result.trade_id // 상점 거래번호
      }
    };
  }, []);

  // 회원정보
  const [customer, setCustomer] = React.useState(initialCustomer);

  // 회원가입 요청 정보
  const [registerState, setRegisterState] = React.useState({
    requesting: false,
    error: null
  });

  // 회원정보 확인
  React.useEffect(() => {
    if (!qrVendorLoaded) {
      console.warn('와인샵 QR 정보가 아직 로드되지 않았습니다');
      return;
    }

    if (!userDataChecked) {
      console.warn('사용자 정보가 아직 로드되지 않았습니다');
      return;
    }

    if (isSnsUser) {
      // 이메일 주소가 중복되지 않는 사용자는 바로 회원가입 요청
      if (!initialCustomer.email_duplicated) {
        console.log('######## SNS 사용자 회원가입 요청 시작 ########');
        registerMember();
      } else {
        console.log(`######## 이메일 중복된 SNS(${initialCustomer.provider}) 사용자`);
      }
    }
  }, [initialCustomer, qrVendorLoaded, userDataChecked]);

  // Amplitude Email Verification End, Create Account End
  const amplitudeLogEvent = React.useCallback((event, successState, error) => {
    amplitude.getInstance().logEvent(event, {
      success: successState,
      err: error
    });
  }, []);

  React.useLayoutEffect(() => {
    // let script = null;
    if (!scriptedRef.current) {
      console.warn('[1KMWINE] Unmounted component.');
      return false;
    }

    if (provider === 'kakao') {
      setUserDataChecked(true);
    }

    // Apple 로그인을 통해 진입한 사용자
    else if (provider === 'apple') {
      setCustomer((customer) => {
        // 애플 계정에 이메일 주소가 있음
        if (email) {
          customer.uid = uid;
          customer.email = email;
          customer.emailVerified = true;
        }
        return { ...customer };
      });

      // Apple 로그인 사용자 회원정보 세팅완료
      setUserDataChecked(true);
    }

    // 이메일 회원가입
    else if (provider === '' || provider === 'email') {
      console.log('[1KMWINE] 이메일 회원가입 사용자');

      // 이메일 회원가입 사용자 회원정보 세팅완료
      setUserDataChecked(true);
    } else {
      console.warn(`[1kmwine] 정의되지 않은 authentication provider = '${provider}'`);
      WoAlert.fire(`알 수 없는 provider의 접근입니다.\n[${provider}]`, '', 'warning');
    }
  }, [scriptedRef]);

  /*
   * 계정 정보
   *********************************************************************************************************************
   */

  /*
   * 비밀번호 유효성
   * - sns 사용자의 경우 비밀번호를 사용하지 않으므로 초기값이 `true`
   */
  const [validPassword, setValidPassword] = React.useState(isSnsUser);

  /*
   * 회원 정보
   *********************************************************************************************************************
   */

  /**
   * 회원가입 요청
   * @returns {Promise<void>}
   * @authors 조현권<hkcho@wineone.io>
   */
  const registerMember: Promise<void> = async () => {
    // 요청상태
    setRegisterState({ requesting: true, error: null });

    console.log('# 회원가입 요청정보: ', { ...customer }, { push });
    // 회원가입 요청
    const response = await register({ ...customer }, { push }).catch((error) => {
      console.error('회원가입 실패', error);
      return { error };
    });

    // 회원가입 요청에러
    if (response.error) {
      const { error } = response;
      console.error('[1KMWINE] 회원가입 요청 실패.', error);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `회원가입 요청 에러`,
        msg: ` ${JSON.stringify(error)}`,
        param: {
          error
        }
      })
        .then(console.log)
        .catch(console.error);

      // 회원가입 실패 alert
      WoAlert.fire('', '회원가입 요청 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요.', 'error').then(() => {
        return false;
      });

      // 요청상태 돌려놓기
      setRegisterState(() => ({ requesting: false, error }));
      return false;
    }

    console.log('[1KMWINE] 회원 가입요청 응답. ', response);

    const { data, success, code } = response;

    // 회원가입 성공

    setRegisterState({ requesting: false, error: null });

    if (success) {
      amplitudeLogEvent('Create Account End', true, null);
      navigate(`/auth/sign-up/complete`, { replace: true, state: data });
    } else {
      console.log('[회원가입 실패]: ', code);
      amplitudeLogEvent('Create Account End', false, code);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `회원가입 실패`,
        msg: ` ${JSON.stringify(data)}`,
        param: { result: data }
      })
        .then(console.log)
        .catch(console.error);

      setRegisterState(() => ({ requesting: false, error: code }));
      if (code === 'invalid.registered_member') {
        await WoAlert.fire('이미 가입이 완료되었습니다.', `로그인 화면으로 이동합니다.`, 'info');
        navigate('/auth/sign-in', { replace: true });
      } else if (code === 'invalid.not_adult') {
        await WoAlert.fire(
          '청소년 가입불가',
          `정보통신망이용촉진 및 정보보호등에 관한 법률 및 청소년보호법의 규정에 의하여 19세 미만의 청소년이 이용할 수 없습니다`,
          'warning'
        );
        navigate('/auth/sign-in', { replace: true });
      } else {
        await WoAlert.fire('', `회원가입 요청 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요.<br/>[${code}]`, 'error');
        setRegisterState(() => ({ requesting: false, error: code }));
      }
    }
  };

  // AuthTitleHeader subtitle
  const subtitle = React.useMemo(() => {
    if (!userDataChecked) return '회원가입 정보를 확인하고 있습니다';
    if (!isSnsUser) {
      return registerState.error === null ? '아이디와 비밀번호를 입력해주세요' : '회원가입에 실패했습니다';
    }
    if (customer.email_duplicated) return '이메일 주소를 확인해주세요';
    if (isSnsUser && registerState.requesting) return '회원가입 요청 중 입니다';
    if (registerState.error) return '회원가입에 실패했습니다';
    return '';
  }, [isSnsUser, userDataChecked, customer.email_duplicated, registerState.error, registerState.requesting]);

  // render
  return (
    <>
      {/* 회원가입 사용자 정보 확인 중일 때 */}
      <Box
        position="fixed"
        width="100vw"
        height="calc(var(--vh, 1vh) * 100)"
        display={!userDataChecked || !qrVendorLoaded ? 'flex' : 'none'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
        sx={(theme) => ({
          background: alpha(theme.palette.background.paper, 0.6),
          backdropFilter: 'blur(5px)'
        })}
      >
        <CircularProgress color="brand" />
        <Typography variant="caption" sx={{ mt: '14px' }}>
          회원가입 정보를 확인하고 있습니다
        </Typography>
      </Box>

      <Box
        position="fixed"
        width="100vw"
        height="calc(var(--vh, 1vh) * 100)"
        display={registerState.requesting ? 'flex' : 'none'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
        sx={(theme) => ({
          background: alpha(theme.palette.background.paper, 0.6),
          backdropFilter: 'blur(5px)'
        })}
      >
        <CircularProgress color="brand" />
        <Typography variant="caption" sx={{ mt: '14px' }}>
          회원가입 요청 중 입니다
        </Typography>
      </Box>

      <Paper component="main" square elevation={0}>
        <Container maxWidth="xs">
          <Box display="flex" flexDirection="column" sx={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
            <Box height="54px" display="flex" alignItems="center">
              {/* 뒤로가기 버튼 */}
              <BackButton color="inherit" onClick={pageBackFunc} />
            </Box>

            <AuthTitleHeader subtitle={subtitle} />

            {/* 이메일 주소가 중복된 sns 사용자일 경우 */}
            {userDataChecked && isSnsUser && customer.email_duplicated && (
              <>
                <SnsSameEmailPage customer={customer} setCustomer={setCustomer} registerMember={registerMember} />
              </>
            )}

            {!isSnsUser && (
              <EmailSignUpForm
                customer={customer}
                setCustomer={setCustomer}
                validPassword={validPassword}
                setValidPassword={setValidPassword}
                registerMember={registerMember}
              />
            )}
          </Box>
        </Container>
      </Paper>
    </>
  );
}

export default SignUpPage;

function getSnsLabel(snsProvider) {
  let label = 'SNS';
  if (snsProvider === 'kakao') {
    label = '카카오';
  } else if (snsProvider === 'apple') {
    label = '애플';
  }
  return label;
}
