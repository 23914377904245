import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { openSnackbar } from 'store/slices/snackbar';
import { blockUser } from 'services/UserService';
import { getReviewThreadList } from 'services/ReviewThreadService';
import BackHeader from 'layouts/Main/BackHeader';
import ReviewReportDialog from '../../../../mall/wine/WineInfo/components/ReviewView/components/ReviewReportDialog';
import { ReviewCreateButton, UserBlockIcon } from 'components';
import {
  ReviewThreadCard,
  ReviewReportDrawer,
  ReviewUpdateDrawer,
  ReviewThreadError,
  ReviewThreadEmpty,
  ReviewImageViewer
} from './components';
import { PdataInfo } from 'components/pdata';

// material-ui
import { Container, Box, CircularProgress, Stack, Typography, Fade, AlertTitle } from '@mui/material';
import { useTheme } from '@mui/styles';

/**
 * 리뷰 쓰레드 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewThread = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const globalDispatch = useDispatch();
  const location = useLocation();

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('rv_thread_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('rt_showPdataInfo', false);

  // 수정/신고/차단할 리뷰
  const [selectReview, setSelectReview] = React.useState({ type: '', reviewId: '' });
  // 리뷰 수정 drawer
  const [reviewUpdateDrawer, setReviewUpdateDrawer] = React.useState(false);
  // 리뷰 신고/차단 drawer
  const [reviewReportDrawer, setReviewReportDrawer] = React.useState(false);
  // 리뷰 신고 modal
  const [reviewReportModal, setReviewReportModal] = React.useState(false);

  // 이미지 미리보기 인덱스 번호
  const [currentImage, setCurrentImage] = React.useState(0);
  // 이미지 뷰어 오픈 여부
  const [viewerOpen, setViewerOpen] = useLocationState('rv_thread_viewOpen', false);
  // 이미지 미리보기 배열
  const [images, setImages] = React.useState([]);

  // 리뷰 썸네일 이미지 클릭 이벤트 콜백
  const handleThumbnailClickEvent = (images, index) => {
    setImages(images);
    setCurrentImage(index);
    setViewerOpen(true, { method: 'push' });
  };

  // 이미지 뷰어 닫기
  const closeImageViewer = () => {
    setCurrentImage(0);
    setViewerOpen(false);
  };

  // 페이지네이트 기본정보
  const initialPage = Object.freeze({
    current: 1,
    total_pages: 0,
    total_results: 0,
    size: 10 // 한번에 호출할 상품 개수
  });

  const initialRowState = Object.freeze({
    loaded: false,
    page: initialPage,
    items: [],
    empty: false
  });

  // 조회된 리뷰 쓰레드 데이터
  const [rows, setRows] = useLocationState('reviewThread', initialRowState);
  // 검색상태
  const [searchState, setSearchState] = React.useState({
    searched: rows.loaded,
    searching: false,
    error: false
  });

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  React.useEffect(() => {
    if (!rows.loaded) getReviewThread(1);
  }, [rows.loaded]);

  // 리뷰 쓰레드 목록 조회
  const getReviewThread = async (current) => {
    // 이미 요청 중
    if (current === 1 && searchState.searching) {
      console.warn('[ReviewThread][getReviewThread] 중복 요청입니다. rows: ', rows);
      return false;
    }

    // 검색 상태로 변경
    setSearchState({ searching: true, error: false });

    const body = {
      page: {
        current,
        size: rows.page.size
      },
      sort: 'new'
    };

    const result = await getReviewThreadList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[ReviewThread][getReviewThread] Unmounted component.');
      return undefined;
    }

    // 리뷰 쓰레드 목록 조회 중 오류 발생
    if (result.error) {
      setSearchState({ searched: true, searching: false, error: true });
      console.error('[ReviewThread][getReviewThread] 리뷰 쓰레드 목록 조회 중 오류 발생 >: ', result.error);
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response 값이 반환됨.
    if (code !== 0) {
      setRows({ ...rows, error: true, loading: false });
      console.error('[ReviewThread][getReviewThread] code: ', code, ', msg: ', msg);
      return undefined;
    }

    // 1페이지 요청 시 리스트 초기화
    const reviewThreads = current === 1 ? [...result.data] : [...rows.items, ...result.data];
    // 목록이 비어있을 경우
    const empty = current === 1 && result.data.length === 0;

    setRows({
      loaded: true,
      page: result.page,
      items: reviewThreads,
      empty
    });

    setSearchState({ searched: true, searching: false, error: false });
  };

  // pdata 상세정보 보기
  const moveToPdataInfo = (initTab, pdata) => {
    if (showPdataInfo) return false;

    if (!pdata) {
      console.warn('아직 리뷰정보가 로드되지 않았습니다.');
      return false;
    }

    if (!pdata?.id) {
      console.warn('[ReviewThread][moveToPdataInfo] 상세정보할 pdata의 id가 없습니다.');
      WoAlert.fire('', '상품 정보를 불러오는 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    setDetailPdataId(pdata?.id);
    setShowPdataInfo(true, { method: 'push' });
  };

  // 스크롤 이벤트
  const handleScrollEvent = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
      if (y < window.scrollY) {
        // 스크롤이 콘텐츠 끝에 거의(-185) 닿았을 때
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 185) {
          if (!searchState.searched) return; // 최초 조회시 중복요청 제외
          if (searchState.searching) return; // 페이지 요청 중일 경우 pass
          // 다음 페이지 호출
          if (rows.page.current < rows.page.total_pages) {
            console.log(`[ReviewThreadList][handleScrollEvent] 다음 리뷰 쓰레드 페이지 호출 (${rows.page.current + 1})`, rows.page);
            getReviewThread(rows.page.current + 1);
          }
          // 이미 검색결과를 모두 보여준 상태
          else {
            console.log('[1KMWINE] 모든 리뷰 쓰레드를 출력했습니다.');
          }
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  // 선택한 리뷰가 수정 또는 신고/차단인지 확인
  const reviewAction = (type, reviewId) => {
    setSelectReview(reviewId);
    if (type === 'update') {
      setReviewUpdateDrawer(true);
    } else {
      setReviewReportDrawer(true);
    }
  };

  // 리뷰 신고 및 차단
  const reviewReport = (type) => {
    // 리뷰 차단
    if (type === 'block') {
      WoAlert.fire({
        title: (
          <Box display="flex" alignItems="center">
            <UserBlockIcon sx={{ mr: '5px' }} />
            <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
              사용자 차단
            </Typography>
          </Box>
        ),
        html: `<div style='line-height: 1.5rem; text-align: left'>이 사용자를 차단 하시겠습니까?<br />사용자의 모든 콘텐츠가 숨김처리 됩니다.</div>`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '차단하기',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        },
        preConfirm: () => {
          WoAlert.showLoading();
          return blockUser(selectReview.uid)
            .then((response) => {
              const { code, msg } = response.result;
              return { code, msg };
            })
            .catch((error) => {
              console.error('[1kmwine] 사용자 차단요청 실패', error);
              WoAlert.fire(`사용자 차단요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
              return { code: 99, msg: '사용자 차단요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.' };
            });
        }
      }).then((result) => {
        if (!result.isConfirmed) return undefined;
        const { code, msg } = result.value;
        // 차단 성공
        if (code === 0 || code === 3) {
          // 차단 성공 후 리뷰 쓰레드 배열 재정렬
          setRowItems('block');
          globalDispatch(
            openSnackbar({
              open: true,
              message: (
                <>
                  <AlertTitle>사용자 차단 완료</AlertTitle>
                  <Typography variant="caption">
                    사용자가 차단 되었습니다. 차단 해제는 '마이 페이지' > '앱 설정'에서 설정 가능합니다.
                  </Typography>
                </>
              ),
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: true,
              autoHideDuration: 6000
            })
          );
        }

        // 작성자 차단 실패
        else {
          globalDispatch(
            openSnackbar({
              open: true,
              message: (
                <>
                  <AlertTitle>사용자 차단 오류</AlertTitle>
                  <Typography variant="caption">
                    {/* 사용자가 차단 되었습니다. 차단 해제는 '마이 페이지' > '앱 설정'에서 설정 가능합니다. */}
                    {msg}
                  </Typography>
                </>
              ),
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: true,
              autoHideDuration: 4000
            })
          );
        }
      });
    }

    // 리뷰 신고
    else if (type === 'report') {
      setReviewReportModal(true);
    }
  };

  // 리뷰 신고 및 차단 후 리뷰 쓰레드 배열 재정렬 (신고/차단 데이터 제외 후 표현)
  const setRowItems = async (type = 'report') => {
    // 표시 안할 리뷰 id
    const { review_id, uid } = selectReview;

    let tempItems = [];
    // 신고 (단일 블러 처리)
    if (type === 'report') {
      tempItems = rows.items.map((item) => {
        if (item.review_id === review_id) {
          item.blur = true;
          item.blur_type = 'report';
        }
        return item;
      });
    }
    // 차단 (복수 블러 처리)
    else if (type === 'block') {
      tempItems = rows.items.map((item) => {
        if (item.uid === uid) {
          item.blur = true;
          item.blur_type = 'block';
        }
        return item;
      });
    }

    await setRows({ ...rows, items: tempItems });
    await setReviewReportDrawer(false);
  };

  // 홈 화면에서 선택한 카드로 스크롤 이동
  React.useEffect(() => {
    if (!searchState.searched && searchState.error && !rows.loaded && rows.empty) return;
    if (!location?.state?.cardIndex) return;

    const { cardIndex } = location.state;

    if (!cardIndex) return;

    const targetCard = document.getElementById(`review-thread-${cardIndex}-card`);

    if (!targetCard) return;

    // 홈 화면에서 선택한 카드 영역으로 스크롤 이동
    targetCard.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

    setTimeout(() => {
      location.state = { ...location.state, cardIndex: null };
    }, 300);
  }, [location, rows, searchState]);

  const [openSelectPdata, setOpenSelectPdata] = React.useState(false);

  // render
  return (
    <BackHeader title="유저의 리얼 리뷰" showFooter={false} showBottomNav={false}>
      {/* 리뷰 쓰레드 목록 조회 중 오류 발생 */}
      {searchState.searched && searchState.error && (
        <ReviewThreadError reloadList={() => getReviewThread(1)} contentHeight="calc(100vh - 56px)" />
      )}

      {/* 리뷰 쓰레도 목록이 비어있음 */}
      {rows.loaded && rows.empty && <ReviewThreadEmpty contentHeight="calc(100vh - 56px)" />}

      {/* 리뷰 쓰레도 목록이 존재함 */}
      {rows.loaded && !rows.empty && (
        <Box>
          <Container maxWidth="xs" sx={{ pb: '12px', bgcolor: '#F3F2F5' }}>
            <Stack spacing={1}>
              {rows.items.map((item, index) => (
                <Box id={`review-thread-${index}-card`} key={`review-thread-${index}-card`}>
                  <ReviewThreadCard
                    review={item}
                    detailPdataId={(pdata) => moveToPdataInfo('product', pdata)}
                    selectReview={(type, review) => reviewAction(type, review)}
                    handleThumbnailClickEvent={handleThumbnailClickEvent}
                  />
                </Box>
              ))}
            </Stack>
          </Container>
        </Box>
      )}

      {/* 리뷰 쓰레드 목록 조회 중 */}
      {searchState.searching && (
        <Fade in>
          <Box textAlign="center" mt={2}>
            <CircularProgress size={30} color="brand" />
          </Box>
        </Fade>
      )}

      {/* 리뷰쓰기 버튼 */}
      <Stack
        direction="row"
        alignItems="center"
        spacing="4px"
        sx={{
          position: 'fixed',
          bottom: 40,
          right: 20,
          zIndex: 1,
          py: '14px',
          px: '20px',
          borderRadius: '24px',
          bgcolor: theme.palette.brand.main,
          boxShadow: '-1px 3px 10px 2px rgba(0, 0, 0, 0.26)'
        }}
        onClick={() => navigate('/my/review/select/pdata')}
      >
        <ReviewCreateButton />
        <Typography
          sx={{
            fontSize: 16,
            color: theme.palette.brand.contrastText,
            fontWeight: 700,
            lineHeight: 'normal'
          }}
        >
          리뷰쓰기
        </Typography>
      </Stack>

      {/* pdata 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />

      {/* 이미지 상세보기 */}
      <ReviewImageViewer open={viewerOpen} onClose={closeImageViewer} images={images} currentIndex={currentImage} />

      {/* 리뷰 수정 drawer */}
      <ReviewUpdateDrawer
        open={reviewUpdateDrawer}
        onClose={() => setReviewUpdateDrawer(false)}
        action={() => navigate(`/my/review/${selectReview?.review_id}/modify`)}
      />

      {/* 리뷰 신고/차단 하기 drawer */}
      <ReviewReportDrawer open={reviewReportDrawer} onClose={() => setReviewReportDrawer(false)} action={(type) => reviewReport(type)} />

      {/* 리뷰 신고 modal */}
      <ReviewReportDialog
        pdata={{ id: selectReview.pdata_id }}
        review={selectReview}
        onClose={() => setReviewReportModal(false)}
        open={reviewReportModal}
        value={''}
        keepMounted={false}
        setRowItems={setRowItems}
      />
    </BackHeader>
  );
};

export default ReviewThread;
