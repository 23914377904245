import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { WINE_ORDER } from 'config';
import { BottomDialog, WoDialogTitle, WoDivider } from '../../../../../components/dialog';
import { Checkbox } from '../../../../../components';

// material-ui
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider, Grid, List, ListItem, ListItemButton, ListItemText, Slide } from '@mui/material';
import { useAuth } from '../../../../../hooks';

/**
 * 상품 정렬 다이어로그
 * @param props
 * @returns {JSX.Element}
 *
 * @authors 조현권<hkcho@wineone.io>, 제동균<jedk@wineone.io>
 */
function OrderDialog(props) {
  const { id = 'order-sort-option-dialog', onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);

  const { user } = useAuth();

  // 정렬옵션
  const options = React.useMemo(() => {
    const o = [...WINE_ORDER];
    // todo delete: 테스트 완료 후 주석 풀기
    // if (user?.god) o.push({ label: '높은 가격순', value: 'highp' }); // v1.0.9: (임시) god 모드 사용자에게만 높은 가격순
    return Object.freeze(o);
  }, [user.god]);

  React.useEffect(() => {
    // 다이어로그가 닫힐 때, 이미 선택되어 있던 값으로 되돌리기
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };
  /**
   * 정렬값 선택 후 확인
   */
  const handleOk = () => {
    let selected = { value, label: 'unknown' };

    options.forEach(({ label, value: newVal }) => {
      if (value === newVal) {
        selected = { value: newVal, label };
      }
    });

    onClose(selected.value, selected.label);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // render
  return (
    <BottomDialog open={open} keepMounted id={id} maxWidth="xs" onClose={handleCancel} {...other}>
      <WoDialogTitle title="상품 정렬" onClickClose={handleCancel} />
      <WoDivider />
      <DialogContent sx={{ py: 0 }}>
        <List dense sx={{ width: '100%' }} disablePadding>
          {options.map((option, i) => (
            <React.Fragment key={option.value}>
              {i > 0 && <Divider component="li" />}
              <ListItem
                dense
                secondaryAction={
                  <Checkbox
                    edge="end"
                    variant="flat"
                    checked={option.value === value}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': option.label }}
                    value={option.value}
                  />
                }
                disablePadding
                sx={{ height: 64 }}
              >
                <ListItemButton
                  value={option.value}
                  onClick={() => {
                    setValue(option.value);
                  }}
                  sx={{ height: '100%' }}
                >
                  <ListItemText
                    primary={option.label}
                    primaryTypographyProps={{ fontSize: '18px', letterSpacing: '-0.18px', lineHeight: 'normal' }}
                  />
                </ListItemButton>
              </ListItem>
              {/* <Divider component="li" /> */}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" size="x-large" color="brand" onClick={handleCancel}>
              취소
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" size="x-large" color="brand" onClick={handleOk}>
              확인
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BottomDialog>
  );
}

OrderDialog.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

export default OrderDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
