import React from 'react';

// project imports
import { SearchIcon } from '../icons';

// material-ui
import { IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/**
 * Search Button
 *
 * @param {number|string} width
 * @param {number|string} height
 * @param {string} color
 * @param {'end'|'start'|false} edge
 * @param {function} onClick
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
/* eslint-disable react/prop-types */
function SearchButton({ width = 34, height = 34, color: colorProp, edge = false, onClick, ...other }) {
  const theme = useTheme();
  return (
    <ButtonBase aria-label="검색" edge={edge} onClick={onClick} {...other}>
      <SearchIcon color={colorProp ?? theme.palette.text.primary} width={width} height={height} />
    </ButtonBase>
  );
}

export default SearchButton;

const ButtonBase = styled((props) => <IconButton {...props} />)`
  padding: 0;
`;
