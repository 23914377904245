import React from 'react';

// project imports
import { CategoryBox } from './components';
import { PDATA_CATEGORY } from 'config';

// material-ui
import { Grid } from '@mui/material';

// assets
import WineIcon from './components/assets/img_home_ctg_wine.png';
import WhiskeyIcon from './components/assets/img_home_ctg_whiskey.png';
import NihonshouIcon from './components/assets/img_home_ctg_nihonshu.png';
import OfferIcon from './components/assets/img_home_ctg_plan.png';

/**
 * 홈 화면 -> 카테고리 영역
 *
 * @authors 최효근 <hkchoi@wineone.io>, 이재일 <leeji@wineone.io>
 */

function CategoryArea() {
  return (
    <Grid container spacing={1} mt="20px" px="20px">
      <Grid item xs={3}>
        <CategoryBox category={PDATA_CATEGORY.WINE} icon={WineIcon} />
      </Grid>
      <Grid item xs={3}>
        <CategoryBox category={PDATA_CATEGORY.WHISKEY} icon={WhiskeyIcon} />
      </Grid>
      <Grid item xs={3}>
        <CategoryBox category={PDATA_CATEGORY.NIHONSHU} icon={NihonshouIcon} />
      </Grid>
      <Grid item xs={3}>
        <CategoryBox category={PDATA_CATEGORY.OFFER} icon={OfferIcon} />
      </Grid>
    </Grid>
  );
}

export default CategoryArea;
