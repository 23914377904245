/**
 * 테마 카드 상품 목록 페이지
 * 홈 > 테마 카드 > 더보기
 * @returns {JSX.Element}
 * @constructor
 */
import React from 'react';
import { useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { useSelector } from 'store';
import { useAmplitude, useScriptRef, useWineOne } from 'hooks';
import { PRODUCT } from 'config';
import { getThemeDetail } from 'services/ContentService';
import {
  BackButton,
  ProducerTypo,
  ProductCurrency,
  ProductLabel,
  ProductName,
  ProductPrice,
  ReviewPointSimple,
  SpaceBetweenBox,
  WishButton,
  WishCountSimple
} from 'components';
import { BottleIcon } from 'components/icons';
import { ProductImage } from 'components/v2';
import { ToolbarTitle } from 'components/toolbar';
import { ProductChipStack } from '../../../../../mall/MallIndex/components/ProductList/components/ProductCard/components';

// material-ui
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { common } from '@mui/material/colors';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

// icons
import discountImg from '../../../../../my/cart/MyCart/CartItem/ico_shop_list_discount.png';
import cardNoImg from '../assets/card_img_notfound.png';

const initialFetchState = Object.freeze({
  loading: false,
  error: false,
  done: false
});

const IMG_WIDTH = 107; // 상품 썸네일 이미지 가로길이
const IMG_HEIGHT = 148; // 상품 썸네일 이미지 세로길이

const aspectRatio = '1/0.7';

/**
 * 홈 테마 카드 상세페이지
 * @returns {JSX.Element}
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>
 */
function ThemeDetail() {
  const scriptedRef = useScriptRef();
  // 테마 카드
  const { themeId } = useParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();

  const { logEvent } = useAmplitude();

  const { onNativeBackPressed } = useWineOne();

  // 검색 상태
  const [fetchState, setFetchState] = React.useState(initialFetchState);

  // 테마 정보
  const [themeCard, setThemeCard] = React.useState(null);
  const [products, setProducts] = useLocationState('theme-products', []); // 상품정보

  // 사용자 위치정보, 서비스 상수
  const { userLocation } = useSelector((state) => state);

  // 테마 타이틀
  const themeTitle = React.useMemo(() => {
    if (location?.state?.title) {
      return location.state.title;
    }
    return '';
  }, [location]);

  React.useLayoutEffect(() => {
    if (userLocation?.loading) {
      console.warn('[ThemeDetail] 사용자 위치정보를 가져오고 있습니다.');
    } else {
      fetchThemeDetail();
    }
  }, [themeId, userLocation?.loading]);

  // 테마 카드 상세정보 가져오기
  const fetchThemeDetail: Promise<void> = React.useCallback(async () => {
    setFetchState({ ...fetchState, loading: true, error: false });

    const result = await getThemeDetail(themeId, userLocation.coord).catch((error) => ({ error }));
    if (!scriptedRef.current) {
      console.warn('[ThemeDetail][fetchThemeDetail] Unmounted component.');
      return undefined;
    }

    // 테마 상세정보 조회 중 오류 발생
    if (result.error) {
      const { error } = result;
      console.error('[테마 상세] 테마 상세정보 조회 중 오류 발생 > ', error);
      setFetchState({ done: false, loading: false, error });
      return undefined;
    }

    const { theme, products = [] } = result;

    setThemeCard(theme); // 테마 카드 상세 정보
    setProducts(products); // 테마 상품 정보
    setFetchState({ done: true, loading: false, error: false });
  }, [themeId, userLocation?.coord]);

  // 정사각 coverImage ref
  const coverImageRef = React.useRef(null);

  const coverHeight = React.useMemo(() => {
    return coverImageRef.current ? coverImageRef.current.offsetHeight - 56 : window.screen.width;
  }, [coverImageRef.current]);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: coverHeight
  });

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`테마 상세화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // 컨테이너 minHeight
  const aspectHeight = React.useMemo(() => {
    const minHeight = 220;
    if (!coverImageRef.current) return minHeight;
    const [, aHeight] = aspectRatio.split('/').map((v) => Number(v));
    const calculated = coverImageRef.current.offsetWidth * aHeight;
    return calculated < minHeight ? minHeight : calculated;
  }, [coverImageRef.current, themeCard?.card_img]);

  // 와인 클릭 이벤트
  const selectWine = (pdata, priceMin, sellInPin, onPromo = false) => {
    // Amplitude Select Wine (home theme card)
    logEvent.selectWine(`home theme card ${themeCard.keyword}`, pdata?.name?.ko);

    // 전지역 주문가능 상점이 나타나도록 조회
    navigate(`/mall/wine/${pdata._id}?pin=${PRODUCT.BIT.ALL}`, {
      state: { pdata, priceMin, hasProduct: { inPin: sellInPin, outOfPin: !sellInPin }, onPromo }
    });
  };

  // 상품목록 memoize
  const productListComponent = React.useMemo(() => {
    return (
      products.length > 0 && (
        <Stack spacing="24px">
          {products.map((product, i) => {
            const { pdata } = product;
            return (
              <Fade in appear={navigationType === 'PUSH'} style={{ transitionDelay: `${i * 10}ms` }} key={`crt-card-item-${i}`}>
                <Card variant="outlined" sx={{ border: 'none' }}>
                  <Box>
                    <Box component="section" display="flex" width="100%" sx={{ flexDirection: 'row' }}>
                      {/* 상품 이미지 */}
                      <Box width={IMG_WIDTH} height={IMG_HEIGHT} mr={1.25}>
                        <ButtonBase
                          sx={{ width: '100%', height: '100%' }}
                          onClick={() => {
                            selectWine(pdata, product.price?.minimum ?? null, product.distance?.minimum <= 1000, product.on_promo > 0);
                          }}
                        >
                          <ProductImage width="100%" height="100%" src={pdata?.bottle_img?.thumb} alt="상품이미지">
                            {product.distance?.minimum > 1000 && (
                              <ProductLabel height={26} fontSize={9}>
                                다른 지역 주문 가능
                              </ProductLabel>
                            )}
                            {product.quantity > 0 ? (
                              <ProductLabel bgColor="#A49FACBF" height={26} fontSize={9}>
                                입고 준비 중
                              </ProductLabel>
                            ) : null}
                            {/* 프로모션 진행 중인 상품일 경우 */}
                            {product.on_promo ? (
                              <Box
                                sx={(theme) => ({
                                  position: 'absolute',
                                  top: '4px',
                                  left: '4px',
                                  bgcolor: theme.palette.brand.main,
                                  py: '4px',
                                  px: '5px',
                                  borderRadius: '11px',
                                  display: 'flex',
                                  alignItems: 'center'
                                })}
                              >
                                <Box
                                  component="img"
                                  src={discountImg}
                                  sx={{ width: '12px !important', height: '13px !important', mr: '2px' }}
                                />
                                <Typography sx={{ color: '#fff', fontSize: '10px', fontWeight: 600, lineHeight: 1 }}>할인</Typography>
                              </Box>
                            ) : null}
                          </ProductImage>
                        </ButtonBase>
                      </Box>
                      {/* 상품 정보 */}
                      <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        width={(theme) => `calc(100% - ${IMG_WIDTH}px - ${theme.spacing(1.25)})`}
                      >
                        <Box>
                          <SpaceBetweenBox height="24px">
                            <ProducerTypo>
                              {pdata.producer?.ko.trim() ? pdata.producer.ko : <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>}
                            </ProducerTypo>

                            {/* Wish 포함여부 */}
                            <WishButton aria-label="위시 와인에 추가" pdataId={pdata.id ?? pdata._id} pdataName={pdata?.name?.ko ?? ''} />
                          </SpaceBetweenBox>
                          {/* 상품명 (한글) */}
                          <Box mt={0.75}>
                            <ProductName>{pdata?.name?.ko}</ProductName>
                          </Box>
                        </Box>

                        <Box>
                          {/* Price */}
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {product.product_count > 0 ? (
                              <>
                                <ProductPrice>{product.price.minimum.toLocaleString()}</ProductPrice>
                                <ProductCurrency multiple={product.product_count > 0} pl="1px" />
                              </>
                            ) : (
                              <Typography component="span" fontSize="11px" color="text.secondary" sx={{ py: '4px' }}>
                                [ 상품 입고가 곧 준비됩니다 ]
                              </Typography>
                            )}
                          </Box>

                          <Grid container display={'flex'} alignItems={'center'} spacing={1}>
                            <Grid item xs={'auto'}>
                              {/* 리뷰 점수 */}
                              <ReviewPointSimple point={pdata.star ?? 0} count={pdata.reviews ?? 0} />
                            </Grid>
                            <Grid item xs={'auto'}>
                              {/* 좋아요 개수 */}
                              <WishCountSimple count={pdata.wishes ?? 0} />
                            </Grid>
                          </Grid>

                          <Box
                            className="none-scroll-bar"
                            sx={{ maxWidth: '100%', height: 24, width: '100%', overflowX: 'auto', mt: '6px' }}
                          >
                            <ProductChipStack pdata={pdata} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Fade>
            );
          })}
        </Stack>
      )
    );
  }, [products]);

  // render
  return (
    <Box component="main" bgcolor="background.paper" minHeight="calc(var(--vh, 1vh) * 100)">
      {/* header */}
      <AppBar position="fixed" sx={(theme) => ({ bgcolor: trigger ? alpha(theme.palette.brand.main, 0.5) : 'transparent' })}>
        <Container maxWidth="xs" sx={{ p: [0, 0] }}>
          <Toolbar>
            <BackButton color={common.white} onClick={pageBackFunc} />
            <ToolbarTitle color={common.white}>{trigger ? themeCard?.keyword ?? '' : null}</ToolbarTitle>
          </Toolbar>
        </Container>
      </AppBar>
      {/* 키워드 & 상세설명 */}
      <Container
        ref={coverImageRef}
        maxWidth="xs"
        sx={{ p: [0, 0], position: 'relative', overflow: 'hidden', minHeight: aspectHeight, aspectRatio }}
      >
        <Box className="wine-discovery-cover" position="relative" width="100%" height="100%">
          <LazyLoadImage
            placeholder={<Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />}
            src={themeCard?.card_img?.original?.url}
            width="100%"
            height="100%"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center'
            }}
            onError={(e) => {
              // e.target.onerror = null;
              e.target.src = `${cardNoImg}`;
            }}
          />
          <Box position="absolute" top={0} right={0} left={0} bottom={0} bgcolor={common.black} sx={{ opacity: 0.5 }} />

          {/* 테마 제목영역 [start] */}
          <ThemeTitle height={aspectHeight} aspectRatio={aspectRatio}>
            <Typography fontSize="18px" fontWeight={800} letterSpacing="-0.3px">
              {themeTitle}
            </Typography>
            <Typography fontSize="16px" lineHeight="1.4" whiteSpace="pre" textAlign="center" sx={{ mt: '24px', maxWidth: '75%' }}>
              {themeCard?.detail_desc?.replaceAll('\\n', '\n')}
            </Typography>
          </ThemeTitle>
          {/* 테마 제목영역 [end] */}
        </Box>
      </Container>

      <Container maxWidth="xs" sx={{ pt: '24px', pb: '16px' }}>
        {/* 테마 상품 목록 */}
        {productListComponent}

        {/* 테마 상품 목록 로딩 중 */}
        {fetchState.loading && !fetchState.done && (
          <Box textAlign="center" py="16px">
            <CircularProgress size="32px" color="brand" />
          </Box>
        )}

        {/* 테마의 상품 목록이 없을 경우 */}
        {!fetchState.loading && fetchState.done && products.length === 0 && (
          <Box mt="80px" display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
            <BottleIcon />
            <Box mt="60px" textAlign="center">
              <Typography fontSize="18px" fontWeight="bold">
                등록된 상품이 없습니다.
              </Typography>
            </Box>
          </Box>
        )}

        {!fetchState.loading && fetchState.error && (
          <Box width="100%" height="40vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mt={2}>
              <Button variant="contained" startIcon={<ReplayOutlinedIcon />} onClick={fetchThemeDetail}>
                재시도
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default ThemeDetail;

// eslint-disable-next-line react/prop-types
const ThemeTitle = React.memo(({ children, height, aspectRatio }) => (
  <Box
    className="theme-cart-title square-area"
    position="absolute"
    top={0}
    right={0}
    left={0}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    zIndex={1}
    width="100%"
    height={height}
    aspectRatio={aspectRatio}
    color={common.white}
  >
    {children}
  </Box>
));
