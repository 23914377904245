import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';

// project imports
import { getFirebaseFunctions } from 'utils/firebase-tools';
import BackHeader from 'layouts/Main/BackHeader';
import { EmptyHeartIcon } from './components';

// material-ui
import { Box, CircularProgress, Fade, Stack, Typography } from '@mui/material';
import { getWishList } from '../../../services/UserService';
import { useAuth } from '../../../hooks';
import { CLO_CODE } from '../../../config';

import { WineCard } from '../../misc/magazine/WineDiscoveryDetailPage/components';
import { WoAlert } from '../../../utils/kmwine-alerts';
import { useSelector } from '../../../store';

const PAGE_SIZE = 12;

const initialState = { loaded: false, loading: false, page: { current: 1, size: PAGE_SIZE }, items: [] };

/**
 * 위시(Wish) 인덱스 화면
 * @returns {JSX.Element}
 *
 * @authors 조현권<hkcho@wineone.io>, 최효근<hkchkoi@wineone.io>
 */
function WishIndex() {
  const location = useLocation();

  const navigate = useNavigate();

  // 로그인 사용자 정보
  const { user } = useAuth();
  const userLocation = useSelector((state) => state.userLocation);

  const [wishList, setWishList] = React.useState(initialState);

  React.useEffect(() => {
    if (userLocation.coord) {
      console.log('나의 wish 목록 조회');
      fetchMyWishList(1).then(() => {
        console.log("[wineone] 로그인 사용자의 'wish' list 조회");
      });
    } else {
      console.warn('핀이 지정되지 않은 사용자 입니다');
    }
  }, [userLocation?.coord]);

  // wish 목록 조회
  const fetchMyWishList = async (current) => {
    setWishList((wishList) => ({ ...wishList, items: current === 1 ? [] : wishList.items, loading: true }));

    const response = await getWishList({
      coordinate: { lng: userLocation.coord.lng, lat: userLocation.coord.lat },
      page: { current, size: PAGE_SIZE }
    }).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `위시리스트 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(response.error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error: response.error }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    // 위시 리스트 조회 오류
    if (response.error) {
      setWishList((wishList) => ({ ...wishList, loading: false }));
      await WoAlert.fire(`위시 리스트 조회 중\n오류가 발생했어요.`, '', 'warning').then(() => {
        navigate(-1);
      });
      return;
    }

    const {
      data, // 위시리스트
      page, // 페이지 정보
      result // 처리결과
    } = response;

    // 리스트 조회 성공
    if (result.code === 0) {
      setWishList((oldList) => {
        const items = current === 1 ? data : [...oldList.items, ...data];
        return { loaded: true, loading: false, page, items };
      });
    } else {
      console.error(`위시 리스트 조회 중 오류`, result);
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `위시 리스트 조회 오류#2`,
        msg: `[uid=${user._id}] ${JSON.stringify(result)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error: result }
      })
        .then(console.log)
        .catch(console.error);
    }
  };

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
      if (y < window.scrollY) {
        // 스크롤이 바닥에 거의(-200) 닿았을 때
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 200) {
          if (!wishList.loaded) return; // 최초 조회시 중복요청 제외
          if (wishList.loading) return; // 페이지 요청 중일 경우 pass

          // 다음 페이지 호출
          if (wishList.page.current < wishList.page.total_pages) {
            console.log(`[WishListIndex] 다음 페이지 호출 (${wishList.page.current + 1}) `);
            fetchMyWishList(wishList.page.current + 1);
          }
          // 이미 검색결과를 모두 보여준 상태
          else {
            console.log('[1KMWINE] 모든 위시 리스트를 출력했습니다.');
          }
        }
      }
      setY(window.scrollY);
    },
    [y, wishList.loading]
  );

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  // render
  return (
    <BackHeader title="위시 리스트" showBackButton={false} showFooter={false} showAlarms showCart>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 위시 리스트`} />

      <Box>
        {wishList.loaded && wishList.items.length > 0 && (
          <Box
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto'
            }}
          >
            <Stack direction="column" spacing="16px" sx={{ pt: '16px' }}>
              {wishList?.items?.map((product) => (
                <WineCard key={`wcr-pdata-${product.pdata._id}`} product={product} pdata={product.pdata} />
              ))}
            </Stack>
          </Box>
        )}

        {/* 리스트 로딩 표현 */}
        <Fade in={wishList.loading}>
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} color="brand" />
          </Box>
        </Fade>

        {/* 위시리스트가 없을시 */}
        {wishList.loaded && wishList.items.length === 0 && (
          <Box mt="80px" display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
            <EmptyHeartIcon />
            <Box mt="60px" textAlign="center">
              <Typography fontSize="18px" fontWeight="bold">
                찜한 상품이 없습니다.
              </Typography>
              <Typography fontSize="18px" fontWeight={300}>
                마음에 드는 와인을 찾아보세요.
              </Typography>
            </Box>
          </Box>
        )}

        {/* {myWishList} */}
      </Box>
    </BackHeader>
  );
}

export default WishIndex;
