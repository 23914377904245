import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

// 1kmwine routes
import AuthRoutes from './AuthRoutes';
import HomeRoutes from './HomeRoutes';
import MallRoutes from './MallRoutes';
import OrderRoutes from './OrderRoutes';
import PaymentRoutes from './PaymentRoutes';
import WishRoutes from './WishRoutes';
import MyRoutes from './MyRoutes';
import ErrorRoutes from './ErrorRoutes';
import VendorRoutes from './VendorRoutes';
// authentication
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| AUTHENTICATION ROUTES ||======================= //
import GuestGuard from 'utils/route-guard/GuestGuard';
import TermPage from 'views/misc/terms/TermPage';
import WineOneEvent from 'views/event/WineOneEvent';

// 본인인증 페이지
import InicisCertificatePage from 'views/pages/certificate/InicisCertificatePage';

export default function BourgogneRoutes({ location }) {
  return (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <AuthGuard>
            <Navigate replace to="/home" />
          </AuthGuard>
        }
      />
      {/* '홈'메뉴 라우트  */}
      {HomeRoutes()}
      {/* '검색'메뉴 라우트  */}
      {MallRoutes()}
      {/* '와인샵'메뉴 라우트 */}
      {VendorRoutes()}
      {/* '위시'메뉴 라우트 */}
      {WishRoutes()}
      {/* 사용자 관련 routes */}
      {MyRoutes()}

      {/* 주문관련 라우트 */}
      {OrderRoutes()}

      {/* 결제관련 routes */}
      {PaymentRoutes()}

      {/* 본인인증 */}
      <Route
        path="/cert/self"
        element={
          <GuestGuard>
            <InicisCertificatePage />
          </GuestGuard>
        }
      />

      {AuthRoutes()}

      {/* 약관 및 정책 / 문서 관련 라우트 */}
      <Route path="/term/:type/latest" element={<TermPage />} />

      {/* 이벤트 페이지 */}
      <Route path="/event/:eventId" element={<WineOneEvent />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
      {ErrorRoutes()}
    </Routes>
  );
}

BourgogneRoutes.propTypes = {
  location: PropTypes.object.isRequired
};
