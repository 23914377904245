import PropTypes from 'prop-types';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

function CouponTerms({ couponTerms, setCouponTerms }) {
  return (
    <Dialog
      open={couponTerms}
      onClose={() => {
        setCouponTerms(false);
      }}
      PaperProps={{
        sx: {
          height: '478px'
        }
      }}
    >
      <Box borderRadius="14px" pt="28px" pb="25px" px="24px" width="315px" height="418px">
        <Typography fontSize="18px" fontWeight="800">
          프로모션 쿠폰 정책
        </Typography>

        <Box mt="16px" mb="20px ">
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              프로모션 전용 쿠폰은 본 프로모션 상품 주문에만 사용가능합니다.
            </Typography>
          </RefundInfoContent>
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              쿠폰 최소 주문 금액 기준에 따라 일부 프로모션 상품을 개별 주문 시 쿠폰 적용이 어려울 수 있습니다.
            </Typography>
          </RefundInfoContent>
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              프로모션이 종료된 후 사용하지 않은 쿠폰은 자동 소멸 처리됩니다.
            </Typography>
          </RefundInfoContent>
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              프로모션 쿠폰은 당사의 정책에 따라 일부 보유 쿠폰과 중복 사용이 가능합니다. <br /> (중복 사용 가능한 쿠폰은 쿠폰 선택 시 중복
              사용 가능한 상태로 표시)
            </Typography>
          </RefundInfoContent>
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              프로모션 쿠폰은 당사의 사정으로 공지 없이 변경 및 중단될 수 있습니다.
            </Typography>
          </RefundInfoContent>
          <RefundInfoContent>
            <Typography color="rgba(20, 2, 41, 0.60)" fontSize="14px" fontWeight="400">
              프로모션 중단 시 발급된 쿠폰은 자동 소멸 처리됩니다.
            </Typography>
          </RefundInfoContent>
        </Box>
        <Button
          onClick={() => {
            setCouponTerms(false);
          }}
          size="large"
          fullWidth
          variant="contained"
          sx={{ borderRadius: '25px', backgroundColor: '#220648', height: '50px' }}
        >
          <Typography fontSize="16px" color="#DEFD9A">
            확인
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
}

export default CouponTerms;

CouponTerms.propTypes = {
  couponTerms: PropTypes.bool,
  setCouponTerms: PropTypes.func
};

const infoColor = alpha('#140229', 0.7);

const RefundInfoContent = React.memo(({ children }) => (
  <Box fontSize="12px" lineHeight="16px" width="100%" display="flex" color={infoColor}>
    <Box mt="3px">&#183;</Box>
    <Box pl="8px">{children}</Box>
  </Box>
));
RefundInfoContent.propTypes = {
  children: PropTypes.any
};
