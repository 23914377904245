import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// 입점샵 주소
const VendorAddress = styled((props) => <Typography className="product-name" {...props} />)(({ noWrap = true }) => ({
  textAlign: 'left',
  color: '#140229',
  fontSize: '12px',
  width: '100%',
  opacity: 0.7,
  lineHeight: 1.3,
  minHeight: noWrap ? '2rem' : 'auto',
  height: noWrap ? '2rem' : 'auto',
  display: noWrap ? '-webkit-box' : 'block',
  overflow: noWrap ? 'hidden' : 'inherit',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'keep-all'
}));

export default VendorAddress;
