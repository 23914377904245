import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';

// project imports
import BackHeader from 'layouts/Main/BackHeader';
import { WoAlert } from 'utils/kmwine-alerts';

// material-ui
import { Container } from '@mui/material';
import ReviewCard from './components/ReviewCard';
import { useAuth } from '../../../../hooks';

const PAGE_SIZE = 8;

// 페이지네이트 기본정보
const initialPage = {
  pageNo: 1,
  lastVisible: null
};

const initialFetchState = {
  loading: false,
  error: false,
  done: false
};

/**
 * 와인(pdata) 리뷰 목록 페이지
 * @returns {JSX.Element}
 * @constructor
 */
function WineReviewList() {
  const { pdataSnapshot } = useOutletContext();
  const { user } = useAuth();

  const navigate = useNavigate();

  // 와인
  const [pdata, setPdata] = useState(null);

  // 페이징 정보
  const [page, setPage] = useState(initialPage);
  const [fetchState, setFetchState] = useState(initialFetchState);

  // 표현할 리뷰 목록
  const [list, setList] = useState([]);

  const reviewList = useMemo(() => {
    return list.map((review) => {
      console.log('review: ', review);

      return <ReviewCard key={`review-${review.id}`} review={review} />;
    });
  }, [list]);

  // 와인정보 조회
  useEffect(() => {
    if (pdataSnapshot != null) {
      // 존재하는 와인
      if (pdataSnapshot.exists()) {
        const pdata = pdataSnapshot.data();

        // 검수 통과하지 않거나 비공개된 와인이 조회됨
        if (!pdata.setup || !pdata.show) {
          invalidWineCallback();
        } else {
          requestList(1);
          setPdata(pdata);
        }
      } else {
        invalidWineCallback();
      }
    }
  }, [pdataSnapshot]);

  const requestList = async (pageNo) => {
    console.time(`리뷰목록 조회[page=${pageNo}]`);
    console.log(`와인[${pdataSnapshot.id}]의 리뷰목록 조회[pageNo=${pageNo}]`);
    if (pageNo === 1) {
      setList([]);
    }

    setFetchState({ ...fetchState, loading: true, error: false });

    // 조회조건
    const conditions = [where('show', '==', true)];
    const sort = [];

    // 차단 사용자 제외하고 조회
    const { blockUsers = [] } = user;

    // 차단 사용자 제외
    if (blockUsers.length > 0) {
      console.debug('[WineReviewList] 차단 사용자의 게시물을 제외한 리뷰를 조회합니다.', blockUsers);
      conditions.push(where('uid', 'not-in', [...blockUsers]));
      sort.push(orderBy('uid'));
    }

    sort.push(orderBy('created_at', 'desc'));

    // 쿼리페이징, todo 데이터 많이 쌓아놓고 테스트하기
    if (pageNo > 1 && page.lastVisible != null) {
      sort.push(startAfter(page.lastVisible));
    }

    // 한 페이지에 보여줄 목록 개수
    sort.push(limit(PAGE_SIZE));

    // todo 리뷰목록 조회
    const snapshot = await getDocs(query(collection(pdataSnapshot.ref, `reviews`), ...conditions, ...sort)).catch((error) => ({ error }));

    let error = false;

    // 에러
    if (snapshot.error) {
      error = true;
      console.error('[1KMWINE] 리뷰목록 조회 중 오류가 발생했습니다.', snapshot.error);
      WoAlert.fire('', '리뷰목록 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate(-1);
      });
    } else {
      // 페이지 정보 저장
      setPage({
        pageNo,
        lastVisible: snapshot.empty ? null : snapshot.docs[snapshot.size - 1]
      });
    }

    const done = error ? true : snapshot.empty; // 모두 조회되어 더 이상 조회할 데이터가 없음

    const _list = error ? [] : snapshot.docs.map((doc) => doc.data());
    console.log('조회된 리뷰목록: ', _list);
    setList((list) => {
      return pageNo === 1 ? _list : [...list, _list];
    });

    console.timeEnd(`리뷰목록 조회[page=${pageNo}]`);

    setFetchState({ loading: false, error, done });
  };

  /** 존재하지 않거나 비공개된 와인일 경우 */
  const invalidWineCallback = useCallback(() => {
    WoAlert.fire({
      title: '와인정보를 불러올 수 없습니다',
      html: `<div style='line-height: 1.5rem; text-align: center'>삭제되거나 존재하지 않는 와인입니다.</div>`,
      customClass: {
        confirmButton: 'max-100'
      },
      confirmButtonText: '확인'
    }).then(() => {
      navigate(-1);
    });
  });

  return (
    <BackHeader title="리뷰 목록">
      <Container maxWidth="xs">{reviewList}</Container>
    </BackHeader>
  );
}

export default WineReviewList;
