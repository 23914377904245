import React from 'react';

import { TextField as MuiTextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

/* eslint-disable react/prop-types */
/**
 * The `TextField` is a convenience wrapper for the most common cases (80%).
 * It cannot be all things to all people, otherwise the API would grow out of control.
 *
 * ## Advanced Configuration
 *
 * It's important to understand that the text field is a simple abstraction
 * on top of the following components:
 *
 * *   [FormControl](https://mui.com/material-ui/api/form-control/)
 * *   [InputLabel](https://mui.com/material-ui/api/input-label/)
 * *   [FilledInput](https://mui.com/material-ui/api/filled-input/)
 * *   [OutlinedInput](https://mui.com/material-ui/api/outlined-input/)
 * *   [Input](https://mui.com/material-ui/api/input/)
 * *   [FormHelperText](https://mui.com/material-ui/api/form-helper-text/)
 *
 * If you wish to alter the props applied to the `input` element, you can do so as follows:
 *
 * ```jsx
 * const inputProps = {
 *   step: 300,
 * };
 *
 * return <TextField id="time" type="time" inputProps={inputProps} />;
 * ```
 *
 * For advanced cases, please look at the source of TextField by clicking on the
 * "Edit this page" button above. Consider either:
 *
 * *   using the upper case props for passing values directly to the components
 * *   using the underlying components directly as shown in the demos
 *
 * Demos:
 *
 * - [Autocomplete](https://mui.com/material-ui/react-autocomplete/)
 * - [Text fields](https://mui.com/material-ui/react-text-field/)
 *
 * API:
 *
 * - [TextField API](https://mui.com/material-ui/api/text-field/)
 * - inherits [FormControl API](https://mui.com/material-ui/api/form-control/)
 *
 * @param props
 * @returns {MuiTextField}
 * @constructor
 */
const TextField = React.forwardRef((props, ref) => <WoTextField inputRef={ref} {...props} />);

export default TextField;

const WoTextField = styled((props) => <MuiTextField variant="filled" InputProps={{ disableUnderline: true }} {...props} />)(
  ({ theme }) => ({
    '& .MuiFilledInput-root': {
      border: `1px solid ${alpha(theme.palette.brand.main, 0.04)}`,
      overflow: 'hidden',
      borderRadius: '10px',
      backgroundColor: alpha(theme.palette.brand.main, 0.04),
      transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderColor: theme.palette.brand.main
      }
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: '0 !important'
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: '0 !important'
    },
    '& .MuiInputBase-inputMultiline::-webkit-scrollbar ': {
      width: 0,
      height: 0,
      background: 'transparent' /* make scrollbar transparent */
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      fontSize: '13px'
    }
  })
);
