import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { josa } from 'josa';
import moment from 'moment';

// project imports
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { SmallButton } from 'components/buttons';
import { UserDefaultIcon } from 'components/icons';

// material-ui
import { Avatar, Badge, Box, ButtonBase, Skeleton, Typography } from '@mui/material';

// assets

/**
 * 사용자 프로필 카드
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function UserProfileCard({ user }) {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  /** god 모드 사용자인가? */
  const isGod = React.useMemo(() => user.god ?? false, [user?.god]);

  // 사용자 프로필 이미지
  /* eslint-disable no-else-return */
  const profileImage = React.useMemo(() => {
    const hasPhoto = user?.profile_img?.thumb?.url ?? false;
    if (hasPhoto) {
      return (
        <LazyLoadImage
          alt={'사용자 이미지'}
          placeholder={<Skeleton animation={'wave'} variant={'circular'} width={'100%'} height={'100%'} />}
          width={'100%'}
          height={'100%'}
          effect={'blur'}
          visibleByDefault={navigationType !== 'PUSH'}
          threshold={0}
          delayTime={0}
          draggable={false}
          style={{ borderRadius: '50%', overflow: 'hidden' }}
          src={user?.profile_img?.thumb?.url} // use normal <img> attributes as props
        />
      );
    } else {
      return <UserDefaultIcon width="100%" height="100%" />;
    }
  }, [user?.photoURL]);

  const userTasteDescription = React.useMemo(() => {
    const now = moment();
    const ageArea = ((now.year() - (user.birth.year >> 0) + 1) / 10) >> 0;
    const { gender } = user;
    const genderStr = gender === 'M' ? '남성' : '여성';

    // console.log('---- userDesc: ', user);
    let tasteStr = (
      <ButtonBase
        onClick={() => {
          navigate('/my/taste/edit');
        }}
      >
        <Typography variant="caption" sx={{ opacity: 0.5 }}>
          [ 와인 취향을 설정해보세요 ]
        </Typography>
      </ButtonBase>
    );

    if (user.my_taste) {
      const taste = user.my_taste.sort((a, b) => a.order - b.order);
      // console.log('와인 취향정보가 있는 사용자.', user.my_taste, taste);
      const [q1, q2] = taste;
      tasteStr = '# ' + q1.choice.value + ` ${josa(`${q2.choice.value}#{을}`)} 좋아하는 ${ageArea}0대 ${genderStr}`;
    }

    return tasteStr;
  }, [user]);

  // render
  return (
    <Box pt="28px" pb="30px" display="flex">
      {/* 프로필 이미지 */}
      <Box width={70} height={70}>
        <GodBadge isGod={isGod}>
          <Avatar aria-label="사용자 프로필이미지" sx={{ minWidth: 70, minHeight: 70, overflow: 'hidden' }}>
            {profileImage}
          </Avatar>
        </GodBadge>
      </Box>

      {/* <Box flexGrow={1} pl="13px" display="flex" flexDirection="column" justifyContent="space-between"> */}
      <Box flexGrow={1} pl="13px" display="flex" flexDirection="column" justifyContent="space-around">
        <SpaceBetweenBox height={18} pt="4px">
          <Typography variant="subtitle1" fontWeight={800} letterSpacing="-0.16px">
            {user.nickname}
          </Typography>
          <SmallButton
            onClick={() => {
              navigate('/my/profile');
            }}
          >
            프로필 수정
          </SmallButton>
        </SpaceBetweenBox>

        <Box>
          {/* <Box display="flex" alignItems="center" fontSize="13px"> */}
          {/*   <MembershipPink /> */}
          {/*   <ButtonBase sx={{ lineHeight: 1, letterSpacing: '-0.13px' }}> */}
          {/*     등급 혜택 보기 */}
          {/*     <BtnMoreIcon /> */}
          {/*   </ButtonBase> */}
          {/* </Box> */}

          <Box display="flex" alignItems="center" minHeight={23}>
            <Typography variant="caption" fontSize="13px" lineHeight="normal" letterSpacing="-0.13px">
              {/* # 무거운 레드와인을 좋아하는 30대 남성 */}
              {userTasteDescription ?? <Skeleton width={150} />}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

UserProfileCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserProfileCard;

// eslint-disable-next-line react/prop-types
const GodBadge = React.memo(({ children, isGod }) => (
  <Badge
    color="brand"
    overlap="circular"
    badgeContent={
      <Typography fontSize="inherit" lineHeight={0}>
        God
      </Typography>
    }
    invisible={!isGod}
  >
    {children}
  </Badge>
));
