import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { ProductCard } from './components';

// material-ui
import { Grid } from '@mui/material';

// constants
import { VIEW_TYPE_GRID, VIEW_TYPE_LIST } from './index';

/**
 * 상품 목록
 *
 * @param {string} viewType - 상품 표현 타입
 * @param {Array<Object>} [list=[]] -  상품 리스트
 * @param totalCount
 * @param sort
 * @returns {JSX.Element|null}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>
 */
function ProductList({ viewType = VIEW_TYPE_GRID, list = [], totalCount = null, sort }) {
  if (totalCount === 0) return null; // 표현할 와인이 없을 경우

  // 뷰 형태(리스트/그리드)
  const isListView = React.useMemo(() => viewType === VIEW_TYPE_LIST, [viewType]);

  // render
  return (
    <Grid className="mall-product-list-container" container columnSpacing={2} rowSpacing={isListView ? 3 : 2} sx={{ pb: '24px' }}>
      {list.map((product, i) => (
        <Grid item key={`product-list-item-${i}`} xs={isListView ? 12 : 6} sm={isListView ? 12 : 4} md={isListView ? 6 : 3}>
          {/* 상품 카드 */}
          <ProductCard viewType={viewType} product={product} sort={sort} />
        </Grid>
      ))}
    </Grid>
  );
}

ProductList.propTypes = {
  viewType: PropTypes.string.isRequired,
  sort: PropTypes.object.isRequired,
  list: PropTypes.array,
  totalCount: PropTypes.number
};

export default ProductList;
