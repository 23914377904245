import axios from 'axios';
import { NODE_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';

/**
 * 주문 가능 와인샵 목록 조회
 */
export async function getOrderableList(body) {
  return axios.post(NODE_URL.POST__GET_ORDERABLE_LIST, { ...body });
}

/**
 * pdata info화면 데이터 조회
 */
export async function getPdataInfoData(id) {
  return axios
    .get(NODE_URL.GET__PDATA_INFO_DATA, { params: { id } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `pdata info 데이터 조회 오류`,
          msg: error.message ?? 'Unexpected Error',
          which: `${window?.location?.href}`,
          param: { error }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * pdata review 정보 조회
 */
export async function getPdataReviewInfo(pdataId): Promise<any> {
  if (!pdataId) throw new Error('pdataId가 없습니다.');
  return axios
    .get(NODE_URL.GET__PDATA_REVIEW_DATA, { params: { id: pdataId } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `pdata review 데이터 조회 오류`,
          msg: error.message ?? 'Unexpected Error',
          which: `${window?.location?.href}`,
          param: { error }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
