// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
import * as UaParser from 'ua-parser-js';
import { setUserProperties } from 'firebase/analytics';
import { analytics } from '../../index';
import { IS_PRODUCTION } from '../../config';

/**
 * 앱 타입
 * @type {{WEB: string, ANDROID: string, IOS: string}}
 */
export const APP_TYPE = {
  WEB: 'web',
  AOS: 'Android',
  IOS: 'iOS'
};

const APP_USER_AGENT_PREFIX = 'WineOne-App'; // 어플리케이션 탐지용 userAgent prefix
const APP_AOS = `${APP_USER_AGENT_PREFIX}-${APP_TYPE.AOS}`;
const APP_IOS = `${APP_USER_AGENT_PREFIX}-${APP_TYPE.IOS}`;

export const USER_AGENT = {
  PREFIX: APP_USER_AGENT_PREFIX,
  AOS: APP_AOS,
  IOS: APP_IOS
};

/** wineone initial state */
export const WINEONE_INITIAL_STATE = {
  currentUserAgent: UaParser(window.navigator.userAgent),
  // 하단 네비게이션
  bottomNav: {
    value: '',
    show: undefined
  },
  // 기기(브라우저, 모바일기기) 위치정보
  geolocation: getLastGeolocation(),
  // 앱 타입
  appType: undefined,
  // 앱 버전
  appVersion: {
    code: undefined,
    name: undefined
  },

  // 기기에서 뒤로가기버튼 콜백
  onBackPressed: null,

  // 서비스 헬스체크
  health: { state: 'ok' }
};

/**
 *  와인원(1KMWINE) redux slice
 */
const slice = createSlice({
  name: 'wineOne',
  initialState: WINEONE_INITIAL_STATE,
  reducers: {
    // 하단네비 토글
    toggleBottomNavigation(state, action) {
      if (typeof action.payload === 'boolean') {
        state.bottomNav.lastState = { show: state.bottomNav.show };
        state.bottomNav.show = action.payload;
      } else {
        state.bottomNav.show = undefined;
        if (state.bottomNav.lastState) {
          state.bottomNav.show = state.bottomNav.lastState.show;
        }
        state.bottomNav.lastState = { show: action.payload };
      }
    },
    // Sets the bottom navigation value.
    setBottomNavigationValue(state, action) {
      state.bottomNav.value = action.payload;
    },
    // Sets the geolocation permission state.
    setUserGeolocation(state, action) {
      const { access = false, initialized, latitude = null, longitude = null } = action.payload;

      state.geolocation = {
        initialized: initialized || false,
        access: access || false
      };

      if (latitude != null && longitude != null) {
        state.geolocation.latitude = Number(latitude);
        state.geolocation.longitude = Number(longitude);
      }
    },
    // 어플리케이션 버전정보
    setApplicationVersion(state, action) {
      const { code = null, name = null } = action.payload;
      state.appVersion.code = code;
      state.appVersion.name = name;
    },
    // Sets the user agent type.
    setApplicationType(state, action) {
      if (IS_PRODUCTION) {
        setUserProperties(analytics, {
          app_name: '1kmwine.mobile',
          app_type: `1kmwine-${action.payload.toLowerCase()}-user`
        });
      }
      state.appType = action.payload;
    },

    // 기기에서 뒤로가기 버튼을 눌렀을 때 콜백정의
    onNativeBackPressed(state, action) {
      if (typeof action.payload === 'function') {
        state.onBackPressed = action.payload;
      } else {
        state.onBackPressed = null;
      }
    },
    // 기기에서 뒤로가기 버튼을 눌렀을 때 콜백실행
    fireNativeBackPressed(state) {
      if (state.health.state !== 'ok') {
        console.warn('서비스 점검 중에는 뒤로가기 버튼을 막습니다.');
        return false;
      }

      // console.log('[wine-one.js] $fireNativeBackPressed: ', state.onBackPressed);
      if (typeof state.onBackPressed === 'function') {
        state.onBackPressed();
      } else {
        window.history.back();
      }
    },

    // 서비스 상태
    setHealth(state, { payload: health }) {
      state.health = health;
      // const oldState = `${state.health.state}`;
      //
      // state.health = health;
      //
      // if (oldState !== 'ok' && health.state === 'ok') {
      //   console.info(
      //     `[wine-one][setHealth] 앱 상태(state:${state.health.state})가 정상(${health.state})으로 돌아왔습니다. 앱을 갱신합니다.`
      //   );
      //   console.log('#REACT_APP_SERVICE_DOMAIN: ', process.env.REACT_APP_SERVICE_DOMAIN);
      //   try {
      //     // window.location.replace(`${process.env.REACT_APP_SERVICE_DOMAIN}?_ts=${new Date().getTime()}`);
      //
      //   } catch (e) {
      //     console.error('[wine-one][setHealth] 앱 갱신 실패: ', e);
      //   }
      // }
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  toggleBottomNavigation,
  setBottomNavigationValue,
  setUserGeolocation,
  setApplicationType,
  onNativeBackPressed,
  fireNativeBackPressed
} = slice.actions;

export function setGeolocation(geolocation) {
  return async (dispatch) => {
    dispatch(slice.actions.setUserGeolocation(geolocation));
  };
}

export function setHealth(health) {
  return async (dispatch) => {
    dispatch(slice.actions.setHealth(health));
  };
}

export function setApplicationVersion(code, name) {
  return async (dispatch) => {
    dispatch(slice.actions.setApplicationVersion(code, name));
  };
}

/**
 * 마지막 geolocation 정보(위치접근 가능여부) 조회
 * @returns {{access: undefined, initialized: boolean}|{access: boolean, initialized: boolean}}
 */
function getLastGeolocation() {
  const settingsStr = localStorage.getItem('wine-one-app');

  try {
    const settings = JSON.parse(settingsStr);
    const { geolocationAccess } = settings;
    let initialized = false;
    let access;
    let latitude = null;
    let longitude = null;

    if (typeof geolocationAccess === 'boolean') {
      initialized = true;
      access = !!geolocationAccess;
      if (settings.geolocationCoords) {
        const [lat, lng] = settings.geolocationCoords.split(',');
        latitude = lat;
        longitude = lng;
      }
    }

    const lastGeolocation = {
      initialized,
      access
    };

    if (latitude != null && longitude != null) {
      lastGeolocation.latitude = Number(latitude);
      lastGeolocation.longitude = Number(longitude);
    }

    return lastGeolocation;
  } catch (e) {
    return {
      initialized: false,
      access: undefined
    };
  }
}
