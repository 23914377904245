import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// project imports
import { useAuth, useKakaoSdk, useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import BackHeader from 'layouts/Main/BackHeader';
import { AppleButton, AuthTitleHeader, KakaoButton } from 'components';
import RecommendVendor, { VendorImage } from './components/RecommendVendor';
import RecommendFriend from './components/RecommendFriend';
import MemberImage from './components/MemberImage';

// material-ui
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useLocationState } from 'react-router-use-location-state';

/**
 * 회원가입 완료화면
 * @authors 최효근<hkchoi@wineone.io>, 조현권<hkcho@wineone.io>
 */
function SignUpCompletePage() {
  const { appleLogin } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { refreshUserGeolocation } = useWineOne();

  React.useLayoutEffect(() => {
    try {
      refreshUserGeolocation(); // 사용자 현재위치 최신화
    } catch (e) {
      console.warn('사용자 현재위치 최신화 오류', e);
    }
  }, []);

  const { initialized: kakaoSdkInitialized } = useKakaoSdk();

  // 모바일에서 뒤로가기 눌렀을시
  const { onNativeBackPressed } = useWineOne();

  // 추천샵정보
  const [inviteVendor, setInviteVendor] = React.useState(null);

  // 추천인정보
  const [recommendFriend, setRecommendFriend] = useLocationState('recommendFriend', null);

  // 추천 와인샵 dialog
  const [recommendVendorDialog, setRecommendVendorDialog] = React.useState(false);

  // 추천인 dialog
  const [recommendFriendDialog, setRecommendFriendDialog] = React.useState(false);

  // 추천인 입력 버튼 텍스트
  const recommendFriendButtonText = React.useMemo(() => (inviteVendor !== null ? '추천인도 있어요' : '추천인이 있어요'), [inviteVendor]);
  // 추천 와인샵 입력버튼 텍스트
  const recommendVendorButtonText = React.useMemo(
    () => (recommendFriend !== null ? '추천 와인샵도 있어요' : '추천 와인샵이 있어요'),
    [recommendFriend]
  );

  // 카카오 로그인 버튼 클릭
  const onClickKakaoLoginButton = React.useCallback(async () => {
    if (!kakaoSdkInitialized) {
      await WoAlert.fire('', '카카오 로그인 모듈을 불러오고 있습니다.<br/>잠시 후 다시 시도해주세요.', 'info');
      return;
    }

    window.Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}/auth/sign-in/kakao/loading`,
      prompt: 'select_account',
      throughTalk: true
    });
  }, [kakaoSdkInitialized]);

  // 애플 로그인 버튼 클릭
  const onClickAppleLoginButton = React.useCallback(async () => {
    const auth = getAuth();
    await auth?.signOut(); // apple 대응

    try {
      const signInResult = await appleLogin();
      const { success } = signInResult;
      console.log('############# signInResult:', signInResult);
      // 애플 로그인 성공 후 홈으로 이동
      if (success) {
        console.log('# 회원가입 완료 화면에서 로그인은 무조건 홈 화면으로 이동');
        setTimeout(() => {
          navigate('/home');
        }, 300);
      }
    } catch (e) {
      console.error('[회원가입 완료] 애플 로그인 실패');
    }
  }, [appleLogin, navigate]);

  const providerName = React.useMemo(() => {
    if (state.provider === 'kakao') return '카카오';
    if (state.provider === 'apple') return 'Apple';

    return '이메일';
  }, [state.provider]);

  const recommendField = React.useMemo(() => {
    /*
     * 추천인만 있을 경우 추천인 먼저 표현
     * 추천 와인샵만 있을 경우 추천 와인샵 먼저 표현
     * 추천인과 추천 와인샵만 있을 경우 추천 와인샵 먼저 표현
     */

    // 추천 와인샵을 먼저 표현
    const recommendShopFirst = recommendFriend === null && inviteVendor !== null;
    // 추천인 정보
    const recommendFriendField = recommendFriend ? (
      <>
        <Typography fontSize="14px" fontStyle="normal" fontWeight={700} lineHeight="23px">
          추천인
        </Typography>

        <Box borderRadius="8px" bgcolor="#F4F0F8" p="12px 16px" mt="8px">
          <Grid container spacing="12px">
            <Grid item xs="auto">
              <Box height="44px">
                <MemberImage borderRadius="50%" src={recommendFriend.profile_img__thumb__url} width={44} height={44} />
              </Box>
            </Grid>
            <Grid item xs>
              <Box height={44} display="flex" flexDirection="column" justifyContent="center">
                <Stack spacing="6px">
                  <Typography fontSize="11px" lineHeight="12px" fontWeight={400}>
                    {recommendFriend.recommend_code}
                  </Typography>
                  <Typography fontSize="14px" lineHeight="normal" fontWeight={700}>
                    {recommendFriend.nickname}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    ) : (
      <RecommendButton
        onClick={() => {
          setRecommendFriendDialog(true);
        }}
      >
        {recommendFriendButtonText}
      </RecommendButton>
    );

    // 추천 와인샵 정보
    const recommendVendorField = inviteVendor ? (
      <>
        <Typography fontSize="14px" fontStyle="normal" fontWeight={700} lineHeight="23px">
          추천 와인샵
        </Typography>
        <Box borderRadius="8px" bgcolor="#F4F0F8" p="12px 16px" mt="8px">
          <Grid container spacing="12px">
            <Grid item xs="auto">
              <Box height="44px">
                <VendorImage src={inviteVendor.vendor_img[0]?.thumb} width={44} height={44} />
              </Box>
            </Grid>
            <Grid item xs>
              <Box height={44} display="flex" flexDirection="column" justifyContent="center">
                <Stack spacing="6px">
                  <Typography fontSize="11px" lineHeight="12px" fontWeight={400}>
                    {inviteVendor.biz.biz__address1}
                  </Typography>
                  <Typography fontSize="14px" lineHeight="normal" fontWeight={700}>
                    {inviteVendor.biz.name}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    ) : (
      <RecommendButton
        onClick={() => {
          setRecommendVendorDialog(true);
        }}
      >
        {recommendVendorButtonText}
      </RecommendButton>
    );

    const recommendFields = recommendShopFirst ? (
      <>
        {recommendVendorField}
        {recommendFriendField}
      </>
    ) : (
      <>
        {recommendFriendField}
        {recommendVendorField}
      </>
    );
    return (
      <Stack spacing="8px" sx={{ pb: '16px' }}>
        {inviteVendor === null && recommendFriend === null && (
          <Typography lineHeight="23px" fontWeight={700} fontStyle="normal" fontSize="14px">
            1KMWINE을 추천해준 와인샵 또는 회원이 있으신가요?
          </Typography>
        )}
        {recommendFields}
      </Stack>
    );
  }, [recommendFriend, inviteVendor]);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    if (recommendFriend !== null && inviteVendor !== null) {
      navigate('/auth/sign-in');
    } else {
      WoAlert.fire({
        html: '추천 정보를 입력하지 않았습니다<br/>로그인 화면으로 이동하시겠습니까?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '네',
        cancelButtonText: '취소',
        customClass: { confirmButton: 'max-50', cancelButton: 'max-50' },
        icon: 'question'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/auth/sign-in');
        }
      });
    }
  }, [navigate, recommendFriend, inviteVendor]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // render
  return (
    <BackHeader hideDivider onBackButtonClick={pageBackFunc} title="" showFooter={false} showBottomNav={false}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 회원가입 완료`} />
      <Container maxWidth="xs">
        <Box display="flex" flexDirection="column" sx={{ height: 'calc(var(--vh, 1vh) * 100 - 54px)' }}>
          <AuthTitleHeader subtitle="회원 가입을 축하합니다!" />

          <Box mt="48px">
            <Box p="14px" borderRadius="8px" bgcolor="#F4F0F8">
              <Stack spacing="3px">
                <InfoRow label="가입일시" text={moment(state.created_at).format('YYYY년 MM월 DD일')} />
                <InfoRow label="가입방법" text={providerName} />
                <InfoRow label="이메일" text={state.email} />
              </Stack>
            </Box>

            <Box mt="8px">
              {/* 카카오 회원가입 사용자의 로그인 버튼 */}
              {state.provider === 'kakao' && <KakaoButton label="카카오로 로그인" onClick={onClickKakaoLoginButton} />}
              {/* 애플 회원가입 사용자의 로그인 버튼 */}
              {state.provider === 'apple' && <AppleButton label="Apple로 로그인" onClick={onClickAppleLoginButton} />}
              {/* 이메일 회원가입 사용자의 로그인 버튼 */}
              {state.provider === 'email' && (
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="brand"
                  onClick={() => {
                    navigate('/auth/email-sign-in', { replace: true, state: { email: state.email } });
                  }}
                  sx={{ borderRadius: '6px', fontSize: '18px', fontWeight: 'bold' }}
                >
                  이메일로 로그인
                </Button>
              )}
            </Box>
          </Box>

          <Box className="sponge" flexGrow={1} minHeight="16px" maxHeight="48px" />

          {recommendField}

          {/* 친구추천 dialog */}
          <RecommendFriend
            setRecommendFriend={setRecommendFriend}
            recommendFriendDialog={recommendFriendDialog}
            setRecommendFriendDialog={setRecommendFriendDialog}
            uid={state.uid}
          />
          {/* 샵추천 dialog */}
          <RecommendVendor
            inviteVendor={inviteVendor}
            setInviteVendor={setInviteVendor}
            recommendVendorDialog={recommendVendorDialog}
            setRecommendVendorDialog={setRecommendVendorDialog}
            uid={state.uid}
          />
        </Box>
      </Container>
    </BackHeader>
  );
}

export default SignUpCompletePage;

const RecommendButton = styled((props) => <Button fullWidth variant="outlined" {...props} />)(() => ({
  height: '56px',
  borderRadius: '6px',
  border: '1px solid #140229',
  backgroundColor: '#ffffff',

  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
}));

const InfoRow = React.memo(({ label, text }) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box component="span" className={classes.labelWrapper}>
        <Typography className={classes.infoText}>{label}:</Typography>
      </Box>
      <Box component="span" className={classes.textWrapper}>
        <Typography className={classes.infoText}>{text} </Typography>
      </Box>
    </Box>
  );
});

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const useStyles = makeStyles({
  labelWrapper: {
    display: 'inline-block',
    width: '52px',
    minWidth: '52px'
  },
  textWrapper: {
    marginLeft: '8px',
    overflow: 'hidden',
    display: 'inline-block',
    flexGrow: 1
  },
  infoText: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    wordBreak: 'break-word'
  }
});
