import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { COUPON_TYPE } from 'config';
import Coupon from './Coupon/Coupon';
import { BottomDialog, WoDialogTitle } from 'components/dialog';

// material-ui
import { Box, Button, Container, DialogActions, DialogContent, Stack, SvgIcon, Typography } from '@mui/material';

/**
 * 주문하기 > 쿠폰선택 다이얼로그
 *
 * @param {boolean} open - 다이어로그 오픈여부
 * @param {boolean} loaded - 쿠폰목록 로딩여부
 * @param {Array} coupons - 쿠폰목록
 * @param {Array} selectedCoupons - 선택된 쿠폰
 * @param {function} onClose - 다이얼로그 닫음
 * @param {function} onSelect - 쿠폰선택 확인버튼
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 최효근<hkchoi@wineone.io>
 */
function CouponDialog({ open = false, loaded = false, coupons = [], selectedCoupons = [], onClose, onSelect }) {
  React.useEffect(() => {
    if (open) {
      console.log('[쿠폰 선택] 보유 쿠폰목록 다이얼로그 열림');
      refineSelectedCoupons();
    } else {
      console.debug('[쿠폰 선택] 보유 쿠폰목록 다이얼로그 닫힘');
    }
  }, [open]);

  // 선택된 쿠폰
  const [selected, setSelected] = React.useState({ coupons: [], ids: [], types: [] });

  const refineSelectedCoupons = () => {
    const coupons = [];
    const ids = [];
    const types = [];

    selectedCoupons.forEach((selectedCoupon) => {
      coupons.push(selectedCoupon);
      ids.push(selectedCoupon.id);
      types.push(selectedCoupon.coupon.type);
    });

    setSelected({ coupons, ids, types });
  };

  React.useEffect(() => {
    refineSelectedCoupons();
  }, [selectedCoupons]);

  // 쿠폰 클릭 이벤트 콜백
  const handleCouponClick = (myCoupon) => {
    if (selected.coupons.length === 0) {
      setSelected({
        coupons: [myCoupon],
        ids: [myCoupon.id],
        types: [myCoupon.coupon.type]
      });
      return;
    }

    const coupons = [...selected.coupons];
    const ids = [...selected.ids];
    const types = [...selected.types];

    // 이미 선택되어있었는가?
    const alreadySelected = ids.indexOf(myCoupon.id) >= 0;

    // 이미 선택되어 있지 않은 쿠폰
    if (!alreadySelected) {
      // 지금 선택된 쿠폰의 타입
      const { type } = myCoupon.coupon;

      // 같은 타입의 쿠폰의 중복사용을 막음
      // 1 - 기존에 같은 타입의 쿠폰이 선택되어있었을 경우 해제
      [...coupons].forEach((c, j) => {
        const { coupon } = c;
        if (coupon.type === type) {
          coupons.splice(j, 1); // 기존에 선택되어있던 같은 타입의 쿠폰선택 해제
          ids.splice(j, 1);
          types.splice(j, 1);
        }
      });

      // 2 - VENDOR, ADVEN은 동시에 사용 불가
      // if (type === COUPON_TYPE.VENDOR || type === COUPON_TYPE.ADIMN_TO_VENDOR) {
      //   [...coupons].forEach((c, k) => {
      //     const { coupon } = c;
      //     if (coupon.type === COUPON_TYPE.VENDOR || coupon.type === COUPON_TYPE.ADIMN_TO_VENDOR) {
      //       coupons.splice(k, 1); // 기존에 선택되어있던 같은 관리자 발행쿠폰 해제
      //       ids.splice(k, 1);
      //       types.splice(k, 1);
      //     }
      //   });
      // }

      // 3 - ADMIN, ADVEN은 동시에 사용 불가
      if (type === COUPON_TYPE.ADMIN || type === COUPON_TYPE.ADIMN_TO_VENDOR) {
        [...coupons].forEach((c, k) => {
          const { coupon } = c;
          if (coupon.type === COUPON_TYPE.ADMIN || coupon.type === COUPON_TYPE.ADIMN_TO_VENDOR) {
            coupons.splice(k, 1); // 기존에 선택되어있던 같은 관리자 발행쿠폰 해제
            ids.splice(k, 1);
            types.splice(k, 1);
          }
        });
      }

      coupons.push(myCoupon);
      ids.push(myCoupon.id);
      types.push(myCoupon.coupon.type);
    }
    // 이미 선택되어있던 쿠폰 -> 선택해제
    else {
      const at = ids.indexOf(myCoupon.id);
      // console.log('#이미 선택되어있던 쿠폰: ', at);

      coupons.splice(at, 1); // 기존에 선택되어있던 같은 타입의 쿠폰선택 해제
      ids.splice(at, 1);
      types.splice(at, 1);
    }

    console.debug('[쿠폰 선택] 선택된 쿠폰들: ', coupons);
    setSelected({ coupons, ids, types });
  };

  const handleClose = (e) => {
    onClose(e);
  };

  // render
  return (
    <BottomDialog open={open} onClose={handleClose}>
      <WoDialogTitle title="쿠폰 선택" onClickClose={handleClose} />
      <DialogContent dividers={false} sx={{ px: 0, pb: '12px' }}>
        <Container maxWidth="xs">
          <Box>
            {!loaded ? (
              <Box minHeight={300} display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" fontSize={14} sx={{ opacity: 0.7 }}>
                  쿠폰 정보를 가져오고 있습니다.
                </Typography>
              </Box>
            ) : coupons.coupons.length > 0 ? (
              <Stack spacing={2}>
                {coupons.coupons.map((myCoupon) => {
                  const { coupon } = myCoupon;

                  const { type, vendor, name } = coupon;

                  const _selectedCoupon = selected.ids.indexOf(myCoupon.id) >= 0;

                  // 쿠폰사용가능여부
                  const available =
                    myCoupon.available &&
                    myCoupon.overMinAmount &&
                    !myCoupon.use &&
                    (myCoupon.rightVendor === undefined || myCoupon.rightVendor);

                  return (
                    <Coupon
                      key={`my-coupon-${myCoupon.id}`}
                      id={coupon.id}
                      type={type}
                      vendor={vendor}
                      name={name}
                      category={coupon.category}
                      discount={coupon.amount >> 0}
                      minOrderAmount={coupon.min_order_amount >> 0}
                      usePeriod={coupon.use_period}
                      selected={_selectedCoupon}
                      enable={_selectedCoupon}
                      clickable={available}
                      overMinAmount={myCoupon.overMinAmount}
                      rightVendor={myCoupon.rightVendor}
                      onWingClick={() => {
                        handleCouponClick(myCoupon);
                      }}
                    />
                  );
                })}
              </Stack>
            ) : (
              <Box minHeight={200} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <CouponEmptyIcon />
                <Box mt="12px">
                  <Typography variant="caption" fontSize={14} sx={{ opacity: 0.7 }}>
                    사용가능한 쿠폰이 없습니다.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </DialogContent>

      {/* 선택완료 버튼 */}
      {coupons.coupons.length > 0 && (
        <DialogActions>
          <Button fullWidth variant="contained" color="brand" size="x-large" onClick={() => onSelect(selected.coupons)}>
            적용하기
          </Button>
        </DialogActions>
      )}
    </BottomDialog>
  );
}

CouponDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  coupons: PropTypes.object,
  selectedCoupons: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};

export default CouponDialog;

/* eslint-disable react/prop-types */
function CouponEmptyIcon({ height = 86, width = 86, color = '#e5dcef' }) {
  return (
    <SvgIcon htmlColor={color} viewBox="0 0 86 86" sx={{ width, height }}>
      <g style={{ fill: 'none' }}>
        <path d="M5307 2488h-70v-15.583a8 8 0 0 0 0-14.836V2441h70v47z" style={{ stroke: 'none' }} transform="translate(-5229 -2422)" />
        <path
          d="M5303 2484v-39h-62v10.25a11.992 11.992 0 0 1 2.896 2.962A11.936 11.936 0 0 1 5246 2465c0 2.437-.727 4.784-2.103 6.787a12.004 12.004 0 0 1-2.897 2.962V2484h62m4 4.001h-70v-15.583a7.993 7.993 0 0 0 3.6-2.895 7.952 7.952 0 0 0 1.4-4.523 7.953 7.953 0 0 0-1.4-4.523 7.993 7.993 0 0 0-3.6-2.895V2441h70V2488z"
          style={{ fill: 'currentcolor', stroke: 'none' }}
          transform="translate(-5229 -2422)"
        />
      </g>
      <path transform="translate(24 19)" style={{ fill: 'currentcolor' }} d="M0 0h4v12H0z" />
      <path transform="translate(24 38)" style={{ fill: 'currentcolor' }} d="M0 0h4v10H0z" />
      <path transform="translate(24 54)" style={{ fill: 'currentcolor' }} d="M0 0h4v12H0z" />
      <path transform="translate(41.5 32.5)" style={{ stroke: 'currentcolor', strokeWidth: '4px', fill: 'none' }} d="M20 0 0 20" />
      <g transform="translate(39 30)" style={{ stroke: 'currentcolor', strokeWidth: '4px', fill: 'none' }}>
        <circle cx="5" cy="5" r="5" style={{ stroke: 'none' }} />
        <circle cx="5" cy="5" r="3" style={{ fill: 'none' }} />
      </g>
      <g transform="translate(54 45)" style={{ stroke: 'currentcolor', strokeWidth: '4px', fill: 'none' }}>
        <circle cx="5" cy="5" r="5" style={{ stroke: 'none' }} />
        <circle cx="5" cy="5" r="3" style={{ fill: 'none' }} />
      </g>
    </SvgIcon>
  );
}
