import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 *
 * @param {number} px
 * @param {number} pt
 * @param {string} color
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function WoDivider({ px = 2.5, pt = 0, color, ...rest }) {
  const theme = useTheme();
  return (
    <Box px={px} pt={pt} {...rest}>
      <Divider color={color ?? theme.palette.brand.main} sx={{ height: '2px' }} />
    </Box>
  );
}

WoDivider.propTypes = {
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  variant: PropTypes.string
};

export default WoDivider;
