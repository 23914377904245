import React from 'react';
import PropTypes from 'prop-types';

// third-party: material-ui
import { Box } from '@mui/material';

/**
 * 주문상품정보 컨테이너
 * @returns {JSX.Element}
 * @constructor
 */
function OrderSection({ children, ...other }) {
  return (
    <Box className="order-class-section" component="section" {...other}>
      {children}
    </Box>
  );
}

OrderSection.propTypes = {
  children: PropTypes.node.isRequired
};

export default OrderSection;
