import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment/moment';
import 'moment/locale/ko';
import { useDebounce } from 'use-debounce';

// project imports
import { CLO_CODE } from 'config';
import { useAuth, useScriptRef } from 'hooks';
import BackHeader from 'layouts/Main/BackHeader';
import { ProductImage, WineBottleLoadingLottie } from 'components';
import { getCoBuyingDetail } from 'services/ProductService';

import { getFirebaseFunctions } from 'utils/firebase-tools';
import { Calculator } from 'utils/bourgogne-tools';
import { WoAlert } from 'utils/kmwine-alerts';

// material-ui
import { Backdrop, Box, Button, Chip, Container, Skeleton, SvgIcon, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

// assets
import TasteDivision from '../../../../../mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';
import { useLocationState } from 'react-router-use-location-state';

/**
 * 공동구매 상세페이지
 *
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>, 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 */
const CoBuyingDetail = () => {
  const scriptedRef = useScriptRef();
  const theme = useTheme(); // material theme
  const navigate = useNavigate();
  const location = useLocation();
  // 로그인 사용자 정보
  const { user } = useAuth();

  // 공동구매 아이디
  const { coBuyingId } = useParams();

  // 공동구매 공유하기
  const shareProp = React.useMemo(() => {
    return {
      linkTitle: '1KMWINE 공동구매',
      linkType: 'cobuy',
      linkData: `${coBuyingId}`
    };
  }, [coBuyingId]);

  // 공동구매 정보
  const [coBuying, setCoBuying] = useLocationState('cb-detail', { loaded: false, data: null });

  // 로딩표현
  const [loading, setLoading] = React.useState(false);
  const [debouncedLoading] = useDebounce(loading, 500);

  // 공동구매 상세정보 가지고 오기
  const fetchCoBuyingDetail: Promise<any> = React.useCallback(async () => {
    setLoading(true);

    // 공동구매 상세조회 불러오기
    const result = await getCoBuyingDetail(coBuyingId).catch((error) => {
      console.error('공동구매 상세조회 오류', error);
      // httpsCallable(
      //   getFirebaseFunctions(),
      //   'call-cdm-clo-error'
      // )({
      //   code: CLO_CODE.UNEXPECTED_ERROR,
      //   title: `공동구매 조회 중 오류`,
      //   msg: `[uid=${user._id}] ${result.error.message}`,
      //   which: `${location.pathname}${location.search}`,
      //   param: {
      //     uid: user._id
      //   }
      // })
      //   .then(console.log)
      //   .catch(console.error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[fetchCoBuyingDetail][fetchCoBuyingDetail] Unmounted component.');
      return undefined;
    }

    // 공동구매 조회 실패
    if (result.error) {
      setLoading(false);
      console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] ', result.error);
      WoAlert.fire('공동구매', '공동구매 조회 중 오류가 발생했습니다', 'error').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    const { cobuying, success } = result;

    // 잘못된 Response 값이 반환됨
    if (!success) {
      setLoading(false);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `공동구매 조회 중 오류#2`,
        msg: `[uid=${user._id}]`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] 잘못된 Response 값이 반환됨');
      WoAlert.fire('공동구매', '공동구매 조회 중 오류가 발생했습니다', 'error').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    setLoading(false);

    // 공동구매 상세정보가 있음
    if (Object.keys(cobuying).length > 0) {
      const { sale_end_dt, sale_state } = cobuying;

      const now = moment();
      const saleEndMmt = moment(sale_end_dt);

      // 공동구매 기간이 지나버림
      if (now.isSameOrAfter(saleEndMmt) || cobuying.show !== 'true') {
        await WoAlert.fire('공동구매', '공동 구매 주문기간이 종료되었습니다.', 'info').then(() => {
          navigate('/', { replace: true });
        });
      }
      // 공동구매의 진행상태가 올바르지 않을 경우
      else if (sale_state !== 'ING' && !user.god) {
        await WoAlert.fire('공동구매', '공동 구매 주문 기간이 아닙니다.', 'info').then(() => {
          navigate('/', { replace: true });
        });
      } else {
        setCoBuying({ loaded: true, data: cobuying });
      }
    }
    // 공동구매 정보가 없는경우
    else {
      await WoAlert.fire('공동구매', '공동 구매 주문기간이 종료되었습니다.', 'info').then(() => {
        navigate('/', { replace: true });
      });
    }
  }, []);

  React.useEffect(() => {
    fetchCoBuyingDetail();
  }, [coBuyingId]);

  // 공동구매 종료까지 남은 시간 계산
  const coBuyingCountdown = (endDt) => {
    const today = moment().format('YYYY-MM-DD HH:mm:ss');
    const coBuyingEndDt = moment(moment(endDt).locale('ko').format('YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(coBuyingEndDt.diff(today));

    const days = duration.days(); // 프로모션 남은 종료기간을 일(day)로 계산
    const hours = duration.hours(); // 프로모션 남은 종료기간이 24시간 이전일 경우 사용
    const minutes = duration.minutes(); // 프로모션 남은 종료기간이 60분 이전일 경우 사용
    const seconds = duration.seconds(); // 프로모션 남은 종료기간이 60초 이전일 경우 사용

    let result;

    // 공동구매 종료 기간이 24시간 이상일 경우
    if (days > 0) {
      result = `${days}일`;
    }
    // 공동구매 종료 기간이 24시간 이하, 60분 이상일 경우
    else if (hours > 0) {
      result = `${hours}시간${minutes > 0 ? ` ${minutes}분` : ''}`;
    }
    // 공동구매 종료 기간이 60분 이전일 경우
    else if (minutes > 0) {
      result = `${minutes}분`;
    }
    // 공동구매 종료 기간이 60초 이전일 경우
    else if (seconds > 0) {
      result = '1분';
    }
    // 공동구매 종료됨
    else {
      result = 0;
    }

    return result;
  };

  // 픽업샵 선택하기
  const clickSelectVendorButton = async () => {
    // 현재판매량
    const orderCnt = coBuying.data.order_cnt === null ? 0 : Number(coBuying.data.order_cnt);

    // 유저의 구입수량
    const myOrderCnt = coBuying.data.my_order_cnt === null ? 0 : Number(coBuying.data.my_order_cnt);

    // 주문수량이 최대수량을 넘었을경우
    if (coBuying.data.max_quantity <= orderCnt) {
      await WoAlert.fire('공동구매', '상품이 품절되었습니다.', 'info');
      return undefined;
    }
    // show가 false일 경우
    if (coBuying.data.show === 'false') {
      await WoAlert.fire('공동구매', '공동 구매 주문기간이 종료되었습니다.', 'info').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // todo 상품 판매기간이 종료되었을 경우
    if (new Date(coBuying.data.sale_end_dt) < new Date()) {
      await WoAlert.fire('공동구매', '공동 구매 주문기간이 종료되었습니다.', 'info').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // 판매제한수량을 넘었을경우
    if (coBuying.data.sale_cnt !== 0 && myOrderCnt >= coBuying.data.sale_cnt) {
      await WoAlert.fire('공동구매', '판매 제한 수량이 초과되었습니다.', 'info');
      return undefined;
    }

    // 입점샵 선택 지도로 이동
    navigate(`/co-buying/${coBuyingId}/map`, {
      state: { location: coBuying.data.location, location_level: coBuying.data.location_level }
    });
  };

  // 주문기간 표현
  const orderablePeriodContent = React.useMemo(() => {
    if (!coBuying.loaded) return <Skeleton width="80px" />;

    const startDtMmt = moment(coBuying.data.sale_start_dt);
    const endDtMmt = moment(coBuying.data.sale_end_dt);

    // test 같은 연월일
    let format = startDtMmt.format('YYMMDD') === endDtMmt.format('YYMMDD') ? 'MM.DD(ddd) HH:mm' : 'MM.DD(ddd)';

    if (moment(startDtMmt).isSame(endDtMmt, 'day')) {
      return <Typography>{startDtMmt.format('MM.DD(ddd)')}</Typography>;
    }

    // test 연도차이
    if (startDtMmt.year() !== endDtMmt.year()) format = 'YY. ' + format;
    return (
      <Typography>
        {startDtMmt.format(format)}&nbsp;~&nbsp;{endDtMmt.format(format)}
      </Typography>
    );
  }, [coBuying.loaded]);

  const successRate = Number(coBuying?.data?.success_rate) ?? 0;
  let successRateLabel;

  // 달성률 포맷 (99 ~ 100 : 소수점 버림, 그 외 : 올림)
  if (successRate >= 99 && successRate <= 100) {
    successRateLabel = Math.floor(successRate);
  } else {
    successRateLabel = Math.ceil(successRate);
  }

  // 품절여부확인
  const isSoldOut = React.useMemo(() => {
    if (!coBuying.loaded) {
      return false;
    }
    const orderCnt = coBuying?.data?.order_cnt === null ? 0 : Number(coBuying?.data.order_cnt);
    return coBuying?.data.max_quantity <= orderCnt;
  }, [coBuying.loaded]);

  // render
  return (
    <BackHeader title="공동 구매" showFooter={false} showBottomNav={false} hideDivider showShare={coBuying.loaded} shareProp={shareProp}>
      {/* todo : backdrop쓰는곳 공통적으로 debounce 처리 */}
      <Backdrop open={debouncedLoading} sx={{ zIndex: 2400 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <WineBottleLoadingLottie />
          <Typography variant="caption" color="#fff">
            상품 정보를 불러오는 중입니다.
          </Typography>
        </Box>
      </Backdrop>
      {coBuying.loaded && (
        <>
          <Container maxWidth="sm" component="article">
            {/* 와인 이미지[start] */}
            <Box
              component="section"
              position="relative"
              className="wine-img-wrapper"
              height={289}
              mx="-16px"
              pb="12px"
              aria-label="상품 이미지"
            >
              <Box
                className="gradient-bg"
                width="100%"
                height={173}
                position="absolute"
                left={0}
                bottom={0}
                sx={{ backgroundImage: `linear-gradient(to bottom, #fff 33%, #f4f0f8)`, marginTop: '33px' }}
              />

              <ProductImage
                width="100%"
                height="100%"
                src={coBuying?.data.bottle_img?.removebg ?? null}
                wrapperProps={{ sx: { backgroundColor: 'transparent', borderRadius: 'none' } }}
                alt="와인 이미지"
              />
            </Box>
            {/* 와인 이미지[end] */}
            <Box width="100%" textAlign="center" mt="12px">
              <Typography variant="caption" fontSize="10px" color="text.secondary">
                ※ 본 상품의 이미지는 실제와 다를 수 있습니다.
              </Typography>
            </Box>

            {/* 와인정보 [start] */}
            <Box mt="28px">
              <Box display="flex" marginBottom="7px">
                {isSoldOut && (
                  <Chip
                    label="품절"
                    variant="contained"
                    sx={{
                      borderRadius: '4px',
                      height: '24px',
                      marginRight: '4px',
                      backgroundColor: '#EEECF1',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      '& .MuiChip-label': {
                        paddingX: '8px',
                        color: '#4B346A'
                      }
                    }}
                  />
                )}
                <Chip
                  label={`${coBuying.data.success_rate === null ? 0 : successRateLabel}% 달성`}
                  variant="contained"
                  sx={{
                    borderRadius: '4px',
                    height: '24px',
                    backgroundColor: '#D8E7FE',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    '& .MuiChip-label': {
                      paddingX: '8px',
                      color: '#3C88FA'
                    }
                  }}
                />
                <Chip
                  label={
                    <Box display="flex" alignItems="center">
                      <PromotionClockIcon color="#5D47F5" />
                      <Typography fontWeight="bold" color="#5D47F5" fontSize="12px" sx={{ marginTop: '1px', marginLeft: '2px' }}>
                        {coBuyingCountdown(coBuying.data.sale_end_dt)} 남음
                      </Typography>
                    </Box>
                  }
                  variant="contained"
                  sx={{
                    marginLeft: '4px',
                    borderRadius: '4px',

                    height: '24px',
                    backgroundColor: '#DEDAFD',
                    '& .MuiChip-label': {
                      paddingX: '8px'
                    }
                  }}
                />
              </Box>
              <Box component="section" aria-label="상품명">
                <Typography
                  fontSize="20px"
                  fontWeight="900"
                  component="h1"
                  sx={{ color: theme.palette.text.primary, lineHeight: '1.4', opacity: `${isSoldOut ? 0.4 : 1}` }}
                >
                  {coBuying.data.name__ko}
                </Typography>
                <Typography
                  textAlign="left"
                  fontSize="12px"
                  fontWeight="400"
                  marginTop="4px"
                  marginBottom="8px"
                  sx={{ color: theme.palette.text.primary, opacity: '.4', lineHeight: '1.4' }}
                >
                  {coBuying.data.name__en}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                {coBuying.data.vintage !== 'NV' && (
                  <>
                    <Typography fontSize="12px"> {coBuying.data.vintage}년</Typography>
                    <Box
                      marginX="6px"
                      width="1px"
                      height="11px"
                      component="img"
                      src={TasteDivision}
                      alt="divider"
                      sx={{ objectFit: 'cover' }}
                    />
                  </>
                )}

                <Typography fontSize="12px">{Calculator.formatCapacity(coBuying.data.capacity)}</Typography>
              </Box>
            </Box>
            {/* 와인정보 [end] */}

            {/* 가격정보 */}
            <Box mt="21px">
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography fontFamily="D-DIN" fontSize="18px" color="#140229" sx={{ opacity: '.6', textDecoration: 'line-through' }}>
                  {coBuying.data?.price__sale?.toLocaleString()}
                </Typography>
                <Typography fontSize="14px" color="#140229" sx={{ opacity: '.6', textDecoration: 'line-through', verticalAlign: 'top' }}>
                  원
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="left" marginTop="-5px">
                <Typography fontWeight="800" color="#9357E5" fontSize="21px">
                  {parseInt(coBuying.data.discount_percent, 10)}%
                </Typography>
                <Typography
                  fontFamily="D-DIN"
                  color="#2D154D"
                  fontSize="28px"
                  sx={{ marginLeft: '4px', opacity: `${isSoldOut ? 0.4 : 1}` }}
                >
                  {coBuying.data?.price__original?.toLocaleString()}
                </Typography>
                <Typography fontWeight="300" color="#2D154D" fontSize="22px" sx={{ opacity: `${isSoldOut ? 0.4 : 1}` }}>
                  원
                </Typography>
              </Box>
            </Box>
          </Container>
          <Box marginY="24px" width="100%" sx={{ height: '8px', backgroundColor: '#F3F2F5' }} />

          {/* 주문/픽업 정보 */}
          <Container maxWidth="sm" component="article">
            <Box component="section">
              <Typography fontWeight="900" fontSize="16px" sx={{ marginBottom: '16px' }}>
                주문/픽업 정보
              </Typography>
              <Box display="flex" alignItems="center" marginBottom="16px">
                <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                  주문 기간
                </Typography>
                <Box
                  marginX="6px"
                  width="1px"
                  height="13px"
                  component="img"
                  src={TasteDivision}
                  alt="divider"
                  sx={{ objectFit: 'cover' }}
                />

                {orderablePeriodContent}
                {/* {moment(coBuying.data.sale_start_dt).locale('ko').format('MM.DD (ddd)')} &nbsp;~&nbsp; */}
                {/* {moment(coBuying.data.sale_end_dt).locale('ko').format('MM.DD (ddd)')} */}
              </Box>
              <Box display="flex" alignItems="center" marginBottom="16px">
                <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                  입고 일정
                </Typography>
                <Box
                  marginX="6px"
                  width="1px"
                  height="13px"
                  component="img"
                  src={TasteDivision}
                  alt="divider"
                  sx={{ objectFit: 'cover' }}
                />
                <Typography>
                  주문 마감 후 {moment(coBuying.data.delivery_end_dt).locale('ko').format('MM.DD(ddd)')}까지 픽업샵에 입고
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="16px">
                <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                  픽업 기간
                </Typography>
                <Box
                  marginX="6px"
                  width="1px"
                  height="13px"
                  component="img"
                  src={TasteDivision}
                  alt="divider"
                  sx={{ objectFit: 'cover' }}
                />
                <Typography>픽업샵 입고 완료 후 별도 안내</Typography>
              </Box>
            </Box>
          </Container>
          <Box marginY="23px" width="100%" sx={{ height: '8px', backgroundColor: '#F3F2F5' }} />

          {/* 공동 구매 진행 안내 */}
          <Container maxWidth="sm" component="article" sx={{ paddingBottom: '120px' }}>
            <Box paddingX="16px" paddingY="20px" bgcolor="#F7F4FA" borderRadius="13px">
              <Typography marginBottom="14px" fontWeight={900} fontSize="16px">
                공동 구매 진행 안내
              </Typography>
              <Box marginBottom="12px" display="flex">
                <CheckIcon fontSize="14px" color="#220348" sx={{ mt: '2px' }} />
                <Typography sx={{ marginLeft: '8px' }}> 픽업샵과 상품 수량을 선택하여 주문/결제 시 공동구매 참여 완료!</Typography>
              </Box>
              <Box marginBottom="12px" display="flex">
                <CheckIcon fontSize="14px" color="#220348" sx={{ mt: '2px' }} />
                <Typography sx={{ marginLeft: '8px' }}>
                  목표 수량이 달성되면 공동구매 성공! <br /> (자세한 픽업 일정은 메세지로 별도 안내)
                </Typography>
              </Box>
              <Box display="flex">
                <CheckIcon fontSize="14px" color="#220348" sx={{ mt: '2px' }} />
                <Typography sx={{ ml: '8px' }}>주문 기간 동안 목표 수량이 채워지지 않으면, 결제하신 수단으로 자동 환불됩니다.</Typography>
              </Box>
            </Box>

            {/* 공동구매 상세 내용 */}
            <Box pt="5px" mx="-16px" dangerouslySetInnerHTML={{ __html: coBuying.data.content }} />
          </Container>

          {/* 픽업샵 선택 버튼 영역 */}
          <Box
            bgcolor="#FFFFFF"
            sx={{
              paddingX: '20px',
              paddingY: '16px',
              position: 'fixed',
              zIndex: 1200,
              border: `1px solid ${alpha(theme.palette.brand.main, 0.1)}`,
              borderBottom: 'none',
              boxShadow: '0px -2px 10px 0 rgba(0, 0, 0, 0.16)',
              right: 0,
              left: 0,
              bottom: 0,
              width: '100%'
            }}
          >
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="brand"
              size="x-large"
              disabled={isSoldOut}
              onClick={() => {
                clickSelectVendorButton();
              }}
              sx={{ fontWeight: '600' }}
            >
              {isSoldOut ? '품절되었습니다' : '픽업샵 선택하기'}
            </Button>
          </Box>
        </>
      )}
    </BackHeader>
  );
};

export default CoBuyingDetail;

// eslint-disable-next-line react/prop-types
function PromotionClockIcon({ width = 14, height = 12, color = '#9357E5' }) {
  return (
    <SvgIcon viewBox="0 0 16 14" sx={{ width, height }}>
      <g transform="translate(-281 -447)">
        <rect style={{ width, height, fill: '#DEDAFD' }} transform="translate(281 447)" />
        <g transform="translate(282.401 424.744)">
          <g transform="translate(1.527 24.607)">
            <g transform="translate(0 0)">
              <path
                d="M65.829,77.017A5.057,5.057,0,1,0,70.9,82.074,5.071,5.071,0,0,0,65.829,77.017Zm0,9.2a4.141,4.141,0,1,1,4.153-4.141A4.151,4.151,0,0,1,65.829,86.215Z"
                transform="translate(-60.757 -77.017)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(5.905 27.338)">
            <g transform="translate(0 0)">
              <path
                d="M229.764,182.408h-1.186v-1.72a.445.445,0,1,0-.89,0v2.165a.445.445,0,0,0,.445.445h1.631a.445.445,0,1,0,0-.89Z"
                transform="translate(-227.688 -180.243)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(1.838 32.883)">
            <g transform="translate(0 0)">
              <path
                d="M75.22,394.482a.445.445,0,0,0-.628.033L73.257,396a.445.445,0,1,0,.661.6l1.335-1.483A.445.445,0,0,0,75.22,394.482Z"
                transform="translate(-73.143 -394.368)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(9.136 32.883)">
            <g>
              <path
                d="M352.5,396l-1.335-1.483a.445.445,0,1,0-.661.6l1.335,1.483a.445.445,0,1,0,.661-.6Z"
                transform="translate(-350.389 -394.371)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(0 23.256)">
            <g transform="translate(0 0)">
              <path
                d="M3.84,23.908a2.265,2.265,0,0,0-3.176,0A2.241,2.241,0,0,0,0,25.518a2.207,2.207,0,0,0,.667,1.576.445.445,0,0,0,.626,0l2.55-2.55a.445.445,0,0,0,0-.632ZM1.027,26.1a1.326,1.326,0,0,1-.138-.585,1.358,1.358,0,0,1,1.359-1.365,1.389,1.389,0,0,1,.6.134Z"
                transform="translate(0 -23.26)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(9.225 23.256)">
            <g transform="translate(0 0)">
              <path
                d="M357.228,23.907a2.265,2.265,0,0,0-3.176,0,.445.445,0,0,0,0,.632l2.55,2.55a.444.444,0,0,0,.626,0,2.206,2.206,0,0,0,.667-1.576A2.243,2.243,0,0,0,357.228,23.907Zm-.363,2.187-1.816-1.816A1.361,1.361,0,0,1,357,25.51,1.326,1.326,0,0,1,356.864,26.094Z"
                transform="translate(-353.918 -23.256)"
                style={{ fill: color }}
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
