// API 서버 호스트
import axios from 'axios';

const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const NODE_URL = {
  /** 카카오 사용자 로그인 처리 */
  POST__SIGN_IN_KAKAO: `${API_SERVER_HOST}/auth/sign-in/kakao`
};

/**
 * 카카오 로그인 정보 요청
 * @param code 인가 코드 받기 요청으로 얻은 인가 코드
 * @param redirect_uri 인가 코드가 리다이렉트된 URI
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function requestKakaoSignIn(code, redirect_uri) {
  return axios
    .post(NODE_URL.POST__SIGN_IN_KAKAO, { code, redirect_uri })
    .then((response) => response.data)
    .catch((error) => {
      console.error('[AuthService][requestKakaoSignIn] error', error);
      throw error;
    });
}

export async function signUp({ user, terms }) {
  return axios.post(
    `${API_SERVER_HOST}/auth/member/register`,
    { user, terms },
    // 20220919 - signedData의 길이가 긴 사용자가 회원가입 안되는 상황해결
    {
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    }
  );
}
