// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import * as CartService from 'services/CartService';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';
import { getAuth } from 'firebase/auth';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

export const initialState = Object.freeze({
  error: null,
  open: false,
  loading: false,
  items: [],
  totalVendors: 0,
  totalItems: 0
});

/**
 * 장바구니 slice
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>
 */
const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // 카트 목록 조회 시작
    fetchCartItemsStart(state) {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    // 카트 목록 조회 성공
    fetchCartItemsSuccess(state, action) {
      state.loading = false;
      const { items, totalVendors, totalItems } = action.payload;
      state.items = items;
      state.totalVendors = totalVendors;
      state.totalItems = totalItems;
    },
    // 카트 목록 조회 실패
    fetchCartItemsFailure(state, action) {
      state.loading = false;
      state.items = [];
      state.error = action.payload;
    },
    // 카트 API 오류 발생
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // 카트 상품 추가 성공
    addProductSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    // 카트 상품 삭제 시작
    removeProductStart(state) {
      state.loading = true;
      state.error = null;
    },
    // 카트 상품 삭제 성공
    removeProductSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    // 카트 상품 삭제 실패
    removeProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // 카트 목록 초기화
    resetUserCart(state) {
      state.error = null;
      state.open = false;
      state.loading = false;
      state.items = [];
      state.totalVendors = 0;
      state.totalItems = 0;
      console.warn('사용자 장바구니가 초기화되었습니다.');
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
/**
 * 장바구니 목록 불러오기
 * @returns {(function(): Promise<void>)|*}
 */
export function fetchCartItems() {
  return async () => {
    console.log('[장바구니]로그인 사용자의 장바구니 정보를 조회 시작');
    const { currentUser } = getAuth();
    const { uid } = currentUser;
    try {
      const body = {};
      dispatch(slice.actions.fetchCartItemsStart());
      const response = await CartService.fetchProducts(body);

      const { code, msg } = response.data.result;

      if (code === 0) {
        // 카트 목록
        const { data: cartList } = response.data;

        // 카트에 담겨 있는 상품 개수
        let totalCount = 0;
        cartList.forEach((cart) => {
          totalCount += cart.products.length;
        });

        const cartItems = {
          items: cartList,
          totalVendors: cartList.length,
          totalItems: totalCount
        };

        dispatch(slice.actions.fetchCartItemsSuccess(cartItems));
      } else {
        console.error(`[장바구니] 장바구니 목록 조회 중 오류 발생 [code=${code}, msg=${msg}]`);
        dispatch(slice.actions.hasError(msg));
        if (msg !== 'Network Error') {
          // clo
          const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
          sendCloError({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `장바구니 목록 조회 중 오류가 발생했습니다. [uid=${uid}]`,
            msg: `${JSON.stringify(msg)}`,
            which: `[uid=${uid ?? 'anonymous'}]`,
            param: {
              uid
            }
          })
            .then(console.log)
            .catch(console.error);
        }
      }
    } catch (error) {
      console.error(`[장바구니] 장바구니 목록 조회 중 오류 발생 [${error}]`);
      if (error.message !== 'Network Error') {
        // clo
        const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `장바구니 목록 조회 중 오류가 발생했습니다. [uid=${uid}]`,
          msg: `${JSON.stringify(error)}`,
          which: `[uid=${uid ?? 'anonymous'}]`,
          error,
          param: {
            uid
          }
        })
          .then(console.log)
          .catch(console.error);
      }

      dispatch(slice.actions.fetchCartItemsFailure(error));
    }
  };
}

/**
 * 장바구니에 상품 담기
 * @param {Array} products
 * @returns {(function(): Promise<void>)|*}
 */
export function addProduct(products = []) {
  const { currentUser } = getAuth();
  const { uid } = currentUser;

  // 데이터 체크
  if (products.length === 0) {
    console.warn('[장바구니] 선택된 상품이 없습니다.');
    dispatch(
      openSnackbar({
        open: true,
        message: '선택된 상품이 없습니다.',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true,
        autoHideDuration: 4000
      })
    );
    return { code: 1, msg: '선택된 상품이 없습니다.' };
  }

  return async () => {
    try {
      const response = await CartService.addProducts(products);

      const { code, msg } = response.data.result;

      if (code === 0) {
        console.debug('[장바구니] 장바구니 상품 추가 성공.');
        dispatch(slice.actions.addProductSuccess(products));
        // 장바구니 상품 삭제 후 장바구니 목록 재호출
        dispatch(fetchCartItems());
      } else {
        console.error(`[장바구니] 장바구니에 상품 담기 중 오류 발생 [code=${code}, msg=${msg}]`);
        dispatch(slice.actions.hasError(msg));

        // clo
        const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `장바구니에 상품 담기 중 오류가 발생했습니다. [uid=${uid}, nickname=${currentUser?.displayName ?? 'undefined'}]`,
          msg
        })
          .then(console.log)
          .catch(console.error);

        // fail snackbar
        dispatch(
          openSnackbar({
            open: true,
            message: '장바구니에 상품을 담는데 실패했습니다.',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true,
            autoHideDuration: 4000
          })
        );
      }

      return { code, msg };
    } catch (error) {
      console.error(`[장바구니] 장바구니에 상품 담기 중 오류 발생 [error=${error}]`);
      dispatch(slice.actions.hasError(error));

      // clo
      const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `장바구니에 상품 담기 중 오류가 발생했습니다. [uid=${uid}, nickname=${currentUser?.displayName ?? 'undefined'}]`,
        msg: JSON.stringify(error)
      })
        .then(console.log)
        .catch(console.error);

      // fail snackbar
      dispatch(
        openSnackbar({
          open: true,
          message: '장바구니에 상품을 담는데 실패했습니다.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
    }
  };
}

/**
 * 장바구니에서 상품 제거
 * @param Array products
 * @returns {(function(): Promise<void>)|*}
 */
export function removeProduct(products = []) {
  const { currentUser } = getAuth();
  const { uid } = currentUser;

  // 데이터 체크
  if (products.length === 0) {
    console.warn('[장바구니] 선택된 상품이 없습니다.');
    dispatch(
      openSnackbar({
        open: true,
        message: '선택된 상품이 없습니다.',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        autoHideDuration: 400
      })
    );
    return { code: 1, msg: '선택된 상품이 없습니다.' };
  }
  return async () => {
    try {
      dispatch(slice.actions.removeProductStart());
      const response = await CartService.deleteProducts(products);

      const { code, msg } = response.data.result;

      if (code === 0) {
        console.debug('[장바구니] 장바구니 상품 삭제 성공.');
        dispatch(slice.actions.removeProductSuccess());

        // 장바구니 상품 삭제 후 장바구니 목록 재호출
        dispatch(fetchCartItems());

        // success snackbar
        dispatch(
          openSnackbar({
            open: true,
            message: '상품이 삭제되었습니다.',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true,
            autoHideDuration: 4000
          })
        );
        dispatch(slice.actions.removeProductSuccess());
      } else {
        console.error(`[장바구니] 장바구니 상품 삭제 중 오류 발생 [code=${code}, msg=${msg}]`);
        dispatch(slice.actions.hasError(msg));

        // clo
        const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `장바구니에 상품 담기 중 오류가 발생했습니다. [uid=${uid}, nickname=${currentUser?.displayName ?? 'undefined'}]`,
          msg
        })
          .then(console.log)
          .catch(console.error);

        // fail snackbar
        dispatch(
          openSnackbar({
            open: true,
            message: '장바구니 상품 삭제를 실패했습니다.',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true,
            autoHideDuration: 4000
          })
        );
      }

      return { code, msg };
    } catch (error) {
      console.error(`[장바구니] 장바구니 상품 삭제 중 오류 발생 [error=${error}]`);
      dispatch(slice.actions.hasError(error));

      // clo
      const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `장바구니 상품 삭제 중 오류가 발생했습니다. [uid=${uid}]`,
        msg: `${JSON.stringify(error)}`,
        which: `[uid=${uid ?? 'anonymous'}]`,
        error,
        param: {
          uid
        }
      })
        .then(console.log)
        .catch(console.error);

      // fail snackbar
      dispatch(
        openSnackbar({
          open: true,
          message: '장바구니 상품 삭제를 실패했습니다.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,
          autoHideDuration: 4000
        })
      );

      dispatch(slice.actions.removeProductFailure());
    }
  };
}

/**
 * 장바구니 초기화
 * @returns {(function(): Promise<void>)|*}
 */
export function resetUserCart() {
  return async () => {
    dispatch(slice.actions.resetUserCart());
  };
}
