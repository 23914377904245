// Firebase functions base host.
const FB_FUNCTIONS_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const FIREBASE_FUNCITON_URL = {
  /** 벤더에서 다운로드 가능한 쿠폰 목록
   * @deprecated
   */
  POST__VENDOR_COUPON_LIST: `${FB_FUNCTIONS_HOST}/call-pay-coupon-vendorCouponList`
};

export const NODE_URL = {
  // 샵 리스트
  POST__GET_VENDOR_LIST: `${API_SERVER_HOST}/vendor/getFind`,

  // 최근 픽업한 샵 목록
  POST__GET_RECENT_VENDOR_LIST: `${API_SERVER_HOST}/vendor/getRecent`,

  // 관심샵 목록
  POST__GET_FVR_VENDOR_LIST: `${API_SERVER_HOST}/vendor/getFvr`,

  // 관심샵 추가
  POST__ADD_FVR_VENDOR: `${API_SERVER_HOST}/vendor/addFvr`,

  // 관심샵 삭제
  DELETE__DELETE_FVR_VENDOR: `${API_SERVER_HOST}/vendor/deleteFvr`,

  // 샵 관심수 조회
  POST__GET__ONE__VENDOR: `${API_SERVER_HOST}/vendor/getOneVendor`,

  // 샵 리스트 카운트 조회
  POST__GET__VENDORS_CNT: `${API_SERVER_HOST}/vendor/getVendorsCnt`,

  /** 입점샵 코드로 입점샵 리스트 조회 */
  POST__GET_FIND_BY_CODE: `${API_SERVER_HOST}/vendor/getFindByCode`,

  /** 입점샵 코드로 입점샵 상세조회 */
  GET__GET_ONE_BY_CODE: `${API_SERVER_HOST}/vendor/getOneByCode`,

  /** 추천 입점샵 소급적용 */
  POST__ADD_INVITE_VENDOR: `${API_SERVER_HOST}/vendor/addInviteVendor`,

  // 현재 진행 중인 입점샵 프로모션 조회
  GET__GET_PROM_VENDOR: `${API_SERVER_HOST}/vendor/promotion/current`,

  // 지도에 위치한 입점샵 리스트 조회
  POST__VENDOR_MAP_LIST: `${API_SERVER_HOST}/vendor/getVendorMapList`,

  // 지도에 위치한 핀 리스트 조회
  POST__PIN_MAP_LIST: `${API_SERVER_HOST}/vendor/getPinMapList`,

  /** 입점샵 상품 목록 조회 */
  POST__GET_PRODUCT_LIST: `${API_SERVER_HOST}/vendor/product/list`,

  /** @deprecated 입점샵 픽업 가능일시 조회 (old) */
  GET__GET_PICKUP_AVAILABLE_DATES: `${API_SERVER_HOST}/vendor/:vendor_id/pickup-dates`,
  /** 입점샵 픽업 가능일시 조회 */
  POST__GET_PICKUP_AVAILABLE_DATES: `${API_SERVER_HOST}/order/check/pickup/available-dates`,

  /** 가까운 핀찾기 */
  POST__FIND_NEAREST_PIN: `${API_SERVER_HOST}/vendor/findNearestPin`
};

export * from './vendor'; // 입점사(판매사) 정보
