import React from 'react';
import { Route } from 'react-router-dom';

import AuthGuard from '../utils/route-guard/AuthGuard';

import HomeIndex from 'views/home/HomeIndex';
import WineDiscoveryDetailPage from 'views/misc/magazine/WineDiscoveryDetailPage';
import CurationProductListPage from 'views/home/HomeIndex/components/CurationProductList';
import MainReviews from 'views/home/MainReviews';
import MainReviewDetail from 'views/home/MainReviewDetail';
import RealtimeOrders from '../views/realtime/RealtimeOrders';
import SelfPromotionsPage from 'views/promotion/SelfPromotions';
import AuthLayout from '../utils/route-guard/AuthLayout';
import VendorListMap from '../views/vendor/VendorList/components/VendorListMap';
import ThemeDetail from '../views/home/HomeIndex/components/ThemeSection/ThemeDetail';
import TodayOneBottleDetail from '../views/home/HomeIndex/components/TodayOneBottle/TodayOneBottleDetail';
import CoBuyingDetail from '../views/home/HomeIndex/components/CoBuying/CoBuyingDetail/CoBuyingDetail';
import CobuyingMap from '../views/home/HomeIndex/components/CoBuying/CoBuyingMap';
import Promotion from '../views/promotion/Promotion';
// import LiveTalkList from 'views/home/HomeIndex/components/LiveTalk/LiveTalkList';
// import LiveTalkCreate from 'views/home/HomeIndex/components/LiveTalk/LiveTalkCreate';
// import StockSaleList from 'views/stockSale';
import ReviewThread from '../views/home/HomeIndex/components/ReviewThread';
import PromotionVendorMap from '../views/promotion/Promotion/components/PromotionVendorMap';
import NaraPosCampaignList from '../views/pos/nara/campaign/NaraCampaignList/index';

/**
 * 홈관련 라우트
 * @constructor
 */
export default function HomeRoutes() {
  return (
    <>
      <Route path="/home" element={<AuthLayout />}>
        <Route
          index
          element={
            <AuthGuard>
              <HomeIndex />
            </AuthGuard>
          }
        />
        {/* 프로모션 진행 중 와인샵 목록 페이지 */}
        <Route path="self-promotions" element={<SelfPromotionsPage />} />
        {/* 테마 상세 페이지 */}
        <Route path="theme/:themeId" element={<ThemeDetail />} />
        {/* 큐레이션 카드 상품목록 페이지 */}
        {/* <Route path="curation-card/:curationCardId" element={<CurationCardProductListPage />} /> */}
        {/* 큐레이션 상품목록 페이지 */}
        <Route path="curation/:curationId" element={<CurationProductListPage />} />
        {/* 메인리뷰 목록 */}
        <Route path="main-reviews" element={<MainReviews />} />
        <Route path="main-review/:reviewId" element={<MainReviewDetail />} />
        {/* 리뷰 쓰레드 목록 */}
        <Route path="review-thread" element={<ReviewThread />} />
        <Route path="live" element={<RealtimeOrders />} />
        {/* 술술 라이브 톡 목록 화면 */}
        {/* <Route path="live-talk/list" element={<LiveTalkList />} /> */}
        {/* 술술 라이브 톡 등록 화면 */}
        {/* <Route path="live-talk/create" element={<LiveTalkCreate />} /> */}
      </Route>

      {/* 오늘의 한 병 페이지 */}
      <Route path="/today-one-bottle/:tobId" element={<TodayOneBottleDetail />} />

      {/* 공동구매 상세 페이지 */}
      <Route path="/co-buying/:coBuyingId" element={<CoBuyingDetail />} />

      {/* 공동구매 지도 페이지 */}
      <Route path="/co-buying/:coBuyingId/map" element={<CobuyingMap height={`calc(var(--vh, 1vh) * 100`} />} />

      {/* 예약판매 프로모션 페이지 */}
      <Route path="/promotion/:promotionId" element={<Promotion />} />
      <Route path="/promotion/:promotionId/map" element={<PromotionVendorMap height={`calc(var(--vh, 1vh) * 100`} />} />

      {/* 나라POS 캠페인 목록 페이지 */}
      <Route path="/pos/campaign/list" element={<NaraPosCampaignList />} />

      {/* 메인 콘텐츠 상세화면 */}
      <Route
        path="/magazine/:wineDiscoveryId"
        element={
          <AuthGuard>
            <WineDiscoveryDetailPage />
          </AuthGuard>
        }
      />

      {/* VENDOR MAP 화면 */}
      <Route path="/map/vendor" element={<VendorListMap height={`calc(var(--vh, 1vh) * 100`} />} />
    </>
  );
}
