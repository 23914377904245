import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment';
import PropTypes from 'prop-types';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { setReviewThreadLike } from 'services/ReviewThreadService';
import { ReviewHeartIcon } from 'components';
import LikeButtonLottie from 'components/spinner/LikeButtonLottie';

// material-ui
import { Box, ButtonBase, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

// 리뷰 이미지 가로 크기
const REVIEW_IMG_WIDTH = 52;
// 리뷰 이미지 세로 크기
const REVIEW_IMG_HEIGHT = 52;

/**
 * 메인 리뷰 쓰레드 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const MainReviewThreadCard = ({ review, index, goThreadList }) => {
  // 리뷰 이미지
  const [src, setSrc] = React.useState({ type: '', url: '' });

  // 접속한 유저의 리뷰 좋아요 플래그
  const [reviewLike, setReviewLike] = React.useState(review?.like_flag);
  // 리뷰 좋아요 수
  const [reviewLikeCnt, setReviewLikeCnt] = React.useState(review?.review_like_cnt ?? 0);
  // 리뷰 좋아요 로딩
  const [reviewLikeLoading, setReviewLikeLoading] = React.useState(false);

  React.useEffect(() => {
    if (!review) return;
    setReviewLike(review?.like_flag);
    setReviewLikeCnt(review?.review_like_cnt ?? 0);
  }, [review]);

  // 작성일자
  const writeDate = React.useMemo(() => {
    if (!review?.review_created_at) return null;

    const { review_created_at } = review;
    return moment(review_created_at).fromNow();
  }, [review]);

  // 평점
  const stars = React.useMemo(() => {
    let stars = null;
    if (typeof review?.review_star === 'number') {
      stars = (
        <Rating
          readonly
          initialValue={review.review_star}
          size="12px"
          allowFraction
          emptyIcon={<EmptyRatingIcon />}
          fillIcon={<FilledRatingIcon />}
          fillStyle={{ paddingTop: '4px', marginLeft: '-1px' }}
          emptyStyle={{ paddingTop: '4px', marginLeft: '-1px' }}
        />
      );
    }
    return stars;
  }, [review]);

  // 구매자 리뷰 칩
  const buyerReviewChip = React.useMemo(() => {
    let chip = null;
    const { order_count = 0 } = review;
    if (order_count > 0) {
      chip = (
        <Box
          component="span"
          display="inline-block"
          bgcolor="#F1F0F2"
          color="#9357E5"
          fontSize="9px"
          borderRadius="4px"
          px="4px"
          py="2px"
          fontWeight={700}
          lineHeight="normal"
          letterSpacing="-0.12px"
          textAlign="center"
        >
          구매자 리뷰
        </Box>
      );
    }
    return chip;
  }, [review]);

  // 리뷰 상품 명
  const reviewProductName = React.useMemo(() => {
    if (!review || !review?.pdata_name?.ko) return <Skeleton variant="rectangular" height="16px" width="135px" />;

    return (
      <Stack direction="row" alignItems="center">
        <ProductName>{review.pdata_name.ko}</ProductName>
      </Stack>
    );
  }, [review]);

  // 리뷰 이미지
  React.useEffect(() => {
    // setReviewLike(review?.like_flag);

    const { review_image, pdata_image } = review;

    // 등록된 리뷰 이미지가 있음
    if (review_image && review_image?.length > 0) {
      setSrc({ type: 'review', url: review_image[0]?.thumb?.url });
    } else if (pdata_image?.thumb) {
      setSrc({ type: 'pdata', url: pdata_image.thumb });
    } else {
      setSrc({ type: 'pdata', url: wineDefaultImg });
    }
  }, [review]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setSrc({ type: 'pdata', url: wineDefaultImg }); // Replace to default wine product image.
  }, []);

  // 리뷰 좋아요 버튼 클릭 이벤트
  const clickReviewLikeHandler = async () => {
    const { review_id, pdata_id } = review;

    // 리뷰 아이디 또는 pdata 아이디가 존재하지 않음
    if (!review_id || !pdata_id) {
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return false;
    }

    // 이미 리뷰 좋아요 설정/해제 중
    if (reviewLikeLoading) {
      console.warn('[MainReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 요청 중입니다.');
      return false;
    }

    setReviewLikeLoading(true);

    const body = {
      reviewId: review_id,
      pdataId: pdata_id
    };

    const result = await setReviewThreadLike(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setReviewLikeLoading(false);
      });

    // 리뷰 좋아요 설정/해제 중 오류 발생
    if (result.error) {
      console.error('[MainReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 중 오류 발생', result.error);
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return undefined;
    }

    const { code, msg, likeFlag } = result.result;

    // 잘못된 Response값이 반환됨
    if (code !== 0) {
      console.error('[MainReviewThreadCard][clickReviewLikeHandler] 리뷰 좋아요 설정/해제 중 오류 발생', msg);
      WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'warning');
      return undefined;
    }

    // 리뷰 좋아요 설정/해제 성공
    setReviewLike(likeFlag);
    setReviewLikeCnt(reviewLike ? reviewLikeCnt - 1 : reviewLikeCnt + 1);
  };

  // render
  return (
    <Box sx={{ mx: '20px', p: '16px', bgcolor: '#FFF', borderRadius: '14px' }}>
      <Box onClick={() => goThreadList(index)}>
        {/* 작성자 닉네임 & 작성 날짜 */}
        <Stack direction="row" spacing="4px" alignItems="center">
          <WriterNickname>{review?.nickname ?? <Skeleton width={100} />}</WriterNickname>
          <WriterTime>{writeDate}</WriterTime>
        </Stack>

        {/* 작성자의 별점 및 구매자 칩 */}
        <Stack direction="row" spacing="4px" alignItems="center" mt="4px" maxHeight="12px">
          {stars}
          {buyerReviewChip}
        </Stack>

        {/* 리뷰 상품 이름 */}
        <Box mt="12px">{reviewProductName}</Box>

        {/* 리뷰 내용 */}
        <Stack direction="row" spacing="28px" mt="12px" width="100%">
          <Box width={`calc(100% - ${REVIEW_IMG_WIDTH}px)`}>
            <ReviewContent>{review?.review_content}</ReviewContent>
          </Box>

          <ReviewThreadImageWrapper width={REVIEW_IMG_WIDTH} height={REVIEW_IMG_HEIGHT} ratio={REVIEW_IMG_WIDTH / REVIEW_IMG_HEIGHT}>
            <LazyLoadImage
              wrapperClassName="main-review-image-wrapper"
              src={src.url}
              alt="리뷰 이미지"
              width="100%"
              height="100%"
              effect="blur"
              draggable={false}
              style={{
                objectFit: src.type === 'pdata' ? 'contain' : 'cover',
                objectPosition: 'center center',
                padding: src.type === 'review' ? 0 : '6px 5px',
                backgroundColor: '#F4F0F8'
              }}
              onError={onError}
            />
          </ReviewThreadImageWrapper>
        </Stack>
      </Box>

      {/* 리뷰 좋아요 수 */}
      <Stack mt="12px" direction="row" spacing="4px" alignItems="center">
        <ButtonBase sx={{ width: 22, height: 22, borderRadius: '50%' }} onClick={() => clickReviewLikeHandler()}>
          <Box position="relative" width="100%" height="100%">
            {/* 리뷰 좋아요 애니메이션 */}
            {reviewLikeLoading && !reviewLike ? <LikeButtonLottie height={40} left={-4} /> : null}
            <ReviewHeartIcon filled={reviewLike} width={22} height={22} />
          </Box>
        </ButtonBase>

        <SubText>
          <b>{reviewLikeCnt?.toLocaleString()}명</b>이 좋아합니다
        </SubText>
      </Stack>
    </Box>
  );
};

export default MainReviewThreadCard;

MainReviewThreadCard.propTypes = {
  review: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  goThreadList: PropTypes.func.isRequired
};

/**
 * 작성자 닉네임
 */
const WriterNickname = styled(Typography)({
  fontSize: '12px',
  fontWeight: 800,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.12px',
  textAlign: 'left'
});

/**
 * 작성 시간
 */
const WriterTime = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.12px',
  textAlign: 'left'
});

/**
 * 리뷰 상품명
 */
const ProductName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 800,
  fontStyle: 'normal',
  lineHeight: 'normal'
});

/**
 * 리뷰 내용
 */
const ReviewContent = styled(Typography)(() => ({
  whiteSpace: 'pre-wrap',
  fontSize: '13px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '1.33',
  color: '#140229',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3
}));

// 비어있는 별 아이콘
const EmptyRatingIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 12, height: 12, fill: 'none' }} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0023 8.74691L3.41914 10.6767C3.36211 10.7193 3.29726 10.7502 3.22829 10.7677C3.15933 10.7852 3.08759 10.7889 3.01717 10.7787C2.94676 10.7684 2.87905 10.7444 2.81791 10.708C2.75678 10.6716 2.70341 10.6236 2.66085 10.5665C2.60826 10.4961 2.57362 10.4138 2.5599 10.327C2.54619 10.2401 2.55382 10.1513 2.58214 10.068L3.61855 7.01387L0.980291 5.15357C0.922006 5.11261 0.87237 5.06056 0.834224 5.0004C0.796078 4.94023 0.770171 4.87314 0.757985 4.80295C0.7458 4.73276 0.747575 4.66086 0.76321 4.59136C0.778844 4.52186 0.808031 4.45612 0.8491 4.39791C0.899815 4.32597 0.967294 4.26747 1.0457 4.22747C1.1241 4.18746 1.21107 4.16717 1.29909 4.16833L4.52246 4.21162L5.48409 1.13124C5.50518 1.06327 5.53946 1.00012 5.58497 0.945396C5.63047 0.890673 5.68631 0.845451 5.7493 0.812313C5.81228 0.779176 5.88118 0.758772 5.95205 0.752268C6.02293 0.745763 6.09439 0.753286 6.16235 0.774405C6.24632 0.800469 6.32268 0.846575 6.38485 0.908744C6.44702 0.970913 6.49313 1.04728 6.51919 1.13124L7.47558 4.21162L10.7003 4.16964C10.7715 4.1686 10.8423 4.18163 10.9085 4.20798C10.9747 4.23433 11.0351 4.27349 11.0862 4.32321C11.1372 4.37294 11.178 4.43224 11.2061 4.49774C11.2342 4.56324 11.2491 4.63363 11.25 4.7049C11.2511 4.79291 11.2308 4.87988 11.1908 4.95829C11.1508 5.03669 11.0923 5.10417 11.0204 5.15489L8.38736 7.01387L9.42377 10.068C9.44668 10.1354 9.4561 10.2066 9.45149 10.2776C9.44689 10.3486 9.42834 10.418 9.39691 10.4818C9.36548 10.5457 9.32178 10.6027 9.26831 10.6496C9.21485 10.6966 9.15266 10.7325 9.0853 10.7554C9.00205 10.7838 8.91316 10.7914 8.82631 10.7777C8.73945 10.764 8.65724 10.7293 8.58677 10.6767L7.29453 9.71182L6.0023 8.74691Z"
        fill="#EDE7F4"
      />
    </SvgIcon>
  );
});

// 채워져있는 별 아이콘
const FilledRatingIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 12, height: 12, fill: 'none' }} viewBox="0 0 12 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0023 8.74691L3.41914 10.6767C3.36211 10.7193 3.29726 10.7502 3.22829 10.7677C3.15933 10.7852 3.08759 10.7889 3.01717 10.7787C2.94676 10.7684 2.87905 10.7444 2.81791 10.708C2.75678 10.6716 2.70341 10.6236 2.66085 10.5665C2.60826 10.4961 2.57362 10.4138 2.5599 10.327C2.54619 10.2401 2.55382 10.1513 2.58214 10.068L3.61855 7.01387L0.980291 5.15357C0.922006 5.11261 0.87237 5.06056 0.834224 5.0004C0.796078 4.94023 0.770171 4.87314 0.757985 4.80295C0.7458 4.73276 0.747575 4.66086 0.76321 4.59136C0.778844 4.52186 0.808031 4.45612 0.8491 4.39791C0.899815 4.32597 0.967294 4.26747 1.0457 4.22747C1.1241 4.18746 1.21107 4.16717 1.29909 4.16833L4.52246 4.21162L5.48409 1.13124C5.50518 1.06327 5.53946 1.00012 5.58497 0.945396C5.63047 0.890673 5.68631 0.845451 5.7493 0.812313C5.81228 0.779176 5.88118 0.758772 5.95205 0.752268C6.02293 0.745763 6.09439 0.753286 6.16235 0.774405C6.24632 0.800469 6.32268 0.846575 6.38485 0.908744C6.44702 0.970913 6.49313 1.04728 6.51919 1.13124L7.47558 4.21162L10.7003 4.16964C10.7715 4.1686 10.8423 4.18163 10.9085 4.20798C10.9747 4.23433 11.0351 4.27349 11.0862 4.32321C11.1372 4.37294 11.178 4.43224 11.2061 4.49774C11.2342 4.56324 11.2491 4.63363 11.25 4.7049C11.2511 4.79291 11.2308 4.87988 11.1908 4.95829C11.1508 5.03669 11.0923 5.10417 11.0204 5.15489L8.38736 7.01387L9.42377 10.068C9.44668 10.1354 9.4561 10.2066 9.45149 10.2776C9.44689 10.3486 9.42834 10.418 9.39691 10.4818C9.36548 10.5457 9.32178 10.6027 9.26831 10.6496C9.21485 10.6966 9.15266 10.7325 9.0853 10.7554C9.00205 10.7838 8.91316 10.7914 8.82631 10.7777C8.73945 10.764 8.65724 10.7293 8.58677 10.6767L7.29453 9.71182L6.0023 8.74691Z"
        fill="#FEC466"
      />
    </SvgIcon>
  );
});

const ReviewThreadImageWrapper = styled((props) => {
  const theme = useTheme();

  const { padding, ratio, width, height, ...other } = props;
  let imgHeight = height;

  if (ratio) imgHeight = width * ratio;
  else if (Number.isNaN(imgHeight)) imgHeight = '100%';

  // 상품 이미지 배경색
  const bgcolor = theme.palette.background.pdata.wine;

  return (
    <Box
      width={width}
      minWidth={width}
      maxWidth={width}
      height={imgHeight}
      minHeight={imgHeight}
      maxHeight={imgHeight}
      p={padding}
      ratio={ratio}
      overflow="hidden"
      bgcolor={bgcolor}
      draggable={false}
      {...other}
    />
  );
})(
  ({ theme }) => `
  border-radius: 2px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  transition: ${theme.transitions.create(['border-color', 'background-color', 'box-shadow'])}
`
);

const SubText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: 'rgba(20, 2, 41, 0.7)'
});
