import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';

// project imports
import { useDispatch } from 'store';
import { closeBackdrop, openBackdrop } from 'store/slices/backdrop';
import { useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { getTodayOneBottleCheck, getTodayOneBottleList } from 'services/ProductService';
import ProductImage from '../ThemeSection/components';
import { EndOrderModal, EndSaleModal, VendorInfo } from './components';
import { TOB } from './index';

// material-ui
import { Box, Button, Chip, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

// assets
import vendorDefaultImg from 'assets/images/default_vendor.png';
import wineDefaultImg from 'assets/images/default_wine.webp';
import dashLineImg from './assets/dashed_line.png';

// 오늘의 한 병 카드 커버 가로 길이
const CARD_COVER_WIDTH = 299;
// 오늘의 한 병 카드 커버 세로 길이
const CARD_COVER_HEIGHT = 445;
// 오늘의 한 병 콘텐츠 세로 길이
const CARD_CONTENT_HEIGHT = 345;
// 오늘의 한 병 상품 이미지 세로 길이
const CARD_PRODUCT_HEIGHT = 185;

const initState = Object.freeze({
  loaded: false,
  error: false,
  errorLoading: false,
  show: false,
  list: []
});

/**
 * 오늘의 한 병
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const TodayOneBottle = () => {
  const scriptedRef = useScriptRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const globalDispatch = useDispatch();

  // 오늘의 한 병 카드 정보 swiper
  const cardSwiperRef = React.useRef(null);

  // card swiper state
  const [cardSwiper, setCardSwiper] = React.useState(null);

  // swiper state
  let SWIPER_INDEX = 0;
  const [swiperIndexState, setSwiperIndexState] = useLocationState();

  // 뒤로가기로 진입 시 swiper index 위치 복원
  React.useEffect(() => {
    if (swiperIndexState && typeof swiperIndexState === 'number' && cardSwiperRef.current) {
      SWIPER_INDEX = swiperIndexState;
      cardSwiperRef.current?.swiper?.slideTo(SWIPER_INDEX);
    }
  }, [swiperIndexState, cardSwiper]);

  // 오늘의 한 병 목록
  const [rows, setRows] = useLocationState('today-one-bottles', initState);

  // 판매 시간 종료 모달
  const [endSaleModal, setEndSaleModal] = React.useState(false);
  // 주문 완료 안내 모달
  const [orderCompleteModal, setOrderCompleteModal] = React.useState(false);
  // 모달 액션 버튼에 대한 이벤트 처리 (입점샵 id)
  const [modalActionVendorId, setModalActionVendorId] = React.useState('');

  React.useEffect(() => {
    fetchTodayOneBottleList();
  }, []);

  // 오늘의 한 병 목록 조회
  const fetchTodayOneBottleList: Promise<any> = React.useCallback(async () => {
    setRows({ ...rows, loaded: false, errorLoading: true });

    const body = {
      display_state: 'ing'
    };

    const list = await getTodayOneBottleList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottle][fetchTodayOneBottleList] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 조회 실패
    if (list.error) {
      console.error('[TodayOneBottle][fetchTodayOneBottleList] ', list.error);
      setRows({ loaded: true, error: true, errorLoading: false, show: false, list: [] });
      return undefined;
    }

    const { success, todayBottles } = list;

    // 오늘의 한 병 조회 실패
    if (!success) {
      console.error('[TodayOneBottle][fetchTodayOneBottleList] 잘못된 Response 값이 반환됨.');
      setRows({ loaded: true, error: true, errorLoading: false, show: false, list: [] });
    }

    setRows({ loaded: true, error: false, errorLoading: false, show: true, list: todayBottles });
  }, []);

  // 판매 날짜/시간 포맷
  function formatSaleDateAndTime(sale_dt) {
    // 날짜
    const saleDate = moment(sale_dt).locale('ko');
    const today = moment().startOf('day');

    let saleDateFormat;

    // sale_dt의 날짜가 오늘이면 '요일' 대신 '오늘'로 표현
    if (saleDate.isSame(today, 'day')) {
      saleDateFormat = `${moment(sale_dt).format('MM월 DD일')}(오늘)`;
    } else {
      saleDateFormat = moment(sale_dt).format('MM월 DD일(ddd)');
    }

    // 시간
    const formattedTime = saleDate.format('HH:mm');
    return { saleDateFormat, formattedTime };
  }

  // 오늘의 한 병 상세 페이지로 이동
  const goDetailPage = async (id) => {
    setSwiperIndexState(SWIPER_INDEX);

    globalDispatch(openBackdrop({ open: true, text: '상품 정보를 불러오는 중입니다.' }));

    const body = {
      bottleId: id,
      requestFvr: false
    };

    const result = await getTodayOneBottleCheck(body)
      .catch((error) => ({ error }))
      .finally(() => {
        globalDispatch(closeBackdrop());
      });

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottleDetail][getTodayOneBottleCheck] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 체크 조회 실패
    if (result.error) {
      console.error('[TodayOneBottleDetail][getTodayOneBottleCheck] ', result.error);
      WoAlert.fire('', `오늘의 한병 상품 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.`, 'error');
      return undefined;
    }

    const { code, success, todayBottle } = result;
    const { product_id, sale_state, vendor_id, display_state, remove: _remove, show: _show } = todayBottle;

    // 삭제 여부
    const remove = _remove === true || _remove === 'true';
    // 숨김 여부
    const show = _show === true || _show === 'true';

    // 삭제 또는 숨김 처리 되었을 시
    if (remove || !show) {
      WoAlert.fire('오늘의 한 병', '상품 전시 기간이 종료되었습니다.', 'warning').then(() => {
        fetchTodayOneBottleList();
      });
      return undefined;
    }

    // 판매 시간이 종료된 경우
    if (display_state === TOB.SALE.END) {
      setModalActionVendorId(vendor_id);
      setEndSaleModal(true);
      return undefined;
    }

    // 오늘의 한병을 이미 주문한 경우
    if (code === TOB.CODE.SALE_OVER) {
      setModalActionVendorId(vendor_id);
      setOrderCompleteModal(true);
      return undefined;
    }

    // 관심샵 등록 완료 & 판매중인 상품인 경우에는 주문페이지로 이동
    if (success && code === TOB.CODE.OK && sale_state === TOB.SALE.ING) {
      navigate(`/order/today-bottle/confirm?pid=${product_id}&tid=${id}`);
    }
    // 나머지 상태 오늘의 한 병 상세페이지로 이동
    else {
      navigate(`/today-one-bottle/${id}`);
    }
  };

  // render
  return (
    <Box>
      {rows.error && (
        <Box mt="42px">
          <Container aria-label="오늘의 한 병" component="article" position="relative">
            <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" lineHeight="normal" noWrap>
              오늘의 한 병
            </Typography>
            <Typography variant="subtitle1" mt={1} fontWeight={400} fontSize={16} letterSpacing="0.4px" lineHeight="normal" noWrap>
              사장님이 추천하는 특별 상품을 단 하루만 !
            </Typography>
          </Container>

          <Box mt="20px" display="flex" justifyContent="center">
            <Box
              sx={{
                position: 'relative',
                width: CARD_COVER_WIDTH,
                height: CARD_COVER_HEIGHT,
                background: 'rgba(0, 0, 0, 0.4)',
                borderRadius: '20px'
              }}
            >
              <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    textShadow: '0 3px 3px #00000029',
                    textAlign: 'center',
                    fontWeight: 400,
                    color: '#FFF',
                    letterSpacing: '-0.32px',
                    lineHeight: 'normal'
                  }}
                >
                  상품을 가져오지 못 했어요
                </Typography>
                {rows.errorLoading ? (
                  <Box mt={2}>
                    <CircularProgress size="32px" color="lime" />
                  </Box>
                ) : (
                  <Button
                    color="white"
                    variant="contained"
                    startIcon={<ReplayOutlinedIcon />}
                    sx={{ mt: 2 }}
                    onClick={() => fetchTodayOneBottleList()}
                  >
                    재시도
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {rows.loaded && !rows.error && rows.show && rows.list.length > 0 && (
        <Box mt="42px">
          {/* 오늘의 한 병 판매 시간 종료 모달 */}
          <EndSaleModal open={endSaleModal} vendor_id={modalActionVendorId} />

          {/* 오늘의 한 병을 이미 주문한 경우의 모달 */}
          <EndOrderModal open={orderCompleteModal} vendor_id={modalActionVendorId} />

          <Container aria-label="오늘의 한 병" component="article" position="relative">
            <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" lineHeight="normal" noWrap>
              오늘의 한 병
            </Typography>
            <Typography variant="subtitle1" mt={1} fontWeight={400} fontSize={16} letterSpacing="0.4px" lineHeight="normal" noWrap>
              사장님이 추천하는 특별 상품을 단 하루만 !
            </Typography>
          </Container>

          <Swiper
            ref={cardSwiperRef}
            onSwiper={setCardSwiper}
            className={`HomeTodayOneBottle-Swiper ${classes.contentSwiper}`}
            slidesPerView="auto"
            centeredSlides={true}
            centeredSlidesBounds={rows.list.length > 1}
            spaceBetween={12}
            onSlideChange={(e) => {
              const { activeIndex } = e;
              SWIPER_INDEX = activeIndex;
            }}
          >
            {rows.list.map((tob, index) => {
              const {
                _id,
                biz__address1,
                vendor_name,
                vendor_img,
                display_state,
                quantity,
                bottle_img,
                introduction,
                sale_dt,
                sale_state,
                price__original,
                show: _show,
                test: _test
              } = tob;

              // 표시 여부
              const show = _show === 'true' && display_state === TOB.DISPLAY.ING;

              // 판매중
              const open = sale_state === TOB.SALE.ING;
              // 대기중
              const wait = sale_state === TOB.SALE.WAIT;
              // 품절 여부
              const soldOut = sale_state === TOB.SALE.ING && quantity === 0;
              // 판매 종료
              const closed = sale_state === TOB.SALE.END;

              // 표시 여부가 false인 경우 null return
              if (!show) return null;

              // 테스트 입점샵 여부
              const test = _test === 'true' && _test;

              // 판매 날짜 & 시간
              const { saleDateFormat, formattedTime } = formatSaleDateAndTime(sale_dt);

              // pdata 이미지
              const pdataSrc = bottle_img?.thumb || wineDefaultImg;
              // 입점샵 이미지
              const vendorSrc = vendor_img[0]?.thumb || vendorDefaultImg;

              return (
                <SwiperSlide
                  key={`today-one-bottle-${index}-card`}
                  className={open ? classes.showSlide : classes.hideSlide}
                  onClick={() => goDetailPage(_id)}
                >
                  {/* 테스트 입점샵 여부 */}
                  {test ? (
                    <Box
                      sx={{
                        zIndex: 3,
                        position: 'absolute',
                        width: '100%',
                        height: '100%'
                      }}
                    >
                      <Box textAlign="right" pt="12px" pr="12px">
                        <Chip label="테스트 포함" variant="outlined" color="error" size="small" sx={{ borderRadius: '4px' }} />
                      </Box>
                    </Box>
                  ) : null}
                  {/* dim 영역 */}
                  {(wait || soldOut || closed) && (
                    <Box className={classes.dimSlide}>
                      <Box mt="101px">
                        <TodayOneBottleDate>{saleDateFormat}</TodayOneBottleDate>

                        <Box mt={wait || soldOut ? '10px' : '20px'}>
                          {/* 상태에 따른 표시 */}
                          <TodayOneBottleTime>
                            {wait && formattedTime}
                            {soldOut && 'SOLD'}
                            {closed && 'CLOSED'}
                          </TodayOneBottleTime>

                          {/* 판매 대기 / 품절인 경우 */}
                          {wait && <TodayOneBottleTime>OPEN</TodayOneBottleTime>}
                          {soldOut && <TodayOneBottleTime>OUT</TodayOneBottleTime>}
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {/* 오늘의 한 병 상품 정보 */}
                  <Box
                    sx={{
                      position: 'relative',
                      height: CARD_CONTENT_HEIGHT,
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px'
                    }}
                  >
                    {/* 오픈 예정 / 품절 / 판매 종료 칩 */}
                    {(wait || soldOut || closed) && (
                      <Chip
                        size="small"
                        label={wait ? '오픈 예정' : '판매 종료'}
                        sx={{
                          position: 'absolute',
                          background: 'rgba(0, 0, 0, 0.4)',
                          color: '#FFF',
                          fontSize: '14px',
                          lineHeight: 'normal',
                          left: '20px',
                          top: '20px',
                          zIndex: 2
                        }}
                      />
                    )}

                    <Box sx={{ width: '100%', height: CARD_PRODUCT_HEIGHT, position: 'absolute', top: '32px' }}>
                      <Box position="relative" width="100%" height="100%">
                        <ProductImage
                          width="100%"
                          bgcolor="#FFF"
                          height={CARD_PRODUCT_HEIGHT}
                          src={pdataSrc}
                          alt={`today-one-bottle-${index}-pdata-image`}
                          visibleByDefault={false}
                        />
                      </Box>
                    </Box>
                    <Box width="calc(100% - 52px)" mx="26px" position="absolute" bottom="20px" textAlign="center">
                      {/* 오늘의 한 병 한 줄 설명 */}
                      <Box>
                        <Typography paragraph className={open || soldOut ? classes.highlightBgText : classes.whiteBgText}>
                          {introduction}
                        </Typography>
                      </Box>

                      {/* 오늘의 한 병 가격 */}
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing="2px"
                        mt="16px"
                        position="relative"
                        zIndex={1}
                      >
                        <Typography fontSize="22px" color={open || soldOut ? '#140229' : '#FFF'} fontFamily="D-DIN" lineHeight={1}>
                          {price__original.toLocaleString()}
                        </Typography>
                        <Typography fontSize="16px" color={open || soldOut ? '#140229' : '#FFF'} fontWeight={200} lineHeight={1}>
                          원
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box px="20px">
                    <Box
                      py="20px"
                      sx={{
                        border: open && !soldOut ? '1px solid transparent' : 'none',
                        borderImage: open && !soldOut ? `url(${dashLineImg}) 1 stretch` : 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: 'none'
                      }}
                    >
                      <VendorInfo vendorSrc={vendorSrc} vendorAddress={biz__address1} vendorName={vendor_name} />
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      )}
    </Box>
  );
};

export default TodayOneBottle;

// 오늘의 한 병 상품 날짜 정보
const TodayOneBottleDate = styled(Typography)({
  fontSize: '16px',
  textShadow: '0 3px 6px #00000029',
  textAlign: 'center',
  fontWeight: 500,
  color: '#FFF',
  letterSpacing: '-0.16px',
  lineHeight: 'normal'
});

// 오늘의 한 병 상품 시간 정보
const TodayOneBottleTime = styled(Typography)({
  fontSize: '32px',
  textShadow: '0 3px 3px #00000029',
  textAlign: 'center',
  fontWeight: 900,
  color: '#FFF',
  letterSpacing: '-0.32px',
  lineHeight: 'normal'
});

const useStyles = makeStyles(() => ({
  contentSwiper: {
    padding: '20px'
  },
  // 오늘의 한 병 오픈 카드 슬라이드
  showSlide: {
    width: CARD_COVER_WIDTH,
    maxWidth: CARD_COVER_WIDTH,
    height: CARD_COVER_HEIGHT,
    maxHeight: CARD_COVER_HEIGHT,
    border: '1px solid #EEECF1',
    borderRadius: '20px',
    backgroundColor: '#FFF',
    position: 'relative'
  },
  // 오늘의 한 병 오픈 예정 카드 슬라이드
  hideSlide: {
    width: CARD_COVER_WIDTH,
    maxWidth: CARD_COVER_WIDTH,
    height: CARD_COVER_HEIGHT,
    maxHeight: CARD_COVER_HEIGHT,
    border: '1px solid #EEECF1',
    borderRadius: '20px'
  },
  // 오늘의 한 병 품절 카드 슬라이드
  dimSlide: {
    position: 'absolute',
    width: CARD_COVER_WIDTH,
    maxWidth: CARD_COVER_WIDTH,
    height: CARD_CONTENT_HEIGHT,
    maxHeight: CARD_CONTENT_HEIGHT,
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1
  },
  // 상품 설명 (흰 배경)
  whiteBgText: {
    fontSize: '18px',
    fontWeight: 900,
    background: '#fff',
    boxDecorationBreak: 'clone',
    color: '#000',
    display: 'inline',
    lineHeight: 1.3,
    marginBottom: '10px',
    padding: '0 4px',
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative',
    zIndex: 1
  },
  // 상품 설명 (형광 배경)
  highlightBgText: {
    fontSize: '18px',
    fontWeight: 900,
    letterSpacing: '-0.18px',
    background: 'linear-gradient(to top, #F3E1F2 60%, transparent 50%)',
    boxDecorationBreak: 'clone',
    color: '#140229',
    display: 'inline',
    lineHeight: 1.3,
    marginBottom: '10px',
    padding: '0 2px'
  }
}));
