import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Numbers } from 'utils/bourgogne-tools';
import { StarIcon } from '../../../icons';

/**
 * 리뷰점수 약식표현
 * @param point 평점
 * @param reviewCount 리뷰 개수
 * @constructor
 */
function ReviewPointSimple({ point = 0, count = 0, color = '#fec466', fontWeight = 100, ...other }) {
  return (
    <Box display="flex" alignItems="center" {...other}>
      <StarIcon color={color} />

      {/* 구매자 평점 */}
      <Typography variant="caption" fontWeight fontSize={11} sx={{ ml: '2px' }}>
        {point}
      </Typography>

      {/* 상품리뷰 개수 */}
      <Typography variant="caption" fontWeight fontSize={11}>
        &nbsp;(
        {count > 9999 ? Numbers.nFormatter(count, 0) : count.toLocaleString()})
      </Typography>
    </Box>
  );
}

ReviewPointSimple.propTypes = {
  point: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.number,
  color: PropTypes.string,
  fontWeight: PropTypes.number
};

export default ReviewPointSimple;
