import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Parallax } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator/es';
import { httpsCallable } from 'firebase/functions';
import { collection, documentId, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { CLO_CODE, IS_PRODUCTION } from 'config';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';

// material-ui
import { Box, Button, ButtonBase, CircularProgress, Fade, Typography } from '@mui/material';

// amplitude
import { amplitude } from 'index';

import { useLocalStorage, useScriptRef } from '../../../../../hooks';
import { getCurrentMainBannerList } from '../../../../../services/ContentService';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import './assets/style.scss';

// 배경 이미지 너비
const PARALLAX_IMG_WIDTH = '160%';

/**
 * 메인 배너
 *
 * @param bannerList
 * @param wrapperRef
 *
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>
 *          George<george@wineone.io>
 *          최효근<hkchoi@winone.io>
 */
function Banner({ wrapperRef = null }) {
  const location = useLocation();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const swiperRef = React.useRef(null);

  // 배너 배경이미지 정보
  const [backgroundImg, setBackgroundImg] = useLocationState('mainBg', { loaded: false, img: null });
  const [mainBgUrl, setMainBgUrl] = useLocalStorage('main-banner-bg', null);

  React.useLayoutEffect(() => {
    if (backgroundImg.loaded && backgroundImg.img) {
      setMainBgUrl(backgroundImg.img);
    }
  }, [backgroundImg.loaded]);

  // 배너 정보
  const [mainBanner, setMainBanner] = useLocationState('mainBanner', {
    loaded: false,
    items: [],
    error: null
  });

  // 배너 배경이미지 불러오기
  const fetchBannerBgData = async () => {
    const q = query(collection(getFirestore(), 'banner_bg'), where('use', '==', true), orderBy(documentId(), 'desc'), limit(1));
    const bannerBgSnapshot = await getDocs(q).catch((error) => {
      console.error('배너 배경이미지 조회 중 오류가 발생했습니다.', error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[Banner][fetchBannerBgData] Unmounted component.');
      return;
    }

    const { bg_img } = bannerBgSnapshot.docs[0].data();
    setBackgroundImg({ loaded: true, img: bg_img });
  };

  // 배너 목록 불러오기
  const fetchBannerData: Promise<void> = React.useCallback(async () => {
    setMainBanner({ loaded: false, items: [], error: null });
    const currentBannerList = await getCurrentMainBannerList().catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[Banner][fetchBannerData] Unmounted component.');
      return;
    }

    // 배너목록 조회실패
    if (currentBannerList.error) {
      const { error } = currentBannerList;
      setMainBanner({ loaded: true, items: [], error });
      return;
    }

    setMainBanner({ loaded: true, items: currentBannerList, error: null });
  }, []);

  React.useEffect(() => {
    if (mainBanner.loaded) {
      swiperRef.current.update();

      if (mainBanner.items.length > 1) {
        swiperRef.current.loopCreate();
      } else {
        swiperRef.current.loopDestroy();
      }

      swiperRef.current.slideTo(1, 0);
      if (mainBanner.items.length) {
        swiperRef.current.autoplay.start();
      }
    }
  }, [mainBanner.loaded]);

  const handleBannerClick = React.useCallback(
    (banner) => {
      console.log(banner);

      const { action_type } = banner;

      if (action_type === 'EVENT') {
        const { event = {} } = banner.banner_dat ?? {};
        console.log('#event: ', event);

        if (event?._id) {
          const { _id: event_id } = event;
          // 이벤트 상세페이지로 연결
          console.debug(`[1KMWINE] 이벤트 상세(${event_id})페이지로 이동합니다.`);
          // Amplitude Open Banner
          amplitude.getInstance().logEvent('Open Banner', {
            'banner type': 'event',
            'event id': event_id,
            'event name': event.name ?? 'unknown'
          });
          navigate(`/my/event/${event_id}`, { state: { event } });
        } else {
          console.warn('[1KMWINE] 잘못된 이벤트 데이터', event);
          if (IS_PRODUCTION) {
            const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
            sendCloError({
              code: CLO_CODE.UNEXPECTED_ERROR,
              title: `잘못된 이벤트 데이터. (이벤트 아이디가 없습니다. 이벤트명=${event.name})`,
              msg: `${JSON.stringify(event)}`,
              which: `${location.pathname}${location.search}`
            })
              .then(console.log)
              .catch(console.error);
          }
        }
      }
      // LINK 타입 배너
      else if (action_type === 'LINK') {
        const { link_url } = banner;
        if (typeof link_url === 'string' && validator.isURL(link_url)) {
          window.open(link_url, '_blank');
        } else {
          console.warn(`유효한 link_url이 아닙니다. banner.id='${banner.id}', link_url=${link_url}`);
        }
      }
      // INAPP 타입 배너
      else if (action_type === 'INAPP') {
        const { link_url } = banner;
        if (typeof link_url === 'string' && link_url.length > 0) {
          navigate(link_url);
        } else {
          console.warn(`유효한 link_url이 아닙니다. banner.id='${banner.id}', link_url=${link_url}`);
        }
      } else if (action_type === 'NOT') {
        /* 해당 이벤트는 클릭해도 아무런 이벤트가 없습니다. */
      } else {
        console.warn('[1KMWINE] 정의되지 않은 배너 타입: ', action_type);
      }
    },
    [mainBanner?.items]
  );

  React.useEffect(() => {
    if (!backgroundImg.loaded) {
      fetchBannerBgData();
    }
  }, []);

  React.useEffect(() => {
    if (!mainBanner.loaded) {
      fetchBannerData();
    }
  }, [mainBanner.loaded, mainBanner.error]);

  // https://swiperjs.com/demos#parallax
  // render
  return (
    <Box className="main-banner-wrapper" ref={wrapperRef}>
      {/* 배너조회 오류 발생시 */}
      {mainBanner.loaded && mainBanner.error ? (
        <Box position="absolute" zIndex={5} left={0} top={0} bottom={0} right={0}>
          <Box position="absolute" width="100%" height="100%" bgcolor="rgba(255,255,255,0.5)" sx={{ backdropFilter: 'blur(15px)' }} />
          <Box px={0} height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <Box color="#000000" textAlign="center" zIndex={1}>
              <Typography>배너를 가져오지 못 했어요</Typography>
              <Button color="white" variant="contained" startIcon={<ReplayOutlinedIcon />} sx={{ mt: 2 }} onClick={fetchBannerData}>
                재시도
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}

      {/* 로딩 표현 */}
      {mainBanner.items.length === 0 && !mainBanner.loaded ? (
        <Box position="absolute" zIndex={5} left={0} top={0} bottom={0} right={0}>
          <Box position="absolute" width="100%" height="100%" bgcolor="rgba(255,255,255,0.05)" />
          <Box px={0} height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <Fade in style={{ transitionDelay: `300ms` }}>
              <CircularProgress color="lime" />
            </Fade>
          </Box>
        </Box>
      ) : null}

      <Swiper
        loop
        parallax
        autoplay={{ delay: 4000 }}
        speed={600}
        pagination={{
          clickable: false,
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            return `<div style="display: flex; justify-content: flex-end; margin-right: 20px">
                      <div   style="width: 48px; height: 24px; background-color: #140229; opacity: .85; border-radius: 15px"><span style="color: #ffffff; font-size: 12px; font-weight: lighter">${current} / ${total}</span></div>
                    </div>`;
          }
        }}
        modules={[Autoplay, Parallax, Pagination]}
        className="HomeBanner-Swiper"
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {mainBanner.items.map((banner, i) => (
          <SwiperSlide key={`home-banner-${i}`}>
            <Box width={1} height={1} color="#ffffff" position="relative">
              <Box className="wo-banner-item-wrapper">
                <ButtonBase
                  data-swiper-parallax="0"
                  sx={{
                    backgroundImage: `url("${banner.banner_img.origin}")`
                  }}
                  onClick={(e) => {
                    handleBannerClick(banner, i);
                    e.target.blur();
                  }}
                  value={banner.action_type === 'LINK' ? banner.link_url : ''}
                  disabled={banner.action_type === 'NOT'}
                  tabIndex={-1}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
        <Box
          component="span"
          slot="container-start"
          className="parallax-bg"
          position="absolute"
          sx={{ top: 0, left: `-${(PARALLAX_IMG_WIDTH - 100) / 2}%`, height: '100%' }}
          width={PARALLAX_IMG_WIDTH}
          data-swiper-parallax={`-${100 / (mainBanner.items.length || 1)}%`}
        >
          <Box position="relative" width={PARALLAX_IMG_WIDTH} height="100%" tabIndex={-1}>
            <LazyLoadImage
              wrapperClassName="banner-bg-img"
              src={mainBgUrl ? mainBgUrl.origin : ''}
              width="100%"
              height="100%"
              visibleByDefault
            />
          </Box>
          <Box className="gradient-box" />
        </Box>
      </Swiper>
    </Box>
  );
}

Banner.propTypes = {
  wrapperRef: PropTypes.any
};

export default Banner;
