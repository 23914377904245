/**
 * String utilities.
 */
import { getTypeByValue, ORDER_ITEM, PACKING_OPTIONS, PDATA_CATEGORY } from '../../config';
import validator from 'validator/es';

class Strings {
  constructor() {
    // eslint-disable-next-line no-throw-literal
    throw '인스턴스화 할 수 없습니다.';
  }

  /**
   * 주문상품 상태코드를 문자열로 변경
   * @param {string} state 주문상태코드, Order item state
   * @returns {*}
   */
  static orderItemStateToString(state) {
    let stateStr = '';

    switch (state) {
      case ORDER_ITEM.STATE.REQUEST:
        stateStr = '주문 확인 중';
        break;

      case ORDER_ITEM.STATE.REJECT:
        stateStr = '주문취소(판매사)';
        break;
      case ORDER_ITEM.STATE.ORDER_CANCEL:
        stateStr = '주문취소';
        break;

      case ORDER_ITEM.STATE.APPROVED: // 주문이 승인된 상태
        stateStr = '픽업대기';
        break;
      case ORDER_ITEM.STATE.PICK_UP_DONE:
        stateStr = '픽업완료';
        break;
      case ORDER_ITEM.STATE.PURCHASE_CONFIRM:
        stateStr = '구매확정';
        break;

      case ORDER_ITEM.STATE.EXCHANGE_RETURN:
        stateStr = '교환/반품';
        break;

      case ORDER_ITEM.STATE.REQUEST_EXCHANGE:
        stateStr = '교환신청';
        break;
      case ORDER_ITEM.STATE.EXCHANGE_REJECTED:
        stateStr = '교환거절';
        break;
      case ORDER_ITEM.STATE.EXCHANGE_WAIT:
        stateStr = '교환대기';
        break;
      case ORDER_ITEM.STATE.REQUEST_RETURN:
        stateStr = '반품요청';
        break;
      case ORDER_ITEM.STATE.RETURN_REJECTED:
        stateStr = '반품거절';
        break;
      case ORDER_ITEM.STATE.RETURN_COMPLETE:
        stateStr = '반품완료';
        break;
      default:
        // todo
        console.warn('[Strings] 알 수 없는 주문 상품 상태코드: ' + state);
        stateStr = '확인 중';
    }
    return stateStr;
  }

  /**
   * @deprecated
   * @param packingOption
   * @returns {null}
   */
  static packingOptionToStr(packingOption) {
    let poStr = null;
    PACKING_OPTIONS.forEach((opt) => {
      if (opt.value === packingOption) poStr = opt.informal;
    });
    return poStr;
  }

  static phoneNumberHyphen(phoneNumber = '', useMask = false) {
    if (!validator.isMobilePhone(phoneNumber, 'ko-KR')) return phoneNumber;
    let resultStr = phoneNumber;
    try {
      resultStr = phoneNumber.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
      if (useMask) {
        const [head, mid, tail] = resultStr.split('-');
        resultStr = `${head}-${mid[0]}${'*'.repeat(mid.length - 1)}-${tail[0]}***`;
        console.debug('휴대폰번호: ', resultStr);
        console.debug('-> 마스킹된 휴대폰번호: ', `${head}-${mid[0]}${'*'.repeat(mid.length - 1)}-${tail[0]}***`);
      }
    } catch (e) {
      console.warn('휴대폰번호 하이픈 삽입 실패. ', e);
    }
    return resultStr;
  }

  static pdataTagStrings(pdata) {
    if (!pdata) return [];
    try {
      // 티켓은 제외
      if (pdata.category === PDATA_CATEGORY.TICKET.value) return [];

      const tagStrs = [];
      if (pdata.country?.ko) tagStrs.push(pdata.country.ko); // 와인, 위스키, 일본주 공통

      // 와인상품 표현
      if (pdata?.category === PDATA_CATEGORY.WINE.value) {
        if (pdata.variety) {
          if (Array.isArray(pdata.variety)) {
            pdata.variety.forEach((vrt) => {
              if (vrt.ko) tagStrs.push(vrt.ko);
            });
          } else if (pdata.variety?.ko) tagStrs.push(pdata.variety.ko);
        }

        if (pdata.type) tagStrs.push(getTypeByValue(pdata.category, pdata.type));
      }

      return tagStrs;
    } catch (e) {
      console.error('pdata 태그문자열 추출오류.', e);
      return [];
    }
  }

  static generateStyleStr(styleItemBit) {
    // eslint-disable-next-line default-case
    switch (styleItemBit) {
      case 1:
        return '낮음';
      case 2:
        return '중간';
      case 3:
        return '낮음~중간';
      case 4:
        return '높음';
      case 5:
      case 7:
        return '낮음~높음 ';
      case 6:
        return '중간~높음';
    }
  }
}

export default Strings;
