import React from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { getWriterReviewPdataList } from 'services/ReviewThreadService';
import { ProducerTypo, ProductName } from 'components';
import BackButton from 'components/buttons/BackButton';
import { ProductImage } from 'components/v2';

// material-ui
import { Box, Button, ButtonBase, CircularProgress, Container, Fade, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { styled } from '@mui/material/styles';

// 페이지네이트 기본 정보
const initialPage = Object.freeze({
  current: 1,
  total_pages: 0,
  total_results: 0,
  size: 10 // 한번에 호출할 상품 개수
});

const initialRowState = Object.freeze({
  loaded: false,
  page: initialPage,
  items: [],
  empty: false
});

const IMG_HEIGHT = 185; // 상품 이미지 높이

/**
 * 최근 주문 상품 목록 drawer
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ReviewSelectPdataList = () => {
  const navigate = useNavigate();
  const { hideBottomNav, resetBottomNav } = useWineOne();

  // 최근 주문 상품 목록
  const [rows, setRows] = React.useState(initialRowState);
  // 최근 주문 상품 목록 조회 상태
  const [searchState, setSearchState] = React.useState({ searched: rows.loaded, searching: false, error: false });

  React.useLayoutEffect(() => {
    hideBottomNav();
    fetchReviewWritableOrderProducts(1);

    return () => {
      resetBottomNav();
    };
  }, []);

  // 최근 주문 상품 목록 조회
  const fetchReviewWritableOrderProducts = async (current) => {
    // 이미 요청 중
    if (current === 1 && searchState.searching) {
      console.warn('[ReviewSelectPdataList][fetchReviewWritableOrderProducts] 이미 최근 주문 상품 목록을 조회 중 입니다.');
      return false;
    }

    // 검색 상태로 변경
    setSearchState({ ...searchState, searching: true, error: false });

    const body = {
      page: {
        current,
        size: rows.page.size
      }
    };

    const result = await getWriterReviewPdataList(body).catch((error) => ({ error }));

    // 오류 발생
    if (result.error) {
      console.error(
        '[ReviewSelectPdataList][fetchReviewWritableOrderProducts] 최근 주문 상품 목록 조회 중 에러가 발생했습니다.',
        result.error
      );
      setSearchState({ ...searchState, searching: false, error: true });
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 반환됨.
    if (code !== 0) {
      setRows({ ...rows, error: true, loading: false });
      console.error('[ReviewSelectPdataList][fetchReviewWritableOrderProducts] 잘못된 Response값이 반환됨.', msg);
      return undefined;
    }

    // 1페이지 요청 시 리스트 초기화
    const pdataList = current === 1 ? [...result.data] : [...rows.items, ...result.data];
    // 목록이 비어있을 경우
    const empty = current === 1 && result.data.length === 0;

    // 목록이 비어있을 경우 뒤로가기
    if (empty) {
      console.warn('[ReviewSelectPdataList][fetchReviewWritableOrderProducts] 최근 주문 상품 목록이 비어있습니다.');
      WoAlert.fire('', '최근 주문 상품이 없습니다.', 'warning').then(() => navigate(-1));
      return undefined;
    }

    setRows({
      loaded: true,
      page: result.page,
      items: pdataList,
      empty
    });

    setSearchState({ searched: true, searching: false, error: false });
  };

  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
      if (y < window.scrollY) {
        // 스크롤이 콘텐츠 끝에 거의(-185) 닿았을 때
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 185) {
          if (!searchState.searched) return; // 최초 조회시 중복요청 제외
          if (searchState.searching) return; // 페이지 요청 중일 경우 pass
          // 다음 페이지 호출
          if (rows.page.current < rows.page.total_pages) {
            console.log(`[ReviewSelectPdataList][handleScrollEvent] 다음 최근 주문 상품 페이지 호출 (${rows.page.current + 1})`, rows.page);
            fetchReviewWritableOrderProducts(rows.page.current + 1);
          }
          // 이미 검색결과를 모두 보여준 상태
          else {
            console.log('[1KMWINE] 모든 최근 주문 상품 목록을 출력했습니다.');
          }
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  // render
  return (
    <Box>
      <Box position="fixed" top={0} left={0} right={0} zIndex={1}>
        <PageHeader>
          <BackButton color="inherit" onClick={() => navigate(-1)} />
          <PageTitle>최근 주문 상품</PageTitle>
        </PageHeader>
      </Box>

      <Container sx={{ pt: '56px', width: '100vw', px: '20px' }}>
        <Stack direction="row" alignItems="center" spacing="6px" py="20px">
          <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal' }}>최근 주문 상품</Typography>
          {searchState.searching ? (
            <CircularProgress size={14} sx={{ color: '#9357E5' }} />
          ) : (
            <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal', color: '#9357E5' }}>
              {rows.page.total_results}
            </Typography>
          )}
        </Stack>

        {/* 최근 주문 상품 목록 조회 중 오류 발생 */}
        {searchState.searched && searchState.error && (
          <Box position="fixed" zIndex={5} left={0} top={112} width="100%" height={357}>
            <Box position="absolute" width="100%" height="100%" bgcolor="rgba(255,255,255,0.5)" sx={{ backdropFilter: 'blur(15px)' }} />
            <Box px={0} height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
              <Box color="#000000" textAlign="center" zIndex={1}>
                <Typography>최근 주문 상품을 가져오지 못 했어요</Typography>
                <Button
                  color="white"
                  variant="contained"
                  startIcon={<ReplayOutlinedIcon />}
                  sx={{ border: '1px solid #D9D3DF', borderRadius: '4px', px: '44px', mt: '12px', boxShadow: 'none' }}
                  onClick={() => fetchReviewWritableOrderProducts(1)}
                >
                  재시도
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {/* 최근 주문 상품이 존재함 */}
        {!rows.empty && (
          <Grid container columnSpacing={2} rowSpacing={2} pb="40px">
            {rows.items.map((row, index) => {
              const { oid, order_product_id: orderProductId, vintage } = row;
              return (
                <Grid item key={`product-list-item-${index}`} xs={6} sm={4} md={3}>
                  <Box component="section" width="100%" display="grid">
                    {/* 상품 이미지 */}
                    <ProductImage src={row?.bottle_img?.thumb} width="100%" height={IMG_HEIGHT} />

                    {/* 상품 생산자 */}
                    <Box height={30} mt={1}>
                      <ProducerTypo>
                        {row?.producer?.ko.trim() ? row?.producer.ko : <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>}
                      </ProducerTypo>
                    </Box>

                    {/* 상품 명 */}
                    <ProductName>{row?.name_ko}</ProductName>

                    {/* 리뷰쓰기 버튼 */}
                    <ButtonBase
                      sx={{ width: '100%', mt: '12px', mb: '4px', borderRadius: '4px' }}
                      onClick={() =>
                        navigate(`/my/review/${row?.pdata_id}/write`, {
                          state: { oid, orderProductId, vintage }
                        })
                      }
                    >
                      <Stack
                        direction="row"
                        sx={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: '5px',
                          border: '1px solid #D9D3DF',
                          borderRadius: '4px'
                        }}
                        spacing="2px"
                      >
                        <WriterIcon />
                        <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal' }}>리뷰 쓰기</Typography>
                      </Stack>
                    </ButtonBase>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* 최근 주문 상품 목록 조회 중 */}
        {searchState.searching && (
          <Fade in>
            <Box textAlign="center" mt={2}>
              <CircularProgress size={30} color="brand" />
            </Box>
          </Fade>
        )}
      </Container>
    </Box>
  );
};

export default ReviewSelectPdataList;

// Page header.
const PageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, '20px'),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

// Page title.
const PageTitle = styled((props) => (
  <Typography
    variant="div"
    noWrap
    lineHeight={1}
    fontSize="18px"
    fontWeight={800}
    letterSpacing="-0.18px"
    color="text.primary"
    {...props}
  />
))({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

const WriterIcon = React.memo(() => (
  <SvgIcon sx={{ width: 22, height: 22, fill: 'none' }} viewBox="0 0 22 22">
    <path
      d="M6.28558 15.2065C6.28558 15.2065 6.33767 15.2065 6.35721 15.2065L8.26512 15.0307C8.53209 15.0047 8.77953 14.8874 8.96837 14.6986L16.1702 7.49674C16.5088 7.15814 16.6977 6.70884 16.6977 6.23349C16.6977 5.75814 16.5088 5.30884 16.1702 4.97023L15.7079 4.50791C15.0307 3.8307 13.8521 3.8307 13.1749 4.50791L12.2567 5.42605L5.97953 11.7033C5.7907 11.8921 5.67349 12.1395 5.65395 12.4065L5.47814 14.3144C5.4586 14.5553 5.54326 14.7898 5.71256 14.9656C5.86884 15.1219 6.0707 15.2065 6.28558 15.2065ZM14.4447 4.95721C14.653 4.95721 14.8614 5.03535 15.0177 5.19814L15.48 5.66047C15.6363 5.81674 15.7209 6.0186 15.7209 6.23349C15.7209 6.44837 15.6363 6.65674 15.48 6.80651L14.907 7.37954L13.2986 5.77116L13.8716 5.19814C14.0279 5.04186 14.2363 4.95721 14.4447 4.95721ZM6.62419 12.4977C6.62419 12.4586 6.64372 12.426 6.66977 12.4L12.6019 6.4614L14.2102 8.06977L8.27814 14.0019C8.27814 14.0019 8.21302 14.0474 8.18047 14.0474L6.46791 14.2037L6.62419 12.4912V12.4977ZM18 17.2837C18 17.5507 17.7786 17.7721 17.5116 17.7721H4.48837C4.2214 17.7721 4 17.5507 4 17.2837C4 17.0167 4.2214 16.7953 4.48837 16.7953H17.5116C17.7786 16.7953 18 17.0167 18 17.2837Z"
      fill="#140229"
    />
  </SvgIcon>
));
