import axios from 'axios';
import { FIREBASE_FUNCITON_URL, NEST_API_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';

/**
 * 상품리뷰가 도움이 돼요
 * @param {string} pdataId 와인 ID
 * @param {string} reviewId 리뷰 ID
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function productReviewLike(pdataId, reviewId) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__PRODUCT_REVIEW_LIKE, { pdata_id: pdataId, review_id: reviewId });
}

/**
 * 상품리뷰 신고
 * @param {string} pdataId 와인 ID
 * @param {string} reviewId 리뷰 ID
 * @param {string} msg 신고 사유
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function productReviewReport(pdataId, reviewId, msg) {
  console.debug('#리뷰 신고: ', pdataId, reviewId, msg);
  return axios.post(FIREBASE_FUNCITON_URL.POST__PRODUCT_REVIEW_REPORT, { pdata_id: pdataId, review_id: reviewId, msg });
}

/**
 * 상품 검색
 * @param query 검색어
 * @param options 엘라스틱 옵션
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function searchProductWithElastic(query, options) {
  return axios.post(NEST_API_URL.POST__PRODUCT_SEARCH, { query, ...options }).then((res) => res.data);
}

/**
 * 오늘의 한 병 목록
 */
export async function getTodayOneBottleList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__TODAY_ONE_BOTTLE_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `오늘의 한 병 목록 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getTodayOneBottleList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 오늘의 한 병 유효성 검사 (검사하고자 하는 오늘의 한 병 -> 날짜, 관심샵 여부 등)
 */
export async function getTodayOneBottleCheck(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__TODAY_ONE_BOTTLE_CHECK, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `오늘의 한 병 유효성 검사 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getTodayOneBottleCheck`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 오늘의 한 병 조회
 */
export async function getTodayOneBottleDetail(tobId): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__TODAY_ONE_BOTTLE_DETAIL, { params: { id: tobId } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `오늘의 한 병 상세 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getTodayOneBottleDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 공동구매 목록
 */
export async function getCoBuyingList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__CO_BUYING_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `공동구매 목록 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCoBuyingList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 공동구매 상세조회
 */
export async function getCoBuyingDetail(coBuyingId): Promise<any> {
  return axios
    .get(NEST_API_URL.GET__CO_BUYING_DETAIL, { params: { id: coBuyingId } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `공동구매 상세조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: error.message ?? 'Unexpected',
          which: `getCoBuyingDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 지도에 위치한 공동구매 입점샵 리스트 조회
 */

export async function getCoBuyingVendorList(body): Promise<any> {
  return axios
    .post(NEST_API_URL.POST__CO_BUYING_VENDOR_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
          title: `공동구매 입점샵 목록 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCoBuyingVendorList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
