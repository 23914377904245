import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet-async';
import { useLocationState } from 'react-router-use-location-state';
import moment from 'moment/moment';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

// import project
import { useAuth, useScriptRef } from '../../../../hooks';
import { OrderLayout } from '../components';
import { getFirebaseFunctions } from '../../../../utils/firebase-tools';
import { PromotionRefundInfo, VendorInfoCard } from './components';
import { OrderSection, TitleBox } from '../OrderConfirm/components';
import OrderPromotionProduct from './components/OrderPromotionProduct';
import { Checkbox, ErrorIcon, FlexBox, SmallButton, SpaceBetweenBox, WoDialogTitle, WoDivider } from '../../../../components';
import { getPromotion } from '../../../../services/PromotionService/promotions';
import { getLatestTerm, TERM_TYPE } from '../../../../services/AppService';
import { WoAlert } from '../../../../utils/kmwine-alerts';
import { amplitude } from '../../../../index';
import * as OrderService from '../../../../services/OrderService';
import { getOneVendor, getPickupAvailableDates } from '../../../../services/VendorService';
import { CLO_CODE, ORDER } from '../../../../config';
import CouponCheckbox from './components/CheckBox';

// import mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  Slide,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// assets
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import TasteDivision from '../../../mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';
import waveBorderImg from '../OrderConfirm/components/wave_border.png';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import shortDashed from '../../../promotion/Promotion/assets/img_coupon dash 147.png';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import CouponCheckboxUnAvailable from './components/CouponCheckBoxUnavailable';

// 약관/정책 초기값
const initialTermState = Object.freeze({
  loaded: false,
  error: false,
  type: null,
  term: null
});

/**
 * 프로모션 주문페이지
 *
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function OrderPromotionConfirm() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const classes = useStyles();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 프로모션 정보
  const [promotionData, setPromotionData] = React.useState(null);

  // 최대할인 금액
  const [maxSaleAmount, setMaxSaleAmount] = React.useState(null);

  // clo error
  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // URL query-strings
  const [searchParams] = useSearchParams();
  // 어디서 왔는가??
  const from = searchParams.get('from') || 'normal';

  const promotionId = searchParams.get('pid');
  const vendorId = searchParams.get('vid');

  // 입점샵 픽업가능날짜
  const [pickupAvailableDates, setPickupAvailableDates] = useLocationState('pickAvail', { loaded: false, error: null, data: {} });
  // 픽업시간 옵션 array
  const [pickupTimeArray, setPickupTimeArray] = React.useState([]);
  // 픽업날짜
  const [pickupYmd, setPickupYmd] = useLocationState('pickupYmd', null);
  // 픽업시간
  const [pickupTime, setPickupTime] = useLocationState('pickupTime', null);

  // 주문상품 옵션 (기본값)
  const initialOrdItemOpts = React.useMemo(() => {
    const _map = {};
    location.state?.orderItems.forEach((oi) => {
      _map[oi.product._id] = { id: oi.product._id, quantity: oi.quantity, packing: '', comment: '' };
    });

    return _map;
  }, [location]);

  // 주문상품 옵션
  const [ordItemOpts, setOrdItemOpts] = useLocationState('ordItemOpts', initialOrdItemOpts);
  // 총 주문 병수
  const totalQuantity = React.useMemo(() => Object.values(ordItemOpts).reduce((total, { quantity }) => total + quantity, 0), [ordItemOpts]);

  // AppBar ref
  let appBarRef = React.useRef(null);
  // AppBar 높이
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  // AppBar 높이 구하기
  React.useEffect(() => {
    // AppBar 높이 구하기
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, [appBarRef]);

  // 주문확인 및 이용약관 동의여부
  const [agree, setAgree] = React.useState(false);

  // 샵 정보
  const [vendor, setVendor] = React.useState({ loaded: false, data: null });

  // 샵 정보 가지고오기
  const fetchVendorDetail: Promise<void> = React.useCallback(async () => {
    const vendorData = await getOneVendor(vendorId).catch((error) => ({ error }));

    if (vendorData.error) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `샵정보 조회 중 오류`,
        msg: `[uid=${user._id}]`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchVendorDetail][fetchVendorDetail] ', vendorData.error);
      return undefined;
    }

    const { data, result } = vendorData.data;

    if (result.code !== 0) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `샵정보 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.msg)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      console.error('[fetchVendorDetail][fetchVendorDetail] ', result.msg);
      return undefined;
    }
    fetchPickupAvailableDates(data._id);
    setVendor({ loaded: true, data });
  }, []);

  const fetchPromotion = async () => {
    setLoading(true);
    const data = await getPromotion(promotionId).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `프로모션 조회 중 에러`,
        msg: ` ${JSON.stringify(error)}`,
        param: {
          error
        }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(`프로모션 조회 중 오류가 발생하였습니다`, '', 'error').then(() => {
        navigate(-1);
      });
    });

    if (data.code === 0) {
      setPromotionData(data.data);

      setLoading(false);
    } else {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `프로모션 조회 중 에러`,
        msg: ` ${JSON.stringify(data.message)}`
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(`프로모션 조회 중 오류가 발생하였습니다`, '', 'error').then(() => {
        navigate(-1);
      });
    }
  };

  // 첫 진입시
  React.useLayoutEffect(() => {
    fetchVendorDetail();
    fetchPromotion();
  }, []);

  // 픽업날짜 변경감시
  React.useEffect(() => {
    if (vendor.loaded && pickupAvailableDates.loaded && pickupYmd != null) {
      const { data } = pickupAvailableDates;
      setPickupTimeArray(data?.[pickupYmd] ?? []);
    } else {
      setPickupTimeArray([]);
    }
  }, [pickupAvailableDates.loaded, pickupYmd]);

  async function fetchPickupAvailableDates(vendorId): Promise<void> {
    setPickupAvailableDates({ loaded: false, error: null, data: {} });

    const now = moment();
    const refDate = now.format('YYYY-MM-DD HH:mm');
    const productIds = location.state.orderItems.map((item) => item.product._id);

    const result = await getPickupAvailableDates(vendorId, refDate, { product_ids: productIds });
    console.log('#result: ', result);

    if (!scriptedRef.current) {
      console.warn('[fetchPickupAvailableDates] Unmounted component.');
      return;
    }

    // 픽업 가능일시 조회 성공
    if (result.code === 0) {
      setPickupAvailableDates({ loaded: true, error: null, data: { ...result.data } });
    } else {
      console.error('[fetchPickupAvailableDates] Fetch pickup available dates failed.', result);
      setPickupAvailableDates({ loaded: true, error: true, data: {} });
    }
  }

  // 주문정보
  const [order, setOrder] = React.useState({
    request: false, // 요청 여부
    data: null // 주문 데이터
  });

  // 사용자 보유쿠폰 정보
  const [myCoupons, setMyCoupons] = useLocationState('myCoupons', {
    loaded: false,
    error: false,
    vendor_coupons: [], // 입점샵 정산 쿠폰
    platform_coupons: [], // 플랫폼 정산 쿠폰
    unavailables: [] // 사용 불가능한 쿠폰
  });

  // 쿠폰 상태정보(선택 등)
  const [selectedCoupons, setSelectedCoupons] = useLocationState('selectedCoupons', []);

  // 내가 보유한 쿠폰 다이어로그 열림
  const [cpnDialogOpen, setCpnDialogOpen] = React.useState(false);
  const [cpnDialogData, setCpnDialogData] = React.useState({ selected: selectedCoupons, showSelected: false });

  /** 쿠폰할인 Dialog 열기 */
  const openCouponDialog = React.useCallback(() => {
    setCpnDialogData((prev) => ({ ...prev, selected: selectedCoupons }));
    setCpnDialogOpen(true);
  }, [setCpnDialogOpen, setCpnDialogData, selectedCoupons]);

  /** 쿠폰할인 Dialog 닫기 */
  const closeCouponDialog = React.useCallback(() => {
    setCpnDialogOpen(false);
    setCpnDialogData((prev) => ({ ...prev, selected: selectedCoupons }));
  }, [setCpnDialogOpen, setCpnDialogData, selectedCoupons]);

  /** 쿠폰할인 Dialog 선택정보 적용하기 */
  const applyCpnDialogData = React.useCallback(() => {
    console.log('[쿠폰할인] 적용하기 버튼 클릭됨');
    setSelectedCoupons(cpnDialogData.selected);
    setCpnDialogOpen(false);
  }, [setCpnDialogOpen, setSelectedCoupons, cpnDialogData.selected]);

  // '쿠폰 할인' dialog에서 쿠폰 클릭 이벤트 콜백
  const onCpnDialogCouponClick = React.useCallback(
    (checked, couponInfo) => {
      const { coupon } = couponInfo;
      let _selected = [...cpnDialogData.selected];
      console.log(`# 쿠폰선택 다이어로그 > 쿠폰 ${checked ? '선택됨' : '선택 풀림'}: `, coupon);

      // 체크됨
      if (checked) {
        // 플랫폼에서 정산하는 쿠폰
        if (coupon.type.startsWith('AD')) {
          console.log('# 플랫폼 정산쿠폰이 선택됨: ', couponInfo);
          // _selected에서 type이 AD로 시작하는 쿠폰들 제거 (중복사용 불가)
          _selected = _selected.filter(({ coupon }) => !coupon.type.startsWith('AD'));
        }
        // 입점샵에서 정산하는 쿠폰
        else if (coupon.type.startsWith('VE')) {
          console.log('# 입점샵 정산쿠폰이 선택됨: ', couponInfo);
          // _selected에서 type이 VE로 시작하는 쿠폰들 제거 (중복사용 불가)
          _selected = _selected.filter(({ coupon }) => !coupon.type.startsWith('VE'));
        }

        _selected.push(couponInfo);
      }
      // 체크 풀림
      else {
        console.log('제외할 쿠폰의 coupon_download_id: ', couponInfo.id);
        // 체크 풀린 쿠폰은 제외
        _selected = _selected.filter(({ id }) => id !== couponInfo.id);
      }

      setCpnDialogData((prev) => ({ ...prev, selected: _selected }));
      // console.log('# cpnDialogData: ', cpnDialogData);
    },
    [setCpnDialogData, cpnDialogData.selected]
  );

  // 총 상품 금액
  const goodsTotalAmount = React.useMemo(() => {
    console.log('# 총 상품 금액 계산하기');
    return location.state.orderItems.reduce((total, orderItem) => {
      const { product } = orderItem;
      const productId = product.id ?? product._id;
      const opt = ordItemOpts[productId];
      return total + orderItem.product.price.original * opt.quantity;
    }, 0);
  }, [totalQuantity]);

  // 쿠폰 할인금액 계산
  const calcCouponDiscountAmount = React.useCallback(
    (coupon: { discount_type: 'price' | 'percent', amount?: number, percent?: number, max_dis_amount?: number }, totalAmount: number) => {
      const discountType = coupon.discount_type ?? 'price'; // 옛날 쿠폰은 discount_type 없는 정액권이었음
      if (discountType === 'price') return coupon.amount;

      let discountAmount = 0;
      if (discountType === 'percent') {
        discountAmount = totalAmount * (coupon.percent / 100);
        // 최대 할인가 확인
        if (discountAmount >= coupon.max_dis_amount) discountAmount = coupon.max_dis_amount;
      }
      return discountAmount;
    },
    []
  );

  // 쿠폰 할인금액
  const discountByCoupons = React.useMemo(() => {
    // 입점샵 정산 쿠폰 할인금액
    const vendorCpnDiscnt = selectedCoupons
      .filter(({ coupon }) => coupon.type.startsWith('VE'))
      .reduce((dcTotal, { coupon }) => {
        console.log('# 입점샵 쿠폰 총 할인금액 계산하기', coupon);
        return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount);
      }, 0);
    // 플랫폼 정산 쿠폰 할인금액
    const platformCpnDiscnt = selectedCoupons
      .filter(({ coupon }) => coupon.type.startsWith('AD'))
      .reduce((dcTotal, { coupon }) => {
        // return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount - vendorCpnDiscnt);
        // 쿠폰정책 변경됨
        return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount);
      }, 0);

    return vendorCpnDiscnt + platformCpnDiscnt;
  }, [selectedCoupons, goodsTotalAmount, calcCouponDiscountAmount]);

  // 쿠폰 다이어로그 할인금액
  const cpnDialogCouponDiscountAmount = React.useMemo((): number => {
    return cpnDialogData.selected.reduce((dcTotal, { coupon }) => {
      return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount);
    }, 0);
  }, [cpnDialogData.selected, goodsTotalAmount, calcCouponDiscountAmount]);

  // 결제 금액 (총 상품 금액 - 쿠폰 할인 )
  const amount = goodsTotalAmount - discountByCoupons;

  // 약관/정책
  const [termState, setTermState] = React.useState(initialTermState);

  // 약관 가져오기
  const fetchTerm = (type) => {
    if (termState.loaded && termState.type === type) {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
      return;
    }

    setTermState({ ...termState, type });

    if (!termState.loaded || termState.type !== type) {
      getLatestTerm(type.toUpperCase())
        .then((snapshot) => {
          if (scriptedRef.current) {
            if (!snapshot.empty) {
              setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
            } else {
              console.error(`[1KMWINE] 문서가 존재하지 않습니다. [type=${type}]`);
              setTermState({ ...termState, type, loaded: true, error: false, term: null });
            }
          }
        })
        .catch((e) => {
          console.error(`[1KMWINE] 문서 조회 중 오류가 발생했습니다. [type=${type}]`, e);
          if (scriptedRef.current) {
            setTermState({ ...termState, type, loaded: true, error: true, term: null });
          }
        });
    } else {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
    }
  };

  // 약관보기
  const [showTerms, setShowTerms] = useLocationState('termModalOpen', false);

  const handleShowTerms = (show = false, agreeTerms) => {
    if (typeof agreeTerms === 'boolean') setAgree(agreeTerms);
    if (show) {
      fetchTerm(TERM_TYPE.SERVICE);
      setShowTerms(show, { method: 'true' });
    } else {
      navigate(-1);
    }
  };

  // '결제하기' 버튼 클릭
  const startPurchase = async () => {
    // 쿠폰 금액이 상품 금액보다 많은 경우
    if (discountByCoupons > goodsTotalAmount) {
      if (selectedCoupons.length > 0) {
        console.warn('할인금액이 주문금액보다 높다.');
        WoAlert.fire(`잘못된 쿠폰입니다.  \n쿠폰을 다시 선택해주세요.`, '', 'error').then(() => {
          // setSelectedCoupons([]);
        });
        return undefined;
      }
    }

    setOrder({ ...order, request: true });

    // -------------------------------------------------------------------- 주문생성 [START]
    console.log('#selectedCoupons: ', selectedCoupons);
    // 선택된 쿠폰아이디 array
    const coupon = selectedCoupons.map(({ id }) => id);

    // 주문(order)생성 요청 파라미터
    const orderRequestParam = {
      vendor_id: vendorId, // 판매사(입점샵) ID
      products: [], // 주문상품
      coupon,
      pickup_date: pickupYmd ? `${moment(pickupYmd, 'YYYYMMDD').format('YYYY-MM-DD')}` : null,
      pickup_time: pickupTime ? `${moment(pickupTime, 'HHmm').format('HH:mm')}` : null,
      pay: {
        type: 'CARD', // 결제방식 'CARD'
        amount // 결제 총액
      },
      state: ORDER.STATE.ING, // 최초상태 : 결제 중
      paid: false,
      from
    };

    const logItems = [];

    // 주문상품 확인
    location.state.orderItems.forEach((orderItem, i) => {
      const { product: orderProduct } = orderItem;
      const { _id: id } = orderProduct;

      const { quantity, packing, comment } = ordItemOpts[id];
      // 주문상품정보
      orderRequestParam.products.push({
        id, // product.id
        quantity, // quantity
        packing, // 포장옵션
        comment // 추가 요청사항
      });

      // Google Analytics정보
      logItems.push({
        item_id: id,
        item_name: orderProduct.pdata.name.ko,
        currency: 'KRW',
        index: i,
        item_category: 'wine',
        item_category2: orderProduct.pdata.type,
        price: orderProduct.price.original,
        quantity
      });
    });

    console.debug('결제 요청정보: ', { ...orderRequestParam });
    // Amplitude Payment Start
    amplitude.getInstance().logEvent('Payment Start');

    const res = await OrderService.createOrder(orderRequestParam).catch((error) => ({ error }));

    // 프로모션 주문정보 생성 오류
    if (res.error) {
      const { error } = res;
      console.error('[1KMWINE] 주문정보 생성실패.', error);
      setOrder({ ...order, request: false });
      errorCallback();
      return undefined;
    }

    setOrder({ ...order, request: false });

    const { status, data } = res;

    if (status !== 200) return errorCallback();

    const { data: orderCreated, result } = data;
    console.debug('생성된 주문정보: ', orderCreated);
    if (result.code !== 0) {
      WoAlert.fire(`주문정보 생성 중 오류가 발생했습니다.\n[${result.msg}]\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        console.warn(`[wineone] 주문정보 생성 오류 [code: ${result.code}, msg: ${result.msg}]`);

        // 주문금액 오류일 경우 뒤로가기
        if (result.code === 1) navigate(-1);
      });
      return false;
    }

    navigate(`/payment/inicis/request`, { state: { order: { ...orderCreated } } }); // 이니시스 카드결제요청

    async function errorCallback() {
      await WoAlert.fire('주문정보 생성 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.', '', 'error').then(() => {
        console.warn('[1KMWINE] 주문정보 생성 오류 [OrderConfirm.createOrder$error]');
      });
      return false;
    }
  };

  // 뒤로가기

  const pageBackFunc = () => {
    navigate(-1);
  };

  // 사용자 보유쿠폰 목록 조회
  const fetchMyCoupons: Promise<boolean> = React.useCallback(async () => {
    console.log('[1KMWINE] 사용자 보유쿠폰 조회 시작...');
    setMyCoupons((prev) => ({ ...prev, loaded: false }));

    const items = location.state.orderItems.map((orderItem) => {
      const { product: orderProduct } = orderItem;
      const { _id: id } = orderProduct;
      const { quantity } = ordItemOpts[id];
      return { product: orderProduct, quantity };
    });

    const response = await OrderService.getAvailableCouponList(vendorId, items).catch((error) => ({ error }));
    if (!scriptedRef.current) {
      console.warn('[OrderPromotionConfirm][fetchMyCoupons] Unmounted component.');
      return;
    }

    // 사용자 보유쿠폰 목록조회 실패(서버에러)
    if (response.error) {
      await WoAlert.fire('', `보유쿠폰 목록 조회 중 오류가 발생했어요.<br/>잠시 후 다시 시도해주세요.`, 'error').then(() => {
        navigate(-1);
      });
      return false;
    }

    // 조회된 쿠폰 정보
    const { vendor_coupons, platform_coupons, unavailables, auto_applied } = response;

    // 보유쿠폰 조회 성공
    console.debug('사용가능한 쿠폰목록 조회결과', response.data);
    setMyCoupons((oldMyCoupons) => ({
      ...oldMyCoupons,
      loaded: true,
      error: false,
      vendor_coupons,
      platform_coupons,
      unavailables
    }));

    const autoApplyCoupon = [
      ...vendor_coupons.filter(({ coupon }) => auto_applied.includes(coupon.id)),
      ...platform_coupons.filter(({ coupon }) => auto_applied.includes(coupon.id))
    ];

    setSelectedCoupons(autoApplyCoupon);

    // 최대할인금액 구하기
    const vendorCpnDiscnt = autoApplyCoupon
      .filter(({ coupon }) => coupon.type.startsWith('VE'))
      .reduce((dcTotal, { coupon }) => {
        console.log('# 입점샵 쿠폰 총 할인금액 계산하기', coupon);
        return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount);
      }, 0);
    // 플랫폼 정산 쿠폰 할인금액
    const platformCpnDiscnt = autoApplyCoupon
      .filter(({ coupon }) => coupon.type.startsWith('AD'))
      .reduce((dcTotal, { coupon }) => {
        return dcTotal + calcCouponDiscountAmount(coupon, goodsTotalAmount - vendorCpnDiscnt);
      }, 0);
    setMaxSaleAmount(vendorCpnDiscnt + platformCpnDiscnt);
  }, [vendorId, myCoupons]);

  // 프로모션 정보 조회 완료 또는 주문수량 변경시 쿠폰정보 다시 조회
  React.useEffect(() => {
    if (promotionData && totalQuantity) {
      fetchMyCoupons();
    }
  }, [promotionData, totalQuantity]);

  // render
  return (
    <OrderLayout
      title="주문하기"
      appBarRef={(ref) => {
        appBarRef = ref;
      }}
      showBackDrop={false}
      loading={!vendor.loaded || !pickupAvailableDates.loaded || !myCoupons.loaded || loading}
      backDrop={
        <Box>
          <Typography variant="body2" color="#ffffff">
            상품정보를 확인 중 입니다
          </Typography>
        </Box>
      }
      onBackButtonClick={() => {
        pageBackFunc();
      }}
    >
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 프로모션 주문하기`} />
      <Backdrop open={order.request} sx={{ zIndex: theme.zIndex.appBar + 5 }}>
        <Box p={3} sx={{ backdropFilter: 'blur(33px)', background: 'rgba(0,0,0,0.4)', borderRadius: '8px', overflow: 'hidden' }}>
          <Typography variant="body2" color="#ffffff">
            주문정보를 생성하고 있습니다.
          </Typography>
        </Box>
      </Backdrop>

      <Container
        maxWidth="xs"
        pt={0}
        sx={{ minHeight: `calc(var(--vh, 1vh) * 100 - ${appBarHeight})`, position: 'relative', px: [2, 2, 2, 2] }}
      >
        {/* 입점샵 정보 [START] */}
        {vendor.loaded && <VendorInfoCard vendor={vendor.data} />}
        {/* 입점샵 정보 [END] */}
        <Divider sx={{ borderColor: '#2D154D' }} />

        <OrderSection>
          <Box pt={2} position="relative" fontSize="11px" fontWeight={800} lineHeight="normal" letterSpacing="-0.11px">
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              상품 정보
            </Typography>
          </Box>
          <Box>
            <Box pb={2}>
              {/* 상품목록[start] */}
              {promotionData && (
                <Box aria-label="구매상품 목록">
                  {location.state.orderItems.map((orderItem, i) => {
                    const orderOption = ordItemOpts[orderItem.product._id];

                    // 최신 상품정보
                    const recentProductData = promotionData?.promotion_pdata.find((product) => product._id === orderItem.product._id);

                    return (
                      <React.Fragment key={`order-prod-${i}`}>
                        {i > 0 && <Divider />}
                        <OrderPromotionProduct
                          orderItem={orderItem}
                          recentProductData={recentProductData}
                          orderOption={orderOption}
                          onOptionChange={(productId, option) => {
                            const _tmpOrdItemOpts = { ...ordItemOpts };
                            _tmpOrdItemOpts[productId] = option;
                            setOrdItemOpts(_tmpOrdItemOpts);
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>
        </OrderSection>

        {/* 주문/픽업 정보 */}
        {promotionData && promotionData.promotion.pickup.state === 'none' ? (
          <Box component="section">
            <Typography fontWeight="900" fontSize="14px" sx={{ marginBottom: '16px' }}>
              주문/픽업 정보
            </Typography>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                주문 기간
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography>
                {moment(promotionData.promotion.period.start_dt).isSame(promotionData.promotion.period.end_dt, 'day')
                  ? `${moment(promotionData.promotion.period.start_dt).locale('ko').format('MM.DD(ddd)')}`
                  : `${moment(promotionData.promotion.period.start_dt).locale('ko').format('MM.DD(ddd)')} ~
                          ${moment(promotionData.promotion.period.end_dt).locale('ko').format('MM.DD(ddd)')}`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                입고 일정
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography>주문 마감 후 각 픽업샵에 순차 입고</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="16px">
              <Typography fontWeight="700" sx={{ opacity: '.8' }}>
                픽업 기간
              </Typography>
              <Box marginX="6px" width="1px" height="13px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover' }} />
              <Typography sx={{ textDecoration: 'underline' }}>입점샵 입고 완료 후 별도 안내</Typography>
            </Box>
            {promotionData.promotion.pickup.comment && (
              <Box my="32px" padding="16px" borderRadius="8px" sx={{ backgroundColor: '#F3F2F5' }}>
                <Typography color="rgba(20, 2, 41, 0.80)" fontSize="14px" fontWeight="700">
                  픽업 안내
                </Typography>
                <Typography color="rgba(20, 2, 41, 0.70)" fontSize="12px" fontWeight="400" sx={{ mt: '8px' }}>
                  {promotionData.promotion.pickup.comment}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <OrderSection>
            <TitleBox>
              <SpaceBetweenBox>
                <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                  픽업 정보
                </Typography>
                <Box>
                  <Typography component="span" fontSize={12}>
                    {pickupYmd ? moment(pickupYmd, 'YYYYMMDD').format('MM.DD (ddd)') : null}
                  </Typography>
                  <Typography component="span" fontSize={12}>
                    {pickupTime ? ` ${moment(pickupTime, 'HHmm').format('A hh:mm')}` : null}
                  </Typography>
                </Box>
              </SpaceBetweenBox>
            </TitleBox>
            <Box
              sx={(theme) => ({
                height: 'auto',
                minHeight: 72,
                transition: theme.transitions.create('min-height')
              })}
            >
              {pickupAvailableDates.loaded ? (
                pickupAvailableDates.error == null ? (
                  <Box py={1}>
                    <SpaceBetweenBox>
                      <Typography variant="subtitle2" fontSize="11px">
                        날짜 선택
                      </Typography>
                      {!pickupYmd && (
                        <Typography variant={'caption'} lineHeight={1} color={'error.main'}>
                          *픽업날짜를 선택해주세요.
                        </Typography>
                      )}
                    </SpaceBetweenBox>

                    {/* 픽업날짜 선택버튼 표현[start] */}
                    <Box mt={1}>
                      <Grid container spacing={1}>
                        {Object.keys(pickupAvailableDates.data).map((date) => {
                          const selected = pickupYmd === date; // 픽업 날짜 선택 플래그

                          return (
                            <Grid item xs={3} key={`pickup-ymd-${date}`}>
                              <Button
                                fullWidth
                                size="small"
                                type="button"
                                color="brand"
                                variant={selected ? 'contained' : 'outlined'}
                                sx={{
                                  p: '3px 0',
                                  letterSpacing: '-0.5px',
                                  fontWeight: selected && 'bold',
                                  border: '1.5px solid #60546E1A',
                                  '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                                }}
                                value={date}
                                onClick={(e) => {
                                  setPickupTime(null);
                                  setPickupYmd(e.target.value);
                                }}
                              >
                                {moment(date, 'YYYYMMDD').format('M.D (ddd)')}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                    {/* 픽업날짜 선택버튼 표현[end] */}

                    {/* 픽업시간 선택버튼 표현[start] */}
                    <Collapse in={pickupYmd != null && pickupTimeArray.length > 0}>
                      <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography variant="subtitle2" fontSize="11px">
                            시간 선택
                          </Typography>
                          {!pickupTime && (
                            <Typography variant={'caption'} lineHeight={1} color={'error.main'}>
                              *픽업시간을 선택해주세요.
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          {pickupTimeArray.map(([hour, available], i) => {
                            // 지나간 시간은 선택 못 하도록 ( buffer: 1분 )
                            const time = moment({ hour });
                            const timeFormat = time.format('HH:mm'); // 픽업 시간 포맷
                            const selected = pickupTime === timeFormat; // 픽업 시간 선택 플래그

                            return (
                              <Grid item xs={3} key={`pickup-time-${i}`}>
                                <Button
                                  fullWidth
                                  disabled={!available}
                                  size="small"
                                  type="button"
                                  color="brand"
                                  variant={selected ? 'contained' : 'outlined'}
                                  sx={{
                                    p: '3px 0',
                                    letterSpacing: '-0.5px',
                                    fontWeight: selected && 'bold',
                                    border: '1.5px solid #60546E1A',
                                    '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                                  }}
                                  value={timeFormat}
                                  onClick={(e) => setPickupTime(e.target.value)}
                                >
                                  {time.format(`A ${hour === 0 ? 'H' : 'h'}시`)}
                                </Button>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Collapse>
                    {/* 픽업시간 선택버튼 표현[end] */}
                  </Box>
                ) : (
                  <Box component="section" height="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box textAlign="center">
                      <Typography id="pickup-avail-dt-err" variant="caption" role="note">
                        픽업 가능일시 조회 중 오류가 발생했습니다.
                      </Typography>
                      <Box mt={1}>
                        <Button
                          variant="contained"
                          size="small"
                          color="brand"
                          startIcon={<ReplayOutlinedIcon />}
                          onClick={() => {
                            fetchPickupAvailableDates(vendorId);
                          }}
                        >
                          재시도
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )
              ) : (
                <PickupAvailDateLoading />
              )}
            </Box>
          </OrderSection>
        )}

        {/*   /!* 쿠폰 변경하기 버튼 *!/ */}
        <TitleBox>
          <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
            쿠폰
          </Typography>
        </TitleBox>
        {discountByCoupons === maxSaleAmount && maxSaleAmount !== 0 && (
          <Typography fontSize="14px" fontWeight="400" color="#9357E5" sx={{ marginBottom: '14px' }}>
            {discountByCoupons.toLocaleString()}원 최대 할인 적용중
          </Typography>
        )}
        <SpaceBetweenBox height="28px">
          <Typography fontSize="14px" fontWeight="400">
            쿠폰 적용({selectedCoupons.length}개)
          </Typography>
          {myCoupons.loaded && (
            <SmallButton onClick={openCouponDialog} sx={{ borderColor: '#60546E26', color: '#140229' }}>
              {selectedCoupons.length === 0
                ? `보유 쿠폰 ${(
                    myCoupons.vendor_coupons.length +
                    myCoupons.platform_coupons.length +
                    myCoupons.unavailables.length
                  ).toLocaleString()}개`
                : '쿠폰 변경하기'}
            </SmallButton>
          )}
        </SpaceBetweenBox>

        {selectedCoupons?.length > 0 && (
          <Stack spacing={1}>
            {selectedCoupons.map((selected, i) => {
              const { coupon } = selected;
              return (
                <FlexBox key={`selected-coupon-${i}`} justifyContent="space-between" alignItems="center" mt="8px">
                  <Typography color="#898294" noWrap sx={{ maxWidth: '50%' }}>
                    ㄴ&nbsp;{coupon.name}
                  </Typography>
                  <Typography color="#898294" fontWeight="bold">
                    {calcCouponDiscountAmount(coupon, goodsTotalAmount).toLocaleString()}원
                  </Typography>
                </FlexBox>
              );
            })}
          </Stack>
        )}

        {/* 결제수단 [START] */}
        <OrderSection sx={{ mt: '32px' }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              결제수단
            </Typography>
          </TitleBox>
          <Box pt={1} pb={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" fontSize="14px">
                신용/체크카드
              </Typography>
              {/* todo 다른 결제방법 기능작업 후 활성화
              <Button variant="outlined" size="small" sx={{ py: 0.25 }}>
                변경
              </Button>
               */}
            </Box>
          </Box>
        </OrderSection>
        {/* 결제수단 [END] */}

        <Box sx={{ mx: '-16px', px: '20px', pt: 3.5, position: 'relative', bgcolor: '#f3f2f5' }}>
          <Box component="img" alt="" src={waveBorderImg} top="-3px" position="absolute" left={0} width="100%" height="4px" />
          {/* 결제 금액 [START] */}
          <OrderSection>
            <TitleBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                결제 금액
              </Typography>
            </TitleBox>
            <Box pt="17px" pb="30px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>총 상품 금액</Typography>
                <Typography fontWeight={900}>{goodsTotalAmount.toLocaleString()}원</Typography>
              </Box>
              {/* 쿠폰 할인 */}
              <Collapse mountOnEnter unmountOnExit in={selectedCoupons.length > 0}>
                <Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                    <Typography>쿠폰 사용</Typography>
                    {myCoupons.loaded ? (
                      <Typography color="#EA3D44" fontWeight={900}>
                        -{discountByCoupons.toLocaleString()}원
                      </Typography>
                    ) : (
                      <CircularProgress size={14} sx={{ color: '#EA3D44' }} />
                    )}
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </OrderSection>
          {/* 결제 금액 [END] */}

          {/* 총 결제 금액 [START] */}
          <Divider />
          <SpaceBetweenBox sx={{ py: 3 }}>
            <Typography variant="subtitle1" fontWeight={900} fontSize="14px">
              총 결제 금액
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" fontSize="22px" color="#140229" fontWeight="bold" fontFamily="D-DIN-Bold,D-DIN">
                {amount.toLocaleString()}
              </Typography>
            </Box>
          </SpaceBetweenBox>
          {/* 총 결제 금액 [END] */}

          {/* 환불규정 */}
          <PromotionRefundInfo pickupState={promotionData?.promotion.pickup.state} />

          {/* 주문내용 확인 및 약관동의 [START] */}
          <Box py={1} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  size="small"
                  inputProps={{ 'aria-labelledby': 'order-confirm-term' }}
                />
              }
              label={
                <Typography id="order-confirm-term" variant="caption" lineHeight={1} fontSize="14px">
                  주문내용 확인 및 약관동의
                </Typography>
              }
            />
            <Button variant="outlined" size="small" sx={{ py: 0.25 }} onClick={() => handleShowTerms(true)}>
              약관보기
            </Button>
          </Box>
          {/* 주문내용 확인 및 약관동의 [END] */}

          {/* 결제하기 버튼 [START] */}
          <Box pt="24px" pb="16px">
            <Button
              fullWidth
              type="button"
              disabled={
                promotionData?.promotion.pickup.state === 'none'
                  ? !myCoupons.loaded || !agree
                  : !myCoupons.loaded || pickupYmd == null || pickupTime == null || !agree
              }
              variant="contained"
              color="brand"
              size="x-large"
              onClick={startPurchase}
              sx={{ height: 46 }}
            >
              <Typography fontSize={17} fontWeight={800}>
                결제하기
              </Typography>
            </Button>
          </Box>
          {/* 결제하기 버튼 [END] */}
        </Box>
      </Container>

      {/* 쿠폰선택 다이어로그 */}
      <Dialog fullScreen open={cpnDialogOpen} scroll="paper" sx={{ margin: 'auto', maxWidth: 400 }}>
        <WoDialogTitle title="쿠폰 할인" wrapperProps={{ sx: { height: '54px !important' } }} onClickClose={closeCouponDialog} />
        <WoDivider />

        <DialogContent sx={{ p: 0, pb: `${(cpnDialogData.showSelected ? 290 : 120) + 8}px` }}>
          <Container maxWidth="xs" sx={{ p: [0, 0, 0, 0] }}>
            <Box mx={2.5} py={2}>
              {myCoupons.loaded ? (
                <Stack divider={<Divider sx={{ my: 2 }} />}>
                  {/* 입점샵 정산 쿠폰목록 */}
                  {myCoupons.vendor_coupons.length > 0 && (
                    <Stack spacing={'12px'}>
                      {myCoupons.vendor_coupons.map((couponInfo, i) => {
                        const { coupon, vendor, promotion } = couponInfo;
                        const selected = _.find(cpnDialogData.selected, (selected) => selected.coupon.id === coupon.id) !== undefined;
                        const chipText = promotion ? '프로모션 전용' : vendor ? vendor.biz_name : '모든샵/전품목';
                        return (
                          <Card
                            key={`vendor-coupon-${i}`}
                            elevation={1}
                            sx={{ display: 'flex', height: '147px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' }}
                          >
                            <Grid container spacing={0}>
                              <Grid item xs>
                                <CardContent>
                                  <Box mb="8px" height="35px">
                                    {coupon.discount_type === 'percent' ? (
                                      <Box display="flex">
                                        <Typography lineHeight="32px" color="#774AD2" fontWeight="700" fontSize="32px" fontFamily="D-DIN">
                                          {coupon.percent}
                                        </Typography>
                                        <Typography color={'#774AD2'} fontSize="24px" fontWeight="800" lineHeight="32px">
                                          %
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box display="flex">
                                        <Typography lineHeight="32px" color={'#774AD2'} fontWeight="700" fontSize="32px" fontFamily="D-DIN">
                                          {coupon.amount.toLocaleString()}
                                        </Typography>
                                        <Typography color={'#774AD2'} fontSize="24px" fontWeight="800" lineHeight="32px">
                                          원
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <Typography lineHeight="14px" fontSize="12px" fontWeight="800" color={'rgba(20, 2, 41, 0.60)'}>
                                    {coupon.name}
                                  </Typography>
                                  <Box my="8px" display="flex" justifyContent="left" alignItems="center" borderRadius="2px" height="16px">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      borderRadius="2px"
                                      height="16px"
                                      paddingX="4px"
                                      sx={{
                                        backgroundColor: 'rgba(147, 87, 229, 0.12)'
                                      }}
                                    >
                                      <Typography color="#9357E5" fontSize="10px" fontWeight="700">
                                        {chipText}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', mb: '4px' }}>
                                    최소 주문 금액: {coupon.min_order_amount.toLocaleString()}원 &nbsp;
                                    {coupon.discount_type === 'percent' && `(최대 ${coupon.max_dis_amount.toLocaleString()}원 할인)`}
                                  </Typography>
                                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                                    사용 기간: {moment(coupon?.use_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm')}&nbsp;까지
                                  </Typography>
                                </CardContent>
                              </Grid>
                              <Grid item xs="auto">
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                  width="76px"
                                  overflow="hidden"
                                >
                                  <Box width="1px" height="147px" component="img" src={shortDashed} />
                                  <CouponCheckbox
                                    label="선택"
                                    checked={selected}
                                    onChange={(e) => {
                                      onCpnDialogCouponClick(e.target.checked, couponInfo);
                                    }}
                                    sx={{ height: '100%', width: '76px' }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })}
                    </Stack>
                  )}

                  {/* 플랫폼 정산 쿠폰목록 */}
                  {myCoupons.platform_coupons.length > 0 && (
                    <>
                      {myCoupons.vendor_coupons.length > 0 && (
                        <FlexBox my={1} justifyContent="center">
                          <Typography fontWeight="700" fontSize="14px" color="rgba(147, 87, 229, 0.60)">
                            함께 쓸 수 있어요!
                          </Typography>
                        </FlexBox>
                      )}
                      <Stack spacing={'12px'} mt="8px">
                        {myCoupons.platform_coupons.map((couponInfo, i) => {
                          const { coupon, promotion } = couponInfo;
                          const selected = _.find(cpnDialogData.selected, (selected) => selected.coupon.id === coupon.id) !== undefined;
                          // console.log('#coupon: ', coupon);
                          return (
                            <Card
                              key={`vendor-coupon-${i}`}
                              elevation={1}
                              sx={{ display: 'flex', height: '147px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' }}
                            >
                              <Grid container spacing={0}>
                                <Grid item xs>
                                  <CardContent>
                                    <Box mb="8px" height="35px">
                                      {coupon.discount_type === 'percent' ? (
                                        <Box display="flex">
                                          <Typography
                                            lineHeight="32px"
                                            color={'#774AD2'}
                                            fontWeight="700"
                                            fontSize="32px"
                                            fontFamily="D-DIN"
                                          >
                                            {coupon.percent}
                                          </Typography>
                                          <Typography color={'#774AD2'} fontSize="24px" fontWeight="800" lineHeight="32px">
                                            %
                                          </Typography>
                                        </Box>
                                      ) : (
                                        <Box display="flex">
                                          <Typography
                                            lineHeight="32px"
                                            color={'#774AD2'}
                                            fontWeight="700"
                                            fontSize="32px"
                                            fontFamily="D-DIN"
                                          >
                                            {coupon.amount.toLocaleString()}
                                          </Typography>
                                          <Typography color={'#774AD2'} fontSize="24px" fontWeight="800" lineHeight="32px">
                                            원
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                    <Typography lineHeight="14px" fontSize="12px" fontWeight="800" color={'rgba(20, 2, 41, 0.60)'}>
                                      {coupon.name}
                                    </Typography>
                                    {promotion ? (
                                      <Box
                                        my="8px"
                                        display="flex"
                                        justifyContent="left"
                                        alignItems="center"
                                        borderRadius="2px"
                                        height="16px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          borderRadius="2px"
                                          height="16px"
                                          paddingX="4px"
                                          sx={{
                                            backgroundColor: 'rgba(255, 139, 170, 0.16)'
                                          }}
                                        >
                                          <Typography color="#FF809E" fontSize="10px" fontWeight="700">
                                            프로모션 전용
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Box
                                        my="8px"
                                        display="flex"
                                        justifyContent="left"
                                        alignItems="center"
                                        borderRadius="2px"
                                        height="16px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          borderRadius="2px"
                                          height="16px"
                                          paddingX="4px"
                                          sx={{
                                            backgroundColor: 'rgba(71, 196, 84, 0.12)'
                                          }}
                                        >
                                          <Typography color="#47C454" fontSize="10px" fontWeight="700">
                                            모든샵/전품목
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )}

                                    <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', mb: '4px' }}>
                                      최소 주문 금액: {coupon.min_order_amount.toLocaleString()}원 &nbsp;
                                      {coupon.discount_type === 'percent' && `(최대 ${coupon.max_dis_amount.toLocaleString()}원 할인)`}
                                    </Typography>
                                    <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                                      사용 기간: {moment(coupon?.use_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm')}&nbsp;까지
                                    </Typography>
                                  </CardContent>
                                </Grid>
                                <Grid item xs="auto">
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                    width="76px"
                                    overflow="hidden"
                                  >
                                    <Box width="1px" height="147px" component="img" src={shortDashed} />
                                    <CouponCheckbox
                                      label="선택"
                                      checked={selected}
                                      onChange={(e) => {
                                        onCpnDialogCouponClick(e.target.checked, couponInfo);
                                      }}
                                      sx={{ height: '100%', width: '76px' }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Card>
                          );
                        })}
                      </Stack>
                    </>
                  )}

                  {/* 사용불가능 쿠폰목록 */}
                  {myCoupons.unavailables.length > 0 && (
                    <Stack spacing={'12px'}>
                      {myCoupons.unavailables.map(({ coupon, vendor, promotion }, i) => {
                        // const chipText_ = vendor ? vendor.biz_name : promotion ? '프로모션 전용' : '모든샵/전품목';
                        const chipText = promotion ? '프로모션 전용' : vendor ? vendor.biz_name : '모든샵/전품목';
                        return (
                          <Card
                            key={`vendor-coupon-${i}`}
                            elevation={1}
                            sx={{ display: 'flex', height: '147px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' }}
                          >
                            <Grid container spacing={0}>
                              <Grid item xs>
                                <CardContent>
                                  <Box mb="8px" height="35px">
                                    {coupon.discount_type === 'percent' ? (
                                      <Box display="flex">
                                        <Typography
                                          lineHeight="32px"
                                          color="rgba(20, 2, 41, 0.60)"
                                          fontWeight="700"
                                          fontSize="32px"
                                          fontFamily="D-DIN"
                                        >
                                          {coupon.percent}
                                        </Typography>
                                        <Typography color="rgba(20, 2, 41, 0.60)" fontSize="24px" fontWeight="800" lineHeight="32px">
                                          %
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box display="flex">
                                        <Typography
                                          lineHeight="32px"
                                          color="rgba(20, 2, 41, 0.60)"
                                          fontWeight="700"
                                          fontSize="32px"
                                          fontFamily="D-DIN"
                                        >
                                          {coupon.amount.toLocaleString()}
                                        </Typography>
                                        <Typography color="rgba(20, 2, 41, 0.60)" fontSize="24px" fontWeight="800" lineHeight="32px">
                                          원
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <Typography lineHeight="14px" fontSize="12px" fontWeight="800" color={'rgba(20, 2, 41, 0.60)'}>
                                    {coupon.name}
                                  </Typography>
                                  <Box my="8px" display="flex" justifyContent="left" alignItems="center" borderRadius="2px" height="16px">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      borderRadius="2px"
                                      height="16px"
                                      paddingX="4px"
                                      sx={{
                                        backgroundColor: 'rgba(20, 2, 41, 0.12)'
                                      }}
                                    >
                                      <Typography color="rgba(20, 2, 41, 0.60)" fontSize="10px" fontWeight="700">
                                        {chipText}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                                    최소 주문 금액: {coupon.min_order_amount.toLocaleString()}원 &nbsp;
                                    {coupon.discount_type === 'percent' && `(최대 ${coupon.max_dis_amount.toLocaleString()}원 할인)`}
                                  </Typography>
                                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                                    사용 기간: {moment(coupon?.use_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm')}&nbsp;까지
                                  </Typography>
                                </CardContent>
                              </Grid>
                              <Grid item xs="auto">
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                  width="76px"
                                  overflow="hidden"
                                >
                                  <Box width="1px" height="147px" component="img" src={shortDashed} />
                                  <CouponCheckboxUnAvailable label="선택" disabled checked={false} sx={{ height: '100%', width: '76px' }} />
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })}
                    </Stack>
                  )}
                </Stack>
              ) : (
                <FlexBox justifyContent="center" alignItems="center" height={100}>
                  <Typography variant="caption">보유쿠폰 목록을 불러오고 있습니다...</Typography>
                </FlexBox>
              )}
            </Box>
          </Container>
        </DialogContent>

        <Box
          component="section"
          className={`${classes.bottomSheet} bottom-button-sheet ${cpnDialogData.selected.length > 0 ? 'open' : 'close'} trans `}
          maxWidth="400px"
        >
          {/* 선택한 상품 목록 영역 */}
          <Box className={`order-info ${cpnDialogData.showSelected ? 'open' : 'close'}`}>
            <WoDialogTitle title="선택한 쿠폰" onClickClose={() => setCpnDialogData((prev) => ({ ...prev, showSelected: false }))} />
            <WoDivider />
            <Box className={classes.productBox}>
              {/* 상품목록 */}
              <List>
                {cpnDialogData.selected.map((selected, i) => {
                  const { coupon } = selected;
                  return (
                    <Box my="7px" key={`cpn-dlg-slctd-coupon-${i}`} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography color="rgba(20, 2, 41, 0.70)" fontSize="12px" fontWeight="400">
                        {coupon.name}
                      </Typography>
                      <Typography color="rgba(20, 2, 41, 0.70)" fontSize="12px" fontWeight="800">
                        -&nbsp; {calcCouponDiscountAmount(coupon, goodsTotalAmount).toLocaleString()}원
                      </Typography>
                    </Box>
                  );
                })}
              </List>
            </Box>
          </Box>

          {/* 선택한 쿠폰 요약 영역 (총 쿠폰 갯수, 가격) */}
          <Box className={`order-summary ${cpnDialogData.selected.length > 0 ? 'open' : 'close'}`}>
            <Box zIndex={1203}>
              <Box px="20px" py="16px" display="flex" justifyContent="space-between" alignItems="center">
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex">
                    <Typography fontSize="14px" color="#140229">
                      총 할인 금액&nbsp;
                    </Typography>
                    <Typography color="rgba(20, 2, 41, 0.60)">(쿠폰{cpnDialogData.selected.length}개 사용)</Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography color="#9357E5" fontFamily="D-DIN" fontSize="22px" fontWeight="700">
                      {cpnDialogCouponDiscountAmount.toLocaleString()}
                    </Typography>
                    <Typography fontWeight="300" fontSize="16px">
                      원
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <Box pl={1}>
                    <ButtonBase
                      onClick={() => setCpnDialogData((prev) => ({ ...prev, showSelected: !prev.showSelected }))}
                      sx={{ borderRadius: '50%' }}
                    >
                      <ArrowCircleButton component="div" on={cpnDialogData.showSelected} />
                    </ButtonBase>
                  </Box>
                </Box>
              </Box>
            </Box>
            <WoDivider />
          </Box>

          {/* 장바구니 & 주문하기 버튼 영역 */}
          <ButtonGroupWrapper display="flex" alignItems="center" width={1} px={2.5}>
            <Grid container flexGrow={1} spacing={1}>
              <Grid item xs>
                <Button type="button" fullWidth variant="contained" color="brand" onClick={applyCpnDialogData} sx={{ height: '47px' }}>
                  <Typography fontSize="17px" fontWeight="800">
                    적용하기
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </ButtonGroupWrapper>
        </Box>
      </Dialog>

      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color="text.primary">
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color="text.primary" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color="text.primary" variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size="small"
              variant="outlined"
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>
      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color="text.primary">
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color="text.primary" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color="text.primary" variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size="small"
              variant="outlined"
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </OrderLayout>
  );
}

export default OrderPromotionConfirm;

// eslint-disable-next-line react/prop-types
const Transition = React.forwardRef(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});

const PickupAvailDateLoading = React.memo(() => (
  <Box
    component="section"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    role="progressbar"
    aria-labelledby="pickup-avail-dt-loadding"
  >
    <Box textAlign="center">
      <Box>
        <CircularProgress size="1.4rem" color="brand" />
      </Box>
      <Typography id="pickup-avail-dt-loadding" variant="caption" role="presentation" sx={{ mt: '4px' }}>
        픽업 가능시간을 확인 중 입니다.
      </Typography>
    </Box>
  </Box>
));

const useStyles = makeStyles((theme) => ({
  bottomSheet: {
    position: 'fixed',
    bottom: 0,
    left: 'auto',
    width: '100%',
    right: 'auto',
    height: 'auto',
    zIndex: 1200,
    backgroundColor: '#fff',
    border: `1px solid ${alpha(theme.palette.brand.main, 0.1)}`,
    maxWidth: 'xs',
    borderBottom: 'none',
    boxShadow: '0px -2px 10px 0 rgba(0, 0, 0, 0.16)'
  },
  productBox: {
    padding: '0 20px',
    maxHeight: 185,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  cartButtonWrapper: {
    border: '1px solid #220348',
    width: '46px',
    height: '46px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px'
  }
}));

export const ButtonGroupWrapper = styled(Box)`
  padding-top: 8px;
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
`;

function ArrowCircleButton({ on = false, onClick = () => {}, component = 'button' }) {
  // render
  return (
    <ArrowButton
      component={component}
      aria-label="상품 선택정보"
      size="small"
      edge="end"
      color="brand"
      sx={{ p: '2px', m: 0 }}
      onClick={onClick}
    >
      <KeyboardArrowUpSharpIcon fontSize="inherit" sx={{ transform: `rotate(${on ? '180' : '0'}deg)` }} />
    </ArrowButton>
  );
}

ArrowCircleButton.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  component: PropTypes.string
};

const ArrowButton = styled(IconButton)(({ theme }) => ({
  marginLeft: '4px',
  marginBottom: '3px',
  border: `1px solid ${theme.palette.brand.main}`,
  borderRadius: '50%',
  transition: `all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut}`
}));
