import { SvgIcon } from '@mui/material';

// eslint-disable-next-line react/prop-types
export function DiscountChipIcon({ width = 12, height = 13 }) {
  return (
    <SvgIcon viewBox="0 0 12 13" sx={{ width, height }}>
      <g transform="translate(-3093 -10812)">
        <rect iwidth="12" height="13" transform="translate(3093 10812)" style={{ fill: '#defd9a' }} />
        <g transform="translate(3082.641 10812.249)">
          <g transform="translate(10.359 -0.002)">
            <g transform="translate(0 0)">
              <path
                d="M21.78,6.413a.367.367,0,0,1,0-.324l.466-.954a1.086,1.086,0,0,0-.468-1.442l-.938-.5a.366.366,0,0,1-.19-.262l-.183-1.046A1.086,1.086,0,0,0,19.24,1l-1.052.149a.366.366,0,0,1-.308-.1L17.117.307a1.086,1.086,0,0,0-1.516,0l-.763.739a.367.367,0,0,1-.308.1L13.478,1a1.086,1.086,0,0,0-1.226.891l-.183,1.046a.367.367,0,0,1-.19.262l-.938.5a1.086,1.086,0,0,0-.468,1.442l.466.954a.366.366,0,0,1,0,.324l-.466.954a1.086,1.086,0,0,0,.468,1.442l.938.5a.366.366,0,0,1,.19.262l.183,1.046a1.085,1.085,0,0,0,1.07.9,1.115,1.115,0,0,0,.156-.011l1.052-.149a.366.366,0,0,1,.308.1l.763.738a1.086,1.086,0,0,0,1.516,0l.763-.738a.367.367,0,0,1,.308-.1l1.052.149a1.086,1.086,0,0,0,1.226-.891l.184-1.046a.367.367,0,0,1,.19-.262l.938-.5a1.086,1.086,0,0,0,.468-1.442ZM14.916,3.005a1.322,1.322,0,1,1-1.322,1.322A1.324,1.324,0,0,1,14.916,3.005Zm-.683,5.881a.361.361,0,0,1-.51-.51l4.76-4.76a.361.361,0,1,1,.51.51ZM17.8,9.5a1.322,1.322,0,1,1,1.322-1.322A1.324,1.324,0,0,1,17.8,9.5Z"
                transform="translate(-10.359 0.002)"
                style={{ fill: '#140229' }}
              />
            </g>
          </g>
          <g transform="translate(17.153 7.523)">
            <g transform="translate(0)">
              <path
                d="M291.091,310.141a.639.639,0,1,0,.639.639A.639.639,0,0,0,291.091,310.141Z"
                transform="translate(-290.452 -310.141)"
                style={{ fill: '#140229' }}
              />
            </g>
          </g>
          <g transform="translate(14.288 3.702)">
            <g transform="translate(0)">
              <path
                d="M172.972,152.646a.639.639,0,1,0,.639.639A.639.639,0,0,0,172.972,152.646Z"
                transform="translate(-172.333 -152.646)"
                style={{ fill: '#140229' }}
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

// eslint-disable-next-line react/prop-types
export function PromotionClockIcon({ width = 14, height = 12, color = '#9357E5' }) {
  return (
    <SvgIcon viewBox="0 0 16 14" sx={{ width, height }}>
      <g transform="translate(-281 -447)">
        <rect style={{ width, height, fill: '#fff' }} transform="translate(281 447)" />
        <g transform="translate(282.401 424.744)">
          <g transform="translate(1.527 24.607)">
            <g transform="translate(0 0)">
              <path
                d="M65.829,77.017A5.057,5.057,0,1,0,70.9,82.074,5.071,5.071,0,0,0,65.829,77.017Zm0,9.2a4.141,4.141,0,1,1,4.153-4.141A4.151,4.151,0,0,1,65.829,86.215Z"
                transform="translate(-60.757 -77.017)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(5.905 27.338)">
            <g transform="translate(0 0)">
              <path
                d="M229.764,182.408h-1.186v-1.72a.445.445,0,1,0-.89,0v2.165a.445.445,0,0,0,.445.445h1.631a.445.445,0,1,0,0-.89Z"
                transform="translate(-227.688 -180.243)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(1.838 32.883)">
            <g transform="translate(0 0)">
              <path
                d="M75.22,394.482a.445.445,0,0,0-.628.033L73.257,396a.445.445,0,1,0,.661.6l1.335-1.483A.445.445,0,0,0,75.22,394.482Z"
                transform="translate(-73.143 -394.368)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(9.136 32.883)">
            <g>
              <path
                d="M352.5,396l-1.335-1.483a.445.445,0,1,0-.661.6l1.335,1.483a.445.445,0,1,0,.661-.6Z"
                transform="translate(-350.389 -394.371)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(0 23.256)">
            <g transform="translate(0 0)">
              <path
                d="M3.84,23.908a2.265,2.265,0,0,0-3.176,0A2.241,2.241,0,0,0,0,25.518a2.207,2.207,0,0,0,.667,1.576.445.445,0,0,0,.626,0l2.55-2.55a.445.445,0,0,0,0-.632ZM1.027,26.1a1.326,1.326,0,0,1-.138-.585,1.358,1.358,0,0,1,1.359-1.365,1.389,1.389,0,0,1,.6.134Z"
                transform="translate(0 -23.26)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g transform="translate(9.225 23.256)">
            <g transform="translate(0 0)">
              <path
                d="M357.228,23.907a2.265,2.265,0,0,0-3.176,0,.445.445,0,0,0,0,.632l2.55,2.55a.444.444,0,0,0,.626,0,2.206,2.206,0,0,0,.667-1.576A2.243,2.243,0,0,0,357.228,23.907Zm-.363,2.187-1.816-1.816A1.361,1.361,0,0,1,357,25.51,1.326,1.326,0,0,1,356.864,26.094Z"
                transform="translate(-353.918 -23.256)"
                style={{ fill: color }}
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
