import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AuthLayout = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const { pathname, search, hash } = location;
  const loginUri = `/auth/sign-in?from=${encodeURIComponent(pathname + search + hash)}`;
  return isLoggedIn ? <Outlet /> : <Navigate to={loginUri} />;
};

export default AuthLayout;
