import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// project imports
import { useScriptRef } from 'hooks';
import { getMainContentsList } from 'services/ContentService';

// material-ui
import { Box, ButtonBase, Container, Paper, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 메인 콘텐츠 이미지 커버 가로 크기
const CONTENTS_IMG_COVER_WIDTH = 294;
// 메인 콘텐츠 이미지 커버 세로 크기
const CONTENTS_IMG_COVER_HEIGHT = 294;
// 메인 콘텐츠 이미지 커버 radius
const CONTENTS_IMG_COVER_RADIUS = 12;

const useStyles = makeStyles((theme) => ({
  contentSwiper: {
    marginTop: '28px',
    marginLeft: '-20px',
    marginRight: '-20px',
    paddingTop: '4px',
    paddingBottom: '32px',
    '--swiper-pagination-color': theme.palette.brand.main
  },
  swiperSlide: {
    width: CONTENTS_IMG_COVER_WIDTH,
    height: CONTENTS_IMG_COVER_HEIGHT,
    borderRadius: `${CONTENTS_IMG_COVER_RADIUS}px`,
    boxShadow: '0px 1px 6px 0px #00000033'
  },

  contentThumbnail: {
    '& > img': {
      width: CONTENTS_IMG_COVER_WIDTH,
      height: CONTENTS_IMG_COVER_HEIGHT,
      borderRadius: `${CONTENTS_IMG_COVER_RADIUS}px`,
      objectFit: 'cover',
      objectPosition: 'center center'
    }
  }
}));

const defaultMainContents = Object.freeze({
  loaded: false,
  error: false,
  title: '',
  show: false,
  list: []
});

/**
 * 홈 > 메인 콘텐츠 리스트 ('와인 디스커버리' -> '메인 콘텐츠'로 명칭 변경)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function MagazineList() {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();

  const classes = useStyles();

  // 메인콘텐츠 정보
  const [mainContents, setMainContents] = useLocationState('main-contents', defaultMainContents);

  React.useEffect(() => {
    if (!mainContents.loaded) fetchMainContents();
  }, [mainContents.loaded, mainContents.error]);

  const fetchMainContents: Promise<void> = React.useCallback(async () => {
    setMainContents({ loaded: false, error: false, title: '', show: false, list: [] });

    // 메인 콘텐츠 정렬 순서
    const body = {
      order_by: 'created_at',
      order: 'desc'
    };

    const mainContents = await getMainContentsList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[MainContents][fetchMainContents] Unmounted component.');
      return;
    }

    // 메인 콘텐츠 조회 실패
    if (mainContents.error) {
      console.error('[HomeIndex][fetchMagazines] ', mainContents.error);
      setMainContents({ loaded: true, error: true, title: '', show: false, magazines: [] });
      return;
    }

    // 잘못된 Response 값이 넘어옴.
    const { code, message } = mainContents.result;

    if (code !== 0) {
      console.error('[HomeIndex][fetchMagazines] ', message);
      setMainContents({ loaded: true, error: true, title: '', show: false, magazines: [] });
      return;
    }

    // 조회된 메인 콘텐츠의 제목과 콘텐츠 목록
    const { title, list, show } = mainContents.data;

    // 메인 콘텐츠 노출 여부가 false 일 경우
    if (!show) {
      console.warn('[HomeIndex][fetchMagazines] 메인 콘텐츠 노출 여부가 false 입니다.');
    }

    setMainContents({ loaded: true, error: false, title, show, list });
  }, []);

  // render
  return (
    mainContents.loaded &&
    !mainContents.error &&
    mainContents.show &&
    mainContents.list.length > 0 && (
      <Box mt="56px">
        <Paper square component="section" elevation={0}>
          <Container aria-label="MAIN_CONTENTS" component="article" position="relative" sx={{ px: '20px' }}>
            {/* 메인 콘텐츠 이름 */}
            <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" lineHeight="normal" noWrap>
              {mainContents?.title && mainContents?.title.length ? mainContents.title : '추천 컨텐츠'}
            </Typography>

            {/* 메인 콘텐츠 목록 */}
            <Swiper
              className={`HomeMainContents-Swiper ${classes.contentSwiper}`}
              loop={mainContents.list.length > 1}
              slidesPerView="auto"
              centeredSlides={true}
              spaceBetween={12}
              pagination={{
                dynamicBullets: true
              }}
              modules={[Pagination]}
            >
              {mainContents.list.map((content, index) => {
                // 메인 콘텐츠 썸네일 이미지
                const { origin = null } = content.magazine_img;

                return (
                  <SwiperSlide key={`main-contents-${index}-cover`} className={classes.swiperSlide}>
                    <ButtonBase
                      sx={{ borderRadius: `${CONTENTS_IMG_COVER_RADIUS}px` }}
                      onClick={() => navigate(`/magazine/${content._id}`)}
                    >
                      <Box
                        sx={{
                          width: CONTENTS_IMG_COVER_WIDTH,
                          height: CONTENTS_IMG_COVER_HEIGHT,
                          borderRadius: `${CONTENTS_IMG_COVER_RADIUS}px`
                        }}
                      >
                        {origin ? (
                          <LazyLoadImage src={origin} wrapperClassName={classes.contentThumbnail} visibleByDefault />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              width: CONTENTS_IMG_COVER_WIDTH,
                              height: CONTENTS_IMG_COVER_HEIGHT,
                              borderRadius: `${CONTENTS_IMG_COVER_RADIUS}px`
                            }}
                          />
                        )}
                      </Box>
                    </ButtonBase>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
        </Paper>
      </Box>
    )
  );
}

export default MagazineList;
