import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { initializeFirestore } from 'firebase/firestore';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { initializeApp } from 'firebase/app';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
// import { initializeAuth, browserLocalPersistence } from 'firebase/auth';
import amplitude from 'amplitude-js';

// project imports
import { persister, store } from 'store';
import App from './App';
import { IS_PRODUCTION } from './config';

// 프로덕션 환경에서 도메인검사
if (IS_PRODUCTION) {
  if (window.location.origin !== process.env.REACT_APP_SERVICE_DOMAIN) {
    console.warn('[1KMWINE] 올바르지 않은 경로로 접근했습니다. 도메인을 변경합니다. origin=', window.location.origin);
    window.location.replace(process.env.REACT_APP_SERVICE_DOMAIN);
  }
}

console.debug('[1KMWINE] 프론트 웹 version = ', process.env.REACT_APP_VERSION);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename="">
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

if (process.env.REACT_APP_FIREBASE_DATABASE_URL) {
  firebaseConfig.databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
}

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });

export const analytics = getAnalytics(firebaseApp);

const amplitudeApiKey = IS_PRODUCTION ? process.env.REACT_APP_AMPLITUDE_API_KEY : 'a8833720189c0df2b4d97df6364342fb';

// Initialize Amplitude
amplitude.getInstance().init(amplitudeApiKey);

if (window.localStorage.getItem('wo-device-id')) {
  const deviceId = window.localStorage.getItem('wo-device-id');
  console.log('------------------ wo-device-id', deviceId);
  amplitude.getInstance().setDeviceId(deviceId);
}

export { amplitude };

// Performance Monitoring - It only works in production environments.
export const perf = getPerformance(firebaseApp);
perf.dataCollectionEnabled = IS_PRODUCTION;
perf.instrumentationEnabled = IS_PRODUCTION;

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// if (process.env.NODE_ENV === 'development') {
//   window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6LfVoNUgAAAAAOZYcjsUnbQRnNWXqJ34XAjZmNwj'),
//   isTokenAutoRefreshEnabled: true // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
// });
