import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project imports
import SpaceBetweenBox from 'components/SpaceBetweenBox';

// material-ui
import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// grid images
import { CouponIcon, EventIcon, OrderHistoryIcon, ReviewIcon } from 'components/icons/inventory';

const styledBorder = '1px solid #1402291a';

/**
 * 주문내역, 쿠폰, 리뷰, 이벤트 표현
 *
 * @constructor
 * @authors hkcho<hkcho@wineone.io>, jilee<jilee@wineone.io>
 */
function InventoryDashboard({ dashboardCounts }) {
  const navigate = useNavigate();

  // 주문이력 페이지로 이동
  const moveToOrderHistory = React.useCallback(() => {
    navigate('/my/order/list');
  }, [navigate]);

  // render
  return (
    <Grid container sx={{ borderTop: styledBorder }}>
      {/* 주문내역 */}
      <Grid item xs={6}>
        <ButtonBase sx={{ width: '100%', height: '60px' }} onClick={moveToOrderHistory}>
          <SpaceBetweenBox
            sx={{
              borderRight: styledBorder,
              borderBottom: styledBorder,
              width: '100%',
              height: '100%',
              pr: '15px'
            }}
          >
            <Box display="flex" alignItems="center">
              <OrderHistoryIcon />
              <ItemTitle>주문내역</ItemTitle>
            </Box>
            <Box fontSize="15px" fontWeight={800} letterSpacing="-0.15px">
              {dashboardCounts.order >= 0 ? dashboardCounts.order.toLocaleString() : ''}
            </Box>
          </SpaceBetweenBox>
        </ButtonBase>
      </Grid>

      {/* 쿠폰 */}
      <Grid item xs={6}>
        <ButtonBase
          sx={{ width: '100%', height: '60px' }}
          onClick={() => {
            navigate('/my/coupon');
          }}
        >
          <SpaceBetweenBox sx={{ borderBottom: styledBorder, width: '100%', height: '100%', pl: '15px' }}>
            <Box display="flex" alignItems="center">
              <CouponIcon />
              <ItemTitle>쿠폰</ItemTitle>
            </Box>
            <Box fontSize="15px" fontWeight={800} letterSpacing="-0.15px">
              {dashboardCounts.coupon >= 0 ? dashboardCounts.coupon.toLocaleString() : ''}
            </Box>
          </SpaceBetweenBox>
        </ButtonBase>
      </Grid>

      {/* 리뷰 개수 */}
      <Grid item xs={6}>
        <ButtonBase
          onClick={() => {
            navigate('/my/review/list');
          }}
          sx={{ width: '100%', height: '60px' }}
        >
          <SpaceBetweenBox
            sx={{
              borderRight: styledBorder,
              width: '100%',
              height: '100%',
              pr: '15px'
            }}
          >
            <Box display="flex" alignItems="center">
              <ReviewIcon />
              <ItemTitle>리뷰관리</ItemTitle>
            </Box>
            <Box fontSize="15px" fontWeight={800} letterSpacing="-0.15px">
              {dashboardCounts?.review >= 0 ? dashboardCounts?.review?.toLocaleString() : ''}
            </Box>
          </SpaceBetweenBox>
        </ButtonBase>
      </Grid>

      {/* 이벤트 */}
      <Grid item xs={6}>
        <ButtonBase
          sx={{ width: '100%', height: '60px' }}
          onClick={() => {
            navigate('/my/event/list');
          }}
        >
          <SpaceBetweenBox sx={{ width: '100%', height: '100%', pl: '15px' }}>
            <Box display="flex" alignItems="center">
              <EventIcon />
              <ItemTitle>이벤트</ItemTitle>
            </Box>

            <Box fontSize="15px" fontWeight={800} letterSpacing="-0.15px">
              {dashboardCounts.event >= 0 ? dashboardCounts.event.toLocaleString() : ''}
            </Box>
          </SpaceBetweenBox>
        </ButtonBase>
      </Grid>
    </Grid>
  );
}
InventoryDashboard.propTypes = {
  dashboardCounts: PropTypes.object.isRequired
};

const ItemTitle = styled((props) => (
  <Typography component="span" fontSize="14px" fontWeight="bold" letterSpacing="-0.14px" lineHeight="normal" {...props} />
))`
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
`;

export default InventoryDashboard;
