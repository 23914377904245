import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// import mui
import { AlertTitle, Box, ButtonBase, CircularProgress, Typography } from '@mui/material';

// import project
import { downloadCoupons } from '../../../../services/UserService';
import { openSnackbar } from '../../../../store/slices/snackbar';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../../../utils/firebase-tools';
import { WoAlert } from '../../../../utils/kmwine-alerts';
import { CLO_CODE } from '../../../../config';

// assets
import shortDashed from '../assets/img_coupon dash 147.png';
import longDashed from '../assets/img_coupon dash 162.png';
import donwloadComplete from '../assets/img_coupon download complete btn.png';
import downloadUnavailable from '../assets/img_coupon download inactive btn.png';
import downloadAvailable from '../assets/img_coupon download active btn.png';

/**
 * 프로모션 쿠폰
 *
 * @returns {JSX.Element}
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function Coupon({ coupon, findRightCouponAndChange, couponLoading, setCouponLoading }) {
  const globalDispatch = useDispatch();
  // 다운로드 기간과 사용 기간이 같은지 확인
  const samePeriod = coupon.download_period.end_dt === coupon.use_period.end_dt;

  // 이미 다운받은 쿠폰
  const alreadyDownloaded = coupon.my.downloaded === 1;

  // 다운로드횟수 초과
  const overDownload = coupon.publish !== -1 && coupon.publish <= coupon.download;

  // 쿠폰 다운로드 기간이 아님
  const notPeriod = new Date() > new Date(coupon.download_period.end_dt) || new Date() < new Date(coupon.download_period.start_dt);

  const findIcon = () => {
    if (alreadyDownloaded) return donwloadComplete;
    if (overDownload || notPeriod) return downloadUnavailable;
    return downloadAvailable;
  };

  const couponDownload = async () => {
    setCouponLoading(true);
    const result = await downloadCoupons([coupon.id]);
    if (result.data.result.code === 0) {
      findRightCouponAndChange(coupon.id);
      setCouponLoading(false);
      globalDispatch(
        openSnackbar({
          open: true,
          message: (
            <>
              <AlertTitle>쿠폰이 발급되었습니다.</AlertTitle>
              <Typography variant="caption">프로모션이 마감되기 전에 쿠폰으로 할인 받고 상품을 주문해보세요!</Typography>
            </>
          ),
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 3000
        })
      );
    } else {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `쿠폰 다운로드 중 중 에러`,
        msg: ` ${JSON.stringify(result.data.result.msg)}`
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(`쿠폰 다운로드 중 에러가 발생하였습니다. </br> 잠시후 다시 시도해주세요`, '', 'error');
      setCouponLoading(false);
    }
  };

  return (
    <Box mb="12px" key={coupon.id} width="100%" borderRadius="8px" boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)">
      <Box width="100%" height={samePeriod && !notPeriod ? '147px' : '162px'} display="flex">
        <Box width="100%">
          <Box py="16px" pl="20px">
            <Box height="35px">
              {coupon.discount_type === 'percent' ? (
                <Box display="flex">
                  <Typography
                    lineHeight="32px"
                    color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : '#774AD2'}
                    fontWeight="700"
                    fontSize="32px"
                    fontFamily="D-DIN"
                  >
                    {coupon.percent}
                  </Typography>
                  <Typography
                    color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : '#774AD2'}
                    fontSize="24px"
                    fontWeight="800"
                    lineHeight="32px"
                    sx={{ marginTop: '1px' }}
                  >
                    %
                  </Typography>
                </Box>
              ) : (
                <Box display="flex">
                  <Typography
                    lineHeight="32px"
                    color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : '#774AD2'}
                    fontWeight="700"
                    fontSize="32px"
                    fontFamily="D-DIN"
                  >
                    {coupon.amount.toLocaleString()}
                  </Typography>
                  <Typography
                    color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : '#774AD2'}
                    fontSize="24px"
                    lineHeight="32px"
                    fontWeight="800"
                  >
                    원
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography
              fontSize="12px"
              fontWeight="800"
              color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : 'rgba(20, 2, 41, 0.60)'}
              lineHeight="14px"
              sx={{ marginY: '8px' }}
            >
              {coupon.name}
            </Typography>
            <Box my="8px" display="flex" justifyContent="left" alignItems="center" borderRadius="2px" height="16px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="2px"
                height="16px"
                paddingX="4px"
                sx={{
                  backgroundColor: alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.12)' : 'rgba(255, 139, 170, 0.16)'
                }}
              >
                <Typography
                  color={alreadyDownloaded || overDownload || notPeriod ? 'rgba(20, 2, 41, 0.60)' : '#FF809E'}
                  fontSize="10px"
                  fontWeight="700"
                >
                  프로모션 전용
                </Typography>
              </Box>
            </Box>

            <Box mt="8px">
              {notPeriod ? (
                <Box>
                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                    다운로드/사용 기간이 다른 쿠폰입니다
                  </Typography>
                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                    다운로드 시작일을 확인해주세요 :)
                  </Typography>
                  <Typography lineHeight="11px" color="#774AD2" fontSize="10px" fontWeight="400" sx={{ opacity: '.8', marginTop: '4px' }}>
                    다운로드 기간: {moment(coupon?.download_period.start_dt).locale('ko').format('YY.MM.DD a hh:mm ')} 부터
                  </Typography>
                </Box>
              ) : overDownload ? (
                <Box>
                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                    준비한 쿠폰이 모두 소진되었습니다.
                  </Typography>
                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                    다음 프로모션을 기대해주세요 :)
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5' }}>
                    최소 주문 금액: {coupon.min_order_amount.toLocaleString()}원 &nbsp;
                    {coupon.discount_type === 'percent' && `(최대 ${coupon.max_dis_amount.toLocaleString()}원 할인)`}
                  </Typography>
                  <Box>
                    {samePeriod ? (
                      <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                        다운로드 및 사용 기간: {moment(coupon?.download_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm ')}
                        &nbsp;까지
                      </Typography>
                    ) : (
                      <>
                        <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                          다운로드 기간: {moment(coupon?.download_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm')}
                          &nbsp;까지
                        </Typography>
                        <Typography lineHeight="11px" fontSize="10px" fontWeight="400" sx={{ opacity: '.5', marginTop: '4px' }}>
                          사용 기간: {moment(coupon?.use_period.end_dt).locale('ko').format('YY.MM.DD a hh:mm')}&nbsp;까지
                        </Typography>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <ButtonBase
          disabled={alreadyDownloaded || overDownload || couponLoading || notPeriod}
          onClick={async () => {
            if (notPeriod) {
              await WoAlert.fire(`쿠폰 다운로드 기간이 아닙니다.`, '', 'error');
              return;
            }
            couponDownload();
          }}
        >
          <Box
            width="1px"
            height={samePeriod && !notPeriod ? '147px' : '162px'}
            component="img"
            src={samePeriod || notPeriod ? shortDashed : longDashed}
          />
          <Box
            display="flex"
            width="75px"
            height={samePeriod && !notPeriod ? '147px' : '162px'}
            justifyContent="center"
            alignItems="center"
          >
            {couponLoading ? <CircularProgress color="primary" size="30px" /> : <Box height="36px" component="img" src={findIcon()} />}
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
}

export default Coupon;

Coupon.propTypes = {
  coupon: PropTypes.object,
  couponLoading: PropTypes.bool,
  findRightCouponAndChange: PropTypes.func,
  setCouponLoading: PropTypes.func
};
