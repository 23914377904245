import React from 'react';

// material-ui
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * 리뷰 목록 > 리뷰 카드
 * @returns {JSX.Element}
 * @constructor
 */
function ReviewCard({ review }) {
  console.log('--- reviewCard: ', review);
  return <Box>리뷰 아이템</Box>;
}

ReviewCard.propTypes = {
  review: PropTypes.object.isRequired
};

export default ReviewCard;
