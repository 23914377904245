import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getFirestore } from 'utils/firebase-tools';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useLocationState } from 'react-router-use-location-state';

// project imports
// import { useAdrop, useAuth, useScriptRef, useWineOne } from 'hooks';
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { AlarmButton, CartButton } from 'components/buttons';
import { PinMap } from 'components/widgets/UserLocationSheet/components';
import Footer from 'components/Footer';
import { useSelector } from 'store';
import { WoAlert } from 'utils/kmwine-alerts';
// import { Banner, CategoryArea, MagazineList, PromotionSection, ReviewList, VendorMapBanner } from './components';
import { Banner, CategoryArea, MagazineList, PromotionSection, VendorMapBanner } from './components';
import LocationButton from './components/LocationButton';
import { USER_AGENT } from 'store/slices/wine-one';
import MainCuration from './components/MainCuration';
import LiveButton from '../../../components/buttons/LiveButton';
import ThemeSection from './components/ThemeSection';
import TodayOneBottle from './components/TodayOneBottle';
import CoBuying from './components/CoBuying';
// import LiveTalk from './components/LiveTalk';
// material-ui
import { AppBar, Box, Container, Dialog, Fab, Slide, Stack, Toolbar, Typography } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTheme } from '@mui/material/styles';

// assets
import ArrowBackImg from 'assets/images/arrow-back.png';
import MainPopup from './components/MainPopup';
import MainReviewThread from './components/MainReviewThread';
// import GptFloatingImg from 'assets/images/gpt_floating.png';
import NaraCampaign from './components/NaraCampaign';

const PIN_MAP_OPEN_HASH = '#pinmap-open';

const { navigator } = window;
const { userAgent } = navigator;

/**
 * 메인화면에서 보여줄 리뷰 카운트
 * @type {number}
 */
const MAIN_REVIEW_COUNT = 8;

const initialReviewList = Object.freeze({ loaded: false, loading: true, error: false, reviews: [] });

/**
 * 홈 메인화면
 *
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 김지현<jh.kim@wineone.io>, 이재일<leeji@wineone.io>
 * @history
 *   - 2023. 01. 27. : AppBar 검색버튼 제거
 *   - 2023. 01. 28. : 정확한 DAU 집계를 위해 `[amplitude] Land On Homepage` 이벤트 로그를 `App.jsx`로 이동
 *   - v1.1.8: 셀프 프로모션
 *   - v1.1.9: 배너, 큐레이션(카드, 필터) 최적화
 */
function HomeIndex() {
  const theme = useTheme(); // 화면 테마
  const navigate = useNavigate();
  const location = useLocation();

  const { needPinSetting, user } = useAuth(); // 로그인 사용자 정보
  const { onNativeBackPressed } = useWineOne();
  const scriptedRef = useScriptRef();

  // Adrop 띠배너 광고
  // const { state: adState, data: adData } = useAdrop(process.env.REACT_APP_ADROP_HOME_BANNER_01_UNIT);

  // 사용자 위치정보, 서비스 상수
  const { userLocation } = useSelector((state) => state);

  const contentTopRef = React.useRef(null);

  // 배너 끝나는 지점의 위치(top)
  const contentTop = React.useMemo(() => {
    let top = 0;

    if (contentTopRef.current) {
      try {
        top = contentTopRef.current.offsetHeight;
      } catch (e) {
        /* DO NOTHING */
      }
    }
    return top;
  }, [contentTopRef.current]);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: contentTop
  });

  // 핀선택지도 열림여부
  const mapOpen = React.useMemo(() => location.hash === PIN_MAP_OPEN_HASH, [location.hash]);

  // 메인리뷰 목록
  const [reviewList, setReviewList] = useLocationState('hi_mainReviewList', initialReviewList);

  React.useLayoutEffect(() => {
    if (!reviewList.loaded) {
      fetchReviewList();
    }
  }, []);

  // 메인리뷰 목록 조회
  const fetchReviewList = async () => {
    // 차단 사용자 제외하고 조회
    const { blockUsers = [] } = user;

    const conditions = [where('show', '==', true)];
    const sort = [];

    // 차단 사용자 제외
    if (blockUsers.length > 0) {
      console.log('[HomeIndex] 차단 사용자의 게시물을 제외한 리뷰를 조회합니다.', [...blockUsers]);
      conditions.push(where('uid', 'not-in', [...blockUsers]));
      sort.push(orderBy('uid'));
    }

    sort.push(orderBy('created_at', 'desc'));

    const mainReviewSnapshot = await getDocs(
      query(collection(getFirestore(), 'main_review'), ...conditions, ...sort, limit(MAIN_REVIEW_COUNT))
    ).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[HomeIndex][fetchReviewList] Unmounted component.');
      return undefined;
    }

    if (mainReviewSnapshot.error) {
      console.error('메인 리뷰조회 오류: ', mainReviewSnapshot.error);
      setReviewList(() => ({
        loaded: true,
        loading: false,
        error: true,
        reviews: []
      }));
      return undefined;
    }

    console.debug('메인 리뷰조회 스냅샷: ', mainReviewSnapshot);
    const reviews = mainReviewSnapshot.docs.map((mainReviewDoc) => {
      const review = mainReviewDoc.data();
      // console.log('#review: ', review);
      const { created_at, id, like_cnt, main_image, oid, pdata_id, remove, show, star, summary, uid } = review;
      return { created_at, id, like_cnt, main_image, oid, pdata_id, remove, show, star, summary, uid };
    });

    setReviewList(() => ({
      loaded: true,
      loading: false,
      error: false,
      reviews
    }));
  };

  // 핀선택지도 열기
  const openPinMap = () => {
    console.log('[홈] 핀선택지도 열기');

    setTimeout(() => {
      navigate(PIN_MAP_OPEN_HASH, { state: location.state });
    }, 200);
  };

  // 핀선택지도 닫기
  const handleMapDialogClose = React.useCallback(() => {
    if (location.hash === PIN_MAP_OPEN_HASH) navigate(-1); // 중복클릭 방지
  }, [location.hash]);

  // 핀 선택완료됨
  const onPinConfirmed = (result) => {
    if (!result.success) {
      WoAlert.fire(`위치 설정 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {});
    } else {
      handleMapDialogClose(); // 핀 지도 닫기
    }
  };

  const helmet = React.useMemo(() => <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 홈`} />, []);

  // const reviewListComponent = React.useMemo(
  //   () => (
  //     <ReviewList
  //       title="주문자들의 솔직한 후기"
  //       // loading={reviewList.loading}
  //       loading={!reviewList.loaded}
  //       error={reviewList.error}
  //       reviews={reviewList.reviews}
  //       showMoreButton={reviewList.reviews.length > 0}
  //       onMoreButtonClick={() => {
  //         navigate('/home/main-reviews');
  //       }}
  //     />
  //   ),
  //   [reviewList.loaded]
  // );

  // 장바구니 버튼
  const cartButtonComponent = React.useMemo(() => <CartButton showBadge />, []);

  // 실시간버튼
  const liveButtonComponent = React.useMemo(() => <LiveButton />, []);

  // 알림버튼
  const alarmButtonComponent = React.useMemo(
    () => <AlarmButton color={theme.palette.text.primary} showBadge aria-label="알림" sx={{ ml: '7px' }} />,
    []
  );

  // 진행 중인 셀프 프로모션 와인샵
  const selfPromotionSection = React.useMemo(() => {
    return userLocation.coord ? <PromotionSection /> : null;
  }, [userLocation?.coord]);

  // 위치설정 버튼
  const userLocationBtn = React.useMemo(() => <LocationButton onClick={openPinMap} hideTooltip={false} light />, [userLocation]);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`[MallIndex] 홈 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);

    const isApplication = userAgent.includes(USER_AGENT.PREFIX);

    // 핀위치 지도 닫기
    if (mapOpen) {
      navigate(-1);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isApplication) {
        // 안드로이드 앱 종료
        if (userAgent.includes(USER_AGENT.AOS)) {
          finishApp();
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
      }
    }
  }, [location, mapOpen]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // GPT floating button
  // const gptFloatingButton = React.useMemo(() => {
  //   return (
  //     <Box
  //       sx={{
  //         position: 'fixed',
  //         zIndex: 1,
  //         bottom: 76,
  //         right: 20,
  //         width: 66,
  //         height: 66,
  //         boxShadow: '-1px 3px 10px 2px rgba(0, 0, 0, 0.25)',
  //         borderRadius: '50%'
  //       }}
  //       onClick={() => navigate('/my/gpt')}
  //     >
  //       <Box component="img" src={GptFloatingImg} sx={{ width: '100%', height: '100%' }} />
  //     </Box>
  //   );
  // }, []);

  // render
  return !needPinSetting ? (
    <Container maxWidth="xs" sx={{ position: 'relative', px: [0, 0] }}>
      {helmet}
      {/* 메인화면 헤더 */}
      <AppBar position="sticky" elevation={trigger ? 1 : 0}>
        <Container maxWidth="xs" sx={{ px: [0, 0] }}>
          <Toolbar>
            <Box component="section" height="100%">
              <Box height={(theme) => theme.components.MuiAppBar.styleOverrides.root.minHeight} display="flex" alignItems="center" top={0}>
                <Box minWidth={100} maxWidth="45vw">
                  {userLocationBtn}
                </Box>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" spacing="8px" sx={{ display: 'flex', alignItems: 'center' }}>
              {/* 실시간 버튼 */}
              {liveButtonComponent}
              {/* 장바구니 버튼 */}
              {cartButtonComponent}
              {/* 홈 화면 알림버튼 */}
              {alarmButtonComponent}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        {/* 정방형 배너영역 */}
        <Box display="block" position="relative">
          <Banner wrapperRef={contentTopRef} />
        </Box>
        {/* 하단 메인 팝업 영역 [start] */}
        <MainPopup />
        {/* 하단 메인 팝업 영역 [end] */}
        {/* 카테고리 영역[start] */}
        <CategoryArea />
        {/* 카테고리 영역[end] */}
        {/* 프로모션 영역[start] */}
        {selfPromotionSection}
        {/* 프로모션 영역[end] */}

        {/* 와인픽스 특가 */}
        <NaraCampaign />

        {/* 공동구매 [start] */}
        <CoBuying />
        {/* 공동구매 영역[end] */}
        {/* 메인 리뷰 쓰레드 [start] */}
        <MainReviewThread />
        {/* 메인 리뷰 쓰레드 [end] */}
        {/* Adrop 띠배너 광고 */}
        {/* {adState === 'initialized' && adData?.code === 0 && ( */}
        {/*   <Box */}
        {/*     className="adrop-home-ad-01" */}
        {/*     display="flex" */}
        {/*     flexDirection="column" */}
        {/*     justifyContent="center" */}
        {/*     alignItems="center" */}
        {/*     height="auto" */}
        {/*     backgroundColor="#F3F2F5" */}
        {/*     mt="-46px" */}
        {/*     mb="-42px" */}
        {/*     sx={{ '& a > img': { borderRadius: '4px' } }} */}
        {/*   > */}
        {/*     <Box height="32px" width="100%" bgcolor="#F3F2F5" /> */}
        {/*     <Box component="section" width="100%" dangerouslySetInnerHTML={{ __html: adData.result?.ad }} /> */}
        {/*     <Box height="32px" width="100%" bgcolor="#F3F2F5" /> */}
        {/*   </Box> */}
        {/* )} */}
        {/* 테마 상품 영역[start] */}
        <ThemeSection />
        {/* 테마 상품 영역[end] */}
        {/* 홈 큐레이션 상품영역[start] */}
        <MainCuration />
        {/* 홈 큐레이션 상품영역[end] */}
        {/* 와인샵 지도 배너 영역[start] */}
        <VendorMapBanner />
        {/* 와인샵 지도 배너 영역[end] */}
        {/* 오늘의 한병 [start] */}
        <TodayOneBottle />
        {/* 오늘의 한병 [end] */}
        {/* 추천 컨텐츠 영역[start] */}
        <MagazineList />
        {/* 추천 컨텐츠 영역[end] */}
        {/* 주문자들의 솔직한 리뷰[start] */}
        {/* {reviewListComponent} */}
        {/* 주문자들의 솔직한 리뷰[end] */}
        {/* 술술 라이브 톡 [start] */}
        {/* <LiveTalk /> */}
        {/* 술술 라이브 톡 [end] */}
        {/* 홈 큐레이션 카드영역[start] */}
        {/* <MainCurationCard /> */}
        {/* 홈 큐레이션 카드영역[end] */}
        {/* GPT 플로팅 버튼[start] */}
        {/* {gptFloatingButton} */}
        {/* GPT 플로팅 버튼[end] */}
      </main>
      <MemoizedFooter />

      {/* 핀선택 지도[start] */}
      <Dialog open={mapOpen} scroll={'paper'} fullScreen TransitionComponent={PinMapTransition} keepMounted onClose={handleMapDialogClose}>
        <Fab
          color="white"
          onClick={handleMapDialogClose}
          aria-label="위치설정 닫기"
          sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
        >
          <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} alt="" />
        </Fab>
        {mapOpen ? <PinMap height={`calc(var(--vh, 1vh) * 100)`} onPinConfirmed={onPinConfirmed} /> : null}
      </Dialog>
    </Container>
  ) : null;
}

export default HomeIndex;

// 직접위치설정 dialog transition.
/* eslint-disable react/prop-types */
const PinMapTransition = React.forwardRef(function Transition(props, ref) {
  const { children, ...other } = props;
  return (
    <Slide {...other} ref={ref} direction="left">
      {children}
    </Slide>
  );
});

function finishApp() {
  console.log('앱 종료하기');
  // 구매확정 API요청
  WoAlert.fire({
    title: (
      <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
        1KMWINE
      </Typography>
    ),
    html: `<div style='line-height: 1.5rem; text-align: left'>앱을 종료하시겠습니까?</div>`,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: '앱 종료',
    cancelButtonText: '취소',
    customClass: {
      confirmButton: 'max-50',
      cancelButton: 'max-50'
    }
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      try {
        window.kmwine.finishApp();
      } catch (e) {
        console.warn('안드로이드 앱 종료 실패.', e);
      }
    }
  });
}

const MemoizedFooter = React.memo(Footer);
