export { default } from './FilterDialog';

export const PRODUCT_MAX_PRICE = 500000;
export const PRODUCT_PRICE_STEP = 10000;

export const INITIAL_FILTER_STATE = {
  price_range: [0, PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP], // 가격범위
  price_range_custom: [0, ''], // 가격범위 직접입력
  price_all: true,
  vivino: false, // 비비노
  // pdata_category: PDATA_CATEGORY.WINE.value,
  pdata_category: 'all',
  tabIndex: 'all', // 타입
  variety: [], // 🍇품종
  country: [], // 생산국가
  taste_sweet: 0, // 🍭
  taste_acidity: 0, // 산도
  taste_body: 0, // 바디
  taste_tannin: 0, // 탄닌
  wine_type: null
};

/**
 * 필터에 사용할 품종 (와인원 지정)
 * @type {string[]}
 */
export const VARIETY_FILTER_NAMES = [
  'Cabernet Sauvignon',
  'Merlot',
  'Pinot Noir',
  'Chardonnay',
  'Sauvignon Blanc',
  'Syrah Shiraz',
  'Malbec',
  'Zinfandel',
  'Riesling',
  'Sangiovese',
  'Nebbiolo',
  'Moscato'
];

/**
 * 필터에 사용할 국가 (와인원 지정)
 * @type {string[]}
 */
export const COUNTRY_FILTER_NAMES = [
  'FR', // 🇫🇷
  'IT', // 🇮🇹
  'US', // 🇺🇸
  'CL', // 🇨🇱
  'ES', // 🇪🇸
  'AU', // 🇦🇺
  'DE', // 🇩🇪
  'AR', // 🇦🇷
  'NZ', // 🇳🇿
  'ZA', // 🇿🇦
  'PT' // 🇵🇹
];
