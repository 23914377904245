import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';

const Timer = ({ targetDate, isStart }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return { difference, timeLeft };
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const [state, setState] = useState(calculateTimeLeft());

  useEffect(() => {
    if (!isStart) {
      const timer = setTimeout(() => {
        setState(calculateTimeLeft());
      }, 1000);

      // 타이머가 만료되었을 때 setTimeout을 종료합니다.
      if (state.difference <= 0) {
        clearTimeout(timer);
        window.location.reload();
      }

      return () => clearTimeout(timer);
    }
  }, [state.difference, isStart]); // state.difference가 변할 때마다 useEffect가 다시 실행됩니다.

  const { timeLeft } = state;

  return (
    <Box>
      <Box>
        <Typography
          color="rgba(20, 2, 41, 0.40)"
          fontSize="12px"
          fontWeight="700"
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          mt="24px"
          mb="16px"
        >
          판매 오픈까지 남은 시간
        </Typography>
      </Box>

      {state.difference > 0 ? (
        <Box display="flex" width="100%" justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="24px"
              borderRadius="4px"
              sx={{ background: 'radial-gradient(50% 50% at 50% 50%, #925CED 0%, #6360CA 100%)' }}
            >
              <Typography color="#FFF" fontSize="16px" fontWeight="800">
                {padZero(timeLeft.days)}
              </Typography>
            </Box>
            <Typography color="rgba(20, 2, 41, 0.40)" fontSize="8px" fontWeight="700">
              일
            </Typography>
          </Box>
          <Typography fontSize="16px" fontWeight="800" color="#6360CA" mx="4px" pb="11px">
            :
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="24px"
              borderRadius="4px"
              sx={{ background: 'radial-gradient(50% 50% at 50% 50%, #925CED 0%, #6360CA 100%)' }}
            >
              <Typography color="#FFF" fontSize="16px" fontWeight="800">
                {padZero(timeLeft.hours)}
              </Typography>
            </Box>
            <Typography color="rgba(20, 2, 41, 0.40)" fontSize="8px" fontWeight="700">
              시간
            </Typography>
          </Box>
          <Typography fontSize="16px" fontWeight="800" color="#6360CA" mx="4px" pb="11px">
            :
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="24px"
              borderRadius="4px"
              sx={{ background: 'radial-gradient(50% 50% at 50% 50%, #925CED 0%, #6360CA 100%)' }}
            >
              <Typography color="#FFF" fontSize="16px" fontWeight="800">
                {padZero(timeLeft.minutes)}
              </Typography>
            </Box>
            <Typography color="rgba(20, 2, 41, 0.40)" fontSize="8px" fontWeight="700">
              분
            </Typography>
          </Box>
          <Typography fontSize="16px" fontWeight="800" color="#6360CA" mx="4px" pb="11px">
            :
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="24px"
              borderRadius="4px"
              sx={{ background: 'radial-gradient(50% 50% at 50% 50%, #925CED 0%, #6360CA 100%)' }}
            >
              <Typography color="#FFF" fontSize="16px" fontWeight="800">
                {padZero(timeLeft.seconds)}
              </Typography>
            </Box>
            <Typography color="rgba(20, 2, 41, 0.40)" fontSize="8px" fontWeight="700">
              초
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Timer;

Timer.propTypes = {
  targetDate: PropTypes.string,
  isStart: PropTypes.bool
};
