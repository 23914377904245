import axios from 'axios';
import { NODE_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';

/**
 * 현재 진행 중인 셆프 프로모션 목록 조회
 */
export async function getSelfPromotionList(body) {
  console.debug('[getSelfPromotionList] 현재 진행 중인 셀프 프로모션 목록조회 요청', body);
  return axios
    .post(NODE_URL.POST__GET_VENDOR__SELF_PROMOTION_LIST, { ...body })
    .then((response) => {
      if (response.data?.success) {
        console.debug('[getSelfPromotionList] 현재 진행 중인 셀프 프로모션 목록조회 성공', response.data);
      } else {
        throw new Error(`서버 응답 'success' 필드의 값이 false 입니다.`);
      }

      return response.data;
    })
    .catch((error) => {
      console.error('[getSelfPromotionList] 현재 진행 중인 셀프 프로모션 목록조회 실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `현재 진행 중인 셀프 프로모션 목록조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `getSelfPromotionList`,
          param: { location: window?.location?.href ?? 'Unknown' }
        }).catch(console.error);
      }

      throw error;
    });
}

export async function getSelfPromotion(id) {
  return axios
    .get(NODE_URL.GET__GET__SELF_PROMOTION.replace(':promoId', id))
    .then((response) => response.data)
    .catch((error) => {
      console.error('[getPromotion] 셀프 프로모션 상세정보 조회 실패', error);
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `셀프 프로모션 상세정보 조회 실패 [${error.message}]`,
          msg: error.message ?? 'Unexpected',
          which: `getSelfPromotion`,
          param: { location: window?.location?.href ?? 'Unknown' }
        }).catch(console.error);
      }
      throw error;
    });
}
