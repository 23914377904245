import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { ORDER_ITEM } from 'config';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup } from '@mui/material';

/**
 * Order item action button set
 * @param order
 * @param orderItem Order item
 * @param handleClick Click event handler
 * @param {object} wrapperProps Wrapper component properties.
 * @param {object} ButtonGroupProps ButtonGroup component properties.
 * @returns {JSX.Element}
 * @constructor
 */
export function OrderItemActionButtons({ order, orderItem, handleClick, wrapperProps = {}, ButtonGroupProps = {} }) {
  const { state } = orderItem;
  const buttons = [];

  const isTicket = React.useMemo(() => {
    return orderItem?.pdata?.category === 'ticket';
  }, [orderItem?.pdata?.category]);

  // '승인대기' 주문상품
  if (state === ORDER_ITEM.STATE.REQUEST) {
    /* DO NOTHING */
    console.debug(`승인대기 상품은 주문전체의 '주문취소', '픽업 일시 변경' 기능을 사용함.`);
  }
  // '픽업대기(주문승인)' 주문상품
  else if (state === ORDER_ITEM.STATE.APPROVED) {
    /* DO NOTHING */
    console.debug(`픽업대기(주문승인) 상품은 주문전체의 '주문취소', '픽업 일시 변경' 기능을 사용함.`);
  }
  // '주문취소(판매사)' 주문상품
  else if (state === 'REQ_NO') {
    /* DO NOTHING */
  }
  // '주문취소' 주문상품
  else if (state === 'CANCEL') {
    /* DO NOTHING */
  }
  // '픽업완료' 주문상품
  else if (state === 'PICK_OK') {
    const { cobuy_state = 'NONE' } = order ?? {};

    const isCoBuyOrder = cobuy_state !== 'NONE';

    if (!isTicket && !isCoBuyOrder && order.promotion?.pickup?.state !== 'none') {
      buttons.push(
        <OrderItemActionButton
          key={`order-item-${orderItem.id}-excrtn`}
          onClick={(e) => {
            handleClick(e, 'order.item.excrtn');
          }}
        >
          교환/반품
        </OrderItemActionButton>
      );
    }

    buttons.push(
      <OrderItemActionButton key={`order-item-${orderItem.id}-confirm`} onClick={(e) => handleClick(e, 'order.item.confirm')}>
        구매확정
      </OrderItemActionButton>
    );
  }
  // '구매확정' 주문상품
  else if (state === ORDER_ITEM.STATE.PURCHASE_CONFIRM && !isTicket) {
    // 리뷰 아이디
    const reviewId = orderItem.review_id ?? orderItem?.review?.id;

    // 리뷰 상세화면으로 이동
    if (reviewId) {
      console.debug(`작성된 리뷰가 있습니다. [review_id=${reviewId}]`);
      buttons.push(
        <OrderItemActionButton key={`order-item-${orderItem.id}-show-review`} onClick={(e) => handleClick(e, 'order.item.show-review')}>
          리뷰 보기
        </OrderItemActionButton>
      );
    }
    // 리뷰 작성버튼
    else {
      buttons.push(
        <OrderItemActionButton key={`order-item-${orderItem.id}-write-review`} onClick={(e) => handleClick(e, 'order.item.write-review')}>
          리뷰 작성
        </OrderItemActionButton>
      );
    }
  }
  // '교환요청' & '교환대기' 주문상품
  else if (state === ORDER_ITEM.STATE.REQUEST_EXCHANGE || state === ORDER_ITEM.STATE.EXCHANGE_WAIT) {
    buttons.push(
      <OrderItemActionButton
        key={`order-item-${orderItem.id}-cancel-exchange`}
        onClick={(e) => handleClick(e, 'order.item.cancel-exchange')}
      >
        교환 신청 취소
      </OrderItemActionButton>
    );
  }

  // '교환취소 (판매사)' 주문상품
  else if (state === ORDER_ITEM.STATE.EXCHANGE_REJECTED) {
    buttons.push(
      <OrderItemActionButton key={`order-item-${orderItem.id}-confirm`} onClick={(e) => handleClick(e, 'order.item.confirm')}>
        구매확정
      </OrderItemActionButton>
    );
  }

  // '반품요청' & '반품대기' 주문상품
  else if (state === ORDER_ITEM.STATE.REQUEST_RETURN || state === ORDER_ITEM.STATE.RETURN_WAIT) {
    buttons.push(
      <OrderItemActionButton key={`order-item-${orderItem.id}-cancel-return`} onClick={(e) => handleClick(e, 'order.item.cancel-return')}>
        반품 신청 취소
      </OrderItemActionButton>
    );
  }

  // '반품완료' 주문상품
  else if (state === ORDER_ITEM.STATE.RETURN_COMPLETE) {
    console.debug('반품완료상태는 기능버튼 없음');
  }
  // '반품취소' 주문상품
  else if (state === ORDER_ITEM.STATE.RETURN_REJECTED) {
    buttons.push(
      <OrderItemActionButton key={`order-item-${orderItem.id}-confirm`} onClick={(e) => handleClick(e, 'order.item.confirm')}>
        구매확정
      </OrderItemActionButton>
    );
  }
  // '구매확정'된 주문상품
  else if (state === 'CONFIRM') {
    /* DO NOTHING */
  }
  // todo 상태별 액션버튼
  else {
    console.warn(`[1kmwine] 처리가 필요한 주문상품 액션. (orderItem.state=${state})`);
  }

  if (buttons.length === 0) return null;

  return buttons.length > 0 ? (
    <ButtonGroupWrapper className={'order-action-button'} key={`order-item-${orderItem.id}-action-buttons`} {...wrapperProps}>
      <ButtonGroup
        fullWidth
        disableElevation
        variant="outlined"
        size="large"
        color="brand"
        aria-label="주문상품 기능버튼모음"
        fontSize={16}
        {...ButtonGroupProps}
      >
        {buttons.map((actionBtn) => actionBtn)}
      </ButtonGroup>
    </ButtonGroupWrapper>
  ) : null;
}

OrderItemActionButtons.propTypes = {
  order: PropTypes.object,
  orderItem: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  wrapperProps: PropTypes.object,
  ButtonGroupProps: PropTypes.object
};

const OrderItemActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px',
  borderColor: '#e7e4eb',
  color: theme.palette.text.primary,
  fontSize: 14,
  lineHeight: 1,
  paddingTop: '10px',
  paddingBottom: '10px',
  height: '40px'
}));

const ButtonGroupWrapper = styled(Box)({
  marginTop: '14px'
});
