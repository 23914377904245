import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../components/Loadable';
import AuthLayout from '../utils/route-guard/AuthLayout';

import MyIndexPage from 'views/my/MyIndex';

import MyOrder from 'views/my/MyOrder';
import MyCoupon from 'views/my/MyCoupon'; // 나의 쿠폰
import MyDocs from 'views/my/MyDocs'; // 약관 및 정책
import AppSettingPage from 'views/my/AppSetting'; // 앱 설정 화면
import AlarmSetting from '../views/my/AlarmSetting'; // 앱 설정 화면
// 이벤트 목록
import EventListPage from 'views/my/event/EventList';
// 이벤트 상세
import EventDetailPage from 'views/my/event/EventDetail';
// 장바구니
import MyCart from 'views/my/cart/MyCart';
import { ReviewSelectPdataList } from '../views/my/MyReviewSelectPdata/component';

const MyProfilePage = Loadable(lazy(() => import('views/my/MyProfile')));
// 와인 취향 설정 페이지
const MyTasteEditPage = Loadable(lazy(() => import('views/my/MyTasteEdit')));
const MyReviewPage = Loadable(lazy(() => import('views/my/MyReviews')));
const MyReviewSelectPdata = Loadable(lazy(() => import('views/my/MyReviewSelectPdata')));
const MyReviewWrite = Loadable(lazy(() => import('views/my/MyReviewWrite')));
const MyReviewDetail = Loadable(lazy(() => import('views/my/MyReviewDetail')));
const MyReviewModify = Loadable(lazy(() => import('views/my/MyReviewModify')));

// GPT
// const MyGpt = Loadable(lazy(() => import('views/my/MyGpt')));

// 추천정보 관리
const Social = Loadable(lazy(() => import('views/my/Social')));
// 차단사용자 관리
const BlockUsers = Loadable(lazy(() => import('views/my/BlockUsers')));
// 키워드 알림
const KeywordAlarm = Loadable(lazy(() => import('views/my/KeywordAlarm')));
// 회원탈퇴
const Withdraw = Loadable(lazy(() => import('views/my/Withdraw')));
// 오픈소스 라이선스
const OpenSourceLicense = Loadable(lazy(() => import('views/my/OpenSourceLicense')));

// 공지사항 목록
const NoticeListPage = Loadable(lazy(() => import('views/my/Notice/NoticeList')));
// 공지사항 상세
const NoticeDetailPage = Loadable(lazy(() => import('views/my/Notice/NoticeDetail')));

// Faq
const FaqPage = Loadable(lazy(() => import('views/my/Faq/Faq')));

export default function MyRoutes() {
  return (
    <Route path="/my" element={<AuthLayout />}>
      <Route index element={<MyIndexPage />} />
      {/* 사용자 프로필 페이지 */}
      <Route path={'profile/*'} element={<MyProfilePage />} />
      {/* 와인 취향 설정 페이지 */}
      <Route path={'taste/edit'} element={<MyTasteEditPage />} />
      {/* 사용자 주문내역 페이지 */}
      <Route path={'order/*'} element={<MyOrder />} />
      {/* 사용자 보유쿠폰 페이지 */}
      <Route path={'coupon'} element={<MyCoupon />} />

      {/* 장바구니 화면 */}
      <Route path={'cart'} element={<MyCart />} />

      {/* 사용자 리뷰 목록 페이지 */}
      <Route path={'review/list'} element={<MyReviewPage />} />
      {/* 사용자 리뷰작성 전 pdata 선택 페이지 */}
      <Route path={'review/select/pdata'} element={<MyReviewSelectPdata />} />
      {/* 사용자 리뷰작성 전 pdata 목록 페이지 */}
      <Route path={'review/select/pdata/list'} element={<ReviewSelectPdataList />} />
      {/* 사용자 리뷰작성 페이지 */}
      <Route path={'review/:pdataId/write'} element={<MyReviewWrite />} />
      {/* 사용자 리뷰수정 페이지 */}
      <Route path={'review/:reviewId/modify'} element={<MyReviewModify />} />
      {/* 사용자 리뷰 상세 페이지 */}
      <Route path={'review/:reviewId'} element={<MyReviewDetail />} />

      {/* 사용자 GPT 페이지 */}
      {/* <Route path={'gpt'} element={<MyGpt />} /> */}

      {/* 사용자 이벤트 목록 페이지 */}
      <Route path={'event/list'} element={<EventListPage />} />
      {/* 사용자 이벤트 상세 페이지 */}
      <Route path={'event/:eventId'} element={<EventDetailPage />} />

      {/* 키워드 알림 페이지 */}
      <Route path={'keyword/alarm'} element={<KeywordAlarm />} />
      {/* 앱 설정 페이지 */}
      <Route path={'app-setting'} element={<AppSettingPage />} />
      {/* 알림 설정 페이지 */}
      <Route path={'app-setting/alarm-setting'} element={<AlarmSetting />} />
      {/* 추천정보 관리 페이지 */}
      <Route path={'app-setting/social'} element={<Social />} />
      {/* 차단사용자 관리 페이지 */}
      <Route path={'app-setting/block-users'} element={<BlockUsers />} />
      {/* 오픈소스 라이선스 페이지 */}
      <Route path={'app-setting/opensource-license'} element={<OpenSourceLicense />} />

      {/* Faq 페이지 */}
      <Route path={'faq'} element={<FaqPage />} />
      {/* 공지사항 페이지 */}
      <Route path={'notice/list'} element={<NoticeListPage />} />
      {/* 공지사항 상세 페이지 */}
      <Route path={'notice/:noticeId'} element={<NoticeDetailPage />} />
      {/* 약관 및 정책 페이지 */}
      <Route path={'docs'} element={<MyDocs />} />

      {/* 회원탈퇴 */}
      <Route path="withdraw" element={<Withdraw />} />
    </Route>
  );
}
