// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { getFirebaseFunctions, getFirestore } from '../../utils/firebase-tools';
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { openSnackbar } from 'store/slices/snackbar';
import * as UserService from 'services/UserService';
import { httpsCallable } from 'firebase/functions';
import { CLO_CODE } from '../../config';

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  open: false, // 알림 드로어 열림
  initialized: false, // 알림 데이터 initialized
  loading: false, // 알림 데이터 로딩 중
  alarms: [], // 알림 리스트
  current: 1, // 현재 페이지
  lastVisible: null, // firestore 호출시 페이징 처리를 위한 마지막 snapshot
  done: false // 모든 알람을 조회함
};

// 한 페이지에 조회할 알림 개수
const PAGE_SIZE = 15;

/**
 * 알람 관련 slice
 */
const slice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // 알람영역 TOGGLE
    toggle(state, action) {
      state.open = action.payload;
    },

    // 로딩상태 변경
    setLoading(state, action = false) {
      state.loading = action.payload;
    },

    setState(state, action) {
      const { alarms, lastVisible, loading, done } = action.payload;
      if (typeof lastVisible !== 'undefined') state.lastVisible = lastVisible;
      if (typeof loading === 'boolean') state.loading = loading;
      if (typeof done === 'boolean') state.done = done;
      if (typeof alarms === 'object' && Array.isArray(alarms)) state.alarms = alarms;
    },

    // 알림 리스트 조회
    fetchAlarmList(state, action) {
      state.error = null;
      state.initialized = true;

      const { pageNo, alarms } = action.payload;
      console.log('[Alarms] pageNo', pageNo);

      state.alarms = alarms;
      state.current = pageNo;
    },

    // Reset alarm slice state
    reset(state) {
      state = { ...initialState };
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

/**
 * 알림영역 열림
 * @returns {(function(): Promise<void>)|*}
 */
export function openAlarmDrawer() {
  return async () => {
    dispatch(slice.actions.toggle(true));

    // 알림영역이 열릴 때, 알림 조회
    dispatch(fetchAlarmList(1));
  };
}

/**
 * 알림영역 닫힘
 * @returns {(function(): Promise<void>)|*}
 */
export function closeAlarmDrawer() {
  return async () => {
    dispatch(slice.actions.toggle(false));
  };
}

/**
 * 알람목록 불러오기
 * @param {number} pageNo 페이지
 * @returns {(function(): Promise<void>)|*}
 */
export function fetchAlarmList(pageNo) {
  return async (dispatch, getState) => {
    const { currentUser } = getAuth();
    if (!currentUser) {
      console.warn('[1KMWINE] 로그인 사용자가 아닙니다. 알림을 불러올 수 없습니다.');
      return false;
    }
    const { uid } = currentUser;

    // Sets the ui loading...

    if (pageNo === 1) {
      dispatch(slice.actions.setState({ alarms: [], loading: true }));
    } else {
      dispatch(slice.actions.setLoading(true));
    }

    // Last alarm slice state.
    const { alarm } = await getState();
    const queryOpts = [collection(getFirestore(), `member/${uid}/user_alarm`), where('remove', '==', false), orderBy('created_at', 'desc')];

    // paginate
    if (pageNo > 1 && alarm.lastVisible !== null) {
      queryOpts.push(startAfter(alarm.lastVisible));
    }

    // fetch alarms from firestore
    const alarmlistSnapshot = await getDocs(query(...queryOpts, limit(PAGE_SIZE))).catch((error) => ({ error }));
    await dispatch(slice.actions.setLoading(false));
    console.log('[Alarms] 로그인 사용자의 알림목록 스냅샷: ', pageNo, alarmlistSnapshot, uid);

    // 목록 조회 실패
    if (alarmlistSnapshot.error) {
      const { error } = alarmlistSnapshot;
      console.error('[Alarms] error: ', error);

      const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `알람목록 조회 중 오류가 발생했습니다.. [uid=${uid}]`,
        msg: `${JSON.stringify(error)}`,
        which: `[uid=${uid ?? 'anonymous'}]`,
        error,
        param: {
          uid
        }
      })
        .then(console.log)
        .catch(console.error);

      dispatch(slice.actions.hasError(alarmlistSnapshot.error));
      return undefined;
    }

    let done = alarmlistSnapshot.empty; // All alarms are already requested.
    let lastVisible = null;

    console.log(`[done=${done}, lastVisible=${lastVisible}]`);

    // 이어서 조회된 알림이 있습니다.
    if (!done) {
      // console.log('############## state: ', alarm.alarms);
      const newAlarms = alarmlistSnapshot.docs.map((alarmDoc) => alarmDoc.data());
      const alarms = pageNo === 1 ? [...newAlarms] : [...alarm.alarms, ...newAlarms];

      await dispatch(slice.actions.fetchAlarmList({ pageNo, alarms }));

      lastVisible = alarmlistSnapshot.docs[PAGE_SIZE - 1] ?? null;
      done = lastVisible == null;
    } else {
      // All alarms are requested.
      console.debug('[Alarms] 모든 알림을 조회했습니다.');
      if (pageNo === 1) {
        await dispatch(slice.actions.fetchAlarmList({ pageNo: 1, alarms: [] }));
      }
    }

    await dispatch(slice.actions.setState({ lastVisible, done }));
  };
}

/**
 * 로그인 사용자의 알림 전체삭제(읽음처리)
 */
export function removeAllAlarms() {
  return async (dispatch, getState) => {
    const { alarm } = getState();
    const tmpAlarms = [...alarm.alarms];
    await dispatch(slice.actions.setState({ alarms: [], loading: true }));
    // await dispatch(slice.actions.setState({ loading: true }));
    // await dispatch(slice.actions.fetchAlarmList({ pageNo: 1, alarms: [] }));
    // await dispatch(slice.actions.setLoading(false));

    // 사용자 알림 전체삭제 API요청
    const response = await UserService.removeAllAlarms().catch((error) => ({ error }));

    // 알림 삭제 오류
    if (response.error) {
      const { error } = response;
      console.error('[1KMWINE] 사용자 알림 전체삭제 중 오류. ', error);

      // toast
      dispatch(
        openSnackbar({
          open: true,
          message: '알림 삭제 중 오류가 발생했습니다.\r\n잠시후 다시 시도해주세요',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,
          autoHideDuration: 4000
        })
      );

      await dispatch(slice.actions.setState({ loading: false }));
      return undefined;
    }

    // 알림 삭제결과
    const { result } = response.data;

    console.debug('알림 전체삭제 결과: ', result);

    // 전체삭제 성공
    if (result.code === 0) {
      dispatch(
        openSnackbar({
          open: true,
          message: '알림이 삭제되었습니다.',
          variant: 'alert',
          alert: { color: 'success' },
          close: true,
          autoHideDuration: 2600
        })
      );
      dispatch(fetchAlarmList(1));
    }

    // 전체삭제 실패 (API 오류
    else {
      console.error(`[1KMWINE] 알림 전체삭제 실패 [code='${result.code}', msg='${result.msg}']`);
      try {
        const auth = getAuth();
        const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `사용자 알림 전체삭제 실패 [uid=${auth.currentUser.uid}, code='${result.code}', msg='${result.msg}']`,
          which: `${window.location.pathname}${window.location.search}`
        })
          .then(console.log)
          .catch(console.error);
      } catch (e) {
        /* DO NOTHING */
      }

      await dispatch(slice.actions.setState({ alarms: tmpAlarms, loading: false }));

      // toast
      dispatch(
        openSnackbar({
          open: true,
          message: '알림 삭제 중 오류가 발생했습니다.\r\n잠시후 다시 시도해주세요',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
    }
  };
}
