import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { useScriptRef, useWineOne } from 'hooks';

// material-ui
import { Box, Skeleton } from '@mui/material';

// assets
import vendorOnIcon from 'assets/images/map/img_store_ongoing@2x.webp';
import vendorOffIcon from 'assets/images/map/img_store_tobe@2x.webp';
import userMarkerIcon from 'assets/images/map_user_marker.png';

/* global kakao */

/**
 * 입점샵 위치 지도
 *
 * @param {string|number} height 지도 높이
 * @param {Object} vendor 입점샵 정보
 */
function VendorLocationMap({ height = '100%', vendor = null }) {
  const scriptedRef = useScriptRef();

  // 사용자의 실제 위치정보
  const { geolocation } = useWineOne();

  const [userCoord, setUserCoord] = React.useState(null); // 현재 사용자 실제 위치좌표
  const [userMarker, setUserMarker] = React.useState(null); // 현재 사용자 실제 위치 마커

  React.useEffect(() => {
    if (geolocation.initialized) {
      const { access, latitude, longitude } = geolocation;
      // 사용자의 위치에 접근할 수 있는 상태
      if (access) {
        setUserCoord({ latitude, longitude });
      }
    }

    return () => {
      setUserCoord(null);
    };
  }, [geolocation]);

  // 카카오 맵이 들어갈 div
  const mapDivRef = React.useRef(null);
  // 카카오 지도 객체
  const [map, setMap] = React.useState(null);
  // 입점샵 마커
  const [vendorMarker, setVendorMarker] = React.useState(null);

  React.useEffect(() => {
    // console.log('[주문상세][입점샵 위치] vendorProp:', vendorProp, vendorMarker);
    if (vendor?.biz?.location) {
      const {
        biz: { location }
      } = vendor;
      // const { latitude: lat, longitude: lng } = location;
      const lat = location.latitude;
      const lng = location.longitude;

      // 입점샵 좌표정보
      let vendorCoord = new kakao.maps.LatLng(lat, lng);

      // 핀 마커 이미지
      const vendorMarkerImage = new kakao.maps.MarkerImage(
        vendor.deleted || vendor.closed ? vendorOffIcon : vendorOnIcon,
        new kakao.maps.Size(32, 38)
      );

      let _vendorMarker;

      // 이미 세팅된 입점샵 마커가 있을 경우
      if (vendorMarker !== null) {
        _vendorMarker = vendorMarker;
        // console.log('[주문상세][입점샵 위치] _vendorMarker:', _vendorMarker);
        try {
          const oldPosition = vendorMarker.getPosition();
          const oldCoord = {
            lat: oldPosition.getLat(),
            lng: oldPosition.getLng()
          };

          // if (oldCoord.lat !== lat || oldCoord.lng !== lng) {
          if (oldCoord.lat === lat || oldCoord.lng !== lng) {
            console.debug('[주문상세][입점샵 위치] 갱신된 입점샵의 위치가 변경되었습니다. 마커위치를 갱신합니다.', oldCoord, lat, lng);
            vendorCoord = new kakao.maps.LatLng(lat, lng);
          }
        } catch (e) {
          console.warn('[주문상세][입점샵 위치] 입점샵 마커 갱신실패:', e);
        }
      } else {
        _vendorMarker = new kakao.maps.Marker();
      }

      _vendorMarker.setImage(vendorMarkerImage);

      // console.log('[주문상세][입점샵 위치] map: ', map);
      let _map;

      if (!map) {
        // 지도 옵션
        _map = new kakao.maps.Map(mapDivRef.current, {
          center: vendorCoord,
          level: 4, // 기본 줌 레벨
          minLevel: 2,
          maxLevel: 10 // 최대 줌 레벨
        });
      } else {
        _map = map;
        _map.setCenter(vendorCoord);
      }

      if (scriptedRef.current) {
        setMap(_map);
        _vendorMarker.setPosition(vendorCoord);
        _vendorMarker.setMap(_map);
        setVendorMarker(_vendorMarker);
      }
    }
  }, [vendor?.biz?.location]);

  React.useEffect(() => {
    if (map && userCoord != null) {
      // 사용자 위치핀
      const tempUserMarker =
        userMarker == null
          ? new kakao.maps.Marker({
              clickable: false,
              zIndex: 1
            })
          : userMarker;

      // 현재 사용자 위치좌표
      const userPosition = new kakao.maps.LatLng(userCoord.latitude, userCoord.longitude);
      tempUserMarker.setImage(new kakao.maps.MarkerImage(userMarkerIcon, new kakao.maps.Size(20, 20)));
      tempUserMarker.setPosition(userPosition);
      tempUserMarker.setMap(map);
      tempUserMarker.setClickable(false);

      if (scriptedRef.current) {
        setUserMarker(tempUserMarker);
      }
    }
  }, [userCoord, map]);

  // render
  return (
    <Box id="vendor-location-map" ref={mapDivRef}>
      <Skeleton variant="rectangular" width="100%" height={height} />;
    </Box>
  );
}

VendorLocationMap.propTypes = {
  height: PropTypes.number,
  vendor: PropTypes.object
};

export default VendorLocationMap;
